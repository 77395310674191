import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GreenitMinmax, GreenitServersSettings, GreenitSettings, Message, User } from '@app/model';
import { AccountService, GreenitService, JsonloaderService, ShareService } from '@app/services';

import { DefaultValues, GreenitTarget, HOST_DIRECT_RATIO, NETWORK_DIRECT_RATIO, STORAGE_DIRECT_RATIO, TreeTarget } from '../greenit.enums';

import { getUserCurrency } from '../../../assets/js/tools';
import { ClrDatagridSortOrder } from '@clr/angular';

import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-greenitsettings',
  templateUrl: './greenitsettings.component.html',
  styleUrls: ['./greenitsettings.component.css']
})
export class GreenitsettingsComponent implements OnInit {

  dataOrder = ClrDatagridSortOrder.ASC;

  math = Math;
  number = Number;

  message: Message;

  isDcscope = true;
	isCo2scope = false;

  currentUser: User;
  globalCurrency: string;

  hosts_uuid: any;

  // PIE CHART
  Highcharts: typeof Highcharts = Highcharts;

  chart: Highcharts.Chart;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;

    this.chart.showLoading();
  };

  options: Highcharts.Options = {
    chart: {
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    time: {
        useUTC: false
    },
    title: {
        text: '',
        //text: 'Division of power consumption by IT',
        style: {
            color: 'grey',
            fontSize: '15px',
            marginTop: -100
        }
    },
    legend: {
        enabled: false
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            size: '65%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                distance: '-35%'
            }
        }
    },
  }

  //FOR SETTINGS
  private db_settings: GreenitSettings[];
  private settings_changed: any;
  private optimization_run: boolean;

  settings_modal: boolean;
  
  save_button: boolean;
  indicator_save: boolean;
  minmax_save: boolean;
  settings_save: boolean;
  extrapolation_save: boolean;

  extrapolation_alert: boolean;

  last_update: number;

  /*dc*/
  average_host_year: number;
  average_pue: number;
  average_co2: number;
  average_cost: number;

  settings_host_other_number: number;
  settings_host_other_sockets: number;
  settings_host_other_memory: number;
  settings_host_other_power: number;
  settings_switch_number: number;
  settings_switch_power: number;
  settings_storage_number: number;
  settings_storage_power: number;

  /*minmax*/
  private db_serversminmax: GreenitMinmax[];
  minmax_data: any;
  show_minmax_details: boolean;

  /*servers indicators*/
  private db_serversindicators: GreenitServersSettings[];
  indicators_data: GreenitServersSettings[];
  show_indicators_details: boolean;

  /*desktop*/
  settings_printer_age: number;
  settings_printer_home_number: number;
  settings_printer_pro_number: number;
  settings_laptop_age: number;
  settings_laptop_13_number: number;
  settings_laptop_15_number: number;
  settings_laptop_17_number: number;
  settings_laptop_charger_number: number;
  settings_desktop_age: number;
  settings_desktop_number: number;
  settings_keyboard_number: number;
  settings_mouse_number: number;
  settings_screen_17_number: number;
  settings_screen_22_number: number;
  settings_screen_27_number: number;
  settings_desktop_router_number: number;
  settings_desktop_network_other_number: number;
  settings_tablet_number: number;
  settings_smartphone_number: number;

  /*optimization*/
  settings_optimization_host: any;

  /*network & storage */
  settings_network_direct_ratio: number;
  settings_storage_direct_ratio: number;
  settings_host_direct_ratio: number;

  // FOR TOOLS
  indicatortree: TreeTarget;
  minmaxtree: TreeTarget;

  // FOR HISTORY
  history_modal: boolean;
  last_history: number;
  history_disabled: boolean;

  /**
   *
   */
  constructor(private authentication_svc: AccountService, private json_svc: JsonloaderService,
    private message_svc: ShareService, private greenit_svc: GreenitService, private changeDetectorRef: ChangeDetectorRef) {

    this.hosts_uuid = [];

    // FOR SETTINGS 
    this.settings_modal = false;

    this.save_button = false;
    this.indicator_save = undefined;
    this.minmax_save = undefined;
    this.settings_save = undefined;
    this.extrapolation_save = undefined;

    this.extrapolation_alert = false;

    this.show_minmax_details = true;
    this.show_indicators_details = true;

    this.db_settings = [];
    this.db_serversminmax = [];
    this.db_serversindicators = [];

    this.last_update = 0;

    // FOR HISTORY
    this.history_modal = false;
    this.last_history = 0;
    this.history_disabled = false;

    // FOR TOOLS
    this.indicatortree = TreeTarget.INDICATOR;
    this.minmaxtree = TreeTarget.MINMAX;

  } 

  /**
   * 
   */
  ngOnInit(): void {
    this.message_svc.currentMessage.subscribe(message => this.message = message);
    this.authentication_svc.user.subscribe(user => this.currentUser = user);

    this.globalCurrency = getUserCurrency(this.currentUser.currency);

    this.isCo2scope = this.message.isCO2Scope;
		this.isDcscope = this.message.isDCScope;
  }

  /**
   * Display settings modal
   */
  async displayModalSettings(): Promise<void> {

    this.settings_modal = true;
    
    // First initialization
    this.save_button = false;
    this.indicator_save = undefined;
    this.minmax_save = undefined;
    this.settings_save = undefined;

    this.settings_changed = {};
    this.optimization_run = false;

    // Empty hosts array
    this.hosts_uuid = [];

    // Init hosts array ...
    let hostSynthesis_json: JSON = this.json_svc.json.hostSynthesis;
    for (let i in hostSynthesis_json) {
      this.hosts_uuid[i] = [];
      this.hosts_uuid[i][0] = hostSynthesis_json[i].uuid;
      this.hosts_uuid[i][1] = hostSynthesis_json[i].name;
    }

    // ... and sort it by name
    this.hosts_uuid.sort(function (a, b) {
      return (b[1].toUpperCase()) < (a[1].toUpperCase()) ? 1 : -1;
    });

    // GET SETTINGS (async)
    try {
      let settings_subscribe = await this.greenit_svc.getGreenitSettings().toPromise();
      this.updateDataSettings(settings_subscribe);
    } catch {
      // Nothing, already on console
    }

    // GET MINMAX (async)
    let minmax_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter, GreenitTarget.HOST_MINMAX).toPromise();
    this.db_serversminmax = <GreenitMinmax[]> minmax_subscribe;
    
    // GET INDICATORS (async)
    let indicators_subscribe = await this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter, GreenitTarget.HOST_SETTINGS).toPromise();
    this.db_serversindicators = <GreenitServersSettings[]> indicators_subscribe;

    // INIT SETTINGS
    this.initSettings();

    // Update modal width for datagrids
    setTimeout(() => {
      // Update modal width
      let modal: HTMLCollectionOf<Element> = document.getElementsByClassName('modal-xl');
      if(modal.length > 0)
        (modal[0] as HTMLElement).style.width = "70rem";
    }, 100);

  }

  /**
   * 
   */
  private initSettings(): void {

    //XXX default average
    this.average_host_year = new Date().getFullYear();
    this.average_pue = DefaultValues.DC_PUE;
    this.average_co2 = DefaultValues.DC_DIRECT_CO2;
    this.average_cost = DefaultValues.DC_DIRECT_COST;

    //XXX default settings

    //
    // DC
    //

    this.settings_host_other_number = 0;
    this.settings_host_other_sockets = 0;
    this.settings_host_other_memory = 0;
    this.settings_host_other_power = 0;
    this.settings_switch_number = 0;
    this.settings_switch_power = 0;
    this.settings_storage_number = 0;
    this.settings_storage_power = 0;

    //
    // DESKTOP
    //
    this.settings_printer_age = 4;
    this.settings_printer_home_number = 0;
    this.settings_printer_pro_number = 0;
    this.settings_laptop_age = 4;
    this.settings_laptop_13_number = 0;
    this.settings_laptop_15_number = 0;
    this.settings_laptop_17_number = 0;
    this.settings_laptop_charger_number = 0;
    this.settings_desktop_age = 4;
    this.settings_desktop_number = 0;
    this.settings_keyboard_number = 0;
    this.settings_mouse_number = 0;
    this.settings_screen_17_number = 0;
    this.settings_screen_22_number = 0;
    this.settings_screen_27_number = 0;
    this.settings_desktop_router_number = 0;
    this.settings_desktop_network_other_number = 0;
    this.settings_tablet_number = 0;
    this.settings_smartphone_number = 0;

    //
    // OPTIMIZATION
    //
    this.settings_optimization_host = "";

    //
    // NETWORK & STORAGE
    //
    this.settings_network_direct_ratio = DefaultValues.NETWORK_EXTRAPOLATION_RATIO;
    this.settings_storage_direct_ratio = DefaultValues.STORAGE_EXTRAPOLATION_RATIO;
    this.settings_host_direct_ratio = DefaultValues.HOST_EXTRAPOLATION_RATIO;

    // Get settings
    for (let i in this.db_settings) {
      if (this.db_settings[i] != undefined) {
        switch (this.db_settings[i].option) {
          //
          // DC
          //
          case "dc.host.other.number":
            this.settings_host_other_number = Number(this.db_settings[i].value);
            break;
          case "dc.host.other.sockets":
            this.settings_host_other_sockets = Number(this.db_settings[i].value);
            break;
          case "dc.host.other.memory":
            this.settings_host_other_memory = Number(this.db_settings[i].value);
            break;
          case "dc.host.other.power":
            this.settings_host_other_power = Number(this.db_settings[i].value);
            break;
          case "dc.switch.number":
            this.settings_switch_number = Number(this.db_settings[i].value);
            break;
          case "dc.switch.power":
            this.settings_switch_power = Number(this.db_settings[i].value);
            break;
          case "dc.storage.number":
            this.settings_storage_number = Number(this.db_settings[i].value);
            break;
          case "dc.storage.power":
            this.settings_storage_power = Number(this.db_settings[i].value);
            break;
          //
          // DESKTOP
          //
          case "desktop.printer.age":
            this.settings_printer_age = Number(this.db_settings[i].value);
            break;
          case "desktop.printer.home.number":
            this.settings_printer_home_number = Number(this.db_settings[i].value);
            break;
          case "desktop.printer.pro.number":
            this.settings_printer_pro_number = Number(this.db_settings[i].value);
            break;
          case "desktop.laptop.age":
            this.settings_laptop_age = Number(this.db_settings[i].value);
            break;
          case "desktop.laptop.13.number":
            this.settings_laptop_13_number = Number(this.db_settings[i].value);
            break;
          case "desktop.laptop.15.number":
            this.settings_laptop_15_number = Number(this.db_settings[i].value);
            break;
          case "desktop.laptop.17.number":
            this.settings_laptop_17_number = Number(this.db_settings[i].value);
            break;
          case "desktop.laptop.charger.number":
            this.settings_laptop_charger_number = Number(this.db_settings[i].value);
            break;
          case "desktop.desktop.age":
            this.settings_desktop_age = Number(this.db_settings[i].value);
            break;
          case "desktop.desktop.number":
            this.settings_desktop_number = Number(this.db_settings[i].value);
            break;
          case "desktop.keyboard.number":
            this.settings_keyboard_number = Number(this.db_settings[i].value);
            break;
          case "desktop.mouse.number":
            this.settings_mouse_number = Number(this.db_settings[i].value);
            break;
          case "desktop.screen.17.number":
            this.settings_screen_17_number = Number(this.db_settings[i].value);
            break;
          case "desktop.screen.22.number":
            this.settings_screen_22_number = Number(this.db_settings[i].value);
            break;
          case "desktop.screen.27.number":
            this.settings_screen_27_number = Number(this.db_settings[i].value);
            break;
          case "desktop.router.number":
            this.settings_desktop_router_number = Number(this.db_settings[i].value);
            break;
          case "desktop.network.other.number":
            this.settings_desktop_network_other_number = Number(this.db_settings[i].value);
            break;
          case "desktop.tablet.number":
            this.settings_tablet_number = Number(this.db_settings[i].value);
            break;
          case "desktop.smartphone.number":
            this.settings_smartphone_number = Number(this.db_settings[i].value);
            break;
          //
          // NETWORK & STORAGE
          //
          case "network.direct.ratio":
            this.settings_network_direct_ratio = Number(this.db_settings[i].value);
            break;
          case "storage.direct.ratio":
            this.settings_storage_direct_ratio = Number(this.db_settings[i].value);
            break;
          case "host.direct.ratio":
            this.settings_host_direct_ratio = Number(this.db_settings[i].value);
            break;
          //
          // OPTIMIZATION
          //
          case "optimization.host":
            let settings_uuid = this.db_settings[i].value;
            // Get host name from uuid
            for (let i in this.hosts_uuid) {
              if (this.hosts_uuid[i][0] == settings_uuid) {
                this.settings_optimization_host = this.hosts_uuid[i][1];
                break;
              }
            }
            break;
        }
      }
    }

    // Get min max
    this.minmax_data = [];
    this.db_serversminmax.forEach(val => this.minmax_data.push(Object.assign({}, val)));  // create a copy

    // Get servers settings
    this.indicators_data = [];
    this.db_serversindicators.forEach(val => this.indicators_data.push(Object.assign({}, val)));  // create a copy

    // Default DC indicators are now based from filter
    if(this.indicators_data.length > 0) {
      let avgYear = this.indicators_data.map(res => res.year).reduce((a, b) => a + b) / this.indicators_data.length;
      let avgPue = this.indicators_data.map(res => res.pue).reduce((a, b) => a + b) / this.indicators_data.length;
      let avgCo2 = this.indicators_data.map(res => res.co2).reduce((a, b) => a + b) / this.indicators_data.length;
      let avgCost = this.indicators_data.map(res => res.cost).reduce((a, b) => a + b) / this.indicators_data.length;

      this.average_host_year = Number(avgYear.toFixed(0));
      this.average_pue = Number(avgPue.toFixed(3));
      this.average_co2 = Number(avgCo2.toFixed(3));
      this.average_cost = Number(avgCost.toFixed(3));
    }

  }

  /**
   * 
   */
  setNetworkStorageTab(): void {
    setTimeout(() => {
      this.displayPie();
    }, 100);
  }

  /**
   * 
   */
  private displayPie(): void {

    if (this.chart.series[0] != undefined)
      this.chart.series[0].remove(true);

    let datas = [];

    let tmp_data: any = {
      name: 'storage',
      y: this.settings_storage_direct_ratio,
    };
    datas.push(tmp_data);

    tmp_data = {
      name: 'network',
      y: this.settings_network_direct_ratio,
    };
    datas.push(tmp_data);

    tmp_data = {
      name: 'hosts',
      y: this.settings_host_direct_ratio,
      color: '#476888'
    };
    datas.push(tmp_data);

    // Add serie
    this.chart.addSeries({
        type: 'pie',
        data: datas,
        tooltip: {
          pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
        },
    });

    // Hide chart loading
    this.chart.hideLoading();

    // Add title (center)
    let newX = this.chart.plotWidth / 2 + this.chart.plotLeft;
    let newY = this.chart.plotHeight / 2 + this.chart.plotTop;

    this.chart.renderer.text('Division of energy consumption by IT', newX, newY / 3)
      .attr({
        zIndex: 5,
        align: 'center'
      })
      .css({
        color: 'grey',
        fontSize: '13px',
        fontWeight: 'bold',
      })
    .add();

    // reflow
    this.chart.reflow();
  }

  /**
   * Auto fill some informations
  */
  autoSettings(element: HTMLElement): void {
    let objInput: HTMLInputElement = null;

    switch (element.id) {
      case "desktop.desktop.number":
        // Update keyboards
        objInput = <HTMLInputElement> document.getElementById("desktop.keyboard.number");
        this.settings_keyboard_number = this.settings_desktop_number;
        this.addSettings(objInput);
        // Update mouses
        objInput = <HTMLInputElement> document.getElementById("desktop.mouse.number");
        this.settings_mouse_number = this.settings_desktop_number;
        this.addSettings(objInput);
        break;
      case "desktop.laptop.13.number":
      case "desktop.laptop.15.number":
      case "desktop.laptop.17.number":
        // Update chargers      
        objInput = <HTMLInputElement> document.getElementById("desktop.laptop.charger.number");
        this.settings_laptop_charger_number = Number(this.settings_laptop_13_number + this.settings_laptop_15_number + this.settings_laptop_17_number);
        this.addSettings(objInput);
        break;
    }

  }

  /**
   * 
  */
  addSettings(element: HTMLInputElement): void {

    this.settings_save = true;

    switch (element.id) {
      //
      // DC
      //
      case "dc.host.other.number":
        if (this.settings_host_other_number != null && this.settings_host_other_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_host_other_number });
        } else {
          this.settings_host_other_number = 0;
        }
        break;
      case "dc.host.other.sockets":
        if (this.settings_host_other_sockets != null && this.settings_host_other_sockets >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_host_other_sockets });
        } else {
          this.settings_host_other_sockets = 0;
        }
        break;
      case "dc.host.other.memory":
        if (this.settings_host_other_memory != null && this.settings_host_other_memory >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_host_other_memory });
        } else {
          this.settings_host_other_memory = 0;
        }
        break;
      case "dc.host.other.power":
        if (this.settings_host_other_power != null && this.settings_host_other_power >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_host_other_power });
        } else {
          this.settings_host_other_power = 0;
        }
        break;
      //
      // DESKTOP
      //
      case "desktop.printer.age":
        if (this.settings_printer_age != null && this.settings_printer_age >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_printer_age });
        } else {
          this.settings_printer_age = 0;
        }
        break;
      case "desktop.printer.home.number":
        if (this.settings_printer_home_number != null && this.settings_printer_home_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_printer_home_number });
        } else {
          this.settings_printer_home_number = 0;
        }
        break;
      case "desktop.printer.pro.number":
        if (this.settings_printer_pro_number != null && this.settings_printer_pro_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_printer_pro_number });
        } else {
          this.settings_printer_pro_number = 0;
        }
        break;
      case "desktop.laptop.age":
        if (this.settings_laptop_age != null && this.settings_laptop_age >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_laptop_age });
        } else {
          this.settings_laptop_age = 0;
        }
        break;
      case "desktop.laptop.13.number":
        if (this.settings_laptop_13_number != null && this.settings_laptop_13_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_laptop_13_number });
        } else {
          this.settings_laptop_13_number = 0;
        }
        break;
      case "desktop.laptop.15.number":
        if (this.settings_laptop_15_number != null && this.settings_laptop_15_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_laptop_15_number });
        } else {
          this.settings_laptop_15_number = 0;
        }
        break;
      case "desktop.laptop.17.number":
        if (this.settings_laptop_17_number != null && this.settings_laptop_17_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_laptop_17_number });
        } else {
          this.settings_laptop_17_number = 0;
        }
        break;
      case "desktop.laptop.charger.number":
        if (this.settings_laptop_charger_number && this.settings_laptop_charger_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_laptop_charger_number });
        } else {
          this.settings_laptop_charger_number = 0;
        }
        break;
      case "desktop.desktop.age":
        if (this.settings_desktop_age != null && this.settings_desktop_age >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_desktop_age });
        } else {
          this.settings_desktop_age = 0;
        }
        break;
      case "desktop.desktop.number":
        if (this.settings_desktop_number != null && this.settings_desktop_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_desktop_number });
        } else {
          this.settings_desktop_number = 0;
        }
        break;
      case "desktop.keyboard.number":
        if (this.settings_keyboard_number != null && this.settings_keyboard_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_keyboard_number });
        } else {
          this.settings_keyboard_number = 0;
        }
        break;
      case "desktop.mouse.number":
        if (this.settings_mouse_number != null && this.settings_mouse_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_mouse_number });
        } else {
          this.settings_mouse_number = 0;
        }
        break;
      case "desktop.screen.17.number":
        if (this.settings_screen_17_number != null && this.settings_screen_17_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_screen_17_number });
        } else {
          this.settings_screen_17_number = 0;
        }
        break;
      case "desktop.screen.22.number":
        if (this.settings_screen_22_number != null && this.settings_screen_22_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_screen_22_number });
        } else {
          this.settings_screen_22_number = 0;
        }
        break;
      case "desktop.screen.27.number":
        if (this.settings_screen_27_number != null && this.settings_screen_27_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_screen_27_number });
        } else {
          this.settings_screen_27_number = 0;
        }
        break;
      case "desktop.router.number":
        if (this.settings_desktop_router_number != null && this.settings_desktop_router_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_desktop_router_number });
        } else {
          this.settings_desktop_router_number = 0;
        }
        break;
      case "desktop.network.other.number":
        if (this.settings_desktop_network_other_number != null && this.settings_desktop_network_other_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_desktop_network_other_number });
        } else {
          this.settings_desktop_network_other_number = 0;
        }
        break;
      case "desktop.tablet.number":
        if (this.settings_tablet_number != null && this.settings_tablet_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_tablet_number });
        } else {
          this.settings_tablet_number = 0;
        }
        break;
      case "desktop.smartphone.number":
        if (this.settings_smartphone_number != null && this.settings_smartphone_number >= 0) {
          Object.assign(this.settings_changed, { [element.id]: this.settings_smartphone_number });
        } else {
          this.settings_smartphone_number = 0;
        }
        break;
      //
      // OPTIMIZATION
      //
      case "optimization.host":
        this.optimization_run = true;
        let settings_optimization_host_uuid: string = "";
        // Get host uuid from name
        for (let i in this.hosts_uuid) {
          if (this.hosts_uuid[i][1] == this.settings_optimization_host) {
            settings_optimization_host_uuid = this.hosts_uuid[i][0];
            break;
          }
        }
        Object.assign(this.settings_changed, { [element.id]: settings_optimization_host_uuid });
        break;
    }

    // Update save button status
    this.updateSaveStatus();
  }

  /**
   * 
   */
  addNetworkStorageSettings(target: string): void {

    // Force to update view
    this.changeDetectorRef.detectChanges();

    this.extrapolation_alert = false;
    this.extrapolation_save = true;

    switch(target) {
      case "network.direct.ratio":
        if (this.settings_network_direct_ratio == null || this.settings_network_direct_ratio < 0 || this.settings_network_direct_ratio > 100) {
          this.settings_network_direct_ratio = 0;
        }
        break;
      case "storage.direct.ratio":
        if (this.settings_storage_direct_ratio == null || this.settings_storage_direct_ratio < 0 || this.settings_storage_direct_ratio > 100) {
          this.settings_storage_direct_ratio = 0;
        }
        break;

    }

    // Check values
    this.settings_host_direct_ratio = 100 - (this.settings_network_direct_ratio + this.settings_storage_direct_ratio);
    
    if(this.settings_host_direct_ratio > 0) {
  
      // Push settings
      Object.assign(this.settings_changed, { [NETWORK_DIRECT_RATIO]: this.settings_network_direct_ratio });
      Object.assign(this.settings_changed, { [STORAGE_DIRECT_RATIO]: this.settings_storage_direct_ratio });
      Object.assign(this.settings_changed, { [HOST_DIRECT_RATIO]: this.settings_host_direct_ratio });

      // Update pie
      this.displayPie();

    } else {
      this.extrapolation_alert = true;
      this.extrapolation_save = false;
    }

    // Update save button status
    this.updateSaveStatus();
  }

  /**
  * 
  */
  checkMinMaxSettings(element: HTMLInputElement): void {

    // Check min or max value
    if(element.valueAsNumber >= 0) {
      this.minmax_save = true;
    } else {
      element.valueAsNumber = 0;
      this.minmax_save = false;
    }

    // Check min max for all
    this.checkMinMax();

    // Update save button status
    this.updateSaveStatus();
  }

  /**
   * 
   */
  private checkMinMax(): void {
    for (let host of this.minmax_data) {
      if(host.min > host.max) {
        this.minmax_save = false;
        break;
      }
    }
  }

  /**
  * 
  */
  checkIndicatorsSettings(element: HTMLInputElement, host: any): void {
    let valid_data: boolean = false;

    // Check value
    switch(element.id) {
      case "settings.host.amortization":
      case "settings.host.co2":
      case "settings.host.cost":
      if(element.valueAsNumber >= 0)
        valid_data = true;
      break;
      default:
        if(element.valueAsNumber > 0)
          valid_data = true;
      break;
    }

    if(valid_data) {
      this.indicator_save = true;
      // Host last update
      host.timeago = new Date().getTime();
    } else {
      element.valueAsNumber = 0;
      this.indicator_save = false;
    }

    // Check for all servers if data are valid
    this.checkIndicators();

    // Update save button status
    this.updateSaveStatus();

  }

  /**
   * 
   */
  private checkIndicators(): void {
    for (let host of this.indicators_data) {
      if(host.year <= 0 || host.amortization < 0 || host.embodied <= 0 || host.co2 < 0 || host.cost < 0 || host.pue <= 0) {
        this.indicator_save = false;
        break;
      }
    }
  }

  /**
   * 
   */
  applyIndicators(data: any) {
    this.indicator_save = true;

    // update servers settings
    for (let i in data) {
      if (data[i] != undefined) {
        let server: GreenitServersSettings = this.indicators_data.find(sv => sv.identifier === data[i].identifier);
        if(server != undefined) {
          if(data[i].year != undefined)
            server.year = data[i].year;
          if(data[i].amortization != undefined)
            server.amortization = data[i].amortization;
          if(data[i].embodied != undefined)
            server.embodied = data[i].embodied;
          if(data[i].co2 != undefined)
            server.co2 = data[i].co2;
          if(data[i].cost != undefined)
            server.cost = data[i].cost;
          if(data[i].pue != undefined)
            server.pue = data[i].pue;
          server.timeago = data[i].timeago;
        }
      }
    }

    // Check min max for all
    this.checkIndicators();

    // Show details
    this.showIndicatorsDetails(true);

    // Update save button status
    this.updateSaveStatus();
  }

  /**
   * 
   */
  applyMinMax(data: any) {
    this.minmax_save = true;

    // update servers settings
    for (let i in data) {
      if (data[i] != undefined) {
        let server: GreenitMinmax = this.minmax_data.find(sv => sv.identifier === data[i].identifier);
        if(server != undefined) {
          if(data[i].min != undefined)
            server.min = data[i].min;
          if(data[i].max != undefined)
            server.max = data[i].max;
        }
      }
    }

    // Check min max for all
    this.checkMinMax();

    // Show details
    this.showMinMaxDetails(true);

    // Update save button status
    this.updateSaveStatus();
  }

  /**
   * 
   */
  showIndicatorsDetails(show: boolean): void {
    this.show_indicators_details = show;
  }

  /**
   * 
   */
  showMinMaxDetails(show: boolean): void {
    this.show_minmax_details = show;
  }

  /**
   * 
   */
  private updateSaveStatus(): void {
    let tmp_indicator = (this.indicator_save == true || this.indicator_save == undefined);
    let tmp_minmax = (this.minmax_save == true || this.minmax_save == undefined);
    let tmp_settings = (this.settings_save == true || this.settings_save == undefined);
    let tmp_extrapolation = (this.extrapolation_save == true || this.extrapolation_save == undefined);
    this.save_button = (tmp_indicator && tmp_minmax && tmp_settings && tmp_extrapolation);
  }

  /**
   * 
   */
  saveSettings(): void {
    this.settings_modal = false;

    let settings_end: boolean = false;
    let minmax_end: boolean = false;
    let indicators_end: boolean = false;

    // Show progress
    this.message.waiting = true;

    // Update date
    this.last_update = new Date().getTime();

    ////////////////
    // SAVE SETTINGS
    ////////////////
    if (Object.keys(this.settings_changed).length !== 0) {

      let json: JSON = JSON.parse(JSON.stringify(this.settings_changed));

      // Save settings
      this.greenit_svc.updateGreenitSettings(json).subscribe(
        success => {
          // Update settings
          this.greenit_svc.getGreenitSettings().subscribe(
            data => {
              this.updateDataSettings(data);

              //Call optimization if needed
              if (this.optimization_run) {
                this.greenit_svc.runGreenitOptimize(this.currentUser.login, this.message.currentFilter).subscribe(
                  success => {
                    // Get data
                    /* this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_SERVERS).subscribe(
                      data => {
                        this.optimization_json = data;
                        if (this.optimization_json != null)
                          this.optimization_kwh = Number(Number(this.optimization_json.KWH).toFixed(0));
                      }
                    ); */
                    settings_end = true;
                    this.message.waiting = !(settings_end && minmax_end && indicators_end);
                  },
                  error => {
                    console.log(error);
                    settings_end = true;
                    this.message.waiting = !(settings_end && minmax_end && indicators_end);
                  }
                );
              } else {
                settings_end = true;
                this.message.waiting = !(settings_end && minmax_end && indicators_end);
              }

            },
            error => {
              console.log(error);
              settings_end = true;
              this.message.waiting = !(settings_end && minmax_end && indicators_end);
            }
          );
        },
        error => {
          console.log(error);
          settings_end = true;
          this.message.waiting = !(settings_end && minmax_end && indicators_end);
        }
      );
    } else {
      settings_end = true;
      this.message.waiting = !(settings_end && minmax_end && indicators_end);
    }

    ////////////////
    // SAVE MINMAX
    ////////////////
    if(this.minmax_save) {
      let json: JSON = JSON.parse(JSON.stringify(this.minmax_data));
      
      // Save min max
      this.greenit_svc.updateServersMinMax(json).subscribe(
        success => {
          // Update min max data
          this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter, GreenitTarget.HOST_MINMAX).subscribe(
            data => {
              this.db_serversminmax = <GreenitMinmax[]> data;
              minmax_end = true;
              this.message.waiting = !(settings_end && minmax_end && indicators_end);
            },
            error => {
              console.log(error);
              minmax_end = true;
              this.message.waiting = !(settings_end && minmax_end && indicators_end);
            }
          );
        },
        error => {
          console.log(error);
          minmax_end = true;
          this.message.waiting = !(settings_end && minmax_end && indicators_end);
        }
      );

    } else {
      minmax_end = true;
      this.message.waiting = !(settings_end && minmax_end && indicators_end);
    }

    /////////////////////////
    // SAVE INDICATORS
    /////////////////////////
    if(this.indicator_save) {
      let json: JSON = JSON.parse(JSON.stringify(this.indicators_data));

      // Save settings
      this.greenit_svc.updateServersSettings(json).subscribe(
        success => {
          // Update settings data
          this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter, GreenitTarget.HOST_SETTINGS).subscribe(
            data => {
              this.db_serversindicators = <GreenitServersSettings[]> data;
              indicators_end = true;
              this.message.waiting = !(settings_end && minmax_end && indicators_end);
            },
            error => {
              console.log(error);
              indicators_end = true;
              this.message.waiting = !(settings_end && minmax_end && indicators_end);
            }
          );
        },
        error => {
          console.log(error);
          indicators_end = true;
          this.message.waiting = !(settings_end && minmax_end && indicators_end);
        }
      );

    } else {
      indicators_end = true;
      this.message.waiting = !(settings_end && minmax_end && indicators_end);
    }

  }

  private updateDataSettings(data: any) {
    this.db_settings = data;

    // Update date
    let update_entry = this.db_settings.filter((e: { option: string; }) => e.option === "settings.update");
    if(update_entry[0])
      this.last_update = Number(update_entry[0].value);

    // Update history date
    let history_entry = this.db_settings.filter((e: { option: string; }) => e.option === "settings.history");
    if(history_entry[0])
      this.last_history = Number(history_entry[0].value);

    // Update history button
    let history_purge_entry = this.db_settings.filter((e: { option: string; }) => e.option === "settings.purge");
    if(history_purge_entry[0])
      this.history_disabled = Number(history_purge_entry[0].value) == 1;
  }

  /**
   *
   */
  closeModal(): void {
    this.history_modal = false;
  }

  /**
	 * 
	 */
	formatDate(time: number): string {
    if(time == 0)
      return "---";
    else
	    return new Date(time).toLocaleDateString('en-EN');
	}

  /**
   * 
  */
  confirmClear(): void  {
    this.history_modal = false;

    // Update date
    this.last_history = new Date().getTime();

    // Disable button
    this.history_disabled = true;

    this.greenit_svc.clearHistory().subscribe(
      data => {  
        // TODO
      },
      error => {
        if(error != null)
          console.log(error);
      }
    );
  }

  /**
   * 
   */
  exportIndicators(): void {
    let res = ["NAME", "DATE OF PURCHASE", "AMORTIZATION (year)", "EMBODIED CARBON (kg CO2eq)", "ENERGY FACTOR EMISSION (kg CO2eq/kWh)", "ENERGY COST (per kWh)", "PUE"].join(',') + '\n';
		res += Object.values<any>(this.indicators_data).map(host =>
			[host.name, host.year, host.amortization, host.embodied, host.co2, host.cost, host.pue ].join(",")
		).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'servers_indicators.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  /**
   * 
   */
  exportMinMax(): void {
    let res = ["NAME", "MODEL", "CPU MODEL", "CPU #", "RAM CAPACITY (MB)", "POWER MIN (W)", "POWER MAX (W)"].join(',') + '\n';
		res += Object.values<any>(this.minmax_data).map(host =>
			[host.name, host.model, host.cpumodel, host.sockets, host.ramcap, host.min, host.max ].join(",")
		).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'servers_minmax.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

}

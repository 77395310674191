import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NetscopeService } from '../../services/netscope.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as d3 from 'd3';

import {JsonloaderService, LicenseService} from "@app/services";
import {ClrCombobox, ClrDatagridSortOrder, ClrDatagridStringFilterInterface} from "@clr/angular";
import {environment} from "@environments/environment";


class SourceFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.source !== undefined && item.source.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return false;
  }
}

class DestinationFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.target !== undefined && item.target.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return false;
  }
}

@Component({
  selector: 'app-netscope-dependencies-viewer',
  templateUrl: './netscope-dependencies-viewer.component.html',
  styleUrls: ['./netscope-dependencies-viewer.component.css']
})
export class NetscopeDependenciesViewerComponent implements OnInit, AfterViewInit, OnDestroy {

  jsonData = undefined;
  subscriptions = [];
  isLoading = false;
  svg;
  height = 1024;
  width = 768;
  chartRef;
  chartCheckInterval;
  reloadButtonColorClass = "btn-primary";

  sourceIpFilter = new SourceFilter();
  destinationIpFilter = new DestinationFilter();
  sourceHostIpFilter = new SourceFilter();
  destinationHostIpFilter = new DestinationFilter();

  isNetscopeLicenceEnabled = true;

  lastSvgWidth = 0;
  lastSvgHeight = 0;
  vmClickAction = "focus";

  focusedVms = [];

  root = [];
  nodesMapping = {};
  timestamps = [];
  replayData;
  graphData = {
    links: [],
    directed_links: [],
    vms: [],
    routers: [],
    clusters: []
  };
  filteredLinks = [];
  nodeRadius = 15;
  currentTime = 0;
  timeResolution = "daily";
  minTime = 0;
  maxTime = 0;

  descSort = ClrDatagridSortOrder.DESC;

  @ViewChild('combobox')
  combobox: ClrCombobox<any>;

  selectedResourcesWithSearchCombobox = [];
  resourcesForSearchCombobox = [];

  @ViewChild('dataSettingsPanel')
  dataSettingsPanel;

  // allHosts = [];
  // allVms = [];

  targetedResources = {
    hosts: [],
    virtualMachines: []
  };
  targetedResourcePattern = "";

  mapDataFilters = {
    hosts: {
    },
    virtualMachines: {}
  };

  filtersInitialized = false;
  filters = {
    protocols: {
    },
    source: {
    },
    destination: {}
  };

  isPlaying = false;
  currentRangeValue = 0;
  minRangeValue = 0;
  maxRangeValue = 0;

  graphParameters = {
    userMovedMap: false,
    lastTransformation: undefined
  }

  updateFlag = false;

  resourceSelectionChanged = new EventEmitter();
  clickOnTimeSlotEmitter = new EventEmitter();

  constructor(private netscopeService: NetscopeService, public licenceService: LicenseService, private activatedRouter: ActivatedRoute, private route:Router, private jsonLoaderService: JsonloaderService) {
  }

  ngOnInit(): void {

    this.activatedRouter.params.subscribe((params) => {
      let resource_uuids_str_value = params["resource_uuids"];
      if (resource_uuids_str_value === undefined) {
        return;
      }
      let resource_uuids = resource_uuids_str_value.split(",");
      this.focusedVms = resource_uuids.map((resource_uuid) => Object({
        id: resource_uuid,
        uuid: resource_uuid,
        type: "vm"
      }));
    });

    let subscription1 = this.jsonLoaderService.currentJsonSimpleSubject.subscribe(json => {
      this.jsonData = json;
      this.reloadData();
    });
    let subscription2 = this.jsonLoaderService.eventJsonAsyncLoaded.subscribe(json => {
      this.jsonData = json;
      this.reloadData();
    });
    this.subscriptions = [subscription1, subscription2];

    window.onresize = () => {
      if (this.jsonData !== undefined) {
        this.reloadVisualization();
      }
    };

    this.route.events.subscribe((event) => {
      this.ngOnDestroy();
    });

    this.licenceService.licenseInfo.subscribe(licenceInfo => {
      if (environment.production) {
        // Check second bit to be different than 0
        this.isNetscopeLicenceEnabled = (licenceInfo.moduleslicense & (1 << 1)) !== 0;
      } else {
        this.isNetscopeLicenceEnabled = true;
      }
    });

    this.clickOnTimeSlotEmitter.subscribe((event) => {
      console.log(event);
      // @ts-ignore
      let timestamp = event[0];
      this.currentTime = timestamp;
      this.timeResolution = event[2];
      this.reloadData();
    })
  }

  ngOnDestroy = () => {
    if (this.chartCheckInterval) {
      clearInterval(this.chartCheckInterval);
    }
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // // Following function is in a setTimeout in order to prevent the "Expression has changed" error when running
      // // the unit tests
      // this.dataSettingsPanel.togglePanel();
    });
  }

  callbackChartRef = (ref) => {
    this.chartRef = ref;
  }

  _generateListOfTargetedResources = () => {

    if (this.targetedResourcePattern === "" || this.targetedResourcePattern.length < 2) {
      return {
        switches: [],
        networks: [],
        hosts: [],
        virtualMachines: []
      };
    }

    var isValidRegex = true;
    try {
      new RegExp(this.targetedResourcePattern);
    } catch(e) {
      isValidRegex = false;
    }

    // Hosts
    let selectedHosts = this.replayData.routers
        .filter((host) => {
          if (host.name.toUpperCase().indexOf(this.targetedResourcePattern.toUpperCase()) !== -1) {
            return true;
          }
          if (isValidRegex && host.name.match(this.targetedResourcePattern)) {
            return true;
          }
          return false;
        });
    // VirtualMachines
    let selectedVirtualMachines = this.replayData.vms
        .filter((vm) => {
          if(vm.name.toUpperCase().indexOf(this.targetedResourcePattern.toUpperCase()) !== -1) {
            return true;
          }
          if(isValidRegex && vm.name.match(this.targetedResourcePattern)) {
            return true;
          }
          return false;
        });

    return {
      hosts: selectedHosts,
      virtualMachines: selectedVirtualMachines
    };
  }

  updateListTargetedResources = () => {
    const targetResources = this._generateListOfTargetedResources();
    this.targetedResources.hosts = targetResources.hosts;
    this.targetedResources.virtualMachines = targetResources.virtualMachines;
  }

  applyActionOnTargetResources = (resourceType, action) => {
    const targetResources = this._generateListOfTargetedResources();
    for (let targetResource of targetResources[resourceType]) {
      this.mapDataFilters[resourceType][targetResource.uuid] = action == "check" ? true : false;
    }
    this.setButtonPrimaryRed();
  }

  clickHostCheckbox = (hostUuid) => {
    this.graphData.routers
        .filter((host) => host.uuid === hostUuid)
        .map((host) => host.children.map((vmUuid) => {
          this.mapDataFilters.virtualMachines[vmUuid] = this.mapDataFilters.hosts[hostUuid];
        }))
    this.setButtonPrimaryRed();
  }

  clickVmCheckbox = (vmUuid) => {
    this.graphData.vms
        .filter((vm) => vm.uuid === vmUuid)
        .map((vm) => {
          if (this.mapDataFilters.hosts[vm.host.uuid] && this.mapDataFilters.virtualMachines[vm.uuid]) {

          } else {
            this.mapDataFilters.hosts[vm.host.uuid] = 'indeterminate';
          }
        })
    this.setButtonPrimaryRed();
  }

  setButtonPrimaryRed = () => {
    this.reloadButtonColorClass = "btn-warning";
  }

  selectedResourceInSearchCombobox = (selection) => {
    this.setButtonPrimaryRed();

    this.selectedResourcesWithSearchCombobox = selection.model;
    if (this.selectedResourcesWithSearchCombobox === null) {
      this.selectedResourcesWithSearchCombobox = [];
    }

    if (this.selectedResourcesWithSearchCombobox.length == 0) {
      for (let hostKey in this.mapDataFilters.hosts) {
        this.mapDataFilters.hosts[hostKey] = true;
      }for (let vmKey in this.mapDataFilters.virtualMachines) {
        this.mapDataFilters.virtualMachines[vmKey] = true;
      }
    } else {
      for (let hostKey in this.mapDataFilters.hosts) {
        this.mapDataFilters.hosts[hostKey] = false;
      }
      for (let vmKey in this.mapDataFilters.virtualMachines) {
        this.mapDataFilters.virtualMachines[vmKey] = false;
      }
    }

    let vms = this.selectedResourcesWithSearchCombobox
        .filter((r) => r.uuid.indexOf("VirtualMachine:") !== -1);
    let hosts = this.selectedResourcesWithSearchCombobox
        .filter((r) => r.uuid.indexOf("HostSystem:") !== -1);

    // Handle VMs first
    vms.map((vm) => {
      this.mapDataFilters.virtualMachines[vm.uuid] = true;
      this.graphData.routers.filter((host) => {
        return host.children.filter((hostVmUuid) => hostVmUuid === vm.uuid).length > 0;
      }).map((host) => {
        this.mapDataFilters.hosts[host.uuid] = true;
      })
    })

    // Handle Hosts second
    hosts.map((host) => {
      this.mapDataFilters.hosts[host.uuid] = true;
      host.children.map((hostVmUuid) => {
        this.mapDataFilters.virtualMachines[hostVmUuid] = true;
      });
    })

    // @ts-ignore
    this.combobox.toggleService.open = false;
    this.combobox.textbox.nativeElement.focus = false;
    this.combobox.focused = false;
  }

  reloadData = () => {
    this.isLoading = true;
    const timestampsAndCountsObservable = this.netscopeService.getDailyTimestamps();

    timestampsAndCountsObservable.subscribe((timestampsAndCounts) => {
      this.timestamps = timestampsAndCounts
          .filter((t) => t.start_time !== 0)
          .map((t) => t.start_time);
      if (this.timestamps.length > 0) {
        if (this.currentTime === 0) {
          this.currentTime = this.timestamps[this.timestamps.length - 1];
        }
      }

	  this.isLoading = false;
      this.reloadVisualization();
    });
  }

  reloadVisualization = () => {
    const startTimeInterval = this.currentTime;
    const lastTimeInterval: any = Math.max(...this.timestamps);
    let endTimeInterval;
    if (this.timeResolution === "daily") {
      if (lastTimeInterval <= startTimeInterval) {
        endTimeInterval = 'now()';
      } else {
        endTimeInterval = Math.min(...this.timestamps.filter((t) => t > startTimeInterval), lastTimeInterval);
      }
    } else {
      endTimeInterval = startTimeInterval + 3600 - 1;
    }

    const vmsUuids = this.jsonData.vmSynthesis.map((vm) => vm.uuid)
    const hostsUuids = this.jsonData.hostSynthesis.map((host) => host.uuid)

    this.isLoading = true;
    this.netscopeService.getClustersWithFilters(startTimeInterval, endTimeInterval, vmsUuids, hostsUuids, true, this.timeResolution).subscribe((clustersData) => {
      this.isLoading = false;
      
      this.reloadButtonColorClass = "btn-primary";

      // Initialize replayData
      this.replayData = clustersData;

      // Reload UI
      this.reloadUi();
    });
  }

  checkFiltersCheckbox = (resourceType) => {
    for (let key in this.mapDataFilters[resourceType]) {
      this.mapDataFilters[resourceType][key] = true;
    }
  }

  uncheckFiltersCheckbox = (resourceType) => {
    for (let key in this.mapDataFilters[resourceType]) {
      this.mapDataFilters[resourceType][key] = false;
    }
  }

  reloadUi = () => {
    const sortedTimes = this.timestamps
        .sort((a, b) => a - b);

    if (this.minTime === 0 || this.maxTime === 0) {
      this.minTime = Math.min(...sortedTimes);
      this.maxTime = Math.max(...sortedTimes);
      console.log(`setting replay start time at ${this.currentTime}`);
    }
    if (this.currentTime === 0) {
      this.currentTime = Math.max(...sortedTimes);
      console.log(`setting replay start time at ${this.currentTime}`);
    }
    // Set the range value
    this.minRangeValue = 0;
    this.maxRangeValue = sortedTimes.length - 1;
    const indexOfCurrentTime = sortedTimes.indexOf(this.currentTime);
    this.currentRangeValue = indexOfCurrentTime;
    // Set the right links
    this.graphData.links = this.replayData.links;
    this.graphData.directed_links = this.replayData.directed_links;
    this.graphData.vms = this.replayData.vms;

    // Take into account focused VMs
    if (this.focusedVms.length > 0) {
      let focusedVmsIds = this.focusedVms.map((vm) => vm.id);
      // Filter relevant links
      let relevantLinks = this.graphData.directed_links.filter((link) => {
        if (focusedVmsIds.indexOf(link.source.id) !== -1 || focusedVmsIds.indexOf(link.target.id) !== -1) {
          return true;
        }
        return false;
      })
      // Extract uuids of VMs in links
      let uuidVmMap = new Map();
      relevantLinks.map((link) => {
        uuidVmMap.set(link.source.id, link.source);
        uuidVmMap.set(link.target.id, link.target);
      })
      // Filter VMs
      this.graphData.vms = Array.from(uuidVmMap.values());
      // Add focused VMs if they are not in the new vms list
      for (let focusedVm of this.focusedVms) {
        let newVmsIds = this.graphData.vms.map((vm) => vm.id);
        if (newVmsIds.indexOf(focusedVm.id) === -1) {
          this.graphData.vms.push(focusedVm);
        }
      }
      // Filter links
      this.graphData.links = this.replayData.links.filter((link) => {
        if (uuidVmMap.has(link.source.id) || uuidVmMap.has(link.target.id)) {
          return true;
        }
        return false;
      });
    }

    // Add hosts UUID to vms
    let vmUuidToHostIndex = {};
    this.replayData.routers.map((router) => router.children.map((vmUuid) => {
      let short_uuid_parts = router.uuid.split(":");
      let short_uuid = short_uuid_parts[short_uuid_parts.length - 1];
      vmUuidToHostIndex[vmUuid] = {
        uuid: router.uuid,
        short_uuid: short_uuid,
        name: router.name
      };
    }))
    this.graphData.vms.map((vm) => {
      vm.host = vmUuidToHostIndex[vm.uuid];
    });
    this.replayData.vms.map((vm) => {
      vm.host = vmUuidToHostIndex[vm.uuid];
    });
    this.graphData.routers = this.replayData.routers;
    this.graphData.clusters = this.replayData.clusters;

    // Initialize mapDataFilters options
    this.graphData.vms.forEach((vm) => {
      if (!this.mapDataFilters.virtualMachines.hasOwnProperty(vm.uuid)) {
        this.mapDataFilters.virtualMachines[vm.uuid] = true;
      }
    });

    this.graphData.routers.forEach((host) => {
      if (!this.mapDataFilters.hosts.hasOwnProperty(host.uuid)) {
        this.mapDataFilters.hosts[host.uuid] = true;
      }
    });

    // Set host on source and target of each links and directed_links (for datagrid and export CSV)
    this.replayData.links.map((link) => {
      link.source.host = vmUuidToHostIndex[link.source.id];
      link.target.host = vmUuidToHostIndex[link.target.id];
    });

    this.replayData.directed_links.map((link) => {
      link.source.host = vmUuidToHostIndex[link.source.id];
      link.target.host = vmUuidToHostIndex[link.target.id];
    });

    this.resourcesForSearchCombobox = [
      ...this.graphData.routers,
      ...this.graphData.vms
    ]

    // Fix VMs without hosts
    const vmsWithoutHosts = this.graphData.vms
        .filter((vm) => vm.host === undefined);

    if (vmsWithoutHosts.length > 0) {
      vmsWithoutHosts.map((vm) => {
        let simpleVmUuid = vm.uuid.split(":")[1];
        const matchingVmsDcscope = this.jsonData.vmSynthesis
            .filter((vmDcScope) => simpleVmUuid === vmDcScope.uuid);
        matchingVmsDcscope.map((matchingVmDcscope) => {
          let hostUuid = `vim.HostSystem:${matchingVmDcscope.father}`;
          let matchingHosts = this.graphData.routers.filter((host) => host.uuid === hostUuid);
          matchingHosts.map((host) => {
            let hostObject = Object({
              uuid: hostUuid,
              short_uuid: `${matchingVmDcscope.father}`,
              name: host.name
            });
            vm.host = hostObject
            host.children.push(vm.uuid);

            [this.graphData.links, this.graphData.directed_links, this.replayData.directed_links, this.replayData.links].map((links) => {
              links.filter((link) => link.source.uuid === vm.uuid).map((link) => {
                link.source.host = hostObject;
              })

              links.filter((link) => link.target.uuid === vm.uuid).map((link) => {
                link.target.host = hostObject;
              })
            })
          });
        })
      });
    }

    // Refresh network view
    this.eraseNetworkView();
    this.createNetworkView(this.graphData);
  }

  isFocusedVm(vmUuid) {
    let focusedVmsIds = this.focusedVms.map((vm) => vm.id);
    return focusedVmsIds.indexOf(vmUuid) !== -1;
  }

  selectForFocus = (vmUuids) => {
    this.focusedVms = [];

    for (let vmUuid of vmUuids) {
      this.focusedVms.push({
        id: vmUuid,
        uuid: vmUuid,
      });
    }
    this.reloadUi();
  }

  clearFocus = () => {
    this.selectForFocus([]);
  }

  switchFocus = (vm) => {
    let focusedVmsIds = this.focusedVms.map((vm) => vm.id);
    // @ts-ignore
    let currentVmId = vm.id;

    if (focusedVmsIds.indexOf(currentVmId) !== -1) {
      this.focusedVms = this.focusedVms.filter((vm) => vm.id !== currentVmId);
    } else {
      this.focusedVms.push(vm);
    }
    this.graphParameters.userMovedMap = false;
    this.reloadUi();
  }

  /**
   * This method export the data table into a CSV file
   */
  exportCSV() {
    let csvHeader = "Source, Destination, SourceHost, DestinationHost, ExchangedBytes, TotalPackets"
    let csvContent = csvHeader+"\n";
    for (let link of this.filteredLinks) {
      let lineValue = `${link.source.name}, ${link.target.name}, ${link.source.host.name}, ${link.target.host.name}, ${link.exchanged_bytes}, ${link.exchanged_packets}\n`;
      csvContent += lineValue;
    }

    let exportedFilename = 'netscope-dependencies.csv';
    let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  exportGraph = () => {
    let svgBody = this.svg.html();
    let svgCode = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${svgBody}</svg>`;
    let exportedFilename = 'netscope-dependencies.svg';

    let blob = new Blob([svgCode], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  createNetworkView(graphData): void {
    d3.select('div#divSvg').select("svg").remove();
    this.refreshNetworkView(graphData);
  }

  eraseNetworkView(): void {
    d3.select('div#divSvg')
        .selectAll('*')
        .remove();
  }

  refreshNetworkView(graphData): void {
    let svgHeight = document.getElementById('divSvg').clientHeight;
    let svgWidth = document.getElementById('divSvg').clientWidth;

    if (svgHeight == 0) {
      svgHeight = this.lastSvgHeight;
    } else {
      this.lastSvgHeight = svgHeight;
    }

    if (svgWidth == 0) {
      svgWidth = this.lastSvgWidth;
    } else {
      this.lastSvgWidth = svgWidth;
    }

    const viewCenterX = 0
    const viewCenterY = 0;

    // Compute position of vms
  	const vms = graphData.vms
	  .filter((vm) => vm.host !== undefined)
	  .filter((n) => this.mapDataFilters.virtualMachines[n.uuid])
	  .sort((vmA, vmB) => vmA.host.uuid.localeCompare(vmB.host.uuid));
    const vmsUuids = vms.map((vm) => vm.uuid);


	let hostsUuidsdIndex = new Map();
	vms.map((vm) => {
		hostsUuidsdIndex.set(vm.host.uuid, vm.host);
		if (vm.host.vms === undefined) {
			vm.host.vms = [];
		}
		vm.host.vms.push(vm);
	});
    let focusedVmsUuids = this.focusedVms.map((vm) => vm.uuid);

	// @ts-ignore
    const hosts = graphData.routers.filter((n) => this.mapDataFilters.hosts[n.uuid] && hostsUuidsdIndex.has(n.uuid));

    // Place links between nodes
    let links = this.replayData.links.filter((link) => vmsUuids.indexOf(link.source.uuid) !== -1 && vmsUuids.indexOf(link.target.uuid) !== -1);
    let directedLinks = this.replayData.directed_links.filter((link) => vmsUuids.indexOf(link.source.uuid) !== -1 && vmsUuids.indexOf(link.target.uuid) !== -1);
    this.filteredLinks = links;
    // this.filteredLinks = directedLinks;

    const totalTrafficBytes = links
        .map((l) => l.metrics)
        .reduce((a, b) => a.concat(b), [])
        .map((m) => m.exchanged_bytes)
        .reduce((a, b) => a + b, 0);

    // Initialize router radius
    let vmsOrbitCircleRadius = Math.min(svgWidth, svgHeight) / 2 * 0.90 - 40;

    if (vmsOrbitCircleRadius < 100) {
      vmsOrbitCircleRadius = 100.0;
    }

    let zoomRatio = 1.0;
    if (graphData.vms.length > 30) {
      let adjustedVmsOrbitCircleRadius = vms.length * 40 / (2 * Math.PI);
      zoomRatio = vmsOrbitCircleRadius / adjustedVmsOrbitCircleRadius;
      vmsOrbitCircleRadius = adjustedVmsOrbitCircleRadius;
    }

    // @ts-ignore
    let ratioMinSizeOverMaxSize = 10;

    // @ts-ignore
    d3.select('div#divSvg').select("svg").remove();
    const svg = d3.select('div#divSvg')
      .append('svg')
      .attr('width', svgWidth)
      .attr('height', svgHeight);
    this.svg = svg;

    // Add zoom
    svg.call(d3.zoom()
      .extent([[0, 0], [svgWidth, svgHeight]])
      .scaleExtent([0.1, ratioMinSizeOverMaxSize])
      .on("zoom", zoomed));

    const g = svg.append("g");

    const self = this;
    function zoomed({transform}) {
      self.graphParameters.userMovedMap = true;
      self.graphParameters.lastTransformation = transform;
      g.attr("transform", transform);
    }

    // Draw circle for vms
    g.append('g').append('circle')
      .attr('cx', viewCenterX)
      .attr('cy', viewCenterY)
      .attr('r', vmsOrbitCircleRadius)
      .attr('fill', 'transparent')
      .attr('stroke', 'rgba(200, 200, 200, 0.3)')
      .attr('stroke-width', '2px');

    // Add hosts UUID to vms
    let vmUuidToHostIndex = {};
    this.replayData.routers.map((router) => router.children.map((vmUuid) => {
      delete router.minAngle;
      delete router.maxAngle;
      vmUuidToHostIndex[vmUuid] = router;
    }))

    const angleIncrementVms = 2 * Math.PI / vms.length;
    let currentAngleVm = 0;
    vms.forEach((vm) => {
		vm.angle = currentAngleVm;
		vm.fx = viewCenterX + Math.cos(vm.angle) * vmsOrbitCircleRadius;
		vm.fy = viewCenterY + Math.sin(vm.angle) * vmsOrbitCircleRadius;
		vm.linefx = viewCenterX + Math.cos(vm.angle) * (vmsOrbitCircleRadius - this.nodeRadius - 10);
		vm.linefy = viewCenterY + Math.sin(vm.angle) * (vmsOrbitCircleRadius - this.nodeRadius - 10);

		// Define angle in host
		let host = vmUuidToHostIndex[vm.uuid];
		if (! host.hasOwnProperty("minAngle")) {
		  host.minAngle = vm.angle;
		}
		host.maxAngle = vm.angle;

		currentAngleVm += angleIncrementVms;
    });

    // D3's arcs is shifted compared to a trigonometric circle: while "0 deg rad" on a trigonometric circle is the
    // middle left point of the circle, on D3's arc "0 deg rad" it is the top middle point of the circle: to
    // make D3'es arc coordinates matche those of classic trigonometry we have to shift angles by PI / 2 on the right
    let shift = (1) * Math.PI / 2;

    // Adjust angles of hosts
    hosts.map((host) => {
      let coordinates = [host.minAngle + shift, host.maxAngle + shift];
      host.minAngle = Math.min(...coordinates) - angleIncrementVms / 4;
      host.maxAngle = Math.max(...coordinates) + angleIncrementVms / 4;
    });

    const defs = g.append('svg:defs');

    const data = [
      {id: 0, name: 'circle', color: '#ddd',  path: 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0', viewbox: '-6 -6 12 12'},
      {id: 1, name: 'square', color: '#ddd', path: 'M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z', viewbox: '-5 -5 10 10'},
      {id: 2, name: 'arrow', color: '#ddd', path: 'M 0,0 m -5,-5 L 2,0 L -5,5 Z', viewbox: '-10 -5 13 10'},
      {id: 2, name: 'arrow_reversed', color: '#ddd', path: 'M 0,0 m -5,-5 L 2,0 L -5,5 Z', viewbox: '-10 -5 13 10', reversed: true},
      {id: 3, name: 'stub', color: '#ddd', path: 'M 0,0 m -1,-5 L 1,-5 L 1,5 L -1,5 Z', viewbox: '-1 -5 2 10'},
      {id: 4, name: 'focus_circle', color: '#e79807', path: 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0', viewbox: '-6 -6 12 12'},
      {id: 5, name: 'focus_square', color: '#e79807', path: 'M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z', viewbox: '-5 -5 10 10'},
      {id: 6, name: 'focus_arrow', color: '#e79807', path: 'M 0,0 m -5,-5 L 2,0 L -5,5 Z', viewbox: '-10 -5 13 10'},
      {id: 6, name: 'focus_arrow_reversed', color: '#e79807', path: 'M 0,0 m -5,-5 L 2,0 L -5,5 Z', viewbox: '-10 -5 13 10', reversed: true},
      {id: 7, name: 'focus_stub', color: '#e79807', path: 'M 0,0 m -1,-5 L 1,-5 L 1,5 L -1,5 Z', viewbox: '-1 -5 2 10'}
    ];

    const margin = {top: 100, right: 100, bottom: 100, left: 100};

    const paths = g.append('svg:g')
        .attr('id', 'markers')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const marker = defs.selectAll('marker')
      .data(data)
      .enter()
      .append('svg:marker')
      .attr('id', function(d) {
        return 'marker_' + d.name;
      })
      .attr('markerHeight', 5)
      .attr('markerWidth', 5)
      .attr('markerUnits', 'strokeWidth')
      .attr('orient', function(d) {
        if (d.reversed === true) {
          return 'auto-start-reverse';
        }
        return 'auto';
      })
      .attr('refX', 0)
      .attr('refY', 0)
      .attr('viewBox', function(d) {
        return d.viewbox;
      })
      .append('svg:path')
      .attr('d', function(d) {
        return d.path;
      })
      .attr('fill', function(d, i) {
        return d.color;
      });

    // Place hosts and vms

    // First, place hosts
    const hostsNodes = g.append('g')
        .attr('class', 'hostsArc')
        .selectAll('g')
        .data(hosts)
        .enter().append('g');

    const hostsArcs = hostsNodes
        .append("path")
        .attr("d", (d) => {
          let arc = d3.arc()
              .innerRadius(vmsOrbitCircleRadius + 30)
              .outerRadius(vmsOrbitCircleRadius + 50)
              // @ts-ignore
              .startAngle(d.minAngle)
              // @ts-ignore
              .endAngle(d.maxAngle)
          // @ts-ignore
          return arc();
        })
        .attr("fill", "rgb(145,64,184)");

    //Create an SVG path (based on bl.ocks.org/mbostock/2565344)
    hostsNodes
        .append("path")
        // @ts-ignore
        .attr("id", (d) => `arc_text_${d.uuid}`) //Unique id of the path
        .attr("d", (d) => {
          // @ts-ignore
          let startAngle = d.minAngle;
          // @ts-ignore
          let endAngle = d.maxAngle;

          // let flip = (startAngle + endAngle) / 2 > 2 * Math.PI ? true : false;
          // let flip = startAngle >= Math.PI ? true : false;
          let flip = false;

          let radius = vmsOrbitCircleRadius + (flip ? 55 : 65);

          let arc = d3.arc()
              .innerRadius(radius)
              .outerRadius(radius+1)
              .startAngle(startAngle)
              .endAngle(endAngle)
          // @ts-ignore
          return arc();
        })
        .style("fill", "none");

    //Create an SVG text element and append a textPath element
    hostsNodes.append("text")
        .append("textPath") //append a textPath to the text element
        // @ts-ignore
        .attr("xlink:href", (d) => `#arc_text_${d.uuid}`) //place the ID of the path here
        .style("text-anchor","middle") //place the text halfway on the arc
        .attr("startOffset", (d) => {
          // @ts-ignore
          let startAngle = d.minAngle;
          // @ts-ignore
          let endAngle = d.maxAngle;

          let flip = (startAngle + endAngle) / 2 > Math.PI ? true : false;

          return flip ? "25%" : "75%";
        })
        .attr("stroke", "rgb(145,64,184)")
        // @ts-ignore
        .text((d) => d.name);

    g.append('g')
      .attr('class', 'links')
      .selectAll('g')
      .data(directedLinks)
      .enter().append('path')
      .attr('d', (d) => {
        // @ts-ignore
        let sourceNode = vms.filter((vm) => vm.uuid === d.source.uuid)[0];
        // @ts-ignore
        let targetNode = vms.filter((vm) => vm.uuid === d.target.uuid)[0];
        const x1 = sourceNode.linefx;
        const y1 = sourceNode.linefy;
        // @ts-ignore
        const x2 = viewCenterX;
        // @ts-ignore
        const y2 = viewCenterY;
        // @ts-ignore
        const x3 = targetNode.linefx;
        // @ts-ignore
        const y3 = targetNode.linefy;
        const result = `
             M ${x1} ${y1}
             Q ${x2} ${y2} ${x3} ${y3}`;
        // console.log(`* => ${result}`);
        return result;
      })
      .attr('marker-start', (d) => {
        // @ts-ignore
        let sourceNode = vms.filter((vm) => vm.uuid === d.source.uuid)[0];
        // @ts-ignore
        let targetNode = vms.filter((vm) => vm.uuid === d.target.uuid)[0];

        if (focusedVmsUuids.indexOf(sourceNode.uuid) != -1 || focusedVmsUuids.indexOf(targetNode.uuid) != -1) {
          return 'url(#marker_focus_arrow_reversed)';
        }

        return 'url(#marker_arrow_reversed)';
      })
      .attr('marker-end', (d) => {
          // @ts-ignore
          let sourceNode = vms.filter((vm) => vm.uuid === d.source.uuid)[0];
          // @ts-ignore
          let targetNode = vms.filter((vm) => vm.uuid === d.target.uuid)[0];

          if (focusedVmsUuids.indexOf(sourceNode.uuid) != -1 || focusedVmsUuids.indexOf(targetNode.uuid) != -1) {
            return 'url(#marker_focus_arrow)';
          }

          return 'url(#marker_arrow)';
        })
      .style('fill', `transparent`)
      .style('stroke', (d) => {
        // @ts-ignore
        let sourceNode = vms.filter((vm) => vm.uuid === d.source.uuid)[0];
        // @ts-ignore
        let targetNode = vms.filter((vm) => vm.uuid === d.target.uuid)[0];

        if (focusedVmsUuids.indexOf(sourceNode.uuid) != -1 || focusedVmsUuids.indexOf(targetNode.uuid) != -1) {
          return `#e79807`;
        }

        return `#DDDDDD`;
      })
      .style('stroke-width', (m) => {
        // @ts-ignore
        return `${Math.max(1, 20 * m.value / totalTrafficBytes)}`;
      });
    console.log(`graphData.links.length = ${graphData.links.length}`);

    // Second, place VMs
    const nodes = vms;
    const visuNode = g.append('g')
        .attr('class', 'nodes')
        .selectAll('g')
        .data(nodes)
        .enter().append('g')
        // @ts-ignore
        .attr('transform', (d) => 'translate(' + d.fx + ',' + d.fy + ')');


    let currentInstanceObject = this;
    visuNode.filter((d) => {
        // @ts-ignore
        return d.type === 'vm' || d.type === 'host';
      })
      .on("click", function (d, i) {
        if (currentInstanceObject.vmClickAction === "graph") {
          // @ts-ignore
          let [rawObjectTypeName, objectUuid] = i.id.split(":");
          // @ts-ignore
          let objectTypeName = i.type == "host" ? "SERVER" : "VM";
          currentInstanceObject.route.navigate(['god/resource/', objectUuid], { queryParams: { useResourceCountersFor: objectTypeName }} );
        } else if (currentInstanceObject.vmClickAction === "focus") {
          currentInstanceObject.switchFocus(i);
        }
      });

    const vmCircles = visuNode
        .append('g')
        .append('circle')
        .attr('r', this.nodeRadius)
        .style('stroke-width', 5)    // set the stroke width
        .style('stroke', (d) => {
          let focusedVmsIds = currentInstanceObject.focusedVms.map((vm) => vm.id);
          // @ts-ignore
          if (focusedVmsIds.indexOf(d.id) !== -1) {
            return '#e79807';
          }
          return '#007FCB';
        })
        .attr('fill', (d) => 'white');

    const iconsSvgCode = {
      "datacenter": "<path d=\"M26.5,4.08C22.77,4.08,19,5.4,19,7.91V9.5a18.75,18.75,0,0,1,2,.2V7.91c0-.65,2.09-1.84,5.5-1.84S32,7.27,32,7.91V18.24c0,.54-1.46,1.44-3.9,1.73v2c3.13-.32,5.9-1.6,5.9-3.75V7.91C34,5.4,30.23,4.08,26.5,4.08Z\"/><path d=\"M4,18.24V7.91c0-.65,2.09-1.84,5.5-1.84S15,7.27,15,7.91V9.7a18.75,18.75,0,0,1,2-.2V7.91c0-2.52-3.77-3.84-7.5-3.84S2,5.4,2,7.91V18.24C2,20.4,4.77,21.67,7.9,22V20C5.46,19.68,4,18.78,4,18.24Z\"/><path d=\"M18,10.85c-4.93,0-8.65,1.88-8.65,4.38V27.54c0,2.5,3.72,4.38,8.65,4.38s8.65-1.88,8.65-4.38V15.23C26.65,12.73,22.93,10.85,18,10.85Zm6.65,7.67c-.85,1-3.42,2-6.65,2A14.49,14.49,0,0,1,14,20v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v3.12c-.85,1-3.42,2-6.65,2a14.49,14.49,0,0,1-4-.53v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v2.29c0,.95-2.65,2.38-6.65,2.38s-6.65-1.43-6.65-2.38V15.23c0-.95,2.65-2.38,6.65-2.38s6.65,1.43,6.65,2.38Z\"/>",
      "network": "<path d=\"M26.58,32h-18a1,1,0,1,0,0,2h18a1,1,0,0,0,0-2Z\"/><path d=\"M17.75,2a14,14,0,0,0-14,14c0,.45,0,.89.07,1.33l0,0h0A14,14,0,1,0,17.75,2Zm0,2a12,12,0,0,1,8.44,3.48c0,.33,0,.66,0,1A18.51,18.51,0,0,0,14,8.53a2.33,2.33,0,0,0-1.14-.61l-.25,0c-.12-.42-.23-.84-.32-1.27s-.14-.81-.19-1.22A11.92,11.92,0,0,1,17.75,4Zm-3,5.87A17,17,0,0,1,25.92,10a16.9,16.9,0,0,1-3.11,7,2.28,2.28,0,0,0-2.58.57c-.35-.2-.7-.4-1-.63a16,16,0,0,1-4.93-5.23,2.25,2.25,0,0,0,.47-1.77Zm-4-3.6c0,.21.06.43.1.64.09.44.21.87.33,1.3a2.28,2.28,0,0,0-1.1,2.25A18.32,18.32,0,0,0,5.9,14.22,12,12,0,0,1,10.76,6.27Zm0,15.71A2.34,2.34,0,0,0,9.2,23.74l-.64,0A11.94,11.94,0,0,1,5.8,16.92l.11-.19a16.9,16.9,0,0,1,4.81-4.89,2.31,2.31,0,0,0,2.28.63,17.53,17.53,0,0,0,5.35,5.65c.41.27.83.52,1.25.76A2.32,2.32,0,0,0,19.78,20a16.94,16.94,0,0,1-6.2,3.11A2.34,2.34,0,0,0,10.76,22Zm7,6a11.92,11.92,0,0,1-5.81-1.51l.28-.06a2.34,2.34,0,0,0,1.57-1.79,18.43,18.43,0,0,0,7-3.5,2.29,2.29,0,0,0,3-.62,17.41,17.41,0,0,0,4.32.56l.53,0A12,12,0,0,1,17.75,28Zm6.51-8.9a2.33,2.33,0,0,0-.33-1.19,18.4,18.4,0,0,0,3.39-7.37q.75.35,1.48.78a12,12,0,0,1,.42,8.2A16,16,0,0,1,24.27,19.11Z\"/>",
      "switch": "<path d=\"M5.71,14H20.92V12H5.71L9.42,8.27A1,1,0,1,0,8,6.86L1.89,13,8,19.14a1,1,0,1,0,1.42-1.4Z\"/><rect x=\"23\" y=\"12\" width=\"3\" height=\"2\"/><rect x=\"28\" y=\"12\" width=\"2\" height=\"2\"/><path d=\"M27.92,17.86a1,1,0,0,0-1.42,1.41L30.21,23H15v2H30.21L26.5,28.74a1,1,0,1,0,1.42,1.4L34,24Z\"/><rect x=\"10\" y=\"23\" width=\"3\" height=\"2\"/><rect x=\"6\" y=\"23\" width=\"2\" height=\"2\"/>",
      "port": "<path d=\"M6.06,30a1,1,0,0,1-1-1V8h-2a1,1,0,0,1,0-2h4V29A1,1,0,0,1,6.06,30Z\"/><path d=\"M30.06,27h-25V9h25a3,3,0,0,1,3,3V24A3,3,0,0,1,30.06,27Zm-23-2h23a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1h-23Z\"/><rect x=\"22.06\" y=\"20\" width=\"6\" height=\"2\"/><rect x=\"22.06\" y=\"14\" width=\"6\" height=\"2\"/><path d=\"M19.06,22h-8V20h7V14h2v7A1,1,0,0,1,19.06,22Z\"/>",
      "host": "<path d=\"M18,24.3a2.48,2.48,0,1,0,2.48,2.47A2.48,2.48,0,0,0,18,24.3Zm0,3.6a1.13,1.13,0,1,1,1.13-1.12A1.13,1.13,0,0,1,18,27.9Z\"/><rect x=\"13.5\" y=\"20.7\" width=\"9\" height=\"1.44\"/><path d=\"M25.65,3.6H10.35A1.35,1.35,0,0,0,9,4.95V32.4H27V4.95A1.35,1.35,0,0,0,25.65,3.6Zm-.45,27H10.8V5.4H25.2Z\"/><rect x=\"12.6\" y=\"7.2\" width=\"10.8\" height=\"1.44\"/><rect x=\"12.6\" y=\"10.8\" width=\"10.8\" height=\"1.44\"/>",
      "vm": "<path d=\"M11,5H25V8h2V5a2,2,0,0,0-2-2H11A2,2,0,0,0,9,5v6.85h2Z\"/><path d=\"M30,10H17v2h8v6h2V12h3V26H22V17a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2V31a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V28h8a2,2,0,0,0,2-2V12A2,2,0,0,0,30,10ZM6,31V17H20v9H16V20H14v6a2,2,0,0,0,2,2h4v3Z\"/>"
    };
    const iconSvg = visuNode.append('g')
        .attr('viewBox', `0 0 ${this.nodeRadius} ${this.nodeRadius}`)
        .append('g')
        .attr('transform', `translate(-11, -10) scale(0.60)`)
        // @ts-ignore
        .html((d) => iconsSvgCode[d.type]);

    const labels = visuNode.append('text')
        // @ts-ignore
        .text((d) => d.name)
        // @ts-ignore
        .attr('x', (d) => d.name.length * 6 / 2 * (-1))
        .attr('y', this.nodeRadius + 15)
        .attr('style', 'text-shadow: 1px 1px 2px white, -1px -1px 2px white;');

    for (let d3elements of [iconSvg, visuNode]) {
      d3elements
        .on("mouseover", function (d3Node, data) {
          d3.select(this).style("cursor", "pointer");
          visuNode
              // @ts-ignore
              .filter((d2) => d2.type === 'vm')
              // @ts-ignore
              .filter((d2) => {
                // @ts-ignore
                return d2.uuid !== data.uuid;
              })
              .style("opacity", "25%");
        })
        .on("mouseout", function (d3Node, data) {
          d3.select(this).style("cursor", "default");
          visuNode
              // @ts-ignore
              .filter((d2) => d2.type === 'vm')
              // @ts-ignore
              .filter((d2) => d2.uuid !== data.uuid)
              .style("opacity", "100%");
        });
    }

    // step1: zoom out, and let d3 recompute positions and sizes
    const [x, y, k] = [0, 0, zoomRatio];
    g.attr('transform', 'translate(' + x + ',' + y + ') scale(' + k + ')');
    this.svg.call(
        d3.zoom().transform,
        d3.zoomIdentity.translate(x, y).scale(k)
    );

    // step2: center the main group by translating in the middle of the size difference
    // between the group and the svg
    let [x2, y2, k2] = [
      svgWidth / 2,
      svgHeight / 2,
      zoomRatio
    ];

    if (this.graphParameters.userMovedMap) {
      [x2, y2, k2] = [this.graphParameters.lastTransformation.x, this.graphParameters.lastTransformation.y, this.graphParameters.lastTransformation.k];
    }

    g.attr('transform', 'translate(' + x2 + ',' + y2 + ') scale(' + k2 + ')');
    this.svg.call(
        d3.zoom().transform,
        d3.zoomIdentity.translate(x2, y2).scale(k2)
    );
  }
}

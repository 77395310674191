export interface Json {
    dcInfraMin: any;
    rpSynthesis: any;
    clusterSynthesis: any;
    clustersPrediction: any;
    hostInfo: any;
    hostConsover: any;
    hostSynthesis: any;
    hostsPrediction: any;
    vmInfo: any;
    vmConsover: any;
    vmComputeStorage: any;
    vmComputeStorageReco: any;
    vmSynthesis: any;
    costAllocated: any;
    t1: any;
    t2: any;
    opportunities: any;
    t3a: any;
    t3b: any;
    t4: any;
    t5: any;
    t6: any;
    // priceAws;
    // priceAzure;
    // priceGcp;
    // priceOvh;
    priceExchangeRates;
    priceUpdateInfo;
    storageOverviewsDatastoreData;
    storageOverviewsVmData;
    storageOverviewsDatastoreVmData;
    version;
    kpiHealth: any;
    dcviewTreeData;
    dcviewTreeStorageData;
    greenitPower;
}

export enum JSONTarget {
    DC_INFRA_MIN = "dc-infra-min",
    RP_SYNTHESIS = "rp-synthesis",
    RP_PREDICTION = "rp_prediction",
    CLUSTER_SYNTHESIS  = "cluster-synthesis",
    CLUSTERS_PREDICTION  = "clusters_prediction",
    HOST_INFO = "server-info",
    HOST_CONSOVER = "server-consover",
    HOST_SYNTHESIS = "server-synthesis",
    HOSTS_PREDICTION = "servers_prediction",
    VM_INFO = "vm-info",
    VM_CONSOVER = "vm-consover",
    VM_SYNTHESIS = "vm-synthesis",
    COST_ALLOCATED = "cost-allocated",
    T1 = "vm-pie",
    T2 = "prediction",
    OPPORTUNITIES = "greenit-opportunities",
    T3A = "greenit-opportunities-after",
    T3B = "greenit-opportunities-before",
    T4 = "dc-summary",
    T5 = "dc-capacity",
    T6 = "cost-meta",
    T8 = "greenit-predictions",
    T8_ALL = "greenit-predictions-all",
    STORAGE_OVERVIEWS_DS = "sto-ds",
    STORAGE_OVERVIEWS_VM = "sto-vm",
    STORAGE_OVERVIEWS_DS_VM = "treemap-sto",
    KPI_HEALTH = "kpi-health",
    CAPA_INFRA = "infra_prediction",
    CAPA_SIMU = "simulation_prediction",
    CAPA_SIMU_CONFIG = "simulation_config",
    CAPA_VM = "capavms",
    CAPA_SRV = "capasrv",
    CAPA_DS = "capads",
    GREENIT_SERVERS = "greenit-servers",
    GREENIT_RECO = "greenit-reco",
    GREENIT_RESIZE = "greenit-resize",
    GREENIT_MONTHLY_EVOL = "greenit-monthly-evol",
    GREENIT_POWER = "greenit_power",
    CLOUD_AWS = "aws",
    CLOUD_AZURE = "az",
    CLOUD_GCP = "gcp",
    CLOUD_OVH = "ovh",
    CLOUD_RATES = "exchange-rates",
    CLOUD_UPDATE_INFO = "update-info",
    COST_CONSUMED = "cost-consumed",
    COST_WASTED = "cost-wasted",
    HOST_VIEW = "server-view",
    HOST_VIEW_SCOPE = "server-view-scope",
    HOST_VIEW_EXAMPLE = "server-view-example",
    FD_VIEW = "folder-view",
    FD_VIEW_EXAMPLE = "folder-view-example",
    RP_VIEW = "rp-view",
    RP_VIEW_EXAMPLE = "rp-view-example",
    TAG_VIEW = "tag-view",
    TAG_VIEW_EXAMPLE = "tag-view-example",
    DS_VIEW = "datastore-view",
    RECO_META = "reco-meta",
    RECO_VM = "reco-vm",
    RECO_SERVER = "reco-server",
    TROUBLE_MAP = "trouble-map",
    KPI_META = "kpi-meta",
    KPI_ACTIVITY = "kpi-activity",
    KPI_CAPACITY = "kpi-capacity",
    KPI_COST = "kpi-cost",
    VM_COMPUTE_STORAGE = "vm-compute-storage",
    VM_COMPUTE_STORAGE_RECO = "vm-compute-storage-reco",
    DCVIEW_TREE_DATA = "treemap",
    DCVIEW_TREE_STORAGE_DATA = "treemap-sto",
    VDC_INFOS = "vdc-info",
    VDC_SYNTHESIS = "vdc-synthesis",
    VDC_PREDICTION = "vdc_prediction",
    VIRT_INDEX="virtindex",
    CLOUD_METADATA =  "cloud_meta",
    CLOUD_INSTANCE = "cloud_instance",
    CLOUD_RESIZING = "cloud_instance_current_resizing",
    CLOUD_BEHAVIOR = "cloud_instance_current_behavior",
    CLOUD_INSTANCE_CURRENT_COST = "cloud_instance_current_cost",
    CLOUD_GLOBAL_COST = "cloud_global_months_cost",
    CLOUD_INSTANCE_MONTH_COST = "cloud_instance_months_cost",
    CLOUD_MONTH = "cloud_month",
    CLOUD_SAVINGSPLAN_SOLUTION = "cloud_savingsplan_solution",
    CLOUD_KPI_CREDITS = "cloud_kpi_cpucredits",
    CLOUD_KPI_SAVINGSPLAN = "cloud_kpi_savingsplan",
    CLOUD_KPI_EVOLUTION = "cloud_kpi_evolution",
    CLOUD_GRAPH_REGION = "cloud_graph_region",
    CLOUD_GRAPH_REGION2 = "cloud_graph_region2",
    CLOUD_OVERVIEW_EVOLUTION = "cloud_overview_evolution",
    CLOUD_AGE_STRUCTURE = "cloud_age_structure",
    CLOUD_INSTANCE_COUNTER = "cloud_instance_current_counter",
    CLOUD_OPP_SPOT = "cloud_opp_spot",
    CLOUD_ORG_VIEW = "cloud_org_view",
    CLOUD_CO2 = "cloud_instance_co2",
    CLOUD_GLOBAL_CO2 = "cloud_global_months_co2",
    CLOUD_INSTANCE_MONTH_CO2 = "cloud_instance_months_co2",
}

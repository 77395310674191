import { Component, OnInit } from '@angular/core';

import { ClrDatagridSortOrder } from '@clr/angular';

import { JSONTarget, Message } from '@app/model';

import { JsonloaderService, ShareService } from '@app/services';


@Component({
  selector: 'app-resizing-detail',
  templateUrl: './resizing-detail.component.html',
  styleUrls: ['./resizing-detail.component.css']
})
export class ResizingDetailComponent implements OnInit {

  message: Message;

  resizes: any = [];

  dataOrder = ClrDatagridSortOrder.ASC;

  selected_resize: any;

  selected_res: any;

  isSelected: boolean = false;


  constructor(
    private json_svc: JsonloaderService,
    private message_svc: ShareService
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);
    let account = this.message.cloudAccount;
    let region_str: string = '';
    region_str = this.message.cloudRegion.replace(/-/g,"");

    this.json_svc.getData('root','cloud_' + account + '_' + region_str, JSONTarget.CLOUD_RESIZING).subscribe(
      data => {
        //this.resizes = data;
        this.filterIts(data);
      }
    );
  }

  resChanged(event: any): void {

    if(event != null) {
      this.selected_resize = this.selected_res;
      this.isSelected = true;
    }
  }

  exportResizing() {

    let res = ["ID", "Name", "Burstable mode", "Gain"].join(',') + '\n';
                res += Object.values<any>(this.resizes).map(resize =>
                        [resize.instanceId, resize.name, resize.mode, resize.bestGain].join(",")
                ).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'opportunities_resizing.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  private filterIts(data: any): void {

    for(let obj of data) {
      if(obj.type == "its")
        this.resizes.push(obj);
    }
  }
}

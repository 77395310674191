import { Component, OnInit } from '@angular/core';

import { ClrDatagridSortOrder } from '@clr/angular';

import { JSONTarget, Message } from '@app/model';

import { JsonloaderService, ShareService } from '@app/services';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import * as moment from 'moment';


@Component({
  selector: 'app-instances-co2',
  templateUrl: './instances-co2.component.html',
  styleUrls: ['./instances-co2.component.css']
})
export class InstancesCo2Component implements OnInit {

  isDcscope = true;
	isCo2scope = false;

  highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart | null;
  chartOptions: Highcharts.Options = {};

  dataOrder = ClrDatagridSortOrder.DESC;
  dataOrder2 = ClrDatagridSortOrder.DESC;

  isSelected: boolean = false;

  message: Message;

  region: string = '';

  account: string = '';

  co2s: any = [];

  instancesAll: any = [];

  instances: any = [];

  co2InstancesYear: any = [];

  months: any = [];

  model: any = {
    isModel: false,
    selectedMonth: ''
  };

  graphClass = 'clr-col-lg-4 clr-col-md-4 clr-col-12';
  isYearly: boolean = false;
  isCo2Year: boolean = false;
  isVmYear: boolean = false;

  selected_instance: any;

  isReady: boolean = false;


  constructor(private json_svc: JsonloaderService, private message_svc: ShareService) { 
  }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);

    this.isCo2scope = this.message.isCO2Scope;
	  this.isDcscope = this.message.isDCScope;

    //console.log(this.message.cloudAccount + " :: " + this.message.cloudRegion);
    this.account = this.message.cloudAccount;
    this.region = this.message.cloudRegion;
    let region_str: string = '';
    region_str = this.region.replace(/-/g,"");

    //console.log(this.account + " :: " + region_str);
    this.json_svc.getData('root','cloud_' + this.account + '_' + region_str, JSONTarget.CLOUD_MONTH).subscribe(
      data => {
        this.months = data;
        this.json_svc.getData('root','cloud_' + this.account + '_' + region_str, JSONTarget.CLOUD_GLOBAL_CO2).subscribe(
          data2 => {
            this.co2s = data2;
            this.json_svc.getData('root','cloud_' + this.account + '_' + region_str, JSONTarget.CLOUD_CO2).subscribe(
              data3 => {
                this.instancesAll = data3;
                let val : string = '';
                val = this.months[0].name;
                this.model.selectedMonth = val;
                for(let obj of this.instancesAll[val.replace(/ /g,"_")]) {
                  this.instances.push(obj);
                }
                this.json_svc.getData('root','cloud_' + this.account + '_' + region_str, JSONTarget.CLOUD_INSTANCE_MONTH_CO2).subscribe(
                  data4 => {
                      this.co2InstancesYear = data4;
                      this.loadGraph(false, true, false);
                  }
                );
              }
            );
          }
        );
      }
    );
  }

  updateList(): void {

    let val: any = this.model.selectedMonth.replace(/ /g,"_");
    this.instances = [];
    if(this.model.isModel) {
      for(let obj of this.instancesAll[val]) {
        if(obj.state != "destroy")
          this.instances.push(obj);
      }
    } else {
      for(let obj of this.instancesAll[val]) {
          this.instances.push(obj);
      }
    }
  }

  loadVmGraph(event): void {

    this.isYearly = false;
    this.model.selectedId = event.identifier;
    this.model.selectedName = event.name;
    this.isCo2Year = true;
    this.isReady = false;
    this.graphClass = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
    setTimeout(() =>this.loadGraph(true, false, false), 100);
  }

  loadMonth(event: any) {

    this.model.isModel = false;
    this.instances = [];
    let val: any = event.replace(/ /g,"_");
    for(let obj of this.instancesAll[val]) {
      this.instances.push(obj);
    }
  }

  reloadGraph(): void {

    this.isReady = false;
    if(this.isCo2Year) {
      this.graphClass = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
      setTimeout(() =>this.loadGraph(true, true, false), 100);
    } else {
      this.graphClass = 'clr-col-lg-4 clr-col-md-4 clr-col-12';
      if(this.isYearly)
        setTimeout(() =>this.loadGraph(false, true, true), 100);
      else
        setTimeout(() =>this.loadGraph(false, true, false), 100);
    }
  }

  exportInstances() {

    let res = ["ID", "Name", "Account", "Type", "State", "Direct power (Wh)", "Direct CO2 emission (Kg CO2eq)", "Embodied emission (Kg CO2eq)"].join(',') + '\n';
                res += Object.values<any>(this.instances).map(instance =>
                        [instance.identifier, instance.name, this.message.cloudAccount, instance.instanceType, 
                          instance.state, instance.direct_power, instance.direct_co2, instance.grey_co2].join(",")
                ).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'overview_greenit.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  private loadGraph(isAllMonths: boolean, isRegion: boolean, isYearly: boolean): void {

    let title: string = '';
    let titleSize: string = '20px';
    let grid: boolean = false;
    let cat: any = [];
    let mergedMeasurements: any = [];
    let data: any = [];
    let data2: any = [];
    let dataDirect: any = {
      "name": "direct CO2 emission",
      "data": data
    };
    let dataGrey: any = {
      "name": "embodied CO2 emission",
      "data": data2
    };
    mergedMeasurements.push(dataDirect);
    mergedMeasurements.push(dataGrey);

    let buttonName: boolean = false;

    if(isYearly) {
      let data_year = this.buildYearEmission();
      title = 'region (' + this.region + ')';
      titleSize = '16px';
      cat[0] = 'previous year';
      cat[1] = 'current year';
      data[0] = data_year[0];
      data[1] = data_year[1];
      data2[0] = data_year[2];
      data2[1] = data_year[3];
    } else {
      if(isRegion) {
        if(!isAllMonths) {
          title = 'region (' + this.region + ')';
          titleSize = '16px';
          cat[0] = 'previous month';
          cat[1] = 'current month';
          data[0] = Math.round(this.co2s[0].previous_direct_co2);
          data[1] = Math.round(this.co2s[0].current_direct_co2);
          data2[0] = Math.round(this.co2s[0].previous_grey_co2);
          data2[1] = Math.round(this.co2s[0].current_grey_co2);
        } else {
          title = 'region (' + this.region + ')';
          titleSize = '20px';
          let j = 0;
          for(let i=0; i < this.co2s.length; i++) {
            if(i > 0) {
              cat[j] = this.co2s[i].month;
              data[j] = Math.round(this.co2s[i].direct);
              data2[j] = Math.round(this.co2s[i].grey);
              ++j;
            }
          }
        }
      } else {
        for(let i=0; i < this.co2InstancesYear.length; i++) {
          if(this.co2InstancesYear[i].id == this.model.selectedId) {
            title = this.model.selectedId + ' (' + this.model.selectedName + ')';
            titleSize = '20px';
            for(let j=0; j < this.co2InstancesYear[i].imc.length; j++) {
              cat[j] = this.co2InstancesYear[i].imc[j].name;
              data[j] = Math.round(this.co2InstancesYear[i].imc[j].directCo2*100)/100;
              data2[j] = Math.round(this.co2InstancesYear[i].imc[j].greyCo2*100)/100;
            }
          }
        }
      }

      if(isAllMonths) {
        buttonName = true;
        grid = true;
      } else {
        buttonName = false;
        grid = false;
      }
    }

    this.chartOptions = {
      credits: {
        enabled: false
      },
      title: {
        text: title,
        style: {
          color: 'grey',
          fontSize: titleSize
        }
      },
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: cat
      },
      yAxis: {
        visible: grid,
        min: 0,
        title: {
            text: 'Total kgCO2eq emission'
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: ( // theme
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || 'gray'
            }
        }
      },
      tooltip: {
        shared: false,
        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} kgCO2eq</b><br/>'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            crop: false
          }
        }
      },
      lang: {
        contextButtonTitle: "Export graph"
      },
      exporting: {
        buttons: {
          contextButton: {
            className: "addLink",
            enabled:buttonName,
            symbol: 'download',
            text: "download",
            symbolStroke: "#0072A3",
            x: -10
          }
        }
      },
      series: mergedMeasurements
    };
    this.isReady = true;
  }

  private buildYearEmission(): any {

    let data_year: any = [];
    let now = moment().format('YYYY');
    let last = moment().subtract(1, 'years').format('YYYY');
    let cur_direct: number = 0;
    let cur_grey: number = 0;
    let pre_direct: number = 0;
    let pre_grey: number = 0;

    for(let i=0; i < this.co2s.length; i++) {
      if(i > 0) {
        let month = this.co2s[i].month.split(" ")[0];
        if(month == now) {
          cur_direct += Math.round(this.co2s[i].direct);
          cur_grey += Math.round(this.co2s[i].grey);
        } else if(month == last) {
          pre_direct += Math.round(this.co2s[i].direct);
          pre_grey += Math.round(this.co2s[i].grey);
        }
      }
    }
    data_year[0] = pre_direct;
    data_year[1] = cur_direct;
    data_year[2] = pre_grey;
    data_year[3] = cur_grey;

    return data_year;
  }
}

<div style="width: 100%; height: 400px; display: block;">
    <highcharts-chart
            [Highcharts]="Highcharts"
            [constructorType]="'stockChart'"
            style="width: 100%; height: 100%; display: block;"
            [(update)]="updateFlag"
            [oneToOne]="true"
            [callbackFunction]="chartCallback"
            [runOutsideAngular]=true
            [options]="chartOptions"
            [hidden]="!displayGraph"
    >
    </highcharts-chart>

    <div *ngIf="!displayGraph" class="alert alert-warning alert-sm" role="alert" style="margin: 20px;">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                </div>
                <div class="alert-text">
                    {{ noDataMessage }}
                </div>
            </div>
        </div>
</div>



<div class="clr-row" *ngIf="message.creditSelected == 'co2'">
    <div class="card" *ngIf="!isSelected">
        <div class="card-header" *ngIf="isDcscope">
			<span style="color:grey;font-size:26px;">
				<fa-icon [icon]="['fas', 'leaf']" style="color:grey;font-size:14px;"></fa-icon>
				GREEN IT
			</span>
			<span style="float: right;" ngClass="addLink" (click)="message.creditSelected = ''">
				<clr-icon shape="times" size="24"></clr-icon>
			</span>
        </div>
		<div class="card-header" *ngIf="isCo2scope">
			<span style="color:grey;">
				<clr-icon shape="tree"></clr-icon>
				CO2 Emissions
			</span>
        </div>
        <div class="card-block">
			<div class="clr-row">
                                <div [ngClass]="graphClass">
					<clr-toggle-container clrInline *ngIf="!isCo2Year">
						<clr-toggle-wrapper>
							<input type="checkbox" clrToggle name="isYearly" value="isYearly" [(ngModel)]="isYearly" (ngModelChange)="reloadGraph()" />
							<label>
								yearly view
							</label>
						</clr-toggle-wrapper>
						<clr-control-helper>display current & previous year</clr-control-helper>
					</clr-toggle-container>
                                        <highcharts-chart
						id="spec-cloud-provider-aws-co2graph"
                                                [Highcharts] = "highcharts"
                                                [options] = "chartOptions"
                                                [constructorType]="'chart'"
                                                style = "width: 100%;display: block;" *ngIf="isReady">
                                        </highcharts-chart>
					<clr-toggle-container clrInline *ngIf="!isYearly">
						<clr-toggle-wrapper>
							<input type="checkbox" clrToggle name="isCo2Year" value="isCo2Year" [(ngModel)]="isCo2Year" (ngModelChange)="reloadGraph()" />
							<label>
								the last months
							</label>
						</clr-toggle-wrapper>
						<clr-control-helper>display month by month</clr-control-helper>
					</clr-toggle-container>
                                </div>
				<div class="clr-col-lg-8 clr-col-md-8 clr-col-12" *ngIf="!isCo2Year">
					<div class="clr-row">
						<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
							<div class="clr-control-container">
								<div class="clr-select-wrapper">
									<select class="clr-select" 
											[(ngModel)]="model.selectedMonth"
											(ngModelChange)="loadMonth($event)">
										<option *ngFor="let month of months">{{month.name}}</option>
									</select>
								</div>
								<span class="clr-subtext">select month to display</span>
							</div>
						</div>
						<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
							<clr-checkbox-container>
								<clr-checkbox-wrapper>
									<input id="overview-instance-detail-cb" type="checkbox" 
										clrCheckbox name="OverviewCo2DetailCb" 
										[(ngModel)]="model.isModel"
										(change)="updateList()" />
									<label>current model</label>
								</clr-checkbox-wrapper>
							</clr-checkbox-container>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<button class="btn btn-link" style="position:relative;float:right;" (click)="exportInstances()">
								Export Instances
							</button>
						</div>
					</div>
					<div class="datagrid-limit-height" >
						<clr-datagrid [(clrDgSingleSelected)]="selected_instance" (clrDgSingleSelectedChange)="loadVmGraph($event)">
							<clr-dg-column [clrDgField]="'identifier'"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'message.cloudAccount'"><ng-container *clrDgHideableColumn="{hidden: false}">Account</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'instanceType'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'state'"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'runH'"><ng-container *clrDgHideableColumn="{hidden: false}">Running hours</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'direct_power'" [clrDgSortOrder]="dataOrder2"><ng-container *clrDgHideableColumn="{hidden: false}">Direct power (Wh)</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'direct_co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Direct CO2 emission (kg CO2eq)</ng-container></clr-dg-column>
							<clr-dg-column [clrDgField]="'grey_co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Embodied emission (kg CO2eq)</ng-container></clr-dg-column>
							<clr-dg-row *clrDgItems="let instance of instances" [clrDgItem]="instance">
								<clr-dg-cell>{{instance.identifier}}</clr-dg-cell>
								<clr-dg-cell>{{instance.name}}</clr-dg-cell>
								<clr-dg-cell>{{message.cloudAccount}}</clr-dg-cell>
								<clr-dg-cell>{{instance.instanceType}}</clr-dg-cell>
								<clr-dg-cell>{{instance.state}}</clr-dg-cell>
								<clr-dg-cell>{{instance.runH}}</clr-dg-cell>
								<clr-dg-cell>{{instance.direct_power.toFixed(2)}}</clr-dg-cell>
								<clr-dg-cell>{{instance.direct_co2.toFixed(2)}}</clr-dg-cell>
								<clr-dg-cell>{{instance.grey_co2.toFixed(2)}}</clr-dg-cell>
							</clr-dg-row>
							<clr-dg-footer>
								<clr-dg-pagination #pagination [clrDgPageSize]="10">
									<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
										entries per page
									</clr-dg-page-size>
									{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
								</clr-dg-pagination>
							</clr-dg-footer>
						</clr-datagrid>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

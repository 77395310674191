<div class="card-text" style="position: relative;">
    <div *ngIf="!(!doubleClickSelectDay && selectionPosition.plotSelection.type != 'none')" class="alert alert-info alert-sm" role="alert">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                </div>
                <span class="alert-text">
                    <span *ngIf="doubleClickSelectDay && selectionPosition.plotSelection.type == 'daily'">You visualising data for an entire day. </span>
                    <span *ngIf="selectionPosition.plotSelection.type !== 'hourly'">Click on any cell to visualise data of the corresponding hour. </span>
                    <span *ngIf="doubleClickSelectDay && (selectionPosition.plotSelection.type == 'none' || selectionPosition.plotSelection.type == 'hourly')">Click again on the selected cell to view the data for the whole day.</span>
                </span>
            </div>
        </div>
        <button type="button" class="close" aria-label="Close">
            <clr-icon aria-hidden="true" shape="close"></clr-icon>
        </button>
    </div>
    <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="overallTrafficChartOptions"
            [(update)]="updateHeatmapChartFlag"
            [oneToOne]="true"
            [callbackFunction]="callbackHeatmapChartRef"
            style="width: 100%; height: 100%; max-height: {{maxHeight}}px; display: block;">
    </highcharts-chart>
</div>

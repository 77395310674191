import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { DataService, GreenitService, ShareService } from '@app/services';

import { FilterMgt, Message } from '@app/model';

import * as Highcharts from 'highcharts';

import heatmap from 'highcharts/modules/heatmap';
heatmap(Highcharts);

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import * as moment from 'moment';


@Component({
  selector: 'app-monthlycal',
  templateUrl: './monthlycal.component.html',
  styleUrls: ['./monthlycal.component.css']
})
export class MonthlycalComponent implements OnInit {

  message: Message;

  monthly_range_date: string = '';

  isNoPrevData: boolean = false;

  isNoNextData: boolean = true;

  isMinMaxValue: boolean = true;

  maxEndTime: number = 0;

  data_cache: any;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;

  isReady: boolean = false;


  constructor(
	private counter_svc: DataService,
	private greenit_svc: GreenitService,
	private message_svc: ShareService) {
  }

  ngOnInit(): void {
	this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  ngAfterViewInit(): void {

	this.maxEndTime = this.message.maxTimeFilter/1000;
	this.callMonthlyApi();
  }

  previous():void {

	var monthLastDay = null;
	monthLastDay = this.maxEndTime - 1;
	const lastDayPreviousMonth = moment.unix(monthLastDay).subtract(1, 'months').endOf('month');
	// CONVERT STRING TO NUMBER
	var y: number = +(lastDayPreviousMonth.valueOf()/1000).toFixed();
	this.maxEndTime = y;
	this.callMonthlyApi();
	this.isNoNextData = false;
  }

  next(): void {

	var monthLastDay = null;
	monthLastDay = this.maxEndTime - 1;
	const lastDayNextMonth = moment.unix(monthLastDay).add(1, 'months').endOf('month');
	var y: number = +(lastDayNextMonth.valueOf()/1000).toFixed();
	this.maxEndTime = y;
	if(this.maxEndTime < (this.message.maxTimeFilter/1000)) {
		this.callMonthlyApi();
	} else if(this.maxEndTime = (this.message.maxTimeFilter/1000)) {
		this.callMonthlyApi();
		this.isNoNextData = true;
	} else {
		this.isNoNextData = true;
	}
	this.isNoPrevData = false;
  }

  updateMonthlyOptions(event): void {

	if(this.data_cache.length > 0) {
		if(event.target.id == "monthly-radio-100")
			this.loadCalendar(this.data_cache, false);
		else
			this.loadCalendar(this.data_cache, true);
	}
  }

  private setRangeDate(): void {

	var monthLastDay = null;
	monthLastDay = this.maxEndTime - 1;
	const lastDay = moment.unix(monthLastDay).add(0, 'seconds')
	const firstDay = moment.unix(monthLastDay).startOf('month')

	let dateFormatPattern1 = 'MMMM Do'
	let dateFormatPattern2 = dateFormatPattern1 + ' YYYY'
	if (firstDay.year() != lastDay.year()){
        	dateFormatPattern1 += ' YYYY'
	}
	this.monthly_range_date = firstDay.format(dateFormatPattern1) + ' - ' + lastDay.format(dateFormatPattern2);
  }

  private callMonthlyApi(): void {

	this.message.waiting = true;
	var monthLastDay = null;
	monthLastDay = this.maxEndTime - 1;
	const lastDay = moment.unix(monthLastDay).add(1, 'seconds');
	const firstDay = moment.unix(monthLastDay).startOf('month');
	const endOfMonth = moment.unix(monthLastDay).endOf('month');
	let start: number = 0;
	let end: number = 0;
	
	if(firstDay.valueOf() < this.message.minTimeFilter) {
		start = this.message.minTimeFilter/1000;
		this.isNoPrevData = true;
	} else {
		start = firstDay.valueOf()/1000
	}

	if(lastDay.valueOf() > this.message.maxTimeFilter)
		end = this.message.maxTimeFilter/1000;
	else
		end = lastDay.valueOf()/1000;

	if(start > 0 && end > 0) {
		if(this.message.powerUsageEnv == "vmware") {
			let filter: FilterMgt = this.getFilter(this.message.currentFilter);
			let wd: boolean = false;
			if(filter.work_days == 1)
				wd = true;

			this.counter_svc.getData(
				this.message.currentUuid,
				start,
				end,
				this.message.calcons_counter,
				this.message.calcons_rollup,
				this.message.currentType,
				wd).pipe(first()).subscribe(
					data => {
						this.data_cache = data;
						this.setRangeDate();
						this.loadCalendar(data, this.isMinMaxValue);
						this.message.waiting = false;
					},
					error=> {
						console.log(error);
						this.message.waiting = false;
					}
			);
		} else {
			let uuids: string[] = [];
			uuids.push(this.message.currentUuid);
			let powerInfo: any = {
				account: this.message.cloudAccount,
				start: firstDay.valueOf(),
				end: lastDay.valueOf(),
				granularity: 'HOURLY',
				data: uuids
			};
			this.greenit_svc.getPowerRegion(powerInfo).subscribe(
				data => {
					let values: any = [];
					for(let i in data) {
						let val: any = {
							time: data[i].TIMEAGO/1000,
							avg_power_float: data[i].POWER				
						};
						values.push(val);
					}
					this.setRangeDate();
					this.data_cache = values;
					this.loadCalendar(values, this.isMinMaxValue);
					this.message.waiting = false;
				},
				error => {
				  this.message.waiting = false;
				  if(error != null)
					console.log(error);
				}
			);
		}
	}
  }

  private loadCalendar(data, isMaxVal): void {

	var maxVal = 100;
	var v = {}
	var tmp = 0;
	var endFilter= this.maxEndTime;

	let cpucap: number = 0;
	if(this.message.currentType == "VM")
		cpucap = this.message.vmSynth.vcpu;
	else if(this.message.currentType == "SERVER")
		cpucap = this.message.hostSynth.cpucap;
	else if(this.message.currentType == "CLUSTER")
		cpucap = this.message.clusterSynth.cpucap;

	let unit: string = '%';

	data.forEach(measurement => {
		let timestamp = parseInt(measurement.time);
		if (!v[timestamp]){
			v[timestamp] = []
		}
		switch(this.message.calcons_counter) {
			case "cpu_usage":
				switch(this.message.calcons_rollup) {
					case "avg":
						v[timestamp].push(measurement.avg_cpu_usage / 10);
						break;
					case "max":
						v[timestamp].push(measurement.max_cpu_usage / 10);
						break;
					case "min":
						v[timestamp].push(measurement.min_cpu_usage / 10);
						break;
					default:
						break;
				}
				break;
			case "ram_usage":
				switch(this.message.calcons_rollup) {
					case "avg":
						v[timestamp].push(measurement.avg_ram_usage / 10);
						break;
					case "max":
						v[timestamp].push(measurement.max_ram_usage / 10);
						break;
					case "min":
						v[timestamp].push(measurement.min_ram_usage / 10);
						break;
					default:
						break;
				}
				break;
			case "cpu_ready":
				switch(this.message.calcons_rollup) {
					case "avg":
						if(cpucap > 0)
							v[timestamp].push(measurement.avg_cpu_ready / 200 / cpucap);
						break;
					case "max":
						if(cpucap > 0)
							v[timestamp].push(measurement.max_cpu_ready / 200 / cpucap);
						break;
					case "min":
						if(cpucap > 0)
							v[timestamp].push(measurement.min_cpu_ready / 200 / cpucap);
						break;
					default:
						break;
				}
				break;
			case "power_float":
				unit = 'W';
				switch(this.message.calcons_rollup) {
					case "avg":
						v[timestamp].push(measurement.avg_power_float);
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
		if(isMaxVal) {
			switch(this.message.calcons_counter) {
				case "cpu_usage":
					switch(this.message.calcons_rollup) {
						case "avg":
							tmp = Math.max(tmp, measurement.avg_cpu_usage);
							break;
						case "max":
							tmp = Math.max(tmp, measurement.max_cpu_usage);
							break;
						case "min":
							tmp = Math.max(tmp, measurement.min_cpu_usage);
							break;
						default:
							break;
					}
					break;
				case "ram_usage":
					switch(this.message.calcons_rollup) {
						case "avg":
							tmp = Math.max(tmp, measurement.avg_ram_usage);
							break;
						case "max":
							tmp = Math.max(tmp, measurement.max_ram_usage);
							break;
						case "min":
							tmp = Math.max(tmp, measurement.min_ram_usage);
							break;
						default:
							break;
					}
					break;
				case "cpu_ready":
					switch(this.message.calcons_rollup) {
						case "avg":
							tmp = Math.max(tmp, measurement.avg_cpu_ready);
							break;
						case "max":
							tmp = Math.max(tmp, measurement.max_cpu_ready);
							break;
						case "min":
							tmp = Math.max(tmp, measurement.min_cpu_ready);
							break;
						default:
							break;
					}
					break;
				case "power_float":
					unit = 'W';
					switch(this.message.calcons_rollup) {
						case "avg":
							tmp = Math.max(tmp, measurement.avg_power_float);
							break;
						default:
							break;
					}
					break;
				default:
					break;
			}
		}
	});
	if(isMaxVal) {
		if (this.message.calcons_counter == "cpu_ready") {
			if(cpucap > 0)
        		maxVal = Math.round(tmp / 200 / cpucap);
		} else if (this.message.calcons_counter == "power_float") {
			maxVal = Math.round(tmp);
		} else {
			maxVal = Math.round(tmp / 10);
		}
	}

	let datas = [];
	Object.keys(v).forEach(k => {
		let avg = v[k].reduce((a,b) => a+b, 0) / v[k].length
		var y: number = +(k);
		let d = moment.unix(y)
		let hour = d.hour()
		let monthDay = d.date()
		if(hour == 0) {
			hour = 24;
			monthDay = monthDay -1;
		}
		// case of last day of month
		if(d.valueOf()/1000 == endFilter && monthDay == 0) {
			var z: number = +(k);
			let prev_d = moment.unix(z-1)
			monthDay = prev_d.date()
		}

		datas.push([monthDay, hour, avg])
	});

	let metric_str = this.message.calcons_counter;
	if(this.message.calcons_counter == "power_float")
		metric_str = 'power';

	this.chartOptions = {
        	chart: {
        		type: 'heatmap',
			marginTop: 40,
        		marginBottom: 10,
        		plotBorderWidth: 1,
        		backgroundColor:'rgba(255, 255, 255, 0.0)',
		},
		title: {
        		text: null
    		},
    		credits: {
        		enabled: false
		},
        	exporting: {
            		enabled: false
		},
    		xAxis: {
			opposite: true,
    			title: {
				text: 'day of month',
				align: 'middle'
			},
			labels: {
	    			formatter: function () {
		 			return '<span class="h5">' + this.value + '</span>'
	    			}
			}
    		},
    		yAxis: {
			title: {
            			text: 'hour'
        		},
        		labels: {
				formatter: function () {
					return String(this.value) + "h00";
				}
        		},
        		minPadding: 0,
        		maxPadding: 0,	
    			startOnTick: false,
    			endOnTick: false,
        		tickPositions: [1, 4, 8, 12, 16, 20, 24],
    			tickWidth: 0.5,
			min: 1,
			max: 24,
			reversed: true
    		},
    		colorAxis: {
        		min: 0,
        		max: maxVal,
			gridLineWidth: 1,
        		gridLineColor: 'white',
			stops: [
	    			[0, '#3060cf'],
	    			[0.5, '#fffbbc'],
	    			[0.9, '#c4463a']
        		]
    		},
    		legend: {
        		align: 'right',
        		layout: 'vertical',
        		margin: 0,
        		verticalAlign: 'top',
        		y: 25,
        		symbolHeight: 280
    		},
    		tooltip: {
        		formatter: function () {
				let text;
   	   			if (this.point.value) {
   	  				text = metric_str + ': ' + this.point.value.toLocaleString("fr-FR",{
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					}) + unit + ' [' + (this.point.y-1) + '-' + this.point.y + 'h]';

  	   			} else {
   	        			text = 'No data available'
   	   			}
   	   			return text
        		}
    		},
    		series: [{
        		type: 'heatmap',
    			name: 'Average Hourly Consumption',
			turboThreshold: 1000,
        		borderWidth: 0.1,
			borderColor: 'black',
			data: datas,
        		dataLabels: {
            			enabled: false,
            			color: '#000000'
        		}
    		}]
	};
	this.isReady = true;
  }

  private getFilter(name: string): FilterMgt {
	return this.message.filterList.find(filter => filter.name === name);
  }
}

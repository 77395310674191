import { Component, OnInit } from '@angular/core';
import { Message } from '@app/model';
import { ShareService } from '@app/services';


@Component({
  selector: 'app-power',
  templateUrl: './power.component.html',
  styleUrls: ['./power.component.css']
})
export class PowerComponent implements OnInit {

  message: Message;

  isDcscope = true;
	isCo2scope = false;

  isVmwareReady: boolean = true;
  isAwsReady: boolean = false;
  isAzureReady: boolean = false;
  
  /**
   * 
   */
  constructor(private data: ShareService) {
  }

  /**
   * 
   */
  ngOnInit(): void {
    this.data.currentMessage.subscribe(message => this.message = message);

		this.isCo2scope = this.message.isCO2Scope;
		this.isDcscope = this.message.isDCScope;
  }

  setVmware(): void {

    this.isVmwareReady = true;
    this.isAwsReady = false;
    this.isAzureReady = false;
  }

  setAws(): void {

    this.isVmwareReady = false;
    this.isAwsReady = true;
    this.isAzureReady = false;
  }

  setAzure(): void {

    this.isVmwareReady = false;
    this.isAwsReady = false;
    this.isAzureReady = true;
  }
}

import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup, FormControl, ValidatorFn } from '@angular/forms';

import * as moment from 'moment';

import { AccountService } from '@app/services';

import { Alias, AliasRec, Message, User, UserCurrencies, UserRec } from '@app/model';

import { getUserCurrency } from '../../../assets/js/tools.js';
import { ClrDatagridSortOrder } from '@clr/angular';


@Component({
  selector: 'app-usermgtnoroot',
  templateUrl: './usermgtnoroot.component.html',
  styleUrls: ['./usermgtnoroot.component.css']
})
export class UsermgtnorootComponent implements AfterViewInit, OnInit {

  message: Message;

  dataOrder = ClrDatagridSortOrder.ASC;

  now: any;

  aliases: Alias[] = [];


  // FOR USERS (aka GROUP)
  currentUser: User;
  
  currencies: string[] = UserCurrencies;

  edit_user_model: any = {};

  isEditUser: boolean = false;
  isUpdateUser: boolean = false;
  isAliasUser: boolean = false;


  // FOR ALIAS (aka USER)
  alias_selected: Alias = {
	user: '',
	login: '',
	is_local: 1,
	is_ldap: 0,
	is_openid: 0
  }

  isEditAlias: boolean = false;
  isUpdateAlias: boolean = false;

  edit_alias_model: any = {};
  signUpForm: FormGroup;

  // FOR ALL
  isModalPassword: boolean = false;
	
  update_state: string = 'error';
  update_result: string = 'changes have not been applied.';


  constructor(private authentication_svc: AccountService, private fb: FormBuilder,
	private cd: ChangeDetectorRef) {

    this.now = moment().format('MMMM Do YYYY');

    this.signUpForm = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20), 
        this.passwordMatchValidator('password')
      ]]
    });
  }

  ngOnInit(): void {
	this.authentication_svc.user.subscribe(user => this.currentUser = user);
  }

  ngAfterViewInit(): void {
    this.getAliases();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  // ALIAS (aka USER)
  editAlias(): void {
	this.isEditAlias = !this.isEditAlias;

	if(this.isEditAlias == true) {
		this.isUpdateAlias = false;

		this.signUpForm.value.password = '';
		this.signUpForm.value.confirmPassword = '';

		this.update_state = 'error';
		this.update_result = 'Changes have not been applied.';

		this.edit_alias_model.password = "";
		this.edit_alias_model.is_ldap = this.alias_selected.is_ldap;
	}
  }

  updateAlias(): void {

	// RECORD ALIAS
	let alias_rec: AliasRec = {
		login: this.alias_selected.login,
		password: this.authentication_svc.crypt(this.edit_alias_model.password),
		user: this.currentUser.login,
		is_local: this.alias_selected.is_local,
		is_ldap: this.alias_selected.is_ldap,
		is_openid: this.alias_selected.is_openid
	};

	this.authentication_svc.updateAlias(alias_rec).pipe(first()).subscribe(
		all => {
			this.update_state = 'success';
			this.update_result = 'changes have been applied.';
		},
		error => {
			if (error != null)
				console.log(error)	
		}
	);
		
	this.isUpdateAlias = false;
  }

  // USER (aka GROUP)
  editUser(): void {
	this.isEditUser = !this.isEditUser;

	if(this.isEditUser == true) {
		this.edit_user_model.currency = this.getCurrency(this.currentUser.currency);
		this.edit_user_model.email = this.currentUser.email;
	}
  }

  updateUser(): void {
		
	// UPDATE USER
	let user_rec: UserRec = {
		login: this.currentUser.login,
		email: this.edit_user_model.email,
		currency: this.encodeCurrency(this.edit_user_model.currency),
		role: this.currentUser.role
	};

	this.authentication_svc.updateUser(user_rec).pipe(first()).subscribe(
		success => {
			this.currentUser.email = this.edit_user_model.email;
	  		this.currentUser.currency = this.encodeCurrency(this.edit_user_model.currency);
			this.update_state = 'success';
			this.update_result = 'changes have been applied.';
		},
		error => {
			if (error != null)
				console.log(error)
		}
	);

	this.isUpdateUser = false;
  }

  enableUserUpdate(): void {
	this.isUpdateUser = true;
	this.update_state = 'error';
	this.update_result = 'changes have not been applied.';
  }

  // SETTINGS PART
  formatEditDate(date: any): any {
	return moment(date).format('MMMM Do YYYY');
  }

  getCurrency(val: string): string {
	return getUserCurrency(val);
  }

  updatePwd(): void {
	this.isModalPassword = false;
	this.edit_alias_model.password = this.signUpForm.value.password;
	this.isUpdateAlias = true;	
  }

  // ALIAS (aka USER) PART
  private getAliases(): void {
	this.authentication_svc.getAliasUser(this.currentUser).pipe(first()).subscribe(
		data => {
			this.aliases = data;
			if (this.aliases.length > 0) {
				this.alias_selected = this.aliases.find(u => u.login == this.currentUser.alias);
			}
		},
		error => {
			if (error != null)
				console.log(error)
		}
	);
  }

  // SETTINGS PART
  private passwordMatchValidator(password: string): ValidatorFn {
	return (control: FormControl) => {
		if (!control || !control.parent) {
			return null;
		}
		return control.parent.get(password).value === control.value ? null : { mismatch: true };
	};
  }

  private encodeCurrency(val: string): string {
	let codeascii: string = '';
	var code: string = val.split('-')[0];
	for (var i = 0; i < code.length; i++) {
		if (code.charCodeAt(i) !== 160)
			codeascii += code.charCodeAt(i) + '-';
	}

	return codeascii.substring(0, codeascii.length - 1);
  }
}

<clr-tabs>
	<clr-tab>
        	<button clrTabLink (click)="setCard()">VM CARD</button>
		<clr-tab-content *clrIfActive="true">
			<div class="card">
				<div class="card-header card-ajust">
					<h5>
						<label>
							<clr-icon shape="vm"></clr-icon>
							{{vm_synth.name}}
							<span class="badge" [ngStyle]="{ 'background-color': vm_synth.state == 'RUNNING' ? '#3c8500' : '#c21d00' }">{{vm_synth.state}}</span>
							<span class="badge" [ngStyle]="{ 'background-color': vm_synth.tools == 'up to date' ? '#3c8500' : '#c21d00' }">{{vm_synth.tools}}</span>
							<span class="label" style="float:right;">{{infra.datacenter}}
								<span class="badge">datacenter</span>
							</span>
							<span class="label clickable addLink" style="float:right;" (click)="callClusterRoute(infra.cluster)">{{infra.cluster}}
								<span class="badge">cluster</span>
							</span>
							<span class="label clickable addLink" style="float:right;" (click)="callHostRoute(vm_synth.host)">{{vm_synth.host}}
								<span class="badge">server</span>
							</span>
						</label>
					</h5>
				</div>
				<div class="card-block">
					<div class="clr-row">
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="currentUser.isDashboardT6">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{vm_synth.co2.toFixed(2)}} KgCO2 eq</b>
										<br>
										CO2 emission
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="currentUser.isDashboardT6">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{globalCurrency}} {{vm_synth.cost}}</b>
										<br>
										Cost
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{vm_synth.vcpu}}</b>
										<br>
										vCPU
										<br>
										<span class="badge" [ngStyle]="{ 'background-color': vm_synth.cpucons >= 70 && vm_synth.cpucons < 90 ? '#c25400' : vm_synth.cpucons >= 90 ? '#c21d00' : 'grey' }">{{vm_synth.cpucons}}% average consumption</span>
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{math.round(vm_synth.vram/1024)}} GB</b>
										<br>
										Memory
										<br>
										<span class="badge" [ngStyle]="{ 'background-color': vm_synth.ramcons >= 70 && vm_synth.ramcons < 90 ? '#c25400' : vm_synth.ramcons >= 90 ? '#c21d00' : 'grey' }">{{vm_synth.ramcons}}% average consumption</span>
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{vm_synth.ds}} GB</b>
										<br>
										Storage
										<br>
										<span class="badge" [ngStyle]="{ 'background-color': vm_synth.stocons >= 70 && vm_synth.stocons < 90 ? '#c25400' : vm_synth.stocons >= 90 ? '#c21d00' : 'grey' }">{{vm_synth.stocons}}% average consumption</span>
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">Status</b>
										<br>
										<div class="clr-row">
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isDcscope">
												<clr-alert [clrAlertType]="trouble_state" [clrAlertSizeSmall]="true" [clrAlertAppLevel]="true" [(clrAlertClosed)]="isNoTsAlert" *ngIf="currentUser.isMenuTrouble">
													<clr-alert-item>
														<span id="vm-technical-alert-ts-text" class="alert-text">
															troubleshooting: normal
														</span>
													</clr-alert-item>
												</clr-alert>
											</div>
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
												<clr-alert [clrAlertType]="behavior_state" [clrAlertSizeSmall]="true" [clrAlertAppLevel]="true" [(clrAlertClosed)]="isNoBehAlert">
													<clr-alert-item>
														<span id="vm-technical-alert-beh-text" class="alert-text">
															behavior: normal
														</span>
													</clr-alert-item>
												</clr-alert>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clr-row">
				<div [ngClass]="general_info_class">
					<div class="card" id="vm-synth-info">
						<div class="card-header card-ajust">
							<h5>
								<clr-icon shape="id-badge"></clr-icon>
								&nbsp;General information
							</h5>
						</div>
						<div class="card-block">
							<div class="clr-row">
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
									<clr-icon shape="vm" size="72" [ngStyle]="{ color: vm_synth.tsstate === 'green' ? '#3c8500' : vm_synth.tsstate === 'orange' ? '#c25400' : vm_synth.tsstate === 'red' ? '#c21d00' : 'grey' }"></clr-icon>
								</div>
							</div>
							<br>
							<div class="clr-row">
								<div [ngClass]="content_info_class">
									<label class="clr-control-label">Owner</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<input type="text" placeholder="not registered" class="clr-input" [(ngModel)]="comment.OWNER" (ngModelChange)="updateOwner($event)">
      										</div>
										<clr-control-helper>who is in charge of the VM</clr-control-helper>
    									</div>
								</div>
								<div [ngClass]="content_info_class">
									<label class="clr-control-label">Environment</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<input type="text" placeholder="not registered" class="clr-input" [(ngModel)]="comment.ENV" (ngModelChange)="updateEnv($event)">
      										</div>
										<clr-control-helper>where the VM is running</clr-control-helper>
    									</div>
								</div>
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isDcscope">&nbsp;</div>
								<div [ngClass]="content_info_class">
									<form clrForm clrLayout="vertical">
										<label class="clr-control-label">Modification date</label>
										<div class="clr-input">
											<label>{{vm_synth.modiftime}}</label>
      										</div>
										<clr-control-helper>when the VM was created or modified</clr-control-helper>
									</form>
								</div>
								<div id="csstoadjust" [ngClass]="content_info_class">
									<label class="clr-control-label">Expiration date</label>
									<div class="clr-input">
										<input [(clrDate)]="newdate" [clrDate]="updateEndDate()" placeholder="{{comment.END_DATE || 'not registered'}}" id="vm-synth-input-end-date" />
      									</div>
									<clr-control-helper>when is the end of life date of the VM</clr-control-helper>
								</div>
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
								<div [ngClass]="content_info_class" style="padding-top:5px;">
									<label class="clr-control-label">DCScope comment</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<input type="text" placeholder="not registered" class="clr-input" [(ngModel)]="comment.COMMENT" (ngModelChange)="updateComment($event)">
      										</div>
										<clr-control-helper>shared between users</clr-control-helper>
    									</div>
								</div>
								<div [ngClass]="content_info_class">
									<form clrForm clrLayout="vertical">
										<label class="clr-control-label">VMWare comment</label>
										<div class="clr-input">
											<label [ngStyle]="{color: vm_synth.vmwarecomment === 'none' ? 'grey' : 'black' }">{{vm_synth.vmwarecomment}}</label>
      										</div>
										<clr-control-helper>stored from a VMWare client</clr-control-helper>
									</form>
								</div>
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isDcscope">&nbsp;</div>
								<div [ngClass]="content_info_class">
									<label class="clr-control-label">Operating system</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<span class="clr-input">{{vm_synth.os}}</span>
      										</div>
										<clr-control-helper>VM operating system</clr-control-helper>
									</div>
								</div>
								<div [ngClass]="content_info_class">
									<label class="clr-control-label">HA</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<span class="clr-input">{{vm_synth.ha}}</span>
      										</div>
										<clr-control-helper>high availability</clr-control-helper>
									</div>
								</div>
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
								<div [ngClass]="content_info_class">
									<label class="clr-control-label">Folder</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<span class="clr-input">{{vm_synth.fd}}</span>
      										</div>
										<clr-control-helper>folder location</clr-control-helper>
									</div>
								</div>
								<div [ngClass]="content_info_class">
									<label class="clr-control-label">Resource pool</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<span class="clr-input">{{vm_synth.rp}}</span>
      										</div>
										<clr-control-helper>resource pool location</clr-control-helper>
									</div>
								</div>
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isDcscope">&nbsp;</div>
								<div [ngClass]="content_info_class">
									<label class="clr-control-label">Tags</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<span class="clr-input">{{vm_synth.tags}}</span>
      										</div>
										<clr-control-helper>tags list</clr-control-helper>
									</div>
								</div>
								<div [ngClass]="content_info_class">
									<label class="clr-control-label">Datastore</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<span class="clr-input">{{vm_synth.dss}}</span>
      										</div>
										<clr-control-helper>datastores list</clr-control-helper>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="isDcscope">
					<div class="card" id="vm-synth-radar">
						<div class="card-header card-ajust">
							<h5>
								<clr-icon shape="resource-pool"></clr-icon>
								&nbsp;Resources information
							</h5>
						</div>
						<div class="card-block" id="vmsynthesis-radar-core">
							<div class="card-text">
								<clr-tabs>
									<clr-tab *ngIf="currentUser.isMenuTrouble && vm_synth.state != 'OFF'">
        									<button clrTabLink (click)="setTroubleshooting()">Troubleshooting</button>
										<clr-tab-content *clrIfActive="true">
											<ng-template load-ts></ng-template>
										</clr-tab-content>
									</clr-tab>
									<clr-tab>
        									<button clrTabLink (click)="setRadarActivity()">Activity</button>
										<clr-tab-content *clrIfActive="false">
											<highcharts-chart
												[Highcharts]="Highcharts"
												[constructorType]="'chart'"
												[options]="chartOptions"
												style="width: 100%; height: 100%; display: block;" *ngIf="isReady">
											</highcharts-chart>
										</clr-tab-content>
									</clr-tab>
								</clr-tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
        </clr-tab-content>
    </clr-tab>
	<clr-tab>
        <button clrTabLink (click)="setCalCons()">CONSUMPTION PROFILE</button>
        <clr-tab-content *clrIfActive="false">
			<ng-template load-cons-cal></ng-template>
        </clr-tab-content>
	</clr-tab>
	<!--<clr-tab *ngIf="vm_synth.state == 'RUNNING'">
        	<button clrTabLink (click)="setOverallCons()">OVERALL consumption</button>
        	<clr-tab-content *clrIfActive="false">
			<div class="card">
				<div class="card-header card-ajust">
					<h5>
						<clr-icon shape="table"></clr-icon>
						&nbsp;Virtual machine consumption tables for {{message.currentName}}
					</h5>
					<span class="export"></span>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<span class="search"></span>
				</div>
				<div class="card-block">
					<ng-template load-vm-consover></ng-template>
				</div>
			</div>
        	</clr-tab-content>
	</clr-tab>
	<clr-tab>
        	<button clrTabLink (click)="setWeeklyCons()">WEEKLY consumption</button>
        	<clr-tab-content *clrIfActive="false">
			<ng-template load-weekly-cal></ng-template>
        	</clr-tab-content>
	</clr-tab>
    	<clr-tab>
        	<button clrTabLink (click)="setMonthlyCons()">MONTHLY consumption</button>
        	<clr-tab-content *clrIfActive="false">
			<ng-template load-monthly-cal></ng-template>
        	</clr-tab-content>
    	</clr-tab>
    	<clr-tab>
        	<button clrTabLink (click)="setYearlyCons()">YEARLY consumption</button>
        	<clr-tab-content *clrIfActive="false">
			<ng-template load-yearly-cal></ng-template>
        	</clr-tab-content>
    	</clr-tab>-->
    	<clr-tab>
        	<button clrTabLink (click)="setYearlyBehavior()">YEARLY BEHAVIOR</button>
        	<clr-tab-content *clrIfActive="false">
			<ng-template load-behavior-cal></ng-template>
        	</clr-tab-content>
	</clr-tab>
    	<clr-tab>
        	<button clrTabLink (click)="setLcy()">LIFE CYCLE</button>
        	<clr-tab-content *clrIfActive="false">
			<ng-template load-lcy></ng-template>
        	</clr-tab-content>
	</clr-tab>
</clr-tabs>

<div class="card">
	<div class="card-header card-ajust">
		<div class="clr-row">
			<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
				<h5>
					<clr-icon shape="table"></clr-icon>
					&nbsp;Yearly behavior for {{message.currentName}} 
				</h5>
			</div>
		</div>
	</div>
	<div class="card-block">
		<div class="clr-row">
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
				<button (click)="previous()" class="btn btn-link" [disabled]="isNoPrevData">
					<clr-icon shape="angle" style="transform:rotate(-90deg)"></clr-icon>
				</button>
				&nbsp;&nbsp;
				&nbsp;&nbsp;
				<button (click)="next()" class="btn btn-link" [disabled]="isNoNextData">
					<clr-icon shape="angle" style="transform:rotate(90deg)"></clr-icon>
				</button>
				&nbsp;&nbsp;
				<span>{{yearly_range_date}}</span>
			</div>
		</div>
		<div id="vmsynthesis-yearly-beh"></div>
	</div>
</div>

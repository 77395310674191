<!-- SETTINGS MODAL -->
<clr-modal [(clrModalOpen)]="modal_settings" [(clrModalClosable)]="!reload_filter" [clrModalSize]="'xl'">
    <h3 class="modal-title">{{isDcscope == true ? 'DCScope' : 'CO2Scope'}} settings</h3>
    <div class="modal-body" style="min-height:620px;">
		<clr-tabs>
    		<clr-tab *ngIf="currentUser.isMenuCapa && valid_filter">
        		<button clrTabLink>Capacity planning</button>
        		<clr-tab-content *clrIfActive="true">
					<app-capasettings #capa (reload)="reload_filter=$event"></app-capasettings>
        		</clr-tab-content>
    		</clr-tab>
    		<clr-tab *ngIf="isDcscope">
        		<button clrTabLink (click)="setSettingsDashboard()">Dashboard</button>
        		<clr-tab-content *clrIfActive="false">
				<clr-stack-view>
					<clr-stack-header>Manage your cards into the dashboard</clr-stack-header>
					<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
						<clr-stack-label>
							<clr-checkbox-container>
  								<clr-checkbox-wrapper>
    								<input id="set-dashboard-t1" type="checkbox" clrCheckbox name="t1" [(ngModel)]="userSettings.isT1" (change)="updateDashboardSettings($event)" [disabled]="userSettings.isT1Disabled" />
									<label class="evSettingsDashboard">Virtual machine behavior</label>
  								</clr-checkbox-wrapper>
							</clr-checkbox-container>
						</clr-stack-label>
						<clr-stack-content>The current VM behavior [idle - busy - lazy - normal - oversized - undersized] based on the resources consumption</clr-stack-content>
					</clr-stack-block>
					<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
						<clr-stack-label>
							<clr-checkbox-container>
  								<clr-checkbox-wrapper>
    									<input id="set-dashboard-t2" type="checkbox" clrCheckbox name="t2" [(ngModel)]="userSettings.isT2" (change)="updateDashboardSettings($event)" [disabled]="userSettings.isT2Disabled" />
									<label class="evSettingsDashboard">Resources prediction</label>
  								</clr-checkbox-wrapper>
							</clr-checkbox-container>
						</clr-stack-label>
						<clr-stack-content>Display the resources saturation date [VCPU - VRAM - VDISK]</clr-stack-content>
					</clr-stack-block>
					<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
						<clr-stack-label>
							<clr-checkbox-container>
  								<clr-checkbox-wrapper>
    									<input id="set-dashboard-t3" type="checkbox" clrCheckbox name="t3" [(ngModel)]="userSettings.isT3" (change)="updateDashboardSettings($event)" [disabled]="userSettings.isT3Disabled" />
									<label class="evSettingsDashboard">Opportunities</label>
  								</clr-checkbox-wrapper>
							</clr-checkbox-container>
						</clr-stack-label>
						<clr-stack-content>Display optimization on server consumption</clr-stack-content>
					</clr-stack-block>
					<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
						<clr-stack-label>
							<clr-checkbox-container>
  								<clr-checkbox-wrapper>
    									<input id="set-dashboard-t4" type="checkbox" clrCheckbox name="t4" [(ngModel)]="userSettings.isT4" (change)="updateDashboardSettings($event)" [disabled]="userSettings.isT4Disabled" />
									<label class="evSettingsDashboard">Resources over the last 24 hours</label>
  								</clr-checkbox-wrapper>
							</clr-checkbox-container>
						</clr-stack-label>
						<clr-stack-content>Display the resources consumption [CPU - RAM - STORAGE]</clr-stack-content>
					</clr-stack-block>
					<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
						<clr-stack-label>
							<clr-checkbox-container>
  								<clr-checkbox-wrapper>
    									<input id="set-dashboard-t5" type="checkbox" clrCheckbox name="t5" [(ngModel)]="userSettings.isT5" (change)="updateDashboardSettings($event)" [disabled]="userSettings.isT5Disabled" />
									<label class="evSettingsDashboard">How many virtual machines can be created</label>
  								</clr-checkbox-wrapper>
							</clr-checkbox-container>
						</clr-stack-label>
						<clr-stack-content>Display the number of virtual machine which can be created from different allocation ratio limitation</clr-stack-content>
					</clr-stack-block>
					<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
						<clr-stack-label>
							<clr-checkbox-container>
  								<clr-checkbox-wrapper>
    									<input id="set-dashboard-t6" type="checkbox" clrCheckbox name="t6" [(ngModel)]="userSettings.isT6" (change)="updateDashboardSettings($event)" [disabled]="userSettings.isT6Disabled" />
									<label class="evSettingsDashboard">Filter cost</label>
  								</clr-checkbox-wrapper>
							</clr-checkbox-container>
						</clr-stack-label>
						<clr-stack-content>Display datacenter cost [allocation - consumption - wasted]</clr-stack-content>
					</clr-stack-block>
				</clr-stack-view>
        		</clr-tab-content>
    		</clr-tab>
    		<clr-tab *ngIf="valid_filter">
        		<button clrTabLink (click)="setSettingsPeriod()">Period analysis</button>
        		<clr-tab-content *clrIfActive="false">
					<div id="settingsPeriod" style="width:75%;margin-top:30px;margin-left:auto;margin-right:auto;">
						<div class="alert alert-info" role="alert" style="padding-left:5px;padding-right:5px;padding-bottom:10px;">
							<span style="text-align:justify;">
								<p><b>{{isDcscope == true ? 'DCScope' : 'CO2Scope'}} allows for a more precise analysis by specifying an alternative analysis period (e.g. work days/hours).</b></p>
								<p>Notice that these settings are valid only for work days configurations within the time views settings, meaning that when not selected, the full periods will be considered for the analysis.</p>
							</span>
						</div>
						<br>
						<div class="clr-row">
							<div class="clr-col-6">
                                <div style="float:left">
									<div class="alert alert-info" role="alert" style="padding-left:5px;padding-right:5px;padding-top:10px;padding-bottom:10px;">
										<span style="text-align:justify;">
											Time zone for the {{isDcscope == true ? 'DCScope' : 'CO2Scope'}} VM : {{timezone}}
										</span>
									</div>
								</div>
							</div>
							<div class="clr-col-6">
                                <div style="float:right">
									<div class="alert alert-success" role="alert" style="padding-left:5px;color:black">
										<div class="alert-items">
											<div class="alert-item static">
												<clr-icon shape="check-circle" size="24" style="margin-right:10px"></clr-icon>
												<span class="alert-text" style="margin:auto">
													Last period update : {{formatDate(this.settings_period_lastupdate)}}
												</span>
												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="clr-row" style="margin-top:100px;">
							<div class="clr-col-4">
								<label class="clr-control-label"> Days of week:</label>
							</div>
							<div class="clr-col-8" style="padding-left:0px;">
								<div class="btn-group btn-primary btn-sm">
									<button *ngFor="let btn of period_days" [ngClass]="btn.state == true ? 'btn btn-primary' : 'btn btn-outline'" (click)="btn.state = !btn.state;settings_period_update=true"> {{ btn.caption }} </button>
								</div>
							</div>
						</div>

						<div class="clr-row" style="margin-top:100px;">
							<div class="clr-col-4">
								<label class="clr-control-label"> Time range :</label>
							</div>
							<div class="clr-col-8">
								<div class="clr-row">
									<clr-select-container style="margin-top:0;width:25%">
										<label class="clr-control-label" style="text-align:center;margin-bottom:10px;">start :</label>
										<select clrSelect [(ngModel)]="settings_period_start" (change)="settings_period_update=true">
											<option *ngFor="let option of period_start" [value]="option.value"> {{ option.text }} </option>
										</select>
									</clr-select-container>

									<clr-select-container style="margin-top:0;width:25%">
										<label class="clr-control-label" style="text-align:center;margin-bottom:10px;">end :</label>
										<select clrSelect [(ngModel)]="settings_period_end" (change)="settings_period_update=true">
											<option *ngFor="let option of period_end" [value]="option.value"> {{ option.text }} </option>
										</select>
									</clr-select-container>
								</div>
							</div>
						</div>

					</div>
					<button type="button" class="btn btn-danger-outline btn-sm" *ngIf="settings_period_update" style="margin-right:15%;float:right" (click)="modal_confirm_periods = true">
						UPDATE
					</button>

					<div class="divid" style="margin-top:40px;"></div>

					<div style="width:75%;margin-top:30px;margin-left:auto;margin-right:auto;">
						<div class="clr-row">
							<div class="clr-col-4">
								<label class="clr-control-label"> Data retention for time view 'all' :</label>
							</div>
							<div class="clr-col-8">
								<div class="clr-row">
									<clr-select-container style="margin-top:0px">
										<select clrSelect [(ngModel)]="settings_retention_all" (change)="settings_retention_update=true">
											<option>3 years</option>
											<option>2 years</option>
											<option>1 year</option>
										</select>
									</clr-select-container>
								</div>
							</div>
						</div>
					</div>
					<button type="button" class="btn btn-danger-outline btn-sm" *ngIf="settings_retention_update" style="margin-right:15%;float:right" (click)="updateRetention()">
						UPDATE
					</button>

        		</clr-tab-content>
    		</clr-tab>
    		<clr-tab *ngIf="valid_filter">
        		<button clrTabLink (click)="setSettingsThreshold()">Threshold level</button>
        		<clr-tab-content *clrIfActive="false">
					<div id="settingsThreshold" style="width:75%;margin-top:30px;margin-left:auto;margin-right:auto;">
						<div class="alert alert-info" role="alert" style="padding-left:5px;padding-right:5px;padding-bottom:10px;">
							<span style="text-align:justify;">
								<p><b>Identify an element whose resource consumption does not exceed a certain threshold.</b></p>
								<p>An element is said idle (for example) on a time range, if : X% of processor and memory consumption values are less than Y%.</p>
								<p>For noise resistance, X varies over four values : 100%, 99.99%, 99.9% and 99%.</p>
								<p>The inactivity threshold is also parameterizable. Indeed, the rate of activity (and nonactivity) detected is directly dependent on the capacity of the processor of the server on which the virtual machine is hosted. So, Y varies over three values : 2%, 5% or 10%.</p>
							</span>
						</div>
	
						<div style="margin-top:30px;display:flex;margin-left:25%;">
							<clr-select-container style="margin-top:0;width:33%">
								<label class="clr-control-label" style="text-align:center;margin-bottom:10px;">The noise level :</label>
								<select clrSelect [(ngModel)]="reco_settings.p3" (change)="settings_threshold_update=true">
									<option value="100"> 100 </option>
									<option value="9999"> 99.99 </option>
									<option value="999"> 99.9 </option>
									<option value="99"> 99 </option>
								</select>
							</clr-select-container>
	
							<clr-select-container style="margin-top:0;width:33%">
								<label class="clr-control-label" style="text-align:center;margin-bottom:10px;">The threshold to not exceed :</label>
								<select clrSelect [(ngModel)]="reco_settings.p2" (change)="settings_threshold_update=true">
									<option value="10"> 10 </option>
									<option value="5"> 5 </option>
									<option value="2"> 2 </option>
								</select>
							</clr-select-container>
						</div>
					</div>
					<button type="button" class="btn btn-danger-outline btn-sm" *ngIf="settings_threshold_update" style="margin-right:15%;margin-top:20px;float:right" (click)="updateSettingsThreshold()">
						UPDATE
					</button>
        		</clr-tab-content>
			</clr-tab>
    			<clr-tab *ngIf="valid_filter">
				<button clrTabLink (click)="setSettingsGod()">Graph on Demand</button>

				<ng-template #templateSelectedCounters let-item="item">
					<clr-icon shape="line-chart" role="img" aria-label="welcome home" size="12"></clr-icon>
					{{ item?.label }}
					<span class="badge" *ngFor="let ressourceType of item.description.appliesTo" [attr.class]="ressourceType == 'HOST' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ ressourceType | lowercase}}</span>
				</ng-template>

				<ng-template #templateDisplayCounters let-item='item'>
					{{ item.label }}
					<span class="badge" *ngFor="let ressourceType of item.description.appliesTo" [attr.class]="ressourceType == 'HOST' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ ressourceType | lowercase}}</span>
				</ng-template>

				<ng-template #templateDisplayCountersWhenEmpty>
					<clr-control-helper slot="displayWhenEmpty">Type a counter name</clr-control-helper>
				</ng-template>

				<ng-template #templateDisplayCountersWhenErrors>
					<clr-control-error slot="displayWhenError">There was an error</clr-control-error>
				</ng-template>

        		<clr-tab-content *clrIfActive="false">
					<div id="settingGod"></div>
					<clr-stack-view>
						<clr-stack-header>Manage the default counters</clr-stack-header>
						<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
							<clr-stack-label>
								<div class="clr-select-wrapper">
									<select id="select-full-cpu" class="clr-select" [(ngModel)]="graphOnDemandSettings.defaultCounter.cpu" (change)="updateSettingsGod()">
										<option [ngValue]="cpuOption.metricName" *ngFor="let cpuOption of graphOnDemandSettings.counters['cpu']">{{ cpuOption.label }}</option>
									</select>
								</div>
							</clr-stack-label>
							<clr-stack-content>The default CPU counter displayed in the GraphOnDemand section</clr-stack-content>
						</clr-stack-block>
						<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
							<clr-stack-label>
								<div class="clr-select-wrapper">
									<select id="select-full-ram" class="clr-select" [(ngModel)]="graphOnDemandSettings.defaultCounter.ram" (change)="updateSettingsGod()">
										<option [ngValue]="cpuOption.metricName" *ngFor="let cpuOption of graphOnDemandSettings.counters['ram']">{{ cpuOption.label }}</option>
									</select>
								</div>
							</clr-stack-label>
							<clr-stack-content>The default RAM counter displayed in the GraphOnDemand section</clr-stack-content>
						</clr-stack-block>
						<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
							<clr-stack-label>
								<div class="clr-select-wrapper">
									<select id="select-full-disk" class="clr-select" [(ngModel)]="graphOnDemandSettings.defaultCounter.disk" (change)="updateSettingsGod()">
										<option [ngValue]="cpuOption.metricName" *ngFor="let cpuOption of graphOnDemandSettings.counters['disk']">{{ cpuOption.label }}</option>
									</select>
								</div>
							</clr-stack-label>
							<clr-stack-content>The default DISK counter displayed in the GraphOnDemand section</clr-stack-content>
						</clr-stack-block>
						<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
							<clr-stack-label>
								<div class="clr-select-wrapper">
									<select id="select-full-network" class="clr-select" [(ngModel)]="graphOnDemandSettings.defaultCounter.network" (change)="updateSettingsGod()">
										<option [ngValue]="cpuOption.metricName" *ngFor="let cpuOption of graphOnDemandSettings.counters['network']">{{ cpuOption.label }}</option>
									</select>
								</div>
							</clr-stack-label>
							<clr-stack-content>The default NETWORK counter displayed in the GraphOnDemand section</clr-stack-content>
						</clr-stack-block>
					</clr-stack-view>
        		</clr-tab-content>
    		</clr-tab>
			<clr-tab *ngIf="valid_filter && netscopeSettings.netscopeModuleIsInLicence && netscopeSettings.showSettings && isDcscope">
				<button clrTabLink (click)="setSettingsGod()">DCNetScope</button>

				<clr-tab-content *clrIfActive="false">
					<div id="settingNetscope"></div>
					<clr-stack-view>
						<clr-stack-header>
							Manage DCNetScope settings
							<span class="spinner spinner-sm" *ngIf="netscopeSettings.isUploading">
								Loading...
							</span>
						</clr-stack-header>
						<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
							<clr-stack-label>
								<input class="clr-input" placeholder="URL of the netscope agent" name="input" style="width: 90%;" (change)="updateNetscopeSettings()" [(ngModel)]="netscopeSettings.agentUrl" />
							</clr-stack-label>
							<clr-stack-content>URL of the DCNetScope agent</clr-stack-content>
						</clr-stack-block>
						<clr-stack-block [clrStackViewLevel]="1" [clrStackViewSetsize]="1" [clrStackViewPosinset]="1">
							<clr-stack-label>
								<input class="clr-input" placeholder="Token for the API" name="input" style="width: 90%;" (change)="updateNetscopeSettings()" [(ngModel)]="netscopeSettings.tokenApi" />
							</clr-stack-label>
							<clr-stack-content>Token for the API</clr-stack-content>
						</clr-stack-block>
					</clr-stack-view>
				</clr-tab-content>
			</clr-tab>
		</clr-tabs>
    </div>
    <div class="modal-footer" style="min-height:60px;">
	    <button type="button" class="btn btn-primary" (click)="reloadFilter()" *ngIf=reload_filter>Apply Changes</button>
    </div>
</clr-modal>

<!-- CONFIRM PERIODS MODAL -->
<clr-modal [(clrModalOpen)]="modal_confirm_periods" [clrModalClosable]=false>
    <h3 class="modal-title">Warning</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
			<p>You are about to modify the analysis periods. All historical measurement data for the current settings <b>will be erased.</b></p>
			<p><b>A new data history will start from tomorrow.</b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="modal_confirm_periods = false;">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="updatePeriodsSettings();">Confirm</button>
    </div>
</clr-modal>

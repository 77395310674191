import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild, ComponentFactoryResolver, ComponentRef } from '@angular/core';

import { ConsoverComponent } from '@app/consover/consover.component';
import { VmconsoverDirective } from '@app/directives/vmconsover.directive';

import { ShareService } from '@app/services';

import { Message } from '@app/model';

var componentRef: ComponentRef<any>;


@Component({
  selector: 'app-vmconsover',
  templateUrl: './vmconsover.component.html',
  styleUrls: ['./vmconsover.component.css']
})
export class VmconsoverComponent implements OnInit {

  @ViewChild(VmconsoverDirective, {static: false}) addVmConsover: VmconsoverDirective;

  message: Message;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {}

  ngOnInit(): void {

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.isVmConsover = true;
  }

  ngAfterViewInit(): void{
	  this.loadVmConsOver();
  }

  ngAfterViewChecked(): void {

	if(this.message.isReloadConsover) {
		this.message.isReloadConsover = false;
		this.reloadVmConsOver();
	}

  	this.cd.detectChanges();
  }

  private loadVmConsOver(): void {

	if(this.addVmConsover != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsoverComponent);
		const viewContainerRef = this.addVmConsover.viewContainerRef;
	  	viewContainerRef.clear();
		componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private reloadVmConsOver(): void {

	$( "div.dataTables_filter" ).remove();
	if(componentRef != undefined)
		componentRef.destroy();

	setTimeout(() => this.loadVmConsOver(), 100);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, first, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Counter, GreenitSettings } from '@app/model';

import { GreenitTarget } from '../greenit/greenit.enums';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { JsonloaderService } from '@app/services/jsonloader.service';
import { ManagementService } from '@app/services/management.service';

@Injectable({
  providedIn: 'root'
})
export class GreenitService {

  greenit_settings: GreenitSettings[] = [];

  currentSettings: BehaviorSubject<GreenitSettings[]> = new BehaviorSubject<GreenitSettings[]>(this.greenit_settings);
  public settings: Observable<GreenitSettings[]> = this.currentSettings.asObservable();

  constructor(
    private http: HttpClient,
    private json_svc: JsonloaderService,
    private mgt_svc: ManagementService) { }

  getGreenitSettings() {
    return this.http.get<GreenitSettings[]>(`${environment.apiUrl}/` + 'management/getgreenitsettings/').pipe(map(settings => {
      this.currentSettings.next(settings);
      return settings;
    }));
  }

  updateGreenitSettings(settings: JSON) {
    return this.http.post<GreenitSettings[]>(`${environment.apiUrl}/` + 'management/updategreenitsettings/', settings);
  }

  getGreenitData(user: string, filter: string, target: GreenitTarget) {
    return this.http.post(`${environment.apiUrl}/` + 'greenit/getgreenitdata/', [user, filter, target]).pipe(map(data => {
      return data;
    }));
  }

  updateServersMinMax(data: JSON) {
    return this.http.post(`${environment.apiUrl}/` + 'infrastructure/updateserversminmax/', data);
  }

  updateServersSettings(data: JSON) {
    return this.http.post(`${environment.apiUrl}/` + 'management/updateserverssettings/', data);
  }

  runGreenitOptimize(user: string, filter: string) {
    return this.http.post(`${environment.apiUrl}/` + 'greenit/runoptimize/', [user, filter]);
  }

  clearHistory() {
    return this.http.post(`${environment.apiUrl}/` + 'greenit/clearhistory/',[]);
  }

  getElementsListObservable(pattern: string): Observable<string[]> {
    return of(this.getFilteredElementsList(pattern)).pipe(delay(0));
  }

  private getFilteredElementsList(pattern: string): string[] {
    let resources = [];

    let hosts = this.json_svc.json.hostSynthesis;
    let vms = this.json_svc.json.vmSynthesis;

    // Add the resourceType HOST
    if(hosts != null) {
      for (let resource of hosts) {
        resource.resourceType = "HOST";
      }
      resources.push(hosts);
    }

    // Add the resourceType VM
    if(vms != null) {
      for (let resource of vms) {
        resource.resourceType = "VM";
      }
      resources.push(vms);
    }

    let result = resources
      .flat()
      .filter((o) => (o.name.toLowerCase().indexOf(pattern.toLowerCase()) > -1)
      );

    return result.slice(0, 20);
  }

  getHostsListObservable(pattern: string): Observable<string[]> {
    return of(this.getHostsList(pattern)).pipe(delay(0));
  }

  private getHostsList(pattern: string): string[] {
    let resources = [];

    let hosts = this.json_svc.json.hostSynthesis;

    // Add the resourceType HOST
    if(hosts != null) {
      for (let resource of hosts) {
        resource.resourceType = "HOST";
      }
      resources.push(hosts);
    }

    let result = resources
      .flat()
      .filter((o) => (o.name.toLowerCase().indexOf(pattern.toLowerCase()) > -1)
      );

    return result.slice();
  }

  getCountersListObservable(): Observable<any[]> {
    return of(this.getCountersList()).pipe(delay(0));
  }

  private getCountersList(): any[] {
    const rollups = ["MIN", "AVG", "MAX"];

    let countersConfig: any = {
      power: {
        key: "POWER",
        icon: "fa fa-sitemap",
        defaultCounters: [{
          key: 'POWER',
          rollup: "AVG"
        }],
        counters: {
          POWER: {
            name: 'Power',
            unit: 'Watts',
            conversionFactor: 1,
            rollup: rollups,
            appliesTo: ["VM", "HOST"]
          }
        }
      }
    };

    let result = Object
      .entries(countersConfig.power.counters)
      .map(([k, v]) => v["rollup"].length == 0 ? [[k, v, undefined, k]] : v["rollup"].map((r) => [k + " - " + r, v, r, k]))
      .flat()
      .map(([k, v, r, k_orig]) => Object({
        name: k,
        label: r != undefined ? `${v["name"]} - ${r} (${v["unit"]})` : `${v["name"]} (${v["unit"]})`,
        description: v,
        metricName: k.indexOf("-") == -1 ? k : (x => x.split(" - ").reverse().join("_"))(k),
        // See graph-on-demand.service.ts : add the "metricNameMinutely" in each counters
        metricNameMinutely: k_orig
      }));

    return result;
  }

  getDataObservable(uuids: string[], startTime: number, endTime: number, requiredCounters: any[], showLast: boolean): Observable<Object> {
		let observables = uuids.map((r: any) => {
			return this.getData(r, startTime, endTime, requiredCounters, showLast, "");
		});
		return forkJoin(observables);
	}

  /**
   * Same as graph-on-demand.service.ts
   */
  private getData(r: any, startTime: number, endTime: number, requiredCounters: any[], showLast: boolean, lastHash: string) {
    let granularity = this.getGranularity(startTime, endTime);
    let resourceType = r["resourceType"].toUpperCase();
    let uuids = [r["uuid"]];

    let promiseResult = new Promise((resolve, reject) => {
      this.getCountersValues(resourceType, uuids, startTime, endTime, requiredCounters, granularity.granularity).subscribe((resp) => {

        // @ts-ignore
        if (resp.length > 0) {
          let currentHash = `${granularity.granularity}_${startTime}_${endTime}`;

          if (! showLast || (showLast && !granularity.alternativeLowerGranularity) || lastHash == currentHash) {
            resolve(resp);
          } else {
            // @ts-ignore
            const lastItem = resp[resp.length - 1];

            if (lastItem.time == endTime) {
              resolve(resp);
            } else {
              this.getData(r, lastItem.time, endTime, requiredCounters, showLast, currentHash).then((resp2) => {
                let additionalElement = [];
                // @ts-ignore
                if (resp2.length > 0) {
                  // @ts-ignore
                  additionalElement.push(resp2[resp2.length -1]);
                }
                // @ts-ignore
                resolve(resp.concat(additionalElement));

              }).catch((reason) => {
                console.error(`cannot fetch more precise results: ${reason}`);
                console.error('I am sending the results with an higher granularity');
                resolve(resp);
              });
            }
          }
        } else {
          this.getCountersValues(resourceType, uuids, startTime - granularity.alternativeRangeExtension, endTime + granularity.alternativeRangeExtension, requiredCounters, granularity.alternativeGranularity).subscribe((resp2) => {
            resolve(resp2);
          });
        }

      })
    });
    return promiseResult;
  }


  private getCountersValues(resourceType: string, uuids: any, start: number, end: number, requiredCounters: any[], granularity: any) {
    let request = {
      "aggregationGranularity": granularity,
      "resourceType": resourceType,
      "timeInterval": {
        "startTime": start,
        "endTime": end
      },
      "resourceFilter": {
        "fields": ["UUID"],
        "values": [uuids]
      },
      "requiredCounters": requiredCounters
    };

    return this.http.post<Counter[]>(`${environment.apiUrl}/` + 'v1/counters/query', request).pipe(map(
      data => {
        return data;
      },
      error => {
        console.log(error);
      }
    ));
  }

  /**
   * Same as graph-on-demand.service.ts
   */
  private getGranularity(start: number, end: number): any {

    // Compute granularity
    let durationInDays = (end - start) / (24 * 3600 * 1000);

    let granularity: string;
    let alternativeGranularity: string;
    let alternativeLowerGranularity: string;
    let alternativeRangeExtension: number = 0;

    if (durationInDays < 1) {
      granularity = "MINUTELY";
      alternativeGranularity = "HOURLY";
      alternativeRangeExtension = 2 * 3600 * 1000;
    } else if (durationInDays < 30) {
      granularity = "HOURLY";
      alternativeGranularity = "DAILY";
      alternativeLowerGranularity = "MINUTELY";
      alternativeRangeExtension = 2 * 24 * 3600 * 1000;
    } else if (durationInDays < 365) {
      granularity = "DAILY";
      alternativeGranularity = "MONTHLY";
      alternativeLowerGranularity = "HOURLY";
      alternativeRangeExtension = 2 * 30 * 24 * 3600 * 1000;
    } else {
      granularity = "DAILY";
      alternativeGranularity = "MONTHLY";
      alternativeLowerGranularity = "HOURLY";
      alternativeRangeExtension = 2 * 30 * 24 * 3600 * 1000;
    }

    let result: any = {};
    result.granularity = granularity;
    result.alternativeGranularity = alternativeGranularity;
    result.alternativeLowerGranularity = alternativeLowerGranularity;
    result.alternativeRangeExtension = alternativeRangeExtension;

    return result;
  }

  getRegionsListObservable(pattern: string, provider: string): Observable<any[]> {
    return of(this.getRegionssList(pattern, provider)).pipe(delay(500));
  }

  private getRegionssList(pattern: string, provider: string): string[] {

    let regions: string[] = [];
  
    this.mgt_svc.getRegions(provider).pipe(first()).subscribe(
			data => {				
        	if(data.length > 0) {
            for(let i in data) {
              let region: any = {
                account: data[i].NAME,
                name: data[i].REGION,
                resourceType: 'REGION',
                uuid: data[i].IDENTIFIER
              };
              regions.push(region);
            }
          }
			}
		);

    return regions;
  }

  getRegionElementsListObservable(pattern: string, provider: string, account: string): Observable<any[]> {
    return of(this.getFilteredRegionElementsList(pattern, provider, account)).pipe(delay(500));
  }

  private getFilteredRegionElementsList(pattern: string, provider: string, account: string): string[] {
    
    let resources = [];
    
    this.mgt_svc.getRegions(provider).pipe(first()).subscribe(
			data => {
        	if(data.length > 0) {
            for(let i in data) {
              if(data[i].NAME === account) {
                let region: any = {
                  name: data[i].REGION,
                  resourceType: 'REGION',
                  uuid: data[i].IDENTIFIER,
                  account: data[i].NAME,
                  father: null
                };
                resources.push(region);
  
                // GET INSTANCES FOR ACCOUNT & REGION
                this.http.get<any>(`${environment.apiUrl}/` + 'greenit/getinstances/' + data[i].NAME.replace('-', '')).pipe(first()).subscribe(
                  data2 => {
                    if(data2.length > 0) {
                      for(let j in data2) {
                        if(data2[j].IDENTIFIER.includes(data[i].IDENTIFIER)) {
                          let instance: any = {
                            uuid: data2[j].IDENTIFIER,
                            iid: data2[j].INSTANCE_ID,
                            name: data2[j].NAME,
                            resourceType: 'INSTANCE',
                            account: data[i].NAME,
                            father: data[i].REGION
                          };
                          resources.push(instance);
                        }
                      }
                    }
                  }
                );
              }
            }
          }
			}
		);

    return resources;
  }

  getCloudData(account: string, uuid: string) : any {

    return this.http.get<any>(`${environment.apiUrl}/` + 'greenit/getdailypower/' + account + '&' + uuid).pipe(map(data => {
      return data;
    }));
  }

  getPowerRegion(pi: any) {
    return this.http.post<any>(`${environment.apiUrl}/greenit/` + 'getpowerregion', pi).pipe(map(result => {
      return result;
    }));
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';

import { ManagementService, ShareService } from '@app/services';

import { Message } from '@app/model';

import { RecommendationComponent } from '@app/recommendation/recommendation.component';
import { AwsComponent } from '@app/provider/aws/aws.component';
import { AzureComponent } from '@app/provider/azure/azure.component';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.css']
})
export class OpportunitiesComponent implements OnInit {

  message: Message;

  @ViewChild('vmwareopp') vmwareopp: RecommendationComponent;
  @ViewChild('awsopp') awsopp: AwsComponent;
  @ViewChild('azureopp') azureopp: AzureComponent;

  isAccountMgtReady: boolean = false;
  isReady: boolean = false;

  isDataReload: boolean = false;


  constructor(
    private mgt_svc: ManagementService,
    private message_svc: ShareService
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  setVmware(): void {

    this.isAccountMgtReady = false;
  }

  setAws(): void {

    this.isAccountMgtReady = false;
		this.isReady = false;
    this.message.cloudProvider = 'aws';
    this.mgt_svc.getRegions('aws').pipe(first()).subscribe(
			data => {
        	if(data.length > 0) {
            this.message.isValidAws = true;
		        setTimeout(() =>  this.initCloud(), 100);
          }
        }
    );
  }

  setAzure(): void {

    
    this.isAccountMgtReady = false;
		this.isReady = false;
    this.message.cloudProvider = 'azure';
    this.mgt_svc.getRegions('azure').pipe(first()).subscribe(
			data => {
        	if(data.length > 0) {
            this.message.isValidAzure = true;
		        setTimeout(() =>  this.initCloud(), 100);
          }
        }
    );
  }

  loadRegion(): void {
	
		this.isDataReload = true;
		setTimeout(() => this.isDataReload = false, 100);
	}

  private initCloud(): void {

    this.isAccountMgtReady = true;
    setTimeout(() =>  this.isReady = true, 100);
	}
}

<clr-tabs>
	<clr-tab>
        	<button clrTabLink (click)="setCard()">SERVER CARD</button>
		<clr-tab-content *clrIfActive="true">
			<div class="card">
				<div class="card-header card-ajust">
					<h5>
						<label>
							<clr-icon shape="host"></clr-icon>
							{{host_synth.name}}
							<span class="label" style="float:right;">{{infra.datacenter}}
								<span class="badge">datacenter</span>
							</span>
							<span class="label clickable addLink" style="float:right;" (click)="callClusterRoute(infra.cluster)">{{infra.cluster}}
								<span class="badge">cluster</span>
							</span>
						</label>
					</h5>
				</div>
				<div class="card-block">
					<div class="clr-row">
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="currentUser.isDashboardT6">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{host_synth.co2}} KgCO2 eq</b>
										<br>
										CO2 emission
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="currentUser.isDashboardT6">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{globalCurrency}} {{host_synth.cost}}</b>
										<br>
										Cost
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{host_synth.cpucap}}</b>
										<br>
										Logical cores
										<br>
										<span class="badge" [ngStyle]="{ 'background-color': host_cons_avg_cpu >= 70 && host_cons_avg_cpu < 90 ? '#c25400' : host_cons_avg_cpu >= 90 ? '#c21d00' : 'grey' }">{{host_cons_avg_cpu}}% average consumption</span>
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{math.round(host_synth.ramcap/1024)}} GB</b>
										<br>
										Memory
										<br>
										<span class="badge" [ngStyle]="{ 'background-color': host_cons_avg_ram >= 70 && host_cons_avg_ram < 90 ? '#c25400' : host_cons_avg_ram >= 90 ? '#c21d00' : 'grey' }">{{host_cons_avg_ram}}% average consumption </span>
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{host_synth.vms}}</b>
										<br>
										<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callVmList()">
											<clr-icon shape="vm"></clr-icon>
											VMs
										</button>
										<br>
										<span class="badge badge-success" style="font-size:12px;" ngClass="addLink" (click)="listVmRun()">run {{host_synth.vms_run}}</span>
										<span class="badge" [ngClass]="host_badge.vm_off" style="font-size:12px;background-color:#c21d00;" (click)="listVmOff()">off {{host_synth.vms_off}}</span>
										<span class="badge" [ngClass]="host_badge.vm_other" style="font-size:12px;background-color:grey;" (click)="listVmOther()">other {{host_synth.vms_oth}}</span>
										<span class="badge" class="badge addLink" style="font-size:12px;color:white;" [ngStyle]="{ 'background-color': tools_state === 'orange' ? '#c25400' : tools_state === 'red' ? '#c21d00' : '#3c8500' }" (click)="callVmList()">tools</span>
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">Status</b>
										<br>
										<div class="clr-row">
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isDcscope">
												<clr-alert [clrAlertType]="trouble_state" [clrAlertSizeSmall]="true" [clrAlertAppLevel]="true" [(clrAlertClosed)]="isNoTsAlert" *ngIf="currentUser.isMenuTrouble">
													<clr-alert-item>
														<span id="server-alert3-text" class="alert-text">
															troubleshooting: nomal
														</span>
													</clr-alert-item>
												</clr-alert>
											</div>
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
												<clr-alert [clrAlertType]="evolution_state" [clrAlertSizeSmall]="true" [clrAlertAppLevel]="true" [(clrAlertClosed)]="isNoEvoAlert">
													<clr-alert-item>
														<span id="server-alert4-text" class="alert-text">
															evolution: normal
														</span>
													</clr-alert-item>
												</clr-alert>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clr-row">
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
					<div class="card">
						<div class="card-header card-ajust">
							<h5>
								<clr-icon shape="id-badge"></clr-icon>
								&nbsp;General information 
							</h5>
						</div>
						<div class="card-block">
							<div class="clr-row">
								<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
									<clr-icon shape="host" size="72" [ngStyle]="{ color: host_synth.tsstate === 'green' ? '#3c8500' : host_synth.tsstate === 'orange' ? '#c25400' : host_synth.tsstate === 'red' ? '#c21d00' : 'grey' }"></clr-icon>
								</div>
								<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
									<label class="clr-control-label">Owner</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<input type="text" placeholder="not registered" class="clr-input" [(ngModel)]="comment.OWNER" (ngModelChange)="updateOwner($event)">
      										</div>
										<clr-control-helper>who is in charge of the SERVER</clr-control-helper>
    									</div>
								</div>
								<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" style="padding-left:50px;">
									<label class="clr-control-label">Environment</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<input type="text" placeholder="not registered" class="clr-input" [(ngModel)]="comment.ENV" (ngModelChange)="updateEnv($event)">
      										</div>
										<clr-control-helper>where the SERVER is running</clr-control-helper>
    									</div>
								</div>
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
								<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
									<form clrForm clrLayout="vertical">
										<label class="clr-control-label">Commissioning date</label>
										<div class="clr-input">
											<input [(clrDate)]="newmoddate" [clrDate]="updateCreateDate()" placeholder="{{comment.CREATE_DATE || 'not registered'}}" id="host-synth-input-create-date" />
      										</div>
										<clr-control-helper>when the SERVER has been commissioned</clr-control-helper>
									</form>
								</div>
								<div id="csstoadjust" class="clr-col-lg-6 clr-col-md-6 clr-col-12" style="padding-top:5px;">
									<label class="clr-control-label">Maintenance date</label>
									<div class="clr-input">
										<input [(clrDate)]="newenddate" [clrDate]="updateEndDate()" placeholder="{{comment.END_DATE || 'not registered'}}" id="host-synth-input-end-date" />
      									</div>
									<clr-control-helper>when is the scheduled maintenance date</clr-control-helper>
								</div>
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
								<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
									<label class="clr-control-label">DCScope comment</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<input type="text" placeholder="not registered" class="clr-input" [(ngModel)]="comment.COMMENT" (ngModelChange)="updateComment($event)">
      										</div>
										<clr-control-helper>shared between users</clr-control-helper>
    									</div>
								</div>
								<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
									<label class="clr-control-label">Model</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<span class="clr-input">{{host_synth.model}}</span>
      										</div>
										<clr-control-helper>ESX server</clr-control-helper>
									</div>
								</div>
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
								<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
									<label class="clr-control-label">Operating system</label>
									<div class="clr-control-container">
										<div class="clr-input-wrapper">
											<span class="clr-input">{{host_synth.os}}</span>
      										</div>
										<clr-control-helper>ESX version</clr-control-helper>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
					<div class="card">
						<div class="card-header card-ajust">
							<h5>
								<clr-icon shape="resource-pool"></clr-icon>
								&nbsp;Resources information
							</h5>
						</div>
						<div class="card-block">
							<div class="card-text">
								<clr-tabs>
									<clr-tab>
        									<button clrTabLink (click)="setAllocation()">Allocation</button>
										<clr-tab-content *clrIfActive="true">
											<div class="clr-row">
												<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
													<highcharts-chart
														[Highcharts]="Highcharts"
														[constructorType]="'chart'"
														[options]="chartOptions"
														style="width: 100%; height: 100%; display: block;" *ngIf="isReady">
													</highcharts-chart>
												</div>
												<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
													<highcharts-chart
														[Highcharts]="Highcharts2"
														[constructorType]="'chart'"
														[options]="chartOptions2"
														style="width: 100%; height: 100%; display: block;" *ngIf="isReady2">
													</highcharts-chart>
												</div>
												<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
													<form clrForm clrLayout="vertical">
														<div class="clr-input">
															<label>
																CPU commitment
															</label>
      														</div>
														<span class="badge" [ngClass]="host_badge.cpu_commitment" style="font-size:12px;">{{host_synth.cpu_oc_tx}}%</span>
														<clr-control-helper>the overcommitment rate (vcpu/threads)</clr-control-helper>
													</form>
												</div>
												<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
													<form clrForm clrLayout="vertical">
														<div class="clr-input">
															<label>
																RAM commitment
															</label>
      														</div>
														<span class="badge" [ngClass]="host_badge.ram_commitment" style="font-size:12px;">{{host_synth.ram_oc_tx}}%</span>
														<clr-control-helper>the overcommitment rate (vram/ram server capacity)</clr-control-helper>
													</form>
												</div>
											</div>
        									</clr-tab-content>
    									</clr-tab>
									<clr-tab>
        									<button clrTabLink (click)="setDistribution()">Distribution</button>
										<clr-tab-content *clrIfActive="false">
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
											<highcharts-chart
												[Highcharts]="Highcharts"
												[constructorType]="'chart'"
												[options]="chartOptions"
												style="width: 100%; height: 100%; display: block;" *ngIf="isReady3">
											</highcharts-chart>
        									</clr-tab-content>
    									</clr-tab>
									<clr-tab>
        									<button clrTabLink (click)="setConsumption(true)">Consumption</button>
										<clr-tab-content *clrIfActive="false">
											<div class="clr-row">
												<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
													<clr-radio-container clrInline>
														<clr-radio-wrapper>
															<input id="server-radio-avgval" type="radio" clrRadio name="options" required (change)="setConsumption($event)" [(ngModel)]="isAvgMaxValue" [value]=true />
															<label>average</label>
  														</clr-radio-wrapper>
														<clr-radio-wrapper>
															<input id="server-radio-maxval" type="radio" clrRadio name="options" required (change)="setConsumption($event)" [(ngModel)]="isAvgMaxValue" [value]=false />
															<label>maximum</label>
														</clr-radio-wrapper>
														<clr-control-helper>display according to the average consumption values or according to the maximums</clr-control-helper>
														<clr-control-error>This field is required!</clr-control-error>
													</clr-radio-container>
												</div>
												<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
												<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
													<div class="clr-row">
														<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
															<highcharts-chart
																[Highcharts]="Highcharts"
																[constructorType]="'chart'"
																[options]="chartOptions"
																style="width: 100%; height: 100%; display: block;" *ngIf="isReady">
															</highcharts-chart>
														</div>
														<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
															<highcharts-chart
																[Highcharts]="Highcharts2"
																[constructorType]="'chart'"
																[options]="chartOptions2"
																style="width: 100%; height: 100%; display: block;" *ngIf="isReady2">
															</highcharts-chart>
														</div>
														<highcharts-chart
															[Highcharts]="Highcharts"
															[constructorType]="'chart'"
															[options]="chartOptions"
															style="width: 100%; height: 100%; display: block;" *ngIf="isReady3">
														</highcharts-chart>
													</div>
												</div>
											</div>
        									</clr-tab-content>
    									</clr-tab>
									<clr-tab>
        									<button clrTabLink (click)="setEvolution()">Evolution</button>
										<clr-tab-content *clrIfActive="false">
											<ng-template load-evolution></ng-template>
        									</clr-tab-content>
    									</clr-tab>
									<clr-tab *ngIf="currentUser.isMenuTrouble && isDcscope">
        									<button clrTabLink (click)="setTroubleshooting()">Troubleshooting</button>
										<clr-tab-content *clrIfActive="false">
											<ng-template load-ts></ng-template>
        									</clr-tab-content>
    									</clr-tab>
    									<clr-tab>
        									<button clrTabLink>Tools</button>
        									<clr-tab-content *clrIfActive="false">
											<div class="clr-row">
												<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
													<form clrForm clrLayout="vertical">
														<div class="clr-input">
															<label>
																TOOLS
															</label>
      														</div>
														<span class="badge badge-info" style="font-size:12px;" ngClass="addLink" (click)="callVmList()">{{host_synth.tools}}</span>
														<clr-control-helper>VMWare tools</clr-control-helper>
													</form>
												</div>
												<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
													<form clrForm clrLayout="vertical">
														<div class="clr-input">
															<label>
																TO ENABLE
															</label>
      														</div>
														<span class="badge" [ngClass]="host_badge.tools_te" style="font-size:12px;" (click)="listToolsTe()">{{host_synth.tools_te}}</span>
														<clr-control-helper>VMWare tools to enable</clr-control-helper>
													</form>
												</div>
												<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
													<form clrForm clrLayout="vertical">
														<div class="clr-input">
															<label>
																TO INSTALL
															</label>
      														</div>
														<span class="badge" [ngClass]="host_badge.tools_ti" style="font-size:12px;" (click)="listToolsTi()">{{host_synth.tools_ti}}</span>
														<clr-control-helper>VMWare tools to install</clr-control-helper>
													</form>
												</div>
												<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
													<form clrForm clrLayout="vertical">
														<div class="clr-input">
															<label>
																TO UPDATE
															</label>
      														</div>
														<span class="badge" [ngClass]="host_badge.tools_tu" style="font-size:12px;" (click)="listToolsTu()">{{host_synth.tools_tu}}</span>
														<clr-control-helper>VMWare tools to update</clr-control-helper>
													</form>
												</div>
											</div>
        									</clr-tab-content>
    									</clr-tab>
								</clr-tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
        	</clr-tab-content>
    	</clr-tab>
	<clr-tab>
        <button clrTabLink (click)="setCalCons()">CONSUMPTION PROFILE</button>
        <clr-tab-content *clrIfActive="false">
			<ng-template load-cons-cal></ng-template>
        </clr-tab-content>
	</clr-tab>
	<!--<clr-tab>
        	<button clrTabLink (click)="setOverallCons()">OVERALL consumption</button>
        	<clr-tab-content *clrIfActive="false">
			<div class="card">
				<div class="card-header card-ajust">
					<h5>
						<clr-icon shape="table"></clr-icon>
						&nbsp;Server consumption tables for {{message.currentName}}
					</h5>
					<span class="export"></span>
					<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					<span class="search"></span>
				</div>
				<div class="card-block">
					<ng-template load-host-consover></ng-template>
				</div>
			</div>
        	</clr-tab-content>
	</clr-tab>
	<clr-tab>
        	<button clrTabLink (click)="setWeeklyCons()">WEEKLY consumption</button>
        	<clr-tab-content *clrIfActive="false">
			<ng-template load-weekly-cal></ng-template>
        	</clr-tab-content>
	</clr-tab>
    	<clr-tab>
        	<button clrTabLink (click)="setMonthlyCons()">MONTHLY consumption</button>
        	<clr-tab-content *clrIfActive="false">
			<ng-template load-monthly-cal></ng-template>
        	</clr-tab-content>
    	</clr-tab>
    	<clr-tab>
        	<button clrTabLink (click)="setYearlyCons()">YEARLY consumption</button>
        	<clr-tab-content *clrIfActive="false">
			<ng-template load-yearly-cal></ng-template>
        	</clr-tab-content>
    	</clr-tab>-->
</clr-tabs>
<clr-modal [(clrModalOpen)]="isModalVmList" [clrModalSize]="'xl'">
	<h3 class="modal-title">The VM list for {{message.currentName}}</h3>
	<div class="modal-body">
		<ng-template load-vm-list></ng-template>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="isModalVmList = false">OK</button>
	</div>
</clr-modal>

<div class="login-wrapper login-wrapper-background">
    <form class="login" (ngSubmit)="onSubmit()">
        <section class="title">
            <h3 class="welcome">Welcome to</h3>
            <img *ngIf="isDcscope" src="/assets/img/logo-login.svg" class="img-logo-login" />
            <img *ngIf="isCo2scope" src="/assets/img/logo-login_co2scope.svg" class="img-logo-login" />
        </section>
        <div class="login-group">

            <clr-select-container>
                <select clrSelect name="type" [(ngModel)]="form.type">
                    <option [value]="local">Local Account</option>
                    <option [value]="ldap">LDAP</option>
                </select>
            </clr-select-container>

            <clr-input-container>
                <label class="clr-sr-only">Username</label>
                <input type="text" name="username" clrInput placeholder="Username" [(ngModel)]="form.username" />
            </clr-input-container>

            <clr-password-container>
                <label class="clr-sr-only">Password</label>
                <input type="password" name="password" clrPassword placeholder="Password" [(ngModel)]="form.password" />
            </clr-password-container>

            <clr-alert *ngIf="waiting" [clrAlertType]="'info'" [clrAlertAppLevel]=true [clrAlertClosable]=false>
                <clr-alert-item>
                    <span class="alert-text">
                        Connecting, please wait ...
                    </span>
                </clr-alert-item>
            </clr-alert>

            <div class="error active" *ngIf="error">
                {{error_message}}
            </div>

            <button style="margin-top:10%;" type="submit" class="btn btn-primary" [disabled]="waiting || form.password.length == 0">LOGIN</button>
        </div>
    </form>
</div>

<!-- MODAL ALIAS -->
<clr-modal [(clrModalOpen)]="alias_modal" [clrModalSize]="'l'">
    <div class="modal-body" style="min-height:100px;">
        <div class="alert alert-warning" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                    </div>
                    <span class="alert-text" style="font-size:13px;text-align:justify;">
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;Your account is associated with multiple users.</b><br>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;Please choose the one you want to connect with.</b>
                    </span>
                </div>
            </div>
        </div>
        <clr-datagrid style="margin-top:20px;margin-bottom:20px;" [(clrDgSingleSelected)]="alias_selected">
			<clr-dg-column [clrDgField]="'user'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">USER</ng-container></clr-dg-column>
			<clr-dg-column [clrDgField]="'group'"><ng-container *clrDgHideableColumn="{hidden: false}">GROUP</ng-container></clr-dg-column>
									
			<clr-dg-row *clrDgItems="let alias of aliases" [clrDgItem]="alias">
				<clr-dg-cell>{{alias.alias_login}}</clr-dg-cell>
				<clr-dg-cell>{{alias.user_login}}</clr-dg-cell>
			</clr-dg-row>
		</clr-datagrid>
    </div>
    <div class="modal-footer">
        <button type="button"  [disabled]="alias_selected == undefined"  class="btn btn-success" (click)="confirmAlias()">Confirm</button>
    </div>
</clr-modal>

<app-monitor #monitor></app-monitor>

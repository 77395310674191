<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<highcharts-chart
			[Highcharts]="Highcharts"
			[constructorType]="'chart'"
			[options]="chartOptions"
			style="width: 100%; height: 100%; display: block;" *ngIf="isReady">
		</highcharts-chart>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="clr-row">
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
				<form clrForm clrLayout="vertical">
					<span class="badge" style="background-color:#046380;font-size:12px;">{{host_prediction.NBVM_CAP}}</span>
					<div class="clr-input">
						<label>VM that can be created</label>
						&nbsp;&nbsp;<clr-icon shape="warning-standard" [ngClass]="host_badge.nbvm_class" size="18" *ngIf="host_badge.nbvm_cap"></clr-icon>
      					</div>
					<clr-control-helper>based on allocation</clr-control-helper>
					<clr-control-helper>vcpu condition: 4 per logical core</clr-control-helper>
					<clr-control-helper>vram condition: 100% of the server's ram capacity</clr-control-helper>
				</form>
			</div>
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
				<form clrForm clrLayout="vertical">
					<span class="badge" style="background-color:grey;font-size:12px;">{{host_prediction.NBVM_CAPMAX}}</span>
					<div class="clr-input">
						<label>VM that can be created</label>
						&nbsp;&nbsp;<clr-icon shape="warning-standard" [ngClass]="host_badge.nbvmmax_class" size="18" *ngIf="host_badge.nbvm_capmax"></clr-icon>
      					</div>
					<clr-control-helper>based on allocation</clr-control-helper>
					<clr-control-helper>vcpu condition: {{host_prediction.CPU_RATIO}} per logical core</clr-control-helper>
					<clr-control-helper>vram condition: {{host_prediction.RAM_RATIO}}% of the server's ram capacity</clr-control-helper>
				</form>
			</div>
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
				<form clrForm clrLayout="vertical">
					<span class="badge" style="background-color:#5EA11A;font-size:12px;">{{host_prediction.NBVM_CONS}}</span>
					<div class="clr-input">
						<label>VM that can be created</label>
						&nbsp;&nbsp;<clr-icon shape="warning-standard" [ngClass]="host_badge.nbvmcons_class" size="18" *ngIf="host_badge.nbvm_cons"></clr-icon>
      					</div>
					<clr-control-helper>based on average consumption</clr-control-helper>
				</form>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="clr-row">
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
				<form clrForm clrLayout="vertical">
					<div class="clr-input">
						<label>CPU saturation</label>
      					</div>
					<span class="badge" [ngClass]="host_badge.cpu_sat" style="font-size:12px;">{{host_prediction.CPU_DATE_SAT}}</span>
					<clr-control-helper>the server CPU saturation date</clr-control-helper>
				</form>
			</div>
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
				<form clrForm clrLayout="vertical">
					<div class="clr-input">
						<label>RAM saturation</label>
      					</div>
					<span class="badge" [ngClass]="host_badge.ram_sat" style="font-size:12px;">{{host_prediction.RAM_DATE_SAT}}</span>
					<clr-control-helper>the server RAM saturation date</clr-control-helper>
				</form>
			</div>
		</div>
	</div>
</div>

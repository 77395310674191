import { Component, EventEmitter, OnInit } from '@angular/core';
import { Chart } from 'highcharts';
import { ClrDatagridComparatorInterface, ClrLoadingState } from "@clr/angular";
import { JsonloaderService, LicenseService, ShareService } from "@app/services";
import { ActivatedRoute } from "@angular/router";
import { faAws, faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { faHouseUser } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import * as Highcharts from 'highcharts';

import { CloudPricingService } from "@app/services/cloud-pricing.service";
import { CloudCarbonService } from '@app/services/cloud-carbon.service';

class TotalCostsComparator implements ClrDatagridComparatorInterface<any> {
	compare(a: any, b: any) {
		return (a.computeCost + a.storageCost) - (b.computeCost + b.storageCost);
	}
}

class TotalCostsSavingComparator implements ClrDatagridComparatorInterface<any> {
	compare(a: any, b: any) {
		return (a.savings.compute + a.savings.storage) - (b.savings.compute + b.savings.storage);
	}
}
class TotalEmissionsComparator implements ClrDatagridComparatorInterface<any> {
	compare(a: any, b: any) {
		return (a.computeEmissions + a.memoryEmissions + a.storageEmissions) - (b.computeEmissions + b.memoryEmissions + b.storageEmissions);
	}
}

class TotalEmissionsSavingComparator implements ClrDatagridComparatorInterface<any> {
	compare(a: any, b: any) {
		return a.savings - b.savings;
	}
}

@Component({
	selector: 'app-cloud',
	templateUrl: './cloud.component.html',
	styleUrls: ['./cloud.component.css']
})
export class CloudComponent implements OnInit {

	providersWithoutCarbon = ["ovh"];

	// Display parameters
	dateLastUpdatePrices = "checking";
	dateLastUpdateEmissions = "checking";

	fetchState: ClrLoadingState = ClrLoadingState.DEFAULT;
	isLoading = true;
	informationModalOpened = false;
	changeRegionModalOpened = false;
	debug = true;
	regionName = "";

	// Common cloud parameters
	period = "year";
	isRecoEnabled = false;
	providerName = "";
	region = "";
	infoUpdate: any;

	// Cloud pricing parameters
	currencyOption = "USD";
	currencySymbol = "$";
	billingType = "od";
	exchangeRates: any;

	// Data structures
	onPremiseCost = {
		"compute": 0,
		"storage": 0,
		"total": 0
	};

	onPremiseEmissions = {
		"computeEmissions": 0,
		"greyEmissions": 0,
		"totalEmissions": 0,
	};

	providerCosts = {
		"compute": 0,
		"storage": 0,
		"total": 0,
		"listedVMs": [],
		"faultyVMs": []
	};

	providerEmissions = {
		"computeEmissions": 0,
		"greyEmissions": 0,
		"totalEmissions": 0,
		"listedVMs": [],
		"faultyVMs": []
	};

	economy = {
		"computeCost": 0,
		"storageCost": 0,
		"totalCost": 0,
		"computeEmissions": 0,
		"greyEmissions": 0,
		"totalEmissions": 0
	};

	synthesis = {};

	// Cloud provider icons
	overviewIcon = faEye;
	awsIcon = faAws;
	azureIcon = faMicrosoft;
	gcpIcon = faGoogle;
	myproviderIcon = faHouseUser;

	// Comparators initialization
	totalCostsComparator: any = new TotalCostsComparator();
	totalCostsSavingComparator: any = new TotalCostsSavingComparator();
	totalEmissionsComparator: any = new TotalEmissionsComparator();
	totalEmissionsSavingComparator: any = new TotalEmissionsSavingComparator();

	// Highcharts variables and other initializations required
	Highcharts: typeof Highcharts = Highcharts;
	chartOption = "OS";

	private computeChart: Chart;
	computeChartOptions: Highcharts.Options;
	updateComputeChartFlag = true;
	computeChartReady = false;
	onReady: EventEmitter<any> = new EventEmitter();

	defaultColors = {
		win: "#f7a35c",
		linux: "#7cb5ec",
		rhel: "#434348"
	}

	colors = ["#90ed7d", "#8085e9", "#f15c80", "#e4d354",
		"#2b908f", "#f45b5b", "#91e8e1"];

	// My provider informations
	numberOfVmTypes = 0;
	numberOfStorageTypes = 0;

	// Variables that are used to enable the region change
	availableProviders = {
		"aws": "Amazon AWS",
		"az": "Azure",
		"gcp": "Google Compute platform",
		"ovh": "OVH",
		"myprovider": "My provider"
	};
	availableRegionsSave;
	availableRegions;
	availableSelectedProvider = "";
	availableSelectedRegion = "";
	regionSelectionIsDisabled = false;

	// Miscellaneous
	shouldUpdateFilters = new EventEmitter();

	constructor(public jsonLoaderService: JsonloaderService, public licenceService: LicenseService, public route: ActivatedRoute, public cloudCarbonService: CloudCarbonService, public cloudPricingService: CloudPricingService, public shareService: ShareService) {
		// Ensure that parameters that may have been set previously are kept
		this.shareService.currentMessage.subscribe((msg) => {
			this.currencyOption = msg.cloudPricing.currency;
			this.period = msg.cloudPricing.period;
			this.billingType = msg.cloudPricing.billingType;
			this.isRecoEnabled = msg.cloudPricing.applyRecommendations;
			this.period = msg.cloudPricing.period;
			this.isRecoEnabled = msg.cloudPricing.applyRecommendations;
		});

		this.route.params.subscribe(params => {
			this.providerName = params.provider;
			this.region = params.region;
			this.reloadUi();
		});

		// Ensure that filter changes are taken into account
		this.jsonLoaderService.eventJsonAsyncLoaded.subscribe(json => {
			this.reloadUi();
		});
	}

	keepCloudImpactParameters() {
		// Ensure that parameters that may have been set previously are kept
		this.shareService.currentMessage.subscribe((msg) => {
			msg.cloudPricing.currency = this.currencyOption;
			this.currencySymbol = this.currencyOption == "USD" ? "$" : "€";
			msg.cloudPricing.period = this.period;
			msg.cloudPricing.billingType = this.billingType;
			msg.cloudPricing.applyRecommendations = this.isRecoEnabled;
			msg.cloudPricing.period = this.period;
			msg.cloudPricing.applyRecommendations = this.isRecoEnabled;
		});
	}

	myProviderShouldBeConfigured = () => {
		return this.providerName == "myprovider" && (this.numberOfVmTypes == 0 || this.numberOfStorageTypes == 0);
	}

	ngOnInit(): void {
	}

	reloadUi(): void {
		this.isLoading = true;
		// Fix currency symbol
		this.currencySymbol = this.currencyOption == "USD" ? "$" : "€";
		// Reload fresh data
		this.jsonLoaderService.currentJsonSubject.subscribe(json => {
			// Fetch the date of the last price update
			this.cloudPricingService.fetchLastUpdateDetails().subscribe(lastUpdateDetailsData => {
				// @ts-ignore
				if (lastUpdateDetailsData.date !== undefined) {
					// @ts-ignore
					this.dateLastUpdatePrices = lastUpdateDetailsData.date;
				} else {
					this.dateLastUpdatePrices = "unknown";
				}
			});
			// Fetch the date of the last price update
			this.cloudCarbonService.fetchLastUpdateDetails().subscribe(lastUpdateDetailsData => {
				// @ts-ignore
				if (lastUpdateDetailsData.date !== undefined) {
					// @ts-ignore
					this.dateLastUpdateEmissions = lastUpdateDetailsData.date;
				} else {
					this.dateLastUpdateEmissions = "unknown";
				}
			});
			this.cloudCarbonService.fetchProviderDetails(this.providerName).subscribe(cloudCarbonData => {
				this.cloudPricingService.fetchProviderDetails(this.providerName).subscribe(cloudPricingData => {
					this.reloadData(json, cloudCarbonData, cloudPricingData);
					// Force the refresh of all filters in the datagrid
					this.shouldUpdateFilters.emit(this.providerEmissions.listedVMs);
				});
			});
		});
	}

	reloadData(json, cloudCarbonData, cloudPricingData): void {
		this.resetData();

		this.regionName = cloudPricingData["regions"][this.region];

		// Populate list of regions for "regionChange" modal
		this.availableRegionsSave = cloudPricingData["regions"];

		this.numberOfVmTypes = Object.keys(cloudPricingData.computeTypes).length;
		this.numberOfStorageTypes = Object.keys(cloudPricingData.storageTypes).length;

		let mapOS = cloudPricingData["mapOs"] || {
			"Microsoft_Windows": "win",
			"Debian": "linux",
			"Ubuntu": "linux",
			"SUSE": "sles",
			"Red_Hat": "rhel",
			"CentOS": "linux"
		};

		this.infoUpdate = json.priceUpdateInfo;
		this.exchangeRates = json.priceExchangeRates;

		switch (this.isRecoEnabled) {
			case false:
				this.providerCosts = this.cloudPricingService.computeProviderCosts(json.vmComputeStorage, cloudPricingData, json.costAllocated, this.region, this.billingType, mapOS, this.exchangeRates, this.currencyOption, this.period);
				if (!this.providersWithoutCarbon.includes(this.providerName)) {
					try {
						this.providerEmissions = this.cloudCarbonService.computeProviderEmissions(json.vmComputeStorage, json.vmConsover, cloudCarbonData, cloudPricingData, json.greenitPower, this.region, this.period);
					} catch (e) {
						this.providersWithoutCarbon.push(this.providerName);
					}
				}
				break;

			case true:
				this.providerCosts = this.cloudPricingService.computeProviderCosts(json.vmComputeStorageReco, cloudPricingData, json.costAllocated, this.region, this.billingType, mapOS, this.exchangeRates, this.currencyOption, this.period);
				if (!this.providersWithoutCarbon.includes(this.providerName)) {
					try {
						this.providerEmissions = this.cloudCarbonService.computeProviderEmissions(json.vmComputeStorageReco, json.vmConsover, cloudCarbonData, cloudPricingData, json.greenitPower, this.region, this.period);
					} catch (e) {
						this.providersWithoutCarbon.push(this.providerName);
					}
				}
				break;
		}

		// First update date of "onpremise" provider. Unlike other providers, its data is based on date used for the
		// T6 graph in the dashboard page.
		let exchangeRate = json.priceExchangeRates.currency[this.currencyOption].rate;
		// The variable 'exchangeRate' is a ratio used to transform costs from dollars to euros. Regarding 'onpremise
		// costs', they are specified in euros in the json files, thus we need to do the conversion in the opposite
		// direction:
		//   - if selected currency is "EUR": as costs is already in euros, ratio is 1.0
		//   - if selected currency is "USD": as costs is already in euros, ratio is 1.0 / (ratio_USD_to_EUR)
		let onPremiseExchangeRate = this.currencyOption === "EUR" ? 1.0 : 1.0 / json.priceExchangeRates.currency["EUR"].rate;

		this.onPremiseCost.total = json.t6[0].allocated * onPremiseExchangeRate;
		this.onPremiseCost.compute = 0;
		this.onPremiseCost.storage = 0;
		let test = 0;
		for (let elt of json.costAllocated) {
			this.onPremiseCost.compute += elt.server_cost * onPremiseExchangeRate + elt.cpu_cost * onPremiseExchangeRate + elt.ram_cost * onPremiseExchangeRate;
			this.onPremiseCost.storage += elt.sto_cost * onPremiseExchangeRate;
			test += elt.total_cost * onPremiseExchangeRate;
		}

		this.onPremiseEmissions.computeEmissions = 0;
		this.onPremiseEmissions.greyEmissions = 0;
		this.onPremiseEmissions.totalEmissions = 0;
		for (let server of json.greenitPower) {
				this.onPremiseEmissions.computeEmissions += Number(server.CO2);
				this.onPremiseEmissions.greyEmissions += Number(server.CO2G);
				this.onPremiseEmissions.totalEmissions += Number(server.CO2) + Number(server.CO2G);
		}

		this.economy.computeCost = this.onPremiseCost.compute - this.providerCosts.compute;
		this.economy.storageCost = this.onPremiseCost.storage - this.providerCosts.storage;
		this.economy.totalCost = this.onPremiseCost.total - this.providerCosts.total;
		this.economy.computeEmissions = this.onPremiseEmissions.computeEmissions - this.providerEmissions.computeEmissions;
		this.economy.greyEmissions = this.onPremiseEmissions.greyEmissions - this.providerEmissions.greyEmissions;
		this.economy.totalEmissions = this.onPremiseEmissions.totalEmissions - this.providerEmissions.totalEmissions;

		// Update the bar chart
		setTimeout(() => {
			this.buildComputeCard(cloudPricingData, this.providerCosts, this.providerEmissions);
			this.isLoading = false;
		}, 500);
	}

	resetData() {
		this.onPremiseCost = {
			"compute": 0,
			"storage": 0,
			"total": 0
		};
	}

	displayChangeRegionModal = () => {
		this.availableSelectedProvider = this.providerName;
		this.availableRegions = this.availableRegionsSave;
		this.availableSelectedRegion = this.region;
		this.changeRegionModalOpened = true;
	}

	selectedRegionHasChanged = () => {
		this.regionSelectionIsDisabled = true;
		this.cloudPricingService.fetchProviderDetails(this.availableSelectedProvider).subscribe((cloudPricingData) => {
			this.availableRegions = cloudPricingData["regions"];
			this.availableSelectedRegion = Object.keys(this.availableRegions)[0];
			this.regionSelectionIsDisabled = false;
		})
	}

	// Exports the data table into a CSV file
	exportPricingCSV() {
		function parsePotentialNumber(value) {
			// If the given value is already a number, return it
			if (!isNaN(value)) {
				return value;
			}

			let valueWithoutSpaces = value.replace(/[^0-9.,]/g, '').replace(",", ".");
			let parsingResult = parseFloat(valueWithoutSpaces);

			// If the given value cannot be parsed as a float then return 0.
			// It the given value can be parsed as a float, return the parsed value.
			if (isNaN(parsingResult)) {
				return 0;
			} else {
				return parsingResult;
			}
		}

		let csvContent = ["Name", "Compute", "Storage", "OperatingSystem", "Compute Cost", "Storage Cost", "Total Cost", "Compute Savings", "Storage Savings", "Total Savings"].join(',') + '\n';
		csvContent += Object.values(this.providerCosts.listedVMs).map(vm =>
			// @ts-ignore
			[vm.name, vm.compute, vm.storage, vm.operatingSystem, vm.computeCost, vm.storageCost, vm.computeCost + vm.storageCost, vm.savings.compute, vm.savings.storage, vm.savings.total].join(",")
		).join('\n');

		let exportedFilenmae = 'cloud-pricing-' + this.providerName + '-' + this.region + ((this.isRecoEnabled) ? '-reco' : '') + '.csv';
		let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		// @ts-ignore
		if (navigator.msSaveBlob) { // IE 10+
			// @ts-ignore
			navigator.msSaveBlob(blob, exportedFilenmae);
		} else {
			let link = document.createElement("a");
			if (link.download !== undefined) { // feature detection
				// Browsers that support HTML5 download attribute
				let url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", exportedFilenmae);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	exportCarbonCSV() {
		function parsePotentialNumber(value) {
			// If the given value is already a number, return it
			if (!isNaN(value)) {
				return value;
			}

			let valueWithoutSpaces = value.replace(/[^0-9.,]/g, '').replace(",", ".");
			let parsingResult = parseFloat(valueWithoutSpaces);

			// If the given value cannot be parsed as a float then return 0.
			// It the given value can be parsed as a float, return the parsed value.
			if (isNaN(parsingResult)) {
				return 0;
			} else {
				return parsingResult;
			}
		}

		let csvContent = ["Name", "Compute", "Operating System", "Compute Emissions", "Grey Emissions", "Total Emissions", "Emissions Savings"].join(',') + '\n';
		csvContent += Object.values(this.providerEmissions.listedVMs).map(vm =>
			// @ts-ignore
			[vm.name, vm.compute, vm.operatingSystem, vm.computeEmissions, vm.greyEmissions, vm.totalEmissions, vm.savings].join(",")
		).join('\n');

		let exportedFilenmae = 'cloud-carbon-' + this.providerName + '-' + this.region + ((this.isRecoEnabled) ? '-reco' : '') + '.csv';
		let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		// @ts-ignore
		if (navigator.msSaveBlob) { // IE 10+
			// @ts-ignore
			navigator.msSaveBlob(blob, exportedFilenmae);
		} else {
			let link = document.createElement("a");
			if (link.download !== undefined) { // feature detection
				// Browsers that support HTML5 download attribute
				let url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", exportedFilenmae);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	// Functions used to generate the graph
	generateComputeTypeData(providerData, providerCosts, providerEmissions): any {
		// Determine which compute types have at least one VM
		let computeTypesWithAtLeastOneVM = Object.entries(providerData.computeTypes)
		.filter(([c_k, _]) => providerCosts.listedVMs.filter(vm => vm.compute == c_k).length > 0)
		.map(([c_k, _]) => c_k);

		switch (this.chartOption) {
			case "OS":
				// Determine which operating systems have at least one VM
				let operatingSystemsWithAtLeastOneVM = Object.entries(providerData.os)
					.filter(([o_k, o_v]) => providerCosts.listedVMs.filter(vm => vm.operatingSystem == o_v).length > 0);
				// .map(([o_k, _]) => o_k);

				// Sort the compute types by the number of vm
				let sortedComputeTypes = computeTypesWithAtLeastOneVM
					.map((c) => [c, providerCosts.listedVMs.filter((vm) => vm.compute == c).length])
					.sort(([c1, c1_count], [_, c2_count]) => +c2_count - +c1_count)
					.map(([c, _]) => c);

				// Compute the UI data
				let computeTypeData = {
					categories: sortedComputeTypes,
					series: []
				};
				for (let [os_k, os_v] of operatingSystemsWithAtLeastOneVM) {
					let currentOsTuple = {
						name: os_v,
						data: [],
					};
					// Set the color of the bar part
					if (Object.keys(this.defaultColors).indexOf(os_k) != -1) {
						currentOsTuple["color"] = this.defaultColors[os_k];
					} else {
						let colorIndex = Object.keys(providerData.os).indexOf(os_k);
						if (colorIndex > -1 && colorIndex <= this.colors.length) {
							currentOsTuple["color"] = this.colors[colorIndex];
						}
					}
					for (let computeType_k of sortedComputeTypes) {
						let matchCount = providerCosts.listedVMs
							.filter((vm) => vm.compute == computeType_k && vm.operatingSystem == os_v)
							.length;
						currentOsTuple.data.push(matchCount);
					}
					computeTypeData.series.push(currentOsTuple);
				}
				return computeTypeData;
			
			case "Cost":
				let data = computeTypesWithAtLeastOneVM
					.map((c) => [c, providerCosts.listedVMs
						.filter((vm) => vm.compute == c)
						.map((c) => c.totalCost)
						.reduce((a, b) => a + b)])
					.sort(([c1, c1_count], [_, c2_count]) => +c2_count - +c1_count);
				let result = {
					categories: data.map(([c, _]) => c),
					series: []
				};
				result.series.push({
					data: data.map(([_, c]) => c),
					color: "#f1c232"
				});
				return result;

			case "Emissions":
				let emit = Object.entries(providerData.computeTypes)
					.filter(([c_k, _]) => providerEmissions.listedVMs.filter(vm => vm.compute == c_k).length > 0)
					.map(([c_k, _]) => c_k)
						.map((c) => [c, providerEmissions.listedVMs
							.filter((vm) => vm.compute == c)
							.map((c) => c.computeEmissions)
							.reduce((a, b) => a + b)])
						.sort(([c1, c1_count], [_, c2_count]) => +c2_count - +c1_count);

				let grey = Object.entries(providerData.computeTypes)
					.filter(([c_k, _]) => providerEmissions.listedVMs.filter(vm => vm.compute == c_k).length > 0)
					.map(([c_k, _]) => c_k)
					.map((c) => [c, providerEmissions.listedVMs
						.filter((vm) => vm.compute == c)
						.map((c) => c.greyEmissions)
						.reduce((a, b) => a + b)])
					.sort(([c1, c1_count], [_, c2_count]) => +c2_count - +c1_count);

				let result1 = {
					categories: emit.map(([c, _]) => c),
					series: []
				};
				result1.series.push({
					name: "Compute Emissions",
					data: emit.map(([b, c]) => [b, c]).sort(([a, b], [c, d]) => d - b),
					color: "#6aa84f"
				});
				result1.series.push({
					name: "Grey Emissions",
					data: grey.map(([b, c]) => [b, c]).sort(([a, b], [c, d]) => d - b),
					color: "#808080"
				});
				return result1;
		};
	};

	buildComputeCard = (providerData, providerCosts, providerEmissions) => {
		let chartData = this.generateComputeTypeData(providerData, providerCosts, providerEmissions);
		let newComputeChartOptions: Highcharts.Options;
		switch(this.chartOption) {
			case "OS":
				newComputeChartOptions = this.generateDefaultHighchartsOptions("Number of Compute Instances", chartData);
				break;
			case "Emissions":
				newComputeChartOptions = this.generateDefaultHighchartsOptions("Cost of each instance type", chartData);
				break;
			case "Cost":
				newComputeChartOptions = this.generateDefaultHighchartsOptions("Emissions of each instance type", chartData);
				break;
		}

		this.computeChartOptions = newComputeChartOptions;
		this.updateComputeChartFlag = true;

		setTimeout(() => {
			// The following check prevents a failure with JEST tests
			try {
				this.computeChart.reflow();
			} catch (e) {
				console.error("error when reflowing compute chart: the chart may not be ready");
			}
		}, 100);
	}

	chartCallbackComputeChart: Highcharts.ChartCallbackFunction = (chart) => {
		this.computeChart = chart;
		this.computeChartReady = true;
		if (this.computeChartReady) {
			this.onReady.emit(this);
		}
	};

	generateDefaultHighchartsOptions(title: string, chartData): Highcharts.Options {
		switch (this.chartOption) {
			case "OS":
				let result: Highcharts.Options = {
					chart: {
						type: 'bar'
					},
					title: {
						text: null
					},
					legend: {
						enabled: true,
						reversed: true
					},
					credits: {
						enabled: false
					},
					xAxis: {
						categories: chartData.categories
					},
					yAxis: {
						min: 0,
						title: {
							text: title
						}
					},
					tooltip: {
						outside: true,
						pointFormat: "{series.name}: <b>{point.y}</b>"
					},
					plotOptions: {
						series: {
							stacking: 'normal'
						}
					},
					series: chartData.series
				};
				return result;

			case "Cost":
				let result1: Highcharts.Options = {
					chart: {
						type: 'bar'
					},
					title: {
						text: null
					},
					legend: {
						enabled: false
					},
					credits: {
						enabled: false
					},
					xAxis: {
					},
					yAxis: {
						min: 0,
						title: {
							text: 'Cost (USD per month)'
						}
					},
					tooltip: {
						outside: true,
						pointFormat: `Cost: <b>{point.y:.2f} ${this.currencySymbol}/month</b>`
					},
					plotOptions: {
						series: {
							stacking: 'normal'
						}
					},
					series: chartData.series
				};
				return result1;

			case "Emissions":
				let result2: Highcharts.Options = {
					chart: {
						type: 'bar'
					},
					title: {
						text: null
					},
					legend: {
						enabled: true,
						reversed: true
					},
					credits: {
						enabled: false
					},
					xAxis: {
					},
					yAxis: {
						min: 0,
						title: {
							text: 'Emissions (kgCO2ph)'
						}
					},
					tooltip: {
						outside: true,
						pointFormat: '{series.name}: <b>{point.y:.2f} kgCO2ph</b>'
					},
					plotOptions: {
						series: {
							stacking: 'normal'
						}
					},
					series: chartData.series
				};
				return result2;
		}
	}

	clickCount = 0;
	handleClickToActivateDebugOfCosts = () => {
		this.clickCount += 1;
		if (this.clickCount % 3 == 0) {
			this.debug = true;
		}
	}

	// Function binded on the reload button
	askToFetchNewData = () => {
		this.fetchState = ClrLoadingState.LOADING;
		this.cloudPricingService.askToFetchNewPrices().subscribe((result) => {
			this.cloudCarbonService.askToFetchNewEmissionsData().subscribe((result) => {
				console.log(result);
				setTimeout(() => {
					this.fetchState = ClrLoadingState.DEFAULT;
					this.reloadUi();
				}, 500);
			});
		});
	}
}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-recommendation-resizing]'
})
export class RecommendationresizingDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {JsonloaderService} from "@app/services/jsonloader.service";
import {of} from 'rxjs';
import {delay} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import {rejects} from "assert";
import { environment } from '@environments/environment';
import * as memoizee from 'memoizee';

// Remember the result for 20 seconds
const MAX_CACHE_AGE = 20 * 1000;

export function memoize() {
	return function(target, key, descriptor) {
		const oldFunction = descriptor.value;
		const newFunction = memoizee(oldFunction, { maxAge: MAX_CACHE_AGE });
		descriptor.value = function () {
			return newFunction.apply(this, arguments);
		};
	};
};

@Injectable({
	providedIn: 'root'
})
export class GraphOnDemandService {
	constructor(private http: HttpClient, private jsonLoaderService: JsonloaderService) {
	}

	allUrls = {
		new: {
			countersQueryUrl: `${environment.apiUrl}/v1/counters/query`
		}
	};
	defautlUrlVersion = "new";

	_getResources(filterString: string, limitResult=null): string[] {
		let resources = [];
		resources.push(this.jsonLoaderService.json.hostSynthesis);
		resources.push(this.jsonLoaderService.json.vmSynthesis);
		let result = resources
			.flat()
			.filter((o) => o.os)
			.filter((o) => (
				o.uuid.toLowerCase().indexOf(filterString.toLowerCase()) > -1
				|| o.name.toLowerCase().indexOf(filterString.toLowerCase()) > -1)
			);
		if (limitResult !== null) {
			result = result.slice(0, limitResult);
		}
		// Add the type of the ressource (VM or HOST)
		for (let resource of result) {
			resource.resourceType = resource.hasOwnProperty("cpucap") ? "host" : "vm";
		}
		return result;
	}

	getResources(filterString: string) {
		return of(this._getResources(filterString, 20)).pipe(
			delay(0)
		);
	}

	_countersConfig() {
		const rollups = ["MIN", "AVG", "MAX"];
		var godCPUDefaultCounter = "CPU_COSTOP";
		var godRAMDefaultCounter = "RAM_VMMEMCTL";
		var godNETDefaultCounter = "NET_IO";
		var godDISKDefaultCounter = "DISK_IO";

		let countersConfig = {
			ram: {
				key: "RAM",
				icon: "fa fa-tasks",
				defaultCounters: [{
					key: godRAMDefaultCounter,
					rollup: "AVG"
				}],
				counters: {
					RAM_CAPACITY: {
						name: 'RAM Capacity',
						unit: 'MB',
						conversionFactor: 0.0009765625,
						rollup: [],
						appliesTo: ["VM", "HOST"]
					},
					RAM_USAGE: {
						name: 'RAM Usage (Host)',
						unit: '%',
						conversionFactor: 0.1,
						rollup: rollups,
						appliesTo: ["HOST"]
					},
					RAM_USAGE_INTRA: {
						name: 'RAM Usage Intra',
						unit: '%',
						conversionFactor: 0.1,
						rollup: rollups,
						appliesTo: ["VM"]
					},
					RAM_USAGE_EXTRA: {
						name: 'RAM Usage Extra',
						unit: '%',
						conversionFactor: 0.1,
						rollup: rollups,
						appliesTo: ["VM"]
					},
					RAM_CONSUMED: {
						name: 'RAM Consumed',
						unit: 'MB',
						conversionFactor: 0.0009765625,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					RAM_ACTIVE: {
						name: 'RAM Active',
						unit: 'MB',
						conversionFactor: 0.0009765625,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					RAM_SHARED: {
						name: 'RAM Shared',
						unit: 'MB',
						conversionFactor: 0.0009765625,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					RAM_VM_MEM_CTL: {
						name: 'RAM Balloon',
						unit: 'MB',
						conversionFactor: 0.0009765625,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					}
				}
			}, cpu: {
				key: "CPU",
				icon: "fa fa-microchip",
				defaultCounters: [{
					key: godCPUDefaultCounter,
					rollup: "AVG"
					//}, {
					//    key: "CPU_USAGE",
					//    rollup: "AVG"
				}],
				counters: {
					CPU_CAPACITY: {
						name: 'CPU Capacity',
						unit: 'MHz',
						conversionFactor: 1,
						rollup: [],
						appliesTo: ["VM", "HOST"]
					},
					CPU_USAGE: {
						name: 'CPU Usage (Host)',
						unit: '%',
						conversionFactor: 0.1,
						rollup: rollups,
						appliesTo: ["HOST"]
					},
					CPU_USAGE_INTRA: {
						name: 'CPU Usage Intra',
						unit: '%',
						conversionFactor: 0.1,
						rollup: rollups,
						appliesTo: ["VM"]
					},
					CPU_USAGE_EXTRA: {
						name: 'CPU Usage Extra',
						unit: '%',
						conversionFactor: 0.1,
						rollup: rollups,
						appliesTo: ["VM"]
					},
					CPU_USAGE_MHZ: {
						name: 'CPU Usage',
						unit: 'MHz',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					CPU_READY: {
						name: 'CPU Ready',
						unit: 'ms',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					CPU_READY_PERCENTAGE: {
						name: 'CPU Ready',
						unit: '%',
						conversionFactor: 0.005,
						rollup: ["AVG", "MAX"],
						appliesTo: ["VM", "HOST"]
					},
					CPU_COSTOP: {
						name: 'CPU CoStop',
						unit: 'ms',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					CPU_RUN: {
						name: 'CPU Run',
						unit: 'ms',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					CPU_WAIT: {
						name: 'CPU Wait',
						unit: 'ms',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					}
				}
			}, disk: {
				key: "DISK",
				icon: "fa fa-hdd-o",
				defaultCounters: [{
					key: godDISKDefaultCounter,
					rollup: "AVG"
				}],
				counters: {
					DISK_IO: {
						name: 'Disk IO',
						unit: 'KB/s',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					DISK_READ_LATENCY: {
						name: 'Disk Read Latency',
						unit: 'ms',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					DISK_WRITE_LATENCY: {
						name: 'Disk Write Latency',
						unit: 'ms',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					DISK_RW_LATENCY: {
						name: 'Disk Total Latency',
						unit: 'ms',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					DISK_CMD_ABORTED: {
						name: 'Disk Commands Aborted',
						unit: '#',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					}
				}
			}, network: {
				key: "NET",
				icon: "fa fa-sitemap",
				defaultCounters: [{
					key: godNETDefaultCounter,
					rollup: "AVG"
				}],
				counters: {
					NET_IO: {
						name: 'Network IO',
						unit: 'KB/s',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					},
					NET_DROPPED: {
						name: 'Packages Dropped',
						unit: '#',
						conversionFactor: 1,
						rollup: rollups,
						appliesTo: ["VM", "HOST"]
					}
				}
			}
		};

		// Add for each counter, its belonging category
		for (let categoryKey of Object.keys(countersConfig)) {
			for (let counterKey of Object.keys(countersConfig[categoryKey].counters)) {
				countersConfig[categoryKey].counters[counterKey].category = categoryKey;
			}
		}

		return countersConfig;
	}

	_getCounters(category: string, filterString: string) {
		let countersConfig = this._countersConfig();
		let categoryCountersConfig = countersConfig[category];

		let result = Object
			.entries(categoryCountersConfig.counters)
			.map(([k, v]) => v["rollup"].length == 0 ? [[k, v, undefined, k]] : v["rollup"].map((r) => [k + " - " + r, v, r, k]))
			.flat()
			.filter(([k, v, r, k_orig]) => `${k} ${v["name"]}`.toLowerCase().indexOf(filterString.toLowerCase()) != -1)
			.map(([k, v, r, k_orig]) => Object({
				name: k,
				label: r != undefined ? `${v["name"]} - ${r} (${v["unit"]})` : `${v["name"]} (${v["unit"]})`,
				description: v,
				metricName: k.indexOf("-") == -1 ? k : (x => x.split(" - ").reverse().join("_"))(k),
				// Hack: add the "metricNameMinutely" in each counters to help GraphOnDemand to find the origin counter from
				// an aggregated counter (i.e. find that "AVG_DISK_IO" should become "DISK_IO" when granularity is set to
				// "minute")
				metricNameMinutely: k_orig
			}));
		return result;
	}

	getCounters(category: string, filterString: string) {
		if (category == "*") {
			let countersCpu = this._getCounters("cpu", filterString);
			let countersRam = this._getCounters("ram", filterString);
			let countersDisk = this._getCounters("disk", filterString);
			let countersNetwork = this._getCounters("network", filterString);
			return of([...countersCpu, ...countersRam, ...countersDisk, ...countersNetwork]).pipe(
				delay(0)
			);
		} else {
			return of(this._getCounters(category, filterString)).pipe(
				delay(0)
			);
		}
	}

	_getDefaultCounters(category: string) {
		let countersConfig = this._countersConfig();
		let categoryCountersConfig = countersConfig[category];

		let defaultCountersNames = categoryCountersConfig
			.defaultCounters
			.map(d => d.rollup != "" ? d.key + " - " + d.rollup : d.key);

		let counters = this._getCounters(category, "");

		let result = counters.filter((c) => defaultCountersNames.indexOf(c.name) != -1);
		return result;
	}

	getDefaultCounters(category: string) {
		return of(this._getDefaultCounters(category)).pipe(
			delay(0)
		);
	}

	CACHE = {}

	@memoize()
	getCountersValues(category: string, selectedResources, filter, minRange, maxRange, requiredCounters, granularity) {
		let hashRequest = JSON.stringify([category, selectedResources, filter, minRange, maxRange, requiredCounters, granularity]);
		let now = Date.now();

		let counterResult = new Observable((subscriber => {
			if (this.CACHE.hasOwnProperty(hashRequest) && (now - this.CACHE[hashRequest]["when"]) < MAX_CACHE_AGE) {
				subscriber.next(this.CACHE[hashRequest]["cachedResponse"]);
				subscriber.complete();
			} else {
				this.http.post(
					this.allUrls[this.defautlUrlVersion]["countersQueryUrl"],
					{
						"aggregationGranularity": granularity,
						"resourceType": category,
						"timeInterval": {
							"startTime": minRange,
							"endTime": maxRange
						},
						"resourceFilter": {
							"fields": [
								"UUID"
							],
							"values": [
								selectedResources
							]
						},
						"requiredCounters": requiredCounters
				}).subscribe((result) => {
					this.CACHE[hashRequest] = {
						"when": Date.now(),
						"cachedResponse": result
					}
					subscriber.next(result);
					subscriber.complete();
				});
			}
		}));

		return counterResult;
	}

	_decideGranularityAndGetCountersValues(category: string, selectedResources, filter = '', minRange, maxRange, requiredCounters = [], extendsWithLowerGranularity = false, extendsWithHigherGranularity = false, resolve, reject) {

		// Compute granularity
		let durationInDays = (maxRange - minRange) / (24 * 3600 * 1000);

		let granularity: string;
		let alternativeGranularity: string;
		let alternativeLowerGranularity: string;
		let alternativeRangeExtension: number = 0;

		if (durationInDays < (30 * (1.0 / 24))) {
			granularity = "MINUTELY";
			alternativeGranularity = "HOURLY";
			alternativeRangeExtension = 2 * 3600 * 1000;
		} else if (durationInDays < 30) {
			granularity = "HOURLY";
			alternativeGranularity = "DAILY";
			alternativeLowerGranularity = "MINUTELY";
			alternativeRangeExtension = 2 * 24 * 3600 * 1000;
		} else if (durationInDays < 365) {
			granularity = "DAILY";
			alternativeGranularity = "MONTHLY";
			alternativeLowerGranularity = "HOURLY";
			alternativeRangeExtension = 2 * 30 * 24 * 3600 * 1000;
		} else {
			granularity = "DAILY";
			alternativeGranularity = "MONTHLY";
			alternativeLowerGranularity = "HOURLY";
			alternativeRangeExtension = 2 * 30 * 24 * 3600 * 1000;
		}

		// let promiseResult = new Promise((resolve, reject) => {
		this.getCountersValues(category, selectedResources, filter, minRange, maxRange, requiredCounters, granularity).subscribe((resp) => {
			// @ts-ignore
			if (resp.length > 0) {
				console.log(`There are some results with granularity ${granularity}`);
				if (! extendsWithLowerGranularity || (extendsWithLowerGranularity && !alternativeLowerGranularity)) {
					resolve(resp);
				} else {
					// @ts-ignore
					const lastItem = resp[resp.length - 1];

					if (lastItem.time === maxRange) {
						resolve(resp);
					} else {
						console.log(`I will try to extend the results (granularity=${granularity}) with one next element from lower granularity (granularity=${extendsWithLowerGranularity})`);
						this.decideGranularityAndGetCountersValues(category, selectedResources, filter, lastItem.time, maxRange, requiredCounters, extendsWithLowerGranularity, false).then((resp2) => {
							let additionalElement = [];
							// @ts-ignore
							if (resp2.length > 0) {
								// @ts-ignore
								additionalElement.push(resp2[resp2.length -1]);
							}
							// @ts-ignore
							resolve(resp.concat(additionalElement));
						}).catch((reason) => {
							console.error(`cannot fetch more precise results: ${reason}`);
							console.error('I am sending the results with an higher granularity');
							resolve(resp);
						});
					}
				}
			} else {
				console.log(`There are no results with granularity ${granularity}, I will try with an alternative granularity ${alternativeGranularity}`);
				this.getCountersValues(category, selectedResources, filter, minRange - alternativeRangeExtension, maxRange + alternativeRangeExtension, requiredCounters, alternativeGranularity).subscribe((resp2) => {
					resolve(resp2);
				});
			}
		})
	}

	decideGranularityAndGetCountersValues(category: string, selectedResources, filter = '', minRange, maxRange, requiredCounters = [], extendsWithLowerGranularity = false, extendsWithHigherGranularity = false) {
		let promiseResult = new Promise((resolve, reject) => {
			this._decideGranularityAndGetCountersValues(category, selectedResources, filter, minRange, maxRange, requiredCounters, extendsWithLowerGranularity, extendsWithHigherGranularity, resolve, reject);
		});
		return promiseResult;
	}

	fetchSqlData(selectedResources: string[], minRange: number, maxRange: number, requiredCounters = [], extendsWithLowerGranularity: boolean = true): Observable<Object> {
		let observables = selectedResources.map((r) => {
			return this.decideGranularityAndGetCountersValues(r["resourceType"].toUpperCase(), [r["uuid"]], "", minRange, maxRange, requiredCounters, extendsWithLowerGranularity)
		});
		return forkJoin(observables);
	}

	fetchData(selectedResources: string[], requiredCounters = []): Observable<Object> {
		let minRange = 0;
		let maxRange = 2553270400000;
		return this.fetchSqlData(selectedResources, minRange, maxRange, requiredCounters);
	}

	errorHandler(error: HttpErrorResponse) {
		return throwError(error.message || "server error.");
	}
}

<div class="card">
	<div class="card-header">
		<div class="clr-row">
			<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
				<span style="color:grey;font-size:24px;">Operation</span>
			</div>
			<div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
				<span [ngClass]="zombie_class" (click)="filterReco('zombie')" style="float:right;">
					Zombies
					<span class="badge">{{nb_zombie}}</span>
				</span>
				<span [ngClass]="tools_class" (click)="filterReco('tools')" style="float:right;">
					Tools
					<span class="badge">{{nb_tools}}</span>
				</span>
				<span [ngClass]="oversized_class" (click)="filterReco('oversized')" style="float:right;">
					Oversized
					<span class="badge">{{nb_oversized}}</span>
				</span>
				<span [ngClass]="busy_class" (click)="filterReco('busy')" style="float:right;">
					Undersized
					<span class="badge">{{nb_busy}}</span>
				</span>
				<span [ngClass]="normal_class" (click)="filterReco('normal')" style="float:right;">
					Normal
					<span class="badge">{{nb_normal}}</span>
				</span>
				<span [ngClass]="lazy_class" (click)="filterReco('lazy')" style="float:right;">
					Lazy
					<span class="badge">{{nb_lazy}}</span>
				</span>
				<span [ngClass]="idle_class" (click)="filterReco('idle')" style="float:right;">
					Idle
					<span class="badge">{{nb_idle}}</span>
				</span>
				<span [ngClass]="all_class" (click)="filterReco('all')" style="float:right;">
					All
					<span class="badge">{{reco_vms_data.length}}</span>
				</span>
			</div>
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		</div>
		<span class="search"></span>
	</div>
	<div class="card-block">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<table id="reco-operation-list" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
			<thead>
				<tr>
					<th>VM</th>
					<th>Action to apply</th>
					<th>Modification date</th>
					<th>Tools</th>
					<th>State</th>
					<th>Behavior</th>
					<th *ngIf="tm_valid">Daily behaviors (%)
						<clr-tooltip>
							<clr-icon clrTooltipTrigger shape="info-circle" size="24" aria-label="Uniq tooltip 6"></clr-icon>
							<clr-tooltip-content clrPosition="top-right" clrSize="xs" *clrIfOpen style="background-color:white;outline-style:solid;outline-color:black;outline-width: thin;outline-offset:-1px">
								<span style="color:black">Legend</span>
								<p>idle<code class="idle" style="width:95%"></code>
								lazy<code class="lazy" style="width:95%"></code>
								normal<code class="normal" style="width:95%"></code>
								oversized<code class="oversized" style="width:95%"></code>
								undesized<code class="undersized" style="width:95%"></code>
								busy<code class="busy" style="width:95%"></code></p>
							</clr-tooltip-content>
						</clr-tooltip>
					</th>
				</tr>
			</thead>
			<tbody>
    				<tr *ngFor="let reco of reco_model">
					<td>{{reco.name}}</td>
					<td>{{reco.reco_behavior}}</td>
					<td [attr.data-sort]="reco.date">{{moment(reco.date).format('MMMM Do YYYY')}}</td>
					<td>{{reco.reco_tools}}</td>
					<td>{{reco.state}}</td>
					<td>{{reco.behavior}}</td>
					<td *ngIf="tm_valid">
						<div class="progress-block" style="width:80%;padding-left:40px;">
							<div class="busy" [style.width.%]="reco.time_percent.busy" *ngIf="reco.time_percent.busy>0">
								<clr-tooltip>
									<clr-icon clrTooltipTrigger shape="info-circle" size="10" aria-label="Uniq tooltip 1"></clr-icon>
									<clr-tooltip-content clrPosition="top-right" clrSize="xs" *clrIfOpen>
										<span>busy: {{reco.time_percent.busy}}%</span>
									</clr-tooltip-content>
								</clr-tooltip>
							</div>
							<div class="undersized" [style.width.%]="reco.time_percent.undersized" *ngIf="reco.time_percent.undersized>0">
								<clr-tooltip>
									<clr-icon clrTooltipTrigger shape="info-circle" size="10" aria-label="Uniq tooltip 1"></clr-icon>
									<clr-tooltip-content clrPosition="top-right" clrSize="xs" *clrIfOpen>
										<span>undersized: {{reco.time_percent.undersized}}%</span>
									</clr-tooltip-content>
								</clr-tooltip>
							</div>
							<div class="idle" [style.width.%]="reco.time_percent.idle" *ngIf="reco.time_percent.idle>0">
								<clr-tooltip>
									<clr-icon clrTooltipTrigger shape="info-circle" size="10" aria-label="Uniq tooltip 1"></clr-icon>
									<clr-tooltip-content clrPosition="top-right" clrSize="xs" *clrIfOpen>
										<span>idle: {{reco.time_percent.idle}}%</span>
									</clr-tooltip-content>
								</clr-tooltip>
							</div>
							<div class="lazy" [style.width.%]="reco.time_percent.lazy" *ngIf="reco.time_percent.lazy>0">
								<clr-tooltip>
									<clr-icon clrTooltipTrigger shape="info-circle" size="10" aria-label="Uniq tooltip 1"></clr-icon>
									<clr-tooltip-content clrPosition="top-right" clrSize="xs" *clrIfOpen>
										<span>lazy: {{reco.time_percent.lazy}}%</span>
									</clr-tooltip-content>
								</clr-tooltip>
							</div>
							<div class="normal" [style.width.%]="reco.time_percent.normal" *ngIf="reco.time_percent.normal>0">
								<clr-tooltip>
									<clr-icon clrTooltipTrigger shape="info-circle" size="10" aria-label="Uniq tooltip 1"></clr-icon>
									<clr-tooltip-content clrPosition="top-right" clrSize="xs" *clrIfOpen>
										<span>normal: {{reco.time_percent.normal}}%</span>
									</clr-tooltip-content>
								</clr-tooltip>
							</div>
							<div class="oversized" [style.width.%]="reco.time_percent.oversized" *ngIf="reco.time_percent.oversized>0">
								<clr-tooltip>
									<clr-icon clrTooltipTrigger shape="info-circle" size="10" aria-label="Uniq tooltip 1"></clr-icon>
									<clr-tooltip-content clrPosition="top-right" clrSize="xs" *clrIfOpen>
										<span>oversized: {{reco.time_percent.oversized}}%</span>
									</clr-tooltip-content>
								</clr-tooltip>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

import { ChangeDetectorRef, AfterContentChecked, Component, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrSelectedState } from "@clr/angular";

import { AccountService, JsonloaderService, ManagementService, ShareService } from '@app/services';

import { ElementView, JSONTarget, Message, SelectionFilter, Searchbar, User } from '@app/model';


@Component({
  selector: 'app-selectiontree',
  templateUrl: './selectiontree.component.html',
  styleUrls: ['./selectiontree.component.css']
})
export class SelectiontreeComponent implements OnInit {

  message: Message;

  css_id: string = '';

  infra: any[] = [];

  current_view: string = '';
  
  current_view_type: string = 'server';

  search = new FormGroup({
    item: new FormControl('', [ Validators.required, Validators.minLength(4)]),
  });
  selectedItem : Searchbar;

  private currentUser: User;


  constructor(
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private management_svc: ManagementService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef
  ) { }

  getItemChildren = (item) => item.children;

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.infra = this.json_svc.json.dcInfraMin;

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	if(this.message.isElementWizard)
		this.css_id = 'csstoadjustwizard'
	else
		this.css_id = 'csstoadjust'

	setTimeout(() => $('#' + this.css_id).removeClass('clr-form-horizontal'), 100);
	setTimeout(() => $('#' + this.css_id).removeClass('clr-form'), 100);

	let jt: JSONTarget = JSONTarget.HOST_VIEW; 
	switch (this.message.elementViewType) {
		case "server":
			jt = JSONTarget.HOST_VIEW; 
			break;
		case "server-scope":
			jt = JSONTarget.HOST_VIEW_SCOPE; 
			break;
		case "folder":
			jt = JSONTarget.FD_VIEW; 
			break;
		case "rp":
			jt = JSONTarget.RP_VIEW; 
			break;
		case "tag":
			jt = JSONTarget.TAG_VIEW; 
			break;
		case "datastore":
			jt = JSONTarget.DS_VIEW; 
			break;
		default:
			break;
	}
	this.json_svc.getData(this.currentUser.login, 'all', jt).subscribe(
	  	data => {
			this.message.elementViewTree = data;
      		}
	);
  }

  ngAfterViewChecked(){
  	this.cd.detectChanges();
  }

  elementTreeAnalysis(item, event): void {

	this.message.isTreeAnalysis = true;
	if(event > 0) {
		if(item.name != undefined) {
			if(item.selected == 1)
				this.enableChild(item);
		}
	} else {
		if(item.name != undefined) {
			if(item.selected == 0) {
				this.disableChild(item);
			}
		}
	}
  }

  submit(value):void {

	this.message.isTreeAnalysis = true;
	this.selectedItem = this.getSelectedEntity(value);
	if(this.selectedItem != undefined) {
		for(var i = 0; i < this.message.elementViewTree.length; ++i) {
			if(this.message.elementViewTree[i].id == this.selectedItem.uuid) {
				this.message.elementViewTree[i].selected = ClrSelectedState.SELECTED;
				this.enableChild(this.message.elementViewTree[i]);
			} else {
				this.analyseSearchSubmitChild(this.selectedItem, this.message.elementViewTree[i]);
			}
		}
	}
  }

  enter(): void {

	this.message.isTreeAnalysis = true;
	if(this.search.value.item != "") {
		for(var i = 0; i < this.message.elementViewTree.length; ++i) {
			if(this.message.elementViewTree[i].name.includes(this.search.value.item)) {
				this.message.elementViewTree[i].selected = ClrSelectedState.SELECTED;
				this.enableChild(this.message.elementViewTree[i]);
			} else {
                                this.analyseSearchChild(this.message.elementViewTree[i], this.search.value.item);
			}
		}
	}
  }

  private enableChild(node: any) {

	for(var i = 0; i < node.children.length; ++i) {
		node.children[i].selected = ClrSelectedState.SELECTED;
		if(node.children[i].type != "vm")
			this.enableChild(node.children[i]);
	}
  }

  private disableChild(node: any) {

	for(var i = 0; i < node.children.length; ++i) {
		node.children[i].selected = ClrSelectedState.UNSELECTED;
		if(node.children[i].type != "vm")
			this.disableChild(node.children[i]);
	}
	this.checkParentState(node);
  }

  private analyseChild(node: any): void {

	if(node != undefined) {
                if(node.children.length > 0) {
			for(var i = 0; i < node.children.length; ++i) {
				switch(node.children[i].selected) {
                        		case 1:
                                		this.enableChild(node.children[i]);
                                		break;
					case 2:
						if(node.children[i].type != "vm")
                                			this.analyseChild(node.children[i]);
                                		break;
                        		default:
                               	 		break;
                		}
			}
		}
	}
  }

  private analyseSearchSubmitChild(selectedItem: any, node: any): void {

	if(node != undefined) {
		if(node.children.length > 0) {
			for(var i = 0; i < node.children.length; ++i) {
				if(node.children[i].id == selectedItem.uuid) {
					node.children[i].selected = ClrSelectedState.SELECTED;
					if(node.children[i].type != "vm")
						this.enableChild(node.children[i]);
				} else {
					if(node.children[i].type != "vm")
						this.analyseSearchSubmitChild(selectedItem, node.children[i]);
				}
			}
			this.checkParentState(node);
		}
	}
  }

  private analyseSearchChild(node: any, val: string): void {

        if(node != undefined) {
		if(node.children.length > 0) {
                	for(var i = 0; i < node.children.length; ++i) {
                        	if(node.children[i].name.includes(val)) {
                                	node.children[i].selected = ClrSelectedState.SELECTED;
					if(node.children[i].type != "vm")
						this.enableChild(node.children[i]);
				} else {
                        		if(node.children[i].type != "vm")
                                		this.analyseSearchChild(node.children[i], val);
				}
                	}
			this.checkParentState(node);
		}
        }
  }

  private getSelectedEntity(selectedName: string): Searchbar {
	return this.infra.find(item => item.text === selectedName);
  }

  private checkParentState(node: any): void {

	if(node != undefined) {
		if(node.children.length > 0) {
			let all_child_selected: boolean = true;
			let nb_child_selected: number = 0;
			for(var i = 0; i < node.children.length; ++i) {
				switch(node.children[i].selected) {
					case 0:
						all_child_selected = false;
						break;
					case 1:
						++nb_child_selected;
						break;
					case 2:
						all_child_selected = false;
						++nb_child_selected;
						break;
					default:
						break;
				}
			}
			if(all_child_selected)
				node.selected = ClrSelectedState.SELECTED;
			else {
				if(nb_child_selected > 0)
					node.selected = ClrSelectedState.INDETERMINATE;
			}
		}
		if(node.type != "dc") {
			let father: any = this.getFather(node);
			if(father != null)
				this.checkParentState(father);
		}
	}
  }

  private getFather(node: any): any {

	let father: any = null;

	for(var i = 0; i < this.message.elementViewTree.length; ++i) {
		if(this.message.elementViewTree[i].children.length > 0) {
			for(var j = 0; j < this.message.elementViewTree[i].children.length; ++j) {
				if(this.message.elementViewTree[i].children[j].id == node.id)
					return this.message.elementViewTree[i];
			}
		}
	}

	return father;
  }

  private changeTree(): void {

	this.current_view_type = this.message.elementViewType;
	switch (this.current_view_type) {
		case "folder":
			this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.FD_VIEW).subscribe(
	  			data => {
					this.message.elementViewTree = data;
					this.clearTree();
      				}
			);
	  		break;
		case "server":
			this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.HOST_VIEW).subscribe(
	  			data => {
					this.message.elementViewTree = data;
					this.clearTree();
      				}
			);
	  		break;
		case "rp":
			this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RP_VIEW).subscribe(
	  			data => {
					this.message.elementViewTree = data;
					this.clearTree();
      				}
			);
	  		break;
		case "tag":
			this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.TAG_VIEW).subscribe(
	  			data => {
					this.message.elementViewTree = data;
					this.clearTree();
      				}
			);
	  		break;
	  	default:
	  		break;
	}
  }

  private clearTree(): void {

	if(this.message.elementViewTree != undefined) {
		for(var i = 0; i < this.message.elementViewTree.length; i++) {
			this.message.elementViewTree[i].selected = ClrSelectedState.UNSELECTED;
		}
	}
  }
}

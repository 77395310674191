import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-troubleshooting-list]'
})
export class TroubleshootinglistDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import { AccountService, JsonloaderService, ShareService, ReportService } from '@app/services';

import { JSONTarget, User, Message, RecoVm } from '@app/model';


@Component({
  selector: 'app-recommendationresizingdetaildisk',
  templateUrl: './recommendationresizingdetaildisk.component.html',
  styleUrls: ['./recommendationresizingdetaildisk.component.css']
})
export class RecommendationresizingdetaildiskComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject();

  message: Message;

  math = Math;

  data_model: RecoVm[] = [];

  data_elements: RecoVm[] = [];

  private currentUser: User;


  constructor(
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();
	$( "dt-buttons" ).remove();

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.isRecoPowershell = false;
	this.message.isPowershellRam = false;
	this.message.isPowershellCpu = false;

	this.initDtOptions();

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_VM).subscribe(
		data => {
	  		this.data_model = data;
	  		if(this.data_model.length > 0)
				this.buildData();
	  	}
	);
  }

  ngAfterViewInit(): void {
	this.dtTrigger.next();
  }

  ngOnDestroy(): void {
	this.dtTrigger.unsubscribe();
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  private reloadTable(): void {

	  if(this.dtElement.dtInstance != undefined) {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			$( "div.dataTables_filter" ).remove();
                	dtInstance.destroy();
	  		this.dtTrigger.next();
          	});
	  }
  }

  private initDtOptions(): void {

	this.dtOptions = {
		pagingType: 'full_numbers',
	  	pageLength: 10,
		lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
		processing: true,
	  	destroy: true,
	  	initComplete: function() {
			$('div.dataTables_filter').appendTo('span.search');
			$('div.dt-buttons').appendTo('span.export');
	  		$("[id*='DataTables_Table_']").css({'padding-top':'5px'});
	  		$("[name*='DataTables_Table_']").css({'padding':'0px'});
	  	},
	  	language: {
			loadingRecords: "loading ...",
	  		zeroRecords: "no data",
	  		search: '',
	  		searchPlaceholder: " search a vm..."
	  	},
	  	dom: '<"top"B><"clear">frtlip',
	  	buttons: [
	  		{ extend: 'csv', className: 'btn btn-link btn-sm' },
	  		{ extend: 'copy', className: 'btn btn-link btn-sm' },
	  		{ extend: 'print', className: 'btn btn-link btn-sm' }
	  	]
	};
  }

  private buildData(): void {

	this.data_elements = [];
	for(var i = 0; i < this.data_model.length; i++) {
		if(this.data_model[i].disk_saved > 0)
	  		this.data_elements.push(this.data_model[i]);
	}
	this.reloadTable();
  }
}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-consover-ram]'
})
export class ConsoverramDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

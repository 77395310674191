import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { JsonloaderService, ShareService } from '@app/services';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

import { Json, Message } from '@app/model';


@Component({
  selector: 'app-kpihealthgraph',
  templateUrl: './kpihealthgraph.component.html',
  styleUrls: ['./kpihealthgraph.component.css']
})
export class KpihealthgraphComponent implements AfterViewInit, OnInit {

  highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;

  isReady: boolean = false;

  jsonLoader: Json;

  message: Message;

  health_model: any;

  health_data: any;


  constructor(
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
	this.health_data = this.jsonLoader.kpiHealth;

	this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  ngAfterViewInit(): void {
	this.filterResource();
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  private filterResource(): void {
	
	this.health_model = {};
	this.health_model.month = this.health_data.month;
	switch(this.message.kpiHealth) {
		case "all":
			this.health_model.gloora = this.health_data.gloora;
			this.health_model.glored = this.health_data.glored;
			break;
		case "cpu":
			this.health_model.cpuora = this.health_data.cpuora;
			this.health_model.cpured = this.health_data.cpured;
			break;
		case "disk":
			this.health_model.diskora = this.health_data.diskora;
			this.health_model.diskred = this.health_data.diskred;
			break;
		case "net":
			this.health_model.netora = this.health_data.netora;
			this.health_model.netred = this.health_data.netred;
			break;
		case "ram":
			this.health_model.ramora = this.health_data.ramora;
			this.health_model.ramred = this.health_data.ramred;
			break;
		default:
			break;
	}
	this.buildCard();
  }

  private buildCard(): void {

	this.chartOptions = {
		credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                title: {
                    text: 'Monthly Defects Resources'
                },
                xAxis: {
                    categories: this.health_model['month']
                },
                yAxis: [{
                    min: 0,
                    title: {
                        text: 'Exceeded threshold'
                    }
                }],
                legend: {
                    shadow: false
                },
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    column: {
                        grouping: false,
                        shadow: false,
                        borderWidth: 0
                    }
		},
		series: [{
                    type: 'column',
                    name: 'GLOBAL orange',
                    color: 'rgba(248,161,63,1)',
                    data: this.health_model['gloora'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.3
                }, {
                    type: 'column',
                    name: 'GLOBAL red',
                    color: 'rgba(252,0,0,.9)',
                    data: this.health_model['glored'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.4
                }, {
                    type: 'column',
                    name: 'CPU orange',
                    color: 'rgba(248,161,63,1)',
                    data: this.health_model['cpuora'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.3
                }, {
                    type: 'column',
                    name: 'CPU red',
                    color: 'rgba(252,0,0,.9)',
                    data: this.health_model['cpured'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.4
		}, {
                    type: 'column',
		    name: 'DISK orange',
                    color: 'rgba(248,161,63,1)',
                    data: this.health_model['diskora'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.3
                }, {
                    type: 'column',
                    name: 'DISK red',
                    color: 'rgba(252,0,0,.9)',
                    data: this.health_model['diskred'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.4
                }, {
                    type: 'column',
                    name: 'NET orange',
                    color: 'rgba(248,161,63,1)',
                    data: this.health_model['netora'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.3
                }, {
                    type: 'column',
                    name: 'NET red',
                    color: 'rgba(252,0,0,.9)',
                    data: this.health_model['netred'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.4
                }, {
                    type: 'column',
                    name: 'RAM orange',
                    color: 'rgba(248,161,63,1)',
                    data: this.health_model['ramora'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.3
		}, {
                    type: 'column',
		    name: 'RAM red',
                    color: 'rgba(252,0,0,.9)',
                    data: this.health_model['ramred'],
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} dots</b><br/>'
                    },
                    showInLegend: false,
                    pointPadding: 0.4
                }]
	};

	this.isReady = true;
  }
}

<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<div class="clr-row">
					<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
						<h5>
							<clr-icon shape="bar-chart"></clr-icon>
							&nbsp;Health evolution
						</h5>
					</div>
					<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
						<clr-radio-container clrInline>
							<clr-radio-wrapper>
								<input type="radio" clrRadio name="options" (click)="callResource('all')" [(ngModel)]="isAll" [value]=true />
								<label>all</label>
  							</clr-radio-wrapper>
							<clr-radio-wrapper>
								<input type="radio" clrRadio name="options" (click)="callResource('cpu')" [value]=false />
								<label>cpu</label>
							</clr-radio-wrapper>
							<clr-radio-wrapper>
								<input type="radio" clrRadio name="options" (click)="callResource('disk')" [value]=false />
								<label>storage</label>
							</clr-radio-wrapper>
							<clr-radio-wrapper>
								<input type="radio" clrRadio name="options" (click)="callResource('net')" [value]=false />
								<label>network</label>
							</clr-radio-wrapper>
							<clr-radio-wrapper>
								<input type="radio" clrRadio name="options" (click)="callResource('ram')" [value]=false />
								<label>ram</label>
							</clr-radio-wrapper>
							<clr-control-helper>Display by resource</clr-control-helper>
						</clr-radio-container>
					</div>
				</div>
			</div>
			<div class="card-block">
				<div class="card-text">
					<ng-template load-kpi-health-graph></ng-template>
                		</div>
			</div>
		</div>
	</div>
</div>

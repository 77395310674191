<div class="card-block">
	<div class="card-text">
        	<div class="clr-row">
                	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
                        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                              	<button class="btn btn-primary" style="float:left;" (click)="wizardav.open();initWizard()">
                                        <clr-icon shape="plus-circle" class="is-solid"></clr-icon>
					CREATE ALERT
				</button>
			</div>
			<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
				<div class="card">
					<div class="card-block">
						<h5 class="card-title">select your alert</h5>
						<div class="card-text">
							<div class="clr-control-container">
								<div class="clr-select-wrapper">
									<select id="alert-view" class="clr-select" [(ngModel)]="selected_alert" (ngModelChange)="switchAlert($event)">
										<option *ngFor="let alert of alerts">{{alert.notified_name}}</option>
									</select>
								</div>
								<span class="clr-subtext">the list of alerts</span>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div class="clr-control-container clr-control-inline">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="editAlert()">
								EDIT
							</button>
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="removeAlert()">
								REMOVE
							</button>
							<clr-toggle-wrapper>
								<input type="checkbox" clrToggle name="state" value="enable" [(ngModel)]="enable_alert" (ngModelChange)="updateState($event)" />
								<label style="color:#0072a3;font-size:0.55rem">{{alert_state}}</label>
							</clr-toggle-wrapper>
						</div>
					</div>
				</div>
			</div>
			<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
				<div class="card">
					<div class="card-block">
						<h5 class="card-title" *ngIf="isRdy">information on your alert {{selected_alert.notified_name}}</h5>
						<div class="card-text" *ngIf="isRdy">
							<div class="alert alert-info">
								<div class="alert-items">
									<div class="alert-item static">
										<div class="alert-icon-wrapper">
											<clr-icon shape="info-circle" class="alert-icon" size="24"></clr-icon>
										</div>
										<span class="alert-text">
											{{selected_alert.definition.replace('%STATE%', '[THRESHOLD]')}}
										</span>
									</div>
								</div>
							</div>
							<table class="table" *ngIf="isRdy">
								<thead>
									<tr>
										<th>COUNTER</th>
										<th>BASED ON</th>
										<th>WARNING THRESHOLD</th>
										<th>CRITICAL THRESHOLD</th>
										<th>FREQUENCY</th>
										<th>ELEMENT</th>
										<th>ELEMENT TYPE</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{selected_alert.name}}</td>
										<td>{{converted_alert.base}}</td>
										<td>{{converted_alert.th1}}{{converted_alert.unit}}</td>
										<td>{{converted_alert.th2}}{{converted_alert.unit}}</td>
										<td>{{converted_alert.freq}}</td>
										<td>{{converted_alert.itemname}}</td>
										<td>{{converted_alert.type}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isEdit">
				<div class="card">
					<div class="card-block">
						<div class="clr-row">
							<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
								<h5 class="card-title" *ngIf="isRdy">edit alert {{selected_alert.notified_name}}</h5>
							</div>
							<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
								<button style="float: right;" type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateAlert()" *ngIf="isUpdate">
									Update
								</button>
							</div>
						</div>
						<div class="card-text">
							<clr-timeline>
								<clr-timeline-step clrState="success">
									<clr-timeline-step-header>{{formatEditDate(this.selected_alert.timeago)}}</clr-timeline-step-header>
									<clr-timeline-step-title>CREATE or UPDATE</clr-timeline-step-title>
									<clr-timeline-step-description>Last modification date</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="success">
									<clr-timeline-step-header>STEP 1</clr-timeline-step-header>
									<clr-timeline-step-title>NAME</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<div class="clr-input-container">
												<label>{{selected_alert.notified_name}}</label>
											</div>
											<span class="clr-subtext">(cannot be changed)</span>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="success">
									<clr-timeline-step-header>STEP 2</clr-timeline-step-header>
									<clr-timeline-step-title>TYPE</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<div class="clr-input-container">
												<label>{{selected_alert_type}}</label>
											</div>
											<span class="clr-subtext">(cannot be changed)</span>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="success">
									<clr-timeline-step-header>STEP 3a</clr-timeline-step-header>
									<clr-timeline-step-title>COUNTER</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<div class="clr-input-container">
												<label>{{selected_alert.name}}</label>
											</div>
											<span class="clr-subtext">(cannot be changed)</span>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
							</clr-timeline>
							<clr-timeline style="padding-left:15%">
								<clr-timeline-step clrState="current">
									<clr-timeline-step-header>STEP 3b</clr-timeline-step-header>
									<clr-timeline-step-title>THRESHOLD</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<div class="clr-input-container">
												<input type="number" size="14%" placeholder="{{converted_alert.th1}}" class="clr-input" [(ngModel)]="edit_model.th1" (ngModelChange)="enableUpdateTh1($event)">
											</div>
											<span class="clr-subtext">warning threshold ({{converted_alert.unit}})</span>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="current">
									<clr-timeline-step-header>STEP 3c</clr-timeline-step-header>
									<clr-timeline-step-title>THRESHOLD</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<div class="clr-input-container">
												<input type="number" size="14%" placeholder="{{converted_alert.th2}}" class="clr-input" [(ngModel)]="edit_model.th2" (ngModelChange)="enableUpdateTh2($event)">
											</div>
											<span class="clr-subtext">critical threshold ({{converted_alert.unit}})</span>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="current">
									<clr-timeline-step-header>STEP 4</clr-timeline-step-header>
									<clr-timeline-step-title>FREQUENCY</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<div class="clr-select-wrapper">
												<select class="clr-select" [(ngModel)]="edit_model.freq" (ngModelChange)="enableUpdateFreq($event)">
													<option *ngFor="let freq of frequencies">{{freq}}</option>
												</select>
											</div>
											<span class="clr-subtext">select a new frequency</span>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="current">
									<clr-timeline-step-header>STEP 5</clr-timeline-step-header>
									<clr-timeline-step-title>ELEMENT</clr-timeline-step-title>
									<clr-timeline-step-description>
										<div class="clr-control-container">
											<div class="clr-select-wrapper">
												<select class="clr-select" [(ngModel)]="edit_model.item" (ngModelChange)="enableUpdateItem($event)">
													<option *ngFor="let item of items">{{item}}</option>
												</select>
											</div>
											<span class="clr-subtext">select a new element</span>
										</div>
									</clr-timeline-step-description>
								</clr-timeline-step>
								<clr-timeline-step clrState="{{update_state}}">
									<clr-timeline-step-header>{{now}}</clr-timeline-step-header>
									<clr-timeline-step-title>UPDATE</clr-timeline-step-title>
									<clr-timeline-step-description>
										{{update_result}}
									</clr-timeline-step-description>
								</clr-timeline-step>
							</clr-timeline>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<clr-wizard #wizardAlert
        [clrWizardSize]="'xl'"
        (clrWizardOnPrevious)="goBack()"
        (clrWizardOnCancel)=doReset()
        (clrWizardOnFinish)="doFinish(false, wizard_model)">
	
	<clr-wizard-title>Create an alert</clr-wizard-title>
	<clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
	<clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
	<clr-wizard-button [type]="'next'">Next</clr-wizard-button>
	<clr-wizard-button [type]="'finish'">Finish</clr-wizard-button>

	<clr-wizard-page [clrWizardPageNextDisabled]="wizard_model.name == '' || filterList(wizard_model.name)">
        	<ng-template clrPageTitle>The name of the alert</ng-template>
        	<div class="card">
                	<div class="card-block">
                        	<div class="card-text">
                                	<div class="clr-form-control">
                                        	<div class="clr-input-container" [ngClass]="{'clr-error' :  wizard_model.error}">
                                                	<input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="wizard_model.name" name="name" (ngModelChange)="checkName($event)" required />
                                                	<clr-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="wizard_model.error"></clr-icon>
                                                	<clr-control-error *ngIf="wizard_model.error">This field cannot set {{wizard_model.errorName}}</clr-control-error>
                                        	</div>
                                        	<span class="clr-subtext">the name of new alert</span>
                                	</div>
                        	</div>
                	</div>
        	</div>
	</clr-wizard-page>
	<clr-wizard-page>
        	<ng-template clrPageTitle>The type of the alert</ng-template>
        	<div class="card">
                	<div class="card-block">
                        	<div class="card-text">
                                	<div class="clr-row">
                                        	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                                                	<clr-radio-container clrInline>
                                                        	<clr-radio-wrapper *ngIf="currentUser.login == 'root'">
                                                                	<input type="radio" clrRadio name="options" required [(ngModel)]="wizardAlertTypeRt" [value]=true (ngModelChange)="initWizardModel($event)" />
                                                                	<label>real time</label>
                                                        	</clr-radio-wrapper>
                                                        	<clr-radio-wrapper>
                                                                	<input type="radio" clrRadio name="options" required [(ngModel)]="wizardAlertTypeRt" [value]=false (ngModelChange)="initWizardModel($event)" />
                                                                	<label>filter</label>
                                                        	</clr-radio-wrapper>
                                                        	<clr-control-helper>select the alert type</clr-control-helper>
                                                	</clr-radio-container>
                                        	</div>
                                	</div>
                        	</div>
                	</div>
        	</div>
	</clr-wizard-page>
	<clr-wizard-page (clrWizardPageOnLoad)="loadCounters()">
        	<ng-template clrPageTitle>Select counter & thresholds</ng-template>
        	<div class="card">
                	<div class="card-block">
                        	<div class="card-text">
                                	<div class="clr-row">
                                        	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="!wizardAlertTypeRt">
                                                	<clr-radio-container clrInline>
                                                        	<clr-radio-wrapper>
                                                                	<input type="radio" id="wizard-at-a" clrRadio name="radio-full" value="allocation" [checked]="true" (change)="loadFilterCounters($event)" />
                                                                        	<label for="wizard-at-a" class="clr-control-label">based on allocation</label>
                                                        	</clr-radio-wrapper>
                                                        	<clr-radio-wrapper>
                                                                	<input type="radio" id="wizard-at-b" clrRadio name="radio-full" value="behavior" (change)="loadFilterCounters($event)" />
                                                                        	<label for="wizard-at-b" class="clr-control-label">based on behavior</label>
                                                        	</clr-radio-wrapper>
                                                        	<clr-radio-wrapper>
                                                                        	<input type="radio" id="wizard-at-c" clrRadio name="radio-full" value="consumption" (change)="loadFilterCounters($event)" />
                                                                        	<label for="wizard-at-c" class="clr-control-label">based on consumption</label>
                                                        	</clr-radio-wrapper>
                                                        	<clr-control-helper>select the counter base</clr-control-helper>
                                                	</clr-radio-container>
                                        	</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="!wizardAlertTypeRt">&nbsp;</div>
                                        	<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
                                                	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                                                        	<label class="clr-control-label">Counter</label>
                                                        	<div class="clr-control-container">
                                                                	<div class="clr-select-wrapper">
                                                                        	<select class="clr-select" [(ngModel)]="wizard_model.counter" (ngModelChange)="updateWizardThreshold($event)">
                                                                                	<option *ngFor="let counter of counters">{{counter}}</option>
                                                                        	</select>
                                                                	</div>
                                                                	<span class="clr-subtext">{{counter_type}} analysis</span>
                                                        	</div>
                                                	</div>
                                        	</div>
						<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                	<label class="clr-control-label">
                                                        	<clr-icon shape="warning-standard" class="is-warning" size="18"></clr-icon>
                                                        	Warning threshold
                                                	</label>
                                                	<div class="clr-control-container">
                                                        	<div class="clr-input-wrapper">
                                                                	<input type="number" size="16%" placeholder="{{wizard_catalog.threshold_1}}" class="clr-input" [(ngModel)]="wizard_model.th1" min="0" max="100" (ngModelChange)="updateWizardComment()">
                                                        	</div>
                                                        	<clr-control-helper>set the warning threshold in {{wizard_catalog.unit}}</clr-control-helper>
                                                	</div>
                                        	</div>
						<div class="clr-col-lg-1 clr-col-md-1 clr-col-1">&nbsp;</div>
                                        	<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                	<label class="clr-control-label">
                                                        	<clr-icon shape="error-standard" class="is-error" size="18"></clr-icon>
                                                        	Critical threshold
                                                	</label>
                                                	<div class="clr-control-container">
                                                        	<div class="clr-input-wrapper">
                                                                	<input type="number" size="16%" placeholder="{{wizard_catalog.threshold_2}}" class="clr-input" [(ngModel)]="wizard_model.th2" min="0" max="100" (ngModelChange)="updateWizardComment()">
                                                        	</div>
                                                        	<clr-control-helper>set the critical threshold in {{wizard_catalog.unit}}</clr-control-helper>
                                                	</div>
                                        	</div>
                                        	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
                                        	<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">&nbsp;</div>
						<div class="clr-col-lg-7 clr-col-md-7 clr-col-12">
                                                	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                                        	<div class="alert alert-info">
                                                                	<div class="alert-items">
                                                                        	<div class="alert-item static">
                                                                                	<div class="alert-icon-wrapper">
                                                                                        	<clr-icon shape="info-circle" class="alert-icon" size="24"></clr-icon>
                                                                                	</div>
                                                                                	<span class="alert-text">
                                                                                        	Configure the thresholds corresponding to the level of warning and critical vigilance.<br><br>
                                                                                        	Between <span style="color:#f39c12"><b>{{wizard_catalog.threshold_1}}</b></span> and <span style="color:#f39c12"><b>{{wizard_catalog.threshold_2}}</b></span> {{wizard_catalog.unit}} the alert level is warning.<br>
                                                                                        	{{wizard_th_format}} <span style="color:#e74c3c"><b>{{wizard_catalog.threshold_2}}</b></span> {{wizard_catalog.unit}} the alert level is critical.<br><br>
                                                                                        	{{wizard_catalog.definition}}
                                                                                	</span>
                                                                        	</div>
                                                                	</div>
                                                        	</div>
                                                	</div>
                                        	</div>
						<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="!wizardAlertTypeRt">&nbsp;</div>
                                        	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="!wizardAlertTypeRt">&nbsp;</div>
                                        	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="!wizardAlertTypeRt">&nbsp;</div>
                                        	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="!wizardAlertTypeRt">&nbsp;</div>
                                	</div>
                        	</div>
                	</div>
        	</div>
	</clr-wizard-page>
	<clr-wizard-page (clrWizardPageOnLoad)="loadFrequencies()">
        	<ng-template clrPageTitle>Selec the frequency</ng-template>
        	<div class="card">
                	<div class="card-block">
                        	<div class="card-text">
                                	<div class="clr-row">
                                        	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                	<div class="clr-control-container">
                                                        	<div class="clr-select-wrapper">
                                                                	<select class="clr-select" [(ngModel)]="wizard_model.freq">
                                                                        	<option *ngFor="let freq of frequencies">{{freq}}</option>
                                                                	</select>
                                                        	</div>
                                                        	<span class="clr-subtext">select frequency</span>
                                                	</div>
                                        	</div>
						<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                	<div class="alert alert-info">
                                                        	<div class="alert-items">
                                                                	<div class="alert-item static">
                                                                        	<div class="alert-icon-wrapper">
                                                                                	<clr-icon shape="info-circle" class="alert-icon" size="24"></clr-icon>
                                                                        	</div>
                                                                        	<span class="alert-text" *ngIf="wizard_freq_auth">
                                                                                	Select a frequency to determine the number of time impacted.
                                                                        	</span>
                                                                        	<span class="alert-text" *ngIf="!wizard_freq_auth">
                                                                                	The frequency is limited to the last day for the counter {{wizard_model.counter}}.
                                                                        	</span>
                                                                	</div>
                                                        	</div>
                                                	</div>
                                        	</div>
                                        	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
					</div>
                        	</div>
                	</div>
        	</div>
	</clr-wizard-page>
	<clr-wizard-page [clrWizardPageNextDisabled]="wizard_model.item.length == 0" (clrWizardPageOnLoad)="loadItems()">
        	<ng-template clrPageTitle>Select the element</ng-template>
        	<div class="card">
                	<div class="card-block">
                        	<div class="card-text">
                                	<div class="clr-row">
                                        	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                                                	<div class="clr-control-container">
                                                        	<div class="clr-select-wrapper">
                                                                	<select class="clr-select" [(ngModel)]="wizard_model.item">
                                                                        	<option *ngFor="let item of items">{{item}}</option>
                                                                	</select>
                                                        	</div>
                                                        	<span class="clr-subtext" *ngIf="wizardAlertTypeRt">select a VM or a SERVER</span>
                                                        	<span class="clr-subtext" *ngIf="!wizardAlertTypeRt">select a FILTER</span>
                                                	</div>
                                        	</div>
						<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
                                                	<div class="alert alert-info" *ngIf="wizardAlertTypeRt">
                                                        	<div class="alert-items">
                                                                	<div class="alert-item static">
                                                                        	<div class="alert-icon-wrapper">
                                                                                	<clr-icon shape="info-circle" class="alert-icon" size="24"></clr-icon>
                                                                        	</div>
                                                                        	<span class="alert-text">
                                                                                	Select an item to track.<br><br>
                                                                                	---ALL VM--- means that the alert concerns all VM.<br>
                                                                                	---ALL ESX--- means that the alert concerns all SERVER.
                                                                        	</span>
                                                                	</div>
                                                        	</div>
                                                	</div>
                                        	</div>
                                	</div>
                        	</div>
                	</div>
        	</div>
	</clr-wizard-page>
</clr-wizard>

import { Component } from '@angular/core';
import { Message } from './model';
import { ShareService } from './services';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	message: Message;

	constructor(private message_svc: ShareService) {}


	ngOnInit(): void {
		// Init app
		this.message_svc.initApp();

		this.message_svc.currentMessage.subscribe(message => this.message = message);		
	}
}

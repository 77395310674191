<div class="clr-row">
        <div class="card">
                <div class="card-header">
			<span style="color:grey;font-size:26px;">
				<clr-icon shape="process-on-vm"></clr-icon>
				Snapshots
			</span>
			<span style="margin-left:100px;" class="label label-info">
				Click on a snapshot name to display the list
			</span>
			<button class="btn btn-link" style="position:relative;float:right;" (click)="exportSnapshots()">
                        	Export
                        </button>
                </div>
                <div class="card-block">
			<div class="datagrid-limit-height">
				<clr-datagrid>
					<clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Virtual machine</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'snapshots'"><ng-container *clrDgHideableColumn="{hidden: false}">Snapshot number</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'snaphotName'"><ng-container *clrDgHideableColumn="{hidden: false}">Snapshot name</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'status'"><ng-container *clrDgHideableColumn="{hidden: false}">Status</ng-container></clr-dg-column>
					<clr-dg-column [clrDgField]="'size'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Size (MB)</ng-container></clr-dg-column>
					<clr-dg-row *clrDgItems="let vm of vm_snapshot" [clrDgItem]="vm" clickable>
						<clr-dg-cell>{{vm.name}}</clr-dg-cell>
						<clr-dg-cell>{{vm.snapshots}}</clr-dg-cell>
						<clr-dg-cell><a ngClass="addLink" (click)="callUrl(vm)">{{vm.snaphotName}}</a></clr-dg-cell>
						<clr-dg-cell>{{vm.status}}</clr-dg-cell>
						<clr-dg-cell>{{vm.size.toFixed(2)}}</clr-dg-cell>
					</clr-dg-row>
					<clr-dg-footer>
						<clr-dg-pagination #pagination [clrDgPageSize]="10">
							<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
								entries per page
							</clr-dg-page-size>
							{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
						</clr-dg-pagination>
					</clr-dg-footer>
				</clr-datagrid>
			</div>
		</div>
	</div>
</div>
<div *ngIf="target != modal" class="login-wrapper login-wrapper-background">
	<form class="login" (ngSubmit)="onSubmit()">
		<section class="title">
			<h3 class="welcome">Welcome to</h3>
			<img *ngIf="isDcscope" src="/assets/img/logo-login.svg" class="img-logo-login" />
            <img *ngIf="isCo2scope" src="/assets/img/logo-login_co2scope.svg" class="img-logo-login" />
		</section>
		<div class="login-group">
			<div class="clr-input-container">
				<label>Please enter your license key :</label>
				<input style="margin-top:30px;" clrInput placeholder="license key" name="license_key"
					[(ngModel)]="license_key" required />
			</div>
			<button type="submit" class="btn btn-primary">Validate</button>
		</div>

		<div style="margin-top:30px;text-align:center">
			<b>{{license_message}}</b>
			<p><a target="_blank" href="{{license_request_url}}">Request your license key</a></p>
		</div>

		<div class="alert alert-info" role="alert"
			style="margin-top:30px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
			<span style="text-align:center;" class="alert-text">
				<b>Your product key :
					<br>
					{{product_key.key}}</b>
				<div *ngIf="product_key.key == ''"><b>Can't generate product key ! Please contact Easyvirt <a
							href="mailto:contact@easyvirt.com">contact@easyvirt.com</a></b></div>
			</span>
		</div>

	</form>

</div>

<!-- MODAL LICENSE -->
<clr-modal [(clrModalOpen)]="license_modal" [clrModalSize]="'l'">
	<h3 class="modal-title">License</h3>
	<div class="modal-body" style="overflow:auto">

		<form (ngSubmit)="onSubmit()">

			<div style="text-align:right">
				<p><a target="_blank" href="{{license_request_url}}">Request your license key</a></p>
			</div>

			<div class="clr-input-container" style="margin-top:30px;flex-direction:column;">
				<label>Please enter your license key :</label>
				<input style="margin-top:30px;width:25rem;" clrInput placeholder="license key" name="license_key"
					[(ngModel)]="license_key" required />
			</div>

			<div style="margin-top:30px;text-align:center">
				<button style="margin-top:30px;" type="submit" class="btn btn-primary">Validate</button>
			</div>

			<div style="margin-top:30px;text-align:center">
				<b [style.color]="license_message_color">{{license_message}}</b>
			</div>

			<div class="alert alert-info" role="alert"
				style="margin-top:30px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
				<span style="text-align:center;" class="alert-text">
					<b>Your product key :
						<br>
						{{product_key.key}}</b>
					<div *ngIf="product_key.key == ''"><b>Can't generate product key ! Please contact Easyvirt <a
								href="mailto:contact@easyvirt.com">contact@easyvirt.com</a></b></div>
				</span>
			</div>
		</form>

	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="license_modal = false">Close</button>
	</div>
</clr-modal>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CloudService {

  constructor() { }

  // This function calls the cloudpricing's matchComputeOffering function with default parameters to get the correct instance match for each VM.
  instanceMatch(vm, region, cloudPricingData, sortedInstanceTypesCache) {
    let computePrices = cloudPricingData.computePrices[region];
    let mapOS = cloudPricingData['mapOs'] || { 'Microsoft_Windows': 'win','Debian': 'linux','Ubuntu': 'linux','SUSE': 'sles','Red_Hat': 'rhel','CentOS': 'linux' };
    // let sortedInstanceTypesCache = {};
    let selectedBillingType = 'od';
    let computeTypes = cloudPricingData.computeTypes;
    let defaultOs = cloudPricingData.defaultOs;
    let billingTypes = cloudPricingData.billingTypes;
    return this.matchComputeOffering(vm, computePrices, mapOS, sortedInstanceTypesCache, selectedBillingType, computeTypes, defaultOs, billingTypes);
  }


  //This method returns a compute type (template) that matches a given VM compute capacity.
  matchComputeOffering(vm, regionalPrices, mapOs, sortedInstanceTypesCache, selectedBillingType, computeTypes, defaultOs, billingTypes): any {

    let matchInfo = {
      "price": 0,
      "state": 1
    };

    // let regionalPrices = computePrices[selectedRegion];
    let osKey = this.getOsInProvider(vm['os'], mapOs);

    let selected;
    let vcpu_requested = vm['vcpu'];
    let memory_requested = vm['memory'];

    // When an instance is not running, the
    // VmComputeStorageGenerator.java generator set vcpu = 0
    // and memory_requested = 0.  In this case, we don't count
    // the instance in the total.
    if (vcpu_requested <= 0 || memory_requested <= 0) {
      matchInfo["state"] = 0;
      return matchInfo;
    }

    let osKeyUsedForSearch = osKey;
    let instance_type_sorted_by_increasing_price = [];
    let try_count = 0;

    // Generation of a list of computeTypes that matches the
    // requirements. The computeTypes are sorted by their
    // increasing price
    while (try_count < 3 && instance_type_sorted_by_increasing_price.length == 0) {
      if (!(osKeyUsedForSearch in sortedInstanceTypesCache)) {
        sortedInstanceTypesCache[osKeyUsedForSearch] =
          Object.keys(computeTypes)
            .filter(computeType => regionalPrices[computeType] != undefined)
            .filter(computeType => osKeyUsedForSearch in regionalPrices[computeType])
            .filter(computeType => regionalPrices[computeType][osKeyUsedForSearch][selectedBillingType] > 0)
            .sort((t1, t2) => regionalPrices[t1][osKeyUsedForSearch][selectedBillingType] - regionalPrices[t2][osKeyUsedForSearch][selectedBillingType]);

      }
      instance_type_sorted_by_increasing_price =
        sortedInstanceTypesCache[osKeyUsedForSearch]
          .filter(computeType => computeTypes[computeType]["vcpu"] >= vcpu_requested)
          .filter(computeType => computeTypes[computeType]["memory"] >= memory_requested);

      if (instance_type_sorted_by_increasing_price.length == 0) {
        osKeyUsedForSearch = defaultOs;
      }

      try_count += 1;
    }

    // Iterate over the matching computeTypes. Select the first matching computeType.
    // Ensure that the selected computeTypes are matching the specs of the vm.
    for (const instance_type of instance_type_sorted_by_increasing_price) {
      let computeType = computeTypes[instance_type];
      if (computeType['memory'] >= memory_requested && computeType['vcpu'] >= vcpu_requested) {
        selected = instance_type;
        break;
      }
    }

    if (selected) {
      let computeType = computeTypes[selected];
      let computeTypePrice = regionalPrices[selected][osKeyUsedForSearch][selectedBillingType];
      let billingConverterFactor = billingTypes[selectedBillingType]["converterFactor"];
      matchInfo['instanceTypeKey'] = selected;
      matchInfo['osKey'] = osKeyUsedForSearch;
      matchInfo['price'] = computeTypePrice * billingConverterFactor;
      matchInfo['computeDebug'] = {
        title: `Compute type`,
        explanations: [
          `Using <span class="label label-info">${selected}</span>`,
          `<span class="label label-info">${selected}</span> provides ${computeType['vcpu']} core(s) (requested: ${vcpu_requested} core(s))`,
          `<span class="label label-info">${selected}</span> provides ${computeType['memory']} GB of memory (requested: ${memory_requested} GB)`,
          `Using ${osKeyUsedForSearch} (requested: ${osKey})`,
          `<b>Compute subtotal</b>: ${matchInfo['price'].toFixed(2)} $ per month`,
        ],
        monthlyPrice: matchInfo['price']
      }
      matchInfo['comment'] = '';
    } else {
      console.log("Warning: no computeType matching vm requirements '" + vm["name"] + "' (os: '" + vm["os"] + "')");
      matchInfo['comment'] = `no computeType matching vm requirements (vcpu: ${vm['vcpu']}, memory: ${vm['memory']} Mo)`;
    }

    return matchInfo;
  }

  
  // This method returns a storage type that matches given VM storage capacity
  matchStorageOffering(vm, storageTypes, storagePrices, selectedRegion): any {
    let selected;
    let matchInfo = {};

    let storageTypesKeys = Object.keys(storageTypes);

    let storageTypesKeysWithHighThroughput = storageTypesKeys
      .sort((k1, k2) => storageTypes[k1]["maxThroughputPerVolume"] - storageTypes[k2]["maxThroughputPerVolume"])
      .reverse()[0]

    let storageTypesKeysWithHighIops = storageTypesKeys
      .sort((k1, k2) => storageTypes[k1]["maxIopsPerVolume"] - storageTypes[k2]["maxIopsPerVolume"])
      .reverse()[0]

    let storageScenarios = [];
    let useSimpleStorageComputations = true;

    if (vm) {
      //this is necessary because sometimes we need
      //more storage capacity in order to meet iops
      //and throughput requirements
      vm['requiredStorage'] = vm['storageProvisioned'];

      storageTypesKeys.forEach((key) => {
        let storageType = storageTypes[key];
        let storageTypePrice = storagePrices[key];

        if (storageType === undefined || storageTypePrice === undefined) {
          return;
        }

        let authorizedScenarios = [1, 2, 3, 4, 5];
        if (storageTypesKeysWithHighIops.includes(key)) {
          authorizedScenarios.push(6);
          // if (!useSimpleStorageComputations) {
          //   authorizedScenarios.push(3);
          // }
        }
        if (storageTypesKeysWithHighThroughput.includes(key)) {
          authorizedScenarios.push(7);
          // if (!useSimpleStorageComputations) {
          //   authorizedScenarios.push(4);
          // }
        }
        let storageScenariosWithCurrentStorageType = this.findStorageScenarios(vm, storageType, key, storageTypePrice, authorizedScenarios, useSimpleStorageComputations);

        storageScenarios.push(...storageScenariosWithCurrentStorageType);
      });
    }

    let sortedStorageScenarios = storageScenarios
      .filter((s) => s.volumeCount < 20)
      .sort((s1, s2) => s1.monthlyPrice - s2.monthlyPrice);

    if (sortedStorageScenarios.length > 0) {
      selected = sortedStorageScenarios[0];
    }

    if (selected) {
      matchInfo['storageTypeKey'] = selected.storageKey;
      matchInfo['volumeCount'] = selected.volumeCount;
      matchInfo['price'] = selected.monthlyPrice;
      matchInfo['storageDebug'] = selected.storageDebug;
    } else {
      // console.error(`No storage match for vm ${vm.name}`);
    }

    return matchInfo;
  }


  findStorageScenarios(vm, storageType, storageKey, storagePrice, authorizedScenarios = [], simplifiedScenarios = true) {
    let scenarios = [];

    let vmMaxIops = vm.maxIops;
    let vmMaxThroughputMB = vm.maxThroughput / 1000;

    let requiredStorageBeforeAdjustment, minRequiredVolumeCount, requiredStorage = 0;
    let defaultIopsAreEnough, defaultThroughputIsEnough = false;

    if (!simplifiedScenarios) {
      requiredStorageBeforeAdjustment = Math.max(vm.storageProvisioned, storageType.volumeSizeMin);
      minRequiredVolumeCount = Math.ceil(requiredStorageBeforeAdjustment / storageType.volumeSizeMax);
      requiredStorage = Math.min(Math.max(vm.storageProvisioned, storageType.volumeSizeMin), storageType.volumeSizeMax) * minRequiredVolumeCount;
    } else {
      requiredStorageBeforeAdjustment = vm.storageProvisioned;
      minRequiredVolumeCount = 1;
      requiredStorage = vm.storageProvisioned;
    }

    defaultIopsAreEnough = vmMaxIops <= (storageType.iopsPerGB > 0 ? Math.min(storageType.iopsPerGB * requiredStorage, storageType.maxIopsPerVolume) : storageType.maxIopsPerVolume) * minRequiredVolumeCount;
    defaultThroughputIsEnough = vmMaxThroughputMB <= (storageType.throughputPerGB > 0 ? Math.min(storageType.throughputPerGB * requiredStorage, storageType.maxThroughputPerVolume) : storageType.maxThroughputPerVolume) * minRequiredVolumeCount;

    // Scenario 1: the storageTypes provide enough storage, throughput and IOPS
    if (authorizedScenarios.includes(1) && defaultIopsAreEnough && defaultThroughputIsEnough) {
      scenarios.push({
        storageKey: storageKey,
        storageType: storageType,
        storageSize: requiredStorage,
        volumeCount: minRequiredVolumeCount,
        additionalIops: 0,
        scenario: "IOPS and Throughput requirements are OK",
        storageDebug: {
          title: "Storage scenario <span class=\"label label-success\">IOPS and Throughput requirements are OK</span>",
          explanations: [
            `Using ${minRequiredVolumeCount} volume(s) of type <span class="label label-info">${storageKey}</span> for a total of ${requiredStorage} GB`,
            `This is enough to meet IOPS and Throughput requirements`,
            `IOPS requirements: ${vmMaxIops}`,
            `Throughput requirements: ${vmMaxThroughputMB} MB/s`,
          ]
        }
      })
    }

    // Scenario 2: IOPS is not enough or throughput is not enough: use more storage to meet requirements
    if (authorizedScenarios.includes(2)) {
      if ((!defaultIopsAreEnough && storageType.iopsPerGB > 0) || (!defaultThroughputIsEnough && storageType.throughputPerGB > 0)) {
        let remainingIops = defaultIopsAreEnough ? 0 : vmMaxIops - Math.min(storageType.maxIopsPerVolume, requiredStorage * storageType.iopsPerGB) * minRequiredVolumeCount;
        let remainingThroughput = defaultThroughputIsEnough ? 0 : vmMaxThroughputMB - Math.min(storageType.maxThroughputPerVolume, requiredStorage * storageType.throughputPerGB) * minRequiredVolumeCount;
        let additionalStorageRequiredIops = storageType.iopsPerGB > 0 ? remainingIops / storageType.iopsPerGB : 0;
        let additionalStorageRequiredThroughput = storageType.throughputPerGB > 0 ? remainingThroughput / storageType.throughputPerGB : 0;
        let additionalStorageRequired = Math.max(additionalStorageRequiredIops, additionalStorageRequiredThroughput);
        let additionalVolumeCount = Math.ceil((requiredStorage + additionalStorageRequired) / storageType.volumeSizeMax) - minRequiredVolumeCount;
        scenarios.push({
          storageKey: storageKey,
          storageType: storageType,
          storageSize: requiredStorage + additionalStorageRequired,
          volumeCount: minRequiredVolumeCount + additionalVolumeCount,
          additionalIops: 0,
          scenario: "add more GB to increase IOPS or Throughput",
          storageDebug: {
            title: "Storage scenario <span class=\"label label-warning\">add more GB to increase IOPS or Throughput</span>",
            explanations: [
              `Using ${minRequiredVolumeCount + additionalVolumeCount} volume(s) of <span class="label label-info">${storageKey}</span> for a total of ${(requiredStorage + additionalStorageRequired).toFixed(2)} GB`,
              `Used an additional capacity of ${(additionalStorageRequired).toFixed(2)} GB to get the missing ${(remainingIops).toFixed(1)} IOPS and ${(remainingThroughput).toFixed(1)} MB/s throughput`,
              `Each additional GB provides ${storageType.iopsPerGB} IOPS`,
              `Each additional GB provides ${storageType.throughputPerGB} MB/s throughput`
            ]
          }
        })
      }
    }

    // Scenario 3: IOPS is not enough: create several volumes to meet requirements
    // It only works if the storage type's iops are not dependent of the size of the volume (storageType.iopsPerGB === 0)
    if (authorizedScenarios.includes(3)) {
      if (!defaultIopsAreEnough && storageType.iopsPerGB === 0) {
        let remainingIops = vmMaxIops - storageType.maxIopsPerVolume * minRequiredVolumeCount - requiredStorage * storageType.iopsPerGB;
        let additionalVolumeCount = Math.ceil(remainingIops / storageType.maxIopsPerVolume);
        let averageStoragePerVolume = Math.max(requiredStorage / (minRequiredVolumeCount + additionalVolumeCount), storageType.volumeSizeMin);
        let additionalStorageRequired = Math.max(averageStoragePerVolume * (minRequiredVolumeCount + additionalVolumeCount) - vm.requiredStorage, 0);
        scenarios.push({
          storageKey: storageKey,
          storageType: storageType,
          storageSize: vm.requiredStorage + additionalStorageRequired,
          volumeCount: minRequiredVolumeCount + additionalVolumeCount,
          additionalIops: 0,
          scenario: "not enough IOPS: create additional volumes",
          storageDebug: {
            title: "Storage scenario <span class=\"label label-warning\">not enough IOPS: create additional volumes</span>",
            explanations: [
              `Using ${minRequiredVolumeCount + additionalVolumeCount} volume(s) of type <span class="label label-info">${storageKey}</span> for a total of ${requiredStorage + additionalStorageRequired} GB`,
              `Used ${additionalVolumeCount} more volumes to get the missing ${remainingIops} IOPS`,
              `Used an additional capacity of ${additionalStorageRequired} GB (${minRequiredVolumeCount + additionalVolumeCount} x <span class="label label-blue">${storageType.volumeSizeMin} GB<span class="badge">volume's minimum storage</span></span> - ${vm.requiredStorage} GB) if the average storage per volume is inferior to their minimal size`,
              `Each volume provides ${storageType.maxIopsPerVolume} IOPS`,
              `Each volume requires a minimum of ${storageType.volumeSizeMin} GB allocation per volume`,
              `Each volume creates an additional ${(additionalStorageRequired / (minRequiredVolumeCount + additionalVolumeCount)).toFixed(2)} GB footprint`
            ]
          }
        })
      }
    }

    // Scenario 4: Throughput is not enough: create several volumes to meet requirements
    // It only works if the storage type's throughput are not dependent of the size of the volume (storageType.throughputPerGB === 0)
    if (authorizedScenarios.includes(4)) {
      if (!defaultThroughputIsEnough && storageType.throughputPerGB === 0) {
        let remainingThroughput = vmMaxThroughputMB - Math.min(storageType.maxThroughputPerVolume, requiredStorage * storageType.throughputPerGB) * minRequiredVolumeCount;
        let additionalVolumeCount = Math.ceil(remainingThroughput / storageType.maxThroughputPerVolume);
        let averageStoragePerVolume = Math.max(requiredStorage / (minRequiredVolumeCount + additionalVolumeCount), storageType.volumeSizeMin);
        let additionalStorageRequired = Math.max(averageStoragePerVolume * (minRequiredVolumeCount + additionalVolumeCount) - vm.requiredStorage, 0);
        scenarios.push({
          storageKey: storageKey,
          storageType: storageType,
          volumeCount: minRequiredVolumeCount + additionalVolumeCount,
          storageSize: vm.requiredStorage + additionalStorageRequired,
          additionalIops: 0,
          scenario: "not enough Throughput: create additional volumes",
          storageDebug: {
            title: "Storage scenario <span class=\"label label-warning\">not enough Throughput: create additional volumes</span>",
            explanations: [
              `Using ${minRequiredVolumeCount + additionalVolumeCount} volume(s) of type <span class="label label-info">${storageKey}</span> for a total of ${requiredStorage + additionalStorageRequired} GB`,
              `Used ${additionalVolumeCount} more volumes to get the missing ${remainingThroughput} MB/s Throughput`,
              `Used an additional capacity of ${additionalStorageRequired} GB (${minRequiredVolumeCount + additionalVolumeCount} x <span class="label label-blue">${storageType.volumeSizeMin} GB<span class="badge">volume's minimum storage</span></span> - ${vm.requiredStorage} GB) if the average storage per volume is inferior to their minimal size`,
              `Each volume provides ${storageType.maxThroughputPerVolume} MB/s throughput`,
              `Each volume requires a minimum of ${storageType.volumeSizeMin} GB allocation per volume`,
              `Each volume creates an additional ${(additionalStorageRequired / (minRequiredVolumeCount + additionalVolumeCount)).toFixed(2)} GB footprint`
            ]
          }
        })
      }
    }

    // Scenario 5: only IOPS is not enough: buy additional IOPs
    if (authorizedScenarios.includes(5)) {
      if (!defaultIopsAreEnough && storageType.provisionedIops) {
        let remainingIops = vmMaxIops - storageType.maxIopsPerVolume * minRequiredVolumeCount - requiredStorage * storageType.iopsPerGB;
        scenarios.push({
          storageKey: storageKey,
          storageType: storageType,
          volumeCount: minRequiredVolumeCount,
          storageSize: requiredStorage,
          additionalIops: remainingIops,
          scenario: "not enough IOPS: buy more IOPS",
          storageDebug: {
            title: "Storage scenario <span class=\"label label-info\">not enough IOPS: buy more IOPS</span>",
            explanations: [
              `Using ${minRequiredVolumeCount} of type <span class="label label-info">${storageKey}</span> volume(s) for a total of ${requiredStorage} GB`,
              `Booked ${remainingIops} IOPS to get the missing ${remainingIops} IOPS`,
              `Each volume can be associated with more IOPS`
            ]
          }
        })
      }
    }

    // Scenario 6: only IOPS is not enough: use the storage type with the largest IOPS amount
    if (simplifiedScenarios && !defaultIopsAreEnough && authorizedScenarios.includes(6)) {
      // The following checks if the storage type's iops are dependent of the size of the volume :
      //   - if yes, use the maximum volume size to be close to expected iops
      //   - if no, use the required storage as it already has the max iops
      let storageSize = storageType.iopsPerGB > 0 ? storageType.volumeSizeMax : requiredStorage;
      scenarios.push({
        storageKey: storageKey,
        storageType: storageType,
        storageSize: storageSize,
        volumeCount: minRequiredVolumeCount,
        additionalIops: 0,
        scenario: "IOPS is not enough: using the storage type with the max IOPS",
        storageDebug: {
          title: "Storage scenario <span class=\"label label-success\">IOPS is not enough: using the storage type with the max IOPS</span>",
          explanations: [
            `Using ${minRequiredVolumeCount} volume(s) of type <span class="label label-info">${storageKey}</span> for a total of ${storageSize} GB`,
            `This storage type is the storage type that has the biggest amount of IOPS`
          ]
        }
      })
    }

    // Scenario 7: only Throughput is not enough: use the storage type with the largest Throughput amount
    if (simplifiedScenarios && authorizedScenarios.includes(7)) {
      // The following checks if the storage type's throughput are dependent of the size of the volume :
      //   - if yes, use the maximum volume size to be close to expected throughput
      //   - if no, use the required storage as it already has the max throughput
      let storageSize = storageType.throughputPerGB > 0 ? storageType.volumeSizeMax : requiredStorage;
      scenarios.push({
        storageKey: storageKey,
        storageType: storageType,
        storageSize: storageSize,
        volumeCount: minRequiredVolumeCount,
        additionalIops: 0,
        scenario: "Throughput is not enough: using the storage type with the max Throughput",
        storageDebug: {
          title: "Storage scenario <span class=\"label label-success\">Throughput is not enough: using the storage type with the max Throughput</span>",
          explanations: [
            `Using ${minRequiredVolumeCount} volume(s) of type <span class="label label-info">${storageKey}</span> for a total of ${storageSize} GB`,
            `This storage type is the storage type that has the biggest amount of Throughput`
          ]
        }
      })
    }

    let matchingScenarios = scenarios.filter((scenario) => {
      if (vm.requiredStorage == 0) {
        return true;
      }

      // Check that the volume count is consistent
      if (scenario.volumeCount < 1) {
        return false;
      }
      // Check that the volume count is consistent
      if (scenario.storageSize < 1 || Number.isNaN(scenario.storageSize)) {
        return false;
      }
      // Check there is enough storage
      if (!simplifiedScenarios && (scenario.storageSize / scenario.volumeCount) < scenario.storageType.volumeSizeMin) {
        return false;
      }
      if (!simplifiedScenarios && (scenario.storageSize / scenario.volumeCount) > scenario.storageType.volumeSizeMax) {
        return false;
      }
      // Check there is enough IOPS or is scenario 6
      if (((vmMaxIops - scenario.additionalIops) / scenario.volumeCount) > scenario.storageType.maxIopsPerVolume) {
        if (scenario.scenario !== "IOPS is not enough: using the storage type with the max IOPS") {
          return false;
        }
      }
      // Check there is enough throughput or is scenario 7
      if ((vmMaxThroughputMB / scenario.volumeCount) > scenario.storageType.maxThroughputPerVolume) {
        if (scenario.scenario !== "Throughput is not enough: using the storage type with the max Throughput") {
          return false;
        }
      }
      // Every criteria is OK
      return true;
    })

    // Set the price for each scenerios
    for (let scenario of matchingScenarios) {
      scenario.monthlyPrice = scenario.storageSize * storagePrice.volume + scenario.additionalIops * storagePrice.iops;
      scenario.storageDebug.monthlyPrice = scenario.monthlyPrice;
      scenario.storageDebug.explanations.push([`<b>Volume price</b>: ${scenario.storageSize.toFixed(2)} GB x ${storagePrice.volume.toFixed(2)} $/GB per month = ${(scenario.storageSize * storagePrice.volume).toFixed(2)}$ per month`])
      if (scenario.additionalIops > 0) {
        scenario.storageDebug.explanations.push([`<b>IOPS price</b>: ${scenario.additionalIops.toFixed(2)} IOPS x ${storagePrice.iops.toFixed(2)} $/IOPS per month = ${(scenario.additionalIops * storagePrice.iops).toFixed(2)}$ per month`])
      }
      scenario.storageDebug.explanations.push([`<b>Storage subtotal</b>: ${scenario.monthlyPrice.toFixed(2)}$ per month`])
    }

    return matchingScenarios;
  }


  getOsInProvider(osName, mapOS): any {
    let keys = Object.keys(mapOS).filter(function (t) {
      return osName.startsWith(t);
    });

    /** IE
     var keys = Object.keys(mapOS).filter(t => osName.startsWith(t));
     */

    if (keys.length > 0) {
      return mapOS[keys[0]];
    } else {
      return null;
    }
  }
}

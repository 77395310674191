import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NetscopeClustersViewerComponent } from './netscope-clusters-viewer/netscope-clusters-viewer.component';
import { NetscopeDashboardComponent } from './netscope-dashboard/netscope-dashboard.component';
import { NetscopeDependenciesViewerComponent } from './netscope-dependencies-viewer/netscope-dependencies-viewer.component';
import { NetscopeProtocolAnalysisComponent } from './netscope-protocol-analysis/netscope-protocol-analysis.component';
import { NetscopeTopologyComponent } from './netscope-topology/netscope-topology.component';
import { NetscopeHeatmapViewerComponent } from "./netscope-heatmap-viewer/netscope-heatmap-viewer.component";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {ClarityModule} from "@clr/angular";
import {AppRoutingModule} from "@app/app-routing.module";
import {HighchartsChartModule} from "highcharts-angular";
import {DataTablesModule} from "angular-datatables";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import { ExpandableRowsComponent } from './netscope-dashboard/expandable-rows/expandable-rows.component';
import {ExpandableRowsLinksComponent} from "@app/netscope/netscope-dependencies-viewer/expandable-rows-links/expandable-rows-links.component";
import {HeatmapComboboxComponent} from "@app/netscope/netscope-heatmap-viewer/heatmap-combobox/heatmap-combobox.component";
import { HeatmapHighchartsHeatmapComponent } from './netscope-heatmap-viewer/heatmap-highcharts-heatmap/heatmap-highcharts-heatmap.component';
import { NetscopeCustomProtocolsComponent } from './netscope-custom-protocols/netscope-custom-protocols.component';


@NgModule({
  declarations: [
    NetscopeClustersViewerComponent,
    NetscopeDashboardComponent,
    NetscopeDependenciesViewerComponent,
    NetscopeProtocolAnalysisComponent,
    NetscopeHeatmapViewerComponent,
    NetscopeTopologyComponent,
    ExpandableRowsComponent,
    ExpandableRowsLinksComponent,
    HeatmapComboboxComponent,
    HeatmapHighchartsHeatmapComponent,
    NetscopeCustomProtocolsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ClarityModule,
    AppRoutingModule,
    HighchartsChartModule,
    DataTablesModule,
    FontAwesomeModule,
    NgxSliderModule,
  ]
})
export class NetscopeModule { }

<div class="clr-row" *ngIf="isData">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <button class="btn btn-link btn-sm" style="float: right;size: 16px;" (click)="isSettings = true">
            <clr-icon shape="cog" size="16"></clr-icon>
        </button>
        <span style="float: right;color:grey;font-size:16px;">account: {{message.cloudAccount}}</span>
    </div>
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5>
                    <clr-icon shape="line-chart"></clr-icon>&nbsp;Power Graph
                </h5>

                <clr-toggle-container style="flex-direction:row;float:right">
                    <clr-toggle-wrapper style="margin-bottom:20px;margin-right:20px;">
                        <input type="checkbox" clrToggle [(ngModel)]="isStacked" (ngModelChange)="stackOnRegion()" [disabled]="isStackedDisable" />
                        <label>
                            <span class="btn btn-sm btn-link" style="margin:0px">stacked instances</span>
                        </label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>

                 <clr-toggle-container style="flex-direction:row;float:right">
                    <clr-toggle-wrapper style="margin-bottom:20px;margin-right:20px;">
                        <input type="checkbox" clrToggle [(ngModel)]="all_regions" (ngModelChange)="checkAll()" [disabled]="isAllDisable" />
                        <label>
                            <span class="btn btn-sm btn-link" style="margin:0px">all regions</span>
                        </label>
                    </clr-toggle-wrapper>
                </clr-toggle-container>

                <app-power-combobox (onChange)="all_regions = false; isStacked = false; updateChart()" [(selectedElements)]="selectedElements" [targetInfrastructure]="targetInfrastructure" style="width:100%;display:inline-block;"></app-power-combobox>
            </div>

            <div class="card-block" style="width:100%;display:block;">
                <app-power-graph [selectedElements]="selectedElements" [targetInfrastructure]="targetInfrastructure" #powerGraph *ngIf="!isStacked"></app-power-graph>
                <app-power-vm-stacked [selectedElements]="selectedElements" [targetInfrastructure]="targetInfrastructure" #powerVmStacked *ngIf="isStacked"></app-power-vm-stacked>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isData">
    <div class="card">
            <div class="card-block">
                    <div class="card-text">
                            <div>
                                    <strong class="center">No data available !</strong>
                            </div>
                    </div>
            </div>
    </div>
</div>

<clr-modal [(clrModalOpen)]="isSettings" [clrModalClosable]=false>
	<h3 class="modal-title">
			Settings
	</h3>
	<div class="modal-body" style="overflow:auto">
        <div class="card">
            <div class="card-header">
                <span style="color:grey;font-size:16px;">
                    Selected an account
                </span>
            </div>
            <div class="card-block">
                <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                        <select class="clr-select" [(ngModel)]="model.accountFullNameSelected">
                            <option *ngFor="let account of accounts">
                                {{account}}
                            </option>
                        </select>
                    </div>
                    <span class="clr-subtext">select an account</span>
                </div>
			</div>
        </div>
	</div>
	<div class="modal-footer" style="margin-top:20px">
			<button type="button" class="btn btn-link" (click)="isSettings = false; reloadRegions()">OK</button>
	</div>
</clr-modal>
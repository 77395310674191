import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Months } from '../report.enums';
import { CronOptions } from './reportcron_options';

export const Days = {
  'SUN': 'Sunday',
  'MON': 'Monday',
  'TUE': 'Tuesday',
  'WED': 'Wednesday',
  'THU': 'Thursday',
  'FRI': 'Friday',
  'SAT': 'Saturday'
};

@Component({
  selector: 'app-reportcron',
  templateUrl: './reportcron.component.html',
  styleUrls: ['./reportcron.component.css']
})
export class ReportcronComponent implements OnInit {

  @Input() options: CronOptions;

  activeTab: string;
  daily_active: boolean;
  weekly_active: boolean;
  monthly_active: boolean;
  yearly_active: boolean;

  selectOptions: any;
  data: any;

  @Input() cron: string;

  @Output() cronChange = new EventEmitter();
  pushcron() {
    this.cronChange.emit(this.cron);
  }

  /**
   * 
   */
  constructor() {
    this.selectOptions = this.initSelectOptions();
    this.daily_active = false;
    this.weekly_active = false;
    this.monthly_active = false;
    this.yearly_active = false;
  }

  
  /**
   * 
   */
  ngOnInit() {
    this.data = this.initDefaultData();
    this.updateDataFromCron(this.cron);
  }

  /**
   * 
   */
  setActiveTab(tab: string, regenerate: boolean): void {
    this.activeTab = tab;
    switch(tab) {
      case "daily":
        this.daily_active = true;
        this.weekly_active = false;
        this.monthly_active = false;
        this.yearly_active = false;
      break;
      case "weekly":
        this.daily_active = false;
        this.weekly_active = true;
        this.monthly_active = false;
        this.yearly_active = false;
      break;
      case "monthly":
        this.daily_active = false;
        this.weekly_active = false;
        this.monthly_active = true;
        this.yearly_active = false;
      break;
      case "yearly":
        this.daily_active = false;
        this.weekly_active = false;
        this.monthly_active = false;
        this.yearly_active = true;
      break;
    }

    if(regenerate)
      this.regenerateCron();
  }

  /**
   * 
   */
  dayDisplay(day: string): string {
    return Days[day];
  }

  /**
   * 
   */
  monthDisplay(month: number): string {
    return Months[month];
  }

  /**
   * 
   */
   monthDayDisplay(day: string): string {
    if (day === '28-31') {
      return 'Last Day';
    } else {
      return `${day}${this.getDaySuffix(day)} day`;
    }
  }

  /**
   * 
   */
  regenerateCron(): void {

    let day = null;

    switch (this.activeTab) {
      case 'daily':
        this.cron = `${this.data.daily.minutes} ${this.data.daily.hours} * * *`;
        break;
      case 'weekly':
        //let days = this.selectOptions.days
        //  .reduce((acc: any[], day: string | number) => this.data.weekly[day] ? acc.concat([day]) : acc, [])
        //  .join(',');
        day = this.data.weekly.day;
        this.cron = `${this.data.weekly.minutes} ${this.data.weekly.hours} * * ${day}`;
        break;
      case 'monthly':
        day = this.data.monthly.day;
        this.cron = `${this.data.monthly.minutes} ${this.data.monthly.hours} ${day} * *`;
        break;
      case 'yearly':
        day = this.data.yearly.day;
        this.cron = `${this.data.yearly.minutes} ${this.data.yearly.hours} ${day} ${this.data.yearly.month} *`;
        break;
      default:
        throw new Error('Invalid cron active tab selection');
    }

    // push cron to parent
    this.pushcron();

  }

  /**
   * 
   */
  private updateDataFromCron(cron: string): void {

    let fullcron = cron;

    // add seconds
    fullcron = `0 ${cron}`;

    // add years
    fullcron = `${fullcron} *`;

    let [seconds, minutes, hours, dayOfMonth, month, dayOfWeek] = fullcron.split(' ');

    if (fullcron.match(/\d+ \d+ \d+ \* \* (MON|TUE|WED|THU|FRI|SAT|SUN) \*/)) {
      this.setActiveTab('weekly', false);
      this.data.weekly.day = dayOfWeek;
      this.data.weekly.hours = Number(hours);
      this.data.weekly.minutes = Number(minutes);
      this.data.weekly.seconds = Number(seconds);
    
    } else if (fullcron.match(/\d+ \d+ \d+ \d+ \d+ \* \*/)) {
      this.setActiveTab('yearly', false);
      this.data.yearly.month = Number(month);
      this.data.yearly.day = dayOfMonth;
      this.data.yearly.hours = Number(hours);
      this.data.yearly.minutes = Number(minutes);
      this.data.yearly.seconds = Number(seconds);
    
    } else if (fullcron.match(/\d+ \d+ \d+ (\d+|28-31) \* \* \*/)) {
      this.setActiveTab('monthly', false);
      this.data.monthly.day = dayOfMonth;
      this.data.monthly.months = Number(month.substring(2));
      this.data.monthly.hours = Number(hours);
      this.data.monthly.minutes = Number(minutes);
      this.data.monthly.seconds = Number(seconds);

    } else if (fullcron.match(/\d+ \d+ \d+ \* \* \* \*/)) {
      this.setActiveTab('daily', false);
      this.data.daily.days = Number(dayOfMonth.substring(2));
      this.data.daily.hours = Number(hours);
      this.data.daily.minutes = Number(minutes);
      this.data.daily.seconds = Number(seconds);
    
    } 

  }

  /**
   * 
   */
  private getDaySuffix(value: string): string {
    if (value.length > 1) {
      let secondToLastDigit = value.charAt(value.length - 2);
      if (secondToLastDigit === '1') {
        return 'th';
      }
    }

    let lastDigit = value.charAt(value.length - 1);
    switch (lastDigit) {
      case '1':
        return 'st';
      case '2':
        return 'nd';
      case '3':
        return 'rd';
      default:
        return 'th';
    }
  }

  /**
   * 
   */
  private initDefaultData(): any {
    let [defaultHours, defaultMinutes, defaultSeconds] = this.options.defaultTime.split(':').map(Number);

    return {
      minutes: {
        minutes: 1,
        seconds: 0
      },
      hourly: {
        hours: 1,
        minutes: 0,
        seconds: 0
      },
      daily: {
        days: 1,
        hours: defaultHours,
        minutes: defaultMinutes,
        seconds: defaultSeconds
      },
      weekly: {
        day: "MON",
        hours: defaultHours,
        minutes: defaultMinutes,
        seconds: defaultSeconds,
      },
      monthly: {
        day: '1',
        months: 1,
        hours: defaultHours,
        minutes: defaultMinutes,
        seconds: defaultSeconds
      },
      yearly: {
        month: 1,
        day: '1',
        hours: (defaultHours),
        minutes: defaultMinutes,
        seconds: defaultSeconds
      }
    };
  }

  /**
   * 
   */
  private initSelectOptions(): any {
    return {
      days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
      months: this.getRange(1, 12),
      monthDays: this.getRange(1, 31),
      monthDaysWithLast: [...this.getRange(1, 31).map(String), '28-31'],
    };
  }

  /**
   * 
   */
  private getRange(startFrom: number, until: number): any {
    return Array.from({ length: (until + 1 - startFrom) }, (_, k) => k + startFrom);
  }

}

<div class="clr-row">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5><clr-icon shape="line-chart"></clr-icon>&nbsp;vSAN Graph</h5>
            </div>
            <div class="card-block" style="width:100%;display:block;">
                <highcharts-chart [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="options" [callbackFunction]="chartCallback" style="width:100%;display:block;"></highcharts-chart>
            </div> 
        </div>
    </div>
</div>
<div class="clr-row">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card">
            <div class="card-header card-ajust">
                <h5><clr-icon shape="storage"></clr-icon>&nbsp;Disk group information</h5>
            </div>
            <div class="card-block" style="width:100%;display:block;">
                <table *ngIf="dg_data" class="row-border hover cell-border stripe dt-body-center" datatable [dtOptions]="dg_dtOptions">
                    <thead>
                        <tr>
                        <th style="font-size:12px;width:5%;">Disk Group (UUID)</th>
                        <th style="font-size:12px;width:5%;">Server</th>
                        <th style="font-size:12px;width:5%;">Disk Capacity</th>
                        <th style="font-size:12px;width:5%;">Capacity (GB)</th>
                        <th style="font-size:12px;width:5%;">Cache (GB)</th>
                        <th style="font-size:12px;width:5%;">All flash</th>
                        </tr>
                    </thead>
    
                    <tbody>
                        <tr *ngFor="let host of dg_data">
                            <td>{{host.uuid}}</td>
                            <td>{{host.name}}</td>
                            <td>{{host.nbdiskcap}}</td>
                            <td>{{host.capacity / 1073741824}}</td>
                            <td>{{host.cache / 1073741824}}</td>
                            <td>{{host.allflash}}</td>
                        </tr>
                    </tbody>

                </table>
            </div> 
        </div>
    </div>
</div>

import { Component, ChangeDetectorRef, OnInit, AfterViewInit } from '@angular/core';

import { AccountService, JsonloaderService, SettingsService } from '@app/services';

import { Json, User } from '@app/model';

import * as Highcharts from 'highcharts/highcharts.src';
import highcharts3D from 'highcharts/highcharts-3d.src';
highcharts3D(Highcharts);

@Component({
  selector: 'app-t3',
  templateUrl: './t3.component.html',
  styleUrls: ['./t3.component.css']
})
export class T3Component implements OnInit, AfterViewInit {

  private currentUser: User;

  private jsonLoader: Json;

  highcharts: typeof Highcharts = Highcharts;
  chartOptions: any;

  data_t3: any;
  data_t3_a: any;
  data_t3_b: any;

  servers_after: number;
  servers_after_percent: number;
  servers_after_percent_color: string;

  watts_before: number;
  watts_after: number;
  watts_after_percent: number;
  watts_after_percent_color: string;

  carbon_after: number;
  carbon_after_percent: number;
  carbon_after_percent_color: string;

  isReady: boolean = false;


  constructor(private json_svc: JsonloaderService, private authentication_svc: AccountService,
	  private management_svc: SettingsService, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

    this.data_t3 = [];
	  this.data_t3_a = [];
	  this.data_t3_b = [];

	  this.json_svc.currentJson.subscribe(
      json => {
        this.jsonLoader = json;
        if(this.jsonLoader.opportunities != null) {
          this.data_t3 = this.jsonLoader.opportunities;
          this.data_t3_a = this.jsonLoader.t3a;
          this.data_t3_b = this.jsonLoader.t3b;
          this.initData();
        } else {
          this.data_t3 = [];
	        this.data_t3_a = [];
          this.data_t3_b = [];
          this.isReady = false;
        }
      }
    );
	
	  this.authentication_svc.user.subscribe(user => this.currentUser = user);
  }

  ngAfterViewInit(): void {
    if(Object.keys(this.data_t3).length > 0)
	    this.opport_after();
  }

  ngAfterViewChecked(): void {
  	this.cd.detectChanges();
  }

  resizeCard(): void {
	  $('#dashboard-t3-core').slideToggle();
  }

  closeCard(): void {
    this.management_svc.updateSettings(this.currentUser.login, 'isT3', false).subscribe(
      error => {
        if(error != null)
          console.log(error)
      }
    );
    $('#dashboard-t3').hide();
  }

  opport_before(): void {
	  this.buildCard(this.data_t3_b);
  }

  opport_after(): void {
	  this.buildCard(this.data_t3_a);
  }

  private buildCard(data): void {
    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
        },
        backgroundColor:'rgba(255, 255, 255, 0.0)',
        margin: [0, 0, 0, 0]
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true
        },
        borderWidth : 0,
        depth: 35
        }
      },
		  series: [{
        colorByPoint: true,
        showInLegend: false,
        innerSize: '30%',
        turboThreshold : 3000,
        dataLabels: {
          enabled: false
        },
        type: 'pie',
        data: data
      }]
	  };
    this.isReady = true;
  }

  initData(): void {
    let servers_before = Number(this.data_t3[2]);
    this.servers_after = Number(this.data_t3[3]);
    this.servers_after_percent = Number(Number(-(100 - (this.servers_after * 100 / servers_before))).toFixed(0));
    this.servers_after_percent_color = this.servers_after_percent <= 0 ? '#306b00' : '#991700';

    this.watts_before = Number(Number(this.data_t3[4]).toFixed(0));
    this.watts_after = Number(Number(this.data_t3[5]).toFixed(0));
    this.watts_after_percent = Number(Number(-(100 - (this.watts_after * 100 / this.watts_before))).toFixed(0));
    this.watts_after_percent_color = this.watts_after_percent <= 0 ? '#306b00' : '#991700';

    let carbon_before = Number(this.data_t3[9]);
    this.carbon_after = Number(this.data_t3[10]);
    this.carbon_after_percent = Number(Number(-(100 - (this.carbon_after * 100 / carbon_before))).toFixed(0));
    this.carbon_after_percent_color = this.carbon_after_percent <= 0 ? '#306b00' : '#991700';

  }

  /**
   * 
   */
  formatNumber(value: number): string {
    return Intl.NumberFormat().format(value);
  }

}

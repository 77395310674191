import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-troubleshooting-graph]'
})
export class TroubleshootinggraphDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

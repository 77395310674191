import { AfterViewInit, Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import * as Highcharts from "highcharts/highstock";
import { Options } from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import * as moment from 'moment';

import { MeasurementService, ShareService } from '@app/services';

import { Counter, Message, MergeDISKCMD, MergeDISKLAT, MergeNET, MergeRAM, MergeTMCAOH, MergeTMVOVM, MergeVMO, MergeSRDY, MergeSMO, TsSelected } from '@app/model';


@Component({
  selector: 'app-troubleshootinggraph',
  templateUrl: './troubleshootinggraph.component.html',
  styleUrls: ['./troubleshootinggraph.component.css']
})
export class TroubleshootinggraphComponent implements OnInit {

  message: Message;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;

  isReady: boolean = false;

  private ts_selected: TsSelected;

  private requests_counter: Counter[] = [];

  private colors: string[] = [];


  constructor(
	private measurement_svc: MeasurementService,
	private message_svc: ShareService) {
  }

  ngOnInit(): void {

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.ts_selected = this.message.troubleSelect;
  }

  ngAfterViewInit(): void {

	this.initColors();
	this.getData();
  }

  private getData(): void {

	let start: number = this.message.minTimeFilter;
	if(this.message.isTroubleMap) {
		const start_str: string = moment.unix(this.message.maxTimeFilter/1000).subtract(30, 'days').startOf('day').format("x");
		start = +start_str;
	}

	let type: string = 'VM';
	let uuid: string = this.ts_selected.uuid;
	let metrics: any;

	switch(this.message.troubleCounter) {
		case "tmcaoh":
			metrics = [
				{
					"counter": "CPU_TMCAOH",
					"rollup": ["AVG", "MAX"]
				}, {
					"counter": "CPU_READY",
					"rollup": ["AVG"]
				}
			];
			break;
		case "tmvovm":
			metrics = [
				{
					"counter": "CPU_TMVOVM",
					"rollup": ["AVG", "MAX"]
				}, {
					"counter": "CPU_COSTOP",
					"rollup": ["AVG"]
				}
			];
			break;
		case "vmo":
			metrics = [
				{
					"counter": "CPU_VMO",
					"rollup": ["AVG", "MAX"]
				}, {
					"counter": "CPU_USAGE_INTRA",
					"rollup": ["AVG"]
				}
			];
			break;
		case "smo":
			type = 'HOST';
			uuid = this.ts_selected.father_id;
			metrics = [
				{
					"counter": "CPU_USAGE",
					"rollup": ["AVG", "MAX"]
				}
			];
			break;
		case "diskcmd":
			metrics = [
				{
					"counter": "DISK_CMD_ABORTED",
					"rollup": ["AVG", "MAX"]
				}
			];
			break;
		case "disklat":
			metrics = [
				{
					"counter": "DISK_RW_LATENCY",
					"rollup": ["AVG", "MAX"]
				}
			];
			break;
		case "sdisk":
			type = 'HOST';
			uuid = this.ts_selected.father_id;
			metrics = [
				{
					"counter": "DISK_RW_LATENCY",
					"rollup": ["AVG", "MAX"]
				}
			];
			break;
		case "net":
			metrics = [
				{
					"counter": "NET_DROPPED",
					"rollup": ["AVG", "MAX"]
				}
			];
			break;
		case "snet":
			type = 'HOST';
			uuid = this.ts_selected.father_id;
			metrics = [
				{
					"counter": "NET_DROPPED",
					"rollup": ["AVG", "MAX"]
				}
			];
			break;
		case "ram":
			metrics = [
				{
					"counter": "RAM_USAGE_INTRA",
					"rollup": ["AVG", "MAX"]
				}
			];
			break;
		case "sram":
			type = 'HOST';
			uuid = this.ts_selected.father_id;
			metrics = [
				{
					"counter": "RAM_USAGE",
					"rollup": ["AVG", "MAX"]
				}
			];
			break;
		default:
			break;
	}

	this.measurement_svc.getTroubleGraph(uuid, type, start, this.message.maxTimeFilter, metrics).pipe(first()).subscribe(
		data => {
			this.requests_counter = data;
	  		this.buildData();
		},
		error => {
			if(error != null)
				console.log(error)
		}
	);
  }

  private buildData(): void {

	let vcpu = 2;
	if(this.ts_selected.cpu_nb > 0)
		vcpu = this.ts_selected.cpu_nb;

	let seriesOptions = [];
	let spec_counters: string[] = [];

	switch(this.message.troubleCounter) {
		case "tmcaoh":
			spec_counters = ['CRITICAL_THRESHOLD', 'WARNING_THRESHOLD', 'MAX_CPU_TMCAOH', 'AVG_CPU_TMCAOH', 'AVG_CPU_READY'];
			break;
		case "tmvovm":
			spec_counters = ['CRITICAL_THRESHOLD', 'WARNING_THRESHOLD', 'MAX_CPU_TMVOVM', 'AVG_CPU_TMVOVM', 'AVG_CPU_COSTOP'];
			break;
		case "vmo":
			spec_counters = ['CRITICAL_THRESHOLD', 'WARNING_THRESHOLD', 'MAX_CPU_VMO', 'AVG_CPU_VMO', 'AVG_CPU_USAGE_INTRA'];
			break;
		case "smo":
			spec_counters = ['CRITICAL_THRESHOLD', 'WARNING_THRESHOLD', 'MAX_CPU_USAGE', 'AVG_CPU_USAGE'];
			break;
		case "diskcmd":
			spec_counters = ['CRITICAL_THRESHOLD', 'WARNING_THRESHOLD', 'MAX_DISK_CMD_ABORTED', 'AVG_DISK_CMD_ABORTED'];
			break;
		case "disklat":
		case "sdisk":
			spec_counters = ['CRITICAL_THRESHOLD', 'WARNING_THRESHOLD', 'MAX_DISK_RW_LATENCY', 'AVG_DISK_RW_LATENCY'];
			break;
		case "net":
		case "snet":
			spec_counters = ['CRITICAL_THRESHOLD', 'WARNING_THRESHOLD', 'MAX_NET_DROPPED', 'AVG_NET_DROPPED'];
			break;
		case "ram":
			spec_counters = ['CRITICAL_THRESHOLD', 'WARNING_THRESHOLD', 'MAX_RAM_USAGE_INTRA', 'AVG_RAM_USAGE_INTRA'];
			break;
		case "sram":
			spec_counters = ['CRITICAL_THRESHOLD', 'WARNING_THRESHOLD', 'MAX_RAM_USAGE', 'AVG_RAM_USAGE'];
			break;
		default:
			break;
	}

	let mergedMeasurements = {};
	for(let i=0; i < this.requests_counter.length; i++) {
		if (!mergedMeasurements[this.requests_counter[i].time]) {
			mergedMeasurements[i] = {};
                        mergedMeasurements[i]["timeago"] = this.requests_counter[i].time;
                        mergedMeasurements[i]["metrics"] = {};
		}
                Object.assign(mergedMeasurements[i]["metrics"], this.toDict(this.requests_counter[i].dataPoints));
	}

	for(let i = 0; i < spec_counters.length; i++) {
		let legend_name = spec_counters[i];
		var linestyle = 'solid';
		let counterData = [];
		switch(this.message.troubleCounter) {
			case "tmcaoh":
				Object.values(mergedMeasurements).forEach((measurement: MergeTMCAOH,j) => {
					counterData[j] = [];
					counterData[j][0] = measurement.timeago;
					if(spec_counters[i] == "AVG_CPU_READY") {
						counterData[j][1] = measurement.metrics.AVG_CPU_READY/200/vcpu; 
					} else if(spec_counters[i] == "AVG_CPU_TMCAOH") {
						counterData[j][1] = measurement.metrics.AVG_CPU_TMCAOH;
					} else if(spec_counters[i] == "MAX_CPU_TMCAOH") {
						counterData[j][1] = measurement.metrics.MAX_CPU_TMCAOH;
						linestyle = 'shortdot';
					} else if(spec_counters[i] == "CRITICAL_THRESHOLD") {
						counterData[j][1] = 10;
					} else if(spec_counters[i] == "WARNING_THRESHOLD") {
						counterData[j][1] = 5;
					}
				});
				break;
			case "tmvovm":
				Object.values(mergedMeasurements).forEach((measurement: MergeTMVOVM,j) => {
					counterData[j] = [];
					counterData[j][0] = measurement.timeago;
					if(spec_counters[i] == "AVG_CPU_COSTOP") {
						counterData[j][1] = measurement.metrics.AVG_CPU_COSTOP/200/vcpu; 
					} else if(spec_counters[i] == "AVG_CPU_TMVOVM") {
						counterData[j][1] = measurement.metrics.AVG_CPU_TMVOVM;
					} else if(spec_counters[i] == "MAX_CPU_TMVOVM") {
						counterData[j][1] = measurement.metrics.MAX_CPU_TMVOVM;
					} else if(spec_counters[i] == "CRITICAL_THRESHOLD") {
						counterData[j][1] = 3;
					} else if(spec_counters[i] == "WARNING_THRESHOLD") {
						counterData[j][1] = 1;
					}
				});
				break;
			case "vmo":
				Object.values(mergedMeasurements).forEach((measurement: MergeVMO,j) => {
					counterData[j] = [];
					counterData[j][0] = measurement.timeago;
					if(spec_counters[i] == "AVG_CPU_USAGE_INTRA") {
						counterData[j][1] = measurement.metrics.AVG_CPU_USAGE_INTRA/10; 
					} else if(spec_counters[i] == "AVG_CPU_VMO") {
						counterData[j][1] = measurement.metrics.AVG_CPU_VMO;
					} else if(spec_counters[i] == "MAX_CPU_VMO") {
						counterData[j][1] = measurement.metrics.MAX_CPU_VMO;
					} else if(spec_counters[i] == "CRITICAL_THRESHOLD") {
						counterData[j][1] = 95;
					} else if(spec_counters[i] == "WARNING_THRESHOLD") {
						counterData[j][1] = 90;
					}
				});
				break;
			case "smo":
				Object.values(mergedMeasurements).forEach((measurement: MergeSMO,j) => {
					counterData[j] = [];
					counterData[j][0] = measurement.timeago;
					if(spec_counters[i] == "AVG_CPU_USAGE") {
						counterData[j][1] = measurement.metrics.AVG_CPU_USAGE/10;
					} else if (spec_counters[i] == "MAX_CPU_USAGE") {
						counterData[j][1] = measurement.metrics.MAX_CPU_USAGE/10;
					} else if(spec_counters[i] == "WARNING_THRESHOLD") {
						counterData[j][1] = 90;
					} else if(spec_counters[i] == "CRITICAL_THRESHOLD") {
						counterData[j][1] = 95;
					}
				});
				break;
			case "diskcmd":
				Object.values(mergedMeasurements).forEach((measurement: MergeDISKCMD,j) => {
					counterData[j] = [];
					counterData[j][0] = measurement.timeago;
					if(spec_counters[i] == "AVG_DISK_CMD_ABORTED") {
						counterData[j][1] = measurement.metrics.AVG_DISK_CMD_ABORTED;
					} else if(spec_counters[i] == "MAX_DISK_CMD_ABORTED") {
						counterData[j][1] = measurement.metrics.MAX_DISK_CMD_ABORTED;
					} else if(spec_counters[i] == "CRITICAL_THRESHOLD") {
						counterData[j][1] = 5;
					} else if(spec_counters[i] == "WARNING_THRESHOLD") {
						counterData[j][1] = 1;
					}
				});
				break;
			case "disklat":
			case "sdisk":
				Object.values(mergedMeasurements).forEach((measurement: MergeDISKLAT,j) => {
					counterData[j] = [];
					counterData[j][0] = measurement.timeago;
					if(spec_counters[i] == "AVG_DISK_RW_LATENCY") {
						counterData[j][1] = measurement.metrics.AVG_DISK_RW_LATENCY;
					} else if(spec_counters[i] == "MAX_DISK_RW_LATENCY") {
						counterData[j][1] = measurement.metrics.MAX_DISK_RW_LATENCY;
					} else if(spec_counters[i] == "CRITICAL_THRESHOLD") {
						counterData[j][1] = 30;
					} else if(spec_counters[i] == "WARNING_THRESHOLD") {
						counterData[j][1] = 20;
					}
				});
				break;
			case "net":
			case "snet":
				Object.values(mergedMeasurements).forEach((measurement: MergeNET,j) => {
					counterData[j] = [];
					counterData[j][0] = measurement.timeago;
					if(spec_counters[i] == "AVG_NET_DROPPED") {
						counterData[j][1] = measurement.metrics.AVG_NET_DROPPED;
					} else if(spec_counters[i] == "MAX_NET_DROPPED") {
						counterData[j][1] = measurement.metrics.MAX_NET_DROPPED;
					} else if(spec_counters[i] == "CRITICAL_THRESHOLD") {
						counterData[j][1] = 5;
					} else if(spec_counters[i] == "WARNING_THRESHOLD") {
						counterData[j][1] = 1;
					}
				});
				break;
			case "ram":
				Object.values(mergedMeasurements).forEach((measurement: MergeRAM,j) => {
					counterData[j] = [];
					counterData[j][0] = measurement.timeago;
					if(spec_counters[i] == "AVG_RAM_USAGE_INTRA") {
						counterData[j][1] = measurement.metrics.AVG_RAM_USAGE_INTRA/10;
					} else if(spec_counters[i] == "MAX_RAM_USAGE_INTRA") {
						counterData[j][1] = measurement.metrics.MAX_RAM_USAGE_INTRA/10;
					} else if(spec_counters[i] == "CRITICAL_THRESHOLD") {
						counterData[j][1] = 90;
					} else if(spec_counters[i] == "WARNING_THRESHOLD") {
						counterData[j][1] = 70;
					}
				});
				break;
			case "sram":
				Object.values(mergedMeasurements).forEach((measurement: MergeRAM,j) => {
					counterData[j] = [];
					counterData[j][0] = measurement.timeago;
					if(spec_counters[i] == "AVG_RAM_USAGE") {
						counterData[j][1] = measurement.metrics.AVG_RAM_USAGE/10;
					} else if(spec_counters[i] == "MAX_RAM_USAGE") {
						counterData[j][1] = measurement.metrics.MAX_RAM_USAGE/10;
					} else if(spec_counters[i] == "CRITICAL_THRESHOLD") {
						counterData[j][1] = 90;
					} else if(spec_counters[i] == "WARNING_THRESHOLD") {
						counterData[j][1] = 70;
					}
				});
				break;
			default:
				break;
		}
		seriesOptions[i] = {
			name: legend_name,
			color: this.colors[i],
            		cursor: 'pointer',
			dashStyle: linestyle,
            		data: counterData
        	};
	}
	this.callLineGraph(seriesOptions);
  }

  private toDict(dataPoints: any): any {

        var result = {};
        dataPoints.forEach(dp => {
             result[dp.metricName] = dp.value
        });

        return result;
  }

  private callLineGraph(data: any): void {

    let spec_unit: string = '%';
    switch(this.message.troubleCounter) {
	case "diskcmd":
		spec_unit = 'disk command aborted';
		break;
	case "disklat":
	case "sdisk":
		spec_unit = 'ms';
		break;
	case "net":
	case "snet":
		spec_unit = 'network packets dropped';
		break;
	default:
		break;
    }

    this.chartOptions = {
    	credits: {
        	enabled: false
	},
        exporting: {
            enabled: false
	},
	title: {
        text: this.ts_selected.name
	},
	chart: {
            zoomType: 'x',
            resetZoomButton: {
                position: {
                    align: 'center',
                    y: -15
                }
            },
	    backgroundColor: '#ffffff',
            marginRight: 0,
            marginLeft: 0
        },
	navigator: {
            enabled: true
        },
        scrollbar: {
            enabled: true
        },
        rangeSelector: {
            inputEnabled: false,
            enabled: true
	},
	time: {
	    useUTC: false,
	},
	legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical',
            x: 0,
            y: -50,
	    labelFormatter: function() {
		let legend_str = ''
		switch(this.name) {
			case "CRITICAL_THRESHOLD":
				legend_str = 'critical threshold (' + spec_unit + ')';
				break;
			case "WARNING_THRESHOLD":
				legend_str = 'warning threshold (' + spec_unit + ')';
				break;
			case "MAX_CPU_TMCAOH":
				legend_str = 'maximum of too much cpu activity on server (' + spec_unit + ')';
				break;
			case "AVG_CPU_TMCAOH":
				legend_str = 'average of too much cpu activity on server (' + spec_unit + ')';
				break;
			case "AVG_CPU_READY":
				legend_str = 'average of cpu ready (' + spec_unit + ')';
				break;
			case "MAX_CPU_TMVOVM":
				legend_str = 'maximum of too much vCPU on VM (' + spec_unit + ')';
				break;
			case "AVG_CPU_TMVOVM":
				legend_str = 'average of too much vCPU on VM (' + spec_unit + ')';
				break;
			case "AVG_CPU_COSTOP":
				legend_str = 'average of cpu costop (' + spec_unit + ')';
				break;
			case "MAX_CPU_VMO":
				legend_str = 'maximum of VM overloaded in cpu (' + spec_unit + ')';
				break;
			case "AVG_CPU_VMO":
				legend_str = 'average of VM overloaded in cpu (' + spec_unit + ')';
				break;
			case "AVG_CPU_USAGE_INTRA":
			case "AVG_CPU_USAGE":
				legend_str = 'average of cpu usage (' + spec_unit + ')';
				break;
			case "MAX_CPU_USAGE":
				legend_str = 'maximum of cpu usage (' + spec_unit + ')';
				break;
			case "MAX_CPU_READY":
				legend_str = 'maximum of cpu ready (' + spec_unit + ')';
				break;
			case "MAX_DISK_CMD_ABORTED":
				legend_str = 'maximum of ' + spec_unit;
				break;
			case "AVG_DISK_CMD_ABORTED":
				legend_str = 'average of ' + spec_unit;
				break;
			case "MAX_DISK_RW_LATENCY":
				legend_str = 'maximum of disk latency (' + spec_unit + ')';
				break;
			case "AVG_DISK_RW_LATENCY":
				legend_str = 'average of disk latency (' + spec_unit + ')';
				break;
			case "MAX_NET_DROPPED":
				legend_str = 'maximum of ' + spec_unit;
				break;
			case "AVG_NET_DROPPED":
				legend_str = 'average of ' + spec_unit;
				break;
			case "MAX_RAM_USAGE_INTRA":
			case "MAX_RAM_USAGE":
				legend_str = 'maximum of ram usage (' + spec_unit + ')';
				break;
			case "AVG_RAM_USAGE_INTRA":
			case "AVG_RAM_USAGE":
				legend_str = 'average of ram usage (' + spec_unit + ')';
				break;
			default:
				legend_str = this.name;
				break;
		}
		return legend_str;
            }
        },
	xAxis: {
		type: 'datetime'
	},
	yAxis: {
            min: 0,
            labels: {
                align: 'right',
                x: -10
            },
            title: {
                text: spec_unit
            }
        },
        tooltip: {
            shared: true,
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y} ' + spec_unit + '</b><br/>',
            valueDecimals: 0
        },
        series: data
  };
	this.isReady = true;
	this.message.waiting = false;
  }

  private initColors(): void {

        this.colors[0] = '#FF140C';
	this.colors[1] = '#FFD700';
	this.colors[2] = '#A7A37E';
        this.colors[3] = '#ADCF4F';
        this.colors[4] = '#A2B5BF';
        this.colors[5] = '#B78178';
        this.colors[6] = '#183152';
        this.colors[7] = '#5EB6DD';
        this.colors[8] = '#A67E2E';
        this.colors[9] = '#4C1B1B';
  }
}

import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { DatePipe } from '@angular/common';

import { AccountService, InfrastructureService, JsonloaderService, SettingsService, ShareService } from '@app/services';

import { ClusterSynthesis, Comment, HostSynthesis, Infrastructure, Json, JSONTarget, Message, VmSynthesis, User } from '@app/model';

import * as Highcharts from 'highcharts';

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import { ConsoverComponent } from '@app/consover/consover.component';
import { VmconsoverDirective } from '@app/directives/vmconsover.directive';

import { TroubleshootingcardComponent } from '@app/troubleshooting/troubleshootingcard/troubleshootingcard.component';
import { TroubleshootingDirective } from '@app/directives/troubleshooting.directive';

import { ConscalendarComponent } from '@app/conscalendar/conscalendar.component';
import { ConscalendarDirective } from '@app/directives/conscalendar.directive';

/*import { WeeklycalComponent } from '@app/weeklycal/weeklycal.component';
import { WeeklycalDirective } from '@app/directives/weeklycal.directive';

import { MonthlycalComponent } from '@app/monthlycal/monthlycal.component';
import { MonthlycalDirective } from '@app/directives/monthlycal.directive';

import { YearlycalComponent } from '@app/yearlycal/yearlycal.component';
import { YearlycalDirective } from '@app/directives/yearlycal.directive';*/

import { BehaviorcalComponent } from '@app/behaviorcal/behaviorcal.component';
import { BehaviorcalDirective } from '@app/directives/behaviorcal.directive';

import { LcyComponent } from '@app/lcy/lcy.component';
import { LcyDirective } from '@app/directives/lcy.directive';

import { commaBigNumber, getUserCurrency } from '../../assets/js/tools.js';

var componentRef3: ComponentRef<any>;


@Component({
  selector: 'app-vmsynthesis',
  templateUrl: './vmsynthesis.component.html',
  styleUrls: ['./vmsynthesis.component.css']
})
export class VmsynthesisComponent implements OnInit {

  isDcscope = true;
  isCo2scope = false;

  jsonLoader: Json;

  @ViewChild(VmconsoverDirective, {static: false}) addVmConsover2: VmconsoverDirective;

  @ViewChild(TroubleshootingDirective, {static: false}) addTroubleshooting: TroubleshootingDirective;

  @ViewChild(ConscalendarDirective, {static: false}) addConsCal: ConscalendarDirective;
  /*@ViewChild(WeeklycalDirective, {static: false}) addWeeklyCal: WeeklycalDirective;

  @ViewChild(MonthlycalDirective, {static: false}) addMonthlyCal: MonthlycalDirective;

  @ViewChild(YearlycalDirective, {static: false}) addYearlyCal: YearlycalDirective;*/

  @ViewChild(BehaviorcalDirective, {static: false}) addBehaviorCal: BehaviorcalDirective;

  @ViewChild(LcyDirective, {static: false}) addLcy: LcyDirective;

  math = Math;

  message: Message;

  data_vms: any = [];
  
  data_hosts: any = [];

  data_clusters: any = [];

  vm_avg: VmSynthesis;

  vm_synth: VmSynthesis = {
	uuid: '',
	name: '',
	father: '',
	cluster: '',
	sizingcpu: 0,
	sizingram: 0,
	cpucons: 0,
	ramcons: 0,
	stocap: 0,
	stocons: 0,
	tscons: 0,
	modiftime: '',
	cost: 0,
	vmwarecomment: '',
	fd: '',
	host: '',
	cpu: 0,
	ram: 0,
	vcpu: 0,
	vram: 0,
	state: '',
	os: '',
	tools: '',
	behavior: '',
	ha: '',
	dss: '',
	dsscap: 0,
	tags: '',
	rp: '',
    rpuuid: '',
	ds: 0,
	tsstate: '',
	co2: 0
  };

  infra: Infrastructure = {
	cluster: '',
	datacenter: ''
  };

  comment : Comment = {
    ID: 0,
    USER: 'not registered',
    IDENTIFIER: 'not registered',
    COMMENT: 'not registered',
    OWNER: 'not registered',
    ENV: 'not registered',
    CREATE_DATE: 'not registered',
    END_DATE: 'not registered'
  };

  trouble_state: string = 'success';

  trouble_main_state: string = 'normal';

  isNoTsAlert: boolean = false;

  behavior_state: string = 'success';

  isNoBehAlert: boolean = false;

  newdate: string = '';

  dtOptions: any;

  zoom: boolean = false;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;

  isReady: boolean = false;

  currentUser: User;

  globalCurrency: string = '';

  general_info_class: string = 'clr-col-lg-6 clr-col-md-6 clr-col-12';
  content_info_class: string = 'clr-col-lg-6 clr-col-md-6 clr-col-12';

  datas_green: any = [];


  constructor(
	private router: Router,
	private componentFactoryResolver: ComponentFactoryResolver,
	private authentication_svc: AccountService,
	private infra_svc: InfrastructureService,
	private settings_svc: SettingsService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
	this.data_vms = this.jsonLoader.vmSynthesis;

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.isVmConsover = true;
	this.message.powerUsageEnv = 'vmware';

	this.isCo2scope = this.message.isCO2Scope;
	this.isDcscope = this.message.isDCScope;

	if (this.isDcscope) {
		this.general_info_class = 'clr-col-lg-6 clr-col-md-6 clr-col-12';
		this.content_info_class = 'clr-col-lg-6 clr-col-md-6 clr-col-12';
	} else {
		this.general_info_class = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
		this.content_info_class = 'clr-col-lg-3 clr-col-md-3 clr-col-12';
	}

	this.dtOptions = {
		pagingType: 'full_numbers',
		pageLength: 10,
		processing: true,
		columnDefs: [
        		{ targets: '_all', className: 'dt-body-center' }
		],
		dom: '<"top">rt<"bottom"ip>'
	};

	this.buildCard();
  }

  ngAfterViewInit(): void {

	if(this.currentUser.isMenuTrouble && this.vm_synth.state != "OFF")
		this.setTroubleshooting();
	else
		this.buildRadar();
  }

  ngAfterViewChecked(): void {

	if(this.message.isReloadConsover)
		this.reloadVmConsOver();

  	this.cd.detectChanges();
  }

  setCard():void {

	this.buildCard();
	if(this.currentUser.isMenuTrouble && this.vm_synth.state != "OFF")
		this.setTroubleshooting();

	setTimeout(() => $('#csstoadjust').parent().find('clr-date-container').removeClass('clr-form-control'), 100);
  }

  /*setOverallCons(): void {
	setTimeout(() => this.loadVmConsOver(), 100);
  }*/

  filterVm(vms: any): void {

	let vm: any = [];
	for (var i in vms) {
		if(vms[i][2] == this.message.currentUuid)
			vm.push(vms[i]);
	}

	return vm;
  }

  setCalCons(): void {
	setTimeout(() => this.loaddConsCal(), 100);
  }

  /*setWeeklyCons():void {
	setTimeout(() => this.loadWeeklyCal(), 100);
  }

  setMonthlyCons():void {
	setTimeout(() => this.loadMonthlyCal(), 100);
  }

  setYearlyCons():void {
	setTimeout(() => this.loadYearlyCal(), 100);
  }*/

  setYearlyBehavior():void {
	setTimeout(() => this.loadYearlyBehavior(), 100);
  }

  setLcy():void {
	setTimeout(() => this.loadLcy(), 100);
  }

  updateOwner(value): void {
	this.comment.OWNER = value;
	if(value == "")
		value = 'NULL';
	this.callUpdateComment('OWNER', value);
  }

  updateComment(value): void {
	this.comment.COMMENT = value;
	if(value == "")
		value = 'NULL';

	this.callUpdateComment('COMMENT', value);
  }

  updateEnv(value): void {
	this.comment.ENV = value;
	if(value == "")
		value = 'NULL';
	this.callUpdateComment('ENV', value);
  }

  updateEndDate(): void {
	if(this.newdate != '') {
		let formatedDate = new DatePipe('en-US').transform(this.newdate, 'fullDate');
		this.comment.END_DATE = formatedDate;
		this.callUpdateComment('END_DATE', formatedDate);
		this.newdate = '';
	}
  }

  setTroubleshooting(): void {
	setTimeout(() => this.loadTroubleshooting(), 100);
  }

  setRadarActivity(): void {
	this.buildRadar();
  }

  callHostRoute(name): void {

	this.data_hosts = this.jsonLoader.hostSynthesis;
	var host: HostSynthesis = this.getHostData(name);
	this.message.currentUuid = host.uuid;
	this.message.currentName = name;
	this.message.currentType = 'SERVER';
	this.router.navigate(['/hostviews/synthesis']);
  }

  callClusterRoute(name): void {

	this.data_clusters = this.jsonLoader.clusterSynthesis;
	var cluster: ClusterSynthesis = this.getClusterData(name);
	this.message.currentUuid = cluster.uuid;
	this.message.currentName = name;
	this.message.currentType = 'CLUSTER';
	this.router.navigate(['/clusterviews/synthesis']);
  }

  private buildCard() {

	if(this.message != null) {
		if(this.message.currentUuid != '') {
			this.vm_synth = this.getVmData(this.message.currentUuid);
			if(this.vm_synth === undefined) {
				this.message.currentUuid = '';
				this.buildCard();
			}
		} else {
			this.vm_synth = this.data_vms[0];
			this.message.currentUuid = this.vm_synth.uuid;
			this.message.currentName = this.vm_synth.name;
			this.message.currentType = 'VM';
		}
		this.setAlertStates();

		// SET COST
		this.globalCurrency = getUserCurrency(this.currentUser.currency);
		try {
			if(!isNaN(this.vm_synth.cost)) {
				var y: number = +(this.vm_synth.cost).toFixed();
				this.vm_synth.cost = commaBigNumber(y);
			}
		} catch(e) {
			console.log('fail to cast cost');
		}

		// SHARE THE VmSynthesis OBJECT
		this.message.vmSynth = this.vm_synth;

		// SET INFRA
		this.infra_svc.getElementInfra(this.message.currentUuid, 'VM').pipe(first()).subscribe(
			data => {
				this.infra.cluster = data.cluster;
				this.infra.datacenter = data.datacenter;
			}, error => {
				console.log('no location data ' + this.message.currentUuid);
			}
		);

		// SET COMMENT
		this.settings_svc.getUserComment('root', this.message.currentUuid).pipe(first()).subscribe(
			data => {
				this.comment.ID = data.ID;
				this.comment.USER = data.USER;
				this.comment.COMMENT = data.COMMENT;
				this.comment.ENV = data.ENV;
				this.comment.OWNER = data.OWNER;
				this.comment.END_DATE = data.END_DATE;
				setTimeout(() => this.applyCssSchema(), 100);
			}, error => {
				console.log('no comment data for ' + this.message.currentUuid);
    			this.comment.COMMENT = '';
				this.comment.OWNER = '';
				this.comment.ENV = '';
				this.comment.END_DATE = 'not registered';
				
				// No data, getMaxIdComment
				this.settings_svc.getMaxIdComment().pipe(first()).subscribe(
					data => {
						var y: number = Number(data.id);
						this.comment.ID = y;
					},
					error => {
						if(error != null)
							console.log(error)
					}
				);

				setTimeout(() => this.applyCssSchema(), 100);
			}
		);

		// SET CO2
		this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_POWER).subscribe(
			data => {
				this.datas_green = data;
				let co2_el: any = this.getPowerElement();
				if(this.message.currentUuid != "" && co2_el != undefined) {
					let direct: number = +co2_el.CO2;
					let grey: number = +co2_el.CO2G;
					this.vm_synth.co2 = direct+grey;
				}
			}
		);
	}
  }

  private applyCssSchema(): void {

	$('#csstoadjust').parent().find('clr-date-container').removeClass('clr-form-control');
	if(this.comment.END_DATE != "not registered" && this.comment.END_DATE !== null)
		$('#vm-synth-input-end-date').addClass('applyBlack');
  }

  private setAlertStates(): void {

	// TROUBLESHOOTING STATE
	if(this.vm_synth.tsstate == "green") {
		this.trouble_state = 'success';
  		this.trouble_main_state = 'normal';
		setTimeout(() => $('#vm-technical-alert-ts-text').html('troubleshooting: normal'), 100);
	} else if(this.vm_synth.tsstate == "orange") {
		this.isNoTsAlert = false;
		this.trouble_state = 'warning';
  		this.trouble_main_state = 'warning';
		setTimeout(() => $('#vm-technical-alert-ts-text').html('troubleshooting: warning'), 100);
	} else if(this.vm_synth.tsstate == "red") {
		this.isNoTsAlert = false;
		this.trouble_state = 'danger';
  		this.trouble_main_state = 'critical';
		setTimeout(() => $('#vm-technical-alert-ts-text').html('troubleshooting: critical'), 100);
	}

	// BEHAVIOR STATE
	if(this.vm_synth.behavior == "idle" || this.vm_synth.behavior == "lazy" 
		|| this.vm_synth.behavior == "undersized" || this.vm_synth.behavior == "busy") {
		this.isNoBehAlert = false;
		this.behavior_state = 'danger';
		setTimeout(() => $('#vm-technical-alert-beh-text').html('behavior: ' + this.vm_synth.behavior), 100);
	} else if(this.vm_synth.behavior == "oversized") {
		this.isNoBehAlert = false;
		this.behavior_state = 'warning';
		setTimeout(() => $('#vm-technical-alert-beh-text').html('behavior: ' + this.vm_synth.behavior), 100);
	}
  }

  private getHostData(name: string): HostSynthesis {
	return this.data_hosts.find(host => host.name === name);
  }

  private getClusterData(name: string): ClusterSynthesis {
	return this.data_clusters.find(cluster => cluster.name === name);
  }

  private callUpdateComment(item: string, value: string): void {

	this.settings_svc.updateComment(this.comment.ID, this.message.currentUuid, item, value).subscribe(
		error => {
			if(error != null)
				console.log(error)
		}
	);
  }

  private loadTroubleshooting(): void {

	if(this.addTroubleshooting != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TroubleshootingcardComponent);
		const viewContainerRef = this.addTroubleshooting.viewContainerRef;
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadVmConsOver(): void {

	if(this.addVmConsover2 != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsoverComponent);
		const viewContainerRef = this.addVmConsover2.viewContainerRef;
	  	viewContainerRef.clear();
		componentRef3 = viewContainerRef.createComponent(componentFactory);
	}
  }

  private reloadVmConsOver(): void {

	this.message.isReloadConsover = false;
	$( "div.dataTables_filter" ).remove();
	if(componentRef3 != undefined)
		componentRef3.destroy();

	setTimeout(() => this.loadVmConsOver(), 100);
  }

  /*private loadWeeklyCal(): void {

	if(this.addWeeklyCal != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WeeklycalComponent);
		const viewContainerRef = this.addWeeklyCal.viewContainerRef;
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadMonthlyCal(): void {

	if(this.addMonthlyCal != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MonthlycalComponent);
		const viewContainerRef = this.addMonthlyCal.viewContainerRef;
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadYearlyCal(): void {

	if(this.addYearlyCal != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(YearlycalComponent);
		const viewContainerRef = this.addYearlyCal.viewContainerRef;
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }*/

  private loaddConsCal(): void {

    if(this.addConsCal != undefined) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConscalendarComponent);
      const viewContainerRef = this.addConsCal.viewContainerRef;
      const componentRef = viewContainerRef.createComponent(componentFactory);
    }
  }

  private loadYearlyBehavior(): void {

	if(this.addBehaviorCal != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BehaviorcalComponent);
		const viewContainerRef = this.addBehaviorCal.viewContainerRef;
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadLcy(): void {

	if(this.addLcy != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LcyComponent);
		const viewContainerRef = this.addLcy.viewContainerRef;
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private getVmData(uuid: string): VmSynthesis {
	return this.data_vms.find(vm => vm.uuid === uuid);
  }

  private buildRadar(): void {

    this.vm_avg = this.getVmData('vm_average_uuid');

    this.chartOptions = {
        chart: {
		polar: true,
		type: 'line',
        	marginTop: 0,
        	marginBottom: 0,
        	plotBorderWidth: 0,
            	height: '60%'
	},
    	credits: {
        	enabled: false
	},
        exporting: {
            enabled: false
	},
	title: {
		text: '',
		x: -100
	},
	pane: {
		size: '75%'
	},
	xAxis: {
		categories: ['TROUBLESHOOTING', 'CPU ACTIVITY', 'CPU CAPACITY', 'STORAGE CAPACITY', 'RAM CAPACITY', 'RAM ACTIVITY'],
		tickmarkPlacement: 'on',
		lineWidth: 0
	},
	yAxis: {
		gridLineInterpolation: 'polygon',
		lineWidth: 0,
		min: 0
	},
	tooltip: {
		shared: true,
		pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}%</b><br/>'
	},
	legend: {
		align: 'center',
		verticalAlign: 'bottom',
		y: 20,
		layout: 'horizontal'
	},
	series: [
		{
			type: 'line',
			name: 'Average VM',
			data: [
				this.vm_avg.tscons,
				this.vm_avg.cpucons,
				Math.round(Number(this.vm_avg.sizingcpu)),
				this.vm_avg.stocap,
				Math.round(Number(this.vm_avg.sizingram)),
				this.vm_avg.ramcons
			],
			pointPlacement: 'on'
		},
		{
			type: 'line',
			name: this.vm_synth.name,
			data: [
				this.vm_synth.tscons,
				this.vm_synth.cpucons,
				Math.round(Number(this.vm_synth.sizingcpu)),
				this.vm_synth.stocap,
				Math.round(Number(this.vm_synth.sizingram)),
				this.vm_synth.ramcons
			],
			pointPlacement: 'on'
                }
	]
    };
    this.isReady = true;
  }

  private getPowerElement(): any {

	for(let i in this.datas_green) {
		for(let j in this.datas_green[i].VMS) {
			if(this.datas_green[i].VMS[j].IDENTIFIER === this.message.currentUuid) {
				return this.datas_green[i].VMS[j];
			}
		}
	}
  }
}

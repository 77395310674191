import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { first } from 'rxjs/operators';

import { ManagementService, ShareService } from '@app/services';

import { Message } from '@app/model';

import { ConscalendarComponent } from '@app/conscalendar/conscalendar.component';
import { ConscalendarDirective } from '@app/directives/conscalendar.directive';

var componentRef: ComponentRef<any>;


@Component({
  selector: 'app-elementsynthesis',
  templateUrl: './elementsynthesis.component.html',
  styleUrls: ['./elementsynthesis.component.css']
})
export class ElementsynthesisComponent implements AfterViewChecked, AfterViewInit, OnInit {

  @ViewChild(ConscalendarDirective, {static: false}) addConsCal: ConscalendarDirective;

  message: Message;


  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private mgt_svc: ManagementService,
    private message_svc: ShareService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);

    this.mgt_svc.getRegions('aws').pipe(first()).subscribe(
			data => {				
        	if(data.length > 0)
					  this.message.isValidAws = true;
      }
    );
    this.mgt_svc.getRegions('azure').pipe(first()).subscribe(
			data => {				
        	if(data.length > 0)
					  this.message.isValidAzure = true;
      }
    );
  }

  ngAfterViewInit(): void {

    this.loaddConsCal();
  }

  ngAfterViewChecked(): void {

    this.cd.detectChanges();
  }

  setVmware(): void {
    
    if (componentRef != undefined)
			componentRef.destroy();

    this.message.powerUsageEnv = 'vmware';
    setTimeout(() => { this.loaddConsCal(); }, 100);
  }

  setAws(): void {

    if (componentRef != undefined)
			componentRef.destroy();

    this.message.powerUsageEnv = 'aws';
    setTimeout(() => { this.loaddConsCal(); }, 100);
  }

  setAzure(): void {

    if (componentRef != undefined)
			componentRef.destroy();

    this.message.powerUsageEnv = 'azure';
    setTimeout(() => { this.loaddConsCal(); }, 100);
  }

  loadCloud(): void {

    if (componentRef != undefined)
			componentRef.destroy();

    setTimeout(() => { this.loaddConsCal(); }, 100);
  }

  private loaddConsCal(): void {

    this.message.calcons_counter = 'power_float';
    if(this.addConsCal != undefined) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConscalendarComponent);
      const viewContainerRef = this.addConsCal.viewContainerRef;
      componentRef = viewContainerRef.createComponent(componentFactory);
    }
  }
}

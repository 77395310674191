import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { InfrastructureService, JsonloaderService, MonitorService } from '@app/services';

import { VmSnapshot, VmSynthesis } from '@app/model';

import { ClrDatagridSortOrder } from '@clr/angular';


@Component({
  selector: 'app-vmsnapshot',
  templateUrl: './vmsnapshot.component.html',
  styleUrls: ['./vmsnapshot.component.css']
})
export class VmsnapshotComponent implements OnInit {

  dataOrder = ClrDatagridSortOrder.DESC;

  data_vms: VmSynthesis[] = [];

  instances_list: any;

  snapshots_list: any;

  vm_snapshot: VmSnapshot[] = [];


  constructor(
    private infra_svc: InfrastructureService,
    private json_svc: JsonloaderService,
    private monitor_svc: MonitorService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.data_vms = this.json_svc.json.vmSynthesis;

    this.monitor_svc.getMonitorList().subscribe(
      data => {
        this.instances_list = data;
        this.infra_svc.getSnapshots().pipe(first()).subscribe(
          data2 => {
            this.snapshots_list = data2;
            this.buildVmSnapshot();
          }, error => {
            console.log('no snapshot data');
          }
	  		);
      },
      error => {
        console.log("no instance ...");
      }
    );
  }

  callUrl(vm: any): void {

    let vBaseUrl = vm.url;
    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = vBaseUrl;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  exportSnapshots(): void {

    let res = ["Virtual machine", "Snapshot number", "Snapshot name", "Status", "Size (MB)"].join(',') + '\n';
                res += Object.values<any>(this.vm_snapshot).map(vm =>
                        [vm.name, vm.snapshots, vm.snaphotName, vm.status, vm.size.toFixed(2)].join(",")
                ).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'overview_instances.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  private buildVmSnapshot(): void {

    for (var i in this.data_vms) {
      let vmware_id: string = '';
      let vmware_uuid: string = '';
      let url: string = '';
      let size: number = 0;
      let name: string = '';
      let nb: number = 0;
      let status: string = '';

      let uuid_tmp : any;
      uuid_tmp = this.data_vms[i].uuid.split('-');
      if(uuid_tmp.length > 6)
        vmware_id = uuid_tmp[5] + '-' + uuid_tmp[6];

      for (var j in this.instances_list) {
        if(this.data_vms[i].uuid.includes(this.instances_list[j].instanceid)) {
          vmware_uuid = this.instances_list[j].instanceid;
          url = 'https://' 
                + this.instances_list[j].ip 
                + '/ui/app/vm;nav=h/urn:vmomi:VirtualMachine:' 
                + vmware_id
                + ':'
                + vmware_uuid
                + '/snapshots';
          break;
        }
      }

		  if(this.data_vms[i].name != "vm_average") {
        for(var j in this.snapshots_list) {
          if(this.snapshots_list[j].FATHER_ID == this.data_vms[i].uuid) {
            size = this.snapshots_list[j].SIZE/1048576;
            name = this.snapshots_list[j].NAME;
            nb = this.snapshots_list[j].SNAPSHOT_NB;
            status = this.snapshots_list[j].STATUS.toString().toLowerCase();
            break;
          }
        }

        if(nb > 0) {
          let vm_snap: VmSnapshot = {
            uuid: this.data_vms[i].uuid,
            name: this.data_vms[i].name,
            snaphotName: name,
            snapshots: nb,
            status: status,
            size: size,
            url: url
          };
          this.vm_snapshot.push(vm_snap);
        }
      }
    }
  }
}

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import { JSONTarget, Message, User } from '@app/model';

import { commaBigNumber, getUserCurrency } from '../../../assets/js/tools.js';


@Component({
  selector: 'app-t6detail',
  templateUrl: './t6detail.component.html',
  styleUrls: ['./t6detail.component.css']
})
export class T6detailComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject();

  message: Message;

  data_cost: any[] = [];

  globalCurrency: string = '';

  private currentUser: User;


  constructor(
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService) { 
  }

  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();
	$( "dt-buttons" ).remove();

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.globalCurrency = getUserCurrency(this.currentUser.currency);

	this.initDtOptions();

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	switch(this.message.t6Detail) {
	  	case "allocated":
	  		this.data_cost = this.json_svc.json.costAllocated;
			if(this.data_cost.length > 0)
	  			this.reloadTable();
	  		break;
	  	case "consumed":
	  		this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.COST_CONSUMED).subscribe(
	  			data => {
					this.data_cost = data;
					if(this.data_cost.length > 0)
	  					this.reloadTable();
      				}
	  		);
	  		break;
		case "wasted":
	  		this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.COST_WASTED).subscribe(
	  			data => {
					this.data_cost = data;
					if(this.data_cost.length > 0)
	  					this.reloadTable();
      				}
	  		);
	  		break;
		default:
	  		break;
	}
  }

  ngAfterViewInit(): void {
	this.dtTrigger.next();
  }

  ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
  }

  formatCost(cost: number): number {
	return commaBigNumber(cost);
  }

  private reloadTable(): void {

	  if(this.dtElement != undefined) {
	  	if(this.dtElement.dtInstance != undefined) {
			this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				$( "div.dataTables_filter" ).remove();
	  			$( "dt-buttons" ).remove();
                		dtInstance.destroy();
                		this.dtTrigger.next();
          		});
	  	}
	  }
  }

  private initDtOptions(): void {

	this.dtOptions = {
		pagingType: 'full_numbers',
		pageLength: 10,
		lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
		processing: true,
	  	destroy: true,
		initComplete: function() {
	  		$('div.dataTables_filter').appendTo('span.search');
	  		$('div.dt-buttons').appendTo('span.export');
	  		$("[id*='DataTables_Table_']").css({'padding-top':'5px'});
	  		$("[name*='DataTables_Table_']").css({'padding':'0px'});
	  	},
	  	language: {
			zeroRecords: "no data",
	  		search: '',
	  		searchPlaceholder: " search..."
	  	},
	  	dom: '<"top"B><"clear">frtlip',
	  	buttons: [
	  		{ extend: 'csv', className: 'btn btn-link btn-sm' },
	  		{ extend: 'copy', className: 'btn btn-link btn-sm' },
	  		{ extend: 'print', className: 'btn btn-link btn-sm' }
	  	]
	};
  }
}


<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<clr-icon shape="table"></clr-icon>
					&nbsp;Server consumption table
				</h5>
				<span class="export"></span>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search"></span>
			</div>
			<div class="card-block">
				<ng-template load-host-consover></ng-template>
			</div>
		</div>
	</div>
</div>
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

import { AccountService, GreenitService, JsonloaderService, SettingsService, ShareService } from '@app/services';

import { IhmSettings, IhmSettingsTarget } from '@app/model/management';
import { GreenitTarget, Settings } from './greenit.enums';

import { User, Message, JSONTarget } from '@app/model';

import * as Highcharts from 'highcharts';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';

import { getUserCurrency } from '../../assets/js/tools';

import { Subscription } from 'rxjs';

import { ClrDatagridSortOrder } from '@clr/angular';


@Component({
  selector: 'app-greenit',
  templateUrl: './greenit.component.html',
  styleUrls: ['./greenit.component.css'],
})
export class GreenitComponent implements OnInit {

  @ViewChild('currentyears') simu_currentyears: ElementRef;
  @ViewChild('currentcost') simu_currentcost: ElementRef;
  @ViewChild('simucost') simu_simucost: ElementRef;
  @ViewChild('currentco2') simu_currentco2: ElementRef;
  @ViewChild('simuco2') simu_simuco2: ElementRef;
  @ViewChild('currentnbvm') simu_currentnbvm: ElementRef;
  @ViewChild('simunbvm') simu_simunbvm: ElementRef;
  @ViewChild('currentvmtee') simu_currentvmtee: ElementRef;
  @ViewChild('simuvmtee') simu_simuvmtee: ElementRef;
  @ViewChild('currentvmcost') simu_currentvmcost: ElementRef;
  @ViewChild('simuvmcost') simu_simuvmcost: ElementRef;

  dataOrder = ClrDatagridSortOrder.ASC;
  dataOrderDesc = ClrDatagridSortOrder.DESC;

  math = Math;
  number = Number;

  message: Message;

  currentUser: User;
  globalCurrency: string;

  shared_user: string;

  // LIST OF SETTINGS FOR SCORE
  private greenit_settings = Settings;

  // FOR T1
  greenit_score: Number;
  greenit_alert: Boolean;
  greenit_alert_message: string;
  greenit_alert_icon: string;

  Highcharts: typeof Highcharts = Highcharts;
  score_chart: Highcharts.Chart;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.score_chart = chart;
    //XXX force refresh T1
    if (this.greenit_score != undefined)
      this.displayMaturityChart();
  };

  options: Highcharts.Options = {
    credits: { enabled: false },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'gauge',
      //type: 'solidgauge',
      height: '200px',
      backgroundColor: 'rgba(255, 255, 255, 0.0)',
      margin: [0, 0, 0, 0]
    },
    exporting: {
      enabled: false
    },
    time: {
      useUTC: false
    },
    title: {
      text: ''
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      min: 0,
      max: 100,

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 2,
        //rotation: 'auto'
      },
      title: {
        //text: 'Your score',
        style: {
          fontSize: '22px'
        }
      },
      plotBands: [{
        from: 0,
        to: 33,
        color: '#DF5353' // red
      }, {
        from: 33,
        to: 66,
        color: '#DDDF0D' // yellow
      }, {
        from: 66,
        to: 100,
        color: '#55BF3B', // green 
      }]
    },
    tooltip: {
      enabled: false
    },
    pane: {
      size: 250,
      center: ['50%', '68%'],
      startAngle: -90,
      endAngle: 90,
      background: null
    },
  };

  // FOR QUANTIS MODAL
  quantis_modal: boolean;

  // FOR EE MODAL
  efficiency_modal: boolean;
  efficiency_data: any = [];

  // FOR OPTIMIZATION MODAL
  optimization_json: any;
  optimization_modal: boolean;
  annual_kwh: number;
  annual_cost: number;
  annual_co2: number;
  optimization_kwh: number;
  optimization_cost: number;
  optimization_co2: number;
  optimization_current_servers: number;
  optimization_new_servers: number;

  // FOR SIMULATION
  simulation_modal: boolean;
  simulation_data: any;
  simulation_amortization: number;
  simulation_newServerCost: number;
  simulation_newServerkWh: number;
  simulation_newServerRam: number; // GB
  simulation_newServerThread: number;
  simulation_newServerSocket: number;
  simulation_currentServerCost: number;

  // CAPA SETTINGS
  capa_settings: IhmSettings;
  capa_template_avg_vcpu: number;
  capa_template_avg_vram: number;

  // FOR SETTINGS
  settings_subscription: Subscription;


  /**
   * 
   */
  constructor(private authenticationService: AccountService, private greenit_svc: GreenitService,
    private json_svc: JsonloaderService, private message_svc: ShareService, library: FaIconLibrary,
    private settings_svc: SettingsService) {

    library.addIcons(faLeaf);

    this.greenit_alert = true;

    // FOR QUANTIS
    this.quantis_modal = false;

    // FOR EE
    this.efficiency_modal = false;

    // FOR OPTIMIZATION
    this.optimization_modal = false;

    // FOR SIMULATION
    this.simulation_modal = false;
    
    // Capa default values
    this.capa_template_avg_vcpu = 4;
    this.capa_template_avg_vram = 4096;

  }


  /**
   * 
   */
  ngOnInit(): void {
    this.authenticationService.user.subscribe(user => this.currentUser = user);
    this.message_svc.currentMessage.subscribe(message => this.message = message);

    this.globalCurrency = getUserCurrency(this.currentUser.currency);

    //XXX Check shared filter
    this.shared_user = this.currentUser.login;
    if(this.message.isShared) {
      this.shared_user = "root";
    }

    let force_reload_capa = this.message.isShared;

    // GET CAPA SETTINGS (FOR SIMULATION)
    // Reload capa settings
    this.settings_svc.reload(this.shared_user, this.message.currentFilter, IhmSettingsTarget.CAPACITY, force_reload_capa);

    // Update settings
    this.settings_svc.currentCapa.subscribe(capaSettings => {
      this.capa_settings = capaSettings;

      // Get avg VM data
      this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.CAPA_VM).subscribe(
        data => {
          let vm_data = data;
          if(vm_data != null) {
            for(let vm of vm_data) {
              if(vm.ID == '--new-vm--') {
                this.capa_template_avg_vcpu = vm.CPUTHREADNB;
                this.capa_template_avg_vram = vm.RAMCAP;
                break;
              }
            }
          }
        }
      );
    });

    // GET SETTINGS & BUILD SCORE
    this.greenit_svc.getGreenitSettings().subscribe(
      data => {
        // SUBSCRIPTION SETTINGS (REBUILD T1 IF SETTINGS CHANGED)
        setTimeout(() => {
          this.settings_subscription = this.greenit_svc.currentSettings.subscribe(
            data => {
              this.buildT1(data);
            }
          );
        },100);

      },
      error => {
        this.greenit_score = 0;

        this.greenit_alert = true;

        // Display gauge
        this.displayMaturityChart();
      }
    );

  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    // Remove subscription
    if(this.settings_subscription != undefined)
      this.settings_subscription.unsubscribe();
    //console.log('destroyed');
  }

  /**
   * 
   */
  buildT1(data: any): void {
    let settings_nb = 0;

    // Init alert & settings number
    this.greenit_settings.forEach(function (item, index) {
      if (data.filter((e: { option: string; }) => e.option === item).length != 0) {
        settings_nb++;
      }
    }, this);

    // Compute score from settings
    this.greenit_score = Math.floor(Math.floor(settings_nb * Math.floor(100)) / this.greenit_settings.length);

    if (this.greenit_score >= 100) {
      this.greenit_score = 100;
      this.greenit_alert = false;
    }

    // Display gauge
    this.displayMaturityChart();
  }

  /**
   * 
   */
  displayGlobal(): void { }

  /**
   * 
   */
  displayDC(): void {

    // FOR OPTIMIZATION
    this.annual_kwh = 0;
    this.annual_cost = 0;
    this.annual_co2 = 0;
    this.optimization_kwh = 0;
    this.optimization_cost = 0;
    this.optimization_co2 = 0;
    this.optimization_current_servers = 0;
    this.optimization_new_servers = 0;

    this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_SERVERS).subscribe(
      data => {
        this.optimization_json = data;
        if (this.optimization_json != null) {
          this.annual_kwh = Number(Number(this.optimization_json.OLDKWH).toFixed(0));
          this.annual_co2 = Number(Number(this.optimization_json.OLDCO2).toFixed(0));
          this.annual_cost = Number(Number(this.optimization_json.OLDCOST).toFixed(0));
          this.optimization_kwh = Number(Number(this.optimization_json.KWH).toFixed(0));
          this.optimization_co2 = Number(Number(this.optimization_json.CO2).toFixed(0));
          this.optimization_cost = Number(Number(this.optimization_json.COST).toFixed(0));
        }
      }
    );

    // FOR CEE
    this.greenit_svc.getGreenitData(this.shared_user, this.message.currentFilter, GreenitTarget.HOST_CEE).subscribe(
      data => {
        if(data != undefined) {
          this.efficiency_data = data;
          // Keep only RUNNING servers
          this.efficiency_data = this.efficiency_data.filter((e: { state: string; }) => e.state == "RUNNING");
        }
      },
      error => {
        if (error != null)
          console.log(error);
      }
    );

  }

  /**
   * 
   */
  displayDesktop(): void { }

  /**
   * Display DC EE modal
   */
  displayModalEfficiency(): void {
    this.efficiency_modal = true;
  }

  /**
   * Display DC simulation modal
   */
  displayModalSimulation(data: any): void {
    this.simulation_modal = true;
    this.simulation_data = data;

    //XXX default values
    this.simulation_amortization = this.simulation_data.amortization;
    this.simulation_newServerCost = 5000;
    this.simulation_newServerkWh = 2300;
    this.simulation_newServerRam = 128; // GB
    this.simulation_newServerThread = 32;
    this.simulation_newServerSocket = 1;
    this.simulation_currentServerCost = 0;


    // Update values
    setTimeout(() => {
      this.updateSimulation();
    });

  }

  /**
   * 
   */
  updateSimulation(): void {

    // Get settings
    let cost = this.simulation_data.cost;
    let pue = this.simulation_data.pue;
    let coeffco2 = this.simulation_data.coeffco2;
    let ramoc = Number(this.capa_settings.p1);
    let vcpuoc = Number(this.capa_settings.p2);
    let ramavg = this.simulation_data.ramavg;
    let vcpuavg = this.simulation_data.vcpuavg;

    // Get current server values
    let currentthvm = Number(this.simulation_data.thvm);
    let currenttee = Number(this.simulation_data.tee);
    let currentram = Number(this.simulation_data.ramcap);
    let currentkwh = Number(this.simulation_data.wh / 1000); // show kWh without pue
    let currentco2 = Number(this.simulation_data.co2);
    let currentco2G = Number(this.simulation_data.co2G);

    // Update current server cost / vm cost
    let currenttotalco2 = (currentco2 + currentco2G); //XXX FIX co2G ???
    let currentkWhCost = (this.simulation_amortization * currentkwh * cost);
    let currentCost = (this.simulation_currentServerCost + currentkWhCost);
    let currentvmcost = (((currentkWhCost / this.simulation_amortization) + (this.simulation_currentServerCost / this.simulation_amortization)) / currentthvm);

    let currentCost_element: Element = this.simu_currentcost.nativeElement;
    currentCost_element.innerHTML = '<b>' + currentCost.toFixed(2) + '</b>';

    let currentYears_element: Element = this.simu_currentyears.nativeElement;
    currentYears_element.innerHTML = '<b>' + this.simulation_amortization + '&nbsp;' + '</b>';

    // Update new server cost / thvm / tee / vm cost
    let newco2 = Number(this.simulation_newServerkWh * pue * coeffco2);
    // From Quantis LR
    let newco2G = Number(Number(1064 + Number(this.simulation_newServerSocket * 136) + Number(this.simulation_newServerRam * 2)) / this.simulation_amortization);
    if (this.simulation_newServerSocket == 0 && this.simulation_newServerRam == 0) {
      newco2G = 0;
    }
    let newtotalco2 = (newco2 + newco2G);
    let newkWhCost = (this.simulation_amortization * this.simulation_newServerkWh * pue * cost);
    let newCost = (this.simulation_newServerCost + newkWhCost);
    let newthvmR = ((this.simulation_newServerRam * 1024 * ramoc) / 100) / ramavg;
    let newthvmV = Number(this.simulation_newServerThread * vcpuoc) / vcpuavg;
    let newthvm = Math.min(newthvmR, newthvmV);
    let newPower = Number(this.simulation_newServerkWh * 1000 / 365 / 24);
    let newtee = (newPower / newthvm);
    let newvmcost = (((newkWhCost / this.simulation_amortization) + (this.simulation_newServerCost / this.simulation_amortization)) / newthvm);
    if (newthvm == 0) {
      newtee = 0;
      newvmcost = 0;
    }

    let simuCost_element: Element = this.simu_simucost.nativeElement;
    simuCost_element.innerHTML = '<b>' + newCost.toFixed(2) + '</b>';

    let currentCo2_element: Element = this.simu_currentco2.nativeElement;
    let simuCo2_element: Element = this.simu_simuco2.nativeElement;

    if (currenttotalco2 <= newtotalco2) {
      currentCo2_element.innerHTML = '<b style="color:green">' + currenttotalco2.toFixed(2) + '&nbsp;' + '</b>';
      simuCo2_element.innerHTML = '<b style="color:red">' + newtotalco2.toFixed(2) + '&nbsp;' + '</b>';
    } else {
      currentCo2_element.innerHTML = '<b style="color:red">' + currenttotalco2.toFixed(2) + '&nbsp;' + '</b>';
      simuCo2_element.innerHTML = '<b style="color:green">' + newtotalco2.toFixed(2) + '&nbsp;' + '</b>';
    }

    let currentnbvm_element: Element = this.simu_currentnbvm.nativeElement;
    let simunbvm_element: Element = this.simu_simunbvm.nativeElement;

    if (currentthvm >= newthvm) {
      currentnbvm_element.innerHTML = '<b style="color:green">' + currentthvm.toFixed(2) + '&nbsp;' + '</b>';
      simunbvm_element.innerHTML = '<b style="color:red">' + newthvm.toFixed(2) + '&nbsp;' + '</b>';
    } else {
      currentnbvm_element.innerHTML = '<b style="color:red">' + currentthvm.toFixed(2) + '&nbsp;' + '</b>';
      simunbvm_element.innerHTML = '<b style="color:green">' + newthvm.toFixed(2) + '&nbsp;' + '</b>';
    }

    let currentvmtee_element: Element = this.simu_currentvmtee.nativeElement;
    let simuvmtee_element: Element = this.simu_simuvmtee.nativeElement;

    if (currenttee <= newtee) {
      currentvmtee_element.innerHTML = '<b style="color:green">' + currenttee.toFixed(2) + '&nbsp;' + '</b>';
      simuvmtee_element.innerHTML = '<b style="color:red">' + newtee.toFixed(2) + '&nbsp;' + '</b>';
    } else {
      currentvmtee_element.innerHTML = '<b style="color:red">' + currenttee.toFixed(2) + '&nbsp;' + '</b>';
      simuvmtee_element.innerHTML = '<b style="color:green">' + newtee.toFixed(2) + '&nbsp;' + '</b>';
    }

    let currentvmcost_element: Element = this.simu_currentvmcost.nativeElement;
    let simuvmcost_element: Element = this.simu_simuvmcost.nativeElement;

    if (currentvmcost < newvmcost) {
      currentvmcost_element.innerHTML = '<b style="color:green">' + currentvmcost.toFixed(2) + '</b>';
      simuvmcost_element.innerHTML = '<b style="color:red">' + newvmcost.toFixed(2) + '</b>';
    } else {
      currentvmcost_element.innerHTML = '<b style="color:red">' + currentvmcost.toFixed(2) + '</b>';
      simuvmcost_element.innerHTML = '<b style="color:green">' + newvmcost.toFixed(2) + '</b>';
    }

  }

  /**
  * Display DC optimization modal
  */
  displayModalOptimization(): void {
    this.optimization_modal = true;

    setTimeout(() => {
      if (this.optimization_json != null) {

        let current_kwh = Number(this.optimization_json.OLDKWH).toFixed(0);
        let new_kwh = Number(this.optimization_json.KWH).toFixed(0);

        if (Number(current_kwh) > Number(new_kwh)) {
          document.getElementById('greenit-prediction-current').innerHTML = '<b style="color:red">' + Intl.NumberFormat().format(Number(current_kwh)) + '</b>';
          document.getElementById('greenit-prediction-new').innerHTML = '<b style="color:green">' + Intl.NumberFormat().format(Number(new_kwh)) + '</b>';
        } else {
          document.getElementById('greenit-prediction-current').innerHTML = '<b style="color:green">' + Intl.NumberFormat().format(Number(current_kwh)) + '</b>';
          document.getElementById('greenit-prediction-new').innerHTML = '<b style="color:red">' + Intl.NumberFormat().format(Number(new_kwh)) + '</b>';
        }

        this.optimization_current_servers = this.optimization_json.CURRENT_SERVERS_NB;
        this.optimization_new_servers = this.optimization_json.OPTIMIZE_SERVERS_NB;

      }
    });
  }

  /**
   * Close modal
   */
  closeModal(): void {
    this.optimization_modal = false;
    this.efficiency_modal = false;
    this.quantis_modal = false;
    this.simulation_modal = false;
  }

  /**
   * Build the Highcharts gauge
   */
  private displayMaturityChart(): any {

    if (this.score_chart.series[0] != undefined)
      this.score_chart.series[0].remove(true);

    let scorecolor = '#DDDF0D';

    if (this.greenit_score <= 33) {
      scorecolor = '#DF5353';
    } else if (this.greenit_score > 66) {
      scorecolor = "#55BF3B";
    }

    const data = [this.greenit_score];

    // Push to chart
    this.score_chart.addSeries({
      name: '',
      type: undefined,  // FIX for angular ...
      data: data,
      tooltip: {
        valueSuffix: ''
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '22px',
          color: scorecolor
        }
      }
    });

  }

  /**
   * 
   */
  formatNumber(value: number): string {
    return Intl.NumberFormat().format(value);
  }

  /**
   * 
   */
  exportEfficiency() {
    let res = ["NAME", "CEE", "TEE", "ANNUAL kWh", "ANNUAL COST", "ANNUAL CO2 (kg CO2eq)", "POWER (W)"].join(',') + '\n';
		res += Object.values<any>(this.efficiency_data).map(data =>
			[data.name, data.cee, Number(data.tee).toFixed(2), Number(data.wh/1000).toFixed(2), (Number(data.cost * data.wh) / 1000).toFixed(2) , Number(data.co2).toFixed(2), Number(data.power).toFixed(2)].join(",")
		).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'greenit_efficiency.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  /**
   * 
   */
  exportOptimization() {
    let res = ["NEW", "NEW SERVER OR ACTUAL", "CURRENT ANNUAL KWH", "NEW ANNUAL KWH (ESTIMATION)", "LIST OF REPLACED SERVERS"].join(',') + '\n';
		res += Object.values<any>(this.optimization_json.SERVERS).map(data =>
			[data.NEWFLAG, data.NAME, Number(data.OLDKWH).toFixed(0), Number(data.KWH).toFixed(0), data.OLDSERVERS.split(',').join(' - ')].join(",")
		).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'greenit_optimization.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

}

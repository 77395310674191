import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-costdetail-server]'
})
export class CostserverdetailDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import {Observable} from "rxjs";
import {Component, EventEmitter, OnInit} from '@angular/core';
import {ClrDatagridComparatorInterface} from "@clr/angular";
import {ActivatedRoute} from "@angular/router";
import * as Highcharts from "highcharts/highstock";
import {Chart, SeriesOptionsType} from "highcharts";
import {GraphOnDemandService, JsonloaderService, SettingsService, ShareService} from "@app/services";
import {FetchResult, MetricItem, StorageOverviewService} from "@app/services/storage-overview.service";
import {RangeFilterComponent} from "@app/storage-overview/range-filter/range-filter.component";

class TotalComparator implements ClrDatagridComparatorInterface<any> {
	compare(a: any, b: any) {
		return (a.computeCost + a.storageCost) - (b.computeCost + b.storageCost);
	}
}

@Component({
	selector: 'app-storage-overview',
	templateUrl: './storage-overview.component.html',
	styleUrls: ['./storage-overview.component.css']
})
export class StorageOverviewComponent implements OnInit {

	Highcharts: typeof Highcharts = Highcharts;
	selectedDatastore: any;
	selectedVms = [];
	listedDatastores = [];
	listedVms = [];

	storageChartOptions: Highcharts.Options = this.generateDefaultHighchartsOptions("Number of Volumes");
	chart: Chart;
	updateStorageChartFlag = true;
	storageChartReady = false;
	onReady: EventEmitter<any> = new EventEmitter();

	firstTableShouldChange = new EventEmitter();
	secondTableShouldChange = new EventEmitter();

	options: any;
	vmsAreLoading: boolean = false;
	graphsAreLoading: boolean = false;
	totalComparator: any = new TotalComparator();

	startTime = 0
	endTime = 2553270400000;

	minRange = this.startTime;
	maxRange = this.endTime;

	availableMetrics: MetricItem[] = [];
	selectedMetricName: string;

	displayGraph = true
	noDataMessage = ""

	constructor(public jsonLoaderService: JsonloaderService, private settingsService: SettingsService, private shareService: ShareService, public route: ActivatedRoute, public storageOverviewService: StorageOverviewService, public graphOnDemandService: GraphOnDemandService) {
		this.jsonLoaderService.currentJson.subscribe((json) => {
			this.listedDatastores = json.storageOverviewsDatastoreData;

			this.shareService.currentMessage.subscribe((msg) => {
				// Change default time interval if specified in filter
				if (msg.minTimeFilter != 0) {
					this.startTime = msg.minTimeFilter;
					this.minRange = msg.minTimeFilter;
				}
				if (msg.maxTimeFilter != 0) {
					this.endTime = msg.maxTimeFilter;
					this.maxRange = msg.maxTimeFilter;
				}
			});

			setTimeout(() => {
				this.firstTableShouldChange.emit(this.listedDatastores);
			}, 100)
		});
	}

	ngOnInit(): void {
		this.availableMetrics = this.storageOverviewService.getMetrics();
		this.selectedMetricName = "lat_max";
	}

	getSelectedMetric(): MetricItem {
		let [result] = this.availableMetrics
			.filter((m) => m.id == this.selectedMetricName)
		return result;
	}

	/**
	 * This method export the data table into a CSV file
	 */
	exportDatastoreCSV() {
		let csvContent = ["NAME", "CAPACITY (Go)", "USAGE (%)", "FREE (Go)", "PROVISIONED (Go)", "IOPS (avg)",
			"IOPS (max)", "IOPS READ (avg)", "IOPS READ (max)", "IOPS WRITE (avg)", "IOPS WRITE (max)",
			"LATENCY (avg)", "LATENCY (max)", "LATENCY READ (avg)", "LATENCY READ (max)", "LATENCY WRITE (avg)",
			"LATENCY WRITE (max)"].join(',') + '\n';
		csvContent += Object.values(this.listedDatastores).map(datastore =>
			[datastore.name, datastore.capacity, datastore.usageP, datastore.free, datastore.usage, datastore.avgiops,
				datastore.maxiops, datastore.avgiopsread, datastore.maxiopsread, datastore.avgiopswrite, datastore.maxiopswrite,
				datastore.avglat, datastore.maxlat, datastore.avglatread, datastore.maxlatread, datastore.avglatwrite,
				datastore.maxlatwrite].join(",")
		).join('\n');

		let exportedFilename = 'Datastore capacity.csv';
		let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
		if (navigator.msSaveBlob) { // IE 10+
			navigator.msSaveBlob(blob, exportedFilename);
		} else {
			let link = document.createElement("a");
			if (link.download !== undefined) { // feature detection
				// Browsers that support HTML5 download attribute
				let url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", exportedFilename);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	/**
	 * This method export the VMs associated to the selected table into a CSV file
	 */
	exportVmsCSV() {
		let csvContent = ["VIRTUAL MACHINE NAME", "USAGE (%)", "USAGE (Go)", "PROVISIONED (Go)", "IOPS (avg)", "IOPS (max)", "LATENCY (avg)", "LATENCY (max)", "SNAPSHOT NB", "CURRENT SNAPSHOT", "SNAPSHOT SIZE", "SNAPSHOT DATE", "IS THE LAST?"].join(',') + '\n';
		csvContent += Object.values(this.listedVms).map(vm =>
			[vm.name, vm.sto, vm.stocom, vm.stoprov, vm.avgiops, vm.maxiops, vm.avglat, vm.maxlat, "na", "na", "na", "na", "na"].join(",")
		).join('\n');

		let exportedFilename = 'Datastore details.csv';
		let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
		if (navigator.msSaveBlob) { // IE 10+
			navigator.msSaveBlob(blob, exportedFilename);
		} else {
			let link = document.createElement("a");
			if (link.download !== undefined) { // feature detection
				// Browsers that support HTML5 download attribute
				let url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", exportedFilename);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	selectionChanged = (newlySelectedDatastore) => {
		let triggeredByFilter = false;

		// Detect if the change is caused by setting-up a filter
		if (newlySelectedDatastore == null) {
			triggeredByFilter = true
		}

		// Ignore change events caused by filters
		if (triggeredByFilter) {
			return;
		}

		this.jsonLoaderService.currentJsonSubject.subscribe((json) => {
			this.vmsAreLoading = true;
			let storageOverviewsDatastoreVmData = json.storageOverviewsDatastoreVmData;

			let allVmDatastoreAssociations = storageOverviewsDatastoreVmData.children
				.map((d) => d.children)
				.flat();

			let vmData = allVmDatastoreAssociations
				.filter((a) => a.father_name == newlySelectedDatastore.name);

			let displayVmData = vmData
				.map((vm) => json.storageOverviewsVmData
					.filter((vm2) => vm.name == vm2.name)
					.map((vm2) => Object({
						...vm,
						...vm2
					})))
				.flat()

			this.listedVms = displayVmData;
			this.vmsAreLoading = false;
			setTimeout(() => {
				this.secondTableShouldChange.emit(this.listedVms);
			}, 1000);
		});
	}

	chartCallbackStorageChart: Highcharts.ChartCallbackFunction = (chart) => {
		this.chart = chart;
		this.storageChartReady = true;
		this.onReady.emit(this);

		setTimeout(() => {
			chart.reflow();
		});
	};

	fetchChartNavigationData = (): Observable<Object> => {
		let vmIds = this.selectedVms.map((vm) => vm.uuid);
		return this.storageOverviewService.fetchChartData(vmIds, this.startTime, this.endTime);
	};

	fetchChartData = (min: number, max: number): Observable<Object> => {
		let vmIds = this.selectedVms.map((vm) => vm.uuid);
		return this.storageOverviewService.fetchChartData(vmIds, min, max);
	};

	updateChartData = (event) => {
		if (this.selectedVms.length == 0) {
			return;
		}
		this.graphsAreLoading = true;

		// First manage navigator data
		this.fetchChartNavigationData().subscribe((data: FetchResult[]) => {
			const chartData: SeriesOptionsType[] = [];
			data.map((fr: FetchResult) => {
				let selectedMetric = this.getSelectedMetric();
				let countersSeries = selectedMetric.type == "counters" ? fr.results[1] : fr.results[0];
				let [selectedVm] = this.listedVms.filter((vm) => vm.uuid == fr.vmId);

				let vmPoints = countersSeries
					.filter((point) => point.uuid == selectedVm.uuid)
					.filter((point) => point.dataStoreUrl == this.selectedDatastore.url)
					.map((point) => Object({
						timestamp: point.time,
						value: point.dataPoints.filter((p) => p.metricName == selectedMetric.metricName)[0].value
					}))
					.map((p) => [p.timestamp, p.value * selectedMetric.conversionFactor]);

				chartData.push({
					type: 'line',
					name: `${selectedMetric.label} (${selectedVm.name}) navigator`,
					data: vmPoints,
					dataGrouping: {
						enabled: false
					},
				});
			})

			this.chart.update({
				navigator: {
					series: chartData
				}
			});
		});

		// Now Update the displayed series with more detailed points. I remove one hour (specified in ms) to min date
		// and add one hour (specified in ms) to the max date to give the impression that zoomed lines are continuous
		const oneHourInMs = 1 * 3600 * 1000;
		this.fetchChartData(this.minRange - oneHourInMs, this.maxRange + oneHourInMs).subscribe((data2: FetchResult[]) => {
			this.graphsAreLoading = false;

			const chartData2: SeriesOptionsType[] = [];
			data2.map((fr2: FetchResult) => {
				let selectedMetric = this.getSelectedMetric();
				let countersSeries2 = selectedMetric.type == "counters" ? fr2.results[1] : fr2.results[0];
				let [selectedVm2] = this.listedVms.filter((vm) => vm.uuid == fr2.vmId);

				let vmPoints2 = countersSeries2
					.filter((point) => point.uuid == selectedVm2.uuid)
					.filter((point) => point.dataStoreUrl == this.selectedDatastore.url)
					.map((point) => Object({
						timestamp: point.time,
						value: point.dataPoints.filter((p) => p.metricName == selectedMetric.metricName)[0].value
					}))
					.map((p) => [p.timestamp, p.value * selectedMetric.conversionFactor]);

				chartData2.push({
					type: 'line',
					name: `${selectedMetric.label} (${selectedVm2.name})`,
					data: vmPoints2,
					showInNavigator: false,
					dataGrouping: {
						enabled: false
					},
					tooltip: {
						valueDecimals: 2,
						valueSuffix: ` ${selectedMetric.unit}`
					}
				})
			});

			// Remove series that should not be display
			let displayedSeriesName = chartData2.map((s) => s.name);
			let seriesThatShouldNotBeDisplayed = this.chart.series
				.filter(s => displayedSeriesName.indexOf(s.name.split("_navigator")[0]) == -1);
			seriesThatShouldNotBeDisplayed.map((s) => s.remove());

			// Add missing series
			for (let c of chartData2) {
				let matchingExistingSeries = this.chart.series.filter(s => s.name == c.name);
				if (matchingExistingSeries.length == 0) {
					this.chart.addSeries(c, false);
				}
			}

			// Update all series
			for (let c of chartData2) {
				let matchingExistingSeries = this.chart.series.filter(s => s.name == c.name);
				if (matchingExistingSeries.length > 0) {
					matchingExistingSeries[0].update(c);
				}
			}
		});
	}

	generateDefaultHighchartsOptions(title: string): Highcharts.Options {
		let result: Highcharts.Options = {
			chart: {
				type: 'stock',
				zoomType: 'x',
			},
			time: {
				useUTC: false
			},
			series: [],
			navigator: {
				series: []
			},
			scrollbar: {
				liveRedraw: false
			},
			rangeSelector: {
				buttons: [{
					type: 'hour',
					count: 1,
					text: '1h'
				}, {
					type: 'day',
					count: 1,
					text: '1d'
				}, {
					type: 'month',
					count: 1,
					text: '1m'
				}, {
					type: 'year',
					count: 1,
					text: '1y'
				}, {
					type: 'all',
					text: 'All'
				}],
				inputEnabled: false, // it supports only days
				selected: 4 // all
			},
			xAxis: {
				events: {
					afterSetExtremes: (event) => {
						// Prevent duplicate trigger of this event
						if (this.minRange == event.min && this.maxRange == event.max) {
							return;
						}

						// Prevent this event to be triggered by the setting of a new navigator serie
						if (event.trigger != "zoom" && event.trigger != "navigator" && event.trigger != "rangeSelectorButton") {
							return;
						}

						console.log(`afterSetExtremes ${this.minRange} ${event.min} ${this.maxRange} ${event.max}`);
						console.log(`                 (${new Date(this.minRange)} ${new Date(event.min)} ${new Date(this.maxRange)} ${new Date(event.max)})`);
						this.minRange = event.min;
						this.maxRange = event.max;

						this.updateChartData(this.selectedVms);
					}
				},
				type: 'datetime',
				minRange: 3600 * 1000 // one hour
			},
			credits: {
				enabled: false
			}
		};
		return result;
	}
}

<div class="clr-row">
	<div [ngClass]="col_class">
		<div class="card">
			<div class="card-header card-ajust">
				<div class="clr-row">
					<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
						<h5>
							<clr-icon shape="bar-chart"></clr-icon>
							&nbsp;Capacity evolution
						</h5>
					</div>
					<div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
						<div class="clr-row">
							<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
								<span [ngClass]="cpu_sat_class">
									CPU saturation: {{cpu_saturation}}
								</span>
							</div>
							<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
								<span [ngClass]="ram_sat_class">
									RAM saturation: {{ram_saturation}}
								</span>
							</div>
							<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
								<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
								<span [ngClass]="sto_sat_class">
									STORAGE saturation: {{sto_saturation}}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-block">
				<div class="card-text">
					<highcharts-chart
						[Highcharts]="highcharts"
						[options]="chartOptions"
						[constructorType]="'chart'"
						style="width: 100%; height: 350px; display: block;" *ngIf="isReady">
					</highcharts-chart>
                		</div>
			</div>
		</div>
	</div>
</div>

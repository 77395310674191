import { Component, OnInit } from '@angular/core';

import { ShareService } from '@app/services';

import { Message } from '@app/model';
import { VirtindexService } from '@app/services/virtindex.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-t10',
  templateUrl: './t10.component.html',
  styleUrls: ['./t10.component.css']
})
export class T10Component implements OnInit {

  message: Message;

  isReady: boolean = true;

  isRadar: boolean = false;

  isRank: boolean = false;

  toggle_status: boolean = true;
  radio_choice: number = 0;

  isNoData: boolean = true;

  greenindex_status: boolean = false;

  carbon_radar: Array<any> = [];

  private unsubscribe_init;

  constructor(private message_svc: ShareService, private virtindex_svc: VirtindexService, private router: Router) { }

  /**
   * 
   */
  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);

    this.unsubscribe_init = this.virtindex_svc.virtindexSettings.subscribe(settings => {
			// Get settings
			for (let i in settings) {
				if (settings[i] != undefined) {
					switch (settings[i].option) {
					case 'status':
						this.greenindex_status =  String(settings[i].value) == "true" ;
					break;
					default:
					break;
					}
				}
			}

      // Build data
      if(this.greenindex_status) {

        this.virtindex_svc.virtindexData.subscribe(data => {
            if(data.server != undefined) {
              this.carbon_radar =  [data.server, data.socket,
                  data.ageserver, data.pueserver, data.coeffco2server,
                  data.kwhserver,data.co2gserver,
                  data.wattsvm, data.co2gvm, data.co2tvm];

              if(this.carbon_radar.length > 0) {
                this.isNoData = false;
                this.isRank = true;
              } else {
                this.isNoData = true;
                this.isRank = false;
              }
            }
        });

      } else {
        this.isNoData = false;
      }

		});

    this.unsubscribe_init.unsubscribe();
  }

  /**
   * 
   */
  radioAction(): void {
    if(this.radio_choice == 0) {
      if(!this.isNoData) {
        this.isRadar = true;
        this.isRank = false;
      }
    } else {
      if(!this.isNoData) {
        this.isRadar = false;
        this.isRank = true;
      }
    }
  }

  /**
   * 
   */
  callRoute(): void {
    this.router.navigate(['/greenindex']);
  }

}

<div class="clr-row" *ngIf="message.creditSelected == ''">
    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
            <div class="card" style = "width: 100%;height: 150px;display: block; ">
                    <div class="card-header">
                            <span style="color:grey;font-size:20px;">
                                    <clr-icon shape="dollar"></clr-icon>
                                    COSTS
                            </span>
                    </div>
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                            <div class="card" style="margin-top: 0px;">
                                                    <div class="card-block">
                                                    <span style="font-weigth:bold;font-size:24px">
                                                            $ {{metadata.computeCost}}
                                                            </span>
                                                            <br>
                                                            <span style="float: inline-end;">compute EC2</span>
                                                            <br>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                            <div class="card" style="margin-top: 0px;">
                                                    <div class="card-block">
                                                            <span style="font-weigth:bold;font-size:24px">
                                                                    $ {{metadata.storageCost}}
                                                            </span>
                                                            <br>
                                                            <span style="float: inline-end;">storage EBS</span>
                                                            <br>
                                                    </div>
                                            </div>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="card">
                    <div class="card-block">
                            <span style="font-size: 28px" id="spec-cloud-provider-aws-total-cost">
                                    $ {{metadata.computeCost+metadata.storageCost}}
                            </span>
                            <span>
                                    over the current month
                            </span>
                            <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadCostsDetail()" id="spec-cloud-provider-aws-morecost">
                                    more ...
                            </span>
                    </div>
            </div>
    </div>
    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
            <div class="card" style = "width: 100%;height: 150px;display: block; ">
                    <div class="card-header">
                            <span style="color:grey;font-size:20px;">
                                    <fa-icon [icon]="['fas', 'leaf']" style="color:grey;font-size:14px;"></fa-icon>
                                    GREEN IT
                            </span>
                            <clr-tooltip>
                                    <clr-icon clrTooltipTrigger style="color: #0072a3;" shape="info-circle" size="24" aria-label="Uniq tooltip 6"></clr-icon>
                                    <clr-tooltip-content clrPosition="left" clrSize="lg" *clrIfOpen style="background-color:white;outline-style:solid;outline-color: #0072a3;outline-width: thin;outline-offset:-1px">
                                            <span style="color: #0072a3">Green IT</span>
                                            <p>
                                                    Current month data.
                                                    <br>For the calculation of the carbon emission:
                                                    <br> - unit: kg CO2eq
                                                    <br> - result = direct energy + embodied energy
                                                    <br> - CPU based on consumption
                                                    <br> - RAM based on allocation
                                                    <br> - STORAGE based on allocation.
                                            </p>
                                    </clr-tooltip-content>
                            </clr-tooltip>
                    </div>
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                            <div class="card" style="margin-top: 0px;">
                                                    <div class="card-block">
                                                    <span style="font-weigth:bold;font-size:24px" id="spec-cloud-provider-aws-greenit">
                                                                    {{toFixedNumber((metadata.aggPower/1000), 0)}}
                                                            </span>
                                                            <br>
                                                            <span style="float: inline-end;">kWh</span>
                                                            <br>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                            <div class="card" style="margin-top: 0px;">
                                                    <div class="card-block">
                                                    <span style="font-weigth:bold;font-size:24px">
                                                            {{toFixedNumber(metadata.aggCo2, 0)}}
                                                            </span>
                                                            <br>
                                                            <span style="float: inline-end;">kg CO2eq</span>
                                                            <br>
                                                    </div>
                                            </div>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="card">
                    <div class="card-block">
<span style="font-size: 28px">
{{metadata.currentMonthInstance}}
</span>
                            <span>
                                    instances for the current month
                            </span>
                            <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadCo2Detail()" id="spec-cloud-provider-aws-moreco2">
                                    more ...
                            </span>
                    </div>
            </div>
    </div>
    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
            <div class="card" style = "width: 100%;height: 150px;display: block; ">
                    <div class="card-header">
                            <span style="color:grey;font-size:20px;">
                                    <clr-icon shape="vm"></clr-icon>
                                    INSTANCES	
                            </span>
                            <button class="btn btn-link btn-sm" style="float: right;size: 16px;" (click)="updateModel()">
                                    UPDATE MODEL
                            </button>
                    </div>
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                            <div class="card" style="margin-top: 0px;">
                                                    <div class="card-block">
                                                            <span style="font-weigth:bold;font-size:24px">
                                                                    {{metadata.runInstance}}
                                                            </span>
                                                            <br>
                                                            <span style="float: inline-end;">running</span>
                                                            <br>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                            <div class="card" style="margin-top: 0px;">
                                                    <div class="card-block">
                                                            <span style="font-weigth:bold;font-size:24px">
                                                                    {{metadata.offInstance}}
                                                            </span>
                                                            <br>
                                                            <span style="float: inline-end;">off</span>
                                                            <br>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                            <div class="card" style="margin-top: 0px;">
                                                    <div class="card-block">
                                                            <span style="font-weigth:bold;font-size:24px">
                                                                    {{metadata.kpiEvolutionCreated}}
                                                            </span>
                                                            <br>
                                                            <span style="float: inline-end;">created</span>
                                                            <br>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                            <div class="card" style="margin-top: 0px;">
                                                    <div class="card-block">
                                                            <span style="font-weigth:bold;font-size:24px">
                                                                    {{metadata.kpiEvolutionDestroy}}
                                                            </span>
                                                            <br>
                                                            <span style="float: inline-end;">deleted</span>
                                                            <br>
                                                    </div>
                                            </div>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="card">
                    <div class="card-block">
                            <span style="font-size: 28px" id="spec-cloud-provider-aws-total-instance">
                                    {{metadata.runInstance+metadata.offInstance}}
                            </span>
                            <span>
                                    current instances
                            </span>
                            <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadInstancesDetail()" id="spec-cloud-provider-aws-moreinstance">
                                    more ...
                            </span>
                    </div>
            </div>
</div>
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
            <div class="card">
                    <div class="card-header" style="height: 60px;">
                            <span style="color:grey;font-size:20px;">
                                    <clr-icon shape="vm"></clr-icon>
                                    ELASTICITY
                            </span>
                            <clr-tooltip>
                                    <clr-icon clrTooltipTrigger style="color: #0072a3;" shape="info-circle" size="24" aria-label="Uniq tooltip 6"></clr-icon>
                                    <clr-tooltip-content clrPosition="middle-right" clrSize="lg" *clrIfOpen style="background-color:white;outline-style:solid;outline-color: #0072a3;outline-width: thin;outline-offset:-1px">
                                            <span style="color: #0072a3">Elasticity</span>
                                            <p>
                                                    The objective is to ensure that the number of instances created each day does not increase exponentially.
                                                    <br><br>The graph shows the last 30 days.
                                                    <br>The green bar indicates the number of deleted instances for a given day.
                                                    <br>The blue bar indicates the number of instances created for a given day.
                                                    <br>The red dot indicates the number of instances existing at the end of the day.
                                                    <br>If the red dot drifts to the right, the number of instances increases compared to the previous day. Conversely, if the red dot drifts to the left, the number of instances decreases compared to the previous day.
                                            </p>
                                    </clr-tooltip-content>
                            </clr-tooltip>
                    </div>
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                            <highcharts-chart
        id="spec-elasticity"
                                                    [Highcharts] = "highcharts"
                                                    [options] = "chartOptions3"
                                                    [constructorType]="'chart'"
                                                    style = "width: 100%;height: 275px;display: block; " 
                                                    *ngIf="isReady3">
                                            </highcharts-chart>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="card">
                    <div class="card-block">
                            <span style="font-size: 28px">
                                    {{metadata.kpiEvolutionCreated}}
                            </span>
                            <br>
                            <span>
                                    instances created over the current month
                            </span>
                    </div>
            </div>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
            <div class="card">
                    <div class="card-header" style="height: 60px;">
                            <span style="color:grey;font-size:20px;">
                                    <clr-icon shape="vm"></clr-icon>
                                    DYNAMICITY
                            </span>
                            <clr-tooltip>
                                    <clr-icon clrTooltipTrigger style="color: #0072a3;" shape="info-circle" size="24" aria-label="Uniq tooltip 6"></clr-icon>
                                    <clr-tooltip-content clrPosition="middle-right" clrSize="md" *clrIfOpen style="background-color:white;outline-style:solid;outline-color: #0072a3;outline-width: thin;outline-offset:-1px">
                                            <span style="color: #0072a3">Dynamicity</span>
                                            <p>
                                                    The objective is to ensure that the instances created have a short lifespan.
                                                    <br><br>The graph shows how long ago the instances were created.
                                                    <br>For example {{model.insLastDay}} instances have been created since {{model.lastDay}} days.
                                            </p>
                                    </clr-tooltip-content>
                            </clr-tooltip>
                    </div>
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                            <highcharts-chart
        id="spec-dynamicity"
                                                    [Highcharts] = "highcharts"
                                                    [options] = "chartOptions4"
                                                    [constructorType]="'chart'"
                                                    style = "width: 100%;height: 275px;display: block; " 
                                                    *ngIf="isReady4">
                                            </highcharts-chart>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="card">
                    <div class="card-block">
                            <span style="font-size: 28px" id="spec-cloud-provider-aws-dynamicity">
                                    {{model.more30Days}}
                            </span>
                            <br>
                            <span>
                                    instances that are over 30 days old
                            </span>
                    </div>
            </div>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
            <div class="card">
                    <div class="card-header" style="height: 60px;">
                            <span style="color:grey;font-size:20px;">
                                    <clr-icon shape="cpu"></clr-icon>
                                    CPU CREDITS
                            </span>
                            <clr-tooltip>
                                    <clr-icon clrTooltipTrigger style="color: #0072a3;" shape="info-circle" size="24" aria-label="Uniq tooltip 6"></clr-icon>
                                    <clr-tooltip-content clrPosition="middle-right" clrSize="lg" *clrIfOpen style="background-color:white;outline-style:solid;outline-color: #0072a3;outline-width: thin;outline-offset:-1px">
                                            <span style="color: #0072a3">CPU Credits</span>
                                            <p>
                                                    The objective is to show how instances use their cpu credit.  Over-consumption of CPU credits can lead to over-billing.
                                                    <br><br>The graph shows the distribution of the number of instances according to their rate of use of cpu credits.
                                                    <br>Normal means that the CPU credits utilization rate is above 50% without surplus credits charged.
                                                    <br>Underused means that the CPU credit utilization rate is below 50%.
                                                    <br>Overused means that the CPU credit utilization generate a surplus credits charged.
                                            </p>
                                    </clr-tooltip-content>
                            </clr-tooltip>
                    </div>
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
                                            <highcharts-chart
        id="spec-credit"
                                                    [Highcharts] = "highcharts"
                                                    [options] = "chartOptions"
                                                    [constructorType]="'chart'"
                                                    style = "width: 100%;height: 275px;display: block;" 
                                                    *ngIf="isReady">
                                            </highcharts-chart>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="card">
                    <div class="card-block">
                            <span style="font-size: 28px" id="spec-cloud-provider-aws-credits">
                                    {{metadata.kpiCreditAggUsage}}
                            </span>
                            <br>
                            <span>
                                    credits used over the last day ({{model.kpiAggUsagePercent}}%)
                            </span>
                            <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadCreditsDetail()">
                                    more ...
                            </span>
                    </div>
            </div>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12" *ngIf="kpiSavingsplans.length > 0">
            <div class="card">
                    <div class="card-header" style="height: 60px;">
                            <div class="clr-row">
                                    <div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
                                            <span style="color:grey;font-size:20px;">
                                                    <clr-icon shape="book"></clr-icon>
                                                    SAVINGS PLAN
                                            </span>
                                            <clr-tooltip>
                                                    <clr-icon clrTooltipTrigger style="color: #0072a3;" shape="info-circle" size="24" aria-label="Uniq tooltip 6"></clr-icon>
                                                    <clr-tooltip-content clrPosition="left" clrSize="lg" *clrIfOpen style="background-color:white;outline-style:solid;outline-color: #0072a3;outline-width: thin;outline-offset:-1px">
                                                            <span style="color: #0072a3">Savings plan</span>
                                                            <p>
                                                                    The objective is to show the utilization rate of a saving plan which must be between 90 and 100% each day.
                                                                    <br><br>The graph shows the distribution of the number of days according to the utilization rate.
                                                                    <br>Normal means that the utilization rate is between 90 and 100%.
                                                                    <br>Underused means that the utilization rate is less than 90%.
                                                                    <br>Overused means that the utilization rate is greater than 100%.
                                                            </p>
                                                    </clr-tooltip-content>
                                            </clr-tooltip>
                                            </div>
                                            <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                                            <div style="float:right;">
                                                    <div class="clr-control-container">
                                                            <div class="clr-select-wrapper">
                                                                    <select class="clr-select" [(ngModel)]="model.selectedId" (ngModelChange)="loadSp($event)">
                                                                            <option *ngFor="let sp of kpiSavingsplans">
                                                                                    savings plan {{sp.id}}
                                                                            </option>
                                                                    </select>
                                                            </div>
                                                            <span class="clr-subtext">select a savings plan</span>
                                                    </div>
                                            </div>
                                    </div>
                            </div>
                    </div>
                    <div class="card-block">
                            <div class="clr-row">
                                    <div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
                                            <highcharts-chart
                                                    [Highcharts] = "highcharts"
                                                    [options] = "chartOptions2"
                                                    [constructorType]="'chart'"
                                                    style = "width: 120%;height: 275px;display: block; z-index: 10;" 
                                                    *ngIf="isReady2">
                                            </highcharts-chart>
                                    </div>
                                    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12" style="padding-top: 180px;z-index: 99;">
                                            <span class="clr-subtext" style="float: right;">
                                                    usage rate
                                            </span>
                                            <br>
                                            <span [ngClass]="model.maxSpColor" style="font-size: 14px;">
                                                    {{model.maxSp}} %
                                            </span><br>
                                            <span [ngClass]="model.avgSpColor" style="font-size: 18px;padding-right: 10px;">
                                                    {{model.avgSp}} %
                                            </span><br>
                                            <span [ngClass]="model.minSpColor" style="font-size: 14px;">
                                                    {{model.minSp}} %
                                            </span>
                                    </div>
                            </div>
                    </div>
            </div>
            <div class="card">
                    <div class="card-block">
                            <span style="font-size: 28px">
                                    {{model.lastSp}}%
                            </span>
                            <br>
                            <span>
                                    usage rate over the last day 
                            </span>
                            <span style="float: right;color: #057ff5;" ngClass="addLink" (click)="loadSpDetail()">
                                    more ...
                            </span>
                    </div>
            </div>
    </div>
</div>
<div class="card-block">
    <ng-template cloud-instances-detail *ngIf="detail_str == 'instance'"></ng-template>
    <ng-template cloud-costs-detail *ngIf="detail_str == 'cost'"></ng-template>
    <ng-template cloud-co2-detail *ngIf="detail_str == 'co2'"></ng-template>
    <ng-template cloud-kpi-credits *ngIf="detail_str == 'kpicredits'"></ng-template>
    <ng-template cloud-kpi-savingsplan *ngIf="detail_str == 'kpisp'"></ng-template>
    <ng-template cloud-kpi-evolution *ngIf="detail_str == 'kpievolution'"></ng-template>
</div>
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-cluster-distribution8]'
})
export class Clusterdistribution8Directive {

  constructor(public viewContainerRef8: ViewContainerRef) { }

}

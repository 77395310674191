<table class="table">
	<thead>
		<tr>
			<th class="left">Scope</th>
			<th class="left">Type</th>
			<th class="left">Recurrence</th>
			<th>VM cost ({{globalCurrency}})</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td class="left">all VMs</td>
			<td class="left">generic cost</td>
			<td class="left">annual</td>
			<td style="width:15%;vertical-align:top">
				<div class="clr-form-control" style="flex-direction:column;margin-top:unset;">
                                        <div>
                                            	<div class="clr-input-wrapper">
							<b><input class="clr-input" id="cost.vm.gen" type="number" [(ngModel)]="model.vmCostGeneric" (ngModelChange)="enableVmUpdate()" style="width:6em;text-align:left"></b>
                                                	<clr-icon class="clr-validate-icon" *ngIf="model.vmCostGeneric == null" shape="exclamation-circle"></clr-icon>
                                            	</div>
                                        </div>
                                    </div>
			</td>
		</tr>
	</tbody>
</table>

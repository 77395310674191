<!-- UPLOAD DIV FOR REPORT -->
<div *ngIf="target==report">
    <button style="float:left" *ngIf="currentUser.login == 'root'" class="btn btn-link btn-sm" (click)="displayModalUpload()">
        Upload your logo
    </button>
</div>

<clr-modal [(clrModalOpen)]="upload_modal" [clrModalClosable]=false [clrModalSize]="'ll'">
    <h3 *ngIf="target==updates" class="modal-title">Upload your update</h3>
    <h3 *ngIf="target==report" class="modal-title">Upload your logo</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
           
            <button style="margin-top:20px;margin-bottom:50px;color:black" class="btn btn-sm btn-info-outline" (click)="fileinput.click()">
                <clr-icon shape="folder-open"></clr-icon>
                <span>&nbsp;&nbsp;Select file ...&nbsp;&nbsp;</span>
                <input #fileinput type="file" (change)="init($event)" style="display:none;" />
            </button>

            <clr-progress-bar [ngClass]="upload_progress_class" [clrValue]="upload_progress" clrMax="100"></clr-progress-bar>

            <div *ngIf="target==updates" style="margin-top:15px;"><b>Accepted file type : .tar.gz</b></div>
            <div *ngIf="target==report" style="margin-top:15px;"><b>Accepted file type : .jpg .jpeg .png</b></div>

            <form (ngSubmit)="upload()">
                <div style="text-align:center;margin-top:30px;">
                    <div *ngIf="upload_progress_message">
                        <span *ngIf="upload_progress_class.includes('danger')" style="color:#991700">
                            <clr-icon size="24" shape="exclamation-circle"></clr-icon>
                            <b>{{upload_progress_message}}</b>
                        </span>
                        <span *ngIf="upload_progress_class.includes('success')" style="color:#306b00">
                            <clr-icon size="24" shape="check-circle"></clr-icon>
                            <b>{{upload_progress_message}}</b>
                        </span>
                        <span *ngIf="upload_progress_class.includes('init')" style="color:black">
                            <b>{{upload_progress_message}}</b>
                        </span>
                    </div>
                    <button [clrLoading]="upload_button_state" style="margin-top:30px" *ngIf="upload_button_ready" class="btn  btn-block btn-primary" aria-label="upload">
                        <clr-icon shape="upload"></clr-icon>
                    </button>
                </div>
            </form>

            <hr *ngIf="target == report">
                        
            <div *ngIf="target == report" style="text-align:center;">
                <b>Custom logo uploaded : &nbsp;&nbsp;</b>
                <clr-icon *ngIf="!custom_logo" shape="times" style="color:#991700" size="36"></clr-icon>
                <!-- <clr-icon *ngIf="custom_logo" shape="check" style="color:#306b00" size="36"></clr-icon> -->
                <img *ngIf="custom_logo" [src]="url_logo">
                <clr-icon *ngIf="custom_logo" (click)="delete_modal_logo = true" style="cursor:pointer;margin-left:30px;"shape='trash' size="15"></clr-icon>
            </div>

        </div>
        
    </div>
    <div class="modal-footer">
	    <button [disabled]="!upload_close_button" type="button" class="btn btn-primary" (click)="upload_modal = false">Close</button>
    </div>
</clr-modal>

<!-- REPORT LOGO DELETE -->
<clr-modal [(clrModalOpen)]="delete_modal_logo" [clrModalClosable]=false>
    <h3 class="modal-title">Delete logo</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>Are you sure you want to delete current logo ?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-link" (click)="delete_modal_logo = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="deleteLogo()">Delete</button>
    </div>
</clr-modal>

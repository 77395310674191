import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-consover-cpu]'
})
export class ConsovercpuDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

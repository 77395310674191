import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { AccountService, JsonloaderService, ManagementService, ShareService } from '@app/services';

import { COST, HostSynthesis, Json, JSONTarget, Message, User } from '@app/model';

import { getUserCurrency } from '../../../assets/js/tools.js';


@Component({
  selector: 'app-costservergeneric',
  templateUrl: './costservergeneric.component.html',
  styleUrls: ['./costservergeneric.component.css']
})
export class CostservergenericComponent implements AfterViewInit, OnInit {

  message: Message;

  globalCurrency: string = '';

  data_hosts: HostSynthesis[] = [];

  model: any;

  private currentUser: User;


  constructor(
	private authentication_svc: AccountService,
        private json_svc: JsonloaderService,
        private management_svc: ManagementService,
        private data: ShareService,
        private cd: ChangeDetectorRef) { 
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.globalCurrency = getUserCurrency(this.currentUser.currency);

	this.data.currentMessage.subscribe(message => this.message = message);

	this.model = {
		serverThread: 0,
		serverRam: 0,
		srvCostGeneric: 10000,
		cpuCostGeneric: 1,
		ramCostGeneric: 1,
		serverRatio: 40
	};

	this.data_hosts = this.json_svc.json.hostSynthesis;
	if(this.data_hosts.length > 0) {
		let cpu_cap: number = 0;
		let ram_cap: number = 0;
		for(var i=0; i < this.data_hosts.length; i++) {
			cpu_cap += this.data_hosts[i].cpucap;
			ram_cap += this.data_hosts[i].ramcap;
		}
		this.model.serverThread = Math.round(cpu_cap/this.data_hosts.length);
		this.model.serverRam = Math.round(ram_cap/1024/this.data_hosts.length);
	}
  }

  ngAfterViewInit(): void {
	this.getCost();
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  updateCostServer(): void {

	this.message.isCostRecord = true;
	this.serverCostProcessing();
  }

  updateRatio(): void {

	this.message.isCostRecord = true;
	this.serverCostProcessing();
  }

  updateCostVcpu() : void {

	if(this.model.serverRam > 0 && this.model.srvCostGeneric > 0) {
		this.message.isCostRecord = true;

		this.model.serverRatio = Math.round(this.model.cpuCostGeneric*100*this.model.serverThread/this.model.srvCostGeneric);
		if(this.model.serverRatio < 100) {
			if(this.model.serverRatio < 0)
				this.model.serverRatio = 0;

			this.model.ramCostGeneric = Math.round((100-this.model.serverRatio)*this.model.srvCostGeneric/100/this.model.serverRam);
		} else {
			this.model.serverRatio = 100;
			this.model.ramCostGeneric = Math.round(this.model.srvCostGeneric/100/this.model.serverRam);
		}

		this.message.costCpuGeneric = this.model.cpuCostGeneric;
		this.message.costRamGeneric = this.model.ramCostGeneric;
		this.message.costSrvGeneric = this.model.srvCostGeneric;
		this.message.costRatioGeneric = this.model.serverRatio;
	}
  }

  updateCostVram() : void {

	if(this.model.serverThread > 0 && this.model.srvCostGeneric > 0) {
		this.message.isCostRecord = true;

		this.model.serverRatio = 100 - Math.round(this.model.ramCostGeneric*100*this.model.serverRam/this.model.srvCostGeneric);
		if(this.model.serverRatio < 0)
			this.model.serverRatio = 0;

		if(this.model.serverRatio > 100)
			this.model.serverRatio = 100;

		this.model.cpuCostGeneric = Math.round(this.model.serverRatio*this.model.srvCostGeneric/100/this.model.serverThread);

		this.message.costCpuGeneric = this.model.cpuCostGeneric;
		this.message.costRamGeneric = this.model.ramCostGeneric;
		this.message.costSrvGeneric = this.model.srvCostGeneric;
		this.message.costRatioGeneric = this.model.serverRatio;
	}
  }

  private serverCostProcessing(): void {

	if(this.model.serverThread > 0)
		this.model.cpuCostGeneric = Math.round(this.model.serverRatio*this.model.srvCostGeneric/100/this.model.serverThread);
	if(this.model.serverRam > 0)
		this.model.ramCostGeneric = Math.round((100 - this.model.serverRatio)*this.model.srvCostGeneric/ 100/this.model.serverRam);

	this.message.costCpuGeneric = this.model.cpuCostGeneric;
	this.message.costRamGeneric = this.model.ramCostGeneric;
	this.message.costSrvGeneric = this.model.srvCostGeneric;
	this.message.costRatioGeneric = this.model.serverRatio;
  }

  private getCost(): void {

	this.management_svc.getCost('root').pipe(first()).subscribe(
		data => {
			if(data.SERVER_COST != -1) {
				this.model.srvCostGeneric = data.SERVER_COST;
				this.model.cpuCostGeneric = data.CPU_COST;
				this.model.ramCostGeneric = data.RAM_COST;
				this.model.serverRatio = data.RATIO;
				this.serverCostProcessing();
			}
		},
		error => {
			if(error != null)
				console.log(error)
		}
	);
  }
}

<div class="card" style="margin-top:0.6rem">
    <div class="card-header card-ajust" style="padding-top:0">
        <h5><clr-icon [attr.shape]="icon"></clr-icon>&nbsp;{{title}}</h5>
    </div>
    <div class="card-block" style="height:270px;">
        <div style="width:100%;text-align:center;">
            <button type="button" class="btn btn-sm" (click)=showElementModal(); style="width:150px"><b> Deploy / Delete</b></button>
        </div>
        <hr>
        <div style="height:66%;overflow:auto;color:#0072a3">
            <table #addtable id="{{ 'capa-simul-' + target }}" style="width:100%;border-radius:10px;border-color:#e8e8e8;border-style:outset;text-align:center;">
                <tr class="title">
                    <td></td>
                    <td><label  class="clr-control-label" style='color:#0072a3;font-size:13px'>TEMPLATE</label></td>
                    <td><label class="clr-control-label" style='color:#0072a3;font-size:13px'>QUANTITY</label></td>
                    <td></td>
                    <td></td>
                </tr>
                <!-- auto -->
            </table>
        </div>
    </div>
</div>

<!-- ELEMENT MODAL -->
<clr-modal [(clrModalOpen)]="element_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title" *ngIf="target == 'vm'"> Deploy / Delete Virtual Machines</h3>
    <h3 class="modal-title" *ngIf="target == 'host'"> Deploy / Delete Physical Machines</h3>
    <h3 class="modal-title" *ngIf="target == 'ds'"> Deploy / Delete Datastores</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%">
            <table style="width:100%" *ngIf="modal_data" class="row-border hover cell-border stripe dt-body-center" datatable #dtable [dtOptions]="dtOptions">
                <thead>
                    <tr>
                    <th></th>
                    <th style="font-size:12px;">NAME</th>
                    <th *ngIf="target == 'vm'" style="font-size:12px;">VRAM (MB)</th>
                    <th *ngIf="target == 'vm'" style="font-size:12px;">VCPU</th>
                    <th *ngIf="target == 'vm'" style="font-size:12px;">VDISK (MB)</th>
                    <th *ngIf="target == 'vm'" style="font-size:12px;">RAM (MB)</th>
                    <th *ngIf="target == 'vm'" style="font-size:12px;">CPU (MHz)</th>
                    <th *ngIf="target == 'host'" style="font-size:12px;">RAM Capacity (MB)</th>
                    <th *ngIf="target == 'host'" style="font-size:12px;">Logical Cores</th>
                    <th *ngIf="target == 'ds'" style="font-size:12px;">Storage Capacity (MB)</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let element of modal_data" [attr.data-id]="element.ID">
                        <td style='width:2px;text-align:center'>
                      
                              <div class="clr-select-wrapper">
                                <select [attr.data-id]="element.ID + simulation_input_suffix" id="select-full" class="clr-select" (change)="addRemoveElement($event.target.selectedOptions[0],element)">
                                  <option value=""></option>
                                  <option value="add">Deploy</option>
                                  <option *ngIf="element.ID != '--new-vm--' && element.ID != '--new-server--' &&  element.ID != '--new-datastore--'" value="del">Delete</option>
                                </select>
                              </div>
                            
                        </td>

                        <td style='text-align:left'>{{element.NAME}}</td>
                        <td *ngIf="target == 'vm' || target == 'host'" style='text-align:center'>{{element.RAMCAP}}</td>
                        <td *ngIf="target == 'vm' || target == 'host'" style='text-align:center'>{{element.CPUTHREADNB}}</td>
                        <td *ngIf="target == 'vm' || target == 'ds'" style='text-align:center'>{{element.DISKCAP}}</td>
                        <td *ngIf="target == 'vm'" style='text-align:center'>{{element.RAMCONS}}</td>
                        <td *ngIf="target == 'vm'" style='text-align:center'>{{element.CPUCONSMHZ}}</td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>

<!-- EDIT MODAL -->
<clr-modal [(clrModalOpen)]="edit_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Edit template</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%">
            <table #edittable style="width:100%;border-radius:10px;text-align:center;">
            </table>
            <hr>
            <table #legendtable>
            </table>
        </div>
    </div>
    <div class="modal-footer">
	    <button type="submit" class="btn btn-primary" (click)="closeEditModal()">Save</button>
    </div>
</clr-modal>


<div class="card">
	<div class="card-header">
		<div class="clr-row">
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="manageView(true)">
					LAST 30 DAYS ANALYSIS
				</button>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="manageView(false)">
					TIME VIEW ANALYSIS
				</button>
			</div>
			<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
				<div class="alert alert-app-level alert-info" role="alert">
					<div class="alert-items">
        					<div class="alert-item static">
            						<div class="alert-icon-wrapper">
                						<clr-icon class="alert-icon" shape="info-circle"></clr-icon>
            						</div>
            						<div class="alert-text">
								{{text_info}}
            						</div>
        					</div>
    					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card-block">
		<div *ngIf="!isListView">
			<ng-template load-troubleshooting-map></ng-template>
		</div>
		<div *ngIf="isListView">
			<ng-template load-troubleshooting-list></ng-template>
		</div>
	</div>
</div>

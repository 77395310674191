export interface SelectionFilter {
    user: string;
    filter: string;
    element_type: string;
    element_name: string;
    element_id: string;
    flag: number;
}

export interface SelectionFilterRec {
    user: string;
    filter: string;
    element_type: string;
    element_name: string;
    element_id: string;
    flag: number;
    resource: string;
}

export interface ElementView {
    USER: string;
    FILTER: string;
    SRV_STATE: number;
    FD_STATE: number;
    TAG_STATE: number;
    RP_STATE: number;
}

export interface TimeFilter {
    user: string;
    filter: string;
    beginDate: string;
    endDate: string;
    begin: number;
    end: number;
}

export interface FilterMgt {
    id: number;
    name: string;
    status: string;
    time: string;
    work_days: number;
    time_view: string;
    element_view: string;
    shared: number;
}

export interface IhmSettings {
    user: string,
    filter: string,
    target: string,
    p1: string,
    p2: string,
    p3: string,
    p4: string,
    p5: string,
    p6: string,
    p7: string,
    p8: string,
    p9: string,
    p10: string,
    p11: string,
    p12: string,
    p13: string,
    p14: string,
    p15: string,
    p16: string,
    p17: string,
    p18: string,
    p19: string,
    p20: string
}

export enum IhmSettingsTarget {
    CAPACITY = "capacity",
    RECOMMENDATION = "recommendation",
    GOD = "god"

}

export interface GreenitSettings {
    option: string;
    value: string;
}

export interface GreenitMinmax {
    name: string;
    identifier: string;
    model: string;
    cpumodel: string;
    sockets: number;
    ramcap: number;
    power: number;
    min: number;
    max:number;
}

export interface GreenitServersSettings {
    name: string;
    identifier: string;
    year: number;
    amortization: number;
    embodied: number;
    co2: number;
    cost: number;
    pue: number;
    timeago:number;
}

export interface NetscopeSettings {
    option: string;
    value: string;
}

export interface RecoServerStop {
    user: string;
    filter: string;
    uuid: string;
    servers: number;
}

export interface COST {
    USER: string;
    CPU_COST: number;
    RAM_COST: number;
    VM_COST: number;
    STO_COST: number;
    SERVER_COST: number;
    RATIO: number;
}

export interface VM_COST {
    USER: string;
    NAME: string;
    IDENTIFIER: string;
    COST: number;
}

export interface DS_COST {
    USER: string;
    NAME: string;
    URL: string;
    COST: number;
}

export interface SERVER_COST {
    USER: string;
    NAME: string;
    IDENTIFIER: string;
    HOSTCOST: number;
    CPUCOST: number;
    RAMCOST: number;
    RATIO: number;
}

export interface FilterActivation {
    name: string;
    user_login: string;
    status: string;
}

export interface FilterDelete {
    name: string;
    user: string;
    id: number;
}

export interface ConsOver {
    timeStart: number;
    timeEnd: number;
    start: string;
    end: string;
    uuid: string;
    name: string;
    values: number;
    avgcpu: number;
    mincpu: number;
    maxcpu: number;
    cpu10: number;
    cpu70: number;
    avgcpurdy: number;
    mincpurdy: number;
    maxcpurdy: number;
    percpurdy: number;
    avgram: number;
    minram: number;
    maxram: number;
    avgiodisk: number;
    miniodisk: number;
    maxiodisk: number;
    avglatdisk: number;
    minlatdisk: number;
    maxlatdisk: number;
    avgionet: number;
    minionet: number;
    maxionet: number;
}

export interface TagCostRec {
    timeago: number;
    uuid: string;
    user: string;
    name: string;
    cost: number;
    element: string;
}

export interface TagCost {
    TIMEAGO: number;
    IDENTIFIER: string;
    USER: string;
    NAME: string;
    COST: number;
}

export interface TagCostMin {
    IDENTIFIER: string;
    NAME: string;
    COST: number;
}

export interface TagCostElement {
    IDENTIFIER: string;
    ELEMENT: string;
}

export interface COST_UPDATE {
    VM: number;
    HOST: number;
    STORAGE: number;
}

<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
        	<div class="card-block">
				<div class="clr-row">
                    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="isSelectElement">
                        <clr-combobox-container>
							<clr-combobox
								[(ngModel)]="element_selected"
								(ngModelChange)="loadData()"
								(clrOpenChange)="initElementList()"
								placeholder="select an element"
								required>
							  <clr-options>
								<clr-option *clrOptionItems="let element of elements; field:'name'" [clrValue]="element">
									{{element.text}}
								</clr-option>
							  </clr-options>
							</clr-combobox>
							<clr-control-helper>enter an element name</clr-control-helper>
							<clr-control-error>element not found</clr-control-error>
						</clr-combobox-container>
                    </div>
					<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
						<div class="clr-control-container">
							<div class="clr-select-wrapper">
								<select class="clr-select" [(ngModel)]="cons_selected" (ngModelChange)="loadData()">
                                    <option value="weekly">weekly</option>
									<option value="monthly">monthly</option>
									<option value="yearly">yearly</option>
									<option value="overall" *ngIf="!isSelectElement">overall</option>
								</select>
							</div>
							<span class="clr-subtext">select a consumption profile</span>
						</div>
					</div>
                    <div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="!isSelectElement">
                        <div class="clr-control-container">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [(ngModel)]="message.calcons_counter" (ngModelChange)="loadData()">
                                    <option value="power_float">Power (Watt)</option>
                                    <option value="cpu_usage">CPU Usage (%)</option>
                                    <option value="ram_usage">RAM Usage (%)</option>
                                    <option value="cpu_ready">CPU Ready (%)</option>
                                </select>
                                <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                            </div>
                            <span class="clr-subtext">Select counter</span>
                        </div>
                    </div>
                    <div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="message.calcons_counter != 'power_float'">
                        <div class="clr-control-container">
                            <div class="clr-select-wrapper">
                                <select class="clr-select" [(ngModel)]="message.calcons_rollup" (ngModelChange)="loadData()">
                                    <option value="avg">Average</option>
                                    <option value="max">Maximum</option>
                                    <option value="min">Minimum</option>
                                </select>
                                <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                            </div>
                            <span class="clr-subtext">Select rollup</span>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div class="card">
            <div class="card-block">
                <ng-template load-weekly-cal *ngIf="cons_selected == 'weekly'"></ng-template>
                <ng-template load-monthly-cal *ngIf="cons_selected == 'monthly'"></ng-template>
                <ng-template load-yearly-cal *ngIf="cons_selected == 'yearly'"></ng-template>
                <ng-template load-vm-consover *ngIf="cons_selected == 'overall'"></ng-template>
            </div>
        </div>
    </div>
</div>
import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
 
import { AccountService, JsonloaderService, ManagementService, ShareService } from '@app/services';

import { JSONTarget, Message, RecoServer, RecoServerStop, User } from '@app/model';

import { RecommendationconsolidationserverlistComponent } from '@app/recommendation/recommendationconsolidationserverlist/recommendationconsolidationserverlist.component';
import { RecommendationconsolidationserverlistDirective } from '@app/directives/recommendationconsolidationserverlist.directive';


@Component({
  selector: 'app-recommendationconsolidation',
  templateUrl: './recommendationconsolidation.component.html',
  styleUrls: ['./recommendationconsolidation.component.css']
})
export class RecommendationconsolidationComponent implements OnInit {

  @ViewChild(RecommendationconsolidationserverlistDirective) addConsolidationServerList: RecommendationconsolidationserverlistDirective;

  message: Message;

  reco_meta_data: any = [];

  reco_servers_data: RecoServer[] = [];

  consolidate_server: RecoServer = {
        name: '',
        uuid: '',
	type: 'SERVER',
	tools_install: 0,
	tools_update: 0,
	tools_enable: 0,
	vm_nb: 0,
	avg_cpu_cons: 0,
	avg_ram_cons: 0,
	over_cpu: 0,
	over_ram: 0,
	isStop: false
  };

  consolidation_cpu_class: string = 'progress info';

  consolidation_ram_class: string = 'progress info';

  consolidate_cpu_class: string = 'progress info';

  consolidate_ram_class: string = 'progress info';

  isModalRecoServerList: boolean = false;

  test: boolean = true;

  reco_easyvirt: boolean = false;

  filter_srv: RecoServer[] = [];

  nb_server_stop: number = 1;
  
  nb_server_stop_consolidated: number = 1;

  private currentUser: User;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private management_svc: ManagementService,
        private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

        this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.recoServerStop = [];

	this.getData();
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  consolidate(): void {

	this.test = true;
	this.message.genericInfoType = 'info';
	this.message.genericInfo = 'filter ' + this.message.currentFilter + ' processing ...';
	this.message.isNoInfo = false;
	this.message.waiting = true;
	this.json_svc.cleanJson(this.currentUser.login, this.message.currentFilter).subscribe(
		success => {
	  		this.json_svc.checkJson(this.currentUser.login, this.message.currentFilter).subscribe(
				complete => {
					this.message.genericInfo = 'filter ' + this.message.currentFilter + ' is ready to use';
					this.message.isNoInfo = false;
					this.message.waiting = false;
					this.getData();
				},
				error => {
	  				if(error != null) {
						this.message.isNoInfo = true;
						this.message.waiting = false;
	  					console.log(error);
	  				}
				}
	  		);
	  	},
                error => {
                        console.log(error);
	  	}
	  );
  }

  updateConsolidate(): void {

	if(this.reco_easyvirt)
		this.consolidate_server = this.filterRecoServer('xwrdm');
	else
		this.consolidate_server = this.filterRecoServer('xdm');

	this.setConsolidateClass();
  }

  loadServerList(): void {
	setTimeout(() => this.loadConsolidationServerList(), 100);
  }

  updateServerStop(): void {

	this.test = false;
	let srv_tmp : RecoServerStop = {
		user: this.currentUser.login,
		filter: this.message.currentFilter,
		uuid: '',
		servers: Math.ceil(this.nb_server_stop)
	};
	this.management_svc.updateNbStop(srv_tmp).pipe(first()).subscribe(
		error => {
			if(error != null)
				console.log(error)
		}
	);
  }

  private getData(): void {

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_META).subscribe(
		data => {
			this.reco_meta_data = data;
			this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_SERVER).subscribe(
	  			data => {
					this.reco_servers_data = data;
	  				this.buildData();
      				}
			);
      		}
	);
  }

  private buildData(): void {

	/*this.management_svc.getServerStop(this.currentUser.login, this.message.currentFilter).pipe(first()).subscribe(
		data => {
			if(data.length > 0) {
				for(var i = 0; i < data.length; i++) {
					let reco_srv: RecoServer = this.filterRecoServer(data[i].uuid);
					reco_srv.isStop = true;
					this.message.recoServerStop.push(reco_srv);
				}
	  		} else {
				// TODO DEFINE A SERVER TO STOP
				console.log('no server stopped');
			}
		},
		error => {
			if(error != null)
				console.log(error)
		}
	);
	if(this.message.recoServerStop.length > 0) {
		if((this.reco_servers_data.length-this.message.recoServerStop.length) >= 1)
			this.test = true;
	}*/
	this.management_svc.getNbStop(this.currentUser.login, this.message.currentFilter).pipe(first()).subscribe(
		data => {
			if(data.length > 0) {
				this.nb_server_stop = data[0].p1;
				this.nb_server_stop_consolidated = this.nb_server_stop;
			}
		},
		error => {
			if(error != null)
				console.log(error)
		}
	);

	this.consolidate_server = this.filterRecoServer('xdm');

	//MANAGE CSS CLASS
	//FOR THE AVERAGE SERVER
	if(this.reco_meta_data.over_ram_cons > 50 && this.reco_meta_data.over_ram_cons < 81)
  		this.consolidation_ram_class = 'progress top warning';
	else if(this.reco_meta_data.over_ram_cons > 80)
  		this.consolidation_ram_class = 'progress top danger';

	if(this.reco_meta_data.over_cpu_cons > 50 && this.reco_meta_data.over_cpu_cons < 81)
  		this.consolidation_cpu_class = 'progress top warning';
	else if(this.reco_meta_data.over_cpu_cons > 80)
		this.consolidation_cpu_class = 'progress top danger';

	//FOR THE CONSOLIDATE AVERAGE SERVER
	this.setConsolidateClass();
  }

  private setConsolidateClass(): void {

  	this.consolidate_cpu_class = 'progress info';
	this.consolidate_ram_class = 'progress info';

	if(this.consolidate_server.avg_ram_cons > 50 && this.consolidate_server.avg_ram_cons < 81)
  		this.consolidate_ram_class = 'progress top warning';
	else if(this.consolidate_server.avg_ram_cons > 80)
  		this.consolidate_ram_class = 'progress top danger';

	if(this.consolidate_server.avg_cpu_cons > 50 && this.consolidate_server.avg_cpu_cons < 81)
  		this.consolidate_cpu_class = 'progress top warning';
	else if(this.consolidate_server.avg_cpu_cons > 80)
  		this.consolidate_cpu_class = 'progress top danger';
  }

  private filterRecoServer(uuid: string): RecoServer {
	return this.reco_servers_data.find(reco => reco.uuid === uuid);
  }

  private loadConsolidationServerList(): void {

	if(this.addConsolidationServerList != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RecommendationconsolidationserverlistComponent);
		const viewContainerRef = this.addConsolidationServerList.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }
}

<div class="clr-row" style="min-width: 1200px">
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12" style="text-align:center">
        <label class="clr-control-label" style="font-size:inherit">
            Details of allocation & consumption on :<br>
            {{capaplan_lastdate_format}}
        </label>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
        <clr-radio-container clrInline style="margin-left:20%;margin-top:0px;">
            <label style="font-size:inherit">Simulation option :</label>
            <clr-radio-wrapper data-toggle="tooltip" data-placement="bottom" title="Disaster Recovery : only VM with the desired HA level">
                <input type="radio" clrRadio name="capaplan_simulation_option" required [value]=1 [(ngModel)]="capaplan_simulation_option"/>
                <label>DR</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper  data-toggle="tooltip" data-placement="bottom" title="Business Continuity Plan : all VM hosted on available servers & only VM with the desired HA level hosted on removed servers">
                <input type="radio" clrRadio name="capaplan_simulation_option" required [value]=0 [(ngModel)]="capaplan_simulation_option"/>
                <label>BCP</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper  data-toggle="tooltip" data-placement="bottom" title="Filter : only VM selected in the filter">
                <input type="radio" clrRadio name="capaplan_simulation_option" required [value]=2 [(ngModel)]="capaplan_simulation_option"/>
                <label>Filter</label>
            </clr-radio-wrapper>
        </clr-radio-container>
    </div>
    <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
        <clr-range-container [clrRangeHasProgress]="true" style="margin-left:20%;margin-top:0px;">
            <label style="font-size:inherit">HA Priority :</label>
            <input name="haslider" type="range" clrRange [(ngModel)]="capaplan_haslider_level" min="0" max="5" step="1" (change)="hasliderChange($event.target.value)"/>
            <clr-control-helper style="color:black;">
                <span style="float:left;">{{capaplan_haslider_label}}</span>
                <span style="float:right;">[{{capaplan_haslider_label_totalvm}} VM]</span>
            </clr-control-helper>
        </clr-range-container>
    </div>
</div>

<div class="clr-row" style="min-width: 1200px">
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <app-capares [target]="cap" [infra]="infraSubject.asObservable()" [simu]="simuSubject.asObservable()"></app-capares>
    </div>
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <app-capares [target]="cons" [infra]="infraSubject.asObservable()" [simu]="simuSubject.asObservable()"></app-capares>
    </div>
</div>
<div class="clr-row" style="min-width: 1200px">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
        <div class="card" >
            <div class="card-header card-ajust">
                <h5>
                    <clr-icon shape="slider"></clr-icon>&nbsp;Simulation
                    <button type="button" style="float:right;" class="btn btn-default btn-sm" (click)="generateReport()"><clr-icon shape="download"></clr-icon> Export Informations</button>
                    <button type="button" style="float:right;" class="btn btn-primary btn-sm" (click)="runSimulation()"><clr-icon shape="play"></clr-icon> Run Simulation</button>
                </h5>
            </div>
            <div class="card-block" style="padding-top: 0;">
                <div class="clr-row">
                    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                        <app-capaentity [target]="vm" [config]="configSubject.asObservable()"></app-capaentity>
                    </div>
                   <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                        <app-capaentity [target]="host" [config]="configSubject.asObservable()"></app-capaentity>
                    </div>
                    <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
                        <app-capaentity [target]="ds" [config]="configSubject.asObservable()"></app-capaentity>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL INFO -->
<clr-modal [(clrModalOpen)]="info_modal" [clrModalClosable]=false [clrModalSize]="'sm'">
    <h3 class="modal-title">{{info_header}}</h3>
    <div class="modal-body" style="min-height:100px;">
        <div style="width:100%;overflow:auto;">
            {{info_body}}
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="info_modal = false">Ok</button>
    </div>
</clr-modal>

<div class="clr-row">
	<div [ngClass]="col_class">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<clr-icon shape="bar-chart"></clr-icon>
					&nbsp;Activity evolution
				</h5>
			</div>
			<div class="card-block">
				<div class="card-text">
					<highcharts-chart
						[Highcharts]="highcharts"
						[options]="chartOptions"
						[constructorType]="'chart'"
						style="width: 100%; height: 350px; display: block;" *ngIf="isReady">
					</highcharts-chart>
                		</div>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="isTable">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					{{table_title}}
				</h5>
				<span class="export"></span>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search"></span>
			</div>
			<div class="card-block">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<ng-template load-kpi-table></ng-template>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="hideTable()">
					HIDE TABLE
				</button>
			</div>
		</div>
	</div>
</div>

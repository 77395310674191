import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-wizard-period-calendar]'
})
export class WizardperiodcalendarDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[cloud-kpi-savingsplan]'
})
export class CloudkpisavingsplanDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

<app-account-management [targetInfrastructure]="this.message.cloudProvider" (loadRegion)="loadRegion()" *ngIf="isAccountMgtReady"></app-account-management>
<clr-tabs>
	<clr-tab>
        <button clrTabLink (click)="setVmware()">VMWARE</button>
		<clr-tab-content *clrIfActive="true">
            <app-recommendation></app-recommendation>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="setAws()">AWS</button>
		<clr-tab-content>
            <app-opportunities-cloud [isreload]="isDataReload" *ngIf="isReady == true"></app-opportunities-cloud>
            <div *ngIf="!this.message.isValidAws">
                <div class="card">
                        <div class="card-block">
                                <div class="card-text">
                                        <div>
                                                <strong class="center">No data available !</strong>
                                        </div>
                                </div>
                        </div>
                </div>
            </div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="setAzure()">AZURE</button>
		<clr-tab-content>
            <app-opportunities-cloud [isreload]="isDataReload" *ngIf="isReady == true"></app-opportunities-cloud>
            <div *ngIf="!this.message.isValidAzure">
                <div class="card">
                        <div class="card-block">
                                <div class="card-text">
                                        <div>
                                                <strong class="center">No data available !</strong>
                                        </div>
                                </div>
                        </div>
                </div>
            </div>
        </clr-tab-content>
    </clr-tab>
</clr-tabs>
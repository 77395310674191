import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import * as CryptoJS from 'crypto-js';

import { environment } from '@environments/environment';
import { Alias, AliasRec, ConnectType, Role, User, UserRec } from '@app/model';
import { FormGroup } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class AccountService {
 
  init_user: User = {
    login: '',
    alias: '',
    currency: '',
    role: '',
    token: '',
    last_co: 0,
    email: '',
    isMenuCloud: false,
    isMenuKpi: false,
    isMenuAlert: false,
    isMenuTrouble: false,
    isMenuCapa: false,
    isMenuGod: false,
    isMenuReco: false,
    isMenuReport: false,
    isMenuGreenit: false,
    isMenuDCNetScope: false,
    isMenuVcloud: false,
    isMenuVirtindex: false,
    isMenuExternalCloud: false,
    isDashboardT1: false,
    isDashboardT2: false,
    isDashboardT3: false,
    isDashboardT4: false,
    isDashboardT5: false,
    isDashboardT6: false
  };

  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(this.init_user);
  public user: Observable<User> = this.currentUserSubject.asObservable();

  constructor(private http: HttpClient) {}

  public get currentUserValue(): User {
	  return this.currentUserSubject.value;
  }

  public changeUser(user: User): void {
    this.currentUserSubject.next(user)
  }

  crypt(password: string): string {
    var iv = CryptoJS.enc.Utf8.parse('btrCloud');
    var key = CryptoJS.enc.Hex.parse('bcb04b7e103a0cd8b57853051cdd08bc55abe029fbabae5e1d417e2ffb2a00a3');
    let hash = CryptoJS.AES.encrypt(password, key, {
        mode: CryptoJS.mode.CBC,
        iv: iv,
        padding: CryptoJS.pad.Pkcs7
    }).toString();

    return hash;
  }

  login(login: string, password: string, type: ConnectType) {
    return this.http.post<User>(`${environment.apiUrl}/` + 'login',
      {
        "username": login, 
        "password": password,
        "type": type
      }
    ).pipe(map(user => {
      localStorage.setItem('user', JSON.stringify(user));
      this.currentUserSubject.next(user);
      return user;
    }));
  }

  confirmAlias(alias: string, user: string) {
    return this.http.post<User>(`${environment.apiUrl}/` + 'postlogin', {
        "alias": alias,
        "user": user,
      }).pipe(map(
      user => {
        // XXX Keep token obtained previously
        let token = this.init_user.token;
        let user_data = user;
        user_data.token = token;
        localStorage.setItem('user', JSON.stringify(user_data));
        this.currentUserSubject.next(user_data);
        return user_data;
      }
    ));
  }

  logout():void {
    this.init_user.login = "";
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    this.currentUserSubject.next(this.init_user);
  }

  reload() {
    if(JSON.parse(localStorage.getItem('user')) != null) {
      if(JSON.parse(localStorage.getItem('user')).login != "") {
        //this.init_user.uuid =  JSON.parse(localStorage.getItem('user')).uuid;
        this.currentUserSubject.next(JSON.parse(localStorage.getItem('user')));
        //alert(this.currentUserSubject.value.uuid);
      }
    }
  }

  private checkToken() {
  	return this.http.get(`${environment.apiUrl}/` + 'api/token_valid', {responseType: 'text'});
  }

  async isLogged(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.checkToken().pipe(first()).subscribe(
        success => {
          resolve(true);
        },
        error => {
          resolve(false);
        }
      );
    })
  }

  // ALIAS PART
  addAlias(alias: AliasRec) {
    return this.http.post<any>(`${environment.apiUrl}/` + 'addalias', alias).pipe(map(result => {
      return result;
    }));
  }

  getAliasList() {
    return this.http.get<Alias[]>(`${environment.apiUrl}/` + 'getaliases').pipe(map(result => {
      return result;
    }));
  }

  getAliasUser(user: User) {
    return this.http.post<Alias[]>(`${environment.apiUrl}/` + 'getaliasuser', [user.login]).pipe(map(result => {
      return result;
    }));
  }

  updateAlias(alias: AliasRec) {
    return this.http.put<any>(`${environment.apiUrl}/` + 'updatealias', alias).pipe(map(result => {
      return result;
    }));
  }

  deleteAlias(alias: Alias) {
    return this.http.put<any>(`${environment.apiUrl}/` + 'delalias', alias).pipe(map(result => {
      return result;
    }));
  }

  // USER PART
  getUserList() {
    return this.http.get<User[]>(`${environment.apiUrl}/` + 'users').pipe(map(user => {
      return user;
    }));
  }

  addUser(user: UserRec) {
    return this.http.post<any>(`${environment.apiUrl}/` + 'adduser', user).pipe(map(result => {
      return result;
    }));
  }

  checkUser(user: string, mode: string) {
    return this.http.post<any>(`${environment.apiUrl}/` + 'checkuser', [user, mode]).pipe(map(result => {
      return result;
    }));
  }

  updateUser(user: UserRec) {
    return this.http.put<any>(`${environment.apiUrl}/` + 'updateuser', user).pipe(map(result => {
      return result;
    }));
  }

  updateCurrencyUser(rate: number) {
    return this.http.post<any>(`${environment.apiUrl}/` + 'updatecurrency', [rate]).pipe(map(result => {
      return result;
    }));
  }

  deleteUser(user: User) {
    return this.http.put<any>(`${environment.apiUrl}/` + 'deluser', user).pipe(map(result => {
      return result;
    }));
  }

  // ROLE PART
  checkUserRole(uuid: string) {
    return this.http.get<UserRec[]>(`${environment.apiUrl}/checkuserrole/` + uuid).pipe(map(names => {
      return names;
    }));
  }
  
  getRoleList() {
    return this.http.get<Role[]>(`${environment.apiUrl}/` + 'roles').pipe(map(role => {
      return role;
    }));
  }

  addRole(role: Role) {
    return this.http.post<any>(`${environment.apiUrl}/` + 'addrole', role).pipe(map(result => {
      return result;
    }));
  }

  updateRole(role: Role) {
    return this.http.put<any>(`${environment.apiUrl}/` + 'updaterole', role).pipe(map(result => {
      return result;
    }));
  }

  deleteRole(role: Role) {
    return this.http.put<any>(`${environment.apiUrl}/` + 'delrole', role).pipe(map(result => {
      return result;
    }));
  }

  // AD PART
  getADSettings() {
    return this.http.get(`${environment.apiUrl}/` + 'adsettings/').pipe(map(list => {
      return list;
    }));
  }

  getADInfo(form: FormGroup) {
    return this.http.post(`${environment.apiUrl}/` + 'adinfos/', [form.value.login, this.crypt(form.value.password)]);
  }

}

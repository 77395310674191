import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import { JsonloaderService, ShareService } from '@app/services';

import { Json, Message } from '@app/model';


@Component({
  selector: 'app-hostinfo',
  templateUrl: './hostinfo.component.html',
  styleUrls: ['./hostinfo.component.css']
})
export class HostinfoComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject();

  jsonLoader: Json;

  message: Message;

  data_hosts: any = [];

  math = Math;


  constructor(
	private router: Router,
	private json_svc: JsonloaderService,
	private message_svc: ShareService
  ) {
  }

  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();
	$( "dt-buttons" ).remove();

	this.initDtOptions();

	this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
	this.data_hosts = this.jsonLoader.hostInfo;
	if(this.data_hosts.length > 0)
		this.reloadTable();

	this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  ngAfterViewInit(): void {
	this.dtTrigger.next();
  }

  ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
  }

  callroute(host): void {
	
	this.message.currentUuid = host[1];
	this.message.currentName = host[0];
	this.message.currentType = 'SERVER';
	this.router.navigate(['/hostviews/synthesis']);
  }

  private reloadTable(): void {

	  if(this.dtElement.dtInstance != undefined) {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			$( "div.dataTables_filter" ).remove();
	  		$( "dt-buttons" ).remove();
                	dtInstance.destroy();
                	this.dtTrigger.next();
          	});
	  }
  }

  private initDtOptions(): void {

	this.dtOptions = {
		pagingType: 'full_numbers',
	  	pageLength: 10,
		lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
		processing: true,
	  	destroy: true,
		initComplete: function() {
	  		$('div.dataTables_filter').appendTo('span.search');
			$('div.dt-buttons').appendTo('span.export');
	  		$("[id*='DataTables_Table_']").css({'padding-top':'5px'});
	  		$("[name*='DataTables_Table_']").css({'padding':'0px'});
	  	},
	  	language: {
	  		search: '',
	  		searchPlaceholder: " search..."
	  	},
	  	dom: '<"top"B><"clear">frtlip',
	  	buttons: [
	  		{ extend: 'csv', className: 'btn btn-link btn-sm' },
	  		{ extend: 'copy', className: 'btn btn-link btn-sm' },
	  		{ extend: 'print', className: 'btn btn-link btn-sm' }
	  	]
	};
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { COST, FilterActivation, FilterDelete, FilterMgt, SelectionFilter, SelectionFilterRec, ElementView, RecoServerStop, TimeFilter, IhmSettings, VM_COST, DS_COST, SERVER_COST, TagCost, TagCostElement, TagCostRec, TagCostMin, COST_UPDATE } from '@app/model';
import { deprecate } from 'util';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  constructor(private http: HttpClient) { }

  getUserSelection(user: string, filter: string) {
	return this.http.get<SelectionFilter>(`${environment.apiUrl}/` + 'management/findselection/' + user + '&' + filter).pipe(map(selection => {
		return selection;
	}));
  }

  checkUserSelection(selections: SelectionFilterRec[]) {
	return this.http.post<any>(`${environment.apiUrl}/management/` + 'checkuserselection', selections).pipe(map(result => {
		return result;
	}));
  }
  
  remSelection(user: string, filter: string) {
	return this.http.put<SelectionFilter>(`${environment.apiUrl}/` + 'management/remselection/' + user + '&' + filter, [user, filter]);
  }

  addSelection(user: string, filter: string, name: string, type: string, uuid: string, flag: number, resource: string) {
	return this.http.post<SelectionFilter>(`${environment.apiUrl}/` + 'management/addselection/' + user + '&' + filter + '&' + name + '&' + type + '&' + uuid + '&' + flag + '&' + resource, [user, filter, name, type, uuid, flag, resource]);
  }

  addSelectionList(selections: SelectionFilterRec[]) {
	  return this.http.post<any>(`${environment.apiUrl}/management/` + 'addselections', selections).pipe(map(result => {
		  return result;
	  }));
  }

  addSelectionList0(selections: SelectionFilterRec[]) {
	  return this.http.post<any>(`${environment.apiUrl}/management/` + 'addselections0', selections).pipe(map(result => {
		  return result;
	  }));
  }

  updSelection(user: string, filter: string, name: string, type: string, uuid: string, flag: number) {
	return this.http.post<SelectionFilter>(`${environment.apiUrl}/` + 'management/updselection/' + user + '&' + filter + '&' + name + '&' + type + '&' + uuid + '&' + flag, [user, filter, name, type, uuid, flag]);
  }

  remElement(user: string, filter: string, uuid: string) {
	return this.http.put<SelectionFilter>(`${environment.apiUrl}/` + 'management/remelement/' + user + '&' + filter + '&' + uuid, [user, filter, uuid]);
  }

  getElementView(user: string) {
	return this.http.get<ElementView[]>(`${environment.apiUrl}/` + 'management/findelementview/' + user).pipe(map(view => {
		return view;
	}));
  }

  getTimeViewAll(user: string) {
	return this.http.get<TimeFilter>(`${environment.apiUrl}/` + 'management/findtimeviewall/' + user).pipe(map(view => {
		return view;
	}));
  }

  addTimeView(user: string, filter: string, begin: string, end: string) {
	return this.http.post<TimeFilter>(`${environment.apiUrl}/` + 'management/addtimeview/' + user + '&' + filter + '&' + begin + '&' + end, [user, filter, begin, end]);
  }

  getTimeView(user: string) {
	return this.http.get<TimeFilter[]>(`${environment.apiUrl}/` + 'management/findtimeview/' + user).pipe(map(view => {
		return view;
	}));
  }

  updTimeView(user: string, filter: string, begin: string, end: string) {
	return this.http.post<SelectionFilter>(`${environment.apiUrl}/` + 'management/updtimefilter/' + user + '&' + filter + '&' + begin + '&' + end, [user, filter, begin, end]);
  }

  remTimeFilter(user: string, filter: string) {
	return this.http.put<TimeFilter>(`${environment.apiUrl}/` + 'management/remtimefilter/' + user + '&' + filter, [user, filter]);
  }

  getFilterList(user: string) {
	return this.http.get<FilterMgt[]>(`${environment.apiUrl}/` + 'management/findfilterlist/' + user).pipe(map(filter => {
		return filter;
	}));
  }

  getFullFilterList(user: string) {
	return this.http.get<FilterMgt[]>(`${environment.apiUrl}/` + 'management/findfullfilterlist/' + user).pipe(map(filter => {
		return filter;
	}));
  }

  getSharedFilterList() {
	return this.http.get<FilterMgt[]>(`${environment.apiUrl}/` + 'management/findsharedfilterlist/').pipe(map(filter => {
		return filter;
	}));
  }

  getUsersFilterList() {
	return this.http.get<FilterMgt[]>(`${environment.apiUrl}/` + 'management/findusersfilterlist/').pipe(map(filter => {
		return filter;
	}));
  }

  getViewsFromSharedFilter(user: string) {
	return this.http.get<any>(`${environment.apiUrl}/` + 'management/findviewsfromsharedfilter/' + user).pipe(map(view => {
		return view;
	}));
  }

  testSharedFilter(user: string, ev: string) {
	return this.http.get<any>(`${environment.apiUrl}/` + 'management/testsharedfilter/' + user + '&' + ev).pipe(map(test => {
		return test;
	}));
  }

  getFilterFromId(id: string) {
	return this.http.get<FilterMgt>(`${environment.apiUrl}/` + 'management/findfilterfromid/' + id).pipe(map(filter => {
		return filter;
	}));
  }

  addSymLink(user: string, filter: string) {
      return this.http.post(`${environment.apiUrl}/` + 'management/addsymboliclink/', [user, filter]);
  }

  unLink(user: string, filter: string) {
      return this.http.post(`${environment.apiUrl}/` + 'management/unlink/', [user, filter]);
  }

  updFilter(user: string, filter: string, id: number, element: string, time: string, wd: boolean, share: boolean) {
    const replacer = (key, value) => (value == null) ? undefined : value;
    let settings = {
      user: user,
      filter: filter,
      id: id,
      element: element,
      time: time,
      wd: wd,
      share: share
    };
    return this.http.post<any>(`${environment.apiUrl}/` + 'management/updfilter/', JSON.parse(JSON.stringify(settings,replacer)));
  }

  addFilter(user: string, filter: string, element: string, time: string, wd: boolean, share: boolean) {
	return this.http.post<SelectionFilter>(`${environment.apiUrl}/` + 'management/addfilter/' + user + '&' + filter + '&' + element + '&' + time + '&' + wd + '&' + share, [user, filter, element, time, wd, share]);
  }

  remFilter(filter: FilterDelete) {
	return this.http.put<any>(`${environment.apiUrl}/` + 'management/remfilter', filter).pipe(map(result => {
                  return result;
    	}));
  }

  enableFilter(filter: FilterActivation) {
	return this.http.post<SelectionFilter>(`${environment.apiUrl}/` + 'management/enablefilter', filter);
  }

  // RECOMMENDATION PART
  getServerStop(user: string, filter: string) {
	return this.http.get<RecoServerStop[]>(`${environment.apiUrl}/` + 'management/findserverstop/' + user + '&' + filter).pipe(map(uuids => {
		return uuids;
	}));
  }

  getNbStop(user: string, filter: string) {
        return this.http.get<any>(`${environment.apiUrl}/` + 'management/findnbserverstop/' + user + '&' + filter).pipe(map(uuids => {
                return uuids;
        }));
  }

  updateNbStop(reco_srv: RecoServerStop) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatenbserverstop', reco_srv).pipe(map(result => {
                return result;
	}));
  }
  
  addServerStop(reco_srv: RecoServerStop) {
	return this.http.post<any>(`${environment.apiUrl}/management/` + 'addserverstop', reco_srv).pipe(map(result => {
    return result;
	}));
  }

  delServerStop(reco_srv: RecoServerStop) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'remserverstop', reco_srv).pipe(map(result => {
    return result;
  }));
  }

  // COST PART
  getCost(user: string) {
	return this.http.get<COST>(`${environment.apiUrl}/management/` + 'findcost/' + user).pipe(map(cost => {
		return cost;
	}));
  }

  updateCost(cost: COST) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatecost', cost).pipe(map(result => {
                return result;
	}));
  }

  getVmCost(user: string) {
	return this.http.get<VM_COST[]>(`${environment.apiUrl}/management/` + 'findvmcost/' + user).pipe(map(cost => {
		return cost;
	}));
  }

  updateVmCost(vm_cost: VM_COST) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatevmcost', vm_cost).pipe(map(result => {
                return result;
	}));
  }

  updateVmCosts(vms_cost: any) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatevmcosts', vms_cost).pipe(map(result => {
                return result;
	}));
  }

  getStorageCost(user: string) {
	return this.http.get<DS_COST[]>(`${environment.apiUrl}/management/` + 'findstocost/' + user).pipe(map(cost => {
		return cost;
	}));
  }

  updateStorageCost(ds_cost: DS_COST) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatestocost', ds_cost).pipe(map(result => {
                return result;
	}));
  }

  updateStorageCosts(dss_cost: any) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatestocosts', dss_cost).pipe(map(result => {
                return result;
	}));
  }

  getServerCost(user: string) {
	return this.http.get<SERVER_COST[]>(`${environment.apiUrl}/management/` + 'findsrvcost/' + user).pipe(map(cost => {
		return cost;
	}));
  }

  updateServerCost(srv_cost: SERVER_COST) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatesrvcost', srv_cost).pipe(map(result => {
                return result;
	}));
  }

  updateServerCosts(srvs_cost: any) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatesrvcosts', srvs_cost).pipe(map(result => {
                return result;
	}));
  }

  listTagCost() {
	return this.http.get<TagCost[]>(`${environment.apiUrl}/management/` + 'listtagcosts').pipe(map(tagcosts => {
		return tagcosts;
	}));
  }

		/*addTagCost(tagcosts: TagCostRec[]) {
	  return this.http.post<any>(`${environment.apiUrl}/management/` + 'addtagcosts', tagcosts).pipe(map(result => {
		  return result;
	  }));
		  }*/

  addTagCost(tagcost: TagCost) {
	  return this.http.post<any>(`${environment.apiUrl}/management/` + 'addtagcosts', tagcost).pipe(map(result => {
		  return result;
	  }));
  }

  addTagCostElement(tagcostels: TagCostElement[]) {
	  return this.http.post<any>(`${environment.apiUrl}/management/` + 'addtagcostelements', tagcostels).pipe(map(result => {
		  return result;
	  }));
  }

  delTagCost(taguuid: string) {
		  //return this.http.get<any>(`${environment.apiUrl}/management/` + 'deltagcosts', taguuid).pipe(map(result => {
	return this.http.get<any>(`${environment.apiUrl}/management/` + 'deltagcosts/' + taguuid).pipe(map(result => {
		  return result;
	}));
  }

  updateTagCost(tagcost: TagCost) {
	  return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatetagcosts', tagcost).pipe(map(result => {
		  return result;
	  }));
  }

  getTagCost(taguuid: string) {
	return this.http.get<TagCost>(`${environment.apiUrl}/management/` + 'findtagcost/' + taguuid).pipe(map(tagcost => {
		return tagcost;
	}));
  }

  getTagCostElement(taguuid: string) {
	return this.http.get<TagCostElement[]>(`${environment.apiUrl}/management/` + 'findtagcostelements/' + taguuid).pipe(map(tagcostel => {
		return tagcostel;
	}));
  }

  getTagsVm(vmuuid: string) {
	return this.http.get<TagCostMin[]>(`${environment.apiUrl}/management/` + 'findtagsvm/' + vmuuid).pipe(map(tagcost => {
		return tagcost;
	}));
  }

  delTagCostElement(tagcostel: TagCostElement) {
	  return this.http.put<any>(`${environment.apiUrl}/management/` + 'deltagcostelement', tagcostel).pipe(map(result => {
		  return result;
	  }));
  }

  updateGenericCpuCost(cost: COST) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updategenericcpucost', cost).pipe(map(result => {
                return result;
	}));
  }

  updateGenericStoCost(cost: COST) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updategenericstocost', cost).pipe(map(result => {
                return result;
	}));
  }

  updateGenericSrvCost(cost: COST) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updategenericsrvcost', cost).pipe(map(result => {
                return result;
	}));
  }

  getCostInfo() {
	return this.http.get<COST_UPDATE>(`${environment.apiUrl}/management/` + 'getcostinfo').pipe(map(costinfo => {
		return costinfo;
	}));
  }

  updateCostInfo(costinfo: COST_UPDATE) {
	return this.http.put<any>(`${environment.apiUrl}/management/` + 'updatecostinfo', costinfo).pipe(map(result => {
                return result;
	}));
  }

  getRegions(provider: string) {
	return this.http.get<any>(`${environment.apiUrl}/` + 'management/getregions/' + provider).pipe(map(regions => {
		return regions;
	}));
  }
}

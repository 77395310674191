import { Component, OnInit } from '@angular/core';

import { ClrDatagridSortOrder } from '@clr/angular';

import { JSONTarget, Message } from '@app/model';

import { JsonloaderService, ShareService } from '@app/services';


@Component({
  selector: 'app-opportunities-spot',
  templateUrl: './opportunities-spot.component.html',
  styleUrls: ['./opportunities-spot.component.css']
})
export class OpportunitiesSpotComponent implements OnInit {

  message: Message;

  spots: any = [];

  dataOrder = ClrDatagridSortOrder.ASC;


  constructor(
    private json_svc: JsonloaderService,
    private message_svc: ShareService
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);
    let account = this.message.cloudAccount;
    let region_str: string = '';
    region_str = this.message.cloudRegion.replace(/-/g,"");

    this.json_svc.getData('root','cloud_' + account + '_' + region_str, JSONTarget.CLOUD_OPP_SPOT).subscribe(
      data => {
        this.spots = data;
      }
    );
  }

  exportSpots() {

    let res = ["ID", "Name", "Created with same name", "Compute cost($)", "Spot cost($)", "Estimated compute cost year ($/hour)", "Estimated spot cost year ($/hour)", "Gain (%)"].join(',') + '\n';
                res += Object.values<any>(this.spots).map(spot =>
                        [spot.id, spot.name, spot.iter, spot.computeCost, spot.spotCost, spot.computeCostYear, spot.spotCostYear, spot.gain].join(",")
                ).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'opportunities_spot.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }
}

import { Component, OnInit, Input, HostListener } from '@angular/core';
import { first } from 'rxjs/operators';

import { JSONTarget, User, Message } from '@app/model';

import { AccountService, GreenitService, JsonloaderService, ManagementService, ShareService } from '@app/services';

import { Resource, GreenitTarget, DefaultValues } from '../greenit.enums';

import * as Highcharts from 'highcharts';

import * as moment from 'moment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-greenitcf',
  templateUrl: './greenitcf.component.html',
  styleUrls: ['./greenitcf.component.css']
})
export class GreenitcfComponent implements OnInit {

  @Input() target: GreenitTarget;
  @Input() nbHost: boolean;
  @Input() showStackLabel: boolean;
  @Input() allowReload: boolean;

  message: Message;

  private currentUser: User;

  needReload: boolean = false;

  settings_subscription: Subscription;

  greenit_alert: boolean = false;
  greenit_nodata: boolean = false;

  private greenit_data: any;
  private greenit_data_nbhost: any;
  private greenit_clouddata: any = [];

  private greenit_resource: Resource;

  private greenit_color = ['#7cb5ec', '#95959d', '#39ac73', '#a47d7c', '#ff6666', '#946ac8' ];
  private greenit_grey_color = ['#476888', '#434348', '#26734d', '#694a49', '#b30000', '#613795'  ];

  Highcharts: typeof Highcharts = Highcharts;

  /**
  * PIE CHART
  */
  chart2: Highcharts.Chart | null;
  chartOptions: Highcharts.Options = {};

  isPieReady: boolean = false;

  /**
  * CF CHART
  */
  chart: Highcharts.Chart;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;
    
    //Stacked column
    this.chart.update({ plotOptions: {column: { stacking: 'normal', dataLabels: { enabled: false  } }} });
    this.chart.update({ yAxis: {min: 0, stackLabels: { enabled: this.showStackLabel == undefined ? true : false, style: { fontWeight: 'bold', color:'gray' } }} });

    this.chart.showLoading();
  };

  options: Highcharts.Options = {
    chart: {
        height: '250px',
        backgroundColor: 'rgba(255, 255, 255, 0.0)'
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    time: {
        useUTC: false
    },
    title: {
        text: ''
    },
    xAxis: {
        type: 'datetime',
        labels: {
            formatter: function() {
                return Highcharts.dateFormat('%m-%Y',this.value);
            }
        },
    },
    yAxis: {
        min: 0,
        labels: {
            align: 'right',
            x: -10
        },
        title: {
            text: ''
        }
    },
    tooltip: {
        borderColor: 'black',
        formatter: function() {
            return `<span style="color: ${this.color}">•</span> ${this.series.name}: <b>${this.y}</b>`;
        }
    }
  }

  /**
  * STORAGE & NETWORK EXTRAPOLATION
  */
  host_ratio: number = DefaultValues.HOST_EXTRAPOLATION_RATIO;
  storage_ratio: number = DefaultValues.STORAGE_EXTRAPOLATION_RATIO;
  network_ratio: number = DefaultValues.NETWORK_EXTRAPOLATION_RATIO;


  /**
   * 
   */
  constructor(private authenticationService: AccountService, private greenit_svc: GreenitService,
    private json_svc: JsonloaderService, private mgt_svc: ManagementService, private message_svc: ShareService) { }

  /**
   * 
   */ 
  async ngOnInit(): Promise<void> {

    this.greenit_data = [];

    this.authenticationService.user.subscribe(user => this.currentUser = user);
    this.message_svc.currentMessage.subscribe(message => this.message = message);

   // GET SETTINGS (async)
    try {
      let settings_subscribe = await this.greenit_svc.getGreenitSettings().toPromise();
      this.updateDataSettings(settings_subscribe);
    } catch {
      // Nothing, already on console
    }

    // LOAD DATA
    this.load(false);


    // Need subscription to GreenitService to detect settings changes & reload extrapolation
    if(this.allowReload) {
        this.settings_subscription = this.greenit_svc.currentSettings.subscribe(
            data => {
                if(this.needReload){
                    this.updateDataSettings(data);
                    this.load(this.needReload);
                }
                this.needReload = true;
            }
        );
    }
        
  }

  /**
   * 
   */
  @HostListener('unloaded')
  ngOnDestroy(): void {
	  // Remove subscription
	  if (this.settings_subscription != undefined) {
	    this.settings_subscription.unsubscribe();
	  }
	  //console.log("destroyed greenitcf");
  }

  /**
   * 
   */
  load(reload: boolean): void {

    if(reload) {
        // Remove old series
        for(var i = this.chart.series.length - 1; i >= 0; i--) {
            this.chart.series[i].remove(false);
        }

        // Show loading
        this.chart.showLoading();
    }

    let current_target: GreenitTarget = this.target;

    // XXX GET GreenitTarget.DC_ALL_CO2 DATA
    if(this.target == GreenitTarget.ALL_INCLUSIVE_CO2 || this.target == GreenitTarget.STORAGE_CO2 || this.target == GreenitTarget.NETWORK_CO2)
        current_target = GreenitTarget.DC_ALL_CO2;

    this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter, current_target).subscribe(
        data => {
            this.greenit_data = data;

            this.greenit_nodata = false;

            if(this.greenit_data.length == 0)
                this.greenit_alert = true;

            switch(this.target) {
                case GreenitTarget.ALL_CO2:
                    this.greenit_resource = Resource.CO2;
                    // set data
                    this.setGlobalChartData();
                break;
                case GreenitTarget.DC_ALL_CO2:
                    this.greenit_resource = Resource.CO2;
                    // set data
                    this.setDCChartData();
                break;
                case GreenitTarget.DC_WH:
                    this.greenit_resource = Resource.WH;
                    // set data
                    this.setDCChartData();
                break;
                case GreenitTarget.DESKTOP_CO2:
                    this.greenit_resource = Resource.CO2;
                    // set data
                    this.setDesktopChartData();
                break;
                case GreenitTarget.ALL_INCLUSIVE_CO2:
                    this.greenit_resource = Resource.CO2;
                    // set data
                    this.setAwsData();
                break;
                case GreenitTarget.STORAGE_CO2:
                    this.greenit_resource = Resource.CO2;
                    // set data
                    this.setOtherChartData();
                break;
                case GreenitTarget.NETWORK_CO2:
                    this.greenit_resource = Resource.CO2;
                    // set data
                    this.setOtherChartData();
                break;
                default:
                    console.error("greenitcf.target not set properly !");
                break;
            }
        },
        error => {
            this.greenit_nodata = true;
            if(this.chart !== undefined) {
                this.chart.hideLoading();
            }
        }
    );

    // Build host data
    if(this.nbHost) {
        this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter,GreenitTarget.HOST_NB).subscribe(
            data => {
                this.greenit_data_nbhost = data;
                // set date
                this.setNbHostData();
            },
            error => {
                if(error != null)
                    console.log(error)
            }
        );
    }
  }

  /**
   * 
   */
  private setNbHostData(): void {

    // Init vars
    let times = [];    
    let nbhost = [];
     
     for (let i in this.greenit_data_nbhost) {
        if (this.greenit_data_nbhost[i] != undefined) {
            //XXX in order to show last month number (first day of month minus a day)
            let lastTime = this.greenit_data_nbhost[i].time;
            lastTime = parseInt(lastTime) - 24 * 3600* 1000;
            
            times[i] = parseInt(lastTime);
            nbhost[i] = parseFloat(this.greenit_data_nbhost[i].nbhost);
            
        }
     }
     
    // Add secondary Y-axis
    this.chart.addAxis({ id: 'yaxis-2', opposite: true, title: { text: "servers", }, }, false);

    // Push to chart
    this.chart.addSeries({
        type: 'spline',
        name: "Servers number",
        yAxis: 'yaxis-2',
        cursor: 'pointer',
        color: '#80699B',
        //dashStyle: this.linestyle,
        data: nbhost
    });

  }

  /**
   * 
   */
  private setGlobalChartData() : void {
    
    // Init vars
    let div = 1;
    let yname: string;
    if(this.greenit_resource == Resource.WH) {
        yname = "kWh";
        div = 1000;
    } else if(this.greenit_resource == Resource.CO2) {
        yname = "kg CO2eq";
    }

    // Init data arrays
    let times = [];
    let dc = [];
    //let network = [];
    let desktop = [];
          
    for (let i in this.greenit_data) {
        if (this.greenit_data[i] != undefined) {
            let lastTime = this.greenit_data[i].time;
            //XXX in order to show last month number (first day of month minus a day)
            lastTime = parseInt(lastTime) - 24 * 3600* 1000;
                      
            let lastdc = this.greenit_data[i].dc;
            //let lastNetwork = this.greenit_data[i].network;
            let lastDesktop = this.greenit_data[i].desktop;
                      
            lastdc = Number((parseFloat(lastdc) / div).toFixed(2));
            //lastNetwork = Number((parseFloat(lastNetwork) / div).toFixed(2));
            lastDesktop = Number((parseFloat(lastDesktop) / div).toFixed(2));
                      
            // Save points
            times[i] = parseInt(lastTime);
            dc[i] = Number(lastdc);
            //network[i] = Number(lastNetwork);
            desktop[i] = Number(lastDesktop);                     

            // Merge with grey points
            if(this.greenit_resource == Resource.CO2) {
                let lastdcG = this.greenit_data[i].dcG;
                //let lastNetworkG = this.greenit_data[i].networkG;
                let lastDesktopG = this.greenit_data[i].desktopG;
                          
                lastdcG = Number((parseFloat(lastdcG) / div).toFixed(2));
                //lastNetworkG = Number((parseFloat(lastNetworkG) / div).toFixed(2));
                lastDesktopG = Number((parseFloat(lastDesktopG) / div).toFixed(2));
                                          
                // XXX  only G for network & desktop
                dc[i] = Number(parseFloat(lastdc + lastdcG ).toFixed(2));
                //network[i] = Number(lastNetworkG);
                desktop[i] = Number(lastDesktopG);
            }
        }
    }

    // Update chart title
    this.chart.update({ yAxis: { title: { text: yname }} });

    // Set categories
    this.chart.xAxis[0].setCategories(times);

    // Push dc
    this.chart.addSeries({
        type: 'column',
        name: "DC",
        cursor: 'pointer',
        color: this.greenit_color[0],
        //dashStyle: this.linestyle,
        data: dc
    }, false);
          
    // Push network
    /*this.chart.addSeries({
        type: 'column',
        name: "Network",
        cursor: 'pointer',
        color: '#434348',
        dashStyle: this.linestyle,
        data: network
    }, false);*/
              
    // Push desktop
    this.chart.addSeries({
        type: 'column',
        name: "Desktop",
        cursor: 'pointer',
        color: this.greenit_grey_color[2],
        //dashStyle: this.linestyle,
        data: desktop
    }, false);
          
    // Hide chart loading
    this.chart.hideLoading();

    // Redraw
    this.chart.redraw();

  }

  /**
   * 
   */
  private setDCChartData() : void {

    // Init vars
    let div = 1;
    let yname: string;
    let serverlegend: string;
    if(this.greenit_resource == Resource.WH) {
        yname = "kWh";
        serverlegend = "Servers";
        div = 1000;
    } else if(this.greenit_resource == Resource.CO2) {
        yname = "kg CO2eq";
        serverlegend = "Servers (direct)";
    }

    let times = [];
    let host = [];
    let hostG = [];
    //let switchs = [];
    //let switchsG = [];
    //let storage = [];
    //let storageG = [];
    
    for (let i in this.greenit_data) {
        if (this.greenit_data[i] != undefined) {
            let lastTime = this.greenit_data[i].time;
            //XXX in order to show last month number (first day of month minus a day)
            lastTime = parseInt(lastTime) - 24 * 3600* 1000;
                
            let lastHost = this.greenit_data[i].host;
            //let lastSwitch = this.greenit_data[i].switchs;
            //let lastStorage = this.greenit_data[i].storage;
                
            // Save points
            times[i] = parseInt(lastTime);
            host[i] = Number((parseFloat(lastHost) / div).toFixed(2));
            //switchs[i] = Number((parseFloat(lastSwitch) / div).toFixed(2));
            //storage[i] = Number((parseFloat(lastStorage) / div).toFixed(2));
                
            // Save Grey points
            if(this.greenit_resource == Resource.CO2) {
                let lastHostG = this.greenit_data[i].hostG;
                //let lastSwitchG = this.greenit_data[i].switchsG;
                ///let lastStorageG = this.greenit_data[i].storageG;
                    
                hostG[i] = Number((parseFloat(lastHostG) / div).toFixed(2));
                //switchsG[i] = Number((parseFloat(lastSwitchG) / div).toFixed(2));
                //storageG[i] = Number((parseFloat(lastStorageG) / div).toFixed(2));
            }
        }
    }
        
    // Set categories
    this.chart.xAxis[0].setCategories(times);
        
    // Update chart title
    this.chart.update({ yAxis: { title: { text: yname }} });

    if(this.greenit_resource == Resource.WH) {
        this.chart.update({ plotOptions: {column: { dataLabels: { enabled: false } } } });
    }

    // Push hosts
    this.chart.addSeries({
        type: 'column',
        name: serverlegend,
        cursor: 'pointer',
        color: this.greenit_color[0],
        //dashStyle: this.linestyle,
        data: host
    }, false);
        
    if(this.greenit_resource == Resource.CO2) {
        this.chart.addSeries({
            type: 'column',
            name: "Servers (embodied)",
            cursor: 'pointer',
            color: this.greenit_grey_color[0],
            //dashStyle: this.linestyle,
            data: hostG
        }, false);
    }
        
    // Push switchs
    /*chart.addSeries({
        type: 'column',
        name: "Switchs",
        cursor: 'pointer',
        color: greenit_color[1],
        dashStyle: this.linestyle,
        data: switchs
    }, false);*/
        
    // Push storage
    /*chart.addSeries({
        type: 'column',
        name: "Storage",
        cursor: 'pointer',
        color: greenit_color[2],
        dashStyle: this.linestyle,
        data: storage
    }, false);*/
        
    // Hide chart loading
    this.chart.hideLoading();

    // Redraw
    this.chart.redraw();

  }

  /**
   * 
   */
  private setDesktopChartData() : void {
    
    // Init vars
    let div = 1;
    let yname: string;
    if(this.greenit_resource == Resource.WH) {
        yname = "kWh";
        div = 1000;
    } else if(this.greenit_resource == Resource.CO2) {
        yname = "kg CO2eq";
    }

    let times = [];
    let desktopG = [];
    let printerG = [];
    let laptopG = [];
    let screenG = [];
    let networkG = [];
    let otherG = [];

    for (let i in this.greenit_data) {
        if (this.greenit_data[i] != undefined) {
            let lastTime = this.greenit_data[i].time;
            //XXX in order to show last month number (first day of month minus a day)
            lastTime = parseInt(lastTime) - 24 * 3600* 1000;
            
            // Save Grey points
            if(this.greenit_resource == Resource.CO2) {
                let lastDesktopG = this.greenit_data[i].desktopG;
                let lastPrinterG = this.greenit_data[i].printerG;
                let lastLaptopG = this.greenit_data[i].laptopG;
                let lastScreenG = this.greenit_data[i].screenG;
                let lastNetworkG = this.greenit_data[i].networkG;
                let lastOtherG = this.greenit_data[i].otherG;
                
                times[i] = parseInt(lastTime);
                desktopG[i] = Number((parseFloat(lastDesktopG) / div).toFixed(2));
                printerG[i] = Number((parseFloat(lastPrinterG) / div).toFixed(2));
                laptopG[i] = Number((parseFloat(lastLaptopG) / div).toFixed(2));
                screenG[i] = Number((parseFloat(lastScreenG) / div).toFixed(2));
                networkG[i] = Number((parseFloat(lastNetworkG) / div).toFixed(2));
                otherG[i] = Number((parseFloat(lastOtherG) / div).toFixed(2));
            }
        }
     }
    
    // Set categories
    this.chart.xAxis[0].setCategories(times);

    // Update chart title
    this.chart.update({ yAxis: { title: { text: yname }} });
    
    if(this.greenit_resource == Resource.CO2) {
        // Push desktop
        this.chart.addSeries({
            type: 'column',
            name: "Desktop (embodied)",
            cursor: 'pointer',
            color: this.greenit_grey_color[0],
            //dashStyle: this.linestyle,
            data: desktopG
        }, false);
        
        // Push printer
        this.chart.addSeries({
            type: 'column',
            name: "Printer (embodied)",
            cursor: 'pointer',
            color: this.greenit_grey_color[1],
            //dashStyle: this.linestyle,
            data: printerG
        }, false);
        
        // Push desktop
        this.chart.addSeries({
            type: 'column',
            name: "Laptop (embodied)",
            cursor: 'pointer',
            color: this.greenit_grey_color[2],
            //dashStyle: this.linestyle,
            data: laptopG
        }, false);
        
        // Push screen
        this.chart.addSeries({
            type: 'column',
            name: "Screen (embodied)",
            cursor: 'pointer',
            color: this.greenit_grey_color[3],
            //dashStyle: this.linestyle,
            data: screenG
        }, false);
        
        // Push network
        this.chart.addSeries({
            type: 'column',
            name: "Network (embodied)",
            cursor: 'pointer',
            color: this.greenit_grey_color[4],
            //dashStyle: this.linestyle,
            data: networkG
        }, false);
        
        // Push other
        this.chart.addSeries({
            type: 'column',
            name: "Other (embodied)",
            cursor: 'pointer',
            color: this.greenit_grey_color[5],
            //dashStyle: this.linestyle,
            data: otherG
        }, false);
    }
    
    // Hide chart loading
    this.chart.hideLoading();

    // Redraw
    this.chart.redraw();

  }

  /**
   *  ONLY GreenIt data (VMware)
   */
  private setOtherChartData() : void {

    // Init vars
    let div = 1;
    let yname: string = "kg CO2eq";
    let serverlegend: string;
    let ratio: number;

    if(this.target == GreenitTarget.STORAGE_CO2) {
        serverlegend = "Storage extrapolation (direct)";
        ratio = this.storage_ratio;
    } else if(this.target ==  GreenitTarget.NETWORK_CO2) {
        serverlegend = "Network extrapolation (direct)";
        ratio = this.network_ratio;
    }

    let times = [];
    let values = [];
    
    
    for (let i in this.greenit_data) {
        if (this.greenit_data[i] != undefined) {
            let lastTime = this.greenit_data[i].time;
            //XXX in order to show last month number (first day of month minus a day)
            lastTime = parseInt(lastTime) - 24 * 3600* 1000;
                
            let lastHost = this.greenit_data[i].host;
                
            // Save points
            times[i] = parseInt(lastTime);
            values[i] = Number((parseFloat(lastHost) / div).toFixed(2));

            // Apply extrapolation ratio from host data
            let tmp_data = (values[i] * ratio) / this.host_ratio;
            values[i] = Number((parseFloat(String(tmp_data)) / div).toFixed(2));
            
        }
    }
        
    // Set categories
    this.chart.xAxis[0].setCategories(times);
        
    // Update chart title
    this.chart.update({ yAxis: { title: { text: yname }} });

    if(this.greenit_resource == Resource.WH) {
        this.chart.update({ plotOptions: {column: { dataLabels: { enabled: false } } } });
    }

    // Push hosts
    this.chart.addSeries({
        type: 'column',
        name: serverlegend,
        cursor: 'pointer',
        color: this.greenit_color[0],
        //dashStyle: this.linestyle,
        data: values
    }, false);
        
    
        
    // Push switchs
    /*chart.addSeries({
        type: 'column',
        name: "Switchs",
        cursor: 'pointer',
        color: greenit_color[1],
        dashStyle: this.linestyle,
        data: switchs
    }, false);*/
        
    // Push storage
    /*chart.addSeries({
        type: 'column',
        name: "Storage",
        cursor: 'pointer',
        color: greenit_color[2],
        dashStyle: this.linestyle,
        data: storage
    }, false);*/
        
    // Hide chart loading
    this.chart.hideLoading();

    // Redraw
    this.chart.redraw();

  }

  /**
   * ALL INCLUSIVE CF (VMWARE, AWS, AZURE)
   */
  private setAllInclusiveChartData(): void {

    // Init vars
    let times = [];
    let it = [];
    let itG = [];
    
    for (let i in this.greenit_data) {
        if (this.greenit_data[i] != undefined) {
            let lastTime = this.greenit_data[i].time;
            //XXX in order to show last month number (first day of month minus a day)
            lastTime = parseInt(lastTime) - 24 * 3600* 1000;
                
            // VMware value
            let lastHost = this.greenit_data[i].host;
            let value = Number((parseFloat(lastHost)).toFixed(2));

            // Apply storage extrapolation ratio from host data 
            let tmp_data = (value * this.storage_ratio) / this.host_ratio;
            let storage_value = Number((parseFloat(String(tmp_data))).toFixed(2));   

            // Apply network extrapolation ratio from host data
            tmp_data = (value * this.network_ratio) / this.host_ratio;
            let network_value = Number((parseFloat(String(tmp_data))).toFixed(2)); 


            // Cloud provider values
            let cloud_direct: number = 0;
            let cloud_grey: number = 0;
            for(let k = 0; k < this.greenit_clouddata.length; k++) {
                if(this.isSameMonth(this.greenit_clouddata[k].month, lastTime)) {
                    cloud_direct += this.greenit_clouddata[k].direct;
                    cloud_grey += this.greenit_clouddata[k].grey;
                }
            }

            // Save Direct points
            times[i] = parseInt(lastTime);
            it[i] = Number(Number(value + storage_value + network_value + cloud_direct).toFixed(2));
                
            // Save Grey points
            if(this.greenit_resource == Resource.CO2) {
                let lastHostG = this.greenit_data[i].hostG;
                    
                itG[i] = Number((parseFloat(lastHostG) + cloud_grey).toFixed(2));
            }
        }
    }

    // Set categories
    this.chart.xAxis[0].setCategories(times);
        
    // Update chart title
    this.chart.update({ yAxis: { title: { text: 'kg CO2eq' }} });

    if(this.greenit_resource == Resource.WH) {
        this.chart.update({ plotOptions: {column: { dataLabels: { enabled: false } } } });
    }

    // Push hosts
    let time: number = 0;
    this.chart.addSeries({
        type: 'column',
        name: 'direct',
        cursor: 'pointer',
        color: this.greenit_color[0],
        data: it,
        events: {
            click: function (event) {
                let time_str: string = event.point.category;
                time = +time_str;
            }
        },
        point: {
            events: {
                  click: function () {
                        this.isPieReady = false;
                        this.loadPieChart(time, 'direct');
                  }.bind(this)
            }
        }
    }, false);
        
    if(this.greenit_resource == Resource.CO2) {
        this.chart.addSeries({
            type: 'column',
            name: "embodied",
            cursor: 'pointer',
            color: this.greenit_grey_color[0],
            data: itG,
            events: {
                click: function (event) {
                    let time_str: string = event.point.category;
                    time = +time_str;
                }
            },
            point: {
                events: {
                      click: function () {
                            this.isPieReady = false;
                            this.loadPieChart(time, 'grey');
                      }.bind(this)
                }
            }
        }, false);
    }
        
    // Hide chart loading
    this.chart.hideLoading();

    // Redraw
    this.chart.redraw();
  }

  private setAwsData(): void {

    let aws_regions: any = [];

    this.mgt_svc.getRegions('aws').pipe(first()).subscribe(
        data => {				
            if(data.length > 0) {
                this.message.isValidAws = true;
                for(let i = 0; i < data.length; i++) {
                    aws_regions.push(data[i]);
                }
                this.aggregateProviderData(aws_regions, 'aws');
                setTimeout(() => this.setAzureData(), 500);
            }
        }, error => {
            setTimeout(() => this.setAzureData(), 500);
        }
    );
  }

  private setAzureData(): void {

    let azure_regions: any = [];

    this.mgt_svc.getRegions('azure').pipe(first()).subscribe(
        data2 => {				
            if(data2.length > 0) {
                this.message.isValidAzure = true;
                for(let i = 0; i < data2.length; i++) {
                    azure_regions.push(data2[i]);
                }
                this.aggregateProviderData(azure_regions, 'azure');
                setTimeout(() => this.setAllInclusiveChartData(), 500);
            }
        }, error => {
            setTimeout(() => this.setAllInclusiveChartData(), 500);
        }
    );
  }

  private aggregateProviderData(data: any, provider: string): void {
   
    for(let i=0; i < data.length; i++) {
        let account: string = data[i].NAME;
        let region: string = data[i].REGION.replaceAll('-', '');
        this.json_svc.getData('root','cloud_' + account + '_' + region, JSONTarget.CLOUD_GLOBAL_CO2).subscribe(
               data2 => {
                if(i == 0) {
                    let months: string[]= [];
                    for(let j=0; j < data2.length; j++) {
                        if(j > 0) {
                            if(!months.includes(data2[j].month)) {
                                months.push(data2[j].month);
                                let cloud_co2: any = {
                                    month: data2[j].month,
                                    direct: 0,
                                    grey: 0,
                                    it: provider
                                };
                                this.greenit_clouddata.push(cloud_co2);
                            }
                        }
                    }
                }

                if(this.greenit_clouddata.length > 0) {
                    for(let j=0; j < data2.length; j++) {
                        if(j > 0) {
                            for(let k=0; k < this.greenit_clouddata.length; k++) {
                                if(this.greenit_clouddata[k].month === data2[j].month) {
                                    this.greenit_clouddata[k].direct += data2[j].direct;
                                    this.greenit_clouddata[k].grey += data2[j].grey;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        );
    }
  }

  private isSameMonth(t1: string, t2: number): boolean {

    let same: boolean = false;

    let target_date = moment.unix(t2/1000).format("YYYY MMM");
    if(t1 === target_date)
        same = true;

    return same;
  }

  /**
   * ALL INCLUSIVE PIE (VMWARE, AWS, AZURE)
   */
  private loadPieChart(time: number, co2emission: string): void {

    let serie_name: string = 'direct emission';
    if(co2emission == 'grey')
        serie_name = 'embodied emission';


    let target_date = moment.unix(time/1000).format("YYYY MMM");
    let series: any = [];

    let data: any = [];
    let serie_it: any = {
        name: serie_name,
        type: 'pie',
        data: data
    };
    series.push(serie_it);

    for(let i in this.greenit_clouddata) {
        if(this.greenit_clouddata[i].month === target_date) {
            let val: number = this.greenit_clouddata[i].direct;
            if(co2emission == 'grey')
                val = this.greenit_clouddata[i].grey;

            let data_it: any = {
                name: this.greenit_clouddata[i].it,
                y: val
            };
            data.push(data_it);
        }
    }

    for(let i in this.greenit_data) {
        let lastTime = this.greenit_data[i].time;
        //XXX in order to show last month number (first day of month minus a day)
        lastTime = parseInt(lastTime) - 24 * 3600* 1000;
        let t2 = moment.unix(lastTime/1000).format("YYYY MMM");

        if(t2 === target_date) {

            // VMware value
            let lastHost = this.greenit_data[i].host;
            let val = Number((parseFloat(lastHost)).toFixed(2));

            if(co2emission == 'grey')
                val = this.greenit_data[i].hostG;

            let data_it: any = {
                name: 'VMware',
                y: val
            };
            data.push(data_it);


            // Apply storage extrapolation ratio from host data
            if(co2emission == 'direct') {
                let tmp_data = (val * this.storage_ratio) / this.host_ratio;
                let storage_val = Number((parseFloat(String(tmp_data))).toFixed(2));

                let data_it: any = {
                    name: 'Storage extrapoloation',
                    y: storage_val
                };
                data.push(data_it);
            }

            // Apply network extrapolation ratio from host data
            if(co2emission == 'direct') {
                let tmp_data = (val * this.network_ratio) / this.host_ratio;
                let network_val = Number((parseFloat(String(tmp_data))).toFixed(2)); 

                let data_it: any = {
                    name: 'Network extrapoloation',
                    y: network_val
                };
                data.push(data_it);
            }
        }
    }

    this.chartOptions = {
        credits: {
          enabled: false
        },
        title: {
          text: 'Distribution of carbon emission by IT',
          style: {
            color: 'grey',
            fontSize: '20px'
          }
        },
        subtitle: {
            text: serie_name + ' - ' + target_date
        },
        legend: {
          enabled: false
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: series
      };

      this.isPieReady = true;
  }

  /**
   * 
   */
  private updateDataSettings(data: any) {
    // Update storage extrapoloation ratio
    let storage_entry = data.filter((e: { option: string; }) => e.option === "storage.direct.ratio");
    if(storage_entry[0])
      this.storage_ratio = Number(storage_entry[0].value);

    // Update network extrapolation ratio
    let network_entry = data.filter((e: { option: string; }) => e.option === "network.direct.ratio");
    if(network_entry[0])
      this.network_ratio = Number(network_entry[0].value);


    // Compute host part from previous data
    this.host_ratio = 100 - this.storage_ratio - this.network_ratio;
    if(this.host_ratio < 0)
        this.host_ratio = 0;
  }
}

<div [clrLoading]="loading">
    <div style="margin: 10px;">
        <b>Ports:</b>
        <clr-datagrid>
            <clr-dg-column>Summary</clr-dg-column>
            <clr-dg-column>Port</clr-dg-column>
            <clr-dg-column>Protocol</clr-dg-column>
            <clr-dg-column>Exchanged bytes</clr-dg-column>
            <clr-dg-column>Exchanged packets</clr-dg-column>

            <clr-dg-row *ngFor="let port of ports">
                <clr-dg-cell>
                    <a class="label label-blue" *ngIf="flowDetail.source">
                        {{ flowDetail.source.name }}
                        <span class="badge" *ngIf="port.direction === 1">{{port.label}}</span>
                    </a>
                    <a class="label label-blue" *ngIf="flowDetail.source === undefined">
                        {{ flowDetail.source }}
                    </a>

                    <span style="font-size: 16px; margin-right: 5px;">&rarr;</span>

                    <a class="label label-blue" *ngIf="flowDetail.target">
                        {{ flowDetail.target.name }}
                        <span class="badge" *ngIf="port.direction === 0">{{port.label}}</span>
                    </a>
                    <a class="label label-blue" *ngIf="flowDetail.target === undefined">
                        {{ flowDetail.target }}
                    </a>
                </clr-dg-cell>
                <clr-dg-cell><span class="label">{{port.label}}</span></clr-dg-cell>
                <clr-dg-cell><span class="label" *ngFor="let protocol of port.protocols">{{protocol}}</span></clr-dg-cell>
                <clr-dg-cell>{{port.exchanged_bytes / (1024 * 1024)  | number: '1.0-0'}} MiB </clr-dg-cell>
                <clr-dg-cell>{{port.exchanged_packets | number}} packets </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>{{ports.length}} ports</clr-dg-footer>
        </clr-datagrid>
    </div>
</div>

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

import { JSONTarget, Message, User } from '@app/model';

import { getUserCurrency } from '../../../assets/js/tools.js';


@Component({
  selector: 'app-kpicost',
  templateUrl: './kpicost.component.html',
  styleUrls: ['./kpicost.component.css']
})
export class KpicostComponent implements OnInit {

  highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;

  isReady: boolean = false;

  message: Message;

  globalCurrency: string = '';

  cost_data: any = [];

  private currentUser;


  constructor(
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.message_svc.currentMessage.subscribe(message => this.message = message);

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KPI_COST).subscribe(
		data => {
			this.cost_data = data;
			this.buildCard();
		}
	);
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  private buildCard(): void {

	this.globalCurrency = getUserCurrency(this.currentUser.currency);
	this.chartOptions = {
		credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                title: {
                    text: 'Monthly Average VM cost'
                },
                xAxis: {
                    categories: this.cost_data['month'],
                    crosshair: true
                },
                yAxis: {
                    title: {
                        text: 'VM cost'
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f} ' + this.globalCurrency + '</b><br/>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [{
                    type: 'column',
                    name: 'VMRUN',
                    data: this.cost_data['vmrun']
                }, {
                    type: 'column',
                    name: 'VMADD',
                    data: this.cost_data['vmadd']
                }, {
                    type: 'column',
                    name: 'VMDEL',
                    data: this.cost_data['vmdel']
                }]
	};

	this.isReady = true;
  }
}
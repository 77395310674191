import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

import * as moment from 'moment';

import { JSONTarget, Message, User } from '@app/model';


@Component({
  selector: 'app-kpicapacity',
  templateUrl: './kpicapacity.component.html',
  styleUrls: ['./kpicapacity.component.css']
})
export class KpicapacityComponent implements OnInit {

  highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;

  isReady: boolean = false;

  message: Message;

  moment = moment;

  capacity_data: any = [];

  prediction_data: any = [];

  cpu_saturation: string = '';
  cpu_sat_class = 'label label-info';

  ram_saturation: string = '';
  ram_sat_class = 'label label-info';

  sto_saturation: string = '';
  sto_sat_class = 'label label-info';

  col_class: string = 'clr-col-lg-12 clr-col-md-12 clr-col-12';

  private currentUser: User;


  constructor(
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.message_svc.currentMessage.subscribe(message => this.message = message);

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KPI_CAPACITY).subscribe(
		data => {
			this.capacity_data = data;
			this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.CAPA_INFRA).subscribe(
				data => {
					this.prediction_data = data;
					this.buildCard();
				}
			);
		}
	);
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  private buildCard(): void {

	if(this.prediction_data.CAP.CAPLAST.CPUCAP.CAPCPU_DATE > 0) {
		this.cpu_saturation = this.moment.unix(this.prediction_data.CAP.CAPLAST.CPUCAP.CAPCPU_DATE/1000).format("MMM YYYY"); 
	} else {
  		this.cpu_sat_class = 'label label-danger';
		this.cpu_saturation = 'ko';
	}

	if(this.prediction_data.CAP.CAPLAST.RAMCAP.CAPRAM_DATE > 0) {
		this.ram_saturation = this.moment.unix(this.prediction_data.CAP.CAPLAST.RAMCAP.CAPRAM_DATE/1000).format("MMM YYYY"); 
	} else {
  		this.ram_sat_class = 'label label-danger';
		this.ram_saturation = 'ko';
	}

	if(this.prediction_data.CAP.CAPLAST.DISKCAP.CAPDISK_DATE > 0) {
		this.sto_saturation = this.moment.unix(this.prediction_data.CAP.CAPLAST.DISKCAP.CAPDISK_DATE/1000).format("MMM YYYY"); 
	} else {
  		this.sto_sat_class = 'label label-danger';
		this.sto_saturation = 'ko';
	}

	this.chartOptions = {
		credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                title: {
                    text: 'Monthly Average Server Capacity',
                    align: 'center',
                    x: 100
                },
                xAxis: {
                    categories: this.capacity_data['month']
                },
                yAxis: [{
                    min: 0,
                    title: {
                        text: 'Capacity / Consumption'
                    }
                }],
                legend: {
                    shadow: false
                },
                tooltip: {
                    shared: true
		},
		plotOptions: {
                    column: {
                        grouping: false,
                        shadow: false,
                        borderWidth: 0
                    }
                },
                series: [{
                    type: 'column',
                    name: 'CPU Capacity',
                    color: 'rgba(248,161,63,1)',
                    data: this.capacity_data['cpu'],
		    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f} GHz</b><br/>'
                    },
		    pointPadding: 0.3,
		    pointPlacement: -0.3
                }, {
                    type: 'column',
                    name: 'CPU Consumption',
                    color: 'rgba(186,60,61,.9)',
                    data: this.capacity_data['cpucons'],
		    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f} GHz</b><br/>'
                    },
		    pointPadding: 0.4,
		    pointPlacement: -0.3
                }, {
                    type: 'column',
                    name: 'RAM Capacity',
                    color: 'rgba(165,170,217,1)',
                    data: this.capacity_data['ram'],
		    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f} Go</b><br/>'
                    },
		    pointPadding: 0.3,
		    pointPlacement: 0
                }, {
                    type: 'column',
                    name: 'RAM Consumption',
                    color: 'rgba(126,86,134,.9)',
                    data: this.capacity_data['ramcons'],
		    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f} Go</b><br/>'
                    },
		    pointPadding: 0.4,
		    pointPlacement: 0
		}, {
                    type: 'column',
		    name: 'STO Capacity',
                    color: 'rgba(65,159,46,1)',
                    data: this.capacity_data['sto'],
		    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f} To</b><br/>'
                    },
		    pointPadding: 0.3,
		    pointPlacement: 0.3
                }, {
                    type: 'column',
                    name: 'STO Consumption',
                    color: 'rgba(99,199,68,.9)',
                    data: this.capacity_data['stocons'],
		    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f} To</b><br/>'
                    },
		    pointPadding: 0.4,
		    pointPlacement: 0.3
                }]
	};

	this.isReady = true;
  } 
}
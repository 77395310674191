import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-cluster-distribution3]'
})
export class Clusterdistribution3Directive {

  constructor(public viewContainerRef3: ViewContainerRef) { }

}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-weekly-cal]'
})
export class WeeklycalDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

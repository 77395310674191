import {Component, EventEmitter, OnInit} from '@angular/core';
import * as d3 from 'd3';
import {JsonloaderService} from "@app/services";

@Component({
	selector: 'app-dcview-flowchart',
	templateUrl: './dcview-flowchart.component.html',
	styleUrls: ['./dcview-flowchart.component.css']
})
export class DcviewFlowchartComponent implements OnInit {

	data = {};

	svg;
	height = 1024;
	width = 768;
	margin = 20;

	loading = false;

	availableMetrics = [{
		"name": "cpu",
		"label": "cpu consumption",
		"json_property": "cpu"
	}, {
		"name": "ram",
		"label": "ram consumption",
		"json_property": "ram"
	}, {
		"name": "storage",
		"label": "storage consumption",
		"json_property": "sto"
	}, {
		"name": "power",
		"label": "power consumption",
		"json_property": "power"
	}]

	selectedMetric = "cpu"

	displayedPath = ["Infrastructure"];
	secondTableShouldChange = new EventEmitter();

	listedVms = [];

	jsonLoaderService;

	constructor(jsonLoaderService: JsonloaderService) {
		this.jsonLoaderService = jsonLoaderService;
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.refreshData();

			window.onresize = () => {
				this.eraseTreeMap();
				this.createTreeMap();
			};
		}, 100);
		
		// Ensure that filter changes are taken into account
		this.jsonLoaderService.eventJsonAsyncLoaded.subscribe(json => {
			this.refreshData();
			this.eraseTreeMap();
			this.createTreeMap();
		});
	}

	resetNavigation() {
		this.displayedPath = ["Infrastructure"];
		this.refreshData();
	}

	refreshData() {
		this.jsonLoaderService.currentJsonSubject.subscribe((json) => {
			let targetedJsonData;
			if (this.selectedMetric != "storage") {
				targetedJsonData = json.dcviewTreeData;
			} else {
				targetedJsonData = json.dcviewTreeStorageData;
			}
			let dcviewTreeDataCopy = JSON.parse(JSON.stringify(targetedJsonData));
			this.data = this.processData(dcviewTreeDataCopy);
			this.createTreeMap();
		})
	}

	selectMetric(metric) {
		let previousMetric = this.selectedMetric;
		this.selectedMetric = metric;

		if (previousMetric == "storage" || this.selectedMetric == "storage") {
			this.resetNavigation();
		}

		this.refreshData();
	}

	processData(data) {
		let selectedMetric = "cpu";

		function color(data) {
			if (data.type == "ROOT" || data.type == "CLUSTER") {
				return "white";
			}
			if (data.type == "VM") {
				return "grey";
			}
			return "black";
		}

		let addAggregatedData = (data, selectedMetric, recursionLevel = 0, parentPath = []) => {
			let currentPath = parentPath.concat(data.name);
			data.recursionLevel = recursionLevel
			data.textColor = color(data);
			data.path = currentPath;
			data.children.map((c) => c.parent = data);
			data.children = data.children.map((c) => addAggregatedData(c, selectedMetric, recursionLevel + 1, currentPath));
			data.sumChildrenValues = data.children.map((c) => c.value).reduce((a, b) => a + b, 0);
			data.labelled_name = data.name;

			if (data.type == "VM") {
				let propertyName = this.availableMetrics.filter((m) => m.name == this.selectedMetric)[0]["json_property"];
				data.value = data[propertyName];
			}

			if (data.type == "ROOT") {
				data.labelled_name = "Infrastructure";
			}

			let desiredServerMinSize = 15;
			if (data.type == "SERVER" && data.sumChildrenValues <= desiredServerMinSize) {
				if (data.sumChildrenValues > 0) {
					data.value = desiredServerMinSize - data.sumChildrenValues;
					if (data.value > 0.66 * desiredServerMinSize) {
						let ratio = (desiredServerMinSize - data.value) / data.sumChildrenValues;
						data.children.map((c) => c.value = ratio * c.value);
						data.sumChildrenValues = data.children.map((c) => c.value).reduce((a, b) => a + b, 0);
						data.value = desiredServerMinSize - data.sumChildrenValues;
					}
				} else {
					data.value = desiredServerMinSize;
				}
			}

			if (data.type == "VM") {
				data.labelled_name = "";
			}

			if (data.type == "VM") {
				let vmObject = {
					name: data.name,
					cpu: data.cpu,
					ram: data.ram,
					storage: data.sto,
					power: data.power,
					uuid: data.uuid
				};
				if (this.selectedMetric == "storage") {
					vmObject["datastore"] = data.parent.name;
					vmObject["datastore_path"] = data.parent.path;
				} else {
					vmObject["server"] = data.parent.name;
					vmObject["server_path"] = data.parent.path;
					vmObject["cluster"] = data.parent.parent.name;
					vmObject["cluster_path"] = data.parent.parent.path;
					vmObject["datacenter"] = data.parent.parent.parent.name;
					vmObject["datacenter_path"] = data.parent.parent.parent.path;
				}
				this.listedVms.push(vmObject);
			}
			return data;
		}

		function filterOnlyDisplayedData(data, path) {
			let firstPart = path[0];
			if (firstPart == data.name || (firstPart == "Infrastructure" && data.name == "root")) {
				let nextPath = path.slice(1)
				if (nextPath.length > 0) {
					let newChildren = data.children
						.map((c) => filterOnlyDisplayedData(c, nextPath))
						.filter((p) => p != null);
					data.children = newChildren;
				}
				return data
			} else {
				return null;
			}
		}

		this.listedVms = [];
		let selectedResult = filterOnlyDisplayedData(data, this.displayedPath);
		let completedResult = addAggregatedData(selectedResult, selectedMetric);
		// Send an event that forces the filters of the datatable to refresh.
		// The event is triggered with 1000ms of delay, to give some time to
		// the datatables to update correctly.
		setTimeout(() => {
			this.secondTableShouldChange.emit(this.listedVms);
		}, 2000);
		return completedResult;
	}

	changeSelection(newPath) {
		this.displayedPath = newPath;
		this.displayedPath[0] = "Infrastructure";
		this.refreshData();
	}

	changeSelectionFromTopMenu(lastElementPath) {
		let indexOfLastElementPath = this.displayedPath.indexOf(lastElementPath);
		this.changeSelection(this.displayedPath.slice(0, indexOfLastElementPath + 1));
	}

	createTreeMap(): void {
		d3.select('div#divSvg').select("svg").remove();
		this.refreshTreeMap();
	}

	eraseTreeMap(): void {
		d3.select('div#divSvg')
			.selectAll('*')
			.remove();
	}

	refreshTreeMap(): void {
		let svgHeight = document.getElementById("divSvg").clientHeight;
		let svgWidth = document.getElementById("divSvg").clientWidth;

		let colors = [
			"hsl(198, 100%, 28%)",
			"hsl(198, 100%, 28%)",
			"hsl(198, 100%, 28%)",
			"hsl(198, 100%, 28%)",
			"hsl(198, 100%, 38%)"
		];

		// Remove useless node levels
		let selectedDataNode = this.data;
		for (let nodeId of this.displayedPath.slice(1)) {
			// @ts-ignore
			[selectedDataNode] = selectedDataNode.children.filter((c) => c.name == nodeId);
		}

		// Use a treemap layout to compute the weights of each Datacenter, Cluster, Server and VM
		// let dataCopy = JSON.parse(JSON.stringify(this.data));
		let treemapLayout = data => d3.treemap()
			.size([svgWidth, svgHeight])
			.paddingOuter(3)
			.paddingTop(19)
			.paddingInner(1)
			.round(true)
			(d3.hierarchy(data)
				.sum(d => d.value)
				.sort((a, b) => b.value - a.value));
		const rootTreemap = treemapLayout(selectedDataNode);

		function associateTreemapPropertiesToData(node, exploredNodes = []) {
			let newExploredNodes = exploredNodes.concat(node);
			node.data.treemapData = {
				x0: node.x0,
				y0: node.y0,
				x1: node.x1,
				y1: node.y1,
				value: node.value,
			};
			node.data.treemapData["relativeValue"] = node.value / newExploredNodes[0].data.treemapData.value;
			if (node.children === undefined) {
				return;
			}
			node.children
				.filter((c) => exploredNodes.indexOf(c) == -1)
				.map((c) => associateTreemapPropertiesToData(c, newExploredNodes))
		}

		associateTreemapPropertiesToData(rootTreemap);

		// @ts-ignore
		let ratioMinSizeOverMaxSize = rootTreemap.data.treemapData.value;

		// declares a tree layout and assigns the size
		var treeLayout = d3.tree()
			.size([svgWidth, svgHeight]);

		// assigns the data to a hierarchy using parent-child relationships
		var nodes = d3.hierarchy(selectedDataNode);

		// maps the node data to the tree layout
		nodes = treeLayout(nodes);

		// @ts-ignore
		d3.select('div#divSvg').select("svg").remove();
		const svg = d3.select('div#divSvg')
			.append('svg')
			.attr('width', svgWidth)
			.attr('height', svgHeight);
		this.svg = svg;


		// Add zoom
		svg.call(d3.zoom()
			.extent([[0, 0], [svgWidth, svgHeight]])
			.scaleExtent([0.1, ratioMinSizeOverMaxSize])
			.on("zoom", zoomed));

		const g = svg.append("g");

		function zoomed({transform}) {
			g.attr("transform", transform);
		}

		let infrastructureNodeSize = 80;
		let infrastructureNodeFontSize = 25;

		// adds the links between the nodes
		var link = g.selectAll(".link")
			.data(nodes.descendants().slice(1))
			.enter().append("path")
			.attr("class", "link")
			.attr("stroke", "#C4C4C4")
			// @ts-ignore
			.attr("stroke-width", (d) => infrastructureNodeSize * d.data.treemapData.relativeValue)
			.attr("d", function (d) {
				// @ts-ignore
				return "M" + d.x + "," + d.y + " L " + d.parent.x + "," + d.parent.y;
			});

		// adds each node as a group
		var node = g.selectAll(".node")
			.data(nodes.descendants())
			.enter().append("g")
			.attr("class", function (d) {
				return "node" +
					(d.children ? " node--internal" : " node--leaf");
			})
			.attr("transform", function (d) {
				// @ts-ignore
				return "translate(" + d.x + "," + d.y + ")";
			});

		// adds the circle to the node
		node.append("circle")
			// @ts-ignore
			.attr("fill", (d) => colors[d.data.recursionLevel])
			// @ts-ignore
			.attr("r", (d) => infrastructureNodeSize * d.data.treemapData.relativeValue);

		node.append("title")
			.text(d => `${d.data["name"]}`);

		// adds the text to the node
		node.append("text")
			.attr("dy", ".35em")
			// @ts-ignore
			.attr("y", (d) => (d.data.type == "VM" ? 1 : -1) * (infrastructureNodeSize + infrastructureNodeFontSize / 2) * d.data.treemapData.relativeValue)
			// @ts-ignore
			.attr("style", (d) => `text-anchor: middle; font-size: ${infrastructureNodeFontSize * d.data.treemapData.relativeValue}px`)
			// @ts-ignore
			.text((d) => d.data.type == "VM" ? d.data.name : d.data.labelled_name);

		// @ts-ignore
		node
			.on("click", (d, i) => {
				// @ts-ignore
				this.changeSelection(i.data.path);
			})
			.on("mouseover", function (d, i) {
				d3.select(this).select("rect").style('opacity', '0.66');
				d3.select(this).style("cursor", "pointer");
			})
			.on("mouseout", function (d, i) {
				d3.select(this).select("rect").style('opacity', '1.0');
				d3.select(this).style("cursor", "default");
			})

		// Fix position of the group in two steps

		// step1: zoom out, and let d3 recompute positions and sizes
		const [x, y, k] = [0, 0, 0.75];
		g.attr('transform', 'translate(' + x + ',' + y + ') scale(' + k + ')');
		svg.call(
			d3.zoom().transform,
			d3.zoomIdentity.translate(x, y).scale(k)
		);

		// step2: center the main group by translating in the middle of the size difference
		// between the group and the svg
		const [x2, y2, k2] = [
			(g.node().getBBox().width - svg.node().getBBox().width) / 2,
			(g.node().getBBox().height - svg.node().getBBox().height) / 2 + 20,
			0.75
		];
		g.attr('transform', 'translate(' + x2 + ',' + y2 + ') scale(' + k2 + ')');
		svg.call(
			d3.zoom().transform,
			d3.zoomIdentity.translate(x2, y2).scale(k2)
		);

	}
}

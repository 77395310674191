import {Component, Input, OnInit} from '@angular/core';
import {NetscopeService} from "@app/services";

@Component({
  selector: 'app-expandable-rows',
  templateUrl: './expandable-rows.component.html',
  styleUrls: ['./expandable-rows.component.css']
})
export class ExpandableRowsComponent implements OnInit {

  @Input() flowDetail;
  @Input() day;
  loading: boolean;

  ports = []
  protocols = []

  constructor(private netscopeService: NetscopeService) { }

  ngOnInit(): void {
    this.loading = true;
    let startTimeInterval = 0;
    let endTimeInterval = "now()";

    if (this.flowDetail !== undefined) {
      let srcIp = this.flowDetail.src_address;
      let dstIp = this.flowDetail.dst_address;
      this.netscopeService.getFlowDetails(startTimeInterval, endTimeInterval, srcIp, dstIp, this.day).subscribe((flowDetails) => {

        // @ts-ignore
        this.ports = flowDetails.ports;
        // @ts-ignore
        this.protocols = flowDetails.protocols;

        setTimeout(() => {
          this.loading = false;
        }, 300);
      })
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';

import { Tab } from '../virtIndex.enums';

import * as Highcharts from 'highcharts';
import Bullet from 'highcharts/modules/bullet';
Bullet(Highcharts);


@Component({
  selector: 'app-bullet',
  templateUrl: './bullet.component.html',
  styleUrls: ['./bullet.component.css']
})
export class BulletComponent implements OnInit {
  
  @Input() data: any;

  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;   
    this.updateChart();
  };

  options: Highcharts.Options = {
    chart: {
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        type: 'bullet',
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    time: {
        useUTC: false
    },
    title: {
        text: ''
    },
    legend: {
        enabled: false
    },
    xAxis: {
        categories: ['']
    },
    yAxis: {},
    tooltip: {
        formatter: function() {
        return '<b>Default value detected : ' + this.y + '</b>';
        },
        borderColor: 'red'
    }
    
  }

  /**
   * 
   */
  constructor() { }

  /**
   * 
   */
  ngOnInit(): void { }

  /**
   * 
   */
  private updateChart(): void {
    if(this.data) {
      switch(this.data.tab){
        case Tab.GREENIT:
          this.buildGreenIt();
        break;
        case Tab.BEHAVIOR:
          this.buildBehavior();
        break;
        default:
        break;
      }
    }
  }

  /**
   * 
   */
  private buildBehavior(): void {

    let customer_x = Number(this.data.you);
    let data_min = this.data.min;
    let data_avg = this.data.median;
    let data_max = this.data.max;
    let label_color = this.data.color;
    let min_color = this.data.min_color;
    let avg_color = this.data.avg_color;

    // Update chart & axis 
    this.chart.update({
      chart: {
          inverted: false,
          width: 150,
          marginTop: 60,         
      },
      xAxis: {
          width: 40,
          lineWidth: 0,
      },
      yAxis: {
          labels: {
            //x: -200
          },
          title: null,
          gridLineWidth: 0,
          startOnTick: false,
          endOnTick: false,
          plotBands: [{
            from: data_min,
            to: data_avg,
            color: min_color
          }, {
            from: data_avg,
            to: data_max,
            color: avg_color
          }],
          min: data_min,
          max: data_max
        }
    });

    // Push to chart
    this.chart.addSeries({
        type:'scatter',
        data: [{
            y: customer_x,
            label: String(customer_x),
            dataLabels: {
                enabled: true,
                //verticalAlign: 'bottom',
                padding: 10,
                style: {
                    fontSize: "16px",
                },
                color: label_color
            },
            marker: {
                enabled: true,
                fillColor: label_color,
                radius: 5,
                states: {
                    hover: {
                        fillColor: label_color
                    }
                }
            }
        }],
        enableMouseTracking: false
    });

    // Update chart title
    this.addTitle(10);

  }

  /**
   * 
   */
  private buildGreenIt(): void {

    let customer_x = Number(this.data.you);
    let data_min = this.data.min;
    let data_avg = this.data.median;
    let data_max = this.data.max;
    let label_color = this.data.color;
    let alert_default = this.data.alert_default;

    // FIX customer_x (default value) for chart
    if(customer_x < data_min)
      data_min = customer_x
    if(customer_x > data_max)
      data_max = customer_x;

    // Update chart & axis 
    this.chart.update({
        chart: {
            height: '150px',
            inverted: true,
            marginTop: 80,
            marginLeft: 20
        },
        xAxis: {
          lineWidth: 0,
        },
        yAxis: {
            title: null,
            startOnTick: false,
            min: data_min,
            max: data_max,
            gridLineWidth: 0,
            plotBands: [{
                from: data_min,
                to: data_avg,
                color: '#6aa84f'
            }, {
                from: data_avg,
                to: data_max,
                color: '#93c47d'
            }]
        }
    });

    // Push to chart
    this.chart.addSeries({
        type:'scatter',
        data: [{
            y: customer_x,
            label: String(customer_x),
            dataLabels: {
                enabled: true,
                verticalAlign: 'top',
                padding: 20,
                style: {
                    fontSize: "16px",
                },
                color: label_color
            },
            marker: {
                enabled: true,
                fillColor: label_color,
                radius: 5,
                states: {
                    hover: {
                        fillColor: label_color
                    }
                }
            }
        }],
        enableMouseTracking: alert_default
    });
 
    // Update chart title
    this.addTitle(20);

  }

  /**
   * 
   */
  private addTitle(x: number): void {

    this.chart.renderer.text(this.data.name, x, 20)
      .attr({
        zIndex: 5
      })
      .css({
        color: 'black',
        fontSize: '12px',
        fontWeight: 'bold'
      })
      .add();

    let top = 'rank : ' + this.data.rank_letter ;
    this.chart.renderer.text(top, x, 40)
      .attr({
        zIndex: 5
      })
      .css({
        color: 'black',
        fontSize: '12px',
      })
      .add();
  }

}


import { Component, Input, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';
import Histogram from 'highcharts/modules/histogram-bellcurve';
Histogram(Highcharts);

declare var require: any;


@Component({
  selector: 'app-bellcurve',
  templateUrl: './bellcurve.component.html',
  styleUrls: ['./bellcurve.component.css']
})
export class BellcurveComponent implements OnInit {
  
  @Input() data: any;

  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;
    this.updateChart();
  };

  options: Highcharts.Options = {
    chart: {
        height: '250px',
        backgroundColor: 'rgba(255, 255, 255, 0.0)'
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    time: {
        useUTC: false
    },
    title: {
        text: ''
    },
    legend: {
        enabled: false
    },
    xAxis: [{
        title: {
            text: null
        },
        visible: false,
      }, {
        title: {
            text: ''
        },
        opposite: false,
        visible: true
    }],
    yAxis: [{
        title: {
            text: null
        },
        visible: false
      }, {
        title: {
            text: null
        },
        opposite: true,
        visible: false
    }],
    series: [
    {
      dataLabels: {
        enabled: true,
        color: 'green',
        align: 'left',
        style: {
          fontSize: "16px"
        },
        formatter: function() {
            return this.point.options.label;
        }
      },
      name: "bellcurve",
      type: 'bellcurve',
      xAxis: 1,
      yAxis: 1,
      baseSeries: 1,
      pointsInInterval: 10,
      intervals: 4,
      enableMouseTracking: false,
    }]
  }

  /**
   * 
   */
  constructor() { }

  /**
   * 
   */
  ngOnInit(): void { }

  /**
   * 
   */
  private updateChart(): void {

    if(this.data) {

      // Push to chart
      this.chart.addSeries({
        name: "data",
        type: 'line',
        data: this.data.data,
        visible: false
      });

      let you = Number(this.data.you);
      let median = this.data.median;
      let label_color = this.data.color;
      let label_align: string = 'left';

      if(you < median)
        label_align = 'right';
        

      // Add label : looking for the nearest point
      let nbpoints = this.chart.series[0].data.length;
      let index = 0;
      
      for(var i = 0; i < nbpoints; i++) {
        if(Number(this.chart.series[0].data[i].category) > you) {
          index = i;
          break;
        }
      }

      let previousvalue = 0
      if(index != 0) {
        previousvalue = Number(this.chart.series[0].data[index - 1].category);
      }
      let nextvalue = Number(this.chart.series[0].data[index].category);

      let previousdelta = (you-previousvalue);
      let nextdelta = (nextvalue-you);

      index = previousdelta < nextdelta ? index -1 : index;

      // Push to chart
      this.chart.series[0].data[index].update({
        label: String(you),
        dataLabels: {
          align: label_align == "right" ? 'right' : 'left',
          verticalAlign: 'bottom',
          color: label_color
        },
        marker: {
          enabled: true,
          fillColor: label_color,
          radius: 5,
          states: {
            hover: {
              fillColor: label_color
            }
          }
        }
      });

      // Update chart title
      this.addTitle();
    }
  }

  /**
   * 
   */
  private addTitle(): void {
    // Title
    this.chart.renderer.text(this.data.name, 20, 20)
      .attr({
        zIndex: 5
      })
      .css({
        color: 'black',
        fontSize: '12px',
        fontWeight: 'bold'
      })
    .add();

    let labelText = 'rank : ' + this.data.rank_letter ;
    this.chart.renderer.text(labelText, 20, 40)
      .attr({
        zIndex: 5
      })
      .css({
        color: 'black',
        fontSize: '12px',
      })
    .add();

    labelText = this.data.max + '<br/>';
    this.chart.renderer.text(labelText, 20, 80)
      .attr({
        zIndex: 5
      })
      .css({
        color: '#0072a3',
        fontSize: '14px',
        fontWeight: 'bold'
      })
    .add();

    labelText = this.data.median + '<br/>';
    this.chart.renderer.text(labelText, 25, 100)
      .attr({
        zIndex: 5
      })
      .css({
        color: '#0072a3',
        fontSize: '16px',
        fontWeight: 'bold'
      })
    .add();

    labelText = this.data.min + '<br/>';
    this.chart.renderer.text(labelText, 20, 120)
      .attr({
        zIndex: 5
      })
      .css({
        color: '#0072a3',
        fontSize: '14px',
        fontWeight: 'bold'
      })
    .add();
  }

}


<clr-tabs>
	<clr-tab>
        <button clrTabLink (click)="setVmware()">VMWARE</button>
		<clr-tab-content *clrIfActive="true">
            <ng-template load-cons-cal></ng-template>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
        <button clrTabLink (click)="setAws()">AWS</button>
		<clr-tab-content *clrIfActive="false">
			<div class="clr-row" *ngIf="this.message.isValidAws">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<app-account-management [targetInfrastructure]="this.message.powerUsageEnv" (loadCloud)="loadCloud()"></app-account-management>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<ng-template load-cons-cal></ng-template>
				</div>
			</div>
			<div *ngIf="!this.message.isValidAws">
				<div class="card">
						<div class="card-block">
								<div class="card-text">
										<div>
												<strong class="center">No data available !</strong>
										</div>
								</div>
						</div>
				</div>
			</div>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
        <button clrTabLink (click)="setAzure()">AZURE</button>
		<clr-tab-content *clrIfActive="false">
            <div class="clr-row" *ngIf="this.message.isValidAzure">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<app-account-management [targetInfrastructure]="this.message.powerUsageEnv" (loadCloud)="loadCloud()"></app-account-management>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<ng-template load-cons-cal></ng-template>
				</div>
			</div>
			<div *ngIf="!this.message.isValidAzure">
				<div class="card">
						<div class="card-block">
								<div class="card-text">
										<div>
												<strong class="center">No data available !</strong>
										</div>
								</div>
						</div>
				</div>
			</div>
		</clr-tab-content>
	</clr-tab>
</clr-tabs>

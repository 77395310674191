import {Component, OnInit} from '@angular/core';
import {JsonloaderService, NetscopeService} from "@app/services";
import {Router} from "@angular/router";

@Component({
	selector: 'app-netscope-custom-protocols',
	templateUrl: './netscope-custom-protocols.component.html',
	styleUrls: ['./netscope-custom-protocols.component.css']
})
export class NetscopeCustomProtocolsComponent implements OnInit {

	isLoading = false;
	isPushingRules = false;
	reloadButtonColorClass = "btn-primary";
	jsonData;
	customProtocolsData;
	rules = []
	subscriptions = [];
	editingRules = [];

	computingResources = [];

	arithmetic_operators = [">", "<", "=", ">=", "<="];
	unary_operators = ["not"];
	n_ary_operators = ["or", "and"];
	targets = ["src.uuid", "src.ip", "dst.uuid", "dst.ip", "port"];
	numberTargets = ["port"];
	vmTargets = ["src.uuid", "dst.uuid"];
	ipTargets = ["src.ip", "dst.ip"];
	labels = {
		"and": "all the following are true",
		"or": "one at least is true",
		"not": "the following is false",
		"src.uuid": "source",
		"src.ip": "source ip",
		"dst.uuid": "destination",
		"dst.ip": "destination ip",
		"port": "port"
	};

	constructor(private netscopeService: NetscopeService, private route: Router, private jsonLoaderService: JsonloaderService) {
	}

	ngOnInit(): void {
		let subscription1 = this.jsonLoaderService.currentJsonSimpleSubject.subscribe(json => {
			this.jsonData = json;
			this.reloadData();
		});

		this.subscriptions = [subscription1];
	}

	ngOnDestroy = () => {
		for (let subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	reloadData = () => {
		this.isLoading = true;

		this.jsonData.vmSynthesis.map((vm) => {
			this.computingResources.push({
				"name": vm.name,
				"uuid": vm.uuid,
				"full_uuid": "vim.VirtualMachine:" + vm.uuid,
				"type": "vm"
			});
		});
		this.jsonData.hostSynthesis.map((server) => {
			this.computingResources.push({
				"name": server.name,
				"uuid": server.uuid,
				"full_uuid": "vim.HostSystem:" + server.uuid,
				"type": "server"
			});
		});

		this.netscopeService.getCustomProtocolsRules().subscribe((customProtocolsData) => {
			this.customProtocolsData = customProtocolsData;
			this.isLoading = false;
			this.reloadRules()
		});
	}

	reloadRules = () => {
		this.rules = this.processRulesFromApiToAngular(this.customProtocolsData.custom_rules);
	}

	processCriterion = (protocolRule, criterion, parent, vmsUuidsIdx, serversUuidsIdx) => {

		if (criterion == undefined) {
			return undefined;
		}

		let result = {
			name: "no criterion",
			files: [],
			type: 'empty',
			operator: "no_operator",
			editing: false,
            basic: false,
			showEditingButton: false,
			logicalModel: undefined,
			logicalModel2: criterion,
			parent: parent,
			protocolRule: protocolRule,
			errors: []
		};

		if (typeof (criterion) === "object") {
			Object.entries(criterion).map((e) => {
				let operator = e[0];
				if (this.n_ary_operators.indexOf(operator) !== -1) {
					// @ts-ignore
					let subcriterion = e[1].map((subcriteria) => this.processCriterion(protocolRule, subcriteria, result, vmsUuidsIdx, serversUuidsIdx))
					let flattenSubcriterion = subcriterion.flat();

					Object.assign(result, {
						name: this.labels[operator],
						files: flattenSubcriterion,
						type: 'nAryOperator',
						operator: operator,
						logicalModel: e,
					});
				}
				if (this.unary_operators.indexOf(operator) !== -1) {
					// @ts-ignore
					let subcriterion = e[1].map((subcriteria) => this.processCriterion(protocolRule, subcriteria, result, vmsUuidsIdx, serversUuidsIdx))
					let flattenSubcriterion = subcriterion.flat();

					Object.assign(result, {
						name: this.labels[operator],
						files: flattenSubcriterion,
						type: 'unaryOperator',
						operator: operator,
						logicalModel: e,
					});
				}
				if (this.arithmetic_operators.indexOf(operator) !== -1) {
					let parts = Object.entries(e[1]);
					let operatorStr = e[0];
					let leftValue = parts[0][0];
					let rightValue = this.numberTargets.indexOf(leftValue) === -1 ? parts[0][1] : parseInt(parts[0][1]);
					let leftLabel = this.labels[leftValue];
					let rightLabel = rightValue;
					let rightObject = undefined

					// Check if rightLabel is a host or a VM
					if (vmsUuidsIdx.hasOwnProperty(rightLabel)) {
						rightObject = {
							uuid: rightLabel,
							name: vmsUuidsIdx[rightLabel],
							type: "vm"
						};
					}

					if (serversUuidsIdx.hasOwnProperty(rightLabel)) {
						rightObject = {
							uuid: rightLabel,
							name: serversUuidsIdx[rightLabel],
							type: "server"
						};
					}

					let name = `${leftLabel} ${operatorStr} ${rightValue}`;

					Object.assign(result, {
						name: name,
						type: 'arithmeticOperator',
						left: leftValue,
						right: rightValue,
						leftLabel: leftLabel,
						rightLabel: rightLabel,
						rightObject: rightObject,
						operator: operatorStr,
						logicalModel: e,
						logicalModel2: criterion,
						parent: parent
					});
				}
			});
		}

		return result;
	}

	processRulesFromApiToAngular = (rules) => {
		// Collect uuids of VMs and ESXi servers
		let vmsUuidsIdx = {};
		this.jsonData.vmSynthesis.map((vm) => {
			vmsUuidsIdx[vm.uuid] = vm.name;
			vmsUuidsIdx[`vim.VirtualMachine:${vm.uuid}`] = vm.name;
		})
		let serversUuidsIdx = {};
		this.jsonData.hostSynthesis.map((server) => {
			serversUuidsIdx[server.uuid] = server.name;
			serversUuidsIdx[`vim.HostSystem:${server.uuid}`] = server.name;
		})

		return rules.map((protocolRule) => {
			let processedCriterion = this.processCriterion(protocolRule, protocolRule.criterion, undefined, vmsUuidsIdx, serversUuidsIdx);
			console.log(processedCriterion);
			return {
				rawRule: protocolRule,
				protocol: protocolRule.protocol,
				showEditingFeatures: this.editingRules.indexOf(protocolRule) !== -1,
				criterion: processedCriterion,
				checkMade: false,
			};
		});
	}

	getChildren = (folder) => folder.files;

	pushData = () => {
		this.isPushingRules = true;
		this.netscopeService.pushCustomProtocols(this.customProtocolsData).subscribe(() => {
			setTimeout(() => {
				this.isPushingRules = false;
			}, 300);
		})
	}

	processEditing = (file) => {
		file.editing = false;

		if (file.type === "nAryOperator") {
			let existingKey = Object.keys(file.logicalModel2)[0];
			if (file.operator !== existingKey) {
				file.logicalModel2[file.operator] = file.logicalModel2[existingKey];
				delete file.logicalModel2[existingKey];
			}
		}

		if (file.type === "unaryOperator") {
			let existingKey = Object.keys(file.logicalModel2)[0];
			if (file.operator !== existingKey) {
				file.logicalModel2[file.operator] = file.logicalModel2[existingKey];
				delete file.logicalModel2[existingKey];
			}
		}

		if (file.type === "arithmeticOperator") {
			let existingKey = Object.keys(file.logicalModel2)[0];
			if (file.operator !== existingKey) {
				file.logicalModel2[file.operator] = file.logicalModel2[existingKey];
				delete file.logicalModel2[existingKey];
			}
			let existingLeft = Object.keys(file.logicalModel2[file.operator])[0];
			if (file.left !== existingLeft) {
				delete file.logicalModel2[file.operator][existingLeft];
			}

			if (this.numberTargets.indexOf(file.left) !== -1) {
				file.right = parseInt(file.right);
			}

			file.logicalModel2[file.operator][file.left] = file.right;
		}

		this.reloadRules();
	}

	deleteRule = (file) => {
		if (file.parent === undefined) {
			file.protocolRule.criterion = undefined
		} else {
			let existingKey = Object.keys(file.parent.logicalModel2)[0];
			file.parent.logicalModel2[existingKey] = file.parent.logicalModel2[existingKey].filter((f) => f !== file.logicalModel2);
		}
		this.reloadRules();
	}

	updateProtocolName = (protocolRule) => {
		protocolRule.rawRule.protocol = protocolRule.protocol
	}

	deleteProtocol = (protocol) => {
		let indexOfProtocol = this.customProtocolsData.custom_rules.indexOf(protocol.rawRule);
		if (indexOfProtocol !== -1) {
			this.customProtocolsData.custom_rules = this.customProtocolsData.custom_rules.filter((e) => e !== protocol.rawRule);
			this.reloadRules();
		}
	}

	startEditing = (rule) => {
		rule.showEditingFeatures = true;
		this.editingRules.push(rule.rawRule);
	}

	stopEditing = (rule) => {
		rule.showEditingFeatures = false;
		this.editingRules = this.editingRules.filter((r) => r !== rule.rawRule);
		this.reloadRules();
	}

	addCompositeCriteria = (protocolRule, file) => {
		let newRule = {
			and: []
		};
		if (file === undefined) {
			protocolRule.rawRule.criterion = newRule
		} else {
			let existingKey = Object.keys(file.logicalModel2)[0];
			file.logicalModel2[existingKey].push(newRule);
		}
		this.reloadRules();
	}

	addNotCriteria = (protocolRule, file) => {
		let newRule = {
			not: []
		};
		if (file === undefined) {
			protocolRule.rawRule.criterion = newRule
		} else {
			let existingKey = Object.keys(file.logicalModel2)[0];
			file.logicalModel2[existingKey].push(newRule);
		}
		this.reloadRules();
	}

	addSimpleCriteria = (protocolRule, file) => {
		let newRule = {
			"=": {
				"src.uuid": ""
			},
		};
		if (file === undefined) {
			protocolRule.rawRule.criterion = newRule
		} else {
			let existingKey = Object.keys(file.logicalModel2)[0];
			file.logicalModel2[existingKey].push(newRule);
		}
		this.reloadRules();
	}

	updateRightSelectedResource = (file, computingResource) => {
		file.right = computingResource.full_uuid;
	}

	keyboardSelect = (file, event) => {
		file.right = event.model.full_uuid;
	}

	addNewProtocol = () => {
		let newProtocolName = "new_protocol";
		this.customProtocolsData.custom_rules.push({
			protocol: newProtocolName,
			criterion: undefined
		})
		this.reloadRules();
	}

	checkRule = (rule) => {
		rule.checkMade = true;
		console.log(`checkRule(${rule})`);
		return this.checkCriterion(rule.criterion);
	}

	checkCriterion = (criterion) => {
		let vmUuids = this.jsonData.vmSynthesis.map((vm) => `vim.VirtualMachine:${vm.uuid}`);

		console.log(`checkCriterion(${criterion})`);

		if (criterion.type === "nAryOperator") {
			let isOk = true;
			for (let subCriterion of criterion.files) {
				if (! this.checkCriterion(subCriterion)) {
					isOk = false;
				}
			}
			if (!isOk) {
				criterion.errors = [`at least one of the sub criterion is not valid`]
			}
			return isOk;
		}

		if (criterion.type === "unaryOperator") {
			let isOk = true;
			for (let subCriterion of criterion.files) {
				if (! this.checkCriterion(subCriterion)) {
					isOk = false;
				}
			}
			if (!isOk) {
				criterion.errors = [`at least one of the sub criterion is not valid`]
			}
			return isOk;
		}

		if (criterion.type === "arithmeticOperator") {
			if (this.ipTargets.indexOf(criterion.left) !== -1) {
				/* Check if string is IP */
				function checkIfValidIP(str) {
					// Regular expression to check if string is a IP address
					const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

					return regexExp.test(str);
				}
				let isValidIp = checkIfValidIP(criterion.right);
				if (!isValidIp) {
					criterion.errors = [`${criterion.right} is not a valid IP`]
				}
				return isValidIp;
			}
			if (this.numberTargets.indexOf(criterion.left) !== -1) {
				let isNumber = false
				try {
					parseInt(criterion.right);
					isNumber = !isNaN(criterion.right);
				} catch (e) {
				}
				if (!isNumber) {
					criterion.errors = [`${criterion.right} is not a valid number`]
				}
				return isNumber;
			}
			if (this.vmTargets.indexOf(criterion.left) !== -1) {
				let isValidVmUuid = true;
				if (vmUuids.indexOf(criterion.right) === -1) {
					isValidVmUuid = false;
				}
				if (!isValidVmUuid) {
					criterion.errors = [`${criterion.right} is not a known VM uuid`]
				}
				return isValidVmUuid;
			}
		}

		return true;
	}
}

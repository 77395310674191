import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { first } from 'rxjs/operators';
import { zip } from 'rxjs';
import { ClrWizard, ClrSelectedState, ClrLoadingState, ClrDatagridSortOrder } from "@clr/angular";
import { FormBuilder, Validators, FormGroup, FormControl, ValidatorFn } from '@angular/forms';

import * as moment from 'moment';

import { AccountService, JsonloaderService, ManagementService, ShareService } from '@app/services';

import { ElementView, Message, Role, User, UserRec, SelectionFilterRec, AliasRec, Alias, UserCurrencies, CreateMode } from '@app/model';

import { getUserCurrency } from '../../../assets/js/tools.js';

import { SelectiontreeComponent } from '@app/selectiontree/selectiontree.component';
import { SelectiontreeDirective } from '@app/directives/selectiontree.directive';
import { WizardselectiontreeDirective } from '@app/directives/wizardselectiontree.directive';


@Component({
	selector: 'app-usermgtroot',
	templateUrl: './usermgtroot.component.html',
	styleUrls: ['./usermgtroot.component.css']
})
export class UsermgtrootComponent implements AfterViewInit, OnInit {

	@ViewChild("wizardUser") wizardusr: ClrWizard;
	@ViewChild("wizardAlias") wizardalias: ClrWizard;

	@ViewChild(SelectiontreeDirective) addTree: SelectiontreeDirective;
	@ViewChildren(WizardselectiontreeDirective) addWizardTrees: any;

	message: Message;

	private componentRef: ComponentRef<any>;

	private componentWizardRefs: Array<ComponentRef<any>> = [];

	dataOrder = ClrDatagridSortOrder.ASC;

	roles: Role[] = [];
	users: User[] = [];
	aliases: Alias[] = [];

	now: any;

	alias_create_mode: CreateMode = CreateMode.LOCAL;
	local_mode: CreateMode = CreateMode.LOCAL;
	ad_mode: CreateMode = CreateMode.LDAP;

	// FOR AD
	ad_settings: any = {};
	ad_form: FormGroup;
	ad_button_state: ClrLoadingState;
	ad_connect: boolean;
	ad_message: string;
	ad_type: string;
	ad_data: Array<any>;
	ad_list_unselected: Array<any> = [];
	ad_persons_selected: Array<any> = [];
	ad_groups_selected: Array<any> = [];
	ad_user_selected: User;
	ad_user_wait: boolean = false;
	ad_subscription: any;
	
	// FOR USERS (aka GROUP)
	currentUser: User;

	currencies: string[] = UserCurrencies;

	user_model: any = {};
	edit_user_model: any = {};

	selected_user: User = {
		login: 'root',
		alias: '',
		email: '',
		currency: '',
		role: 'admin',
		token: '',
		last_co: 0,
		isMenuCloud: true,
		isMenuKpi: true,
		isMenuAlert: true,
		isMenuTrouble: true,
		isMenuCapa: true,
		isMenuGod: true,
		isMenuReco: true,
		isMenuReport: true,
		isMenuGreenit: true,
		isMenuDCNetScope: true,
		isMenuVcloud: true,
		isMenuVirtindex: true,
		isMenuExternalCloud: true,
		isDashboardT1: true,
		isDashboardT2: true,
		isDashboardT3: true,
		isDashboardT4: true,
		isDashboardT5: true,
		isDashboardT6: true
	};

	user_aliases: Array<Alias>;

	isWizardUser: boolean = false;
	isEditUser: boolean = false;
	isUpdateUser: boolean = false;
	isDeleteUser: boolean = false;
	isAliasUser: boolean = false;
	isRootUser: boolean = false;
	isCurrencyUser: boolean = false;

	confirmCurrency: boolean;
	updateCurrency: boolean = false;

	// FOR ALIAS (aka USER)
	alias_selected: Alias = {
		user: '',
		login: 'root',
		is_local: 1,
		is_ldap: 0,
		is_openid: 0
	}

	alias_model: any = {};
	edit_alias_model: any = {};
	
	signUpForm: FormGroup;

	isWizardAlias: boolean = false;
	isEditAlias: boolean = false;
	isUpdateAlias: boolean = false;
	isDeleteAlias: boolean = false;
	isRootAlias: boolean = false;
	

	// FOR ALL
	isModalPassword: boolean = false;
	
	update_state: string = 'error';
	update_result: string = 'changes have not been applied.';

	// FOR SCOPE
	isModalScope: boolean = false;

	elementViews: ElementView[] = [];
	element_lst: SelectionFilterRec[] = [];
	tree_view: any;
	
	// Scope not match
	view_not_match: string[] = [];
	isScopeError: boolean = false;


	constructor(
		public componentFactoryResolver: ComponentFactoryResolver,
		private authentication_svc: AccountService,
		private management_svc: ManagementService,
		private message_svc: ShareService,
		private fb: FormBuilder,
		private cd: ChangeDetectorRef,
		private json_svc: JsonloaderService) {

		this.now = moment().format('MMMM Do YYYY');

		this.signUpForm = this.fb.group({
			password: ['', [
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(20)
			]],
			confirmPassword: ['', [
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(20),
				this.passwordMatchValidator('password')
			]]
		});

		this.ad_form = this.fb.group({
			login: [''],
			password: ['']
		});

		this.ad_user_selected = this.selected_user;
	}

	ngOnInit(): void {
		this.authentication_svc.user.subscribe(user => this.currentUser = user);
		this.message_svc.currentMessage.subscribe(message => this.message = message);
		this.message.elementViewType = 'server-scope';

		this.getRoles();
	}

	ngAfterViewInit(): void {
		this.getUsers();
		this.getAliases();
	}

	ngAfterViewChecked(): void {
		this.cd.detectChanges();
	}

	// CREATE USER (aka GROUP) WIZARD
	initWizardUser(): void {
		this.isWizardUser = true;
		this.isWizardAlias = false;
		this.isEditUser = false;
		this.message.isTreeAnalysis = false;

		this.user_model = {
			name: '',
			email: '',
			currency: this.getCurrency("8364"),
			role: 'admin',
			error: false,
			email_error: false,
			errorName: ''
		};

		if (this.componentRef != undefined)
			this.componentRef.destroy();

		this.wizardusr.open();
	}

	goBackUser(): void {
	}

	doResetUser(): void {
		this.isWizardUser = false;
		this.isEditUser = false;
		this.message.isTreeAnalysis = false;
		this.wizardusr.reset();
	}

	doFinishUser(): void {
		// SYNC FOR WIZARD AD
		this.ad_user_wait = true;
		this.ad_button_state = ClrLoadingState.LOADING;

		// RECORD USER
		let user_rec: UserRec = {
			login: this.user_model.name,
			email: this.user_model.email,
			currency: this.encodeCurrency(this.user_model.currency),
			role: this.user_model.role,
		};

		this.authentication_svc.addUser(user_rec).pipe(first()).subscribe(
			success => {
				this.processingUser(this.user_model.name);
			},
			error => {
				if (error != null)
					console.log(error)
			}
		);

		// RECORD ELEMENT VIEW
		this.element_lst = this.buildElementToRec(this.user_model.name);
		if (this.element_lst.length > 0) {
			this.management_svc.addSelectionList(this.element_lst).subscribe(
				complete => {
					this.doResetUser();
				},
				error => {
					if (error != null)
						console.log(error);
				}
			);
		}
	}

	// CREATE ALIAS (aka USER) WIZARD
	initWizardAlias(): void {
		this.wizardalias.open();
	}

	goBackAlias(): void {
	}

	doResetAlias(): void {
		this.wizardalias.reset();
	}

	doFinishAlias(): void {
		switch(this.alias_create_mode) {
			case CreateMode.LOCAL:
				this.doFinishAliasLocal();
			break;
			case CreateMode.LDAP:
				this.doFinishAliasAD();
			break;
		}
		this.wizardalias.reset();
	}

	initAliasMode(): void {
		switch(this.alias_create_mode) {
			case CreateMode.LOCAL:
				this.initAliasLocal();
			break;
			case CreateMode.LDAP:
				this.initAliasAD();
			break;
		}
	}


	// CREATE ALIAS (aka USER) LOCAL
	initAliasLocal(): void {
		this.isWizardUser = false;
		this.isWizardAlias = true;

		this.alias_model = {
			name: '',
			user: this.selected_user,
			error: false,
			email_error: false,
			errorName: ''
		};

		this.signUpForm.value.password = '';
		this.signUpForm.value.confirmPassword = '';
	}

	doFinishAliasLocal(): void {

		// RECORD ALIAS
		let alias_rec: AliasRec = {
			login: this.alias_model.name,
			password: this.authentication_svc.crypt(this.signUpForm.value.password),
			user: this.alias_model.user.login,
			is_local: 1,
			is_ldap: 0,
			is_openid: 0
		};

		this.authentication_svc.addAlias(alias_rec).pipe(first()).subscribe(
			all => {
				this.getAliases();
				this.message.genericInfo = 'user ' + this.alias_model.name + ' has been created !';
				this.message.genericInfoType = 'info';
				this.message.isNoInfo = false;
			},
			error => {
				console.log(error);
				this.getAliases();
				this.message.genericInfo = 'An error occured during user creation (message : '+ error.error.message +') !';
				this.message.genericInfoType = 'warning';
				this.message.isNoInfo = false;
			}
		);

	}

	// CREATE ALIAS (aka USER) FROM AD
	initAliasAD(): void {
		this.ad_form = this.fb.group({
			login: [''],
			password: ['']
		});

		this.ad_button_state = ClrLoadingState.DEFAULT;
		this.ad_connect = false;
		this.ad_message = "Please connect to your AD / OpenLDAP in order to list persons and groups";
		this.ad_type = "info";

		this.ad_settings.hostname = "---";
		this.ad_settings.group = "---";
		this.ad_settings.lock = true;

		// LDAP settings
		this.authentication_svc.getADSettings().subscribe(
			data => {
				if((<any>data).hostname != "" && (<any>data).hostname.group != "") {
					this.ad_settings = data;
					this.ad_settings.lock = false;
				} else {
					this.ad_message = "Empty hostname or group, please check your LDAP settings !";
					this.ad_type = "danger";	
				}
			},
			error => {
				console.log(error);
				this.ad_message = "Can not get LDAP settings !";
				this.ad_type = "danger";
			}
		);
	}

	doFinishAliasAD(): void {

		let add_subs: Array<any> = [];

		this.message.genericInfoType = 'info';
		this.message.genericInfo = 'users processing ...';
		this.message.isNoInfo = false;

		// CONCAT
		let ad_list_selected: any[] = this.ad_persons_selected.concat(this.ad_groups_selected);

		// SAVE ALIASES
		ad_list_selected.forEach(element => {

			// RECORD ALIAS
			let alias_rec: AliasRec = {
				login: element.samaccountname,
				password: this.authentication_svc.crypt(''), // empty default
				user: this.ad_user_selected.login,
				is_local: 0,
				is_ldap: 1,
				is_openid: 0
			};

			add_subs.push(this.authentication_svc.addAlias(alias_rec).pipe(first()));
		});

		zip(...add_subs).subscribe(
			all => {
				this.getAliases();
				this.message.genericInfo = 'users have been created !';
				this.message.genericInfoType = 'info';
				this.message.isNoInfo = false;
			},
			error => {
				console.log(error);
				this.getAliases();
				this.message.genericInfo = 'An error occured during users creation (message : '+ error.error.message +') !';
				this.message.genericInfoType = 'warning';
				this.message.isNoInfo = false;
			}
		);	
	}

	connectAD(): void {

		this.ad_button_state = ClrLoadingState.LOADING;
		this.ad_message = "Connecting, please wait ...";
		this.ad_type = "info";

		// Connect to AD
		this.authentication_svc.getADInfo(this.ad_form).subscribe(
			data => {
				this.ad_button_state = ClrLoadingState.DEFAULT;
				this.ad_connect = true;
				this.ad_data = (<any>data).entries;
				this.ad_message = "Connection established !";
				this.ad_type = "success";
			},
			error => {
				this.ad_button_state = ClrLoadingState.DEFAULT;
				this.ad_message = error.error;
				this.ad_type = "warning";
			}
		);
	}

	selectAliasAD(): void {

		this.ad_user_selected = this.selected_user;
		this.ad_button_state = ClrLoadingState.DEFAULT;

		this.ad_persons_selected = [];
		this.ad_groups_selected = [];
		this.ad_list_unselected = [];

		this.ad_data.forEach(element => {

			let samaccountname: string;
			let objectclass: string;
			let mail: string = "n/a";

			element.attributes.forEach(attr => {
				switch(String(attr.attribute.attribute).toLowerCase()) {
					case "objectclass":
						let value = String(attr.value.value).toLowerCase();
						if(value == "person" || value == "group")
							objectclass = value;
					break;
					case "samaccountname":
						samaccountname = String(attr.value.value);
					break;
					case "mail":
						mail = String(attr.value.value);
					break;
				}
			});

			// Check duplicates & if alias already exists
			if(!this.ad_list_unselected.find(u => u.samaccountname == samaccountname)) {
				let lock = this.existsAlias(samaccountname);
				
				if(samaccountname && objectclass)
					this.ad_list_unselected.push({'samaccountname' : samaccountname, 'objectclass': objectclass , 'mail': mail, 'lock': lock});
			}
		});
		
	}

	filterADList(objclass: string): any[] {
		return this.ad_list_unselected.filter(e => e.objectclass == objclass);
	}

	// ALIAS (aka USER) PART
	existsAlias(login: string): boolean {
		return this.aliases.find(alias => alias.login === login) != undefined;
	}

	removeAlias(): void {
		this.isDeleteAlias = false
		if (this.alias_selected.login != "root") {
			this.authentication_svc.deleteAlias(this.alias_selected).pipe(first()).subscribe(
				complete => {
					this.message.genericInfoType = 'info';
					this.message.genericInfo = 'user ' + this.alias_selected.login + ' has been deleted';
					this.message.isNoInfo = false;
					this.getAliases();
				},
				error => {
					if (error != null)
						console.log(error)
				}
			);
		}
	}

	editAlias(): void {
		this.isEditAlias = !this.isEditAlias;

		if(this.isEditAlias == true) {
			this.isUpdateAlias = false;

			this.signUpForm.value.password = '';
			this.signUpForm.value.confirmPassword = '';

			this.update_state = 'error';
			this.update_result = 'Changes have not been applied.';

			if (this.alias_selected.login == "root")
				this.isRootAlias = true;
			else
				this.isRootAlias = false;

			this.edit_alias_model.user = this.getUser(this.alias_selected.user);
			this.edit_alias_model.password = "";
			this.edit_alias_model.is_ldap = this.alias_selected.is_ldap;
		}
	}

	enableAliasUpdate(): void {
		this.isUpdateAlias = true;
		this.update_state = 'error';
		this.update_result = 'changes have not been applied.';
	}

	updateAlias(): void {

		// RECORD ALIAS
		let alias_rec: AliasRec = {
			login: this.alias_selected.login,
			password: this.authentication_svc.crypt(this.edit_alias_model.password),
			user: this.edit_alias_model.user.login,
			is_local: this.alias_selected.is_local,
			is_ldap: this.alias_selected.is_ldap,
			is_openid: this.alias_selected.is_openid
		};

		this.authentication_svc.updateAlias(alias_rec).pipe(first()).subscribe(
			all => {
				this.alias_selected.user = this.edit_alias_model.user.login;
				this.update_state = 'success';
				this.update_result = 'changes have been applied.';
			},
			error => {
				if (error != null)
					console.log(error)	
			}
		);
		
		this.isUpdateAlias = false;
	}

	getAliasesFromUser(user: User): Array<Alias> {
		let aliases = this.aliases.filter(alias => alias.user === user.login);
		if(aliases.length > 0)
			return aliases;
		else
			return new Array<Alias>();
	}

	// USER (aka GROUP) PART
	existsUser(login: string): boolean {
		return this.users.find(user => user.login === login) != undefined;
	}

	switchUser(val: User): void {
		this.isEditUser = false;
		this.update_state = 'error';
		this.update_result = 'Changes have not been applied.';
		this.loadUser(val);
	}

	removeUser(): void {
		this.isDeleteUser = false
		if (this.selected_user.login != "root") {
			this.authentication_svc.deleteUser(this.selected_user).pipe(first()).subscribe(
				complete => {
					this.message.genericInfoType = 'info';
					this.message.genericInfo = this.selected_user.login + ' and associated users have been deleted';
					this.message.isNoInfo = false;
					this.getUsers();
					this.getAliases();
				},
				error => {
					if (error != null)
						console.log(error)
				}
			);
		}
	}

	editUser(): void {
		this.isEditUser = !this.isEditUser;
		this.isUpdateUser = false;

		if(this.isEditUser == true) {
			if (this.selected_user.login == "root")
				this.isRootUser = true;
			else
				this.isRootUser = false;

			this.edit_user_model.currency = this.getCurrency(this.selected_user.currency);
			this.edit_user_model.email = this.selected_user.email;
			this.edit_user_model.role = this.selected_user.role;

			this.edit_user_model.rate = 1;
			this.confirmCurrency = true;
		}
	}

	updateUser(): void {
		
		// UPDATE USER
		let user_rec: UserRec = {
			login: this.selected_user.login,
			email: this.edit_user_model.email,
			currency: this.encodeCurrency(this.edit_user_model.currency),
			role: this.edit_user_model.role,
		};

		this.authentication_svc.updateUser(user_rec).pipe(first()).subscribe(
			success => {
				this.selected_user.email = this.edit_user_model.email;
				this.selected_user.currency = this.encodeCurrency(this.edit_user_model.currency);
				this.selected_user.role = this.edit_user_model.role;

				// Update current user
				if(this.selected_user.login == this.currentUser.login) {
					this.currentUser.email = this.edit_user_model.email;
					this.currentUser.currency = this.selected_user.currency
					this.currentUser.role = this.edit_user_model.role;

					this.authentication_svc.changeUser(this.currentUser);
				}

				this.update_state = 'success';
				this.update_result = 'changes have been applied.';
			},
			error => {
				if (error != null)
					console.log(error)
			}
		);

		// UPDATE ELEMENT VIEW
		if (this.selected_user.login != "root") {
			//this.element_lst = this.buildElementToRec(this.selected_user.login);

			if (this.element_lst.length > 0) {
				this.management_svc.addSelectionList(this.element_lst).subscribe(
					complete => {
						this.message.genericInfoType = 'info';
						this.message.genericInfo = 'update processing ...';
						this.message.isNoInfo = false;
						this.authentication_svc.checkUser(this.selected_user.login, 'update').subscribe(
							complete => {
								this.message.genericInfo = 'group ' + this.selected_user.login + ' is ready';
								this.message.isNoInfo = false;
							},
							error => {
								console.log(error);
							}
						);
					},
					error => {
						if (error != null)
							console.log(error);
					}
				);
			}
			this.message.isTreeAnalysis = false;
		}

		// UPDATE CURRENCY
		if(this.selected_user.login == "root" && this.updateCurrency) {
			
			this.authentication_svc.updateCurrencyUser(this.edit_user_model.rate).pipe(first()).subscribe(
				success => {
					// Delete all jsons & recompute current filter
    				this.json_svc.cleanAllJson().subscribe(
						success => {
							// Show progress
							this.message.waiting = true;

							// Check / create json files
							this.json_svc.checkJson(this.currentUser.login, this.message.currentFilter).subscribe(
								complete => {
									// Reload json
									this.json_svc.changeJson(this.currentUser.login, this.message.currentFilter);
								},
								error => {
									//console.log(error);
									// Reload json
									this.json_svc.changeJson(this.currentUser.login, this.message.currentFilter);
								}
							);
						},
						error => { if (error != null) console.log(error) }
					);
				},
				error => { if (error != null) console.log(error) }
			);
		}

		this.isUpdateUser = false;
	}

	enableUserUpdate(): void {
		this.isUpdateUser = true;
		this.update_state = 'error';
		this.update_result = 'changes have not been applied.';
	}

	updateUserCurrency(): void {
		this.isCurrencyUser = true;
	}

	confirmUserCurrency(valid: boolean): void {
		this.isCurrencyUser = false;
		if(valid) {
			this.enableUserUpdate();
			this.updateCurrency = true;
		} else {
			this.edit_user_model.currency = this.getCurrency(this.selected_user.currency);
		}
	}

	checkUserCurrencyRate(): void {
		if(Number.parseFloat(this.edit_user_model.rate) && Number(this.edit_user_model.rate) > 0) {
			this.confirmCurrency = true;
		} else
			this.confirmCurrency = false;
	}

	showAliasForUser(user: User): void {
		this.user_aliases = this.getAliasesFromUser(user);
		this.isAliasUser = true;	
	}

	// SCOPE PART
	loadScope(): void {
		if (this.componentRef != undefined)
			this.componentRef.destroy();

		if (this.componentWizardRefs != undefined)
			this.componentWizardRefs.forEach(element => {
				element.destroy();
			});;

		if (this.isWizardUser) {
			if (this.addWizardTrees != undefined) {
				this.addWizardTrees._results.forEach(element => {
					const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectiontreeComponent);
					const viewContainerRef = element.viewContainerRef;
					viewContainerRef.clear();
					this.componentWizardRefs.push(viewContainerRef.createComponent(componentFactory));
				});
			}
		} else {
			if (this.addTree != undefined) {
				const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectiontreeComponent);
				const viewContainerRef = this.addTree.viewContainerRef;
				viewContainerRef.clear();
				this.componentRef = viewContainerRef.createComponent(componentFactory);
				setTimeout(() => this.loadElementScope(), 500);
			}
		}

	}

	updateScope(): void {
		if (this.selected_user.login != "root") {
			this.element_lst = this.buildElementToRec(this.selected_user.login);
			if (this.element_lst.length > 0) {
				this.management_svc.checkUserSelection(this.element_lst).subscribe(
					data => {
						if (data.length > 0) {
							this.view_not_match = data;
							this.isScopeError = true;
							this.isUpdateUser = false;
						} else {
							if (this.message.isTreeAnalysis)
								this.isUpdateUser = true;
						}
					},
					error => {
						if (error != null)
							console.log(error);
					}
				);
			}
		}
	}

	callCollapse(): void {

		this.tree_view = this.message.elementViewTree;

		for (var i = 0; i < this.tree_view.length; ++i) {
			this.tree_view[i].opened = false;
		}
	}

	callExpand(): void {

		this.tree_view = this.message.elementViewTree;

		for (var i = 0; i < this.tree_view.length; ++i) {
			this.tree_view[i].opened = true;
			this.expandChild(this.tree_view[i]);
		}
	}

	selectALL(): void {

		if (this.message.elementViewTree != null) {
			for (var i = 0; i < this.message.elementViewTree.length; ++i) {
				this.message.elementViewTree[i].selected = ClrSelectedState.SELECTED;
				this.enableChild(this.message.elementViewTree[i]);
			}
		}
	}

	unSelect(): void {

		if (this.message.elementViewTree != null) {
			for (var i = 0; i < this.message.elementViewTree.length; ++i) {
				this.message.elementViewTree[i].selected = ClrSelectedState.UNSELECTED;
				this.disableChild(this.message.elementViewTree[i]);
			}
		}
	}

	// SETTINGS PART
	checkName(data: any): void {
		data.name = data.name.toLowerCase();

		if ((this.isWizardUser && this.existsUser(data.name)) || (this.isWizardAlias && this.existsAlias(data.name))) {
			data.error = true;
			data.errorName = 'to ' + data.name + ' (name already exists)';
		} else if (data.name == "all") {
			data.error = true;
			data.errorName = 'to ' + data.name;
		} else if (data.name.length == 0) {
			data.error = true;
			data.errorName = 'empty';
		} else if (data.name.length > 0) {
			data.error = false;

			//Remove accent
			var accent = [
				/[\300-\306]/g, /[\340-\346]/g, // A, a
				/[\310-\313]/g, /[\350-\353]/g, // E, e
				/[\314-\317]/g, /[\354-\357]/g, // I, i
				/[\322-\330]/g, /[\362-\370]/g, // O, o
				/[\331-\334]/g, /[\371-\374]/g, // U, u
				/[\321]/g, /[\361]/g, // N, n
				/[\307]/g, /[\347]/g, // C, c
			];
			var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

			for (var i = 0; i < accent.length; i++) {
				data.name = data.name.replace(accent[i], noaccent[i]);
			}

			// Remove specs chars
			// XXX authorized chars : - .  
			data.name = data.name.replace(/[\/\\&~"#'{}()\[\]|`^@+°=£$¨¤^µ*%§!:;?,<> ]/g, "_");
		}
	}

	checkMail(data: any): void {
		// Remove specs chars
		// XXX authorized chars : @ .  
		data.email = data.email.replace(/[\/\\&~"#'{}()\[\]|`+°=£$¨¤^µ*%§!:;?,<>_\- ]/g, ".");

		if (data.email.length == 0)
			data.email_error = true;
		else
			data.email_error = false;
	}

	formatEditDate(date: any): any {
		return moment(date).format('MMMM Do YYYY');
	}

	getCurrency(val: string): string {
		return getUserCurrency(val);
	}

	updatePwd(): void {
		this.isModalPassword = false;
		this.edit_alias_model.password = this.signUpForm.value.password;
		this.isUpdateAlias = true;	
	}

	// ALIAS (aka USER) PART
	private getAliases(): void {
		this.authentication_svc.getAliasList().pipe(first()).subscribe(
			data => {
				this.aliases = data;
				if (this.aliases.length > 0)
					this.alias_selected = this.aliases[0];
			},
			error => {
				if (error != null)
					console.log(error)
			}
		);
	}

	// USER (aka GROUP) PART
	private processingUser(name: string): void {
		this.message.genericInfoType = 'info';
		this.message.genericInfo = 'group processing ...';
		this.message.isNoInfo = false;
		this.authentication_svc.checkUser(name, 'create').subscribe(
			complete => {
				this.getUsers();
				this.doResetUser();
				this.message.genericInfo = 'group ' + name + ' has been created';
				this.message.isNoInfo = false;
			},
			error => {
				console.log(error);
				this.doResetUser();
			}
		);
	}

	private getUsers(): void {
		this.authentication_svc.getUserList().pipe(first()).subscribe(
			data => {
				this.users = data;
				if (this.users.length > 0)
					this.loadUser(this.users[0]);
				if(this.ad_user_wait) {
					this.ad_user_selected = this.getUser(this.user_model.name);
					this.ad_user_wait = false;
					this.ad_button_state = ClrLoadingState.DEFAULT;
				}
				
			},
			error => {
				if (error != null)
					console.log(error)
				this.ad_user_wait = false;
				this.ad_button_state = ClrLoadingState.DEFAULT;
			}
		);
	}

	private getUser(login: string): User {
		return this.users.find(user => user.login === login);
	}

	private loadUser(user: User): void {
		this.selected_user = user;
	}

	// ROLE PART
	private getRoles(): void {
		this.authentication_svc.getRoleList().pipe(first()).subscribe(
			data => {
				if (data.length > 0)
					this.roles = data;
			},
			error => {
				if (error != null)
					console.log(error)
			}
		);
	}

	// SCOPE PART
	private loadElementScope(): void {

		this.message.elementViewUser = this.selected_user.login;
		this.management_svc.getUserSelection(this.message.elementViewUser, 'all').pipe(first()).subscribe(
			data => {
				for (var i = 0; i < this.message.elementViewTree.length; ++i) {
					const test: number = this.testSelected(data, this.message.elementViewTree[i].id);
					if (test == 1) {
						this.message.elementViewTree[i].selected = ClrSelectedState.SELECTED;
						this.enableChild(this.message.elementViewTree[i]);
					} else if (test == 0) {
						this.message.elementViewTree[i].selected = ClrSelectedState.INDETERMINATE;
						this.analyseTreeChild(data, this.message.elementViewTree[i]);
					}
				}
			},
			error => {
				if (error != null)
					console.log(error)
			}
		);
	}

	private findChildToRec(node: any, username: string): SelectionFilterRec[] {

		let element_lst: SelectionFilterRec[] = [];

		for (var i = 0; i < node.children.length; ++i) {
			if (node.children[i].selected > 0) {
				let flag = 1;
				if (node.children[i].selected == 2)
					flag = 0;

				let sfr: SelectionFilterRec = {
					filter: 'all',
					user: username,
					element_type: node.children[i].type,
					element_name: node.children[i].name,
					element_id: node.children[i].id,
					flag: flag,
					resource: 'server'
				};
				element_lst.push(sfr);

				if (node.children[i].type != "vm")
					element_lst = element_lst.concat(this.findChildToRec(node.children[i], username));
			}
		}

		return element_lst;
	}

	private buildElementToRec(username: string): SelectionFilterRec[] {

		let element_lst: SelectionFilterRec[] = [];

		for (var i = 0; i < this.message.elementViewTree.length; ++i) {
			if (this.message.elementViewTree[i].selected > 0) {
				let flag = 1;
				if (this.message.elementViewTree[i].selected == 2)
					flag = 0;

				let sfr: SelectionFilterRec = {
					filter: 'all',
					user: username,
					element_type: this.message.elementViewTree[i].type,
					element_name: this.message.elementViewTree[i].name,
					element_id: this.message.elementViewTree[i].id,
					flag: flag,
					resource: 'server'
				};
				element_lst.push(sfr);

				if (this.message.elementViewTree[i].type != "vm")
					element_lst = element_lst.concat(this.findChildToRec(this.message.elementViewTree[i], username));
			}
		}

		return element_lst;
	}

	private enableChild(node: any) {

		for (var i = 0; i < node.children.length; ++i) {
			node.children[i].selected = ClrSelectedState.SELECTED;
			if (node.children[i].type != "vm")
				this.enableChild(node.children[i]);
		}
	}

	private disableChild(node: any) {

		for (var i = 0; i < node.children.length; ++i) {
			node.children[i].selected = ClrSelectedState.UNSELECTED;
			if (node.children[i].type != "vm")
				this.disableChild(node.children[i]);
		}
	}

	private testSelected(data: any, uuid: string): number {

		var test: number = 2;

		var el = data.find(selection => selection.element_id === uuid);
		if (el != undefined) {
			if (el.flag == 1)
				test = 1;
			else if (el.flag == 0)
				test = 0;
		}

		return test;
	}

	private analyseTreeChild(data, node: any): void {

		if (node.name != undefined) {
			for (var i = 0; i < node.children.length; ++i) {
				const test: number = this.testSelected(data, node.children[i].id);
				if (test == 1) {
					node.children[i].selected = ClrSelectedState.SELECTED;
				} else if (test == 0) {
					node.children[i].selected = ClrSelectedState.INDETERMINATE;
					if (node.children[i].type != "vm")
						this.analyseTreeChild(data, node.children[i]);
				}
			}
		}
	}

	private expandChild(node) {

		for (var i = 0; i < node.children.length; ++i) {
			if (node.children[i].type != 'vm') {
				node.children[i].opened = true;
				this.expandChild(node.children[i]);
			}
		}
	}

	// SETTINGS PART
	private passwordMatchValidator(password: string): ValidatorFn {
		return (control: FormControl) => {
			if (!control || !control.parent) {
				return null;
			}
			return control.parent.get(password).value === control.value ? null : { mismatch: true };
		};
	}

	private encodeCurrency(val: string): string {

		let codeascii: string = '';
		var code: string = val.split('-')[0];
		for (var i = 0; i < code.length; i++) {
			if (code.charCodeAt(i) !== 160)
				codeascii += code.charCodeAt(i) + '-';
		}

		return codeascii.substring(0, codeascii.length - 1);
	}
}

<table class="table">
	<thead>
		<tr>
			<th class="left">Scope</th>
			<th class="left">Type</th>
			<th class="left">Recurrence</th>
			<th>Capacity (TB)</th>
			<th>Cost ({{globalCurrency}}/GB)</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td class="left">all Datastores</td>
			<td class="left">generic cost</td>
			<td class="left">annual</td>
			<td>{{model.datastoreCap}}</td>
			<td style="width:15%;vertical-align:top">
				<div class="clr-form-control" style="flex-direction:column;margin-top:unset;">
                                	<div class="clr-input-wrapper">
						<b><input class="clr-input" id="cost.sto.gen" type="number" [(ngModel)]="model.stoCostGeneric" (ngModelChange)="enableStoUpdate()" style="width:6em;text-align:left"></b>
                                                <clr-icon class="clr-validate-icon" *ngIf="model.stoCostGeneric == null" shape="exclamation-circle"></clr-icon>
                                     	</div>
                        	</div>
			</td>
		</tr>
	</tbody>
</table>

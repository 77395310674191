import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Counter, Settings, ThresholdStats } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class MeasurementService {


  constructor(private http: HttpClient) { }

  /**
   * use jetty api
   */
  getSettings() {
	return this.http.get<Settings[]>(`${environment.api2Url}/` + 'v1/settings').pipe(map(settings => {
		return settings;
	}));
  }

  /**
   * use jetty api
   */
  saveSettings(settings: Settings[]): void {
    this.http.post<Settings[]>(`${environment.api2Url}/` + 'v1/settings/', JSON.parse(JSON.stringify(settings))).subscribe(
		success => {
		  // Delete data into WD tables
		  this.http.delete(`${environment.api2Url}/` + 'v1/wd/').subscribe();
		  // Clean WD json files
		  this.http.delete(`${environment.apiUrl}/` + 'json/cleanwd/').subscribe();
		},
		error => {
		  if(error != null)
			console.log(error);
		}
	  );  
  }

  getCounters(uuid: string, type: string, start: number, end: number, granularity: string) {

	let CPU_USAGE_COUNTER: string = 'CPU_USAGE_INTRA';
	let RAM_USAGE_COUNTER: string = 'RAM_USAGE_INTRA';
	if(type == "HOST") {
		CPU_USAGE_COUNTER = 'CPU_USAGE';
		RAM_USAGE_COUNTER = 'RAM_USAGE';
	}

	return this.http.post<Counter[]>(`${environment.api2Url}/` + 'v1/counters/query',
	{
		"aggregationGranularity": granularity,
		"resourceType": type,
		"timeInterval": {
			"startTime": start,
			"endTime": end
		},
		"resourceFilter": {
			"fields": ["UUID"],
			"values": [[uuid]]
		},
		'metrics':[
        {
            "counter": CPU_USAGE_COUNTER,
            "rollup":[
                "AVG",
                "MIN",
                "MAX"
            ]
		},
		{
            "counter":"CPU_READY",
            "rollup":[
                "AVG",
                "MIN",
                "MAX"
            ]
        },
        {
            "counter": RAM_USAGE_COUNTER,
            "rollup":[
                "AVG",
                "MIN",
                "MAX"
            ]
		},
        {
            "counter":"DISK_IO",
            "rollup":[
                "AVG",
                "MIN",
                "MAX"
            ]
		},
        {
            "counter":"DISK_RW_LATENCY",
            "rollup":[
                "AVG",
                "MIN",
                "MAX"
            ]
		},
        {
            "counter":"NET_IO",
             "rollup":[
                "AVG",
                "MIN",
                "MAX"
            ]
			}
		]
	}
	).pipe(map(dp => {
		return dp;
	}));
	
  }

  getThresholds(uuid: string, type: string, start: number, end: number, granularity: string) {

	return this.http.post<Counter[]>(`${environment.api2Url}/` + 'v1/thresholds/query',
	{
		"aggregationGranularity": granularity,
		"resourceType": type,
		"timeInterval": {
			"startTime": start,
			"endTime": end
		},
		"resourceFilter": {
			"fields": ["UUID"],
			"values": [[uuid]]
		},
		"thresholds":[
            "CPU_2",
            "CPU_5",
            "CPU_10",
            "CPU_30",
            "CPU_70",
            "CPU_90",
            "CPU_100",
            "RAM_2",
            "RAM_5",
            "RAM_10",
            "RAM_30",
            "RAM_90",
            "RAM_100"
        ]
	}
	).pipe(map(dp => {
		return dp;
	}));
  }

  getTroubleGraph(uuid: string, type: string, start: number, end: number, metrics: any) {

	return this.http.post<Counter[]>(`${environment.api2Url}/` + 'v1/counters/query',
	{
		"aggregationGranularity": "HOURLY",
		"resourceType": type,
		"timeInterval": {
			"startTime": start,
			"endTime": end
		},
		"resourceFilter": {
			"fields": ["UUID"],
			"values": [[uuid]]
		},
		"metrics": metrics
	}
	).pipe(map(dp => {
		return dp;
	}));
  }

  getThresholdStats(user: string, ev: string, start: number, end: number) {
	  return this.http.get<ThresholdStats[]>(`${environment.apiUrl}/` + 'measurement/thresholdstats/' + start + '&' + end + '&' + user + '&' + ev).pipe(map(data => {
		return data;
	}));
  }

  getThresholdHostStats(start: number, end: number, uuid: string) {
	  return this.http.get<ThresholdStats[]>(`${environment.apiUrl}/` + 'measurement/thresholdhoststats/' + start + '&' + end + '&' + uuid).pipe(map(data => {
		return data;
	}));
  }

  getThreshold(uuid: string, start: number, end: number, counter: string, type: string) {
	  return this.http.get<any>(`${environment.apiUrl}/` + 'measurement/threshold/' + uuid + '&' + start + '&' + end + '&' + counter + '&' + type).pipe(map(data => {
		return data;
	}));
  }

  getInstanceCounters(uuid: string, start: number, end: number, granularity: string) {	

	return this.http.post<Counter[]>(`${environment.api2Url}/` + 'v1/counters/query',
		{
			"aggregationGranularity": granularity,
			"resourceType": "INSTANCE",
			"timeInterval": {
				"startTime": start,
				"endTime": end
				},
			"resourceFilter": {
				"fields": ["UUID"],
				"values": [[uuid]]
				},
			'metrics':[
				{
				"counter": "INSTANCE_CPU_INTRA",
				"rollup":[
					"AVG",
					"MIN",
					"MAX"
					]
				},
				{
				"counter": "INSTANCE_RAM_INTRA",
				"rollup":[
					"AVG",
					"MIN",
					"MAX"
					]
				},
				{
				"counter":"INSTANCE_NET_IN",
				"rollup":[
					"AVG",
					"MIN",
					"MAX"
					]
				},
				{
				"counter":"INSTANCE_NET_OUT",
				"rollup":[
					"AVG",
					"MIN",
					"MAX"
					]
				}
			]
		}
	).pipe(map(dp => {
		return dp;
	}));
  }

  getInstanceStorageCounters(uuid: string, start: number, end: number, granularity: string) {

	return this.http.post<Counter[]>(`${environment.api2Url}/` + 'v1/storage/counters/query',
		{
			"aggregationGranularity": granularity,
			"resourceType": "INSTANCE",
			"timeInterval": {
				"startTime": start,
				"endTime": end
				},
			"resourceFilter": {
				"fields": ["INSTANCE_UUID"],
				"values": [[uuid]]
				},
			'metrics':[
				{
				"counter": "INSTANCE_STORAGE_DISK_READ",
				"rollup":[
					"AVG",
					"MIN",
					"MAX"
					]
				},
				{
				"counter": "INSTANCE_STORAGE_DISK_WRITE",
				"rollup":[
					"AVG",
					"MIN",
					"MAX"
					]
				},
				{
				"counter":"INSTANCE_STORAGE_DISK_READSEC",
				"rollup":[
					"AVG",
					"MIN",
					"MAX"
					]
				},
				{
				"counter":"INSTANCE_STORAGE_DISK_WRITESEC",
				"rollup":[
					"AVG",
					"MIN",
					"MAX"
					]
				}
			]
		}
	).pipe(map(dp => {
		return dp;
	}));
  }

  getMinTimeHourly() {
	return this.http.get<any>(`${environment.apiUrl}/measurement/` + 'getmintimehourly').pipe(map(mintime => {
		return mintime;
	}));
  }

  getInstancesCounters(uuids: any, start: number, end: number, granularity: string) {	

	return this.http.post<Counter[]>(`${environment.api2Url}/` + 'v1/counters/query',
		{
			"aggregationGranularity": granularity,
			"resourceType": "INSTANCE",
			"timeInterval": {
				"startTime": start,
				"endTime": end
			},
			"resourceFilter": {
				"fields": ["UUID"],
				"values": uuids
			},
			'metrics':[
				{
					"counter": "INSTANCE_CPU_INTRA",
					"rollup":[
						"AVG"
					]
				}
			]
		}
	).pipe(map(dp => {
		return dp;
	}));
  }

  getSurplusCredits(start: number, end: number, threshold: number, granularity: string) {
          return this.http.get<any>(`${environment.apiUrl}/` + 'measurement/getsurpluscredits/' + start + '&' + end + '&' + threshold + '&' + granularity).pipe(map(data => {
                return data;
        }));
  }
}	

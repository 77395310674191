import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import {trigger, state, style, animate, transition} from '@angular/animations';

import * as moment from 'moment';

import { AccountService, InfrastructureService, JsonloaderService, MeasurementService, ShareService } from '@app/services';

import { HostSynthesis, Json, JSONTarget, Message, Troubleshooting, TsSelected, ThresholdStats, VmSynthesis, User } from '@app/model';

import { TroubleshootingcardlistComponent } from '@app/troubleshooting/troubleshootingcardlist/troubleshootingcardlist.component';
import { TroubleshootingcardlistDirective } from '@app/directives/troubleshootingcardlist.directive';

import { TroubleshootinggraphComponent } from '@app/troubleshooting/troubleshootinggraph/troubleshootinggraph.component';
import { TroubleshootinggraphDirective } from '@app/directives/troubleshootinggraph.directive';


@Component({
  selector: 'app-troubleshootingmap',
  templateUrl: './troubleshootingmap.component.html',
  styleUrls: ['./troubleshootingmap.component.css'],
  animations: [
          trigger('rotatedState', [
            state('default', style({ transform: 'rotate( 0 )' })),
	    state('rotated', style({ transform: '{{ts_overall_arrow_o}}' }), {params: {ts_overall_arrow_o: 'rotate( 0deg )'}}),
	])
  ]
})
export class TroubleshootingmapComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  @ViewChild(TroubleshootingcardlistDirective) addCardList: TroubleshootingcardlistDirective;
  @ViewChild(TroubleshootinggraphDirective) addCardGraph: TroubleshootinggraphDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  message: Message;

  test_element: boolean = true;

  isAddVms: boolean = true;

  vms_class: string = 'badge badge-info';

  isAddServers: boolean = true;

  servers_class: string = 'badge badge-info';

  cri_class: string = 'label addLink';

  cri_badge_class: string = 'badge';

  cpu_class: string = 'label addLink';

  disk_class: string = 'label addLink';

  net_class: string = 'label addLink';

  ram_class: string = 'label addLink';

  math = Math;

  data_vms: VmSynthesis[] = [];

  vm_selected: VmSynthesis = {
    uuid: '',
    name: '',
    father: '',
    cluster: '',
    sizingcpu: 0,
    sizingram: 0,
    cpucons: 0,
    ramcons: 0,
    stocap: 0,
    stocons: 0,
    tscons: 0,
    modiftime: '',
    cost: 0,
    vmwarecomment: '',
    fd: '',
    host: '',
    cpu: 0,
    ram: 0,
    vcpu: 0,
    vram: 0,
    state: '',
    os: '',
    tools: '',
    behavior: '',
    ha: '',
    dss: '',
    dsscap: 0,
    tags: '',
    rp: '',
    rpuuid: '',
    ds: 0,
    tsstate: '',
	co2: 0
  };

  data_hosts: any = [];

  host_selected: HostSynthesis = {
    uuid: '',
    name: '',
    father: '',
    cpucap: 0,
    ramcap: 0,
    vcpus: 0,
    rams: 0,
    os: '',
    model: '',
    tsstate: '',
    cost: 0,
    cpu_oc: 0,
    ram_oc: 0,
    cpu_oc_tx: 0,
    ram_oc_tx: 0,
    vms: 0,
    vms_run: 0,
    vms_off: 0,
    vms_oth: 0,
    tools: 0,
    tools_ti: 0,
    tools_te: 0,
    tools_tu: 0,
    dis_vcpu_2: 0,
    dis_vcpu_4: 0,
    dis_vcpu_8: 0,
    dis_vcpu_16: 0,
    dis_vcpu_32: 0,
    dis_vcpu_64: 0,
    dis_vram_2: 0,
    dis_vram_4: 0,
    dis_vram_8: 0,
    dis_vram_16: 0,
    dis_vram_32: 0,
    dis_vram_64: 0,
    color_cpu: '',
    color_net: '',
    color_ram: '',
    arrow_o_c: 0,
    arrow_o_n: 0,
    arrow_o_r: 0,
    pts_cpu_ora: 0,
    pts_cpu_red: 0,
    pts_net_ora: 0,
    pts_net_red: 0,
    pts_ram_ora: 0,
    pts_ram_red: 0,
    arrow_ram_swp: '',
    arrow_o_ram_swp: 0,
    color_ram_swp: '',
    arrow_ram_bal: '',
    arrow_o_ram_bal: 0,
    color_ram_bal: '',
    avg_ram_swp: 0,
    avg_ram_bal: 0,
	co2: 0
  };

  threshold_pts: ThresholdStats = {
    uuid: '',
    pts_cpu_total_orange: 0,
    pts_cpu_total_red: 0,
    pts_cpu_crsr_orange: 0,
    pts_cpu_crsr_red: 0,
    pts_cpu_rdy_orange: 0,
    pts_cpu_rdy_red: 0,
    pts_cpu_cstp_orange: 0,
    pts_cpu_cstp_red: 0,
    pts_ram_orange: 0,
    pts_ram_red: 0,
    pts_disk_total_orange: 0,
    pts_disk_total_red: 0,
    pts_disk_lat_orange: 0,
    pts_disk_lat_red: 0,
    pts_disk_cmd_orange: 0,
    pts_disk_cmd_red: 0,
    pts_net_orange: 0,
    pts_net_red: 0
  };

  cri_nb: number = 0;

  cpu_nb: number = 0;

  disk_nb: number = 0;

  net_nb: number = 0;

  ram_nb: number = 0;

  greenResources: boolean = true;

  ts_data: any = [];

  ts_data_agg: Troubleshooting[] = [];

  ts_model: Troubleshooting[] = [];

  ts_selected: TsSelected = {
	uuid: '',
	father_id: '',
	name: '',
	type: '',
	cpu_nb: 0,
	pts_cpu_red: 0,
	pts_cpu_ora: 0,
	pts_crsr_red: 0,
	pts_crsr_ora: 0,
	pts_crdy_red: 0,
	pts_crdy_ora: 0,
	pts_cstp_red: 0,
	pts_cstp_ora: 0,
	pts_net_red: 0,
	pts_net_ora: 0,
	pts_ram_red: 0,
	pts_ram_ora: 0,
	pts_dlat_ora: 0,
	pts_dlat_red: 0,
	pts_dcmd_ora: 0,
	pts_dcmd_red: 0
  };

  serverListLoad: boolean = false;
	  
  vmCardListLoad: boolean = true;

  vmListLoad: boolean = true;

  vmCardGraphLoad: boolean = false;

  col_class: string = 'clr-col-lg-12 clr-col-md-12 clr-col-12';

  private currentTri: string = 'all';

  private currentUser: User;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private infra_svc: InfrastructureService,
	private measurement_svc: MeasurementService,
	private message_svc: ShareService,
        private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.message_svc.currentMessage.subscribe(message => this.message = message);

	this.initDtOptions();

	this.data_vms = this.json_svc.json.vmSynthesis;
	this.data_hosts = this.json_svc.json.hostSynthesis;

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.TROUBLE_MAP).subscribe(
		data => {
	  		this.ts_data = data;
	  		this.buildData();
      		}
	);
  }

  ngAfterViewInit(): void {
	this.dtTrigger.next();
  }

  ngAfterViewChecked(): void {

	if(this.message.isTroubleGraph) {
		this.message.isTroubleGraph = false;
	  	this.vmCardGraphLoad = true;
	  	setTimeout(() => this.loadCardGraph(), 100);
	}
        this.cd.detectChanges();
  }

  ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
  }

  filterTs(item: string): void {

	if(item == this.currentTri) {
		item = 'all';
		this.currentTri = item;
	} else {
		if(item == "element")
			item = this.currentTri;
		else
			this.currentTri = item;
	}

	if(this.isAddVms)
		this.vms_class = 'badge badge-info';
	else
		this.vms_class = 'badge';

	if(this.isAddServers)
		this.servers_class = 'badge badge-info';
	else
		this.servers_class = 'badge';

	this.cri_nb = 0;
	this.cpu_nb = 0;
	this.disk_nb = 0;
	this.net_nb = 0;
	this.ram_nb = 0;
	for(var i=0; i < this.ts_data_agg.length; i++) {
		if(this.ts_data_agg[i].type == "VM") {
			if(this.isAddVms) {
	  			if(this.ts_data_agg[i].critical)
	  				++this.cri_nb;
	  			if(this.ts_data_agg[i].cpu_tri)
	  				++this.cpu_nb;
				if(this.ts_data_agg[i].disk_tri)
	  				++this.disk_nb;
				if(this.ts_data_agg[i].net_tri)
	  				++this.net_nb;
				if(this.ts_data_agg[i].ram_tri)
	  				++this.ram_nb;
	  		}
		} else if(this.ts_data_agg[i].type == "SERVER") {
	  		if(this.isAddServers) {
	  			if(this.ts_data_agg[i].critical)
	  				++this.cri_nb;
	  			if(this.ts_data_agg[i].cpu_tri)
	  				++this.cpu_nb;
				if(this.ts_data_agg[i].disk_tri)
	  				++this.disk_nb;
				if(this.ts_data_agg[i].net_tri)
	  				++this.net_nb;
				if(this.ts_data_agg[i].ram_tri)
	  				++this.ram_nb;
	  		}
	  	}
	}

	this.ts_model = [];
	this.ts_model = this.getTsFromState(item);
	this.reloadTable();
  }

  loadServerInfo(): void {

	this.serverListLoad = true;
	this.col_class = 'clr-col-xxl-6 clr-col-12';

	this.infra_svc.getFather(this.ts_selected.uuid).pipe(first()).subscribe(
	  	data => {
			this.ts_selected.father_id = data.FATHER_ID;
	  		this.host_selected = this.getHostData(data.FATHER_ID);
			this.getHostThreshold();
		}, error => {
			console.log('no father data');
		}
	);
  }

  hideServerInfo(): void {

	this.serverListLoad = false;
	this.col_class = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
  }

  callCardList(ts: Troubleshooting): void {

	this.vmCardGraphLoad = false;

	this.ts_selected.name = ts.name;
	this.ts_selected.uuid = ts.uuid;
	this.ts_selected.type = ts.type;
	this.ts_selected.pts_cpu_red = ts.pts_cpu_red;
	this.ts_selected.pts_cpu_ora = ts.pts_cpu_ora;
	this.ts_selected.pts_crsr_red = ts.pts_crsr_red;
	this.ts_selected.pts_crsr_ora = ts.pts_crsr_ora;
	this.ts_selected.pts_crdy_red = ts.pts_crdy_red;
	this.ts_selected.pts_crdy_ora = ts.pts_crdy_ora;
	this.ts_selected.pts_cstp_red = ts.pts_cstp_red;
	this.ts_selected.pts_cstp_ora = ts.pts_cstp_ora;
	this.ts_selected.pts_net_red = ts.pts_net_red;
	this.ts_selected.pts_net_ora = ts.pts_net_ora;
	this.ts_selected.pts_ram_red = ts.pts_ram_red;
	this.ts_selected.pts_ram_ora = ts.pts_ram_ora;
	this.ts_selected.pts_dlat_ora = ts.pts_dlat_ora;
	this.ts_selected.pts_dlat_red = ts.pts_dlat_red;
	this.ts_selected.pts_dcmd_ora = ts.pts_dcmd_ora;
	this.ts_selected.pts_dcmd_red = ts.pts_dcmd_red;

	this.message.troubleSelect = this.ts_selected;

	if(ts.type == "VM") {
		this.serverListLoad = false;
		this.vmCardListLoad = true;
		this.col_class = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
		this.vm_selected = this.getVmData(ts.uuid);
		this.ts_selected.cpu_nb = this.vm_selected.vcpu;
		setTimeout(() => this.loadCardList(), 100);
	} else {
		this.serverListLoad = true;
		this.vmCardListLoad = false;
	  	this.host_selected = this.getHostData(ts.uuid);
		this.ts_selected.cpu_nb = this.host_selected.cpucap;
		this.ts_selected.father_id = ts.uuid;
		this.getHostThreshold();
	}
  }

  callGraph(val: string): void {

	this.message.troubleCounter = val;
	this.message.isTroubleGraph = true;
  }

  private getVmData(uuid: string): VmSynthesis {
	  return this.data_vms.find(vm => vm.uuid === uuid);
  }

  private buildData(): void {

	let table: any = $('#ts-vm-list').dataTable();
	table.fnProcessingIndicator();

	this.cri_nb = 0;
	this.cpu_nb = 0;
	this.disk_nb = 0;
	this.net_nb = 0;
	this.ram_nb = 0;

	// VM CASE
	if(this.isAddVms) {
		this.vms_class = 'badge badge-info';
		for(var i=0; i < this.ts_data.length; i++) {
	  		let color: string = 'arrow_F_G.png';
			let cpu_class: string = 'badge badge-success';
			let disk_class: string = 'badge badge-success';
			let net_class: string = 'badge badge-success';
			let ram_class: string = 'badge badge-success';
			let weight: number = 0;

	  		if(this.ts_data[i].critical)
	  			++this.cri_nb;
	  		if(this.ts_data[i].cpu_tri)
	  			++this.cpu_nb;
			if(this.ts_data[i].disk_tri)
	  			++this.disk_nb;
			if(this.ts_data[i].net_tri)
	  			++this.net_nb;
			if(this.ts_data[i].ram_tri)
	  			++this.ram_nb;
		
			if(this.ts_data[i].color_cpu == "ORANGE")
				cpu_class = 'badge badge-orange';
			else if(this.ts_data[i].color_cpu == "RED")
				cpu_class = 'badge badge-danger';

			if(this.ts_data[i].color_disk == "ORANGE")
				disk_class = 'badge badge-orange';
			else if(this.ts_data[i].color_disk == "RED")
				disk_class = 'badge badge-danger';

			if(this.ts_data[i].color_net == "ORANGE")
				net_class = 'badge badge-orange';
			else if(this.ts_data[i].color_net == "RED")
				net_class = 'badge badge-danger';

			if(this.ts_data[i].color_ram == "ORANGE")
				ram_class = 'badge badge-orange';
			else if(this.ts_data[i].color_ram == "RED")
				ram_class = 'badge badge-danger';

	  		// SET WEIGHT FOR TRI
			if(this.ts_data[i].arrow_o < 0)
	  			weight = this.math.abs(this.ts_data[i].arrow_o);

	  		if(this.ts_data[i].color_cpu == "ORANGE" && this.ts_data[i].arrow_o_c < 0)
	  			weight = this.math.abs(this.ts_data[i].arrow_o_c)+100;
	  		else if(this.ts_data[i].color_cpu == "RED" && this.ts_data[i].arrow_o_c < 0)
	  			weight = this.math.abs(this.ts_data[i].arrow_o_c)+200;
	  		if(this.ts_data[i].color_disk == "ORANGE" && this.ts_data[i].arrow_o_d < 0)
	  			weight = this.math.max(weight, this.math.abs(this.ts_data[i].arrow_o_d)+100);
	  		else if(this.ts_data[i].color_disk == "RED" && this.ts_data[i].arrow_o_d < 0)
	  			weight = this.math.max(weight, this.math.abs(this.ts_data[i].arrow_o_d)+200);
	  		if(this.ts_data[i].color_net == "ORANGE" && this.ts_data[i].arrow_o_n < 0)
	  			weight = this.math.max(weight, this.math.abs(this.ts_data[i].arrow_o_n)+100);
	  		else if(this.ts_data[i].color_net == "RED" && this.ts_data[i].arrow_o_n < 0)
	  			weight = this.math.max(weight, this.math.abs(this.ts_data[i].arrow_o_n)+200);
	  		if(this.ts_data[i].color_ram == "ORANGE" && this.ts_data[i].arrow_o_r < 0)
	  			weight = this.math.max(weight, this.math.abs(this.ts_data[i].arrow_o_r)+100);
	  		else if(this.ts_data[i].color_ram == "RED" && this.ts_data[i].arrow_o_r < 0)
	  			weight = this.math.max(weight, this.math.abs(this.ts_data[i].arrow_o_r)+200);

			let cur_ts: Troubleshooting = {
				name: this.ts_data[i].name,
	  			uuid: this.ts_data[i].id,
				type: 'VM',
  				overall_url: 'assets/img/' + this.ts_data[i].color,
  				overall_arrow_trigger: 'rotated',
	  			overall_arrow_o: 'rotate( ' + this.ts_data[i].arrow_o + 'deg ) scale(0.6)',
				critical: this.ts_data[i].critical,
	 			cpu_tri: this.ts_data[i].cpu_tri,
	 			disk_tri: this.ts_data[i].disk_tri,
	 			net_tri: this.ts_data[i].net_tri,
	  			ram_tri: this.ts_data[i].ram_tri,
				cpu_class: cpu_class,
				disk_class: disk_class,
				net_class: net_class,
	  			ram_class: ram_class,
				pts_cpu_red: this.ts_data[i].pts_cpu_red,
				pts_cpu_ora: this.ts_data[i].pts_cpu_ora,
				pts_crsr_red: this.ts_data[i].pts_crsr_red,
	  			pts_crsr_ora: this.ts_data[i].pts_crsr_ora,
				pts_crdy_red: this.ts_data[i].pts_crdy_red,
				pts_crdy_ora: this.ts_data[i].pts_crdy_ora,
				pts_cstp_red: this.ts_data[i].pts_cstp_red,
				pts_cstp_ora: this.ts_data[i].pts_cstp_ora,
	  			pts_net_red: this.ts_data[i].pts_net_red,
	  			pts_net_ora: this.ts_data[i].pts_net_ora,
	  			pts_ram_red: this.ts_data[i].pts_ram_red,
	  			pts_ram_ora: this.ts_data[i].pts_ram_ora,
				pts_dlat_ora: this.ts_data[i].pts_dlat_ora,
	  			pts_dlat_red: this.ts_data[i].pts_dlat_red,
				pts_dcmd_ora: this.ts_data[i].pts_dcmd_ora,
	  			pts_dcmd_red: this.ts_data[i].pts_dcmd_red,
				weight: weight
	  		};
	  		this.ts_data_agg.push(cur_ts);
		}
	} else {
		this.vms_class = 'badge';
	}

	// SERVER CASE
	if(this.isAddServers) {
		this.servers_class = 'badge badge-info';
		for(var i=0; i < this.data_hosts.length; i++) {
			let ts_overall_url: string = 'assets/img/arrow_F_G.png';
	  		let ts_overall_arrow_o: string = 'rotate( -0deg )';
			let critical: boolean = false;
			let cpu_class: string = 'badge badge-success', cpu_tri: boolean = false;
			let disk_class: string = 'badge badge-success', disk_tri: boolean = false;
			let net_class: string = 'badge badge-success', net_tri: boolean = false;
	  		let ram_class: string = 'badge badge-success', ram_tri: boolean = false;
			let weight: number = 0;

	  		// GLOBAL
	  		if(this.data_hosts[i].color_cpu == "ORANGE" || this.data_hosts[i].color_disk == "ORANGE" || this.data_hosts[i].color_net == "ORANGE" || this.data_hosts[i].color_ram == "ORANGE")
  				ts_overall_url = 'assets/img/arrow_F_O.png';
	  		if(this.data_hosts[i].color_cpu == "RED" || this.data_hosts[i].color_disk == "RED" || this.data_hosts[i].color_net == "RED" || this.data_hosts[i].color_ram == "RED")
  				ts_overall_url = 'assets/img/arrow_F_R.png';

	  		var o: number = this.data_hosts[i].arrow_o_c;
	  		o = this.math.min(o, this.data_hosts[i].arrow_o_n);
	  		o = this.math.min(o, this.data_hosts[i].arrow_o_r);

	  		// EACH RESOURCE
	  		if(this.data_hosts[i].color_cpu == "ORANGE")
	  			cpu_class = 'badge badge-orange';
			else if(this.data_hosts[i].color_cpu == "RED")
	  			cpu_class = 'badge badge-danger';
	  		if(this.data_hosts[i].color_disk == "ORANGE")
	  			disk_class = 'badge badge-orange';
			else if(this.data_hosts[i].color_disk == "RED")
	  			disk_class = 'badge badge-danger';
	  		if(this.data_hosts[i].color_net == "ORANGE")
	  			net_class = 'badge badge-orange';
			else if(this.data_hosts[i].color_net == "RED")
	  			net_class = 'badge badge-danger';
	  		if(this.data_hosts[i].color_ram == "ORANGE")
	  			ram_class = 'badge badge-orange';
			else if(this.data_hosts[i].color_ram == "RED")
	  			ram_class = 'badge badge-danger';

	  		if(o < 0) {
	  			if(cpu_class == "badge badge-orange" || cpu_class == "badge badge-danger") {
	  				if(this.data_hosts[i].arrow_o_c < 0) {
						critical = true;
	  					cpu_tri = true;
	  					++this.cpu_nb;
	  				}
	  			}
	  			if(disk_class == "badge badge-orange" || disk_class == "badge badge-danger") {
	  				if(this.data_hosts[i].arrow_o_c < 0) {
						critical = true;
	  					disk_tri = true;
	  					++this.disk_nb;
	  				}
	  			}
	  			if(net_class == "badge badge-orange" || net_class == "badge badge-danger") {
	  				if(this.data_hosts[i].arrow_o_n < 0) {
						critical = true;
	  					net_tri = true;
	  					++this.net_nb;
	  				}
	  			}
	  			if(ram_class == "badge badge-orange" || ram_class == "badge badge-danger") {
	  				if(this.data_hosts[i].arrow_o_r < 0) {
						critical = true;
	  					ram_tri = true;
	  					++this.ram_nb;
	  				}
	  			}
	  		}
			if(critical)
	  			++this.cri_nb;

	  		// SET WEIGHT FOR TRI
			if(this.data_hosts[i].arrow_o < 0)
	  			weight = this.math.abs(this.data_hosts[i].arrow_o);

	  		if(this.data_hosts[i].color_cpu == "ORANGE" && this.data_hosts[i].arrow_o_c < 0)
	  			weight = this.math.abs(this.data_hosts[i].arrow_o_c)+100;
	  		else if(this.data_hosts[i].color_cpu == "RED" && this.data_hosts[i].arrow_o_c < 0)
	  			weight = this.math.abs(this.data_hosts[i].arrow_o_c)+200;
	  		if(this.data_hosts[i].color_disk == "ORANGE" && this.data_hosts[i].arrow_o_d < 0)
	  			weight = this.math.max(weight, this.math.abs(this.data_hosts[i].arrow_o_d)+100);
	  		else if(this.data_hosts[i].color_disk == "RED" && this.data_hosts[i].arrow_o_d < 0)
	  			weight = this.math.max(weight, this.math.abs(this.data_hosts[i].arrow_o_d)+200);
	  		if(this.data_hosts[i].color_net == "ORANGE" && this.data_hosts[i].arrow_o_n < 0)
	  			weight = this.math.max(weight, this.math.abs(this.data_hosts[i].arrow_o_n)+100);
	  		else if(this.data_hosts[i].color_net == "RED" && this.data_hosts[i].arrow_o_n < 0)
	  			weight = this.math.max(weight, this.math.abs(this.data_hosts[i].arrow_o_n)+200);
	  		if(this.data_hosts[i].color_ram == "ORANGE" && this.data_hosts[i].arrow_o_r < 0)
	  			weight = this.math.max(weight, this.math.abs(this.data_hosts[i].arrow_o_r)+100);
	  		else if(this.data_hosts[i].color_ram == "RED" && this.data_hosts[i].arrow_o_r < 0)
	  			weight = this.math.max(weight, this.math.abs(this.data_hosts[i].arrow_o_r)+200);

			// BUILD TS OBJECT
			let cur_ts: Troubleshooting = {
				name: this.data_hosts[i].name,
	  			uuid: this.data_hosts[i].uuid,
				type: 'SERVER',
  				overall_url: ts_overall_url,
  				overall_arrow_trigger: 'rotated',
	  			overall_arrow_o: 'rotate( ' + o + 'deg ) scale(0.6)',
				critical: critical,
	 			cpu_tri: cpu_tri,
	 			disk_tri: disk_tri,
	 			net_tri: net_tri,
	  			ram_tri: ram_tri,
				cpu_class: cpu_class,
				disk_class: disk_class,
				net_class: net_class,
	  			ram_class: ram_class,
				pts_cpu_red: this.data_hosts[i].pts_cpu_red,
				pts_cpu_ora: this.data_hosts[i].pts_cpu_ora,
				pts_crsr_red: 0,
	  			pts_crsr_ora: 0,
				pts_crdy_red: 0,
				pts_crdy_ora: 0,
				pts_cstp_red: 0,
				pts_cstp_ora: 0,
	  			pts_net_red: this.data_hosts[i].pts_net_red,
	  			pts_net_ora: this.data_hosts[i].pts_net_ora,
	  			pts_ram_red: this.data_hosts[i].pts_ram_red,
	  			pts_ram_ora: this.data_hosts[i].pts_ram_ora,
				pts_dlat_ora: 0,
	  			pts_dlat_red: 0,
				pts_dcmd_ora: 0,
				pts_dcmd_red: 0,
				weight: weight
			};
	  		this.ts_data_agg.push(cur_ts);
		}
	} else {
		this.servers_class = 'badge';
	}

	if(this.ts_data_agg.length > 0) {
		setTimeout(() => {
	    		this.filterTs('all');
			this.vm_selected = this.getVmData(this.ts_data_agg[0].uuid);
	  		this.callCardList(this.ts_data_agg[0]);
	  	}, 500);
	}
  }

  private getTsFromState(item: string): Troubleshooting[] {

	let result: Troubleshooting[] = [];

	for(var i=0; i < this.ts_data_agg.length; i++) {
		switch(item) {
	  		case "critical":
				this.greenResources = false;
				this.cri_class = 'label label-danger addLink';
				this.cri_badge_class = 'badge badge-danger';
				this.cpu_class = 'label addLink';
				this.disk_class = 'label addLink';
				this.net_class = 'label addLink';
				this.ram_class = 'label addLink';
	  			if(this.ts_data_agg[i].type == "VM") {
	  				if(this.isAddVms) {
						if(this.ts_data_agg[i].critical)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			} else if(this.ts_data_agg[i].type == "SERVER") {
	  				if(this.isAddServers) {
						if(this.ts_data_agg[i].critical)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			}
	  			break;
			case "cpu":
				this.greenResources = false;
				this.cri_class = 'label addLink';
				this.cri_badge_class = 'badge';
				this.cpu_class = 'label label-blue addLink';
				this.disk_class = 'label addLink';
				this.net_class = 'label addLink';
				this.ram_class = 'label addLink';
	  			if(this.ts_data_agg[i].type == "VM") {
	  				if(this.isAddVms) {
						if(this.ts_data_agg[i].cpu_tri)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			} else if(this.ts_data_agg[i].type == "SERVER") {
	  				if(this.isAddServers) {
						if(this.ts_data_agg[i].cpu_tri)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			}
	  			break;
			case "disk":
				this.greenResources = false;
				this.cri_class = 'label addLink';
				this.cri_badge_class = 'badge';
				this.cpu_class = 'label addLink';
				this.disk_class = 'label label-blue addLink';
				this.net_class = 'label addLink';
				this.ram_class = 'label addLink';
	  			if(this.ts_data_agg[i].type == "VM") {
	  				if(this.isAddVms) {
						if(this.ts_data_agg[i].disk_tri)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			} else if(this.ts_data_agg[i].type == "SERVER") {
	  				if(this.isAddServers) {
						if(this.ts_data_agg[i].disk_tri)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			}
	  			break;
			case "net":
				this.greenResources = false;
				this.cri_class = 'label addLink';
				this.cri_badge_class = 'badge';
				this.cpu_class = 'label addLink';
				this.disk_class = 'label addLink';
				this.net_class = 'label label-blue addLink';
				this.ram_class = 'label addLink';
	  			if(this.ts_data_agg[i].type == "VM") {
	  				if(this.isAddVms) {
						if(this.ts_data_agg[i].net_tri)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			} else if(this.ts_data_agg[i].type == "SERVER") {
	  				if(this.isAddServers) {
						if(this.ts_data_agg[i].net_tri)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			}
	  			break;
			case "ram":
				this.greenResources = false;
				this.cri_class = 'label addLink';
				this.cri_badge_class = 'badge';
				this.cpu_class = 'label addLink';
				this.disk_class = 'label addLink';
				this.net_class = 'label addLink';
				this.ram_class = 'label label-blue addLink';
	  			if(this.ts_data_agg[i].type == "VM") {
	  				if(this.isAddVms) {
						if(this.ts_data_agg[i].ram_tri)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			} else if(this.ts_data_agg[i].type == "SERVER") {
	  				if(this.isAddServers) {
						if(this.ts_data_agg[i].ram_tri)
	  						result.push(this.ts_data_agg[i]);
	  				}
	  			}
				break;
	  		case "all":
				this.greenResources = true;
				this.cri_class = 'label addLink';
				this.cri_badge_class = 'badge';
				this.cpu_class = 'label addLink';
				this.disk_class = 'label addLink';
				this.net_class = 'label addLink';
				this.ram_class = 'label addLink';
	  			if(this.ts_data_agg[i].type == "VM") {
	  				if(this.isAddVms)
	  					result.push(this.ts_data_agg[i]);
	  			} else if(this.ts_data_agg[i].type == "SERVER") {
	  				if(this.isAddServers)
	  					result.push(this.ts_data_agg[i]);
	  			}
				break;
	  		default:
	  			break;
	  	}
	}

        return result;
  }

  private getHostThreshold(): void {

	const start_str: string = moment.unix(this.message.maxTimeFilter/1000).subtract(30, 'days').startOf('day').format("x");
	const start: number = +start_str;

	
	this.measurement_svc.getThresholdHostStats(start/1000, this.message.maxTimeFilter/1000, this.host_selected.uuid).pipe(first()).subscribe(
		data => {
	  		this.threshold_pts = data[0];
		},
		error => {
			if(error != null)
				console.log(error)
		}
	);
  }

  private getHostData(uuid: string): HostSynthesis {
	  return this.data_hosts.find(host => host.uuid === uuid);
  }

  private reloadTable(): void {

	  if(this.dtElement.dtInstance != undefined) {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			$( "div.dataTables_filter" ).remove();
                	dtInstance.destroy();
	  		this.dtTrigger.next();
          	});
	  }
  }

  private initDtOptions(): void {

	this.dtOptions = {
		pagingType: 'full_numbers',
		pageLength: 10,
		processing: true,
	  	destroy: true,
		deferRender: true,
		initComplete: function() {
			$('div.dataTables_filter').appendTo('span.search');
			let table: any = $('#ts-vm-list').dataTable();
			table.fnProcessingIndicator( false );
	  	},
		"order": [[ 1, "desc" ]],
	  	language: {
	  		zeroRecords: "no data",
	  		search: '',
	  		searchPlaceholder: " search..."
	  	},
		dom: '<"top">rt<"bottom"ifp>'
	};
  }

  private loadCardList(): void {

	  if(this.addCardList != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TroubleshootingcardlistComponent);
		const viewContainerRef = this.addCardList.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	  }
  }

  private loadCardGraph(): void {

	if(this.addCardGraph != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TroubleshootinggraphComponent);
		const viewContainerRef = this.addCardGraph.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faPlug } from '@fortawesome/free-solid-svg-icons';

import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);

import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
exporting(Highcharts);
offline(Highcharts);

import { PowerGraphComponent } from '@app/greenit/power/power-graph/power-graph.component';
import { PowerVmStackedComponent } from '@app/greenit/power/power-vm-stacked/power-vm-stacked.component';

import { GreenitService, ShareService} from '@app/services';

import { Message } from '@app/model';


@Component({
  selector: 'app-power-azure',
  templateUrl: './power-azure.component.html',
  styleUrls: ['./power-azure.component.css']
})
export class PowerAzureComponent implements OnInit {

  message: Message;

  @ViewChild('powerGraph') powerGraph: PowerGraphComponent;
  @ViewChild('powerVmStacked') powerVmStacked: PowerVmStackedComponent;

  all_regions: boolean = false;

  listRegions: any[] = [];

  selectedElements: string[] = [];

  isAllDisable: boolean = false;

  isStackedDisable: boolean = false;

  isStacked: boolean = false;

  targetInfrastructure: string = 'azure';

  isSettings: boolean = false;

  model: any = {
    accountFullNameSelected: ''
  };

  accounts: any = [];

  isData: boolean = false;
  isReady: boolean = false;


  constructor(
    private message_svc: ShareService,
    private library: FaIconLibrary,
    private greenit_svc: GreenitService
  ) { 
    this.library.addIcons(faPlug);
  }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);

    this.selectedElements = [];

    this.greenit_svc.getRegionsListObservable('', 'azure').subscribe(
      data => {
        if(data.length > 0) {
          this.isData =true;
          this.listRegions = data;
          this.selectedElements.push(data[0]);
          //this.model.accountFullNameSelected = data[0].account.replace("-", "");
          this.model.accountFullNameSelected = data[0].account;
          //this.message.cloudAccount = data[0].account.replace("-", "");
          this.message.cloudAccount = data[0].account;
          for(let i in data) {
            if(!this.accounts.includes(data[i].account))
              this.accounts.push(data[i].account);
          }
          this.isReady = true;
          setTimeout(() => {
            this.updateChart();
          }, 100);
        }
      }
    );
  }

  updateChart(): void {

    if (this.powerGraph != undefined) {
      setTimeout(() => {
        this.powerGraph.updateChart();
      }, 100);
    }
  }

  checkAll() {

    // Reset
    this.selectedElements = [];

    if(this.all_regions) {
      this.selectedElements.push.apply(this.selectedElements,this.listRegions);
      this.isStackedDisable = true;
    } else {
      this.isStackedDisable = false;
    }

    setTimeout(() => {
      this.updateChart();
    }, 100);
  }

  stackOnRegion(): void {

    if(this.isStacked)
      this.isAllDisable = true;
    else
      this.isAllDisable = false;

    setTimeout(() => {
      this.updateChart();
    }, 100);
  }

  reloadRegions(): void {

    this.isReady = false;
    this.selectedElements = [];
  
    this.message.cloudAccount = this.model.accountFullNameSelected;

    this.greenit_svc.getRegionsListObservable('', 'aws').subscribe(
      data => {
        if(data.length > 0) {
          let new_regions: any = [];
          for(let i in data) {
            if(data[i].account == this.model.accountFullNameSelected) {
              this.listRegions.push(data[i]);
              new_regions.push(data[i]);
            }
          }
          if(new_regions.length > 0)
            this.selectedElements.push(new_regions[0]);

          this.isReady = true;
          setTimeout(() => {
            this.updateChart();
          }, 100);
        }
      }
    );
  
    setTimeout(() => {
      this.isReady = true;
      this.updateChart();
    }, 100);
  }
}

export interface VDCSynthesis {
    uuid: string;
    name: string;
    rpuuid: string;
    organization: string;
    vmquota: number;
    allocationModel: string;
    avgVdcCpuAllocated: number;
    avgVdcCpuLimit: number;
    avgVdcCpuReserved: number;
    avgVdcCpuResused: number;
    avgVdcCpuUsed: number;
    avgVdcRamAllocated: number;
    avgVdcRamLimit: number;
    avgVdcRamReserved: number;
    avgVdcRamResused: number;
    avgVdcRamUsed: number;
    avgVdcDiskLimit: number;
    avgVdcDiskUsed: number;
}

export interface VDCPrediction {
    NBVM_USAGE: number;
    USAGECPU_DATE_SAT: string;
    USAGERAM_DATE_SAT: string;
    USAGEDISK_DATE_SAT: string;
}

export interface VDCBadge {
    nbvm_usage_class: string;
    usagecpu_sat: string;
    usageram_sat: string;
    usagedisk_sat: string;
}

export interface VDCAlert {
    nbvm_usage: string;
    usagecpu_sat: string;
    usageram_sat: string;
    usagedisk_sat: string;
}

export enum VDCResource {
  CPU, RAM, DISK
}



import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { first } from 'rxjs/operators';

import { ManagementService, ShareService } from '@app/services';

import { Message } from '@app/model';

import { T7Component } from '@app/t7/t7.component';
import { T7Directive } from '@app/directives/t7.directive';
import { T9Component } from '@app/t9/t9.component';
import { T9Directive } from '@app/directives/t9.directive';
import { T12Component } from '@app/t12/t12.component';
import { T12Directive } from '@app/directives/t12.directive';
import { T13Component } from '@app/t13/t13.component'; 
import { T13Directive } from '@app/directives/t13.directive';

import { InstancesCo2Component } from '@app/provider/aws/instances-co2/instances-co2.component';


@Component({
  selector: 'app-evolution',
  templateUrl: './evolution.component.html',
  styleUrls: ['./evolution.component.css']
})
export class EvolutionComponent implements AfterViewChecked, OnInit {

  message: Message;

  @ViewChild(T7Directive) addT7: T7Directive;
  @ViewChild(T9Directive) addT9: T9Directive;
  @ViewChild(T12Directive) addT12: T12Directive;
  @ViewChild(T13Directive) addT13: T13Directive;
  @ViewChild('addAwsCo2') addAwsCo2: InstancesCo2Component;

  isAccountMgtReady: boolean = false;

  isAwsReady: boolean = false;

  isAzureReady: boolean = false;


  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private mgt_svc: ManagementService,
    private message_svc: ShareService
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  ngAfterViewInit(): void {

    setTimeout(() => this.loadVMwareCards(), 100);
  }

  ngAfterViewChecked(): void {

    this.cd.detectChanges();
  }

  setVmware(): void {

	  setTimeout(() => this.loadVMwareCards(), 100);
  }

  loadRegion(): void {

    this.isAwsReady = false;
    this.isAzureReady = false;

    if(this.message.cloudProvider == "aws")
      setTimeout(() => this.loadAws(), 500);
    else
      setTimeout(() => this.loadAzure(), 500);
  }

  setAws(): void {

    this.isAccountMgtReady = false;
    this.message.cloudProvider = 'aws';
    this.message.isValidAzure = false;

    this.mgt_svc.getRegions('aws').pipe(first()).subscribe(
			data => {				
        	if(data.length > 0)
					  this.message.isValidAws = true;

          if(this.message.isValidAws) {
            this.isAccountMgtReady = true;
            setTimeout(() => this.loadAws(), 500);
          }
			}
		);
  }

  setAzure(): void {

    this.isAccountMgtReady = false;
    this.message.cloudProvider = 'azure';
    this.message.isValidAws = false;

    this.mgt_svc.getRegions('azure').pipe(first()).subscribe(
			data => {				
        	if(data.length > 0)
					  this.message.isValidAzure = true;

          if(this.message.isValidAzure) {
            this.isAccountMgtReady = true;
            setTimeout(() => this.loadAzure(), 500);
          } 
			}
		);
  }

  private loadVMwareCards(): void {
    
    this.loadT7Card()
    this.loadT9Card();
    this.loadT12Card();
    this.loadT13Card();
  }

  private loadT7Card(): void {

		if(this.addT7 != undefined) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T7Component);
			const viewContainerRef = this.addT7.viewContainerRef7;
			viewContainerRef.clear();
			const componentRef = viewContainerRef.createComponent(componentFactory);
		}
	}

  private loadT9Card(): void {

		if(this.addT9 != undefined) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T9Component);
			const viewContainerRef = this.addT9.viewContainerRef9;
			viewContainerRef.clear();
			const componentRef = viewContainerRef.createComponent(componentFactory);
		}
	}

  private loadT12Card(): void {

		if(this.addT12 != undefined) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T12Component);
			const viewContainerRef = this.addT12.viewContainerRef12;
			viewContainerRef.clear();
			const componentRef = viewContainerRef.createComponent(componentFactory);
		}
	}

  private loadT13Card(): void {

		if(this.addT13 != undefined) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T13Component);
			const viewContainerRef = this.addT13.viewContainerRef13;
			viewContainerRef.clear();
			const componentRef = viewContainerRef.createComponent(componentFactory);
		}
	}

  private loadAws() {

    if(this.message.isValidAws) {
      this.message.cloudProvider = 'aws';
      this.message.creditSelected = 'co2';
      this.isAwsReady = true;
    }
  }

  private loadAzure() {

    if(this.message.isValidAzure) {
      this.message.cloudProvider = 'azure';
      this.message.creditSelected = 'co2';
      this.isAzureReady = true;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { JSONTarget, Message, User } from '@app/model';
import { AccountService, GreenitService, JsonloaderService, ShareService } from '@app/services';
import { ClrDatagridSortOrder } from '@clr/angular';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faLeaf, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { GreenitTarget } from '../greenit.enums';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']
})
export class AnalysisComponent implements OnInit {

  message: Message;

  currentUser: User;

  dataOrder = ClrDatagridSortOrder.ASC;

  resize_data: any = [];
  total_resize_co2: number = 0;

  monthly_data: any;
  last_monthly_co2: Number = 0;
  last_monthly_co2_percent: Number = 0;
  last_monthy_arrow_class: String = "fa-rotate-90";

  greenit_alert: boolean = false;


  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;

    //Stacked column
    this.chart.update({ plotOptions: {column: { dataLabels: { enabled: true, style: { fontWeight: 'bold', color:'gray' }  } }} });

    this.chart.showLoading();
  };

  options: Highcharts.Options = {
    chart: {
      height: '520px',
      backgroundColor: 'rgba(255, 255, 255, 0.0)'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    time: {
      useUTC: false
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function() {
          return Highcharts.dateFormat('%m-%Y',this.value);
        }
      },
    },
    yAxis: {
      //min: 0,
      labels: {
        align: 'right',
        x: -10
      },
      title: {
        text: 'kg CO2eq'
      }
    }
  }


  constructor(private authentication_svc: AccountService, private message_svc: ShareService,
    private json_svc: JsonloaderService, private library: FaIconLibrary, private greenit_svc: GreenitService) { }


  ngOnInit(): void {
    this.library.addIcons(faLeaf, faArrowUp);

    this.authentication_svc.user.subscribe(user => this.currentUser = user);
    this.message_svc.currentMessage.subscribe(message => this.message = message);

    // GET RESIZE GREENIT DATA
    this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.GREENIT_RESIZE).subscribe(
      	data => {
          if (data != undefined) {
            this.resize_data = data;
            this.total_resize_co2 = this.resize_data.reduce((sum: number, current: { HISTORY_RESIZE_TOTAL_CO2: any; }) => sum + Number(current.HISTORY_RESIZE_TOTAL_CO2), 0);
            this.total_resize_co2 = Number(this.total_resize_co2.toFixed(0));
          }
		  }
	  );

    // GET MONTHLY EVOL GREENIT DATA
    this.greenit_svc.getGreenitData(this.currentUser.login, this.message.currentFilter,GreenitTarget.HOST_ANALYZES).subscribe(
        data => {
          this.monthly_data = data;

          if(this.monthly_data.length == 0)
            this.greenit_alert = true;

          // set data
          this.setMonthlyData();
		  }
	  );
  }

  /**
   * 
   */
  private setMonthlyData(): void {
    let times = [];
    let waste = [];
    let srv = [];
    let index: number = 0;
    
    for (let i in this.monthly_data) {
      if (this.monthly_data[i] != undefined) {
        //XXX in order to show last month number (first day of month plus a day)
        let lastTime = parseInt(this.monthly_data[i].time) - 24 * 3600* 1000;

        let srv_value = Number(this.monthly_data[i].host);
        let waste_value = Number(this.monthly_data[i].waste);
                
        // Save points
        times[index] = lastTime;
        srv[index] = Number(srv_value.toFixed(0));
        waste[index] = Number(waste_value.toFixed(0));
        index++;
      }
    }

    let prev_idx: number = index - 2;
    let last_idx: number = index - 1;
    this.last_monthy_arrow_class = "fa-rotate-90";

    // Set last values
    if(last_idx >= 0) {
      this.last_monthly_co2_percent = Number(((waste[last_idx] * 100) / srv[last_idx]).toFixed(2));
      this.last_monthly_co2 = waste[last_idx];
    }

    // Set previous values
    if(prev_idx >= 0) {
      // previous
      let previous_monthly_co2_percent = Number(((waste[prev_idx] * 100) / srv[prev_idx]).toFixed(2));

      // arrow
      if(previous_monthly_co2_percent ==  this.last_monthly_co2_percent)
        this.last_monthy_arrow_class = "fa-rotate-90";
      else if(previous_monthly_co2_percent < this.last_monthly_co2_percent)
        this.last_monthy_arrow_class = "fa-rotate-45";
      else
        this.last_monthy_arrow_class = "fa-rotate-135";
    }


    // Set categories
    this.chart.xAxis[0].setCategories(times);

    // Push to chart
    this.chart.addSeries({
      type: 'column',
      name: "Servers (embodied)",
      cursor: 'pointer',
      data: srv,
      tooltip: { xDateFormat: '%b %Y'}
      //pointPadding: 0.3,
		  //pointPlacement: 0.2
    },false);

    this.chart.addSeries({
      type: 'column',
      name: "Unused (embodied)",
      cursor: 'pointer',
      data: waste,
      tooltip: { xDateFormat: '%b %Y'}
      //pointPadding: 0.4,
		  //pointPlacement: 0.2
    });

       
    // Hide chart loading
    this.chart.hideLoading();
  }

   /**
   * 
   */
  exportResize() {
    let res = ["Name", "Operation", "Date", "vCPU action", "RAM (MB) action", "Carbon emission VCPU (kg CO2eq)", "Carbon emission RAM (kg CO2eq)", "Carbon emission TOTAL (kg CO2eq)"].join(',') + '\n';
		res += Object.values<any>(this.resize_data).map(resize =>
			[resize.NAME, resize.HISTORY_ACTION, this.formatDate(resize.HISTORY_DATE,false), resize.HISTORY_RESIZE_VCPU, resize.HISTORY_RESIZE_RAM, resize.HISTORY_RESIZE_VCPU_CO2, resize.HISTORY_RESIZE_RAM_CO2 , resize.HISTORY_RESIZE_TOTAL_CO2].join(",")
		).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'greenit_analyzes.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  /**
   * 
   */
  formatNumber(value : number): number {
    if(value > -1 && value < 1)
      return Number(value.toFixed(2));
    else
      return Number(value.toFixed(0));
  }

  /**
   * 
   */
  formatNumbers(value : number, value2: number): number {
    let val1: number = this.formatNumber(value);
    let val2: number = this.formatNumber(value2);
    let val3: number = val1 + val2;

    if(Number.isInteger(val1) && Number.isInteger(val2))
      return val3;
    else
      return Number(val3.toFixed(2));
  }

  /**
   * 
   */
  toLower(data: string) {
    return data.toLowerCase();
  }

  /**
	 * 
	 */
	formatDate(time: number, showhour: boolean): string {
		if (!showhour)
			return new Date(time).toLocaleDateString('en-EN');
		else
			return new Date(time).toLocaleString('en-EN');
	}
}

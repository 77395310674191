<div style="height:150px;margin-bottom:20px">
    <div role="tooltip" class="tooltip tooltip-lg tooltip-bottom-right" style="width:80%;">
        <!-- <div class="alert alert-info" role="alert" style="text-align:justify;font-size:13px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;float:left;">
          -->   
        <div class="info-custom">
            <ul>
                <li><b>Embodied energy</b> is the energy consumed throughout the life cycle of a product. It includes all the production stages of the product since the extraction of materials, transformation, manufacture, transport, installation, and maintenance as well as the energy consumed for its recycling. The embodied energy does not include direct energy.</li>
                <li><b>Direct energy</b> is the energy that is consumed by the product during its use.</li>
            </ul>
        </div>
        <span class="tooltip-content info-tooltip-header"><b>Embodied energy :</b> To calculate the embodied energy, DC Scope analyses the information from your infrastructure (automatically updated from your vCenter), the information you provided for other IT equipment as desktops and devices (screens, keyboards, etc.), the average age of your equipment, and CO2eq / kWh emissions.&#10;&#10;
            <br><br><b>Direct energy :</b> DC Scope measures the direct energy of your data center and calculates the equivalent emissions of CO2 for each server.
        </span>
    </div>
    <!--<div style="width:20%;float:right;">
        <button style="display:block;margin:auto;border:none;height:100%;" class="btn btn-link" (click)="quantis_modal = true">
            <p style="color:#e5004b"><b>in collaboration with</b></p>
            <img src="../../assets/img/quantis_logo.svg" height="25px" style="display:block;margin:auto;" />
        </button>
    </div>-->
</div>

<clr-tabs>
    <clr-tab>
        <button clrTabLink (click)="displayGlobal()">Global</button>
        <clr-tab-content *clrIfActive="true">
            <div class="clr-row">
                <!-- T1 -->
                <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                    <ng-template [ngTemplateOutlet]="t1"></ng-template>
                </div>
                <!-- T2 -->
                <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
                    <div class="card">
                        <div class="card-header card-ajust">
                            <h5>
                                <clr-icon shape="tree"></clr-icon>&nbsp;Carbon footprint evolution
                            </h5>
                        </div>
                        <div class="card-block">
                            <app-greenitcf [target]="'all.co2.total'"></app-greenitcf>
                        </div>
                    </div>
                </div>
            </div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="displayDC()">DC</button>
        <clr-tab-content *clrIfActive="false">
            <div class="clr-row">
                <!-- T1 -->
                <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                    <ng-template [ngTemplateOutlet]="t1"></ng-template>
                </div>
                <!-- T2 -->
                <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
                    <div class="card">
                        <div class="card-header card-ajust">
                            <h5>
                                <clr-icon shape="tree"></clr-icon>&nbsp;Carbon footprint evolution
                                <button class="btn btn-link" style="position:relative;float:right;" (click)="displayModalEfficiency()">
                                    <clr-icon shape="rack-server"></clr-icon>&nbsp;Servers efficiency list
                                </button>
                                <button class="btn btn-link" style="position:relative;float:right;text-transform:none;margin-right:50px;cursor:default;">
                                    Annual kg CO2eq : {{formatNumber(annual_co2)}}
                                </button>
                            </h5>
                        </div>
                        <div class="card-block">
                            <app-greenitcf [target]="'dc.all.co2.total'"></app-greenitcf>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clr-row" style="padding-bottom:20px;">
                <!-- T3 -->
                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                    <div class="card">
                        <div class="card-header card-ajust">
                            <h5>
                                <clr-icon shape="line-chart"></clr-icon>&nbsp;Informations about VM
                            </h5>
                        </div>
                        <div class="card-block">
                            <app-greenitvm></app-greenitvm>
                        </div>
                    </div>
                </div>
                <!-- T4 : same as T2 -->
                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                    <div class="card">
                        <div class="card-header card-ajust">
                            <h5>
                                <clr-icon shape="tree"></clr-icon>&nbsp;kWh evolution

                                <button class="btn btn-link" style="position:relative;float:right;" (click)="displayModalOptimization()">
                                    <clr-icon shape="rack-server"></clr-icon>&nbsp;With optimization : {{formatNumber(optimization_kwh)}}
                                </button>

                                <button class="btn btn-link" style="position:relative;float:right;text-transform:none;margin-right:50px;cursor:default;">
                                    Annual kWh : {{formatNumber(annual_kwh)}} <span style="font-style:italic;">&nbsp;({{formatNumber(annual_cost) + " " + globalCurrency}})</span>
                                </button>
                            </h5>
                        </div>
                        <div class="card-block">
                            <app-greenitcf [nbHost]=true [target]="'dc.all.wh.total'"></app-greenitcf>
                        </div>
                    </div>
                </div>
            </div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="displayDesktop()">Desktop</button>
        <clr-tab-content *clrIfActive="false">
            <div class="clr-row">
                <!-- T1 -->
                <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
                    <ng-template [ngTemplateOutlet]="t1"></ng-template>
                </div>
                <!-- T2 -->
                <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
                    <div class="card">
                        <div class="card-header card-ajust">
                            <h5>
                                <clr-icon shape="tree"></clr-icon>&nbsp;Carbon footprint evolution
                            </h5>
                        </div>
                        <div class="card-block">
                            <app-greenitcf [target]="'desktop.all.co2.total'"></app-greenitcf>
                        </div>
                    </div>
                </div>
            </div>
        </clr-tab-content>
    </clr-tab>
</clr-tabs>

<!-- T1 -->
<ng-template #t1>
    <div class="card">
        <div class="card-header card-ajust">
            <h5><clr-icon shape="details"></clr-icon>&nbsp;Your maturity score</h5>
        </div>
        <div class="card-block">
            <div [ngClass]=" (greenit_alert) ? 'alert alert-warning' : 'alert alert-success'" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <clr-icon class="alert-icon" [attr.shape]="(greenit_alert) ? 'exclamation-triangle' : 'check-circle'"></clr-icon>
                        </div>
                        <span class="alert-text">{{(greenit_alert) ? 'Fill in your information to improve your score !' : 'All informations are set !'}}</span>
                    </div>
                </div>
            </div>
            <highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="width:100%;display:block;"></highcharts-chart>
        </div>
    </div>
</ng-template>

<!-- GREENIT MODAL OPTIMIZATION -->
<clr-modal [(clrModalOpen)]="optimization_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Energy Optimization</h3>
    <div class="modal-body">
        <div class="clr-row" style="width:100%;">
            <div style="float:left;text-align:center;width:50%;">
                <p style="font-size: 18px;margin:0"><b>Current annual kWh</b></p>
                <p style="font-size: 18px;padding-bottom:10px" id="greenit-prediction-current"></p>
                <div>Now you have : {{optimization_current_servers}} server(s)</div>
            </div>
            <div style="float:right;text-align:center;width:50%;">
                <p style="font-size: 18px;margin:0"><b>New annual kWh</b></p>
                <p style="font-size: 18px;padding-bottom:10px" id="greenit-prediction-new"></p>
                <div>After optimization you will have : {{optimization_new_servers}} server(s)</div>
            </div>
        </div>
        <hr>

            <clr-datagrid *ngIf="optimization_modal">
                <clr-dg-action-bar style="margin-top:0px">
                    <div class="btn-group" style="float:right">
                        <button type="button" class="btn btn-sm btn-link" (click)="exportOptimization()">
                            Export to CSV
                        </button>
                    </div>
                </clr-dg-action-bar>
                <clr-dg-column [clrDgField]="'NEWFLAG'" [clrDgSortOrder]="dataOrderDesc">&nbsp;</clr-dg-column>
                <clr-dg-column [clrDgField]="'NAME'"><ng-container *clrDgHideableColumn="{hidden: false}">New server or server to keep</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'OLDKWH'"><ng-container *clrDgHideableColumn="{hidden: false}">Current annual kWh</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'KWH'"><ng-container *clrDgHideableColumn="{hidden: false}">New annual kWh (estimation)</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'OLDSERVERS'"><ng-container *clrDgHideableColumn="{hidden: false}">Servers that will be replaced</ng-container></clr-dg-column>
            
                <clr-dg-row *clrDgItems="let entry of optimization_json.SERVERS" [clrDgItem]="entry">
                    <clr-dg-cell><span *ngIf="entry.NEWFLAG" style="width:36px" class="badge badge-danger">NEW</span></clr-dg-cell>
                    <clr-dg-cell>{{entry.NAME}}</clr-dg-cell>
                    <clr-dg-cell>{{number(entry.OLDKWH).toFixed(0)}}</clr-dg-cell>
                    <clr-dg-cell>{{number(entry.KWH).toFixed(0)}}</clr-dg-cell>
                    <clr-dg-cell>{{entry.OLDSERVERS.split(',').join(', ')}}</clr-dg-cell>
                </clr-dg-row>              
                <clr-dg-footer>
                    <clr-dg-pagination #opti_page [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                        {{opti_page.firstItem + 1}} - {{opti_page.lastItem + 1}} of {{opti_page.totalItems}} entries
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
       

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>

<!-- GREENIT EE -->
<clr-modal [(clrModalOpen)]="efficiency_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Servers efficiency list</h3>
    <div class="modal-body">
        <clr-datagrid *ngIf="efficiency_modal">
            <clr-dg-action-bar style="margin-top:0px">
                <div class="btn-group" style="float:right">
                    <button type="button" class="btn btn-sm btn-link" (click)="exportEfficiency()">
                        Export to CSV
                    </button>
                </div>
            </clr-dg-action-bar>
            <clr-dg-column>&nbsp;</clr-dg-column>
            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'cee'">
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left">
                        CEE
                        <span class="tooltip-content dt-tooltip">Current Energy Efficiency = Power / Number of virtual machines</span>
                    </div>
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'tee'">
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left">
                        TEE
                        <span class="tooltip-content dt-tooltip">Theorical Energy Efficiency = Power / Theoretical max number of virtual machines"</span>
                    </div>
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'wh'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual kWh</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'wh'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual cost ({{globalCurrency}})</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual CO2 (kg CO2eq)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'power'"><ng-container *clrDgHideableColumn="{hidden: false}">Power (W)</ng-container></clr-dg-column>

            <clr-dg-row *clrDgItems="let host of efficiency_data" [clrDgItem]="host">
                <clr-dg-cell>
                    <button type="button" style="color:black;margin:0" class="btn btn-sm btn-link" (click)="displayModalSimulation(host)">
                        <clr-icon shape="line-chart"></clr-icon>
                    </button>
                </clr-dg-cell>
                <clr-dg-cell>{{host.name}}</clr-dg-cell>
                <clr-dg-cell><font [attr.color]="(host.vmnb == 0) ? 'red' : ''">{{host.cee}}</font></clr-dg-cell>
                <clr-dg-cell>{{number(host.tee).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.wh/1000).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{(number(host.cost * host.wh) / 1000).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.co2).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.power).toFixed(2)}}</clr-dg-cell>
            </clr-dg-row>              
            <clr-dg-footer>
                <clr-dg-pagination #cee_page [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{cee_page.firstItem + 1}} - {{cee_page.lastItem + 1}} of {{cee_page.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>

<!-- GREENIT MODAL SIMULATION -->
<clr-modal [(clrModalOpen)]="simulation_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Energy Efficiency Simulator</h3>
    <div class="modal-body" style="overflow:auto" *ngIf="simulation_data">
        <table width="90%" style="margin:auto;">
            <tr>
                <td style="width:33%;background-color:#f6f6f6"></td>
                <td align="center" style="width:33%;background-color:#f6f6f6;vertical-align:super;">
                    <b>{{simulation_data.name}}</b>
                </td>
                <td align="center" style="width:33%;background-color:#f6f6f6;vertical-align:super;"><b>Your new server</b></td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;Total cost ({{globalCurrency}}) :</td>
                <td align="right">
                    <p #currentcost id="greenit-current-cost"></p>
                </td>
                <td align="right">
                    <p #simucost id="greenit-simulation-cost"></p>
                </td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;Annual CO2 (kg CO2eq) :</td>
                <td align="right">
                    <p #currentco2 id="greenit-current-co2"></p>
                </td>
                <td align="right">
                    <p #simuco2 id="greenit-simulation-co2"></p>
                </td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;VM number max (estimation) :</td>
                <td align="right">
                    <p #currentnbvm id="greenit-current-nbvm"></p>
                </td>
                <td align="right">
                    <p #simunbvm id="greenit-simulation-nbvm"></p>
                </td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;TEE :</td>
                <td align="right">
                    <p #currentvmtee id="greenit-current-vmtee"></p>
                </td>
                <td align="right">
                    <p #simuvmtee id="greenit-simulation-vmtee"></p>
                </td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;Per VM repartition ({{globalCurrency}}) :</td>
                <td align="right">
                    <p #currentvmcost id="greenit-current-vmcost"></p>
                </td>
                <td align="right">
                    <p #simuvmcost id="greenit-simulation-vmcost"></p>
                </td>
            </tr>
        </table>

        <hr>

        <table width="90%" style="margin:auto;">
            <tr>
                <td style="width:33%;background-color:#f6f6f6">&nbsp;RAM capacity (GB) :</td>
                <td style="width:33%;" align="right">
                    <p id="greenit-current-server-ram"><b>{{math.round(simulation_data.ramcap / 1024) }}</b></p>
                </td>
                <td style="width:33%;">
                    <div class="clr-form-control" style="float:right;flex-direction:row;">
                        <div [ngClass]="(simulation_newServerRam == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                            <div class="clr-input-wrapper">
                                <input class="clr-input" type="number" [(ngModel)]="simulation_newServerRam" style="text-align:right;width:4em" (keyup)="updateSimulation();">
                                <clr-icon class="clr-validate-icon" *ngIf="simulation_newServerRam == null" shape="exclamation-circle"></clr-icon>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;Sockets number :</td>
                <td align="right">
                    <p id="greenit-current-server-socket"><b>{{simulation_data.sockets }}</b></p>
                </td>
                <td>
                    <div class="clr-form-control" style="float:right;flex-direction:row;">
                        <div [ngClass]="(simulation_newServerSocket == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                            <div class="clr-input-wrapper">
                                <input class="clr-input" type="number" [(ngModel)]="simulation_newServerSocket" style="text-align:right;width:4em" (keyup)="updateSimulation();">
                                <clr-icon class="clr-validate-icon" *ngIf="simulation_newServerSocket == null" shape="exclamation-circle"></clr-icon>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;Threads number :</td>
                <td align="right">
                    <p id="greenit-current-server-thread"><b>{{simulation_data.threads}}</b></p>
                </td>
                <td>
                    <div class="clr-form-control" style="float:right;flex-direction:row;">
                        <div [ngClass]="(simulation_newServerThread == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                            <div class="clr-input-wrapper">
                                <input class="clr-input" type="number" [(ngModel)]="simulation_newServerThread" style="text-align:right;width:4em" (keyup)="updateSimulation();">
                                <clr-icon class="clr-validate-icon" *ngIf="simulation_newServerThread == null" shape="exclamation-circle"></clr-icon>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;Annual kWh (without PUE) :</td>
                <td align="right">
                    <p id="greenit-current-kwh"><b>{{(simulation_data.wh / 1000 / simulation_data.pue).toFixed(2)}}</b></p>
                </td>
                <td>
                    <div class="clr-form-control" style="float:right;flex-direction:row;">
                        <div [ngClass]="(simulation_newServerkWh == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                            <div class="clr-input-wrapper">
                                <input class="clr-input" type="number" [(ngModel)]="simulation_newServerkWh" style="text-align:right;width:4em" (keyup)="updateSimulation();">
                                <clr-icon class="clr-validate-icon" *ngIf="simulation_newServerkWh == null" shape="exclamation-circle"></clr-icon>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;Server cost ({{globalCurrency}}):</td>
                <td align="right">
                    <div class="clr-form-control" style="float:right;flex-direction:row;">
                        <div [ngClass]="(simulation_currentServerCost == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                            <div class="clr-input-wrapper">
                                <input class="clr-input" type="number" [(ngModel)]="simulation_currentServerCost" style="text-align:right;width:4em" (keyup)="updateSimulation();">
                                <clr-icon class="clr-validate-icon" *ngIf="simulation_currentServerCost == null" shape="exclamation-circle"></clr-icon>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="clr-form-control" style="float:right;flex-direction:row;">
                        <div [ngClass]="(simulation_newServerCost == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                            <div class="clr-input-wrapper">
                                <input class="clr-input" type="number" [(ngModel)]="simulation_newServerCost" style="text-align:right;width:4em" (keyup)="updateSimulation();">
                                <clr-icon class="clr-validate-icon" *ngIf="simulation_newServerCost == null" shape="exclamation-circle"></clr-icon>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="background-color:#f6f6f6">&nbsp;Cost estimation based on (years) :</td>
                <td align="right">
                    <p #currentyears id="greenit-current-years"></p>
                </td>
                <td>
                    <div class="clr-form-control" style="float:right;flex-direction:row;">
                        <div [ngClass]="(simulation_amortization == null) ? 'clr-control-container clr-error' : 'clr-control-container'">
                            <div class="clr-input-wrapper">
                                <input class="clr-input" type="number" [(ngModel)]="simulation_amortization" style="text-align:right;width:4em" (keyup)="updateSimulation();">
                                <clr-icon class="clr-validate-icon" *ngIf="simulation_amortization == null" shape="exclamation-circle"></clr-icon>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="simulation_modal = false">Close</button>
    </div>
</clr-modal>

<!-- GREENIT QUANTIS -->
<clr-modal [(clrModalOpen)]="quantis_modal" [clrModalClosable]=true [clrModalSize]="'m'">
    <h3 class="modal-title">Quantis - Inspiring sustainable change</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;height:25%;margin-top:30px">
            <img src="../../assets/img/quantis_logo.svg" height="50px" style="display:block;margin:auto;" />
        </div>
        <div style="width:100%;">
            <p>Quantis guides top organizations to define, shape and implement intelligent environmental sustainability solutions. We deliver resilient strategies, robust metrics, useful tools, and credible communications.</p>
            <p>In a nutshell, we're the creative geeks. Our clients trust us to take the latest science and make it actionable.</p>
            <b>
                <p>For more information, visit our website: <a target="_blank" href="https://quantis-intl.com">https://quantis-intl.com</a></p>
                <p>and contact us for any demand: <a href="mailto:info@quantis-intl.com">info@quantis-intl.com</a></p>
            </b>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>

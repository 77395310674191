import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-t13]'
})
export class T13Directive {

  constructor(public viewContainerRef13: ViewContainerRef) { }

}

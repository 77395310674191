import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';

import { JsonloaderService, ManagementService, ShareService } from '@app/services';

import { JSONTarget, Message } from '@app/model';


@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.css']
})
export class AccountManagementComponent implements OnInit {

  @Input()
  targetInfrastructure: string = 'aws';
  getTargetInfrastructure() {
    return this.targetInfrastructure;
  }

  @Output("loadRegion") loadRegion: EventEmitter<any> = new EventEmitter();

  @Output("loadCloud") loadCloud: EventEmitter<any> = new EventEmitter();

  message: Message;

  isSettings: boolean = false;

  model: any = {
    regionSelected: '',
    accountSelected: ''
  };

  allAccounts: any = [];

  accounts: any = [];

  regions: any = [];

  isOrg: boolean = false;


  constructor(
    private json_svc: JsonloaderService,
    private mgt_svc: ManagementService,
    private message_svc: ShareService
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);

    let account_str: string = '';
    let region_str: string = '';

    this.mgt_svc.getRegions(this.getTargetInfrastructure()).pipe(first()).subscribe(
      data2 => {
        if(data2.length > 0) {
          this.allAccounts = data2;

          // BUILD ACCOUNT LIST
          let account_check: string[] = [];
          for(let account of this.allAccounts) {
            if(!account_check.includes(account.NAME)) {
              account_check.push(account.NAME);
              this.accounts.push(account);
            }
          }
         
          // BUILD REGIONS BY ACCOUNT
          account_str = this.allAccounts[0].NAME;
          this.regions = this.getAccountRegions(account_str);

          // INIT MODEL
          this.model.accountSelected = account_str;
          region_str = this.allAccounts[0].REGION;
          this.model.regionSelected = region_str;
          this.message.cloudAccount = account_str;
          this.message.cloudRegion = region_str;

          // CHECK FOR ORGANIZATION
          let account_json: string = this.model.accountSelected.replaceAll('-', '');
          let region_json: string = this.model.regionSelected.replaceAll('-', '');
          this.json_svc.getData('root','cloud_' + account_json + '_' + region_json, JSONTarget.CLOUD_ORG_VIEW).subscribe(
            data => {
              if(data.length > 0) {
                this.isOrg = true;
                this.accounts = [];
                this.regions = [];
                for(let i in data) {
                  let acc: any = {
                    NAME: data[i].name,
                    IDENTIFIER: data[i].uuid,
                    REGION: data[i].children
                  };
                  this.accounts.push(acc);
                }
                this.model.accountSelected = this.accounts[0].NAME;

                this.regions = this.getAccountRegionsFromOrg(this.model.accountSelected);
                this.model.regionSelected = this.regions[0];
                
                this.message.cloudAccount = this.model.accountSelected;
                this.message.cloudRegion = this.model.regionSelected;
              }
            }
          );
        }
      }
    );
  }

  initLoadAccount(): void {

    this.message.cloudAccount = this.model.accountSelected;
    let account: any = this.filterAccountByFullName(this.model.accountSelected);
    if(!this.isOrg)
      this.regions = this.getAccountRegions(account.NAME);
    else
      this.regions = this.getAccountRegionsFromOrg(account.NAME);

    this.model.regionSelected = this.regions[0];
    this.message.cloudRegion = this.model.regionSelected;
  }

  initLoadRegion(): void {

    this.message.cloudAccount = this.model.accountSelected;
    this.message.cloudRegion = this.model.regionSelected;
  }

  reloadRegion(): void {

    // FOR TEST ONLY
    /*this.message.cloudAccount = 'france-central';
    this.message.cloudRegion = 'francecentral';
    this.message.powerUsageEnv = 'azure';*/

    this.loadRegion.emit();

    this.loadCloud.emit();
  }

  private filterAccountByFullName(name: string): any {

    return this.accounts.find(account => account.NAME === name);
  }

  private getAccountRegions(name: string): any {

    let regions: any = [];
    let region_check: string[] = [];
    for(let account of this.allAccounts) {
      if(account.NAME === name) {
        if(!region_check.includes(account.REGION)) {
          region_check.push(account.REGION);
          regions.push(account.REGION);
        }
      }
    }

    return regions;
  }

  private getAccountRegionsFromOrg(name: string): any {

    let regions: any = [];
    let region_check: string[] = [];
    for(let account of this.accounts) {
      if(account.NAME === name) {
        for(let i in account.REGION) {
          regions.push(account.REGION[i].name);
        }
      }
    }

    return regions;
  }
}

import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';

import treemap from 'highcharts/modules/treemap';
treemap(Highcharts);
import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import { JsonloaderService, ShareService } from '@app/services';

import { Json, Message } from '@app/model';


@Component({
  selector: 'app-vminfodetail',
  templateUrl: './vminfodetail.component.html',
  styleUrls: ['./vminfodetail.component.css']
})
export class VminfodetailComponent implements AfterViewInit, OnInit {

  Highcharts2: typeof Highcharts = Highcharts;

  chartOptions2: Highcharts.Options;

  isReady2: boolean = false;

  message: Message;

  jsonLoader: Json;

  data_vms: any = [];

  private data_lin: any = [];

  private data_ora: any = [];

  private data_oth: any = [];

  private data_win: any = [];


  constructor(
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
	this.data_vms = this.jsonLoader.vmInfo;

	this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  ngAfterViewInit(): void {
	this.buildOsMap(this.message.vmInfoOs);
  }

  ngAfterViewChecked(): void {
  	this.cd.detectChanges();
  }

  private buildOsMap(pType: number): void {

	this.buildOs();
	let data: any = [];
	switch(pType) {
		case 0:
			data = this.data_win;
			break;
		case 1:
			data = this.data_lin;
			break;
		case 2:
			data = this.data_ora;
			break;
		case 3:
			data = this.data_oth;
			break;
		default:
			break;
	}
	this.chartOptions2 = {
        	chart: {
			type: 'treemap',
		},
		title: {
        		text: null
    		},
    		credits: {
        		enabled: false
		},
        	exporting: {
            		enabled: false
		},
		series: [{
        		type: "treemap",
        		layoutAlgorithm: 'strip',
        		alternateStartingDirection: false,
        		allowTraversingTree: true,
        		levels: [{
            			level: 1,
            			dataLabels: {
                			enabled: true,
                			align: 'left',
                			verticalAlign: 'top',
                			style: {
                    				fontSize: '15px',
                				fontWeight: 'bold'
                			}
            			}
			}],
			point: {
                		events: {
                    			click: function(e) {
						const p = e.point;
                        			var val = p.name.toString().toLowerCase();
						if(val != undefined) {
							var table : any;
							setTimeout(() => table = $('#vm-info-list').dataTable(), 100);
							setTimeout(() => this.filterData(table, 2, val), 200);
						}
					}.bind(this)
				}
			},
			data: data
    		}]
	};
	this.isReady2 = true;
  }

  private buildOs(): void {

	this.data_win = [];
	this.data_win.push(this.buildParentOs('B', 'Microsoft Windows Workstation', '#001939'));
	this.data_win.push(this.buildParentOs('A', 'Microsoft Windows Server', '#00A6EB'));

	this.data_lin = [];
	this.data_lin.push(this.buildParentOs('D', 'Suse Linux Enterprise Server', '#69B125'));
	this.data_lin.push(this.buildParentOs('E', 'Red Hat Enterprise Linux', '#BD0D10'));
	this.data_lin.push(this.buildParentOs('F', 'Debian', '#8AA599'));
	this.data_lin.push(this.buildParentOs('G', 'Other linux', '#FB8106'));

	this.data_ora = [];
	this.data_ora.push(this.buildParentOs('C', 'Oracle Solaris', '#6B8699'));

	this.data_oth = [];
	this.data_oth.push(this.buildParentOs('H', 'Other', '#FFBC75'));

	let nb_win = 0, nb_lin = 0, nb_ora = 0, nb_oth = 0;
	let nb_ora_11 = 0, nb_ora_10 = 0, nb_ora_9 = 0, nb_ora_oth = 0;
	let nb_win_2019 = 0, nb_win_2016 = 0, nb_win_2012 = 0, nb_win_2008 = 0, nb_win_2003 = 0, nb_win_2000 = 0, nb_win_nt = 0, nb_win_th = 0;
	let nb_win_10 = 0, nb_win_8 = 0, nb_win_7 = 0, nb_win_vista = 0, nb_win_xp = 0;
	let nb_lin_rhel_8 = 0, nb_lin_rhel_7 = 0, nb_lin_rhel_6 = 0, nb_lin_rhel = 0;
	let nb_lin_suse_11 = 0, nb_lin_suse_12 = 0, nb_lin_suse_15 = 0, nb_lin_suse = 0;
	let nb_lin_debian = 0;
	let nb_lin_oth = 0;

	for(var i in this.data_vms.data) {
		if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows nt')) {
                	++nb_win;
                	++nb_win_nt;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows 2000') ||
                    this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2000')) {
                	++nb_win;
                	++nb_win_2000;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2003')) {
                	++nb_win;
                	++nb_win_2003;
		} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2008')) {
                	++nb_win;
                	++nb_win_2008;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2012')) {
                	++nb_win;
                	++nb_win_2012;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2016')) {
                	++nb_win;
                	++nb_win_2016;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows server 2019')) {
                	++nb_win;
                	++nb_win_2019;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows server threshold')) {
                	++nb_win;
                	++nb_win_th;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows xp')) {
                	++nb_win;
                	++nb_win_xp;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows vista')) {
                	++nb_win;
                	++nb_win_vista;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows 7')) {
                	++nb_win;
                	++nb_win_7;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows 8')) {
                	++nb_win;
                	++nb_win_8;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('windows 10')) {
                	++nb_win;
			++nb_win_10;
		} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('red hat enterprise') ||
                    this.data_vms.data[i][2].toString().toLowerCase().includes('rhel')) {
                	++nb_lin;
                	if(this.data_vms.data[i][2].toString().toLowerCase().includes('linux 6'))
                        	++nb_lin_rhel_6;
                	else if(this.data_vms.data[i][2].toString().toLowerCase().includes('linux 7'))
                        	++nb_lin_rhel_7;
                	else if(this.data_vms.data[i][2].toString().toLowerCase().includes('linux 8'))
                        	++nb_lin_rhel_8;
                	else if(!this.data_vms.data[i][2].toString().toLowerCase().includes('linux 8') &&
                            !this.data_vms.data[i][2].toString().toLowerCase().includes('linux 7') &&
                            !this.data_vms.data[i][2].toString().toLowerCase().includes('linux 6'))
                        	++nb_lin_rhel;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('suse linux enterprise')) {
                	++nb_lin;
                	if(this.data_vms.data[i][2].toString().toLowerCase().includes('enterprise 15'))
                        	++nb_lin_suse_15;
                	else if(this.data_vms.data[i][2].toString().toLowerCase().includes('enterprise 12'))
                        	++nb_lin_suse_12;
                	else if(this.data_vms.data[i][2].toString().toLowerCase().includes('enterprise 11'))
                        	++nb_lin_suse_11;
                	else if(!this.data_vms.data[i][2].toString().toLowerCase().includes('enterprise 15') &&
                            !this.data_vms.data[i][2].toString().toLowerCase().includes('enterprise 12') &&
                            !this.data_vms.data[i][2].toString().toLowerCase().includes('enterprise 11'))
                        	++nb_lin_suse;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('debian')) {
                	++nb_lin;
                	++nb_lin_debian;
        	} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('fedora') ||
                    this.data_vms.data[i][2].toString().toLowerCase().includes('centos') ||
                    this.data_vms.data[i][2].toString().toLowerCase().includes('ubuntu') ||
                    this.data_vms.data[i][2].toString().toLowerCase().includes('autre linux') ||
                    this.data_vms.data[i][2].toString().toLowerCase().includes('other linux') ||
                    this.data_vms.data[i][2].toString().toLowerCase().includes('later linux') ||
                    this.data_vms.data[i][2].toString().toLowerCase().includes('2.6.x linux')) {
               		++nb_lin;
                	++nb_lin_oth;
		} else if(this.data_vms.data[i][2].toString().toLowerCase().includes('oracle')) {
                	++nb_ora;
                	if(this.data_vms.data[i][2].toString().toLowerCase().includes('11'))
                        	++nb_ora_11;
                	else if(this.data_vms.data[i][2].toString().toLowerCase().includes('10'))
                        	++nb_ora_10;
                	else if(this.data_vms.data[i][2].toString().toLowerCase().includes('9'))
                        	++nb_ora_9;
                	else
                        	++nb_ora_oth;
        	} else {
                	++nb_oth;
        	}
        }
	// BUILD OS CHILD - WINDOWS SERVER
    	this.data_win.push(this.buildChildOs('2019', 'A', nb_win_2019, '#001939'));
    	this.data_win.push(this.buildChildOs('2016', 'A', nb_win_2016, '#001939'));
    	this.data_win.push(this.buildChildOs('threshold', 'A', nb_win_th, '#001939'));
    	this.data_win.push(this.buildChildOs('2012', 'A', nb_win_2012, '#001939'));
    	this.data_win.push(this.buildChildOs('2008', 'A', nb_win_2008, '#001939'));
	this.data_win.push(this.buildChildOs('2003', 'A', nb_win_2003, '#001939'));
	this.data_win.push(this.buildChildOs('Windows NT', 'A', nb_win_nt, '#001939'));

	// BUILD OS CHILD - WINDOWS WORKSTATION
    	this.data_win.push(this.buildChildOs('Windows XP', 'B', nb_win_xp, '#00A6EB'));
    	this.data_win.push(this.buildChildOs('Windows Vista', 'B', nb_win_vista, '#00A6EB'));
    	this.data_win.push(this.buildChildOs('Windows 7', 'B', nb_win_7, '#00A6EB'));
    	this.data_win.push(this.buildChildOs('Windows 8', 'B', nb_win_8, '#00A6EB'));
    	this.data_win.push(this.buildChildOs('Windows 10', 'B', nb_win_10, '#00A6EB'));

	// BUILD OS CHILD - LINUX SERVER
    	this.data_lin.push(this.buildChildOs('Linux Enterprise 15', 'D', nb_lin_suse_15, '#69B125'));
    	this.data_lin.push(this.buildChildOs('Linux Enterprise 12', 'D', nb_lin_suse_12, '#69B125'));
    	this.data_lin.push(this.buildChildOs('Linux Enterprise 11', 'D', nb_lin_suse_11, '#69B125'));
    	this.data_lin.push(this.buildChildOs('Enterprise_suse', 'D', nb_lin_suse, '#69B125'));
    	this.data_lin.push(this.buildChildOs('Enterprise Linux 8', 'E', nb_lin_rhel_8, '#BD0D10'));
    	this.data_lin.push(this.buildChildOs('Enterprise Linux 7', 'E', nb_lin_rhel_7, '#BD0D10'));
    	this.data_lin.push(this.buildChildOs('Enterprise Linux 6', 'E', nb_lin_rhel_6, '#BD0D10'));
    	this.data_lin.push(this.buildChildOs('Enterprise_redhat', 'E', nb_lin_rhel, '#BD0D10'));
    	this.data_lin.push(this.buildChildOs('Debian', 'F', nb_lin_debian, '#8AA599'));
    	this.data_lin.push(this.buildChildOs('Other_Linux', 'G', nb_lin_oth, '#FB8106'));

	// BUILD OS CHILD - ORACLE
    	this.data_ora.push(this.buildChildOs('Solaris 11', 'C', nb_ora_11, '#6B8699'));
    	this.data_ora.push(this.buildChildOs('Solaris 10', 'C', nb_ora_10, '#304250'));
    	this.data_ora.push(this.buildChildOs('Solaris 9', 'C', nb_ora_9, '#00758F'));
    	this.data_ora.push(this.buildChildOs('Solaris other', 'C', nb_ora_oth, '#00758F'));

	// BUILD OS CHILD - OTHER
	this.data_oth.push(this.buildChildOs('Other', 'H', nb_oth, '#FFBC75'));
  }

  private buildParentOs(pId: string, pName: string, pColor: string): any {

	let parentOs = {
        	id: pId,
        	name: pName,
        	color: pColor
	};

	return parentOs;
  }

  private buildChildOs(pName: string, pParent: string, pVal: number, pColor: string): any {

	let childOs = {
        	name: pName,
        	parent: pParent,
        	value: pVal,
        	color: pColor
    	};

	return childOs;
  }

  private filterData(table: any, col: number, test: string): void {
	
	table.api().columns(col).search('').draw();
	table.api().columns(col).search(test).draw();
  }
}

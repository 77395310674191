import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { GreenitTarget } from '@app/greenit/greenit.enums';
import { GreenitcfComponent } from '@app/greenit/greenitcf/greenitcf.component';
import { JsonloaderService } from '@app/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.css']
})
export class StorageComponent implements OnInit {

  @ViewChild('greenitcf') greenitcf: GreenitcfComponent;

  isReady: boolean = false;
  allowReload: boolean = false;

  t1_target: GreenitTarget = GreenitTarget.STORAGE_CO2;

  cardClass: string = 'card addScroll';

  json_subscription: Subscription;

  /**
   * 
   */
  constructor(private json_svc: JsonloaderService) { }

  /**
   * 
   */
  ngOnInit(): void {
    this.isReady = true;

    // Need subscription to JsonloaderService to detect filter reload
    this.json_subscription = this.json_svc.currentJson.subscribe(
			json => {
        if(this.greenitcf !== undefined && this.allowReload) {
					this.greenitcf.load(true);
        }
        this.allowReload = true;
			}
		)
  }

  /**
   * 
   */
  @HostListener('unloaded')
	ngOnDestroy(): void {
		// Remove subscription
		if (this.json_subscription != undefined) {
			this.json_subscription.unsubscribe();
		}
		//console.log("destroyed storage");
	}

  /**
   * 
   */
  loadClass(): void {
    if(this.cardClass == "card addScroll")
      this.cardClass = 'card'
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { VDCResource, VDCSynthesis } from '@app/model';

import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);


@Component({
  selector: 'app-vdcviewbar',
  templateUrl: './vdcviewbar.component.html',
  styleUrls: ['./vdcviewbar.component.css']
})
export class VDCviewbarComponent implements OnInit {


  @Input() target: VDCResource;

  @Input() data: VDCSynthesis;

  res_name: string;
  res_unit: string;

  res_total: number;
  res_use: number;
  res_use_percent: number;
  res_free: number;

  cons_total: number;
  cons_use: number;
  cons_use_percent: number;
  cons_use_percent_label: any;
  cons_free: number;

  /**
   * 
   */
  constructor() { }

  /**
   * 
   */
  ngOnInit(): void { }

  /**
   * 
   */
  ngOnChanges(changes: any): void {
    if(!changes.data.firstChange) {
      this.data = changes.data.currentValue;
    }
    this.updateComponent();
  }

  /**
   * 
   */
  private updateComponent(): void {

    switch(this.target) {
      case VDCResource.CPU:
        this.res_name = "CPU";
        this.res_unit = "GHz";

        this.cons_total =  Math.round((this.data.avgVdcCpuLimit / 1000) * 100) / 100; //MHz to GHz
        this.cons_use =  Math.round((this.data.avgVdcCpuUsed / 1000) * 100) / 100;
        this.cons_use_percent = Number(Number(this.cons_use / this.cons_total * 100).toFixed(0));
        this.cons_use_percent_label = this.cons_use_percent;
        this.cons_free = Number(Number(this.cons_total - this.cons_use).toFixed(2));

      break;
      case VDCResource.RAM:
        this.res_name = "Memory";
        this.res_unit = "GB";

        this.cons_total = Math.round((this.data.avgVdcRamLimit / 1024) * 100) / 100; // MB to GB
        this.cons_use = Math.round((this.data.avgVdcRamUsed / 1024) * 100) / 100;
        this.cons_use_percent = Number(Number(this.cons_use / this.cons_total * 100).toFixed(0));
        this.cons_use_percent_label = this.cons_use_percent;
        this.cons_free = Number(Number(this.cons_total - this.cons_use).toFixed(2));

      break;
      case VDCResource.DISK:
        this.res_name = "Storage";
        this.res_unit = "GB";

        this.cons_total = Math.round(this.data.avgVdcDiskLimit / 1024); // MB to GB
        this.cons_use = Math.round(this.data.avgVdcDiskUsed / 1024);
        this.cons_use_percent = Number(Number(this.cons_use / this.cons_total * 100).toFixed(0));
        this.cons_use_percent_label = this.cons_use_percent;
        this.cons_free = Number(Number(this.cons_total - this.cons_use).toFixed(2));

      break;
    }

    //XXX If no limit, show partial infos
    if(this.cons_total == 0) {
      this.cons_use_percent = this.cons_use > 0 ? 33 : 0;
      this.cons_use_percent_label = "--";
      this.cons_free = undefined;
      this.cons_total = undefined;
    }

  }

}

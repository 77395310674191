import { Component, Input, OnInit } from '@angular/core';
import { RpSynthesis } from '@app/model';

import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { RPResource } from '../rpsynthesis.enums';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);


@Component({
  selector: 'app-rpviewbar',
  templateUrl: './rpviewbar.component.html',
  styleUrls: ['./rpviewbar.component.css']
})
export class RpviewbarComponent implements OnInit {


  @Input() target: RPResource;

  @Input() data: RpSynthesis;

  rp_res_name: string;
  rp_unit: string;

  rp_res_total: number;
  rp_res_use: number;
  rp_res_use_percent: number;
  rp_res_free: number;

  rp_cons_total: number;
  rp_cons_use: number;
  rp_cons_use_percent: number;
  rp_cons_free: number;


  storage_view: boolean;

  /**
   * 
   */
  constructor() { }

  /**
   * 
   */
  ngOnInit(): void {

    this.storage_view = false;

    switch(this.target) {
      case RPResource.CPU:
        this.rp_res_name = "CPU";
        this.rp_unit = "GHz";

        if(this.data.cpu_rescap != 0) {
          this.rp_res_total = Math.round((this.data.cpu_rescap / 1000) * 100) / 100; //MHz to GHz
          this.rp_res_use = Math.round((this.data.cpu_resused / 1000) * 100) / 100;
          this.rp_res_use_percent = Number(Number(this.rp_res_use / this.rp_res_total * 100).toFixed(0));
          this.rp_res_free = Number(Number(this.rp_res_total - this.rp_res_use).toFixed(2));
        } else {
          this.rp_res_total = 0;
          this.rp_res_use = 0;
          this.rp_res_use_percent = 0;
          this.rp_res_free = 0;
        }

        if(this.data.cpu_maxusage != 0) {
          this.rp_cons_total =  Math.round((this.data.cpu_maxusage / 1000) * 100) / 100; //MHz to GHz
          this.rp_cons_use =  Math.round((this.data.cpu_overallusage / 1000) * 100) / 100;
          this.rp_cons_use_percent = Number(Number(this.rp_cons_use / this.rp_cons_total * 100).toFixed(0));
          this.rp_cons_free = Number(Number(this.rp_cons_total - this.rp_cons_use).toFixed(2));
        } else {
          this.rp_cons_total = 0;
          this.rp_cons_use = 0;
          this.rp_cons_use_percent = 0;
          this.rp_cons_free = 0;
        }

      break;
      case RPResource.RAM:
        this.rp_res_name = "Memory";
        this.rp_unit = "GB";

        if(this.data.ram_rescap != 0) {
          this.rp_res_total = Math.round((this.data.ram_rescap / 1024) * 100) / 100; // MB to GB
          this.rp_res_use = Math.round((this.data.ram_resused / 1024) * 100) / 100;
          this.rp_res_use_percent = Number(Number(this.rp_res_use / this.rp_res_total * 100).toFixed(0));
          this.rp_res_free = Number(Number(this.rp_res_total - this.rp_res_use).toFixed(2));
        } else {
          this.rp_res_total = 0;
          this.rp_res_use = 0;
          this.rp_res_use_percent = 0;
          this.rp_res_free = 0;
        }

        if(this.data.cpu_maxusage != 0) {
          this.rp_cons_total = Math.round((this.data.ram_maxusage / 1024) * 100) / 100; // MB to GB
          this.rp_cons_use = Math.round((this.data.ram_overallusage / 1024) * 100) / 100;
          this.rp_cons_use_percent = Number(Number(this.rp_cons_use / this.rp_cons_total * 100).toFixed(0));
          this.rp_cons_free = Number(Number(this.rp_cons_total - this.rp_cons_use).toFixed(2));
        } else {
          this.rp_cons_total = 0;
          this.rp_cons_use = 0;
          this.rp_cons_use_percent = 0;
          this.rp_cons_free = 0;
        }

      break;
      case RPResource.STORAGE:
        this.rp_res_name = "Storage";
        this.rp_unit = "GB";

        if(this.data.sto_cap != 0) {
          this.rp_cons_total = Math.round(this.data.sto_cap / 1024); // MB to GB
          this.rp_cons_use = Math.round(this.data.sto_cons / 1024);
          this.rp_cons_use_percent = Number(Number(this.rp_cons_use / this.rp_cons_total * 100).toFixed(0));
          this.rp_cons_free = Number(Number(this.rp_cons_total - this.rp_cons_use).toFixed(2));
        } else {
          this.rp_cons_total = 0;
          this.rp_cons_use = 0;
          this.rp_cons_use_percent = 0;
          this.rp_cons_free = 0;
        }

        this.storage_view = true;
      break;
    }
 }

}

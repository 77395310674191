import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import * as moment from 'moment';

import { DataTableDirective } from 'angular-datatables';

import { AccountService, AuditService, JsonloaderService, ShareService } from '@app/services';

import { ElementBehaviors, Json, JSONTarget, Message, RecoVm, TimePercent, User, VmSynthesis } from '@app/model';


@Component({
  selector: 'app-recommendationoperation',
  templateUrl: './recommendationoperation.component.html',
  styleUrls: ['./recommendationoperation.component.css']
})
export class RecommendationoperationComponent implements AfterViewInit, OnDestroy, OnInit {


  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  message: Message;

  reco_vms_data: RecoVm[] = [];

  reco_model: RecoVm[] = [];

  all_class: string = 'label label-blue addLink';

  idle_class: string = 'label addLink';

  nb_idle: number = 0;

  lazy_class: string = 'label addLink';

  nb_lazy: number = 0;

  normal_class: string = 'label addLink';

  nb_normal: number = 0;

  busy_class: string = 'label addLink';

  nb_busy: number = 0;

  oversized_class: string = 'label addLink';

  nb_oversized: number = 0;

  tools_class: string = 'label addLink';

  nb_tools: number = 0;

  zombie_class: string = 'label addLink';

  nb_zombie: number = 0;

  tm_valid: boolean = true;

  moment = moment;

  private currentUser: User;

  private math = Math;


  constructor(
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
        private message_svc: ShareService,
        private audit_svc: AuditService,
        private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.message_svc.currentMessage.subscribe(message => this.message = message);

	if(this.message.currentBehTbl) {
		if(this.message.periodView != "all" && this.message.periodView != "last_1D" && this.message.periodView != "last_30D" &&
	  	  this.message.periodView != "last_60D" && this.message.periodView != "last_180D" && this.message.periodView != "last_360D")
			this.tm_valid = false;
	} else {
		this.tm_valid = false;
	}

	if(this.tm_valid)
		this.initDtOptions();
	else
		this.initDtOptions2();

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_VM).subscribe(
	  	data => {
			this.reco_vms_data = data;
			this.buildData();
      		}
	  );
  }

  ngAfterViewInit(): void {
	this.dtTrigger.next();
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
  }

  filterReco(item: string): void {
	
	this.reco_model = [];
	this.reco_model = this.getRecoFromBehavior(item);
	this.reloadTable();
  }

  private buildData(): void {

	let table: any = $('#reco-operation-list').dataTable();
	table.fnProcessingIndicator();

	if(this.reco_vms_data.length > 0) {
		for(var i=0; i < this.reco_vms_data.length; i++) {
	  		if(this.reco_vms_data[i].behavior == "idle")
				++this.nb_idle;
	  		else if(this.reco_vms_data[i].behavior == "lazy")
				++this.nb_lazy;
	  		else if(this.reco_vms_data[i].behavior == "normal")
				++this.nb_normal;
	  		else if(this.reco_vms_data[i].behavior == "busy" || this.reco_vms_data[i].behavior == "undersized")
				++this.nb_busy;
	  		else if(this.reco_vms_data[i].behavior == "oversized")
	  			++this.nb_oversized;
	  		else if(this.reco_vms_data[i].behavior == "zombie")
	  			++this.nb_zombie;

	  		if(this.reco_vms_data[i].reco_tools == "install" || this.reco_vms_data[i].reco_tools == "update" || this.reco_vms_data[i].reco_tools == "enable")
				++this.nb_tools;
	  	}
	}

	if(this.tm_valid) {
		this.audit_svc.getElementBehaviors(this.currentUser.login, this.message.currentFilter, this.message.minTimeFilter, this.message.maxTimeFilter).pipe(first()).subscribe(
			data => {
	  			if(data.length > 0) {
					for(var i=0; i < data.length; i++) {
	  					for(var j=0; j < this.reco_vms_data.length; j++) {
	  						if(data[i].uuid == this.reco_vms_data[j].uuid && data[i].total > 0) {
	  							this.reco_vms_data[j].time_percent.idle = this.math.round(data[i].idle*100/data[i].total);
								this.reco_vms_data[j].time_percent.lazy = this.math.round(data[i].lazy*100/data[i].total);
								this.reco_vms_data[j].time_percent.normal = this.math.round(data[i].normal*100/data[i].total);
								this.reco_vms_data[j].time_percent.undersized = this.math.round(data[i].undersized*100/data[i].total);
								this.reco_vms_data[j].time_percent.busy = this.math.round(data[i].busy*100/data[i].total);
	  							this.reco_vms_data[j].time_percent.oversized = this.math.round(data[i].oversized*100/data[i].total);
	  						}
						}
	  				}
	  			}
	  			setTimeout(() => {
					this.filterReco('all');
	  			}, 500);
                	},
                	error => {
                        	if(error != null)
                                	console.log(error)
                	}
		);
	} else {
		this.filterReco('all');
	}
  }

  private getRecoFromBehavior(behavior: string): RecoVm[] {

	let result: RecoVm[] = [];

	for(var i=0; i < this.reco_vms_data.length; i++) {
		switch(behavior) {
	  		case "all":
  				this.all_class = 'label label-blue';
  				this.idle_class = 'label addLink';
  				this.lazy_class = 'label addLink';
  				this.normal_class = 'label addLink';
  				this.busy_class = 'label addLink';
  				this.oversized_class = 'label addLink';
  				this.tools_class = 'label addLink';
  				this.zombie_class = 'label addLink';
				result.push(this.reco_vms_data[i]);
	  			break;
	  		case "idle":
  				this.all_class = 'label addLink';
  				this.idle_class = 'label label-blue';
  				this.lazy_class = 'label addLink';
  				this.normal_class = 'label addLink';
  				this.busy_class = 'label addLink';
  				this.oversized_class = 'label addLink';
  				this.tools_class = 'label addLink';
  				this.zombie_class = 'label addLink';
	  			if(this.reco_vms_data[i].behavior == "idle")
	  				result.push(this.reco_vms_data[i]);
	  			break;
	  		case "lazy":
  				this.all_class = 'label addLink';
  				this.idle_class = 'label addLink';
  				this.lazy_class = 'label label-blue';
  				this.normal_class = 'label addLink';
  				this.busy_class = 'label addLink';
  				this.oversized_class = 'label addLink';
  				this.tools_class = 'label addLink';
  				this.zombie_class = 'label addLink';
	  			if(this.reco_vms_data[i].behavior == "lazy")
	  				result.push(this.reco_vms_data[i]);
	  			break;
	  		case "normal":
  				this.all_class = 'label addLink';
  				this.idle_class = 'label addLink';
  				this.lazy_class = 'label addLink';
  				this.normal_class = 'label label-blue';
  				this.busy_class = 'label addLink';
  				this.oversized_class = 'label addLink';
  				this.tools_class = 'label addLink';
  				this.zombie_class = 'label addLink';
	  			if(this.reco_vms_data[i].behavior == "normal")
	  				result.push(this.reco_vms_data[i]);
	  			break;
	  		case "busy":
  				this.all_class = 'label addLink';
  				this.idle_class = 'label addLink';
  				this.lazy_class = 'label addLink';
  				this.normal_class = 'label addLink';
  				this.busy_class = 'label label-blue';
  				this.oversized_class = 'label addLink';
  				this.tools_class = 'label addLink';
  				this.zombie_class = 'label addLink';
	  			if(this.reco_vms_data[i].behavior == "busy" || this.reco_vms_data[i].behavior == "undersized")
	  				result.push(this.reco_vms_data[i]);
	  			break;
	  		case "oversized":
  				this.all_class = 'label addLink';
  				this.idle_class = 'label addLink';
  				this.lazy_class = 'label addLink';
  				this.normal_class = 'label addLink';
  				this.busy_class = 'label addLink';
  				this.oversized_class = 'label label-blue';
  				this.tools_class = 'label addLink';
  				this.zombie_class = 'label addLink';
	  			if(this.reco_vms_data[i].behavior == "oversized")
	  				result.push(this.reco_vms_data[i]);
	  			break;
	  		case "tools":
  				this.all_class = 'label addLink';
  				this.idle_class = 'label addLink';
  				this.lazy_class = 'label addLink';
  				this.normal_class = 'label addLink';
  				this.busy_class = 'label addLink';
  				this.oversized_class = 'label addLink';
  				this.tools_class = 'label label-blue';
  				this.zombie_class = 'label addLink';
	  			if(this.reco_vms_data[i].reco_tools == "install"
	  			    || this.reco_vms_data[i].reco_tools == "update"
	  			    || this.reco_vms_data[i].reco_tools == "enable")
	  				result.push(this.reco_vms_data[i]);
	  			break;
	  		case "zombie":
  				this.all_class = 'label addLink';
  				this.idle_class = 'label addLink';
  				this.lazy_class = 'label addLink';
  				this.normal_class = 'label addLink';
  				this.busy_class = 'label addLink';
  				this.oversized_class = 'label addLink';
  				this.tools_class = 'label addLink';
  				this.zombie_class = 'label label-blue';
	  			if(this.reco_vms_data[i].behavior == "zombie")
	  				result.push(this.reco_vms_data[i]);
	  			break;
			default:
	  			break;
		}
	}

	return result;
  }

  private reloadTable(): void {

	  if(this.dtElement.dtInstance != undefined) {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                	$( "div.dataTables_filter" ).remove();
                	dtInstance.destroy();
                	this.dtTrigger.next();
          	});
	  }
  }

  private initDtOptions(): void {

	this.dtOptions = {
		pagingType: 'full_numbers',
	  	pageLength: 10,
		lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
		processing: true,
		destroy: true,
		deferRender: true,
		initComplete: function() {
			$('div.dataTables_filter').appendTo('span.search');
	  		$("[id*='reco-operation-list_length']").css({'padding-top':'5px'});
	  		$("[name*='reco-operation-list_length']").css({'padding':'0px'});
			let table: any = $('#reco-operation-list').dataTable();
			table.fnProcessingIndicator( false );
	  	},
	  	columnDefs: [
	  		{ "width": "10%", "targets": 0, className: 'dt-body-center' },
	  		{ "width": "25%", "targets": 1, className: 'dt-body-center' },
	  		{ "width": "10%", "targets": 2, className: 'dt-body-center' },
	  		{ "width": "5%", "targets": 3, className: 'dt-body-center' },
	  		{ "width": "5%", "targets": 3, className: 'dt-body-center' },
	  		{ "width": "5%", "targets": 5, className: 'dt-body-center' },
	  		{ "width": "40%", "targets": 6, className: 'dt-body-center' }
	  	],
	  	language: {
	  		zeroRecords: "no data",
	  		search: '',
	  		searchPlaceholder: " search..."
	  	},
		dom: '<"top">rt<"bottom"lifp>'
	};
  }

  private initDtOptions2(): void {

	this.dtOptions = {
		pagingType: 'full_numbers',
	  	pageLength: 10,
		lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
		processing: true,
		destroy: true,
		deferRender: true,
		initComplete: function() {
			$('div.dataTables_filter').appendTo('span.search');
	  		$("[id*='reco-operation-list_length']").css({'padding-top':'5px'});
	  		$("[name*='reco-operation-list_length']").css({'padding':'0px'});
			let table: any = $('#reco-operation-list').dataTable();
			table.fnProcessingIndicator( false );
		},
	  	columnDefs: [
	  		{ "width": "15%", "targets": 0, className: 'dt-body-center' },
	  		{ "width": "25%", "targets": 1, className: 'dt-body-center' },
	  		{ "width": "25%", "targets": 2, className: 'dt-body-center' },
	  		{ "width": "25%", "targets": 3, className: 'dt-body-center' },
	  		{ "width": "5%", "targets": 3, className: 'dt-body-center' },
	  		{ "width": "5%", "targets": 5, className: 'dt-body-center' },
	  	],
	  	language: {
	  		zeroRecords: "no data",
	  		search: '',
	  		searchPlaceholder: " search..."
	  	},
		dom: '<"top">rt<"bottom"lifp>'
	};
  }
}

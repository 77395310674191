import { AfterViewInit, AfterContentChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import { Message, User, VmSynthesis } from '@app/model';

import { commaBigNumber, getUserCurrency } from '../../assets/js/tools.js';


@Component({
  selector: 'app-vmlist',
  templateUrl: './vmlist.component.html',
  styleUrls: ['./vmlist.component.css']
})
export class VmlistComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions: any = {};
	  
  dtTrigger: Subject<any> = new Subject();

  message: Message;

  data_vms: VmSynthesis[] = [];

  math = Math;

  globalCurrency: string = '';

  currentUser: User;

  private filter_table: string = '';


  constructor(
	private router: Router,
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService) {
  }

  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();
	$( "dt-buttons" ).remove();

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.globalCurrency = getUserCurrency(this.currentUser.currency);

	this.data_vms = this.json_svc.json.vmSynthesis;

	this.message_svc.currentMessage.subscribe(message => this.message = message);

	this.initDtOptions();
  }

  ngAfterViewInit(): void {

	this.dtTrigger.next();
	if(this.message.currentType == "CLUSTER" && this.data_vms.length > 0)
		this.reloadTable();
  }

  ngAfterViewChecked(): void {

	if(this.filter_table != this.message.vmListFilter && this.message.currentType == "SERVER") {
	  	this.filter_table = this.message.vmListFilter;
		this.reloadTable();
	  	var table : any;
	  	setTimeout(() =>table = $('#host-vm-list').dataTable(), 100);
	  	setTimeout(() => this.filterData(table, this.message.colListFilter, this.message.vmListFilter), 200);
	}
  }

  ngOnDestroy(): void {

        this.dtTrigger.unsubscribe();
	this.message.vmListFilter = '';
  }

  filterVm(): VmSynthesis[] {

	let vms: VmSynthesis[] = [];
	for (var i in this.data_vms) {
		if(this.data_vms[i].name != "vm_average") {
			if(this.data_vms[i].rpuuid == this.message.currentUuid || this.data_vms[i].cluster == this.message.currentUuid || this.data_vms[i].father == this.message.currentUuid)
				vms.push(this.data_vms[i]);
		}
	}

	return vms;
  }

  formatCost(cost: number): number {
	return commaBigNumber(cost);
  }

  callroute(vm): void {

	this.message.currentUuid = vm.uuid;
	this.message.currentName = vm.name;
	this.message.currentType = 'VM';
	this.router.navigate(['/vmviews/synthesis']);
  }

  private filterData(table: any, col: number, item: string): void {

	table.api().columns(4).search('').draw();
	table.api().columns(5).search('').draw();
	if(item != "all") {
		if(item == "OTHER") {
			table.api().columns(col).search('OTHER');
			table.api().columns(col).search('SUSPENDED').draw();
		} else {
	  		table.api().columns(col).search(item).draw();
		}
	}
  }

  private reloadTable(): void {

	  if(this.dtElement != undefined) {
	  	if(this.dtElement.dtInstance != undefined) {
			this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				$( "div.dataTables_filter" ).remove();
	  			$( "dt-buttons" ).remove();
                		dtInstance.destroy();
                		this.dtTrigger.next();
          		});
	  	}
	  }
  }

  private initDtOptions(): void {

	this.dtOptions = {
		pagingType: 'full_numbers',
		pageLength: 10,
		lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
		processing: true,
	  	destroy: true,
		initComplete: function() {
	  		$('div.dataTables_filter').appendTo('span.search');
			$('div.dt-buttons').appendTo('span.export');
	  		$("[id*='host-vm-list_length']").css({'padding-top':'5px'});
	  		$("[name*='host-vm-list_length']").css({'padding':'0px'});
	  	},
	  	language: {
	  		search: '',
	  		searchPlaceholder: " search..."
	  	},
	  	dom: '<"top"B><"clear">frtlip',
	  	buttons: [
	  		{ extend: 'csv', className: 'btn btn-link btn-sm' },
	  		{ extend: 'copy', className: 'btn btn-link btn-sm' },
	  		{ extend: 'print', className: 'btn btn-link btn-sm' }
	  	]
	};
  }
}

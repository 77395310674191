import { Component, OnInit } from '@angular/core';
import { GreenitTarget } from '@app/greenit/greenit.enums';

@Component({
  selector: 'app-t7',
  templateUrl: './t7.component.html',
  styleUrls: ['./t7.component.css']
})
export class T7Component implements OnInit {

  graph_target: GreenitTarget = GreenitTarget.DC_ALL_CO2;

  isReady: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isReady = true;
  }
}

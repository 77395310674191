<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="target"></clr-icon>
			&nbsp;Management of element views
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<button class="btn btn-primary" style="float:left;" (click)="wizardsv.open();initWizard()">
						<clr-icon shape="plus-circle" class="is-solid"></clr-icon>
						CREATE VIEW
					</button>
					<button class="btn btn-link btn-sm" style="float:right;" (click)="isUpdModel = true;" *ngIf="currentUser.login == 'root'">
						UPDATE MODEL
					</button>
				</div>
				<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">select a generated view</h5>
							<div class="card-text">
								<div class="clr-control-container">
									<div class="clr-select-wrapper">
										<select id="select-view" class="clr-select" [(ngModel)]="message.elementViewSelected" (ngModelChange)="switchView()">
											<option *ngFor="let view of filterView()">{{view.FILTER}}</option>
										</select>
									</div>
									<span class="clr-subtext">the list of element views</span>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="isDeletable()">
								Remove
							</button>
							<span class="label label-info" style="float:right" *ngIf="filterView().length > 0">{{current_view_type}}</span>
            					</div>
					</div>
				</div>
				<div class="clr-col-lg-7 clr-col-md-7 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">view configuration</h5>
							<div class="card-text">
								<ng-template load-selection-tree></ng-template>
							</div>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callCollapse()">
								Collapse
							</button>
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callExpand()">
								Expand
							</button>
							<button type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateChange()" *ngIf="message.isTreeAnalysis && !message.isElementWizard">
								Update
							</button>
            					</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<clr-wizard #wizardSelectionView 
	[clrWizardSize]="'xl'"
	(clrWizardOnPrevious)="goBack()"
	(clrWizardOnCancel)=doReset() 
	(clrWizardOnFinish)="doFinish()">
    <clr-wizard-title>Create an element view</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">Finish</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="model.name == '' || model.name == 'all' || model.name == 'ALL' || filterList(model.name)">
        <ng-template clrPageTitle>The name of the view</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-form-control">
					<div class="clr-input-container" [ngClass]="{'clr-error' :  model.error}">
						<input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="model.name" name="name" (ngModelChange)="checkName($event)" required />
						<clr-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="model.error"></clr-icon>
						<clr-control-error *ngIf="model.error">This field cannot set {{model.errorName}}</clr-control-error>
					</div>
					<span class="clr-subtext">the name of new element view</span>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>

    <clr-wizard-page>
        <ng-template clrPageTitle>Select the resource</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="clr-form-control">
							<div class="clr-control-container">
								<div class="clr-select-wrapper">
									<select id="select-resource" class="clr-select" [(ngModel)]="message.elementViewType">
		 								<option value="server">server tree</option>
										<option value="folder">folder tree</option>
										<option value="rp">resource pool tree</option>
										<option value="tag">tag tree</option>
									</select>
								</div>
								<a role="tooltip" aria-haspopup="true" class="tooltip tooltip-xs addLink" (click)="setInfo()">
    									<clr-icon shape="info-circle" size="24"></clr-icon>
								</a>
							</div>
							<span class="clr-subtext">to select the element type</span>
						</div>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="info">
						<span class="label label-info">
							example tree of a {{info_type}} view
						</span>
						<clr-tree [clrLazy]="true">
							<clr-tree-node *clrRecursiveFor="let item of tree_view_eg; getChildren: getItemChildren"
		        						[clrExpandable]="item.isExpandable"
								 	[(clrExpanded)]="item.opened">
								<clr-icon [attr.shape]="item.type == 'vm' ? 'vm' : 
										item.type == 'server' ? 'host' : 
										item.type == 'cluster' ? 'cluster' : 
										item.type == 'dc' ? 'data-cluster' : 
										item.type == 'folder' ? 'folder-open' : 
										item.type == 'tag' ? 'tag' : 
										item.type == 'category' ? 'tag' : 
										item.type == 'rp' ? 'resource-pool' : 
										item.type == 'view' ? 'tree-view' : ''">
								</clr-icon> 
								{{item.name}}
							</clr-tree-node>
						</clr-tree>
					</div>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="message.elementViewTree.length == 0" (clrWizardPageOnLoad)="callWizardTree()">
        <ng-template clrPageTitle>Select the elements</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<ng-template load-wizard-selection-tree></ng-template>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callCollapse()">
					Collapse
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callExpand()">
					Expand
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="selectALL()">
					SelectALL
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="unSelect()">
					Unselect
				</button>
            		</div>
		</div>
	</div>
    </clr-wizard-page>
</clr-wizard>
<clr-modal [(clrModalOpen)]="isDeleteView" [clrModalClosable]=false>
    <h3 class="modal-title">Delete view</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>This view will be permanently deleted !<b></b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteView = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="removeView();isDeleteView = false">Delete</button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="isDeleteError" [clrModalClosable]=false>
    <h3 class="modal-title">Cannot delete view</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
	    <p><b>the following filters are attached to this view</b></p>
	    <table class="table table-compact">
	    	<thead>
			<tr>
				<th>filter</th>
			</tr>
	    	</thead>
	    	<tbody>
    			<tr *ngFor="let filter of filter_view">
				<td>{{filter.name}}</td>
			</tr>
	    	</tbody>
	    </table>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteError = false">OK</button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="isUpdModel" [clrModalClosable]=false>
    <h3 class="modal-title">Update model</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>The model is updated every night. By validating this action it will be updated to the last hour of consumption.</p>
	    <p>
	    	<b>Updating the model may take a long time !</b>
	    </p>
            <p style="float:right;"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isUpdModel = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="callUpdate();isUpdModel = false">Update</button>
    </div>
</clr-modal>

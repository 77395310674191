<div class="clr-row" style="width: 100%">
    <div class="clr-col-11">
        <h3 style="margin: 5px;">
            Protocols analysis
            <span *ngIf="isLoading" style="margin: 0px;">
        <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
      </span>
        </h3>
    </div>
    <div class="clr-col-1">
    <span *ngIf="!isLoading" style="float: right; position: absolute;">
      <button (click)="reloadData()" class="btn btn-primary btn-sm" style="z-index: 1; position: absolute;">reload</button>
    </span>
    </div>
</div>

<clr-accordion style="margin-top: 15px;">
    <clr-accordion-panel>
        <clr-accordion-title>Temporal settings</clr-accordion-title>
        <clr-accordion-content>
            <app-heatmap-highcharts-heatmap
                    [onClick]=""
                    [updateEmitter]="resourceSelectionChanged"
                    [clickOnTimeSlotEmitter]="clickOnTimeSlotEmitter"
                    [selectedResources1]="[{uuid: 'datacenter'}]"
                    [selectedResources2]="[{uuid: 'datacenter'}]"
                    [maxHeight]="400"
                    [legendMaxHeight]="280"
                    [doubleClickSelectDay]="true"
                    [selectLastDay]="true"
            ></app-heatmap-highcharts-heatmap>
        </clr-accordion-content>
    </clr-accordion-panel>
    <clr-accordion-panel>
        <clr-accordion-title>Visualisation settings</clr-accordion-title>
        <clr-accordion-content>
            <clr-checkbox-container clrInline>
                <label>Options</label>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox name="destination" [(ngModel)]="visualisationSettings.only_flows_with_src_and_dst_in_the_filter" />
                    <label>Analyse only flows with source and destination in the filter</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>

<div>
  <span *ngFor="let selectionItem of currentSelection; let i = index">
    <span *ngIf="i > 0" style="margin-right: 5px;">/</span>
      <span (click)="zoomOnNode(selectionItem)">
          <span class="label {{ i === currentSelection.length -1 ? 'label-info' : '' }}">{{ selectionItem.data.object.svg_label }}
              : {{ selectionItem.data.object.name }}</span>
      </span>
    <span class="badge badge-info" *ngIf="selectionItem.data.object.type == 'vm'" (click)="showGraph(selectionItem)"><clr-icon
            shape="line-chart"></clr-icon></span>
  </span>
</div>

<clr-tabs>
    <clr-tab>
        <button clrTabLink>Map</button>
        <clr-tab-content>
            <span style="position: relative; z-index: 999; top:40px; left: 10px;">
                <clr-signpost>
                    <clr-icon shape="cog"
                              class="is-solid"
                              clrSignpostTrigger>
                    </clr-icon>
                    <clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen>
                        <label class="clr-control-label">Export:</label>
                        <div class="btn-group btn-primary btn-icon">
                            <button class="btn btn-primary btn-sm" (click)="exportGraph()">Graph</button>
                        </div>
                    </clr-signpost-content>
                </clr-signpost>
            </span>
            <span *ngIf="isLoading" style="margin: 0px; position: relative; z-index: 999; top:45px; right: 15px; float: right;">
                <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
            </span>
            <div id="divSvg" class="card" style="margin-top: 10px; width: 100%; height: 80%; min-height: 350px;"></div>
        </clr-tab-content>
    </clr-tab>

    <clr-tab>
        <button clrTabLink>Flows</button>
        <clr-tab-content *clrIfActive>
            <div class="clr-row">
                <div class="clr-col-12">
                    <button class="btn btn-primary btn-sm" style="float: right; margin-top: 15px; margin-bottom: 5px;" (click)="exportCSV()">Export CSV</button>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-12">
                    <clr-datagrid>
                        <clr-dg-column [clrDgField]="'src_address'">
                            Source
                            <clr-dg-string-filter [clrDgStringFilter]="sourceIpFilter"></clr-dg-string-filter>
                        </clr-dg-column>
                        <clr-dg-column [clrDgField]="'dst_address'">
                            Destination
                            <clr-dg-string-filter [clrDgStringFilter]="destinationIpFilter"></clr-dg-string-filter>
                        </clr-dg-column>
                        <clr-dg-column [clrDgField]="'port'" [clrDgSortOrder]="descSort">Port</clr-dg-column>
                        <clr-dg-column [clrDgField]="'protocol'">Protocol</clr-dg-column>
                        <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort">Total bytes</clr-dg-column>
                        <clr-dg-column>Actions</clr-dg-column>

                        <clr-dg-row *clrDgItems="let flow of perProtocolFlow" [clrDgItem]="flow">
                            <clr-dg-cell><a routerLink="/god/resource/{{flow.source.uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{flow.source.name}}</span></a></clr-dg-cell>

                            <clr-dg-cell><a routerLink="/god/resource/{{flow.target.short_uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{flow.target.name}}</span></a></clr-dg-cell>
                            <clr-dg-cell>{{flow.port}}</clr-dg-cell>
                            <clr-dg-cell>{{flow.protocol}}</clr-dg-cell>
                            <clr-dg-cell>{{flow.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>

                            <ng-template #actions_if_true>
                                <a *ngIf="flow.source.type === 'vm' && flow.target.type === 'vm'" style="margin-right: 5px;" routerLink="/netscope/dependencies-viewer/focuson/{{flow.source.uuid}},{{flow.target.uuid}}" routerLinkActive="active"><clr-icon shape="recycle"></clr-icon></a>
                                <a *ngIf="flow.source.type === 'vm' && flow.target.type === 'vm'" style="margin-right: 5px;" routerLink="/netscope/clusters-viewer/focuson/{{flow.source.uuid}},{{flow.target.uuid}}" routerLinkActive="active"><clr-icon shape="organization"></clr-icon></a>
                                <a routerLink="/netscope/heatmap-viewer/{{flow.source.uuid}}/{{flow.target.uuid}}" routerLinkActive="active"><clr-icon shape="heat-map"></clr-icon></a>
                            </ng-template>
                            <ng-template #actions_if_not_true></ng-template>
                            <clr-dg-cell>
                                <div *ngIf="flow.source !== undefined && flow.target !== undefined; then actions_if_true else actions_if_not_true"></div>
                            </clr-dg-cell>
                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                            </clr-dg-pagination>
                        </clr-dg-footer>

                    </clr-datagrid>
                </div>
            </div>
        </clr-tab-content>
    </clr-tab>
</clr-tabs>

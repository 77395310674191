import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { AccountService, InfrastructureService, JsonloaderService, ShareService } from '@app/services';
import { Router } from '@angular/router';

import { ClusterSynthesis, Json, Message, User, RpSynthesis } from '@app/model';

import { VmlistDirective } from '@app/directives/vmlist.directive';

import { RPResource } from './rpsynthesis.enums';

import { getUserCurrency } from '../../assets/js/tools';

import { VmlistComponent } from '@app/vmlist/vmlist.component';


@Component({
  selector: 'app-rpsynthesis',
  templateUrl: './rpsynthesis.component.html',
  styleUrls: ['./rpsynthesis.component.css']
})
export class RpsynthesisComponent implements OnInit {

  @ViewChild(VmlistDirective, {static: false}) addVmlist: VmlistDirective;

  math = Math;

  message: Message;

  currentUser: User;
  	
  globalCurrency: string;

  cpu_res: RPResource;
  ram_res: RPResource;
  sto_res: RPResource;

  jsonLoader: Json;

  data_rp: any;
  data_vms: any;
  data_clusters: any;

  cluster_name: string;
  cluster_uuid: string;
  
  rp_synth: RpSynthesis = {
    uuid: 'not registered',
    name: 'not registered',
	  clusteruuid: '',
	  cost: 0,
    vms: 0,
    cpusharesN: 'not registered',
    cpusharesV: 0,
    cpures: 0,
    cpuexpandable: false,
    cpulimit: 0,
    ramsharesN: 'not registered',
    ramsharesV: 0,
    ramres: 0,
    ramexpandable: false,
    ramlimit: 0,
    cpu_rescap: 0,
    cpu_maxusage: 0,
    cpu_overallusage: 0,
    cpu_resused: 0,
    ram_rescap: 0,
    ram_maxusage: 0,
    ram_overallusage: 0,
    ram_resused: 0,
    sto_cap: 0,
    sto_cons: 0,
  };

  isModalVmList: boolean;

  /**
   * 
  */
  constructor(private infra_svc: InfrastructureService, private authentication_svc: AccountService,
	private json_svc: JsonloaderService, private message_svc: ShareService, private router: Router,
	private componentFactoryResolver: ComponentFactoryResolver) {

	this.cpu_res = RPResource.CPU;
	this.ram_res = RPResource.RAM;
	this.sto_res = RPResource.STORAGE;

	this.cluster_name = "";
	this.cluster_uuid = "";

	this.isModalVmList = false;
  }

  /**
   * 
   */
  ngOnInit(): void {

    this.authentication_svc.user.subscribe(
      user => {
        this.currentUser = user;
        this.globalCurrency = getUserCurrency(this.currentUser.currency);
      }
    );

    this.json_svc.currentJson.subscribe(
      json => {
        this.jsonLoader = json;
        this.data_rp = this.jsonLoader.rpSynthesis;
        this.data_vms = this.jsonLoader.vmSynthesis;
        this.data_clusters = this.jsonLoader.clusterSynthesis;

        if(this.data_rp && this.data_rp.length > 0) {
          this.message_svc.currentMessage.subscribe(
            message => { 
              this.message = message;
              this.message.vmListFilter = '';

              // GET RP INFOS
              if(this.message.currentUuid != '') {
                this.rp_synth = this.getRPData(this.message.currentUuid);
                if(this.rp_synth === undefined) {
                  this.rp_synth = this.data_rp[0];
                  this.message.currentUuid = this.rp_synth.uuid;
                  this.message.currentName = this.rp_synth.name;		
                  this.message.currentType = "RESPOOL";
                }
              } else {
                this.rp_synth = this.data_rp[0];
                this.message.currentUuid = this.rp_synth.uuid;
                this.message.currentName = this.rp_synth.name;		
                this.message.currentType = "RESPOOL";	
              }

              // GET CLUSTER NAME
              let cluster_synth: ClusterSynthesis = this.getClusterData(this.rp_synth.clusteruuid);
              if(cluster_synth != undefined) {
                this.cluster_name = cluster_synth.name;
                this.cluster_uuid = cluster_synth.uuid;
              }

            }
          );
        }

      }
    );

  }

  /**
   * 
   */
  callClusterRoute(): void {
    this.message.currentUuid = this.cluster_uuid;
    this.message.currentName = this.cluster_name;
    this.message.currentType = 'CLUSTER';
    this.router.navigate(['/clusterviews/synthesis']);
  }

  /**
   * 
   */
  private getClusterData(uuid: string): ClusterSynthesis {
	  return this.data_clusters.find(cluster => cluster.uuid === uuid);
  }

  /**
   * 
   */
  private getRPData(uuid: string): RpSynthesis {
	  return this.data_rp.find(rp => rp.uuid === uuid);
  }

  /**
   * 
   */
  callVmList(): void {
    this.isModalVmList = true;
    setTimeout(() => this.loadVmList(), 100);
  }

  /**
   * 
   */
  private loadVmList(): void {
    if(this.addVmlist != undefined) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(VmlistComponent);
      const viewContainerRef = this.addVmlist.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
    }
  }

}

<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="tree"></clr-icon>
			&nbsp;Evolution
		</h5>
	</div>
	<div class="card-block" id="dashboard-t14-core">
		<div class="card-text">
			<div *ngIf="isReady">
				<highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="width:100%;display:block;" ></highcharts-chart>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
		</div>
	</div>
</div>
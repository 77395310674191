export let Settings = [ //
  'dc.direct.co2', 'dc.direct.cost', 'dc.pue', //
  'dc.host.year', 'dc.host.amortization', //
  'dc.host.embodied.co2', // 'dc.host.timeago' // Do not include it
  'dc.host.other.number', 'dc.host.other.sockets', 'dc.host.other.memory', //
  // 'dc.host.other.power', // Do not include it
  // 'dc.switch.number', 'dc.switch.power', //
  // 'dc.storage.number', 'dc.storage.power', //
  'desktop.printer.age', 'desktop.printer.home.number', 'desktop.printer.pro.number',//
  'desktop.laptop.age', 'desktop.laptop.13.number', 'desktop.laptop.15.number', 'desktop.laptop.17.number', 'desktop.laptop.charger.number',//
  'desktop.desktop.age', 'desktop.desktop.number', 'desktop.keyboard.number', 'desktop.mouse.number', 'desktop.screen.17.number', 'desktop.screen.22.number', 'desktop.screen.27.number',//
  'desktop.router.number', 'desktop.network.other.number', 'desktop.tablet.number', 'desktop.smartphone.number', //
  // 'network.direct.ratio', 'storage.direct.ratio', 'host.direct.ratio', //
  // 'optimization.host', //
  // 'settings.update', 'settings.purge', 'settings'.history' //
];

export let NETWORK_DIRECT_RATIO = 'network.direct.ratio';
export let STORAGE_DIRECT_RATIO = 'storage.direct.ratio';
export let HOST_DIRECT_RATIO = 'host.direct.ratio';

export enum Resource {
  CO2 = "co2",
  WH = "wh",
}
  
export enum GreenitTarget {
  HOST_CEE = "host.cee",
  HOST_NB = "host.nb",
  VM_PERHOST = "vm.perhost",
  VM_PERSOCKET = "vm.persocket",
  VM_POWER = "dc.vm.power.avg",
  ALL_CO2 = "all.co2.total",
  DC_ALL_CO2 = "dc.all.co2.total",
  DC_WH = "dc.all.wh.total",
  DESKTOP_CO2 = "desktop.all.co2.total",
  HOST_MINMAX = "host.minmax",
  HOST_SETTINGS = "host.settings",
  HOST_ANALYZES = "host.analyzes",
  DASHBOARD_DATA = "dashboard.co2.data",
  ALL_INCLUSIVE_CO2 = "allinclusive.co2.total",
  STORAGE_CO2 = "storage.co2.total",
  NETWORK_CO2 = "network.co2.total"
}

export enum TreeTarget {
  INDICATOR, MINMAX
}

export enum DefaultValues {
  HOST_AGE = 1,
  HOST_AMORTIZATION = 4,
  DC_PUE = 2,
  DC_DIRECT_CO2 = 0.093,
  DC_DIRECT_COST = 0.11,
  HOST_EXTRAPOLATION_RATIO = 65,
  NETWORK_EXTRAPOLATION_RATIO = 10,
  STORAGE_EXTRAPOLATION_RATIO = 25
}
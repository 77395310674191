import { Component, Input, OnInit } from '@angular/core';
import { zip } from 'rxjs';

import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);

import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
import export_data from 'highcharts/modules/export-data';
exporting(Highcharts);
offline(Highcharts);
export_data(Highcharts);

import { GreenitService, ShareService } from "@app/services";
import { Message } from '@app/model';

import { first } from 'rxjs/operators';


@Component({
  selector: 'app-power-graph',
  templateUrl: './power-graph.component.html',
  styleUrls: ['./power-graph.component.css']
})
export class PowerGraphComponent implements OnInit {

  message: Message;

  @Input()
  selectedElements: any[];
  getSelectedElements() {
    return this.selectedElements != null ? this.selectedElements : [];
  }

  @Input()
  targetInfrastructure: string = 'vmware';
  getTargetInfrastructure() {
    return this.targetInfrastructure;
  }

  selectedCounters: any[] = [];
  getSelectedCounters() {
    return this.selectedCounters != null ? this.selectedCounters : [];
  }


  dynamic_period_views = ['all', 'last_1D', 'last_30D', 'last_60D', 'last_180D', 'last_360D'];
  show_last: boolean;
  maxtimeFilter: number;

  startTime: number;
  endTime: number;
  minRange: number;
  maxRange: number;

  lastSearchHash: string = "";

  updateFlag: boolean;
  displayGraph: boolean;
  noDataMessage: string;

  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart;

  options: Highcharts.Options = {
    chart: {
      type: 'stockChart',
      zoomType: 'x',
      events: {
        redraw: function () {
          this.hideLoading();
        }
      }
    },
    credits: {
      enabled: false
    },
    time: {
      useUTC: false
    },
    title: {
      text: ''
    },
    legend: {
      enabled: true,
      align: 'left',
      verticalAlign: 'top',
      borderWidth: 0,
      maxHeight: 70,
    },
    exporting: {
      enabled: true,
      fallbackToExportServer: false,
      /* buttons: {
        contextButton: {
          menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG']
        }
      } */
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        verticalAlign: 'top',
        y: 0
      }
    },
    navigator: {
      series: [],
      adaptToUpdatedData: false
    },
    rangeSelector: {
      inputEnabled: false,
      enabled: true
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
      valueDecimals: 0
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: '#666666'
        }
      }
    },
    scrollbar: {
      liveRedraw: false
    },
    yAxis: {
      opposite: false
    },
    xAxis: {
      events: {
        afterSetExtremes: (event) => {

          if (event.trigger && (event.trigger == "zoom" || event.trigger == "rangeSelectorButton" || event.trigger == "navigator")) {

            // See graph-on-demand.component.ts

            // Quick way to ensure that we don't do the same queries in parallel. This can be caused by the fact that
            // the Highcharts navigator "is moving" thus firing several instances of the "setExtreme" event.
            let searchHash = `${this.getSelectedCounters()}_${this.getSelectedElements()}_${event.min}_${event.max}`;
            if (searchHash == this.lastSearchHash) {
              return;
            } 
            this.lastSearchHash = searchHash;

            //console.log(event);
            //console.log(this.lastSearchHash);

            this.minRange = event.min != undefined ? event.min : this.minRange;
            this.maxRange = event.max != undefined ? event.max : this.maxRange;

            /*if(this.maxRange >= this.maxtimeFilter && this.show_last) {
                //console.log("after set extremes");
                this.maxRange = this.endTime;
                this.chart.xAxis[0].setExtremes(this.minRange, this.maxRange);
            }*/

            this.updateChart(); 
          }

        },
      },
      type: 'datetime',
      minRange: 3600 * 1000 // one hour
    },
    series: [],
  };

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;
  };


  /**
   * 
   */
  constructor(public message_svc: ShareService, private greenit_svc: GreenitService) {

    // Init default power counter
    this.greenit_svc.getCountersListObservable().subscribe(countersResult => {
      let defaultCounterForThisCategory = "AVG_POWER";
      let countersMatchingDefault = countersResult.filter((c) => c.metricName == defaultCounterForThisCategory);
      this.selectedCounters = countersMatchingDefault;
    });

    this.startTime = 0;
    this.endTime = 2553270400000;
    this.minRange = this.startTime;
    this.maxRange = this.endTime;

    this.lastSearchHash = "";

    this.updateFlag = false;
    this.displayGraph = false;
    this.noDataMessage = "No element is selected";

  }

  /**
   * 
   */
  ngOnInit(): void {
    
    // Get min max from period view
    this.message_svc.currentMessage.subscribe((message) => {
      this.maxtimeFilter = message.maxTimeFilter;

      if (message.minTimeFilter != 0) {
        this.startTime = message.minTimeFilter != undefined ? message.minTimeFilter : this.startTime;
        this.minRange = message.minTimeFilter != undefined ? message.minTimeFilter : this.minRange;
      }
      if (message.maxTimeFilter != 0) {
        this.endTime = message.maxTimeFilter != undefined ? message.maxTimeFilter : this.endTime;
        this.maxRange = message.maxTimeFilter != undefined ? message.maxTimeFilter : this.maxRange;
      }

      // For all dynamic timeview, show last hours metrics
      if(this.dynamic_period_views.includes(message.periodView)) {
        this.endTime = this.endTime + ((24 * 3600 -1 ) * 1000);
        this.maxRange = this.maxRange + ((24 * 3600 - 1) * 1000);
        this.show_last = true;
      }

    });
  }

  ngAfterContentInit() {
  }

  updateChart() {
    if(this.targetInfrastructure == "vmware")
      this.updateVmwareChart();
    else
      this.updateCloudChart();
  }

  /**
   * 
   */
  updateVmwareChart() {

    if (this.getSelectedElements().length == 0) {
      this.displayGraph = false;
      this.noDataMessage = "No element is selected";
      return;
    } else {
      this.displayGraph = true;
      this.noDataMessage = "";
    }

    // Show loading
    this.chart.showLoading();

    // Get data
    let navData = this.greenit_svc.getDataObservable(this.selectedElements != null ? this.selectedElements : [], this.startTime, this.endTime, this.selectedCounters, this.show_last);
    let seriesData = this.greenit_svc.getDataObservable(this.selectedElements != null ? this.selectedElements : [], this.minRange, this.maxRange, this.selectedCounters, this.show_last);

    // Waiting for data
    zip(navData, seriesData).subscribe(
      all => {

        let navigatorData: any = all[0];
        let dataSeries: any = all[1];

        // Set Y axis
        let allUnits = this
          .getSelectedCounters()
          .map(c => c.description.unit)
          .filter((v, i, a) => a.indexOf(v) === i); // Ensure that there is no duplicates

        let yAxes = [];
        for (let unit of allUnits) {
          let yAxis = {
            id: unit,
            title: {
              text: unit
            },
            opposite: false
          };
          yAxes.push(yAxis);
        }

        // Build series
        const chartData = [];
        const navigatorChartData = [];

        for (let s of this.getSelectedElements()) {
          for (let c of this.getSelectedCounters()) {

            // Series
            let conversionFactor = c.description.conversionFactor;
            
            let resourceData = dataSeries.flat().filter((e) => e["uuid"] == s["uuid"]);
            
            let values = resourceData
              .map((e) => e["dataPoints"]
              .filter((p) => p.metricName == c.metricName || p.metricName == c.metricNameMinutely)
              .map((x) => x.value * conversionFactor))
              .flat();

            let times = resourceData.map((e) => e["time"]);
            let dataPoints = values.map((e, i) => [times[i], e]);

            // Remove duplicates
            dataPoints = dataPoints.filter((a, index, array) => array.findIndex(t => t[0] == a[0]) == index);

            // Navigator serie
            let navigatorResourceData = navigatorData.flat().filter((e) => e["uuid"] == s["uuid"]);

            let navigatorValues = navigatorResourceData
              .map((e) => e["dataPoints"]
                .filter((p) => p.metricName == c.metricName)
                .map((x) => x.value))
              .flat();

            let navigatorTimes = navigatorResourceData.map((e) => e["time"]);
            let navigatorDataPoints = navigatorValues.map((e, i) => [navigatorTimes[i], e]);

            // Remove duplicates
            navigatorDataPoints = navigatorDataPoints.filter((a, index, array) => array.findIndex(t => t[0] == a[0]) == index);


            // FIX empty dataPoints
            if(dataPoints.length == 0) {
              dataPoints[0] = [this.endTime, 0];
              navigatorDataPoints[0] = [this.endTime, 0];
            }


            // New serie
            let newSerie: Object = {
              type: "area",
              cursor: "pointer",
              //name: `${s['name']} - ${c['label']}`,
              name: `${s['name']}`,
              pointInterval: 24 * 3600 * 1000,
              data: dataPoints,
              yAxis: c.description.unit,
              showInNavigator: false,
              tooltip: {
                //valueSuffix: c.description.unit,
                //valueDecimals: 2
              }
            };

            // New navigator serie
            let newNavigatorSerie: Object = {
              type: "line",
              name: `${s['name']} - ${c['label']}_navigator`,
              pointInterval: 24 * 3600 * 1000,
              data: navigatorDataPoints
            };

            chartData.push(newSerie);
            navigatorChartData.push(newNavigatorSerie);

          }

        }

        // Update the chart
        this.options.yAxis = yAxes;
        this.options.series = chartData;
        this.options.navigator.series = navigatorChartData;

        this.updateFlag = true;
      }
    );

  }

  updateCloudChart() {
    
    this.message_svc.currentMessage.subscribe(message => this.message = message);

    if (this.getSelectedElements().length == 0) {
      this.displayGraph = false;
      this.noDataMessage = "No element is selected";
      return;
    } else {
      this.displayGraph = true;
      this.noDataMessage = "";
    }

    // Show loading
    this.chart.showLoading();
    
    let granularity: string = 'DAILY';

    if(this.maxRange/1000 - this.minRange/1000 <= 604800)
      granularity = 'HOURLY';

    let uuids: string[] = [];

    //console.log(this.minRange + " :: " + this.maxRange + " :: " + granularity);
    //console.log(this.getSelectedElements());
    if(this.getSelectedElements().length > 0) {
      for(let i in this.getSelectedElements()) {
        if(!uuids.includes(this.getSelectedElements()[i].uuid))
          uuids.push(this.getSelectedElements()[i].uuid);
      }
    }

    if(this.message.cloudAccount != "") {
      let powerInfo: any = {
          account: this.message.cloudAccount.replace("-", ""),
          start: this.minRange,
          end: this.maxRange,
          granularity: granularity,
          data: uuids
        };
        this.greenit_svc.getPowerRegion(powerInfo).pipe(first()).subscribe(
          data => {
            this.buildCloudSeries(data);
          },
          error => {
            if(error != null)
              console.log(error);
          }
        );
    }
  }

  private buildCloudSeries(data: any): void {

    let series: any = [];
  
    //console.log(this.getSelectedElements());
    // BUILD SERIES
    let uuids: string[] = [];
    for(let i=0; i < data.length; i++) {
      if(!uuids.includes(data[i].IDENTIFIER)) {
        uuids.push(data[i].IDENTIFIER);
        for(let j=0; j < this.getSelectedElements().length; j++) {
          if(this.getSelectedElements()[j].uuid === data[i].IDENTIFIER) {
            let cloud_data: any = [];
            let cloud_serie = {
              uuid: this.getSelectedElements()[j].uuid,
              name: this.getSelectedElements()[j].name + ' - Power - AVG (Watts)',
              type: "area",
              cursor: "pointer",
              pointInterval: 24 * 3600 * 1000,
              data: cloud_data
            };
            series.push(cloud_serie);
            break;
          }
        }
      }
    }
    // SET SERIES DATA
    for(let i=0; i < data.length; i++) {
      for(let j=0; j < series.length; j++) {
        if(data[i].IDENTIFIER === series[j].uuid) {
          let val: any = [];
          val[0] = data[i].TIMEAGO;
          val[1] = data[i].POWER;
          series[j].data.push(val);
          break;
        }
      }
    }

    this.options.series = series;
    this.updateFlag = true;
  }
}

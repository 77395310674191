import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import { AccountService, JsonloaderService, ManagementService, ShareService } from '@app/services';

import { HostSynthesis, Json, Message, SelectionFilter, ServerCost, SERVER_COST, User } from '@app/model';

import { SelectiontreeComponent } from '@app/selectiontree/selectiontree.component';
import { SelectiontreeDirective } from '@app/directives/selectiontree.directive';

import { getUserCurrency } from '../../../assets/js/tools.js';


@Component({
  selector: 'app-costserverdetail',
  templateUrl: './costserverdetail.component.html',
  styleUrls: ['./costserverdetail.component.css']
})
export class CostserverdetailComponent implements AfterViewInit, OnInit {

  @ViewChild(SelectiontreeDirective) addTree: SelectiontreeDirective;

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  model: any;

  data_hosts: HostSynthesis[] = [];

  data_cost: ServerCost[] = [];
	  
  message: Message;

  globalCurrency: string = '';

  private currentUser: User;

  private current_tool: boolean = false;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private management_svc: ManagementService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.globalCurrency = getUserCurrency(this.currentUser.currency);

	this.initDtOptions();

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.elementViewType = 'server-scope';

	this.model = {
		thread: 0,
	  	ram: 0,
		cost: 10000,
		ratio: 40,
		vcpu_cost: 50,
		vram_cost: 10
	};

	this.data_hosts = this.json_svc.json.hostSynthesis;
	if(this.data_hosts.length > 0) {
		let cpu_cap: number = 0;
		let ram_cap: number = 0;
		for(var i=0; i < this.data_hosts.length; i++) {
			cpu_cap += this.data_hosts[i].cpucap;
	  		ram_cap += this.data_hosts[i].ramcap;
	  		let vcpu_cost: number = 0;
			if(this.data_hosts[i].cpucap > 0)
				vcpu_cost = Math.round(40*10000/100/this.data_hosts[i].cpucap);
			let vram_cost: number = 0;
			if(Math.round(this.data_hosts[i].ramcap/1024) > 0)
	  			vram_cost = Math.round((100 - 40)*10000/100/Math.round(this.data_hosts[i].ramcap/1024));

	  		let srv_cost: ServerCost = {
				uuid: this.data_hosts[i].uuid,
				name: this.data_hosts[i].name,
    				thread: this.data_hosts[i].cpucap,
	  			ram: Math.round(this.data_hosts[i].ramcap/1024),
    				cost: 10000,
    				ratio: 40,
    				vcpu_cost: vcpu_cost,
				vram_cost: vram_cost
	  		};
			this.data_cost.push(srv_cost);
		}
		this.model.thread = Math.round(cpu_cap/ this.data_hosts.length);
		this.model.ram = Math.round(ram_cap/1024/this.data_hosts.length);
	}
	this.serverCostProcessing();
  }

  ngAfterViewInit(): void {

	this.dtTrigger.next();
	this.initServerCost();
  }

  ngAfterViewChecked(): void {

	if(this.current_tool != this.message.isCostTool) {
	  	this.current_tool = this.message.isCostTool;
	  	if(this.current_tool) {
			$( "div.dataTables_filter" ).remove();
	  		this.loadTree();
			$('#cost-server-list').slideToggle();
	  	} else {
			$('#cost-server-list').slideToggle();
			this.reloadTable();
	  	}
	}
        this.cd.detectChanges();
  }

  updateCostServer(): void {
	this.serverCostProcessing();
  }

  updateCostServerFromId(uuid: string): void {

	this.message.isCostRecord = true;
	try {
		let srv_cost: ServerCost = this.findServerCost(uuid);
		if(srv_cost != undefined) {
			this.message.isCostRecord = true;
			if(srv_cost.thread > 0)
				srv_cost.vcpu_cost = Math.round(srv_cost.ratio*srv_cost.cost/100/srv_cost.thread);
			if(srv_cost.ram > 0)
	  			srv_cost.vram_cost = Math.round((100 - srv_cost.ratio)*srv_cost.cost/100/srv_cost.ram);

			this.message.costSrvDetail = this.data_cost;
	  	}
	} catch(e) {
		console.log('fail to load ServerCost');
	}
  }

  updateCostVcpu() : void {

	if(this.model.ram > 0 && this.model.cost > 0) {
		this.model.ratio = Math.round(this.model.vcpu_cost*100*this.model.thread/this.model.cost);
		if(this.model.ratio < 100) {
			if(this.model.serverRatio < 0)
	  			this.model.serverRatio = 0;

			this.model.vram_cost = Math.round((100-this.model.ratio)*this.model.cost/100/this.model.ram);
	  	} else {
	  		this.model.serverRatio = 100;
	  		this.model.vram_cost = Math.round(this.model.cost/100/this.model.ram);
		}
	}
  }

  updateCostVcpuFromId(uuid: string) : void {

	try {
		let srv_cost: ServerCost = this.findServerCost(uuid);
		if(srv_cost != undefined) {
			this.message.isCostRecord = true;
			if(srv_cost.ram > 0 && srv_cost.cost > 0) {
				srv_cost.ratio = Math.round(srv_cost.vcpu_cost*100*srv_cost.thread/srv_cost.cost);
	  			if(srv_cost.ratio < 100) {
	  				if(srv_cost.ratio < 0)
	  					srv_cost.ratio = 0;

					srv_cost.vram_cost = Math.round((100-srv_cost.ratio)*srv_cost.cost/100/srv_cost.ram);
	  			} else {
					srv_cost.ratio = 100;
	  				srv_cost.vram_cost = Math.round(srv_cost.cost/100/srv_cost.ram);
				}
	  		}
			this.message.costSrvDetail = this.data_cost;
	  	}
	} catch(e) {
		console.log('fail to load ServerCost');
	}
  }

  updateCostVram() : void {

	if(this.model.thread > 0 && this.model.cost > 0) {
		this.model.ratio = 100 - Math.round(this.model.vram_cost*100*this.model.ram/this.model.cost);
		if(this.model.ratio < 0)
			this.model.ratio = 0;

		if(this.model.ratio > 100)
			this.model.ratio = 100;

		this.model.vcpu_cost = Math.round(this.model.ratio*this.model.cost/100/this.model.thread);
	}
  }

  updateCostVramFromId(uuid: string) : void {

	try {
		let srv_cost: ServerCost = this.findServerCost(uuid);
		if(srv_cost != undefined) {
			this.message.isCostRecord = true;
			if(srv_cost.thread > 0 && srv_cost.cost > 0) {
				srv_cost.ratio = 100 - Math.round(srv_cost.vram_cost*100*srv_cost.ram/srv_cost.cost);
	  			if(srv_cost.ratio < 0)
	  				srv_cost.ratio = 0;
	  			if(srv_cost.ratio > 100)
	  				srv_cost.ratio = 100;
	  			
				srv_cost.vcpu_cost = Math.round(srv_cost.ratio*srv_cost.cost/100/srv_cost.thread);
			}
			this.message.costSrvDetail = this.data_cost;
	  	}
	} catch(e) {
		console.log('fail to load ServerCost');
	}
  }

  ratioChange(): void {

	this.message.isCostRecord = true;
	this.serverCostProcessing();
  }

  ratioChangeFromid(uuid: string): void {

	try {
	  	let srv_cost: ServerCost = this.findServerCost(uuid);
		if(srv_cost != undefined) {
			this.message.isCostRecord = true;
			if(srv_cost.thread > 0)
				srv_cost.vcpu_cost = Math.round(srv_cost.ratio*srv_cost.cost/100/srv_cost.thread);

			if(srv_cost.ram > 0)
	  			srv_cost.vram_cost = Math.round((100 - srv_cost.ratio)*srv_cost.cost/100/srv_cost.ram);

			this.message.costSrvDetail = this.data_cost;
	  	}
	} catch(e) {
		console.log('fail to load ServerCost');
	}
  }

  private applyChild(node: any): void {

	if(node != undefined) {
		for(var i=0; i < node.children.length; i++) {
			if(node.children[i].type == "server" && node.children[i].selected == 1) {
	  			try {
					let srv_cost: ServerCost = this.findServerCost(node.children[i].id);
	  				srv_cost.cost = this.model.cost;
	  				srv_cost.ratio = this.model.ratio;
	  				srv_cost.vcpu_cost = this.model.vcpu_cost;
	  				srv_cost.vram_cost = this.model.vram_cost;
	  			} catch(e) {
	  				console.log('fail to load VmCost');
	  			}
	  		} else {
				this.applyChild(node.children[i]);
	  		}
	  	}
	}
  }

  applyElement(): void {

	for(var i = 0; i < this.message.elementViewTree.length; ++i) {
		if(this.message.elementViewTree[i].type == "server" && this.message.elementViewTree[i].selected == 1) {
	  		try {
				let srv_cost: ServerCost = this.findServerCost(this.message.elementViewTree[i].id);
	  			srv_cost.cost = this.model.cost;
	  			srv_cost.ratio = this.model.ratio;
	  			srv_cost.vcpu_cost = this.model.vcpu_cost;
	  			srv_cost.vram_cost = this.model.vram_cost;
	  		} catch(e) {
	  			console.log('fail to load ServerCost');
	  		}
	  	} else {
			this.applyChild(this.message.elementViewTree[i]);
		}
	}

	this.message.isTreeAnalysis = false;
	this.message.elementViewType = 'server';
	this.message.isCostTool = false;
	this.message.isCostRecord = true;
	this.message.costSrvDetail = this.data_cost;
  }

  closeCard(): void {
	this.message.isCostTool = false;
  }

  private initServerCost(): void {

	this.management_svc.getServerCost('root').pipe(first()).subscribe(
	  	data => {
			if(data.length > 0) {
	  			for(var i = 0; i < data.length; i++) {
	  				try {
	  					let srvCost: ServerCost = this.findServerCost(data[i].IDENTIFIER);
						srvCost.cost = data[i].HOSTCOST;
						srvCost.vcpu_cost = data[i].CPUCOST;
						srvCost.vram_cost = data[i].RAMCOST;
						srvCost.ratio = data[i].RATIO;
					} catch(e) {
						console.log('fail to find StorageCost');
	  				}
	  			}
				this.reloadTable();
	  		}
		},
		error => {
			if(error != null)
				console.log(error)
	  	}
	);
  }

  private findServerCost(uuid: string): ServerCost {
	return this.data_cost.find(sc => sc.uuid === uuid);
  }

  private loadTree(): void {

	if(this.addTree != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectiontreeComponent);
	  	const viewContainerRef = this.addTree.viewContainerRef;
	  	viewContainerRef.clear();
	  	const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private serverCostProcessing(): void {

	if(this.model.thread > 0)
		this.model.vcpu_cost = Math.round(this.model.ratio*this.model.cost/100/this.model.thread);
	if(this.model.ram > 0)
		this.model.vram_cost = Math.round((100 - this.model.ratio)*this.model.cost/ 100/this.model.ram);
  }

  private reloadTable(): void {

	  if(this.dtElement.dtInstance != undefined) {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			$( "div.dataTables_filter" ).remove();
                	dtInstance.destroy();
                	this.dtTrigger.next();
          	});
	  }
  }

  private initDtOptions(): void {

	this.dtOptions = {
		pagingType: 'full_numbers',
	  	pageLength: 10,
		lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
	  	processing: true,
	  	destroy: true,
		initComplete: function() {
	  		$('div.dataTables_filter').appendTo('span.search');
	  		$("[id*='DataTables_Table_']").css({'padding-top':'5px'});
	  		$("[name*='DataTables_Table_']").css({'padding':'0px'});
	  	},
	  	language: {
			loadingRecords: "loading .. ",
			zeroRecords: "loading ...",
	  		search: '',
	  		searchPlaceholder: " search..."
	  	},
		dom: '<"top">rt<"bottom"lifp>'
	};
  }
}

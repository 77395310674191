import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[cloud-opportunities-spot]'
})
export class CloudoppspotDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

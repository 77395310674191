import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import { Json, JSONTarget, Message, RecoVm, User } from '@app/model';

import { RecommendationresizingcpuComponent } from '@app/recommendation/recommendationresizingcpu/recommendationresizingcpu.component';
import { RecommendationresizingcpuDirective } from '@app/directives/recommendationresizingcpu.directive';

import { RecommendationresizingdiskComponent } from '@app/recommendation/recommendationresizingdisk/recommendationresizingdisk.component';
import { RecommendationresizingdiskDirective } from '@app/directives/recommendationresizingdisk.directive';

import { RecommendationresizingramComponent } from '@app/recommendation/recommendationresizingram/recommendationresizingram.component';
import { RecommendationresizingramDirective } from '@app/directives/recommendationresizingram.directive';


@Component({
  selector: 'app-recommendationresizing',
  templateUrl: './recommendationresizing.component.html',
  styleUrls: ['./recommendationresizing.component.css']
})
export class RecommendationresizingComponent implements AfterViewInit, OnInit {

  @ViewChild(RecommendationresizingdiskDirective) addResizingDisk: RecommendationresizingdiskDirective;
  @ViewChild(RecommendationresizingcpuDirective) addResizingCpu: RecommendationresizingcpuDirective;
  @ViewChild(RecommendationresizingramDirective) addResizingRam: RecommendationresizingramDirective;

  message: Message;

  math = Math;

  reco_vms_data: RecoVm[] = [];

  cpu_model: RecoVm[] = [];

  ram_model: RecoVm[] = [];

  disk_model: RecoVm[] = [];

  isDetail: boolean = false;

  private currentUser: User;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
        private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.recoResizeSearch = '';
	this.message.isRecoPowershell = false;

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_VM).subscribe(
		data => {
	  		this.reco_vms_data = data;
	  		if(this.reco_vms_data.length > 0)
	  			this.buildData();
      		}
	);
  }

  ngAfterViewInit(): void {
	
	setTimeout(() => this.loadResizeRam(), 100);
	setTimeout(() => this.loadResizeCpu(), 100);
	setTimeout(() => this.loadResizeDisk(), 100);
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  loadDetails(is_detail: boolean): void {
	this.isDetail = is_detail;
  }

  private loadResizeCpu(): void {

	if(this.addResizingCpu != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RecommendationresizingcpuComponent);
		const viewContainerRef = this.addResizingCpu.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadResizeDisk(): void {

	if(this.addResizingDisk != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RecommendationresizingdiskComponent);
		const viewContainerRef = this.addResizingDisk.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadResizeRam(): void {

	if(this.addResizingRam != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RecommendationresizingramComponent);
		const viewContainerRef = this.addResizingRam.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private buildData(): void {

	this.ram_model = this.buildTab('ram');
	this.cpu_model = this.buildTab('cpu');
	this.disk_model = this.buildTab('disk');
  }

  private buildTab(item: string): RecoVm[] {

	let result: RecoVm[] = [];

	switch(item) {
		case "cpu":
			for(var i = 0; i < this.reco_vms_data.length; i++) {
				if(this.reco_vms_data[i].cpu_gain > 0)
					result.push(this.reco_vms_data[i]);
			}
			result.sort((a, b) => (a.cpu_gain > b.cpu_gain) ? 1 : -1);
	  		break;
		case "disk":
			for(var i = 0; i < this.reco_vms_data.length; i++) {
				if(this.reco_vms_data[i].disk_saved > 0)
					result.push(this.reco_vms_data[i]);
			}
			result.sort((a, b) => (a.disk_saved > b.disk_saved) ? 1 : -1);
	  		break;
		case "ram":
			for(var i = 0; i < this.reco_vms_data.length; i++) {
				if(this.reco_vms_data[i].ram_gain > 0)
					result.push(this.reco_vms_data[i]);
			}
			result.sort((a, b) => (a.ram_gain > b.ram_gain) ? 1 : -1);
	  		break;
	  	default:
	  		break;
	}

	return result;
  }
}

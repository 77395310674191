import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-t14]'
})
export class T14Directive {

  constructor(public viewContainerRef14: ViewContainerRef) { }

}

<div class="clr-row">
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <div [ngClass]="cardClass">
            <div class="card-header card-ajust">
                <h5>
                    <clr-icon shape="tree"></clr-icon>
                    &nbsp;CO2 Emissions
                </h5>
            </div>
            <div class="card-block">
                <div class="card-text">
                    <div *ngIf="isReady">
                        <div class="alert alert-warning" role="alert" style="margin-bottom:20px">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                                    </div>
                                    <span class="alert-text">Network data is an extrapolation of server consumption.</span>
                                </div>
                            </div>
                        </div>
                        <app-greenitcf #greenitcf [showStackLabel]=false [target]="t1_target" [allowReload]="true" (loadClass)="loadClass()"></app-greenitcf>
                    </div>
                    <div *ngIf="!isReady">
                        <strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <div class="alert alert-info" role="alert" style="margin-top:1.2rem">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                    </div>
                    <span class="alert-text" style="color:#00567a;">
                        Network data is an extrapolation of server consumption.<br><br>
                        <b>For a detailed analysis of carbon emissions associated with the network, it is necessary to collect metrics directly from the hardware.</b>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

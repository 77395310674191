import { Component, ChangeDetectorRef, OnInit } from '@angular/core';

import { AccountService, GreenitService, JsonloaderService, ShareService } from '@app/services';

import { JSONTarget, Message, User } from '@app/model';

import { GreenitTarget } from '@app/greenit/greenit.enums';

import * as Highcharts from 'highcharts';
import { ClrDatagridSortOrder } from '@clr/angular';
import { getUserCurrency } from 'src/assets/js/tools';


@Component({
  selector: 'app-t9',
  templateUrl: './t9.component.html',
  styleUrls: ['./t9.component.css']
})
export class T9Component implements OnInit {

  dataOrder = ClrDatagridSortOrder.ASC;
  dataOrderDesc = ClrDatagridSortOrder.DESC;

  number = Number;

  message: Message;

  private currentUser: User;

  private shared_user: string;

  globalCurrency: string;

  // FOR HEATMAP
  hosts_data: Array<Array<string>> = [];
  host_tooltip: string;

  best_cee: any = {};
  worst_cee: any = {};

  isReady: boolean = false;

  // FOR EE MODAL
  efficiency_modal: boolean;
  efficiency_data: any = [];

  // FOR OPTIMIZATION MODAL
  optimization_json: any;
  optimization_modal: boolean;
  annual_kwh: number;
  annual_cost: number;
  annual_co2: number;
  optimization_kwh: number;
  optimization_cost: number;
  optimization_co2: number;
  optimization_current_servers: number;
  optimization_new_servers: number;


  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;
    this.chart.showLoading();
    
    if(this.efficiency_data.length > 0) {
	    this.setHeatmapData();
    }

    this.chart.hideLoading();
  };

  options: Highcharts.Options = {
    chart: {
        marginTop: 0,
				marginBottom: 0,
        height: '140px',
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        plotBorderWidth: 0,
				plotBorderColor: '#ffffff',
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    time: {
        useUTC: false
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: [],
				visible: false,
    },
    yAxis: {
        title: null,
        reversed: true,
        categories: [],
				visible: false,
				gridLineColor: 'transparent',
    },
    colorAxis: {
        minColor: '#FFFFFF',
        //maxColor: '#39ac73'
        maxColor: '#476888'
    },
    tooltip: {
        formatter: function () {
            return this.host_tooltip;
        }.bind(this)
    },
    legend: {
        align: 'right',
        layout: 'vertical',
        margin: 20,
        verticalAlign: 'top',
        symbolHeight: 90
    },
  }


  /**
   *  
   */
  constructor(private authentication_svc: AccountService, private message_svc: ShareService,
    private greenit_svc: GreenitService, private json_svc: JsonloaderService, private cd: ChangeDetectorRef) {

    this.worst_cee.name = "not available";
    this.worst_cee.cee = "n/a";
    this.best_cee.name = this.worst_cee.name;
    this.best_cee.cee = this.worst_cee.cee;
  }

  /**
   * 
   */
  ngOnInit(): void {
    this.authentication_svc.user.subscribe(user => this.currentUser = user);
	  this.message_svc.currentMessage.subscribe(message => this.message = message);

    this.globalCurrency = getUserCurrency(this.currentUser.currency);

    //XXX Check shared filter
    this.shared_user = this.currentUser.login;
    if(this.message.isShared) {
      this.shared_user = "root";
    }

    // FOR OPTIMIZATION
    this.annual_kwh = 0;
    this.annual_cost = 0;
    this.annual_co2 = 0;
    this.optimization_kwh = 0;
    this.optimization_cost = 0;
    this.optimization_co2 = 0;
    this.optimization_current_servers = 0;
    this.optimization_new_servers = 0;

    this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_SERVERS).subscribe(
      data => {
        this.optimization_json = data;
        if (this.optimization_json != null) {
          this.annual_kwh = Number(Number(this.optimization_json.OLDKWH).toFixed(0));
          this.annual_co2 = Number(Number(this.optimization_json.OLDCO2).toFixed(0));
          this.annual_cost = Number(Number(this.optimization_json.OLDCOST).toFixed(0));
          this.optimization_kwh = Number(Number(this.optimization_json.KWH).toFixed(0));
          this.optimization_co2 = Number(Number(this.optimization_json.CO2).toFixed(0));
          this.optimization_cost = Number(Number(this.optimization_json.COST).toFixed(0));
        }
      }
    );

    // FOR CEE
    this.greenit_svc.getGreenitData(this.shared_user, this.message.currentFilter, GreenitTarget.HOST_CEE).subscribe(
      data => {
        if(data != undefined && (<any>data).length > 0) {
          this.efficiency_data = data;

          // Keep only RUNNING servers
          this.efficiency_data = this.efficiency_data.filter((e: { state: string; }) => e.state == "RUNNING");

          if(this.efficiency_data != undefined && this.efficiency_data.length > 0) {
            
            // Sort per cee
            let best_cee = this.efficiency_data.filter((e: { cee: number}) => e.cee > 0).sort((a, b) => a.cee - b.cee);
            this.best_cee = best_cee[0];
            
            let worst_cee = this.efficiency_data.sort((a, b) => b.cee - a.cee);
            // Consider 0 as worst
            this.worst_cee = worst_cee[worst_cee.length - 1];
            if(this.worst_cee != undefined && this.worst_cee.tee > 0) {
              this.worst_cee = worst_cee[0];
            }

            this.isReady = true;
          }
        }
      }
    );
  }

  /**
   * 
   */
  private setHeatmapData(): void {
    let datas: Array<Array<number>> = [];

    let size = this.efficiency_data.length;
    let columns = Math.round(Math.sqrt(size));

    // FIX width
    columns = columns > 10 ? 10 : columns;

    let i = 0, j = 0;

    this.efficiency_data.forEach(element => {
      if(j == columns) {
        i++;
        j = 0;
      }
      if(j == 0)
        this.hosts_data[i] = [];

      let tmp_data = [i,j,element.cee];
      datas.push(tmp_data);

      this.hosts_data[i][j] = element.name;

      j++;
    });

    this.chart.addSeries({
      type:"heatmap",
      data: datas,
      borderWidth: 1,
      point: {
        events: {
          mouseOver: function (e: { target: any; }) {
            let p = e.target;
            this.host_tooltip = "<b>" + this.hosts_data[p.x][p.y] + "</b><br>Current Energy Efficiency : " + p.value;
          }.bind(this)
        }
      }
    });
  }

  /**
   * 
   */
  displayModalEfficiency(): void {
    this.efficiency_modal = true;
  }

  /**
  * Display DC optimization modal
  */
  displayModalOptimization(): void {
    this.optimization_modal = true;

    setTimeout(() => {
      if (this.optimization_json != null) {

        let current_kwh = Number(this.optimization_json.OLDKWH).toFixed(0);
        let new_kwh = Number(this.optimization_json.KWH).toFixed(0);

        if (Number(current_kwh) > Number(new_kwh)) {
          document.getElementById('greenit-prediction-current').innerHTML = '<b style="color:red">' + Intl.NumberFormat().format(Number(current_kwh)) + '</b>';
          document.getElementById('greenit-prediction-new').innerHTML = '<b style="color:green">' + Intl.NumberFormat().format(Number(new_kwh)) + '</b>';
        } else {
          document.getElementById('greenit-prediction-current').innerHTML = '<b style="color:green">' + Intl.NumberFormat().format(Number(current_kwh)) + '</b>';
          document.getElementById('greenit-prediction-new').innerHTML = '<b style="color:red">' + Intl.NumberFormat().format(Number(new_kwh)) + '</b>';
        }

        this.optimization_current_servers = this.optimization_json.CURRENT_SERVERS_NB;
        this.optimization_new_servers = this.optimization_json.OPTIMIZE_SERVERS_NB;

      }
    });
  }

  /**
   * 
   */
  closeModal() {
    this.efficiency_modal = false;
    this.optimization_modal = false;
  }

  /**
   * 
   */
  exportOptimization() {
    let res = ["NEW", "NEW SERVER OR ACTUAL", "CURRENT ANNUAL KWH", "NEW ANNUAL KWH (ESTIMATION)", "LIST OF REPLACED SERVERS"].join(',') + '\n';
		res += Object.values<any>(this.optimization_json.SERVERS).map(data =>
			[data.NEWFLAG, data.NAME, Number(data.OLDKWH).toFixed(0), Number(data.KWH).toFixed(0), data.OLDSERVERS.split(',').join(' - ')].join(",")
		).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'greenit_optimization.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

}

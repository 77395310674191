import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from '@app/model';
import { MonitorService, ShareService } from '@app/services';

@Component({
  selector: 'app-plugin',
  templateUrl: './plugin.component.html',
  styleUrls: ['./plugin.component.css']
})
export class PluginComponent implements OnInit {
    
  message: Message;

  plugin_modal: boolean;

  info_modal: boolean;
  info_header: string;
  info_body: string;


  constructor(private monitor_svc: MonitorService, private message_svc: ShareService,
    private router: Router) {
    this.plugin_modal = false;
    this.info_modal = false;
   }

  /**
    * 
    */
  ngOnInit(): void {
    this.message_svc.currentMessage.subscribe(message => this.message = message);
  }


  /**
   * 
   */
  displayModal(): void {
	  this.plugin_modal = true;
  }

  /**
   * 
   */
  displayInfo(): void {
	  this.info_modal = true;
  }

  /**
   * 
   */
  getXML(): void {
    this.monitor_svc.getPluginXML().subscribe(
      res => {
        //window.open(window.URL.createObjectURL(res), '_blank');

        let file_type = 'application/xml';

        // Create temp link
        let blob: Blob = new Blob([res], { type: file_type });
        let fileName = 'registration.xml';
        let objectUrl: string = URL.createObjectURL(blob);

        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      },
      error => {
        this.info_header = "Sorry ...";
        this.info_body = "We can not found your file :(";
        this.displayInfo();
    });
  }

  /**
   * 
   */
  update(): void {
    this.message.waiting = true;

    this.monitor_svc.updatePlugin(window.location.hostname).subscribe(
      data => {
        this.message.waiting = false;
        this.displayModal();
      },
      error => {
        this.message.waiting = false;
        this.info_header = "Sorry ...";
        this.info_body = "We can not initialize plugin properties :(";
        this.displayInfo();
    });
  }

}

<div class="clr-row" style="width: 100%">
    <div class="clr-col-11">
        <h3 style="margin: 5px;">
            Dependencies viewer
            <span *ngIf="isLoading" style="margin: 0px;">
        <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
      </span>
        </h3>
    </div>
    <div class="clr-col-1">
    <span *ngIf="!isLoading" style="float: right; position: absolute;">
      <button (click)="reloadData()" class="btn {{reloadButtonColorClass}} btn-sm" style="z-index: 1; position: absolute;">reload</button>
    </span>
    </div>
</div>

<clr-accordion style="margin-top: 15px;">

    <clr-accordion-panel #dataSettingsPanel>
        <clr-accordion-title>Map data settings <span class="badge badge-danger" style="min-width: 0.25rem; height: 0.25rem; padding: 0.1rem; border-radius: .25rem;" *ngIf="this.selectedResourcesWithSearchCombobox !== null && this.selectedResourcesWithSearchCombobox.length > 0"></span></clr-accordion-title>
        <clr-accordion-content>
            <clr-tabs>
                <clr-tab>
                    <button clrTabLink>Hosts</button>
                    <clr-tab-content *clrIfActive>

                        <clr-checkbox-container clrInline>
                            <label>Select the hosts that should be displayed <a (click)="checkFiltersCheckbox('hosts')" class="linkLabel"><span class="badge badge-info">check all</span></a> <a (click)="uncheckFiltersCheckbox('hosts')" class="linkLabel"><span class="badge badge-info">uncheck all</span></a></label>
                            <clr-checkbox-wrapper *ngFor="let host of graphData.routers">
                                <input type="checkbox" clrCheckbox name="host" value="{{host.uuid}}" [(ngModel)]="mapDataFilters.hosts[host.uuid]" (change)="clickHostCheckbox(host.uuid)" [indeterminate]="mapDataFilters.hosts[host.uuid] == 'indeterminate'"/>
                                <label>{{host.name}}</label>
                            </clr-checkbox-wrapper>

                        </clr-checkbox-container>
                    </clr-tab-content>
                </clr-tab>

                <clr-tab>
                    <button clrTabLink>Virtual Machines</button>
                    <clr-tab-content *clrIfActive>

                        <clr-checkbox-container clrInline>
                            <label>Select the virtual machines that should be displayed <a (click)="checkFiltersCheckbox('virtualMachines')" class="linkLabel"><span class="badge badge-info">check all</span></a> <a (click)="uncheckFiltersCheckbox('virtualMachines')" class="linkLabel"><span class="badge badge-info">uncheck all</span></a></label>
                            <clr-checkbox-wrapper *ngFor="let vm of replayData.vms">
                                <input type="checkbox" clrCheckbox name="virtualMachine" value="{{vm.uuid}}" [(ngModel)]="mapDataFilters.virtualMachines[vm.uuid]" (change)="clickVmCheckbox(vm.uuid)"/>
                                <label>{{vm.name}}</label>
                            </clr-checkbox-wrapper>

                        </clr-checkbox-container>
                    </clr-tab-content>
                </clr-tab>

                <clr-tab>
                    <button clrTabLink>Search</button>
                    <clr-tab-content *clrIfActive>

                        <form class="clr-form">
                            <input autocomplete="off" style="width: 100%; margin-left: 10px; margin-right: 10px; margin-top: 10px;" name="targetedResourcePattern" (keyup)="updateListTargetedResources()" [(ngModel)]="targetedResourcePattern" type="text" id="basic" placeholder="Enter pattern here" class="clr-input" />
                        </form>

                        <div class="clr-row" *ngIf="targetedResources.hosts.length > 0">
                            <div class="clr-col-12">
                                <div class="card">
                                    <div class="card-header">
                                        Hosts
                                    </div>
                                    <div class="card-block">
                                        <span class="label label-info" *ngFor="let targetedResource of targetedResources.hosts">
                                            <input (change)="clickHostCheckbox(targetedResource.uuid)" type="checkbox" clrCheckbox [(ngModel)]="mapDataFilters.hosts[targetedResource.uuid]" [indeterminate]="mapDataFilters.hosts[targetedResource.uuid] == 'indeterminate'"/>
                                            {{targetedResource.name}}
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('hosts', 'check')">check all</button>
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('hosts', 'uncheck')">uncheck all</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="clr-row" *ngIf="targetedResources.virtualMachines.length > 0">
                            <div class="clr-col-12">
                                <div class="card">
                                    <div class="card-header">
                                        Virtual machines
                                    </div>
                                    <div class="card-block">
                                        <span style="height: 30px;" class="label label-info" *ngFor="let targetedResource of targetedResources.virtualMachines">
                                            <input (change)="clickVmCheckbox(targetedResource.uuid)" type="checkbox" clrCheckbox [(ngModel)]="mapDataFilters.virtualMachines[targetedResource.uuid]" [indeterminate]="mapDataFilters.hosts[targetedResource.uuid] == 'indeterminate'"/>
                                            {{targetedResource.name}}
                                            <clr-icon *ngIf="isFocusedVm(targetedResource.uuid)" style="height: 16px; margin-left: 10px; color: #e79807; cursor: pointer;" shape="pin" class="is-solid" (click)="switchFocus(targetedResource)"></clr-icon>
                                            <clr-icon *ngIf="!isFocusedVm(targetedResource.uuid)" style="height: 16px; margin-left: 10px; cursor: pointer;" shape="pin" (click)="switchFocus(targetedResource)"></clr-icon>
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('virtualMachines', 'check')">check all</button>
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('virtualMachines', 'uncheck')">uncheck all</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </clr-tab-content>
                </clr-tab>

            </clr-tabs>

        </clr-accordion-content>
    </clr-accordion-panel>



    <clr-accordion-panel>
        <clr-accordion-title>Temporal settings</clr-accordion-title>
        <clr-accordion-content>
            <app-heatmap-highcharts-heatmap
                    [onClick]=""
                    [updateEmitter]="resourceSelectionChanged"
                    [clickOnTimeSlotEmitter]="clickOnTimeSlotEmitter"
                    [selectedResources1]="[{uuid: 'datacenter'}]"
                    [selectedResources2]="[{uuid: 'datacenter'}]"
                    [maxHeight]="400"
                    [legendMaxHeight]="280"
                    [doubleClickSelectDay]="true"
                    [selectLastDay]="true"
            ></app-heatmap-highcharts-heatmap>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>


<clr-tabs>
    <clr-tab>
        <button clrTabLink>Map</button>
        <clr-tab-content>
            <span style="position: relative; z-index: 999; top:40px; left: 10px;">
                <clr-signpost>
                    <clr-icon shape="cog"
                              class="is-solid"
                              clrSignpostTrigger>
                    </clr-icon>
                    <clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen>
                        <clr-radio-container>
                          <label>Click action:</label>
                          <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" value="focus" [(ngModel)]="vmClickAction" />
                            <label>focus</label>
                          </clr-radio-wrapper>
                          <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" value="graph" [(ngModel)]="vmClickAction" />
                            <label>view graphs</label>
                          </clr-radio-wrapper>
                        </clr-radio-container>
                        <label class="clr-control-label">Export:</label>
                        <div class="btn-group btn-primary btn-icon">
                            <button class="btn btn-primary btn-sm" (click)="exportGraph()">Graph</button>
                        </div>
                        <label *ngIf="focusedVms.length > 0" class="clr-control-label">Focus:</label>
                        <div *ngIf="focusedVms.length > 0" class="btn-group btn-primary btn-icon">
                            <button class="btn btn-danger btn-sm" (click)="clearFocus()">Clear focus</button>
                        </div>
                    </clr-signpost-content>
                </clr-signpost>
            </span>
            <span *ngIf="isLoading" style="margin: 0px; position: relative; z-index: 999; top:45px; right: 15px; float: right;">
                <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
            </span>
            <div id="divSvg" class="card" style="margin-top: 10px; width: 100%; height: 80%; min-height: 350px;"></div>
        </clr-tab-content>
    </clr-tab>


    <clr-tab>
        <button clrTabLink>Flows</button>
        <clr-tab-content *clrIfActive>
            <div class="clr-row">
                <div class="clr-col-12">
                    <button class="btn btn-primary btn-sm" style="float: right; margin-top: 15px; margin-bottom: 5px;" (click)="exportCSV()">Export CSV</button>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-12">
                    <clr-datagrid>
                        <clr-dg-column [clrDgField]="'src_address'">
                            Source
                            <clr-dg-string-filter [clrDgStringFilter]="sourceIpFilter"></clr-dg-string-filter>
                        </clr-dg-column>
                        <clr-dg-column [clrDgField]="'src_host_address'">
                            Source Host
                            <clr-dg-string-filter [clrDgStringFilter]="sourceHostIpFilter"></clr-dg-string-filter>
                        </clr-dg-column>
                        <clr-dg-column [clrDgField]="'dst_address'">
                            Destination
                            <clr-dg-string-filter [clrDgStringFilter]="destinationIpFilter"></clr-dg-string-filter>
                        </clr-dg-column>
                        <clr-dg-column [clrDgField]="'dst_host_address'">
                            Destination Host
                            <clr-dg-string-filter [clrDgStringFilter]="destinationHostIpFilter"></clr-dg-string-filter>
                        </clr-dg-column>
                        <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort">Total bytes</clr-dg-column>
                        <clr-dg-column [clrDgField]="'exchanged_packets'">Total (packets)</clr-dg-column>
                        <clr-dg-column>Actions</clr-dg-column>

                        <clr-dg-row *clrDgItems="let link of filteredLinks" [clrDgItem]="link">
                            <clr-dg-cell><a routerLink="/god/resource/{{link.source.short_uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{link.source.name}}</span></a></clr-dg-cell>
                            <clr-dg-cell><a routerLink="/god/resource/{{link.source.host.short_uuid}}" [queryParams]="{useResourceCountersFor: 'SERVER'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge">{{link.source.host.name}}</span></a></clr-dg-cell>

                            <clr-dg-cell><a routerLink="/god/resource/{{link.target.short_uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{link.target.name}}</span></a></clr-dg-cell>
                            <clr-dg-cell><a routerLink="/god/resource/{{link.target.host.short_uuid}}" [queryParams]="{useResourceCountersFor: 'SERVER'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge">{{link.target.host.name}}</span></a></clr-dg-cell>

                            <clr-dg-cell>{{link.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>
                            <clr-dg-cell>{{link.exchanged_packets | number}}</clr-dg-cell>

                            <ng-template #actions_if_true>
                                <a *ngIf="link.source.type === 'vm' && link.target.type === 'vm'" style="margin-right: 5px; cursor: pointer; color: #0072a3;" (click)="selectForFocus([link.source.uuid, link.target.uuid])"><clr-icon shape="recycle"></clr-icon></a>
                                <a *ngIf="link.source.type === 'vm' && link.target.type === 'vm'" style="margin-right: 5px;" routerLink="/netscope/clusters-viewer/focuson/{{link.source.uuid}},{{link.target.uuid}}" routerLinkActive="active"><clr-icon shape="organization"></clr-icon></a>
                                <a routerLink="/netscope/heatmap-viewer/{{link.source.uuid}}/{{link.target.uuid}}" routerLinkActive="active"><clr-icon shape="heat-map"></clr-icon></a>
                            </ng-template>
                            <ng-template #actions_if_not_true></ng-template>
                            <clr-dg-cell>
                                <div *ngIf="link.source !== undefined && link.target !== undefined; then actions_if_true else actions_if_not_true"></div>
                            </clr-dg-cell>

                            <app-expandable-rows-links *clrIfExpanded [flowDetail]="link" [timeResolution]="this.timeResolution"  [epochDate]="this.currentTime" ngProjectAs="clr-dg-row-detail"></app-expandable-rows-links>
                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                            </clr-dg-pagination>
                        </clr-dg-footer>

                    </clr-datagrid>
                </div>
            </div>
        </clr-tab-content>
    </clr-tab>
</clr-tabs>

export let UserCurrencies: string[] = ['8364', '36', '163', '165', '70-114', '8377', '75-269', '68'];

export enum CreateMode {
  LOCAL = "local",
  LDAP = "ldap"
}

export enum ConnectType {
    LOCAL = "local",
    LDAP = "ldap"
}

export interface User {
    login: string;
    alias: string;
    email: string;
    last_co: number;
    currency: string;
    role: string;
    token: string;
    isMenuCloud: boolean;
    isMenuKpi: boolean;
    isMenuAlert: boolean;
    isMenuTrouble: boolean;
    isMenuCapa: boolean;
    isMenuGod: boolean;
    isMenuReco: boolean;
    isMenuReport: boolean;
    isMenuGreenit: boolean;
    isMenuDCNetScope: boolean;
    isMenuVcloud: boolean;
    isMenuVirtindex: boolean;
    isMenuExternalCloud: boolean;
    isDashboardT1: boolean;
    isDashboardT2: boolean;
    isDashboardT3: boolean;
    isDashboardT4: boolean;
    isDashboardT5: boolean;
    isDashboardT6: boolean;
}

export interface Alias {
    login: string;
    user: string;
    is_local: number;
    is_ldap: number;
    is_openid: number;
}

export interface Role {
    role: string;
    isMenuCloud: boolean;
    isMenuKpi: boolean;
    isMenuAlert: boolean;
    isMenuTrouble: boolean;
    isMenuCapa: boolean;
    isMenuGod: boolean;
    isMenuReco: boolean;
    isMenuReport: boolean;
    isMenuGreenit: boolean;
    isMenuDCNetScope: boolean;
    isMenuVcloud: boolean;
    isMenuVirtindex: boolean;
    isMenuExternalCloud: boolean;
    isDashboardT1: boolean;
    isDashboardT2: boolean;
    isDashboardT3: boolean;
    isDashboardT4: boolean;
    isDashboardT5: boolean;
    isDashboardT6: boolean;
}

export interface UserRec {
    login: string;
    email: string;
    currency: string;
    role: string;
}

export interface AliasRec {
	login: string;
	password: string;
	user: string;
	is_local: number;
	is_ldap: number;
	is_openid: number;
}

export interface AliasUser {
    alias_login: string;
	user_login: string;
}
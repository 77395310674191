<!-- MODAL INSTANCES LIST -->
<clr-modal [(clrModalOpen)]="instances_list_modal" [clrModalSize]="'xl'">
    <h3 class="modal-title">Monitoring management</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;margin-bottom:50px;">
            <button class="btn btn-outline" style="position:relative;float:right;" (click)="initWizard(false,false)">
                New registration
            </button>
        </div>
        <div style="width:100%;margin-top:20px;margin-bottom:30px;">
            <b>Registered VMware instances :</b>
        </div>
        <div style="width:100%;">
            <clr-datagrid>
                <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
                <clr-dg-column>IP</clr-dg-column>
                <clr-dg-column>Login</clr-dg-column>
                <clr-dg-column>Status</clr-dg-column>
                <clr-dg-column></clr-dg-column>

                <clr-dg-row *clrDgItems="let data of findVmwareInstances()" [clrDgItem]="data">
                    <clr-dg-cell style="width:20%">{{data.name}}</clr-dg-cell>
                    <clr-dg-cell style="width:25%">
                        <fa-icon [icon]="vmware_icon"></fa-icon>
                        &nbsp;&nbsp;{{getInstanceFullname(data.type)}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{data.ip}}</clr-dg-cell>
                    <clr-dg-cell>{{data.login}}</clr-dg-cell>
                    <clr-dg-cell style="width:5%">
                        <clr-icon *ngIf="data.status == 0" shape="check" style="color:#306b00" size="24"></clr-icon>
                        <clr-icon *ngIf="data.status != 0" shape="times" style="color:#991700" size="24"></clr-icon>
                    </clr-dg-cell>
                    <clr-dg-cell style="width:5%">
                        <button style="margin:0" type='button' class='btn btn-icon btn-danger-outline' aria-label='remove' (click)="initUnregister(data.name)">
                            <clr-icon shape='trash'></clr-icon>
                        </button>
                    </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                    </clr-dg-pagination>
                </clr-dg-footer>

            </clr-datagrid>
        </div>

        <div style="width:100%;margin-top:50px;margin-bottom:30px;">
            <b>Registered Cloud Provider :</b>
        </div>
        <div class="datagrid-limit-height" style="width:100%;">
            <clr-datagrid>
                <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'type'"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'region'"><ng-container *clrDgHideableColumn="{hidden: false}">Region</ng-container></clr-dg-column>
                <clr-dg-column>Status</clr-dg-column>
                <clr-dg-column></clr-dg-column>

                <clr-dg-row *clrDgItems="let data of findCloudInstances()" [clrDgItem]="data">
                    <clr-dg-cell style="width:20%">{{data.name}}</clr-dg-cell>
                    <clr-dg-cell style="width:25%">
                        <fa-icon *ngIf="data.type == aws_type" [icon]="aws_icon"></fa-icon>
                        <fa-icon *ngIf="data.type == azure_type" [icon]="azure_icon"></fa-icon>
                        &nbsp;&nbsp;{{getInstanceFullname(data.type)}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{getRegionFullname(data.type,data.region)}}</clr-dg-cell>
                    <clr-dg-cell style="width:5%">
                        <clr-icon *ngIf="data.status == 0" shape="check" style="color:#306b00" size="24"></clr-icon>
                        <clr-icon *ngIf="data.status != 0" shape="times" style="color:#991700" size="24"></clr-icon>
                    </clr-dg-cell>
                    <clr-dg-cell style="width:5%">
                        <button style="margin:0" type='button' class='btn btn-icon btn-danger-outline' aria-label='remove' (click)="initUnregister(data.name)">
                            <clr-icon shape='trash'></clr-icon>
                        </button>
                    </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    <clr-dg-pagination #pagination2 [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                        {{pagination2.firstItem + 1}} - {{pagination2.lastItem + 1}} of {{pagination2.totalItems}} entries
                    </clr-dg-pagination>
                </clr-dg-footer>

            </clr-datagrid>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="instances_list_modal = false">Close</button>
    </div>
</clr-modal>

<!-- ADD WIZARD -->
<clr-wizard #add [(clrWizardOpen)]="register_wizard" (clrWizardOnCancel)=doCancel(false)
    (clrWizardOnFinish)=doFinish(false) [clrWizardPreventDefaultCancel]="true" [clrWizardClosable]="false">

    <clr-wizard-title>Registration</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{lang.CANCEL}}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{lang.PREVIOUS}}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{lang.NEXT}}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{lang.FINISH}}</clr-wizard-button>

    <!-- 1 -->
    <clr-wizard-page>

        <ng-template clrPageTitle>{{lang.INSTALL_TYPE_TITLE}}</ng-template>

        <div style="width:75%;margin-top:75px;margin-left:auto;margin-right:auto;">
            <div class="clr-control-container clr-control-inline" style="width:100%;">
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="type" required [value]="vmware_type" [(ngModel)]="instance_type" />
                    <label style="display:grid;margin:auto;">
                        <fa-icon [icon]="vmware_icon" style="margin:auto;font-size:4em"></fa-icon>
                        <b style="margin-top:20px;">{{getInstanceFullname(vmware_type)}}</b>
                    </label>
                </clr-radio-wrapper>
                <div style="margin-left:20%"></div>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="type" required [value]="vcloud_type" [(ngModel)]="instance_type" />
                    <label style="display:grid;margin:auto;">
                        <fa-icon [icon]="vmware_icon" style="margin:auto;font-size:4em"></fa-icon>
                        <b style="margin-top:20px;">{{getInstanceFullname(vcloud_type)}}</b>
                    </label>
                </clr-radio-wrapper>
            </div>
            <div class="clr-control-container clr-control-inline" style="width:100%;margin-top:75px;">
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="type" required [value]="aws_type" [(ngModel)]="instance_type" />
                    <label style="display:grid;margin:auto;">
                        <fa-icon [icon]="aws_icon" style="margin:auto;font-size:4em"></fa-icon>
                        <b style="margin-top:20px;">{{getInstanceFullname(aws_type)}}</b>
                    </label>
                </clr-radio-wrapper>
                <div style="margin-left:20%"></div>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="type" required [value]="azure_type" [(ngModel)]="instance_type" />
                    <label style="display:grid;margin:auto;">
                        <fa-icon [icon]="azure_icon" style="margin:auto;font-size:4em"></fa-icon>
                        <b style="margin-top:20px;">{{getInstanceFullname(azure_type)}}</b>
                    </label>
                </clr-radio-wrapper>
            </div>
        </div>
    </clr-wizard-page>

    <!-- 2 -->
    <clr-wizard-page
        [clrWizardPageNextDisabled]="!checkInstance()"
        (clrWizardPageNext)="pushInstance(false)">

        <ng-template clrPageTitle>{{lang.INSTALL_CREDENTIALS_TITLE}}</ng-template>

        <ng-template [ngTemplateOutlet]="instancenametemplate"></ng-template>

        <ng-template *ngIf="instance_type == vmware_type || instance_type == vcloud_type" [ngTemplateOutlet]="vmwaretemplate"></ng-template>
        <ng-template *ngIf="instance_type == aws_type" [ngTemplateOutlet]="awstemplate"></ng-template>
        <ng-template *ngIf="instance_type == azure_type" [ngTemplateOutlet]="azuretemplate"></ng-template>

    </clr-wizard-page>

    <!-- 3 -->
    <clr-wizard-page [clrWizardPageNextDisabled]="instance_status != 0"
        [clrWizardPagePreviousDisabled]="instance_status <= 0" [clrWizardPageHasError]="instance_status != 0">

        <ng-template clrPageTitle>{{lang.INSTALL_PROGRESS_TITLE}}</ng-template>

        <div #addstatus style="height:100%;text-align:center;margin-top:40px;"></div>

    </clr-wizard-page>

    <!-- 4 -->
    <clr-wizard-page [clrWizardPagePreviousDisabled]="true">

        <ng-template clrPageTitle>{{lang.INSTALL_END_TITLE}}</ng-template>

        <div style="height:100%;margin-top:60px;margin-left:20px">
            <p>{{lang.INSTALL_END_TEXT}}</p><br>

            <p>{{lang.INSTALL_END_TEXT2}}</p>

            <div class="alert alert-warning" role="alert"
                style="margin-top:80px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
                <span style="text-align:center;" class="alert-text">
                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                    <b>{{lang.INSTALL_END_TEXT3}}</b>
                </span>
            </div>

        </div>

    </clr-wizard-page>
</clr-wizard>

<!-- WELCOME WIZARD-->
<clr-wizard #welcome [(clrWizardOpen)]="welcome_wizard" (clrWizardOnCancel)=doCancel(true)
    (clrWizardOnFinish)=doFinish(true) [clrWizardPreventDefaultCancel]="true" [clrWizardClosable]="false">


    <clr-wizard-title>{{lang.WELCOME_LANG_TITLE}}</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{lang.CANCEL}}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{lang.PREVIOUS}}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{lang.NEXT}}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{lang.FINISH}}</clr-wizard-button>

    <!-- 1 -->
    <clr-wizard-page [clrWizardPagePreviousDisabled]="true">

        <ng-template clrPageTitle>{{lang.WELCOME_LANG_TITLE}}</ng-template>

        <div style="height:100%;margin-top:60px;margin-left:20px">
            <p>{{lang.WELCOME_LANG_TEXT}}</p>
            <br><br>

            <clr-radio-container clrInline>
                <label style="margin-bottom:10px">{{lang.WELCOME_LANG_TEXT2}} : <br><br></label>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="lang" required value="fr" [(ngModel)]="welcome_lang"
                        (click)="updateLang()" />
                    <label><img src="../../assets/img/fr.svg" height="24"></label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="lang" required value="en" [(ngModel)]="welcome_lang"
                        (click)="updateLang()" />
                    <label><img src="../../assets/img/en.svg" height="24"></label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio name="lang" required value="es" [(ngModel)]="welcome_lang"
                        (click)="updateLang()" />
                    <label><img src="../../assets/img/es.svg" height="24"></label>
                </clr-radio-wrapper>
            </clr-radio-container>

        </div>

    </clr-wizard-page>

    <!-- 2 -->
    <clr-wizard-page [clrWizardPageNextDisabled]="welcome_license_status == 'false'">

        <ng-template clrPageTitle>{{lang.WELCOME_LICENSE_TITLE}}</ng-template>

        <p>{{lang.WELCOME_LICENSE_OTHER}}</p>

        <div [innerHTML]="welcome_license_innerhtml"></div>

        <h4>7. {{lang.WELCOME_LICENSE_TEXT}}</h4>
        {{lang.WELCOME_LICENSE_TEXT2}} <br>

        <clr-radio-container clrInline>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="license" required value="true"
                    [(ngModel)]="welcome_license_status" />
                <label>{{lang.WELCOME_LICENSE_TEXT3}}</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="license" required value="false"
                    [(ngModel)]="welcome_license_status" />
                <label>{{lang.WELCOME_LICENSE_TEXT4}}</label>
            </clr-radio-wrapper>
        </clr-radio-container>

    </clr-wizard-page>

    <!-- 3 -->
    <clr-wizard-page *ngIf="isDcscope">

        <ng-template clrPageTitle>{{lang.WELCOME_MODE_TITLE}}</ng-template>

        <div style="height:100%;margin-top:100px;margin-left:15%">
            <br><br>

            <div class="clr-control-container clr-control-inline" style="width:100%;">
                <clr-radio-wrapper style="width:25%">
                    <input type="radio" clrRadio name="mode" required value="install" [(ngModel)]="welcome_mode" />
                    <label style="display:grid;margin:auto;">
                        <clr-icon shape="wrench" size="128"></clr-icon>
                        <b style="margin-top:20px;text-align:center;">{{lang.WELCOME_MODE_TEXT}}</b>
                    </label>
                </clr-radio-wrapper>
                <div style="margin-left:20%"></div>
                <clr-radio-wrapper style="width:25%">
                    <input type="radio" clrRadio name="mode" required value="migrate" [(ngModel)]="welcome_mode" />
                    <label style="display:grid;margin:auto;">
                        <clr-icon shape="storage" size="128"></clr-icon>
                        <b style="margin-top:20px;text-align:center;">{{lang.WELCOME_MODE_TEXT2}}</b>
                    </label>
                </clr-radio-wrapper>
            </div>

        </div>

    </clr-wizard-page>

    <!-- 4 : INSTALL CREDENTIALS -->
    <clr-wizard-page *ngIf="welcome_mode == install_mode"
        [clrWizardPageNextDisabled]="!checkInstance()"
        (clrWizardPageNext)="pushInstance(true)">

        <ng-template clrPageTitle>{{lang.WELCOME_CREDENTIALS_TITLE}}</ng-template>

        <ng-template [ngTemplateOutlet]="instancenametemplate"></ng-template>

        <ng-template [ngTemplateOutlet]="vmwaretemplate"></ng-template>

    </clr-wizard-page>

    <!-- 4 : MIGRATE CREDENTIALS -->
    <clr-wizard-page *ngIf="welcome_mode == migrate_mode"
        [clrWizardPageNextDisabled]="migrate_connect == false"
        [clrWizardPagePreviousDisabled]="migrate_status != undefined" (clrWizardPageNext)="pushMigrate()">

        <ng-template clrPageTitle>{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT}}</ng-template>

        <div class="alert alert-info" role="alert"
            style="text-align:justify;font-size:13px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
            <ul style="list-style:none;">
                <li style="margin-bottom:10px">{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT8}}</li>
                <li style="margin-bottom:20px">{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT9}}</li>
                <li><b>{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT10}}</b></li>
            </ul>
        </div>

        <div  style="width:100%">
            <div style="width:75%">
                <div class="clr-form-control" style="flex-direction:row;margin-top:50px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT3}} :</label>
                    <div [ngClass]="(migrate_ip == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [disabled]="migrate_connect" [(ngModel)]="migrate_ip" style="width:20em"
                                (keyup)="removeSpaces($event.target);migrate_connect= false;">
                            <clr-icon class="clr-validate-icon" *ngIf="migrate_ip == ''" shape="exclamation-circle">
                            </clr-icon>
                        </div>
                    </div>
                </div>

                <div *ngIf="migrate_pwd_show"  class="clr-form-control" style="flex-direction:row;margin-top:50px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT6}} ('root' OS) :</label>
                    <div [ngClass]="(migrate_pwd == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <clr-password-container style="margin-top:0px">
                            <input clrPassword [disabled]="migrate_connect" [(ngModel)]="migrate_pwd" style="width:20em"
                                (keyup)="migrate_connect= false">
                        </clr-password-container>
                    </div>
                </div>
            </div>
            <div  style="width:25%;float:right">
                <button *ngIf="(migrate_pwd != '' || migrate_ip != '')" [disabled]="migrate_connect" [clrLoading]="migrate_connect_button_state" type="button"
                    [ngClass]="(!migrate_connect) ? 'btn btn-danger-outline btn-sm' : 'btn btn-success-outline btn-sm'" (click)="testMigrate()">
                    <span style="min-width:178px" *ngIf="!migrate_connect">{{migrate_connect_button_text}}</span>
                    <clr-icon style="min-width:152px" *ngIf="migrate_connect" shape="check"></clr-icon>
                </button>
            </div>
        </div>

        <div  style="width:80%">
            <clr-select-container style="flex-direction:row;margin-top:50px">
                <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT3}} :</label>
                <select clrSelect [(ngModel)]="migrate_retention">
                    <option value="1">1 {{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT4}}</option>
                    <option value="2">2 {{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT4}}s</option>
                    <option value="0">{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT5}}</option>
                </select>
                <clr-control-helper>{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT6}}</clr-control-helper>
            </clr-select-container>
        </div>

        <div *ngIf="migrate_retention > 0" class="alert alert-warning" role="alert"
            style="margin-top:40px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
            <span style="text-align:center;" class="alert-text">
                <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                <b>{{lang.WELCOME_CREDENTIALS_MIGRATE_TEXT7}}</b>
            </span>
        </div>

    </clr-wizard-page>

    <!-- 5 : INSTALL PROGRESS -->
    <clr-wizard-page *ngIf="welcome_mode == install_mode" [clrWizardPageNextDisabled]=" instance_status !=0"
        [clrWizardPagePreviousDisabled]="instance_status <= 0" [clrWizardPageHasError]="instance_status != 0">

        <ng-template clrPageTitle>{{lang.INSTALL_PROGRESS_TITLE}}</ng-template>

        <div #welcomestatus style="height:100%;text-align:center;margin-top:40px;"></div>

    </clr-wizard-page>

    <!-- 5 : MIGRATE PROGRESS -->
    <clr-wizard-page #migrationpage *ngIf="welcome_mode == migrate_mode"
        [clrWizardPageNextDisabled]="migrate_status != 0" [clrWizardPagePreviousDisabled]="migrate_status <= 0"
        [clrWizardPageHasError]="migrate_status != 0">

        <ng-template clrPageTitle>{{lang.WELCOME_PROGRESS_MIGRATE_TEXT}}</ng-template>

        <div #welcomestatus style="height:100%;text-align:center;margin-top:100px;">
            <div [ngClass]="migrate_progress_class">
                <div class="progress-meter" [attr.data-value]="migrate_progress_value"
                    [attr.data-displayval]="migrate_progress_value + '%'"></div>
                <span>{{migrate_progress_value}}%</span>
            </div>
            <div style="margin-top:10%;">
                <b><span>{{migrate_progress_message}}</span></b>
            </div>
        </div>

    </clr-wizard-page>

    <!-- 6 -->
    <clr-wizard-page [clrWizardPagePreviousDisabled]="true">

        <ng-template clrPageTitle>{{lang.WELCOME_END_TITLE}}</ng-template>

        <div style="height:100%;margin-top:40px;margin-left:20px">
            {{lang.WELCOME_END_TEXT}}<br><br>

            {{lang.WELCOME_END_TEXT2}}

            <div class="alert alert-info" role="alert"
                style="margin-top:30px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
                <span style="text-align:center;" class="alert-text">
                    <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                    <b>{{lang.WELCOME_END_TEXT3}}</b>
                </span>
            </div>

            <br><br>{{lang.WELCOME_END_TEXT4}}<a href="mailto:licence@easyvirt.com">licence@easyvirt.com</a>

            <div class="alert alert-warning" role="alert"
                style="margin-top:80px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;">
                <span style="text-align:center;" class="alert-text">
                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                    <b>{{lang.WELCOME_END_TEXT5}}</b>
                </span>
            </div>
        </div>

    </clr-wizard-page>

</clr-wizard>

<!-- MODAL INFO -->
<clr-modal [(clrModalOpen)]="info_modal" [clrModalClosable]=false [clrModalSize]="info_modal_size">
    <h3 class="modal-title">{{info_header}}</h3>
    <div class="modal-body" style="min-height:100px;">
        <div #info style="width:100%;overflow:auto;"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="info_modal = false">Ok</button>
    </div>
</clr-modal>

<!-- MODAL UNREGISTER CONFIRM -->
<clr-modal [(clrModalOpen)]="unregister_modal" [clrModalClosable]=false>
    <h3 class="modal-title">Unregistration</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>The instance <b>{{unregister_instance}}</b> will be permanently unregistered !</p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="unregister_modal = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="unregisterInstance()">Unregister</button>
    </div>
</clr-modal>

<!-- INSTANCE NAME INPUT -->
<ng-template #instancenametemplate>
    <div class="clr-form-control" style="flex-direction:row;margin-top:25px">
        <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT}} :</label>
        <div [ngClass]="(instance_name == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
            <div class="clr-input-wrapper">
                <input class="clr-input" type="text" [(ngModel)]="instance_name" style="width:20em"
                    (keyup)="checkName($event.target);" [disabled]="instance_name_disable">
                <clr-icon class="clr-validate-icon" *ngIf="instance_name == ''" shape="exclamation-circle">
                </clr-icon>
            </div>
            <clr-control-helper>{{lang.INSTALL_CREDENTIALS_TEXT2}}</clr-control-helper>
            <clr-control-helper *ngIf="instance_type == azure_type">{{lang.INSTALL_CREDENTIALS_TEXT18}}</clr-control-helper>
        </div>
    </div>
</ng-template>

<!-- VMWARE / VCLOUD -->
<ng-template #vmwaretemplate >
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">

                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT3}} :</label>
                    <div [ngClass]="(instance_vmware_vloud.ip == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_vmware_vloud.ip" style="width:20em"
                                (keyup)="removeSpaces($event.target)">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_vmware_vloud.ip == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:50px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT4}} :</label>
                    <div [ngClass]="(instance_vmware_vloud.login == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_vmware_vloud.login" style="width:20em"
                                (keyup)="removeSpaces($event.target)">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_vmware_vloud.login == ''" shape="exclamation-circle">
                            </clr-icon>
                            <clr-control-helper> {{lang.INSTALL_CREDENTIALS_TEXT5}} </clr-control-helper>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT6}} :</label>
                    <div [ngClass]="(instance_vmware_vloud.pwd == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <clr-password-container style="margin-top:0px">
                            <input clrPassword name="password" style="width:18em" [(ngModel)]="instance_vmware_vloud.pwd" />
                        </clr-password-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>

<!-- AWS -->
<ng-template #awstemplate >
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">
                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT7}} :</label>
                    <clr-select-container style="flex-direction:row;margin:0">
                        <select clrSelect [(ngModel)]="instance_aws.region" >
                            <option *ngFor="let region of aws_regions" [ngValue]="region.value">{{region.display}}</option>
                        </select>
                    </clr-select-container>
                </div>
                <div class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT8}} :</label>
                    <div [ngClass]="(instance_aws.accesskey == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.accesskey" style="width:20em">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_aws.accesskey == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>
                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT9}} :</label>
                    <div [ngClass]="(instance_aws.secretaccesskey == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.secretaccesskey" style="width:20em">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_aws.secretaccesskey == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-block">
            <div class="card-text">
                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT10}} :</label>
                    <clr-select-container style="flex-direction:row;margin:0">
                        <select clrSelect [(ngModel)]="instance_aws.isorg" >
                            <option value=false>false</option>
                            <option value=true>true</option>
                        </select>
                    </clr-select-container>
                </div>
                <div *ngIf="instance_aws.isorg == 'true'" class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT11}} :</label>
                    <div [ngClass]="(instance_aws.orgname == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.orgname" style="width:20em">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_aws.orgname == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="instance_aws.isorg == 'true'" class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT12}} :</label>
                    <div [ngClass]="(instance_aws.orgaccesskey == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.orgaccesskey" style="width:20em">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_aws.orgaccesskey == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="instance_aws.isorg == 'true'" class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT13}} :</label>
                    <div [ngClass]="(instance_aws.orgsecretaccesskey == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_aws.orgsecretaccesskey" style="width:20em">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_aws.orgsecretaccesskey == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- AZURE -->
<ng-template #azuretemplate >
    <div class="card" style="margin-top:50px">
        <div class="card-block">
            <div class="card-text">

                <div class="clr-form-control" style="flex-direction:row;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT7}} :</label>
                    <clr-select-container style="flex-direction:row;margin:0">
                        <select clrSelect [(ngModel)]="instance_azure.region" >
                            <option *ngFor="let region of azure_regions" [ngValue]="region.value">{{region.display}}</option>
                        </select>
                    </clr-select-container>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT14}} :</label>
                    <div [ngClass]="(instance_azure.tenantid == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_azure.tenantid" style="width:20em">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_azure.tenantid == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT15}} :</label>
                    <div [ngClass]="(instance_azure.clientid == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_azure.clientid" style="width:20em">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_azure.clientid == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT16}} :</label>
                    <div [ngClass]="(instance_azure.clientsecret == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_azure.clientsecret" style="width:20em">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_azure.clientsecret == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>

                <div class="clr-form-control" style="flex-direction:row;margin-top:70px;margin-bottom:20px;">
                    <label class="clr-control-label clr-col-12 clr-col-md-2">{{lang.INSTALL_CREDENTIALS_TEXT17}} :</label>
                    <div [ngClass]="(instance_azure.subscriptionid == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                        <div class="clr-input-wrapper">
                            <input class="clr-input" type="text" [(ngModel)]="instance_azure.subscriptionid" style="width:20em">
                            <clr-icon class="clr-validate-icon" *ngIf="instance_azure.subscriptionid == ''" shape="exclamation-circle"></clr-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
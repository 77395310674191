import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ReportEmail, ReportHistory, ReportSettings } from '../model/report';
import { ReportFormat } from '@app/report/report.enums';
import { ReportType } from '@app/report/reportcard/reportcard.component';
import { PowershellResource } from '../model/recommendation';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  
  /**
   * report_settings
   */
  getReportSettings(user: string, uuid:string) {
    return this.http.get<ReportSettings>(`${environment.apiUrl}/` + 'report/getreportsettings/' + user + '&' + uuid).pipe(map(settings => {
      return settings;
    }));
  }

  saveReportSettings(settings: ReportSettings) {
    return this.http.post<ReportSettings>(`${environment.apiUrl}/` + 'report/savereportsettings/', JSON.parse(JSON.stringify(settings)));
      
  }

  /**
   * history
   */
  getReportHistory(user: string) {
    return this.http.get<ReportHistory[]>(`${environment.apiUrl}/` + 'report/getreporthistory/' + user).pipe(map(history => {
      return history;
    }));
  }

  saveReportHistory(history: ReportHistory) {
    return this.http.post<ReportHistory>(`${environment.apiUrl}/` + 'report/savereporthistory/', JSON.parse(JSON.stringify(history)));
      
  }

  /**
   * emails
   */
  getReportEmail(uuid: string) {
    return this.http.get<ReportEmail[]>(`${environment.apiUrl}/` + 'report/getreportemails/' + uuid).pipe(map(history => {
      return history;
    }));
  }

  saveReportEmail(emails: ReportEmail[]) {
    return this.http.post<ReportEmail[]>(`${environment.apiUrl}/` + 'report/savereportemails/', JSON.parse(JSON.stringify(emails)));
      
  }

  /**
   * Report
   */
  getReport(user: string, uuid: string, format: ReportFormat) {
    return this.http.get(`${environment.apiUrl}/` + 'report/getreport/'+ user + '&' + uuid + '&' + format, { responseType: 'blob'});
  }

  deleteReport(user: string, uuid: string) {
    return this.http.put<Object>(`${environment.apiUrl}/` + 'report/deletereport/', [user,uuid]);
  }

  generateReport(user: string, uuid: string, type: ReportType) {
    return this.http.post(`${environment.apiUrl}/` + 'report/generatereport/', [user, uuid, type]);
  }

  /**
   * Powershell
   */
  getPowershell(user: string, filter: string, resource: PowershellResource, list: string,) {
    return this.http.post(`${environment.apiUrl}/` + 'report/generatepowershell/', [user, filter, resource, list], { responseType: 'blob'});
  }


}

import { Component, OnInit, Renderer2 } from '@angular/core';
import { AccountService, JsonloaderService, CapaplanService, ShareService, SettingsService } from '@app/services';
import { User, Message, IhmSettings, IhmSettingsTarget, JSONTarget } from '@app/model';
import { CapaEntity, CapaResource, HALevel } from './capaplan.enums';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-capaplan',
  templateUrl: './capaplan.component.html',
  styleUrls: ['./capaplan.component.css']
})
export class CapaplanComponent implements OnInit {

  infraSubject: Subject<void> = new Subject<void>();
  simuSubject: Subject<void> = new Subject<void>();
  configSubject: Subject<void> = new Subject<void>();

  message: Message;
  currentUser: User;

  //FOR SETTINGS
  capa_settings: IhmSettings;

  // FOR DATA
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
 
  capaplan_json: any;
  capaplan_simulation_json: any;
  capaplan_simulation_config_json: any;
  
  capaplan_lastdate_format : string;

  capaplan_simulation_option: number;
  
  capaplan_haslider_level : HALevel;
  capaplan_haslider_label: string;
  capaplan_haslider_label_totalvm : number;
  ha_highest: number;
  ha_high: number;
  ha_medium: number;
  ha_low: number;
  ha_lowest: number;
  ha_other: number;
  
  cap: CapaResource;
  cons: CapaResource;

  // FOR SIMU
  host: CapaEntity;
  vm: CapaEntity;
  ds: CapaEntity;

  simulation_id_suffix: string = "-ROW";
  simulation_quantity_suffix = "-QUANTITY";
  simulation_input_suffix = "-INPUT";
  simulation_value_separator = "#";

  // Info
  info_modal: boolean;
  info_header: string;
  info_body: string;

  // Progress
  info_progress: boolean;


  /**
   *  
   */
  constructor(private authenticationService: AccountService, private message_svc: ShareService,
     private capaplan_svc: CapaplanService, private settings_svc: SettingsService,
     private renderer: Renderer2, private json_svc: JsonloaderService) {

    this.cap = CapaResource.CAP;
    this.cons = CapaResource.CONS;
    this.host = CapaEntity.HOST;
    this.vm = CapaEntity.VM;
    this.ds = CapaEntity.DS;

    this.capaplan_lastdate_format = "";

    // Default values
    this.capaplan_simulation_option = 0;
    this.capaplan_haslider_level = HALevel.OTHERS;

    this.capaplan_haslider_label = HALevel[this.capaplan_haslider_level].toLowerCase();
    this.capaplan_haslider_label_totalvm = 0;
    this.ha_highest = 0;
    this.ha_high = 0;
    this.ha_medium = 0;
    this.ha_low = 0;
    this.ha_lowest = 0;
    this.ha_other = 0;

   }

  /**
   * 
   */
  ngOnInit(): void {    
    this.authenticationService.user.subscribe(user => this.currentUser = user);
    this.message_svc.currentMessage.subscribe(message => this.message = message);
    
    // Reload capacity settings
    this.settings_svc.reload(this.currentUser.login, this.message.currentFilter, IhmSettingsTarget.CAPACITY, true);

    // Update capacity data
    this.settings_svc.currentCapa.subscribe(
      capaSettings => {
        this.capa_settings = capaSettings;
        this.capaplan_simulation_option = Number(this.capa_settings.p19);
        this.hasliderChange(Number(this.capa_settings.p4));      
      }
    );

    // GET DATA
    this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.CAPA_INFRA).subscribe(
      data => {
        this.capaplan_json = data;
        //Notify child
        this.emitInfra();

        if(this.capaplan_json != null) {
          // Init data date
          let lasttime = Number(this.capaplan_json.CONS.CONSLASTMODELDATE);
          if(lasttime != undefined) {
            let lastdate = new Date(lasttime);
            this.capaplan_lastdate_format = lastdate.getDate() + ' ' + this.monthNames[lastdate.getMonth()] + ' ' + lastdate.getFullYear();
          }

          // Init HA VM number
          this.ha_highest = Number(this.capaplan_json.HA.HA_HIGHEST);
          this.ha_high = Number(this.capaplan_json.HA.HA_HIGH);
          this.ha_medium = Number(this.capaplan_json.HA.HA_MEDIUM);
          this.ha_low = Number(this.capaplan_json.HA.HA_LOW);
          this.ha_lowest = Number(this.capaplan_json.HA.HA_LOWEST);
          this.ha_other = Number(this.capaplan_json.HA.HA_OTHER);

          // Update slider
          this.hasliderChange(Number(this.capa_settings.p4));
        }
      }
    );
   
    // GET SIMU DATA
    this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.CAPA_SIMU).subscribe(
      data => {
        this.capaplan_simulation_json = data;
        //Notify child
        this.emitSimu();
      }
    );

    // GET SIMULATION
    this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.CAPA_SIMU_CONFIG).subscribe(
      data => {
        this.capaplan_simulation_config_json = data;
        //Notify child
        this.emitConfig();
      }
    );

  }

  emitInfra() {
    this.infraSubject.next(this.capaplan_json);
  }

  emitSimu() {
    this.simuSubject.next(this.capaplan_simulation_json);
  }

  emitConfig() {
    this.configSubject.next(this.capaplan_simulation_config_json);
  }

  /**
   * 
   */
  hasliderChange(halevel_number: number): void {

    // Update haslider label
    let keys = Object.keys(HALevel).filter(x => HALevel[x] == halevel_number);
    this.capaplan_haslider_level = HALevel[keys[0]];

    let vm: number = 0;

    switch(this.capaplan_haslider_level) {
      case HALevel.HIGHEST:
        this.capaplan_haslider_label_totalvm = this.ha_highest;
        vm = this.ha_highest;
      break;
      case HALevel.HIGH:
        this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high;
        vm = this.ha_high;
      break;
      case HALevel.MEDIUM:
        this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high + this.ha_medium;
        vm = this.ha_medium;
      break;
      case HALevel.LOW:
        this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high + this.ha_medium + this.ha_low;
        vm = this.ha_low;
      break;
      case HALevel.LOWEST:
        this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high + this.ha_medium + this.ha_low + this.ha_lowest;
        vm = this.ha_lowest;
      break;
      case HALevel.OTHERS:
        this.capaplan_haslider_label_totalvm = this.ha_highest + this.ha_high + this.ha_medium + this.ha_low + this.ha_lowest + this.ha_other;
        vm = this.ha_other;
      break;
    }

    this.capaplan_haslider_label = keys[0].toLowerCase() + ' (' + vm + ' VM)';
   
  }

  /**
   * 
   */
  runSimulation(): void {

    if(this.capaplan_haslider_label_totalvm > 0) {

      // Show waiting
      this.message.waiting = true;

      // Save simu conf
      this.saveSimulation();

      // Save settings
      this.capa_settings.p4 = this.capaplan_haslider_level.toString();
      this.capa_settings.p19 = this.capaplan_simulation_option.toString();
      this.settings_svc.saveIhmSettings(this.capa_settings);


        // TODO progress bar ?
      this.capaplan_svc.runSimulation(this.currentUser.login, this.message.currentFilter).subscribe(
        success => {
          
          // GET SIMU DATA
          this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.CAPA_SIMU).subscribe(
            data => {
              this.capaplan_simulation_json = data;
              
              //Notify child
              this.emitSimu();

              this.message.waiting = false;
            }
          );
          
        },
        error => {
          this.message.waiting = false;
          this.info_header = "Sorry ... ";
          this.info_body = "An error has occurred during simulation !";
          this.displayInfo();
        }
      );

    } else {
      // Info modal
      this.info_header = "Sorry ... ";
      this.info_body = "Can not run simulation : No VM for HA option : " + this.capaplan_haslider_label;
      this.displayInfo();
    }

  }

  /**
   * Save simulation into a JSON file
   */
  saveSimulation(): void {
    let vms: HTMLCollectionOf<HTMLTableRowElement> = document.getElementById("capa-simul-vm").getElementsByTagName("tr");
    let servers: HTMLCollectionOf<HTMLTableRowElement> = document.getElementById("capa-simul-host").getElementsByTagName("tr");
    let datastores: HTMLCollectionOf<HTMLTableRowElement> = document.getElementById("capa-simul-ds").getElementsByTagName("tr");
    
    let json = {
      vm : [],
      server: [],
      datastore: []
    };

    // ADD VM
    for (let i = 1; i < vms.length; i++) {
      let vm = vms[i].dataset.id;
      let action = vms[i].dataset.action;
      let value = vms[i].dataset.value;
      let dataVM = value.split(this.simulation_value_separator);
          
      // Looking for QUANTITY
      let quantity: number = 1;
      let input: HTMLInputElement;
      let query = '[data-id~="' + vm.replace(this.simulation_id_suffix, this.simulation_quantity_suffix) + '"]';
      let list: NodeList = vms[i].querySelectorAll(query);
      if (list.length > 0) {
        input = list[0] as HTMLInputElement;
        quantity = Number(input.value);
      }
          
      let element: any = {};
      element.ACTION = action;
      element.ID = dataVM[0];
      element.NAME = dataVM[1];
      element.RAMCAP = Number(dataVM[2]);
      element.CPUTHREADNB = Number(dataVM[3]);
      element.DISKCAP = Number(dataVM[4]);
      element.RAMCONS = Number(dataVM[5]);
      element.CPUCONSMHZ = Number(dataVM[6]);
      element.QUANTITY = quantity;
          
      json.vm.push(element);
    }

    // ADD SRV
    for (let i = 1; i < servers.length; i++) {
      let srv = servers[i].dataset.id;
      let action = servers[i].dataset.action;
      let value = servers[i].dataset.value;
      let dataSRV = value.split(this.simulation_value_separator);
          
      // Looking for QUANTITY
      let quantity: number = 1;
      let input: HTMLInputElement;
      let query = '[data-id~="' + srv.replace(this.simulation_id_suffix, this.simulation_quantity_suffix) + '"]';
      let list: NodeList = servers[i].querySelectorAll(query);
      if (list.length > 0) {
        input = list[0] as HTMLInputElement;
        quantity = Number(input.value);
      }
         
      let element: any = {};
      element.ACTION = action;
      element.ID = dataSRV[0];
      element.NAME = dataSRV[1];
      element.RAMCAP = Number(dataSRV[2]);
      element.CPUTHREADNB = Number(dataSRV[3]);
      element.RAMCONS = 0;
      element.CPUCONSMHZ = 0;
      element.QUANTITY = quantity;
          
      json.server.push(element);
    }
   

    // ADD DS
    for (let i = 1; i < datastores.length; i++) {
      let ds = datastores[i].dataset.id;
      let action = datastores[i].dataset.action;
      let value = datastores[i].dataset.value;
      let dataDS = value.split(this.simulation_value_separator);
         
      // Looking for QUANTITY
      let quantity: number = 1;
      let input: HTMLInputElement;
      let query = '[data-id~="' + ds.replace(this.simulation_id_suffix, this.simulation_quantity_suffix) + '"]';
      let list: NodeList = datastores[i].querySelectorAll(query);
      if (list.length > 0) {
        input = list[0] as HTMLInputElement;
        quantity = Number(input.value);
      }

      let element: any = {};
      element.ACTION = action;
      element.ID = dataDS[0];
      element.NAME = dataDS[1];
      element.DISKCAP = Number(dataDS[2]);
      element.QUANTITY = quantity;
          
      json.datastore.push(element);
    }

    // SEND JSON TO SERVER
    this.capaplan_svc.saveCapaSimulationFile(this.currentUser.login, this.message.currentFilter, JSON.parse(JSON.stringify(json))).subscribe(
      success => { },
      error => {
        if(error != null)
          console.log("ERROR " + error);
      }
    );
  
  }


  /**
   *
   */
  generateReport(): void {
    // Show waiting
    this.message.waiting = true;

    // Generate & get report
    this.capaplan_svc.generateReport(this.currentUser.login, this.message.currentFilter).subscribe(
      res => {

        // Hide waiting
        this.message.waiting = false;

        //window.open(window.URL.createObjectURL(res), '_blank');
      
        let file_type = "application/pdf";
          
        // Create temp link
        let blob: Blob = new Blob([res], { type: file_type });
        let fileName = 'CapaPlan.pdf';
        let objectUrl: string = URL.createObjectURL(blob);
      
        let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      },
      error => {
        // Hide waiting
        this.message.waiting = false;

        this.info_header = "Sorry ...";
        this.info_body = "An error has occurred during report generation !";
        this.displayInfo();

      }
    );

  }

  /**
   * 
   */
  private displayInfo() {
    this.info_modal = true;
  }

}


import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import { JsonloaderService, ShareService } from '@app/services';

import { Json, Message } from '@app/model';


@Component({
  selector: 'app-rpinfo',
  templateUrl: './rpinfo.component.html',
  styleUrls: ['./rpinfo.component.css']
})
export class RpinfoComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject();

  jsonLoader: Json;

  message: Message;

  data_rp: any = [];

  math = Math;

  /**
   * 
   */
  constructor(private router: Router,private json_svc: JsonloaderService,
	private message_svc: ShareService) { }

  /**
   * 
   */
  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();
	$( "dt-buttons" ).remove();

	// WARN Need to init here ...
	this.dtOptions = {
		dom: 'Bfrtlip',
		pagingType: 'full_numbers',
	  	pageLength: 10,
		lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
		processing: true,
	  	destroy: true,
	  	language: {
	  		search: '',
	  		searchPlaceholder: " search..."
	  	},
		buttons: [
        	{ extend: 'csv', className: 'btn btn-link btn-sm', title: 'Resource pool list' },
        	{ extend: 'copy', className: 'btn btn-link btn-sm', title: 'Resource pool list' },
        	{ extend: 'print', className: 'btn btn-link btn-sm', title: 'Resource pool list' }
      	],
		initComplete: function() {
			// Update datatable positions
			$('div.dataTables_filter').appendTo('span.search');
			$('div.dt-buttons').appendTo('span.export');
	  		$("[id*='DataTables_Table_']").css({'padding-top':'5px'});
	  		$("[name*='DataTables_Table_']").css({'padding':'0px'});
	  	}
	};

	this.json_svc.currentJson.subscribe(
		json => {
			this.jsonLoader = json;
			this.data_rp = this.jsonLoader.rpSynthesis;
			if(this.data_rp != null && this.data_rp.length > 0)
				this.reloadTable();
		}
	);

	this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  /**
   * 
   */
  ngAfterViewInit(): void {
	this.dtTrigger.next();
  }

  /**
   * 
   */
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  /**
   * 
   */
  callroute(uuid, name): void {
	this.message.currentUuid = uuid;
	this.message.currentName = name;
	this.message.currentType = 'RESPOOL';
	this.router.navigate(['/rpviews/synthesis']);
  }

  /**
   * 
   */
  private reloadTable(): void {
	if(this.dtElement != undefined && this.dtElement.dtInstance != undefined) {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			$( "div.dataTables_filter" ).remove();
	  		$( "dt-buttons" ).remove();
            dtInstance.destroy();
            this.dtTrigger.next();
        });
  	}
  }

}

import { Component, OnInit, Output, Input, ViewChild, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { ClrCombobox } from "@clr/angular";
import { GreenitService, ShareService } from '@app/services';
import { tap } from 'rxjs/internal/operators';
import { Message } from '@app/model';


@Component({
  selector: 'app-power-combobox',
  templateUrl: './power-combobox.component.html',
  styleUrls: ['./power-combobox.component.css']
})
export class PowerComboboxComponent implements OnInit {

  message: Message;

  loadingResources: boolean;

  items: Observable<any>;
  selectedElementsArray: String[] = [];
  
  combobox_showList: boolean = false;
  combobox_active: boolean = false;

  skipEventBeforeThisTimestamp;

  @ViewChild('combobox') combobox: ClrCombobox<string>;

  @Input()
  get selectedElements() {
    return this.selectedElementsArray;
  }

  @Input()
  targetInfrastructure: string = 'vmware';
  getTargetInfrastructure() {
    return this.targetInfrastructure;
  }

  @Output() selectedElementsChange = new EventEmitter();
  set selectedElements(element) {

    if (element == undefined) {
      element = [];
    }

    this.selectedElementsArray = element;
    this.selectedElementsChange.emit(this.selectedElementsArray);
    this.combobox_showList = false;

    // Execute this block 100ms later to give the time of the event to propagate
    setTimeout(() => {
      if (this.combobox != undefined) {
        this.combobox.searchText = "";
        this.combobox.focused = false;
        this.combobox.textbox.nativeElement.blur();
      }
    }, 100);
  }

  @Output()
  onChange: EventEmitter<any> = new EventEmitter();


  /**
   * 
   */
  constructor(
    private greenit_svc: GreenitService,
    private message_svc: ShareService) {
    this.loadingResources = false;
  }

  /**
   * 
   */
  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  clickComboBox = (childElement, combo: any, event) => {
    if (event.target !== undefined && event.target.currentShapeAttrVal === "close") {
      return
    }
    this.sendArrowDown();
  }

  sendArrowDown(): void {
    const event = new KeyboardEvent('keydown', { key: "ArrowDown" });
    this.combobox_showList = true;
    this.combobox.textbox.nativeElement.dispatchEvent(event, "plop");
  }

  _focus = () => {
    this.generateList('');
  }

  _clrInputChange = (event: string) => {
    if (this.combobox_active) {
      return this.generateList(event);
    } else {
      return null;
    }
  }

  _clrOpenChange = (event) => {

    let now = new Date().getTime();

    if (event && this.skipEventBeforeThisTimestamp !== undefined && now < this.skipEventBeforeThisTimestamp) {
      console.log(`I ignore openChange events as ${this.skipEventBeforeThisTimestamp} > now (${now})`);
      this.combobox_showList = false;
      return this._clrOpenChange(false);
    }
    this.combobox_active = event;

    let result = null;
    if (this.combobox_active) {
      this.clickComboBox(undefined, this, event);
      result = this.generateList('')
    } else {
      this.skipEventBeforeThisTimestamp = new Date().getTime() + 0.5 * 1000;
      console.log(`I set comboboxes to ignore openChange events before ${this.skipEventBeforeThisTimestamp}`);
    }
    return result;
  }

  _click = (event) => {
    this.clickComboBox(undefined, this, event);
  }

  _clrSelectionChange = (event: any) => {
    this.onChange.emit(event);
  }

  generateList(pattern: string): void {

    this.loadingResources = true;
    if(this.targetInfrastructure == 'vmware') {
      this.items = this.greenit_svc.getElementsListObservable(pattern).pipe(tap(() => {
        this.loadingResources = false;
        this.sendArrowDown();
      }));
    } else {
      //console.log(this.targetInfrastructure + " :: " + this.message.cloudAccount);
      this.items = this.greenit_svc.getRegionElementsListObservable(pattern, this.targetInfrastructure, this.message.cloudAccount).pipe(tap(() => {
        this.loadingResources = false;
        this.sendArrowDown();
      }));
    }
    
  }

}

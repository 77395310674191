import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import { JSONTarget, Message, User } from '@app/model';

import { KpiactivityComponent } from '@app/kpi/kpiactivity/kpiactivity.component';
import { KpiactivityDirective } from '@app/directives/kpiactivity.directive';

import { KpicapacityComponent } from '@app/kpi/kpicapacity/kpicapacity.component';
import { KpicapacityDirective } from '@app/directives/kpicapacity.directive';

import { KpicostComponent } from '@app/kpi/kpicost/kpicost.component';
import { KpicostDirective } from '@app/directives/kpicost.directive';

import { KpihealthComponent } from '@app/kpi/kpihealth/kpihealth.component';
import { KpihealthDirective } from '@app/directives/kpihealth.directive';

import { commaBigNumber, getUserCurrency } from '../../assets/js/tools.js';


@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.css']
})
export class KpiComponent implements OnInit {

  @ViewChild(KpiactivityDirective) addActivity: KpiactivityDirective;
  @ViewChild(KpicapacityDirective) addCapacity: KpicapacityDirective;
  @ViewChild(KpicostDirective) addCost: KpicostDirective;
  @ViewChild(KpihealthDirective) addHealth: KpihealthDirective;

  message: Message;

  isActivity: boolean = false;
  isCapacity: boolean = false;
  isCost: boolean = false;
  isHealth: boolean = false;

  kpi_meta: any = {};

  consumption_cpu_class: string = 'progress top success';
  consumption_ram_class: string = 'progress top success';
  consumption_disk_class: string = 'progress top success';

  activity_url: string = 'assets/img/evolution.png';
  cost_url: string = 'assets/img/euro.png';
  capacity_url: string = 'assets/img/capa.png';
  health_url: string = 'assets/img/defect.png';

  globalCurrency: string = '';

  math = Math;

  currentUser: User;


  constructor(
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	public componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.globalCurrency = getUserCurrency(this.currentUser.currency);

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KPI_META).subscribe(
		data => {
			this.kpi_meta = data;
			// MANAGE CSS CLASS
			if(this.kpi_meta.avg_cpu_cons > 50 && this.kpi_meta.avg_cpu_cons < 81)
  				this.consumption_cpu_class = 'progress top warning';
			else if(this.kpi_meta.avg_cpu_cons > 80)
  				this.consumption_cpu_class = 'progress top danger';

			if(this.kpi_meta.avg_ram_cons > 50 && this.kpi_meta.avg_ram_cons < 81)
  				this.consumption_ram_class = 'progress top warning';
			else if(this.kpi_meta.avg_ram_cons > 80)
  				this.consumption_ram_class = 'progress top danger';

			if(this.kpi_meta.avg_disk_cons > 50 && this.kpi_meta.avg_disk_cons < 81)
  				this.consumption_disk_class = 'progress top warning';
			else if(this.kpi_meta.avg_disk_cons > 80)
  				this.consumption_disk_class = 'progress top danger';
      		}
	);
  }

  callKpiDetail(kpi: string): void {

	this.isActivity = false;
	this.isCapacity = false;
	this.isCost = false;
	this.isHealth = false;

	switch(kpi) {
		case "activity":
			this.isActivity = true;
			setTimeout(() => this.loadActivity(), 100);
			break;
		case "capacity":
			this.isCapacity = true;
			setTimeout(() => this.loadCapacity(), 100);
			break;
		case "cost":
			this.isCost = true;
			setTimeout(() => this.loadCost(), 100);
			break;
		case "health":
			this.isHealth = true;
			setTimeout(() => this.loadHealth(), 100);
			break;
		default:
			break;
	}
  }

  formatVal(value: number): any {

	let result: number = 0;
        if(value != undefined)
        	result = commaBigNumber(value);

 	return result;
  }

  private loadActivity(): void {

	if(this.addActivity != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KpiactivityComponent);
		const viewContainerRef = this.addActivity.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadCapacity(): void {

	if(this.addCapacity != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KpicapacityComponent);
		const viewContainerRef = this.addCapacity.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadCost(): void {

	if(this.addCost != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KpicostComponent);
		const viewContainerRef = this.addCost.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadHealth(): void {

	if(this.addHealth != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KpihealthComponent);
		const viewContainerRef = this.addHealth.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }
}

export enum CapaResource {
  CAP,CONS
}
  
export enum CapaEntity {
  HOST = "host",
  VM = "vm",
  DS = "ds"
}
  
export enum CapaAction {
  ADD = "add",
  DEL = "del"
}
  
export enum HALevel {
  OTHERS = 5,
  LOWEST = 4,
  LOW = 3,
  MEDIUM = 2,
  HIGH = 1,
  HIGHEST = 0
}
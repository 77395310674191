<div *ngIf="target==custom">
    <button style="float:right" [class]="card_button_class" (click)="reportWizard()">{{card_button}}</button>
</div>

<div class="card" *ngIf="((target != edit) && (target != custom))">
    <div class="card-header card-ajust">
        <h5><clr-icon shape="library"></clr-icon>&nbsp;{{card_header}}</h5>
    </div>
    <div class="card-block" style="overflow-y:auto;height:150px;text-align:justify;">
        <p>{{card_body}}</p>
    </div>
    <div class="card-footer">
        <button [class]="card_button_class" (click)="reportWizard()">{{card_button}}</button>
    </div>
</div>

<!-- REPORT CREATION -->
<clr-wizard #createReport
    [(clrWizardOpen)]="report_wizard"
    (clrWizardOnCancel)=doReset()
    (clrWizardOnFinish)=doFinish()>

    <clr-wizard-title>{{card_header}}</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">Finish</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="report_name == '' || report_filter == '' ">
        <ng-template clrPageTitle>Informations</ng-template>
        
        <div class="clr-form-control" style="flex-direction:row;margin-top:30px">
            <label class="clr-control-label clr-col-12 clr-col-md-2">Name :</label>
            <div [ngClass]="(report_name == '') ? 'clr-control-container clr-error' : 'clr-control-container'">
                <div class="clr-input-wrapper">
                    <input class="clr-input" type="text" [(ngModel)]="report_name" style="width:20em" (keyup)="checkReportName();">
                    <clr-icon class="clr-validate-icon" *ngIf="report_name == ''" shape="exclamation-circle"></clr-icon>
                    <clr-control-error *ngIf="report_name == ''">Please enter the report name </clr-control-error>
                </div>
            </div>
        </div>

        <hr style="margin-top:50px;margin-bottom:40px">

        <clr-radio-container clrInline style="flex-direction:row;">
            <label class="clr-control-label clr-col-12 clr-col-md-2">Language :</label>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="language" value="1" [(ngModel)]="report_lang" />
                <label>Français</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="language" value="2" [(ngModel)]="report_lang" />
                <label>English</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="language" value="3"  [(ngModel)]="report_lang"  />
                <label>Español</label>
            </clr-radio-wrapper>
        </clr-radio-container>

        <hr style="margin-top:40px;margin-bottom:40px">

        <clr-select-container style="flex-direction:row;">
            <label class="clr-control-label clr-col-12 clr-col-md-2">Type  :</label>
            <select clrSelect [(ngModel)]="report_type" (change)="initFilters()" [disabled]="(target != custom) && (target != edit)" >
                <option value="infra">Infrastructure</option>
                <option value="vm">VM</option>
            </select>
            <clr-control-helper *ngIf="(target == custom) || (target == edit)">'infra' to generate a report about your infrastructure (ex : behavior, recommendations, ...)</clr-control-helper>
            <clr-control-helper *ngIf="(target == custom) || (target == edit)">'vm' to generate a report about all VM for a specific entity (cluster, server, folder, ...)</clr-control-helper>
            <clr-control-helper *ngIf="(target != custom) && (target != edit)">Not available : automatically set</clr-control-helper>
        </clr-select-container>

        <hr style="margin-top:40px;margin-bottom:20px">

        <div class="clr-row">
            <clr-datalist-container class="clr-col-8" style="flex-direction:row;margin-left:0px">
                <label class="clr-control-label clr-col-3" style="max-width:24%;"> Filter or entity :</label>
                <input class="clr-col-9" clrDatalistInput [(ngModel)]="report_filter" placeholder="Select your filter or entity ..." name="report_filter_select" style="width:400px;padding-left:0px;" required />
                <clr-icon class="clr-validate-icon" *ngIf="report_filter == ''" shape="exclamation-circle"></clr-icon>
                <clr-control-error *ngIf="report_filter == ''">Please choose a filter or an entity </clr-control-error>
                <clr-control-helper>Once you have made your choice, the filter or element identifier will be displayed</clr-control-helper>
                <datalist>
                    <option *ngFor="let item of report_filter_list" [value]="item.id">{{item.name}} - [{{item.type}}]</option>
                </datalist>
            </clr-datalist-container>
            <clr-icon *ngIf="report_filter != ''" class="clr-col-1" style="cursor:pointer;width:15px;margin:auto;margin-left:0px" shape="times" (click)="report_filter = ''"></clr-icon>
        </div>

    </clr-wizard-page>

    <clr-wizard-page>
        <ng-template clrPageTitle>Auto generation & mails</ng-template>
        
        <clr-radio-container clrInline style="flex-direction:row;margin-top:30px">
            <label class="clr-control-label clr-col-12 clr-col-md-2">Automatic :</label>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="autoyesno" value="yes" [(ngModel)]="report_auto" />
                <label>yes</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input type="radio" clrRadio name="autoyesno" value="no" [(ngModel)]="report_auto" />
                <label>no</label>
            </clr-radio-wrapper>
        </clr-radio-container>

        <div *ngIf="report_auto == 'no'">
            <div class="alert alert-info" style="margin-top:50px;text-align:justify;font-size:13px;padding-left:5px;padding-right:5px;padding-top:20px;padding-bottom:20px;float:left;">
                The automatic option will generate a new report without any user intervention. This report will be generated according to the filter that you have defined.
            </div>
            <div id="wizard-auto-yes-email-list">&nbsp;</div>
        </div>

        <div *ngIf="report_auto == 'yes'">
            <hr>
            <p><b>Email addresses :</b></p>
            <div class="clr-form-control">
                <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                        <input type="text" id="add-edit-email" placeholder="Enter new email address" class="clr-input" [(ngModel)]="report_mail" />
                        <clr-icon shape="plus-circle" class="addLink" style="color:#0072a3" (click)="addEmail()"></clr-icon>
                    </div>
                    <span class="clr-subtext">enter a new email address</span>
                </div>
            </div>
            <table class="table">
                <thead><tr><th>LIST OF EMAIL ADDRESSES</th></tr></thead>
                <tbody>
                    <tr *ngFor="let email of report_mails">
                        <td (click)="removeEmail(email)">
                            {{email}}
                            <clr-icon shape="minus-circle" class="addLink"></clr-icon>
                        </td>
                    </tr> 
                </tbody>
            </table>

            <hr>
            <p style="margin-bottom:10px"><b>Choose the generation periodicity :</b></p>
            <app-reportcron [(cron)]="report_cron" [(options)]="report_cron_options"></app-reportcron>
        </div>

    </clr-wizard-page>

    <clr-wizard-page *ngIf="report_type == infra_type && (target == custom || target == edit)">
        <ng-template clrPageTitle>Sections</ng-template>
        
    <div>
        <div style="width:33%;float:left;">
            <clr-checkbox-container style="margin-top:0px"> 
                <clr-checkbox-wrapper style="margin-top: 300px">
                    <input type="checkbox" clrCheckbox (click)="checkAll(behavior_section)" [(ngModel)]="behavior_all" [indeterminate]="behavior_indeterminate" />
                    <label><b>VM behavior</b></label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_scope" />
                    <label>analysed scope</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_behavior" />
                    <label>behavior of VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_idle" />
                    <label>idle VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_lazy" />
                    <label>lazy VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_busy" />
                    <label>busy VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_undersize" />
                    <label>undersized VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_oversize" />
                    <label>oversized VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_ghost" />
                    <label>ghost VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_eol" />
                    <label>end of life VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_zombie" />
                    <label>zombie vmdk</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(behavior_section)" [(ngModel)]="behavior_info" />
                 <label>informations about datastores & servers & VM</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </div>

         <div style="width:33%;float:left;" *ngIf="currentUser.isMenuReco">
            <clr-checkbox-container style="margin-top:0px">
                <clr-checkbox-wrapper style="margin-top: 300px">
                    <input type="checkbox" clrCheckbox (click)="checkAll(reco_section)" [(ngModel)]="reco_all" [indeterminate]="reco_indeterminate" />
                    <label><b>Recommendation</b></label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
    
            <clr-checkbox-container>
                <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox (click)="checkIndeterminate(reco_section)" [(ngModel)]="reco_tools" />
                <label>tools</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox (click)="checkIndeterminate(reco_section)" [(ngModel)]="reco_ocr"  />
                <label>over commitment rate</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(reco_section)" [(ngModel)]="reco_resizing_vp"  />
                    <label>resizing virtual processor</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(reco_section)" [(ngModel)]="reco_resizing_memory"  />
                    <label>resizing memory</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(reco_section)" [(ngModel)]="reco_lcr"  />
                    <label>life cycle of VM & resources</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(reco_section)" [(ngModel)]="reco_lcb"  />
                    <label> life cycle of VM & behavior</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </div>

        <div style="width:33%;float:left;" *ngIf="currentUser.isMenuCapa">
            <clr-checkbox-container style="margin-top:0px">
                <clr-checkbox-wrapper style="padding-bottom: 30px">
                    <input type="checkbox" clrCheckbox [(ngModel)]="capa_all" />
                    <label><b>Capacity Planning</b></label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </div>

    </div>

    <hr style="float:left;width:100%;margin-top:30px;margin-bottom:30px">

    <div style="float:left;width:100%">

        <div style="width:33%;float:left;" *ngIf="currentUser.isMenuKpi && !message.isCO2Scope">
            <clr-checkbox-container style="margin-top:0px">
                <clr-checkbox-wrapper style="margin-top: 300px">
                    <input type="checkbox" clrCheckbox (click)="checkAll(kpi_section)" [(ngModel)]="kpi_all" [indeterminate]="kpi_indeterminate" />
                    <label><b>KPI</b></label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
    
            <clr-checkbox-container>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(kpi_section)" [(ngModel)]="kpi_evolution" />
                    <label> evolution of VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(kpi_section)" [(ngModel)]="kpi_cost"/>
                    <label> cost of VM</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(kpi_section)" [(ngModel)]="kpi_capa" />
                    <label>capacity of resources</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(kpi_section)" [(ngModel)]="kpi_trouble" />
                    <label> troubleshooting rate</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </div>

        <div style="width:33%;float:left;" *ngIf="currentUser.isMenuGreenit">
            <clr-checkbox-container style="margin-top:0px">
                <clr-checkbox-wrapper style="padding-bottom: 30px">
                    <input type="checkbox" clrCheckbox (click)="checkAll(greenit_section)" [(ngModel)]="greenit_all" [indeterminate]="greenit_indeterminate" />
                    <label><b>GreenIt</b></label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(greenit_section)" [(ngModel)]="greenit_evolution" />
                    <label> carbon footprint & kWh evolutions</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(greenit_section)" [(ngModel)]="greenit_settings" />
                    <label> servers indicators</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(greenit_section)" [(ngModel)]="greenit_efficiency" />
                    <label> energy efficiency & consumptions (servers)</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox (click)="checkIndeterminate(greenit_section)" [(ngModel)]="greenit_efficiency_vms" />
                    <label> energy consumptions (vms)</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </div>

    </div>
    </clr-wizard-page>

</clr-wizard>




import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-usermgt-noroot]'
})
export class UsermgtnorootDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

export enum DATA_ORDER {
  ASC, DESC
}

export enum Tab {
  GREENIT, BEHAVIOR, INFRA
}

export enum VirtindexProperty {
  VM_PERLOGICALCORE = 'vm.perlogicalcore',
  VM_PERSERVER = 'vm.perserver',
  VM_PERSOCKET = 'vm.persocket',
  VM_WATT = 'watt.pervm',
  SERVER_KWH = 'kwh.perserver',
  SERVER_CO2G = 'co2.grey.perserver',
  VM_CO2G = 'co2.grey.pervm',
  VM_CO2T = 'co2.total.pervm',
  SERVER_AGE = 'age.perserver',
  SERVER_PUE ='pue.perserver',
  SERVER_CO2_COEFF ='co2coeff.perserver',
  IDLE = 'idle',
  OVERSIZED = 'oversized',
  UNDERSIZED = 'undersized',
  RAM_ALLOC_PERCENT = 'ram.alloc.percent',
  RAM_USE_PERCENT = 'ram.use.percent',
}

export enum VirtindexName {
  VM_PERLOGICALCORE = 'vCPU per logical core',
  VM_PERSERVER = 'VM per server',
  VM_PERSOCKET = 'VM per CPU socket',
  VM_WATT = 'Average Watt per VM',
  SERVER_KWH = 'Annual kWh per server',
  SERVER_CO2G = 'Annual embodied CO2 (kg CO2eq) per server',
  VM_CO2G = 'Annual embodied CO2 (kg CO2eq) per VM',
  VM_CO2T = 'Annual global CO2 (kg CO2eq) per VM',
  SERVER_AGE = 'Average Server age (years)',
  SERVER_PUE ='PUE',
  SERVER_CO2_COEFF ='Emission factor (kg CO2eq/kWh)',
  IDLE = 'idle (%)',
  OVERSIZED = 'oversized (%)',
  UNDERSIZED = 'undersized (%)',
  RAM_ALLOC_PERCENT = 'RAM allocation (%)',
  RAM_USE_PERCENT = 'RAM use (%)',
}
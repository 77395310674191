import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NetscopeService} from '../../services/netscope.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as d3 from 'd3';

import {JsonloaderService, LicenseService} from "@app/services";
import {ClrCombobox, ClrDatagridSortOrder, ClrDatagridStringFilterInterface} from "@clr/angular";
import {environment} from "@environments/environment";

class SourceFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.source !== undefined && item.source.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return false;
  }
}

class DestinationFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.target !== undefined && item.target.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return false;
  }
}

@Component({
  selector: 'app-netscope-clusters-viewer-clusters',
  templateUrl: './netscope-clusters-viewer.component.html',
  styleUrls: ['./netscope-clusters-viewer.component.css']
})
export class NetscopeClustersViewerComponent implements OnInit {

  jsonData = undefined;
  subscriptions = [];
  isLoading = false;
  svg;
  height = 1024;
  width = 768;
  chartRef;
  chartCheckInterval;
  reloadButtonColorClass = "btn-primary";

  sourceIpFilter = new SourceFilter();
  destinationIpFilter = new DestinationFilter();
  descSort = ClrDatagridSortOrder.DESC;

  isNetscopeLicenceEnabled = true;

  lastSvgWidth = 0;
  lastSvgHeight = 0;
  vmClickAction = "remove";

  focusedVms = [];

  root = [];
  nodesMapping = {};
  timestamps = [];
  replayData = {
    routers: [],
    links: [],
    vms: [],
    clusters: []
  }
  graphData = {
    links: [],
    vms: [],
    clusters: []
  };
  filteredLinks = [];
  currentTime = 0;
  timeResolution = "daily";
  minTime = 0;
  maxTime = 0;

  @ViewChild('combobox')
  combobox: ClrCombobox<any>;

  selectedResourcesWithSearchCombobox = [];
  resourcesForSearchCombobox = [];

  gravityValue = undefined; // It will be redefined when we have the numbers of displayed VMs
  gravityMode = "automatic";

  lastForcePositions = {};
  lastAlpha = 0.0;
  simulationAlreadyLoaded = false;

  @ViewChild('dataSettingsPanel')
  dataSettingsPanel;

  allVms = [];
  allProtocols = [];

  targetedResources = {
    virtualMachines: []
  };
  targetedResourcePattern = "";

  mapDataFilters = {
    virtualMachines: {}
  };

  isPlaying = false;
  currentRangeValue = 0;
  minRangeValue = 0;
  maxRangeValue = 0;

  graphParameters = {
    userMovedMap: false,
    lastTransformation: undefined
  }
  simulationShouldRestart = false

  selectedLinkForSankey = undefined;
  sankeyCurrentTime = 0;
  radioOptions = 'origin,protocol,destination';

  resourceSelectionChanged = new EventEmitter();
  clickOnTimeSlotEmitter = new EventEmitter();

  constructor(private netscopeService: NetscopeService, public licenceService: LicenseService, private activatedRouter: ActivatedRoute, private route:Router, private jsonLoaderService: JsonloaderService) {
  }

  ngOnInit(): void {

    this.activatedRouter.params.subscribe((params) => {
      let resource_uuids_str_value = params["resource_uuids"];
      if (resource_uuids_str_value === undefined) {
        return;
      }
      let resource_uuids = resource_uuids_str_value.split(",");
      this.focusedVms = resource_uuids.map((resource_uuid) => Object({
        id: resource_uuid,
        uuid: resource_uuid,
        type: "vm"
      }));
    });

    let subscription1 = this.jsonLoaderService.currentJsonSimpleSubject.subscribe(json => {
      this.jsonData = json;
      this.reloadData();
    });
    let subscription2 = this.jsonLoaderService.eventJsonAsyncLoaded.subscribe(json => {
      this.jsonData = json;
      this.reloadData();
    });
    this.subscriptions = [subscription1, subscription2];

    window.onresize = () => {
      if (this.jsonData !== undefined) {
        this.reloadUi();
      }
    };

    this.licenceService.licenseInfo.subscribe(licenceInfo => {
      if (environment.production) {
        // Check second bit to be different than 0
        this.isNetscopeLicenceEnabled = (licenceInfo.moduleslicense & (1 << 1)) !== 0;
      } else {
        this.isNetscopeLicenceEnabled = true;
      }
    });

    this.clickOnTimeSlotEmitter.subscribe((event) => {
      console.log(event);
      // @ts-ignore
      let timestamp = event[0];
      this.currentTime = timestamp;
      this.timeResolution = event[2];
      this.reloadData();
    })

    this.chartCheckInterval = setInterval(() => {
      if (this.selectedLinkForSankey !== null && this.chartRef !== undefined && this.chartRef !== null) {
        try {
          this.chartRef.reflow();
        } catch (e) {
          console.log(e);
          clearInterval(this.chartCheckInterval);
        }
      }
    }, 1000);

    this.route.events.subscribe((event) => {
      this.ngOnDestroy();
    });
  }

  ngOnDestroy = () => {
    if (this.chartCheckInterval) {
      clearInterval(this.chartCheckInterval);
    }
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  callbackChartRef = (ref) => {
    this.chartRef = ref;
  }

  _generateListOfTargetedResources = () => {

    if (this.targetedResourcePattern === "" || this.targetedResourcePattern.length < 2) {
      return {
        switches: [],
        networks: [],
        hosts: [],
        virtualMachines: []
      };
    }

    var isValidRegex = true;
    try {
      new RegExp(this.targetedResourcePattern);
    } catch(e) {
      isValidRegex = false;
    }

    // VirtualMachines
    let selectedVirtualMachines = this.replayData.vms
        .filter((vm) => {
          if(vm.name.toUpperCase().indexOf(this.targetedResourcePattern.toUpperCase()) !== -1) {
            return true;
          }
          if(isValidRegex && vm.name.match(this.targetedResourcePattern)) {
            return true;
          }
          return false;
        });

    return {
      virtualMachines: selectedVirtualMachines
    };
  }

  updateListTargetedResources = () => {
    const targetResources = this._generateListOfTargetedResources();
    this.targetedResources.virtualMachines = targetResources.virtualMachines;
  }

  applyActionOnTargetResources = (resourceType, action) => {
    const targetResources = this._generateListOfTargetedResources();
    for (let targetResource of targetResources[resourceType]) {
      this.mapDataFilters[resourceType][targetResource.uuid] = action == "check" ? true : false;
    }
    this.setButtonPrimaryRed();
  }

  setButtonPrimaryRed = () => {
    this.reloadButtonColorClass = "btn-warning";
  }

  selectedResourceInSearchCombobox = (selection) => {
    this.setButtonPrimaryRed();

    this.selectedResourcesWithSearchCombobox = selection.model;
    if (this.selectedResourcesWithSearchCombobox === null) {
      this.selectedResourcesWithSearchCombobox = [];
    }

    if (this.selectedResourcesWithSearchCombobox.length == 0) {
      for (let vmKey in this.mapDataFilters.virtualMachines) {
        this.mapDataFilters.virtualMachines[vmKey] = true;
      }
    } else {
      for (let vmKey in this.mapDataFilters.virtualMachines) {
        this.mapDataFilters.virtualMachines[vmKey] = false;
      }
    }

    let vms = this.selectedResourcesWithSearchCombobox
        .filter((r) => r.uuid.indexOf("VirtualMachine:") !== -1);
    let hosts = this.selectedResourcesWithSearchCombobox
        .filter((r) => r.uuid.indexOf("HostSystem:") !== -1);

    // Handle VMs first
    vms.map((vm) => {
      this.mapDataFilters.virtualMachines[vm.uuid] = true;
    })

    // Handle Hosts second
    hosts.map((host) => {
      host.children.map((hostVmUuid) => {
        this.mapDataFilters.virtualMachines[hostVmUuid] = true;
      });
    })

    // @ts-ignore
    this.combobox.toggleService.open = false;
    this.combobox.textbox.nativeElement.focus = false;
    this.combobox.focused = false;
  }

  reloadData = () => {
    this.isLoading = true;
    const timestampsAndCountsObservable = this.netscopeService.getDailyTimestamps();

    timestampsAndCountsObservable.subscribe((timestampsAndCounts) => {
      this.timestamps = timestampsAndCounts
          .filter((t) => t.start_time !== 0)
          .map((t) => t.start_time);
      if (this.timestamps.length > 0) {
        if (this.currentTime === 0) {
          this.currentTime = this.timestamps[this.timestamps.length - 1];
        }
      }

      this.isLoading = false;
      this.reloadVisualization();
    });
  }

  reloadVisualization = () => {
    const startTimeInterval = this.currentTime;
    const lastTimeInterval: any = Math.max(...this.timestamps);
    let endTimeInterval;
    if (this.timeResolution === "daily") {
      if (lastTimeInterval <= startTimeInterval) {
        endTimeInterval = 'now()';
      } else {
        endTimeInterval = Math.min(...this.timestamps.filter((t) => t > startTimeInterval), lastTimeInterval);
      }
    } else {
      endTimeInterval = startTimeInterval + 3600 - 1;
    }

    const vmsUuids = this.jsonData.vmSynthesis.map((vm) => vm.uuid)
    const hostsUuids = this.jsonData.hostSynthesis.map((host) => host.uuid)

    this.isLoading = true;
    this.netscopeService.getClustersWithFilters(startTimeInterval, endTimeInterval, vmsUuids, hostsUuids, true, this.timeResolution).subscribe((clustersData) => {
      this.isLoading = false;

      this.reloadButtonColorClass = "btn-primary";
      // Initialize replayData
      // @ts-ignore
      this.replayData = clustersData;
      // Reload UI
      this.reloadUi();
    });
  }

  checkFiltersCheckbox = (resourceType) => {
    for (let key in this.mapDataFilters[resourceType]) {
      this.mapDataFilters[resourceType][key] = true;
    }
  }

  uncheckFiltersCheckbox = (resourceType) => {
    for (let key in this.mapDataFilters[resourceType]) {
      this.mapDataFilters[resourceType][key] = false;
    }
  }

  reloadUiAndRecomputeDistances = () => {
    this.simulationShouldRestart = true;
    this.reloadUi();
  }

  reloadUi = () => {
    const sortedTimes = this.timestamps
        .sort((a, b) => a - b);

    if (this.minTime === 0 || this.maxTime === 0) {
      this.minTime = Math.min(...sortedTimes);
      this.maxTime = Math.max(...sortedTimes);
      console.log(`setting replay start time at ${this.currentTime}`);
    }
    if (this.currentTime === 0) {
      this.currentTime = Math.max(...sortedTimes);
      console.log(`setting replay start time at ${this.currentTime}`);
    }
    // Set the range value
    this.minRangeValue = 0;
    this.maxRangeValue = sortedTimes.length - 1;
    const indexOfCurrentTime = sortedTimes.indexOf(this.currentTime);
    this.currentRangeValue = indexOfCurrentTime;
    // Set the right links
    this.graphData.links = this.replayData.links;
    this.graphData.vms = this.replayData.vms;
    this.graphData.clusters = this.replayData.clusters;

    // Take into account focused VMs
    if (this.focusedVms.length > 0) {
      let focusedVmsIds = this.focusedVms.map((vm) => vm.id);
      // Filter relevant links
      let relevantLinks = this.graphData.links.filter((link) => {
        if (focusedVmsIds.indexOf(link.source.id) !== -1 || focusedVmsIds.indexOf(link.target.id) !== -1) {
          return true;
        }
        return false;
      })
      // Extract uuids of VMs in links
      let uuidVmMap = new Map();
      relevantLinks.map((link) => {
        uuidVmMap.set(link.source.id, link.source);
        uuidVmMap.set(link.target.id, link.target);
      })
      // Filter VMs
      this.graphData.vms = Array.from(uuidVmMap.values());
      // Add focused VMs if they are not in the new vms list
      for (let focusedVm of this.focusedVms) {
        let newVmsIds = this.graphData.vms.map((vm) => vm.id);
        if (newVmsIds.indexOf(focusedVm.id) === -1) {
          this.graphData.vms.push(focusedVm);
        }
      }
      // Filter links
      this.graphData.links = this.replayData.links.filter((link) => {
        if (uuidVmMap.has(link.source.id) || uuidVmMap.has(link.target.id)) {
          return true;
        }
        return false;
      });
    }

    // Add hosts UUID to vms
    let vmUuidToHostIndex = {};
    this.replayData.routers.map((router) => router.children.map((vmUuid) => {
      vmUuidToHostIndex[vmUuid] = {
        uuid: router.uuid,
        name: router.name
      };
    }))
    this.graphData.vms.map((vm) => {
      vm.host = vmUuidToHostIndex[vm.uuid];
    });
    this.graphData.clusters = this.replayData.clusters;

    // Initialize mapDataFilters options
    this.graphData.vms.forEach((vm) => {
      if (!this.mapDataFilters.virtualMachines.hasOwnProperty(vm.uuid)) {
        this.mapDataFilters.virtualMachines[vm.uuid] = true;
      }
    });

    this.resourcesForSearchCombobox = [
      ...this.graphData.vms
    ]

    // Fix VMs without hosts
    const vmsWithoutHosts = this.graphData.vms
        .filter((vm) => vm.host === undefined);

    if (vmsWithoutHosts.length > 0) {
      vmsWithoutHosts.map((vm) => {
        let simpleVmUuid = vm.uuid.split(":")[1];
        const matchingVmsDcscope = this.jsonData.vmSynthesis
            .filter((vmDcScope) => simpleVmUuid === vmDcScope.uuid);
        matchingVmsDcscope.map((matchingVmDcscope) => {
          let hostUuid = `vim.HostSystem:${matchingVmDcscope.father}`;
          let matchingHosts = this.replayData.routers.filter((host) => host.uuid === hostUuid);
          matchingHosts.map((host) => {
            let hostObject = Object({
              uuid: hostUuid,
              short_uuid: `${matchingVmDcscope.father}`,
              name: host.name
            });
            vm.host = hostObject
            host.children.push(vm.uuid);

            [this.graphData.links].map((links) => {
              links.filter((link) => link.source.uuid === vm.uuid).map((link) => {
                link.source.host = hostObject;
              })

              links.filter((link) => link.target.uuid === vm.uuid).map((link) => {
                link.target.host = hostObject;
              })
            })
          });
        })
      });
    }

    // Refresh network view
    this.eraseNetworkView();
    this.createNetworkView(this.graphData);
  }

  onTimeRangeChange = () => {
    const sortedTimes = this.timestamps
        .sort((a, b) => a - b);
    const requestedTime = sortedTimes[this.currentRangeValue];
    this.currentTime = sortedTimes[this.currentRangeValue];

    // Prevent a continuous slide to trigger many calls to "reloadData()"
    setTimeout(() => {
      if (this.currentTime === requestedTime) {
        this.reloadData();
      }
    }, 200);
  }

  play = () => {
    this.isPlaying = true;
    this.next();
  }

  pause = () => {
    this.isPlaying = false;
  }

  next = () => {
    if (!this.isPlaying) {
      return;
    }

    const sortedTimestamps = this.timestamps.map((d) => d).sort();
    const firstTimestamp = Math.min(...sortedTimestamps);
    const lastTimestamp = Math.max(...sortedTimestamps);
    if (this.currentTime === 0 || this.currentTime === lastTimestamp) {
      this.currentTime = firstTimestamp;
    } else {
      const indexOfCurrentTimestamp = sortedTimestamps.indexOf(this.currentTime);
      this.currentTime = sortedTimestamps[indexOfCurrentTimestamp + 1];
    }

    this.reloadData();

    if (this.currentTime !== lastTimestamp) {
      setTimeout(() => {
        this.next();
      }, 1000);
    } else {
      this.isPlaying = false;
    }
  }

  isFocusedVm(vmUuid) {
    let focusedVmsIds = this.focusedVms.map((vm) => vm.id);
    return focusedVmsIds.indexOf(vmUuid) !== -1;
  }

  selectForFocus = (vmUuids) => {
    this.focusedVms = [];

    for (let vmUuid of vmUuids) {
      this.focusedVms.push({
        id: vmUuid,
        uuid: vmUuid,
      });
    }
    this.reloadUi();
  }

  clearFocus = () => {
    this.selectForFocus([]);
  }

  switchFocus = (vm) => {
    let focusedVmsIds = this.focusedVms.map((vm) => vm.id);
    // @ts-ignore
    let currentVmId = vm.id;

    if (focusedVmsIds.indexOf(currentVmId) !== -1) {
      this.focusedVms = this.focusedVms.filter((vm) => vm.id !== currentVmId);
    } else {
      this.focusedVms.push(vm);
    }
    this.simulationAlreadyLoaded = false;
    this.graphParameters.userMovedMap = false;
    this.reloadUi();
  }

  createNetworkView(graphData): void {
    d3.select('div#divSvg').select("svg").remove();
    this.refreshNetworkView(graphData);
  }

  eraseNetworkView(): void {
    d3.select('div#divSvg')
        .selectAll('*')
        .remove();
  }

  /**
   * This method export the data table into a CSV file
   */
  exportCSV() {
    let csvHeader = "Source, Destination, ExchangedBytes, TotalPackets"
    let csvContent = csvHeader+"\n";
    for (let link of this.filteredLinks) {
      let lineValue = `${link.source.name}, ${link.target.name}, ${link.exchanged_bytes}, ${link.exchanged_packets}\n`;
      csvContent += lineValue;
    }

    let exportedFilename = 'netscope-clusters.csv';
    let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  exportGraph = () => {
    let svgBody = this.svg.html();
    let svgCode = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${svgBody}</svg>`;
    let exportedFilename = 'netscope-clusters.svg';

    let blob = new Blob([svgCode], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  reloadDataAndRecomputeDistances = () => {
    this.simulationAlreadyLoaded = false;
    this.reloadData();
  }

  refreshNetworkView(graphData): void {
    let svgHeight = document.getElementById('divSvg').clientHeight;
    let svgWidth = document.getElementById('divSvg').clientWidth;

    if (svgHeight == 0) {
      svgHeight = this.lastSvgHeight;
    } else {
      this.lastSvgHeight = svgHeight;
    }

    if (svgWidth == 0) {
      svgWidth = this.lastSvgWidth;
    } else {
      this.lastSvgWidth = svgWidth;
    }

    let totalBytes = 0;
    let links = graphData.links;
    if (links.length > 0) {
      totalBytes = links.map((d) => d.value).reduce((a, b) => a + b);
    }

    // Filter links with a datacenter
    links = links.filter((l) => {
      return l.source.type !== 'datacenter' && l.target.type !== 'datacenter:';
    });

    const vms = graphData.vms
        .filter((vm) => vm.host !== undefined)
        .filter((n) => this.mapDataFilters.virtualMachines[n.uuid])
        .sort((vmA, vmB) => vmA.host.uuid.localeCompare(vmB.host.uuid));
    const vmsUuids = vms.map((vm) => vm.uuid);

    // Redefine gravity
    if (this.gravityMode === "automatic" || this.gravityValue === undefined) {
      this.gravityValue = (-1) * (300 + ((10000 - 300) / 200) * vms.length);
    }

    links = links.filter((l) => {
      return vmsUuids.indexOf(l.source.id) !== -1 && vmsUuids.indexOf(l.target.id) !== -1;
    });
    // this.filteredLinks = links;
    this.filteredLinks = JSON.parse(JSON.stringify(links));

    links = links.map((l) => {
      let linkClone = JSON.parse(JSON.stringify(l));
      linkClone.source = linkClone.source.id;
      linkClone.target = linkClone.target.id;
      return linkClone;
    })

    let focusedVmsUuids = this.focusedVms.map((vm) => vm.uuid);

    const forceParameter = this.gravityValue;
    const graphDataNodesOnlyVms = vms;

    // @ts-ignore
    let ratioMinSizeOverMaxSize = 10;

    // @ts-ignore
    d3.select('div#divSvg').select("svg").remove();
    const svg = d3.select('div#divSvg')
        .append('svg')
        .attr('width', svgWidth)
        .attr('height', svgHeight);
    this.svg = svg;

    // Add zoom
    svg.call(d3.zoom()
        .extent([[0, 0], [svgWidth, svgHeight]])
        .scaleExtent([0.01, ratioMinSizeOverMaxSize])
        .on("zoom", zoomed));

    const g = svg.append("g");

    const self = this;
    function zoomed({transform}) {
      self.graphParameters.userMovedMap = true;
      self.graphParameters.lastTransformation = transform;
      g.attr("transform", transform);
    }

    const link = g.append('g')
        .attr('class', 'links')
        .selectAll('line')
        .data(links)
        .enter().append('line')
        .attr('stroke', (d) => {
          // @ts-ignore
          if (focusedVmsUuids.indexOf(d.source) != -1 || focusedVmsUuids.indexOf(d.target) != -1) {
            return `#e79807`;
          }

          return `#aaa`;
        })
        .on('mouseover', function(d, i) {
          d3.select(this).style('cursor', 'pointer');
        })
        .on('mouseout', function(d, i) {
          d3.select(this).style('cursor', 'default');
        })
        .on('click', (d, i) => {
        })
        // @ts-ignore
        .attr('stroke-width', (d) => Math.max(1, 20 * ((1.0 * d.value) / totalBytes)));

    const visuNode = g.append('g')
        .attr('class', 'nodes')
        .selectAll('g')
        .data(graphDataNodesOnlyVms)
        .enter().append('g');

    const nodeRadius = 15;

    let currentInstanceObject = this;
    visuNode.filter((d) => {
        // @ts-ignore
        return d.type === 'vm' || d.type === 'host';
      })
      .on("mouseover", function (d, i) {
        d3.select(this).style("cursor", "pointer");
      })
      .on("mouseout", function (d, i) {
        d3.select(this).style("cursor", "default");
      })
      .on("click", function (d, i) {
        if (currentInstanceObject.vmClickAction === "remove") {
          // @ts-ignore
          currentInstanceObject.mapDataFilters.virtualMachines[i.id] = !currentInstanceObject.mapDataFilters.virtualMachines[i.id];
          // currentInstanceObject.lastAlpha = 0.0;
          // currentInstanceObject.lastForcePositions = {};
          currentInstanceObject.simulationShouldRestart = true;
          currentInstanceObject.setButtonPrimaryRed();
          updateCirclesStrokeColor();
        } else if (currentInstanceObject.vmClickAction === "graph") {
          // @ts-ignore
          let [rawObjectTypeName, objectUuid] = i.id.split(":");
          // @ts-ignore
          let objectTypeName = i.type == "host" ? "SERVER" : "VM";
          currentInstanceObject.route.navigate(['god/resource/', objectUuid], { queryParams: { useResourceCountersFor: objectTypeName }} );
        } else if (currentInstanceObject.vmClickAction === "focus") {
          currentInstanceObject.switchFocus(i);
        }
      });

    const circles = visuNode.append('g')
        .append('circle')
        .attr('class', 'vmCircle')
        .attr('r', nodeRadius)
        .style('stroke-width', 5)    // set the stroke width
        .attr('fill', (d) => 'white');

    function updateCirclesStrokeColor() {
      visuNode
          .selectAll('.vmCircle')
          .style('stroke', (d) => {
            let focusedVmsIds = currentInstanceObject.focusedVms.map((vm) => vm.id);
            // @ts-ignore
            if (currentInstanceObject.mapDataFilters.virtualMachines[d.id]) {
              // @ts-ignore
              if (focusedVmsIds.indexOf(d.id) !== -1) {
                return '#e79807';
              } else {
                return '#007FCB';
              }
            } else {
              return '#d91919';
            }
          });
    }
    updateCirclesStrokeColor();

    const iconsSvgCode = {
      "datacenter": "<path d=\"M26.5,4.08C22.77,4.08,19,5.4,19,7.91V9.5a18.75,18.75,0,0,1,2,.2V7.91c0-.65,2.09-1.84,5.5-1.84S32,7.27,32,7.91V18.24c0,.54-1.46,1.44-3.9,1.73v2c3.13-.32,5.9-1.6,5.9-3.75V7.91C34,5.4,30.23,4.08,26.5,4.08Z\"/><path d=\"M4,18.24V7.91c0-.65,2.09-1.84,5.5-1.84S15,7.27,15,7.91V9.7a18.75,18.75,0,0,1,2-.2V7.91c0-2.52-3.77-3.84-7.5-3.84S2,5.4,2,7.91V18.24C2,20.4,4.77,21.67,7.9,22V20C5.46,19.68,4,18.78,4,18.24Z\"/><path d=\"M18,10.85c-4.93,0-8.65,1.88-8.65,4.38V27.54c0,2.5,3.72,4.38,8.65,4.38s8.65-1.88,8.65-4.38V15.23C26.65,12.73,22.93,10.85,18,10.85Zm6.65,7.67c-.85,1-3.42,2-6.65,2A14.49,14.49,0,0,1,14,20v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v3.12c-.85,1-3.42,2-6.65,2a14.49,14.49,0,0,1-4-.53v1.46a16.33,16.33,0,0,0,4,.47,12.76,12.76,0,0,0,6.65-1.56v2.29c0,.95-2.65,2.38-6.65,2.38s-6.65-1.43-6.65-2.38V15.23c0-.95,2.65-2.38,6.65-2.38s6.65,1.43,6.65,2.38Z\"/>",
      "network": "<path d=\"M26.58,32h-18a1,1,0,1,0,0,2h18a1,1,0,0,0,0-2Z\"/><path d=\"M17.75,2a14,14,0,0,0-14,14c0,.45,0,.89.07,1.33l0,0h0A14,14,0,1,0,17.75,2Zm0,2a12,12,0,0,1,8.44,3.48c0,.33,0,.66,0,1A18.51,18.51,0,0,0,14,8.53a2.33,2.33,0,0,0-1.14-.61l-.25,0c-.12-.42-.23-.84-.32-1.27s-.14-.81-.19-1.22A11.92,11.92,0,0,1,17.75,4Zm-3,5.87A17,17,0,0,1,25.92,10a16.9,16.9,0,0,1-3.11,7,2.28,2.28,0,0,0-2.58.57c-.35-.2-.7-.4-1-.63a16,16,0,0,1-4.93-5.23,2.25,2.25,0,0,0,.47-1.77Zm-4-3.6c0,.21.06.43.1.64.09.44.21.87.33,1.3a2.28,2.28,0,0,0-1.1,2.25A18.32,18.32,0,0,0,5.9,14.22,12,12,0,0,1,10.76,6.27Zm0,15.71A2.34,2.34,0,0,0,9.2,23.74l-.64,0A11.94,11.94,0,0,1,5.8,16.92l.11-.19a16.9,16.9,0,0,1,4.81-4.89,2.31,2.31,0,0,0,2.28.63,17.53,17.53,0,0,0,5.35,5.65c.41.27.83.52,1.25.76A2.32,2.32,0,0,0,19.78,20a16.94,16.94,0,0,1-6.2,3.11A2.34,2.34,0,0,0,10.76,22Zm7,6a11.92,11.92,0,0,1-5.81-1.51l.28-.06a2.34,2.34,0,0,0,1.57-1.79,18.43,18.43,0,0,0,7-3.5,2.29,2.29,0,0,0,3-.62,17.41,17.41,0,0,0,4.32.56l.53,0A12,12,0,0,1,17.75,28Zm6.51-8.9a2.33,2.33,0,0,0-.33-1.19,18.4,18.4,0,0,0,3.39-7.37q.75.35,1.48.78a12,12,0,0,1,.42,8.2A16,16,0,0,1,24.27,19.11Z\"/>",
      "switch": "<path d=\"M5.71,14H20.92V12H5.71L9.42,8.27A1,1,0,1,0,8,6.86L1.89,13,8,19.14a1,1,0,1,0,1.42-1.4Z\"/><rect x=\"23\" y=\"12\" width=\"3\" height=\"2\"/><rect x=\"28\" y=\"12\" width=\"2\" height=\"2\"/><path d=\"M27.92,17.86a1,1,0,0,0-1.42,1.41L30.21,23H15v2H30.21L26.5,28.74a1,1,0,1,0,1.42,1.4L34,24Z\"/><rect x=\"10\" y=\"23\" width=\"3\" height=\"2\"/><rect x=\"6\" y=\"23\" width=\"2\" height=\"2\"/>",
      "port": "<path d=\"M6.06,30a1,1,0,0,1-1-1V8h-2a1,1,0,0,1,0-2h4V29A1,1,0,0,1,6.06,30Z\"/><path d=\"M30.06,27h-25V9h25a3,3,0,0,1,3,3V24A3,3,0,0,1,30.06,27Zm-23-2h23a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1h-23Z\"/><rect x=\"22.06\" y=\"20\" width=\"6\" height=\"2\"/><rect x=\"22.06\" y=\"14\" width=\"6\" height=\"2\"/><path d=\"M19.06,22h-8V20h7V14h2v7A1,1,0,0,1,19.06,22Z\"/>",
      "host": "<path d=\"M18,24.3a2.48,2.48,0,1,0,2.48,2.47A2.48,2.48,0,0,0,18,24.3Zm0,3.6a1.13,1.13,0,1,1,1.13-1.12A1.13,1.13,0,0,1,18,27.9Z\"/><rect x=\"13.5\" y=\"20.7\" width=\"9\" height=\"1.44\"/><path d=\"M25.65,3.6H10.35A1.35,1.35,0,0,0,9,4.95V32.4H27V4.95A1.35,1.35,0,0,0,25.65,3.6Zm-.45,27H10.8V5.4H25.2Z\"/><rect x=\"12.6\" y=\"7.2\" width=\"10.8\" height=\"1.44\"/><rect x=\"12.6\" y=\"10.8\" width=\"10.8\" height=\"1.44\"/>",
      "vm": "<path d=\"M11,5H25V8h2V5a2,2,0,0,0-2-2H11A2,2,0,0,0,9,5v6.85h2Z\"/><path d=\"M30,10H17v2h8v6h2V12h3V26H22V17a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2V31a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V28h8a2,2,0,0,0,2-2V12A2,2,0,0,0,30,10ZM6,31V17H20v9H16V20H14v6a2,2,0,0,0,2,2h4v3Z\"/>"
    };
    const iconSvg = visuNode.append('g')
        .attr('viewBox', `0 0 ${nodeRadius} ${nodeRadius}`)
        .append('g')
        .attr('transform', `translate(-11, -10) scale(0.60)`)
        // @ts-ignore
        .html((d) => iconsSvgCode[d.type]);

    const labels = visuNode.append('text')
        // @ts-ignore
        .text((d) => d.name)
        // @ts-ignore
        .attr('x', (d) => d.name.length * 6 / 2 * (-1))
        .attr('y', nodeRadius + 15)
        .attr('style', 'text-shadow: 1px 1px 2px white, -1px -1px 2px white;');

    // If relevant, set the last known positions of vms and links
    graphData.vms.map((vm) => {
      const vmKey = `${vm.uuid}`;
      if (self.lastForcePositions.hasOwnProperty(vmKey)) {
        vm.x = self.lastForcePositions[vmKey].x;
        vm.y = self.lastForcePositions[vmKey].y;
        vm.vx = self.lastForcePositions[vmKey].vx;
        vm.vy = self.lastForcePositions[vmKey].vy;
      }
    });

    links.map((link) => {
      const linkKey = `${link.source.uuid}_${link.target.uuid}`;
      if (self.lastForcePositions.hasOwnProperty(linkKey)) {
        link.x1 = self.lastForcePositions[linkKey].x1;
        link.y1 = self.lastForcePositions[linkKey].y1;
        link.x2 = self.lastForcePositions[linkKey].x2;
        link.y2 = self.lastForcePositions[linkKey].y2;
      }
    })

    const simulation = d3.forceSimulation()
        // @ts-ignore
        .force('link', d3.forceLink().id((d) => d.id))
        .force('charge', d3.forceManyBody().strength(forceParameter))
        .force('center', d3.forceCenter(svgWidth / 2, svgHeight / 2))
        .force('x', d3.forceX())
        .force('y', d3.forceY());

    simulation
        .nodes(vms)
        .on('tick', ticked);

    simulation
        .force('link')
        // @ts-ignore
        .links(links);

    if (this.simulationAlreadyLoaded) {
      if (this.simulationShouldRestart) {
        simulation.alpha(2.0).restart();
        this.simulationShouldRestart = false;
      } else {
        simulation.alpha(this.lastAlpha).restart();
      }
    }

    this.simulationAlreadyLoaded = true;

    function ticked() {
      link
          // @ts-ignore
          .attr('x1', (d) => d.source.x)
          // @ts-ignore
          .attr('y1', (d) => d.source.y)
          // @ts-ignore
          .attr('x2', (d) => d.target.x)
          // @ts-ignore
          .attr('y2', (d) => d.target.y);
      link
          .each((d) => {
            // @ts-ignore
            self.lastForcePositions[`${d.source.uuid}_${d.target.uuid}`] = {
              // @ts-ignore
              x1: d.source.x,
              // @ts-ignore
              y1: d.source.y,
              // @ts-ignore
              x2: d.target.x,
              // @ts-ignore
              y2: d.target.y,
            }
          })

      visuNode
          // @ts-ignore
          .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')');
      visuNode
          .each((d) => {
            // @ts-ignore
            self.lastForcePositions[`${d.uuid}`] = {
              // @ts-ignore
              x: d.x,
              // @ts-ignore
              y: d.y,
              // @ts-ignore
              vx: d.vx,
              // @ts-ignore
              vy: d.vy
            }
          })

      // @ts-ignore
      self.lastAlpha = simulation.alpha();
    }

    // step1: zoom out, and let d3 recompute positions and sizes
    const [x, y, k] = [0, 0, 1.0];
    g.attr('transform', 'translate(' + x + ',' + y + ') scale(' + k + ')');
    this.svg.call(
        d3.zoom().transform,
        d3.zoomIdentity.translate(x, y).scale(k)
    );

    // step2: center the main group by translating in the middle of the size difference
    // between the group and the svg
    let [x2, y2, k2] = [
      (g.node().getBBox().width - this.svg.node().getBBox().width) / 2 + 20,
      (g.node().getBBox().height - this.svg.node().getBBox().height) / 2 + 20,
      1.0
    ];

    if (this.graphParameters.userMovedMap) {
      [x2, y2, k2] = [this.graphParameters.lastTransformation.x, this.graphParameters.lastTransformation.y, this.graphParameters.lastTransformation.k];
    }

    g.attr('transform', 'translate(' + x2 + ',' + y2 + ') scale(' + k2 + ')');
    this.svg.call(
        d3.zoom().transform,
        d3.zoomIdentity.translate(x2, y2).scale(k2)
    );
  }
}

<clr-tabs>
	<clr-tab>
        	<button clrTabLink (click)="setCard()">CLUSTER CARD</button>
		<clr-tab-content *clrIfActive="true">
			<div class="card">
				<div class="card-header card-ajust">
					<h5>
						<label>
							<clr-icon shape="cluster"></clr-icon>
							{{cluster_synth.name}}
							<span class="label" style="float:right;">{{cluster_synth.datacenter}}
								<span class="badge">Datacenter</span>
							</span>
						</label>
					</h5>
				</div>
				<div class="card-block">
					<div class="clr-row">
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="currentUser.isDashboardT6">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{cluster_synth.co2}} KgCO2 eq</b>
										<br>
										CO2 emission
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="currentUser.isDashboardT6">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{globalCurrency}} {{cluster_synth.cost}}</b>
										<br>
										Cost
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-1 clr-col-md-1 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{cluster_synth.vms}}</b>
										<br>
										<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callVmList()">
											<clr-icon shape="vm"></clr-icon>
											VMs
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-1 clr-col-md-1 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{cluster_synth.hosts}}</b>
										<br>
										<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callHostList()">
											<clr-icon shape="host"></clr-icon>
											Servers
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{cluster_synth.cpucap}}</b>
										<br>
										Logical cores
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{math.round(cluster_synth.ramcap/1024)}} GB</b>
										<br>
										Memory
									</div>
								</div>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
							<div class="card">
								<div class="card-block">
									<div class="card-text">
										<b style="font-size: 20px;">{{math.round(cluster_synth.stocap/1024)}} GB</b>
										<br>
										Storage
										<span class="badge" *ngIf="cluster_synth.vsan">vSAN</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-header card-ajust">
					<h5>
						<clr-icon shape="id-badge"></clr-icon>
						&nbsp;General information
					</h5>
				</div>
				<div class="card-block">
					<div class="clr-row">
						<div class="clr-col-lg-4 clr-col-md-4 clr-col-12" style="padding-left:50px;">
							<label class="clr-control-label">Environment</label>
							<div class="clr-control-container">
								<div class="clr-input-wrapper">
									<input type="text" placeholder="not registered" class="clr-input" [(ngModel)]="comment.ENV" (ngModelChange)="updateEnv($event)">
      								</div>
								<clr-control-helper>where the CLUSTER is running</clr-control-helper>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
							<label class="clr-control-label">DRS</label>
							<div class="clr-control-container">
								<div class="clr-input-wrapper">
									<span class="clr-input" *ngIf="cluster_synth.drs">enabled</span>
									<span class="clr-input" *ngIf="!cluster_synth.drs">disabled</span>
      								</div>
								<clr-control-helper>Distributed Resource scheduler</clr-control-helper>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
							<label class="clr-control-label">DPM</label>
							<div class="clr-control-container">
								<div class="clr-input-wrapper">
									<span class="clr-input" *ngIf="cluster_synth.dpm">enabled</span>
									<span class="clr-input" *ngIf="!cluster_synth.dpm">disabled</span>
      								</div>
								<clr-control-helper>Distributed Power Management</clr-control-helper>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
							<label class="clr-control-label">HA</label>
							<div class="clr-control-container">
								<div class="clr-input-wrapper">
									<span class="clr-input" *ngIf="cluster_synth.ha">enabled</span>
									<span class="clr-input" *ngIf="!cluster_synth.ha">disabled</span>
      								</div>
								<clr-control-helper>High-Availability</clr-control-helper>
							</div>
						</div>
						<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
							<label class="clr-control-label">vSAN</label>
							<div class="clr-control-container">
								<div class="clr-input-wrapper">
									<span class="clr-input" *ngIf="cluster_synth.vsan">enabled</span>
									<span class="clr-input" *ngIf="!cluster_synth.vsan">disabled</span>
      								</div>
								<clr-control-helper>VMware SAN</clr-control-helper>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clr-row">
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
					<ng-template load-t4></ng-template>
				</div>
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
					<div class="card">
						<div class="card-header card-ajust">
							<h5>
								<clr-icon shape="resource-pool"></clr-icon>
								&nbsp;Resources information 
							</h5>
						</div>
						<div class="card-block">
							<clr-alerts>
								<clr-alert [clrAlertType]="cpu_state" [clrAlertSizeSmall]="true" [clrAlertAppLevel]="true" [(clrAlertClosed)]="isNoCpuAlert">
									<clr-alert-item>
										<span id="cluster-alert-text" class="alert-text">
											no alert for the cpu resource
										</span>
									</clr-alert-item>
								</clr-alert>
								<clr-alert [clrAlertType]="ram_state" [clrAlertSizeSmall]="true" [clrAlertAppLevel]="true" [(clrAlertClosed)]="isNoRamAlert">
									<clr-alert-item>
										<span id="cluster-alert2-text" class="alert-text">
											no alert for the ram resource
										</span>
									</clr-alert-item>
								</clr-alert>
								<clr-alert [clrAlertType]="sto_state" [clrAlertSizeSmall]="true" [clrAlertAppLevel]="true" [(clrAlertClosed)]="isNoStoAlert">
									<clr-alert-item>
										<span id="cluster-alert3-text" class="alert-text">
											no alert for the storage resource
										</span>
									</clr-alert-item>
								</clr-alert>
								<clr-alert [clrAlertType]="evolution_state" [clrAlertSizeSmall]="true" [clrAlertAppLevel]="true" [(clrAlertClosed)]="isNoEvoAlert">
									<clr-alert-item>
										<span id="cluster-alert4-text" class="alert-text">
											no evolution
										</span>
									</clr-alert-item>
								</clr-alert>
							</clr-alerts>
							<clr-tabs>
								<clr-tab>
        								<button clrTabLink (click)="setCpuAllocation()">CPU</button>
									<clr-tab-content *clrIfActive="true">
										<div class="clr-row">
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
												<highcharts-chart
													[Highcharts]="Highcharts"
													[constructorType]="'chart'"
													[options]="chartOptions"
													style="width: 100%; height: 100%; display: block;" *ngIf="isReady">
												</highcharts-chart>
											</div>
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
												<form clrForm clrLayout="vertical">
													<div class="clr-input">
														<label>
															CPU commitment
														</label>
      													</div>
													<span class="badge" [ngClass]="cluster_badge.cpu_commitment" style="font-size:12px;">{{math.round(cluster_synth.vcpus*100/cluster_synth.cpucap)}}%</span>
													<clr-control-helper>the overcommitment rate (vcpu/threads)</clr-control-helper>
													</form>
											</div>
										</div>
        								</clr-tab-content>
    								</clr-tab>
								<clr-tab>
        								<button clrTabLink (click)="setRamAllocation()">RAM</button>
									<clr-tab-content *clrIfActive="false">
										<div class="clr-row">
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
												<highcharts-chart
													[Highcharts]="Highcharts"
													[constructorType]="'chart'"
													[options]="chartOptions"
													style="width: 100%; height: 100%; display: block;" *ngIf="isReady">
												</highcharts-chart>
											</div>
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
												<form clrForm clrLayout="vertical">
													<div class="clr-input">
														<label>
															RAM commitment
														</label>
      													</div>
													<span class="badge" [ngClass]="cluster_badge.ram_commitment" style="font-size:12px;">{{math.round(cluster_synth.rams*100/cluster_synth.ramcap)}}%</span>
													<clr-control-helper>the overcommitment rate (vram/ram capacity)</clr-control-helper>
												</form>
											</div>
										</div>
        								</clr-tab-content>
    								</clr-tab>
								<clr-tab>
        								<button clrTabLink (click)="setStoAllocation()">STORAGE</button>
									<clr-tab-content *clrIfActive="false">
										<div class="clr-row">
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
												<highcharts-chart
													[Highcharts]="Highcharts"
													[constructorType]="'chart'"
													[options]="chartOptions"
													style="width: 100%; height: 100%; display: block;" *ngIf="isReady">
												</highcharts-chart>
											</div>
											<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
												<form clrForm clrLayout="vertical">
													<div class="clr-input">
														<label>
															STORAGE commitment
														</label>
      													</div>
													<span class="badge" [ngClass]="cluster_badge.sto_commitment" style="font-size:12px;">{{math.round(cluster_synth.sto_prov/(cluster_synth.stocap/1024))*100}}%</span>
													<clr-control-helper>the overcommitment rate (provisioned to VM/datastore capacity)</clr-control-helper>
												</form>
											</div>
										</div>
        								</clr-tab-content>
    								</clr-tab>
								<clr-tab>
        								<button clrTabLink (click)="setEvolution()">EVOLUTION</button>
									<clr-tab-content *clrIfActive="false">
										<ng-template load-evolution></ng-template>
        								</clr-tab-content>
    								</clr-tab>
							</clr-tabs>
						</div>
					</div>
				</div>
			</div>
        </clr-tab-content>
    </clr-tab>
	<clr-tab>
        <button clrTabLink (click)="setCalCons()">CONSUMPTION PROFILE</button>
        <clr-tab-content *clrIfActive="false">
			<ng-template load-cons-cal></ng-template>
        </clr-tab-content>
	</clr-tab>
	<clr-tab>
        <button clrTabLink (click)="setDistribution()">RESOURCES DISTRIBUTION</button>
        <clr-tab-content *clrIfActive="false">
			<div class="clr-row">
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution2></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution3></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution4></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution5></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution6></ng-template>
				</div>
			</div>
			<div class="clr-row">
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution7></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution8></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution9></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution10></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution11></ng-template>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<ng-template load-cluster-distribution12></ng-template>
				</div>
			</div>
        </clr-tab-content>
	</clr-tab>
    <clr-tab *ngIf="cluster_synth.vsan">
        <button clrTabLink (click)="setVsan()">vSAN</button>
        <clr-tab-content *clrIfActive="false">
		<ng-template load-vsan></ng-template>
        </clr-tab-content>
    </clr-tab>
</clr-tabs>
<clr-modal [(clrModalOpen)]="isModalVmList" [clrModalSize]="'xl'">
	<h3 class="modal-title">The VM list for {{message.currentName}}</h3>
	<div class="modal-body">
		<ng-template load-vm-list></ng-template>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="isModalVmList = false">OK</button>
	</div>
</clr-modal>
<clr-modal [(clrModalOpen)]="isModalHostList" [clrModalSize]="'xl'">
	<h3 class="modal-title">The SERVER list for {{message.currentName}}</h3>
	<div class="modal-body">
		<ng-template load-host-list></ng-template>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="isModalHostList = false">OK</button>
	</div>
</clr-modal>

import {Component, OnDestroy, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {NetscopeService} from '../../services/netscope.service';
import {ClrDatagridSortOrder, ClrDatagridStringFilterInterface} from '@clr/angular';
import {JsonloaderService, LicenseService} from "@app/services";
import {Router} from "@angular/router";
import {environment} from "@environments/environment";

class SourceFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.src_object !== undefined && item.src_object.name !== undefined && item.src_object.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return item.src_address.toUpperCase().indexOf(search.toUpperCase()) !== -1;
  }
}

class DestinationFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.dst_object !== undefined && item.dst_object.name !== undefined && item.dst_object.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return item.dst_address.toUpperCase().indexOf(search.toUpperCase()) !== -1;
  }
}

@Component({
  selector: 'app-netscope-dashboard',
  templateUrl: './netscope-dashboard.component.html',
  styleUrls: ['./netscope-dashboard.component.css']
})
export class NetscopeDashboardComponent implements OnInit, OnDestroy {

  jsonData = undefined;
  subscriptions = [];
  isLoading = false;
  isLoadingGraph = false;
  currentDayTimestamp = undefined;
  dailyTimestamps;
  isFirstDay = false;
  isLastDay = false;

  isNetscopeLicenceEnabled = true;

  Highcharts: typeof Highcharts = Highcharts;
  chartCheckInterval;

  sourceIpFilter = new SourceFilter();
  destinationIpFilter = new DestinationFilter();

  visualisationSettings = {
    only_flows_with_src_and_dst_in_the_filter: false
  };

  // Origin pie chart
  originPieChartOptions: Highcharts.Options = {
    title: {
      text: undefined
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y:.1f} MiB</b>',
    },
    plotOptions: {
      pie: {
        size: "60%",
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          crop: false,
          alignTo: 'connectors'
        }
      }
    },
    series: [{
      data: [{
        name: '',
        y: 0.00,
        sliced: true,
        selected: true
      }, {
        name: '',
        y: 0.00,
      },
      ],
      type: 'pie',
      name: 'Classification',
    }],
    credits: {
      enabled: false
    }
  };
  updateOriginPieChartFlag = false;
  originPieChartRef;

  // Protocol pie chart
  protocolPieChartOptions: Highcharts.Options = {
    title: {
      text: undefined
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y:.1f} MiB</b>'
    },
    plotOptions: {
      pie: {
        size: "60%",
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          crop: false,
          alignTo: 'connectors'
        }
      }
    },
    series: [{
      data: [{
        name: '',
        y: 0.00,
        sliced: true,
        selected: true
      }, {
        name: '',
        y: 0.00
      },
      ],
      type: 'pie',
      name: 'Classification',
    }],
    credits: {
      enabled: false
    }
  };
  updateProtocolPieChartFlag = false;
  protocolPieChartRef;

  // Resource pie chart
  emitterPieChartOptions: Highcharts.Options = {
    title: {
      text: undefined
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y:.1f} MiB</b>'
    },
    plotOptions: {
      pie: {
        size: "60%",
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          crop: false,
          alignTo: 'connectors'
        }
      }
    },
    series: [{
      data: [{
        name: '',
        y: 0.00,
        sliced: true,
        selected: true
      }, {
        name: '',
        y: 0.00
      },
      ],
      type: 'pie',
      name: 'Classification',
    }],
    credits: {
      enabled: false
    }
  };
  updateEmitterPieChartFlag = false;
  emitterPieChartRef;

  // Details datagrid's data

  // Overall network traffic chart's data
  overallTrafficChartOptions: Highcharts.Options = {
    // @ts-ignore
    series: [],
    yAxis: [{
      labels: {
        format: '{value} MiB'
      },
      title: {
        text: 'Volume',
        style: {}
      },
      min: 0
    }, {
      gridLineWidth: 0,
      title: {
        text: 'Packets',
        style: {}
      },
      labels: {
        format: '{value} packets',
        style: {}
      },
      min: 0,
      opposite: true,
    }],
    xAxis: {
      type: 'datetime'
    },
    title: undefined,
    credits: {
      enabled: false
    }
  };
  updateOverallTrafficChartFlag = false;
  overallTrafficChartRef;

  descSort = ClrDatagridSortOrder.DESC;
  flowDetails = [];
  outsideFlowDetails = [];

  amountOfDataProcessed = 0;
  amountOfPacketsProcessed = 0;
  amountOfFlowsProcessed = 0;

  constructor(private netscopeService: NetscopeService, public licenceService: LicenseService, private route:Router, private jsonLoaderService: JsonloaderService) { }

  ngOnInit(): void {
    let subscription1 = this.jsonLoaderService.currentJsonSimpleSubject.subscribe(json => {
      this.jsonData = json;
      this.reloadData();
    });
    let subscription2 = this.jsonLoaderService.eventJsonAsyncLoaded.subscribe(json => {
      this.jsonData = json;
      this.reloadData();
    });
    this.subscriptions = [subscription1, subscription2];

    this.chartCheckInterval = setInterval(() => {
      this.reflowChart(this.originPieChartRef);
      this.reflowChart(this.protocolPieChartRef);
      this.reflowChart(this.emitterPieChartRef);
      this.reflowChart(this.overallTrafficChartRef);
    }, 100);

    this.route.events.subscribe((event) => {
      this.ngOnDestroy();
    });

    this.licenceService.licenseInfo.subscribe(licenceInfo => {
      if (environment.production) {
        // Check second bit to be different than 0
        this.isNetscopeLicenceEnabled = (licenceInfo.moduleslicense & (1 << 1)) !== 0;
      } else {
        this.isNetscopeLicenceEnabled = true;
      }
    });
  }

  ngOnDestroy = () => {
    if (this.chartCheckInterval) {
      clearInterval(this.chartCheckInterval);
    }
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  reflowChart = (chartRef) => {
    if (chartRef !== undefined && chartRef !== null) {
      try {
        chartRef.reflow();
      } catch (e) {
        console.log(e);
        clearInterval(this.chartCheckInterval);
      }
    }
  }

  setPreviousTimestamp = () => {
    let indexOfCurrentTimestamp = this.dailyTimestamps.indexOf(this.currentDayTimestamp);
    indexOfCurrentTimestamp -= 1;
    if (indexOfCurrentTimestamp >= 0) {
      this.currentDayTimestamp = this.dailyTimestamps[indexOfCurrentTimestamp];
      this.reloadData();
    }
  }

  setNextTimestamp = () => {
    let indexOfCurrentTimestamp = this.dailyTimestamps.indexOf(this.currentDayTimestamp);
    indexOfCurrentTimestamp += 1;
    if (indexOfCurrentTimestamp < this.dailyTimestamps.length) {
      this.currentDayTimestamp = this.dailyTimestamps[indexOfCurrentTimestamp];
      this.reloadData();
    }
  }

  reloadData = () => {
    this.isLoading = true;

    let vmsUuidToNameIndex = {};
    for (let vm of this.jsonData.vmSynthesis) {
      vmsUuidToNameIndex[`vim.VirtualMachine:${vm.uuid}`] = vm.name;
    }
    let vmsUuids = this.jsonData.vmSynthesis.map((vm) => vm.uuid)
    let hostsUuidToNameIndex = {};
    for (let host of this.jsonData.hostSynthesis) {
      hostsUuidToNameIndex[`vim.HostSystem:${host.uuid}`] = host.name;
    }
    const hostsUuids = this.jsonData.hostSynthesis.map((vm) => vm.uuid)

    this.isLoading = true;
    const dailyTimestampsSubscription = this.netscopeService.getDailyTimestamps();

    dailyTimestampsSubscription.subscribe((dailyTimestamps) => {
      this.dailyTimestamps = dailyTimestamps.map((v) => v.start_time);
      const minDay = Math.min(...this.dailyTimestamps);
      const maxDay = Math.max(...this.dailyTimestamps);
      if (this.currentDayTimestamp === undefined) {
        let maxTimestamp = maxDay;
        this.currentDayTimestamp = maxTimestamp;
      }
      this.isFirstDay = this.currentDayTimestamp === minDay;
      this.isLastDay = this.currentDayTimestamp === maxDay;

      const dashboardDataObservable = this.netscopeService.getDashboardDataForFilter(vmsUuids, hostsUuids, this.visualisationSettings.only_flows_with_src_and_dst_in_the_filter, this.currentDayTimestamp);
      dashboardDataObservable.subscribe((dashboardData) => {
        // Fix emitters_summary
        for (let emitter of dashboardData["emitters_summary"]) {
          if (vmsUuidToNameIndex.hasOwnProperty(emitter.src_resource_uuid)) {
            emitter["src_address"] = vmsUuidToNameIndex[emitter.src_resource_uuid];
          }
          if (hostsUuidToNameIndex.hasOwnProperty(emitter.src_resource_uuid)) {
            emitter["src_address"] = hostsUuidToNameIndex[emitter.src_resource_uuid];
          }
        }
        // Fix flows_summary and outside_flows_summary
        for (let key of ["flows_summary", "outside_flows_summary"]) {
          for (let flow of dashboardData[key]) {
            for (let object of [flow.src_object, flow.dst_object]) {
              if (object !== undefined) {
                if (vmsUuidToNameIndex.hasOwnProperty(object.uuid)) {
                  object.name = vmsUuidToNameIndex[object.uuid];
                  object.type = "vm";
                } else if (hostsUuidToNameIndex.hasOwnProperty(object.uuid)) {
                  object.name = hostsUuidToNameIndex[object.uuid];
                  object.type = "host";
                } else {
                  if (object.uuid.indexOf("vim.VirtualMachine:") !== -1) {
                    object.type = "vm";
                  }
                  if (object.uuid.indexOf("vim.HostSystem:") !== -1) {
                    object.type = "host";
                  }
                }
              }
            }
          }
        }
        // Send data to functions that update the UI
        this.reloadUi(dashboardData);
        // @ts-ignore
        this.amountOfDataProcessed = dashboardData.internal_traffic_sum + dashboardData.external_traffic_sum;
        // @ts-ignore
        this.amountOfFlowsProcessed = dashboardData.processed_flows_count;
        // @ts-ignore
        this.amountOfPacketsProcessed = dashboardData.protocols_summary
            .map((p) => p.exchanged_packets)
            .reduce((a, b) => a + b, 0);
        this.isLoading = false;
      });
    });

    this.isLoadingGraph = true;
    const dashboardGraphDataForFilterObservable = this.netscopeService.getDashboardGraphDataForFilter(vmsUuids, hostsUuids, this.visualisationSettings.only_flows_with_src_and_dst_in_the_filter);
    dashboardGraphDataForFilterObservable.subscribe((dashboardGraphDataForFilter) => {
      this.updateGraphData(dashboardGraphDataForFilter);
      this.isLoadingGraph = false;
    });
    // });
  }

  reloadUi = (dashboardData) => {
    this.updateInterIntraTrafficPieChart(dashboardData.internal_traffic_sum, dashboardData.external_traffic_sum);
    this.updateProtocolPieChart(dashboardData.protocols_summary);
    this.updateEmitterPieChart(dashboardData.emitters_summary);
    this.updateTrafficDetails(dashboardData.flows_summary, dashboardData.outside_flows_summary);
  }

  updateInterIntraTrafficPieChart = (internalTrafficSum, externalTrafficSum) => {
    // @ts-ignore
    this.originPieChartOptions.plotOptions.pie.dataLabels.enabled = true;
    // @ts-ignore
    this.originPieChartOptions.series = [{
      data: [{
        name: 'Intra datacenter',
        y: (1.0 * internalTrafficSum) / (1024 * 1024),
        sliced: true,
        selected: true
      }, {
        name: 'Inter datacenter',
        y: (1.0 * externalTrafficSum) / (1024 * 1024),
      },
      ],
      type: 'pie',
      name: 'Classification',
    }];
    this.updateOriginPieChartFlag = true;
  }

  updateProtocolPieChart = (protocolsSummary) => {
    // @ts-ignore
    this.protocolPieChartOptions.plotOptions.pie.dataLabels.enabled = true;
    // @ts-ignore
    this.protocolPieChartOptions.series = [{
      data: protocolsSummary.map((pSummary) => {
        return {
          name: pSummary.protocol,
          y: (1.0 * pSummary.exchanged_bytes) / (1024 * 1024),
        };
      }),
      type: 'pie',
      name: 'Classification',
    }];
    this.updateProtocolPieChartFlag = true;
  }

  updateEmitterPieChart = (emittersSummary) => {
    // @ts-ignore
    this.emitterPieChartOptions.plotOptions.pie.dataLabels.enabled = true;
    const sortedEmittersSummary = emittersSummary.sort((p1, p2) => p2.exchanged_bytes - p1.exchanged_bytes).slice(0, 20);
    const data = sortedEmittersSummary.map((pSummary) => {
      const label = pSummary.src_address;
      return {
        name: label,
        y: (1.0 * pSummary.exchanged_bytes) / (1024 * 1024),
      };
    });
    // @ts-ignore
    this.emitterPieChartOptions.series = [{
      data: data,
      type: 'pie',
      name: 'Classification',
    }];
    this.updateEmitterPieChartFlag = true;
  }

  updateTrafficDetails = (flowsSummary, outsideFlowsSummary) => {
    this.flowDetails = flowsSummary;
    this.outsideFlowDetails = outsideFlowsSummary;
  }

  updateGraphData = (graphData) => {
    this.overallTrafficChartOptions.series = [];
    this.overallTrafficChartOptions.series.push({
      type: 'line',
      yAxis: 0,
      name: `Volume (MiB)`,
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y} MiB</b>'
      },
      pointInterval: 24 * 3600 * 1000,
      data: graphData.map((d) => [d.time * 1000, Math.round(d.exchanged_bytes / (1024 * 1024))]),
      showInNavigator: true
    });
    this.overallTrafficChartOptions.series.push({
      type: 'line',
      yAxis: 1,
      name: `Packets count`,

      tooltip: {
        pointFormat: '{series.name}: <b>{point.y} packets</b>'
      },
      pointInterval: 24 * 3600 * 1000,
      data: graphData.map((d) => [d.time * 1000, d.exchanged_packets]),
      showInNavigator: true
    });
    this.updateOverallTrafficChartFlag = true;
  }

  callbackOriginPieChartRef = (ref) => {
    this.originPieChartRef = ref;
  }

  callbackProtocolPieChartRef = (ref) => {
    this.protocolPieChartRef = ref;
  }

  callbackResourcePieChartRef = (ref) => {
    this.emitterPieChartRef = ref;
  }

  callbackOverallTrafficChartRef = (ref) => {
    this.overallTrafficChartRef = ref;
  }

  /**
   * This method export the data table into a CSV file
   */
  exportCSV() {
    let csvHeader = "SourceIp, SourceName, DestinationIp, DestinationName, ExchangedBytes, ExchangedPackets, MatchingFlow"
    let csvContent = csvHeader+"\n";

    for (let flowDetail of this.flowDetails) {
      let src_addr = flowDetail.src_address;
      let src_name = (flowDetail.src_object !== undefined && flowDetail.src_object.name !== undefined) ? flowDetail.src_object.name: "unknown";
      let dst_addr = flowDetail.dst_address;
      let dst_name = (flowDetail.dst_object !== undefined && flowDetail.dst_object.name !== undefined) ? flowDetail.dst_object.name: "unknown";
      let matching_flow = true;
      let lineValue = `${src_addr}, ${src_name}, ${dst_addr}, ${dst_name}, ${flowDetail.exchanged_bytes}, ${flowDetail.exchanged_packets}, ${matching_flow}\n`;
      csvContent += lineValue;
    }
    for (let flowDetail of this.outsideFlowDetails) {
      let src_addr = flowDetail.src_address;
      let src_name = (flowDetail.src_object !== undefined && flowDetail.src_object.name !== undefined) ? flowDetail.src_object.name: "unknown";
      let dst_addr = flowDetail.dst_address;
      let dst_name = (flowDetail.dst_object !== undefined && flowDetail.dst_object.name !== undefined) ? flowDetail.dst_object.name: "unknown";
      let matching_flow = false;
      let lineValue = `${src_addr}, ${src_name}, ${dst_addr}, ${dst_name}, ${flowDetail.exchanged_bytes}, ${flowDetail.exchanged_packets}, ${matching_flow}\n`;
      csvContent += lineValue;
    }

    let exportedFilename = 'netscope-dashboard.csv';
    let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  resolveDomain = (flowDetail, srcOrDst) => {
    let ip = srcOrDst === "src" ? flowDetail.src_address : flowDetail.dst_address;
    this.netscopeService.getDomainResolution(ip).subscribe((domainResult) => {
      if (srcOrDst === "src") {
        // @ts-ignore
        flowDetail.src_ip_resolved = domainResult.status === "ok" ? domainResult.result : "not resolved";
        // @ts-ignore
        flowDetail.src_ip_resolved_class = domainResult.status === "ok" ? "info" : "danger";
      } else {
        // @ts-ignore
        flowDetail.dst_ip_resolved = domainResult.status === "ok" ? domainResult.result : "not resolved";
        // @ts-ignore
        flowDetail.dst_ip_resolved_class = domainResult.status === "ok" ? "info" : "danger";
      }
    })
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NetscopeSettings} from "@app/model";
import {environment} from "@environments/environment";
import {map, mergeMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NetscopeService {

  constructor(private http: HttpClient) { }

  generateHeaders() {
    // const username = sessionStorage.getItem('username');
    // const password = sessionStorage.getItem('password');
    // const header = {
    //   'Authorization': 'Basic ' + btoa(`${username}:${password}`)
    // };
    // return header;
    return {};
  }

  getNetscopeSettings() {
    return this.http.get<NetscopeSettings[]>(`${environment.apiUrl}/` + 'management/getnetscopesettings/').pipe(map(settings => {
      return settings;
    }));
  }

  updateNetscopeSettings(settings: JSON) {
    return this.http.post<NetscopeSettings[]>(`${environment.apiUrl}/` + 'management/updatenetscopesettings/', settings);
  }

  public getClustersWithFilters(startTimeInterval, endTimeInterval, vmsUuids, hostsUuids, only_flows_with_src_and_dst_in_the_filter, timeResolution="daily") {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/clusters/compute_vms_clusters?startTimeInterval=${startTimeInterval}&endTimeInterval=${endTimeInterval}&timeResolution=${timeResolution}`;
      return this.http.post<any[]>(url, {
        vmsUuids: vmsUuids,
        hostsUuids: hostsUuids,
        only_flows_with_src_and_dst_in_the_filter: only_flows_with_src_and_dst_in_the_filter
      }, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getLastInfrastructureVersion() {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/infrastructure/version/last`;
      return this.http.get<any[]>(url, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getFlowDetails(startTimeInterval, endTimeInterval, srcIp, dstIp, epochDate, timeResolution="daily", bidirectional=false) {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/flow/details?startTimeInterval=${startTimeInterval}&endTimeInterval=${endTimeInterval}`;
      return this.http.post<any[]>(url, {
        srcIp: srcIp,
        dstIp: dstIp,
        epochDate: epochDate,
        timeResolution: timeResolution,
        bidirectional: bidirectional,
        headers: this.generateHeaders()
      });
    }));
  }

  public getInfrastructureVersionsBetween(startTimeInterval, endTimeInterval, vmsUuids, hostsUuids) {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/infrastructure/version/between?startTimeInterval=${startTimeInterval}&endTimeInterval=${endTimeInterval}`;
      return this.http.post<any[]>(url, {
        vmsUuids: vmsUuids,
        hostsUuids: hostsUuids,
        headers: this.generateHeaders()
      });
    }));
  }

  public getDailyTimestamps() {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/networking/visualisation/timeranges/daily`;
      return this.http.get<any[]>(url, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getHourlyTimestamps() {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/networking/visualisation/timeranges/hourly`;
      return this.http.get<any[]>(url, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getDashboardData() {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/dashboard/data`;
      return this.http.get<any[]>(url, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getDashboardDataForFilter(vmsUuids, hostsUuids, only_flows_with_src_and_dst_in_the_filter, maxTimestamp) {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/dashboard/data_for_filter`;
      return this.http.post<any[]>(url, {
        vmsUuids: vmsUuids,
        hostsUuids: hostsUuids,
        only_flows_with_src_and_dst_in_the_filter: only_flows_with_src_and_dst_in_the_filter,
        day: maxTimestamp
      }, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getDashboardGraphData() {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/dashboard/graph_summary_data`;
      return this.http.get<any[]>(url, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getDashboardGraphDataForFilter(vmsUuids, hostsUuids, only_flows_with_src_and_dst_in_the_filter) {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/dashboard/graph_summary_data_for_filter`;
      return this.http.post<any[]>(url, {
        vmsUuids: vmsUuids,
        hostsUuids: hostsUuids,
        only_flows_with_src_and_dst_in_the_filter: only_flows_with_src_and_dst_in_the_filter
      }, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getDomainResolution(ip) {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/resolve_domain`;
      return this.http.post<any[]>(url, {
        srcIp: ip,
      }, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getHeatmapFlowsCombinations() {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/heatmap/get_flows_combinations`;
      return this.http.post<any[]>(url, {
      }, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getHeatmapFlowsData(srcUuid, dstUuid) {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/heatmap/get_flow_data`;
      return this.http.post<any[]>(url, {
        srcUuid: srcUuid,
        dstUuid: dstUuid,
      }, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getHeatmapFlowsDetails(srcUuid, dstUuid, timestamp) {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/heatmap/get_flow_details`;
      return this.http.post<any[]>(url, {
        srcUuid: srcUuid,
        dstUuid: dstUuid,
        timestamp: timestamp,
      }, {
        headers: this.generateHeaders()
      });
    }));
  }

  public getCustomProtocolsRules() {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/custom_protocols/rules/fetch`;
      return this.http.post<any[]>(url, {
      }, {
        headers: this.generateHeaders()
      });
    }));
  }

  public pushCustomProtocols(custom_rules) {
    return this.getNetscopeSettings().pipe(mergeMap(settings => {
      let agentUrl = "http://localhost:5000";
      settings.filter((setting) => setting.option === "agent_url").map((setting) => {
        agentUrl = setting.value;
      })
      const url = `${environment.apiUrl}/v1/netscope/api/forward/custom_protocols/rules/push`;
      return this.http.post<any[]>(url, {
        custom_rules: custom_rules,
      }, {
        headers: this.generateHeaders()
      });
    }));
  }
}

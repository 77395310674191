import {CloudService} from './cloud.service';

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class CloudCarbonService {

	constructor(private http: HttpClient, private cloudService: CloudService) {
	}

	fetchLastUpdateDetails() {
		let cloudPricingDetailsUrl = `${environment.apiUrl}/v1/cloudcarbon/lastupdatedetails`;
		return this.http.get(cloudPricingDetailsUrl, {});
	}

	askToFetchNewEmissionsData() {
		return new Observable((observer) => {
			this.http.get("https://www.easyvirt.com/downloadseasyvirt/emissions.tar.bz2.enc", { responseType: 'blob' }).subscribe((archiveBlob) => {
				console.log("plop");

				var b: any = archiveBlob;
				//A Blob() is almost a File() - it's just missing the two properties below which we will add
				b.lastModifiedDate = new Date();
				b.name = "emissions.tar.bz2.enc";

				//Cast to a File() type
				let archiveFile = <File>archiveBlob;

				let cloudPricingDetailsUrl = `${environment.apiUrl}/v1/cloudcarbon/fetchnewemissions`;
				let formData: FormData = new FormData();
				formData.append('file_upload', archiveFile, archiveFile.name);

				this.http.post<any>(cloudPricingDetailsUrl, formData).subscribe((value) => {
					console.log("plop2");
					observer.next(value);
					observer.complete();
				})
			});
		});
	}

	// Computes the emissions of all the VMs.
	computeProviderEmissions(vmsRaw, vmConsover, cloudCarbonData, cloudPricingData, onPremiseCarbon, selectedProviderRegion, period) {
		// Retrieves the average cpu charge from the vmConsover data and merges it with the VMs data.
		for (let vm of vmConsover) {
			let uuid = vm['uuid'];
			let associatedVm = vmsRaw[uuid];
			associatedVm.cpuCharge = vm.avgcpu;
		}

		// Formats the VMs data and select only the emissions for the selected region to save compute time.
		let localVms = Object.entries(vmsRaw).map(([k, v]) => Object.assign(v, {'uuid': k}));
		let regionalEmissions = cloudCarbonData[selectedProviderRegion]['instance_types'];


		// Filtering cloud pricing data to remove instances types not included in cloud carbon data.
		let avaliableInstancesTypes = Object.keys(cloudCarbonData[Object.keys(cloudCarbonData)[0]]['instance_types']);
		let cloudPricingDataCopy = JSON.parse(JSON.stringify(cloudPricingData));
		cloudPricingDataCopy.computeTypes = Object.fromEntries(Object.entries(cloudPricingData.computeTypes).filter(([instanceType, data]) => avaliableInstancesTypes.indexOf(instanceType) !== -1));

		// Computes the data for each VM.
		let vmInfo = [];
		let periodFactor = period == 'month' ? 730 : 8760;
		let computeEmissions = 0;
		let greyEmissions = 0;
		let totalEmissions = 0;

		let sortedInstanceTypesCache = {};

		let vmNumber = 0;
		for (let vm of localVms) {
			let instanceMatch = this.cloudService.instanceMatch(vm, selectedProviderRegion, cloudPricingDataCopy, sortedInstanceTypesCache);
			let instance = instanceMatch['instanceTypeKey'];

			vmInfo.push({
				'name': vm['name'],
				'compute': instanceMatch['instanceTypeKey'],
				'computeEmissions': 0,
				'greyEmissions': 0,
				'totalEmissions': 0,
				'savings': 0,
				'operatingSystem': cloudPricingDataCopy.os[instanceMatch['osKey']],
				'vcpu': vm['vcpu'],
				'memory': vm['memory'],
				'debug': {
					title: 'Computing the carbon emissions',
					explanations: [
						'Something wrong happened'
					],
				},
			});

			if (instance !== undefined) {
				// This if-statement obeys the following naming scheme :
				// xxxEmission (singular) means the emission for an hour of uptime
				// xxxEmissions (plural) means the emission for the chosen amount of time (after the periodFactor has been applied)
				let instanceEmissions = regionalEmissions[instance]['cpu_architectures'][regionalEmissions[instance]['lowest_cpu_architecture']];
				let cpuConsumptionMin = instanceEmissions['cpu_consumption_min'];
				let cpuConsumptionMax = instanceEmissions['cpu_consumption_max'];
				let storageConsumption = instanceEmissions['storage_consumption'];
				let memoryConsumption = instanceEmissions['memory_consumption'];
				let cpuEmissionMin = instanceEmissions['cpu_co2_min'];
				let cpuEmissionMax = instanceEmissions['cpu_co2_max'];
				let greyEmission = instanceEmissions['grey_emission'];
				let memoryEmission = instanceEmissions['memory_co2'];
				let storageEmission = instanceEmissions['storage_co2'];

				let cpuCharge = vm['cpuCharge'] === undefined ? 0 : vm['cpuCharge'] / 100;

				let cpuEmission = cpuEmissionMin + (cpuEmissionMax - cpuEmissionMin) * cpuCharge;
				computeEmissions += (cpuEmission + memoryEmission + storageEmission) * periodFactor;
				greyEmissions += greyEmission * periodFactor;
				totalEmissions += (cpuEmission + memoryEmission + storageEmission + greyEmission) * periodFactor;

				vmInfo[vmNumber]['computeEmissions'] = (cpuEmission + memoryEmission + storageEmission) * periodFactor;
				vmInfo[vmNumber]['greyEmissions'] = greyEmission * periodFactor;
				vmInfo[vmNumber]['totalEmissions'] = (cpuEmission + memoryEmission + storageEmission + greyEmission) * periodFactor;

				let regionalEmissionFactor = (cpuEmissionMin / cpuConsumptionMin); // Ton C02.eq / wH
				let regionalEmissionFactorInGramPerKWattHour = regionalEmissionFactor * (1000 * 1000);
				let cpuEmissionMinGramPerHour = cpuConsumptionMin * regionalEmissionFactorInGramPerKWattHour / (1000);
				let cpuEmissionMaxGramPerHour = cpuConsumptionMax * regionalEmissionFactorInGramPerKWattHour / (1000);
				let greyEmissionInGramPerHour = greyEmission * (1000);
				let storageEmissionGramPerHour = storageConsumption * regionalEmissionFactorInGramPerKWattHour / (1000);
				let memoryEmissionGramPerHour = memoryConsumption * regionalEmissionFactorInGramPerKWattHour / (1000);

				let averageCpuEmissionsPerHour = cpuEmissionMinGramPerHour + (cpuEmissionMaxGramPerHour - cpuEmissionMinGramPerHour) * cpuCharge;
				// let memoryEmissionGramPerHour = memoryEmission * (1000);
				// let storageEmissionGramPerHour = storageEmission * (1000);
				let averageEmissionsPerHour = averageCpuEmissionsPerHour + greyEmissionInGramPerHour+ memoryEmissionGramPerHour + storageEmissionGramPerHour;
				let totalEmission = averageEmissionsPerHour * periodFactor;

				let parametersTableHtml = 'The vm has the following parameters: <table class="table">'+
					'<tr><th class="left">Term</th><th class="left">Result</th></tr>'+
					`<tr><td class="left">regional emssion factor</td><td class="left">${ regionalEmissionFactorInGramPerKWattHour.toFixed(2) }g CO2.eq per KWh</td></tr>`+
					`<tr><td class="left">minimal consumption / emission</td><td class="left">${ cpuConsumptionMin.toFixed(2) }W per hour <u>or</u> ${ cpuEmissionMinGramPerHour.toFixed(2) }g C02.eq per hour</td></tr>`+
					`<tr><td class="left">maximal consumption / emission</td><td class="left">${ cpuConsumptionMax.toFixed(2) }W per hour <u>or</u> ${ cpuEmissionMaxGramPerHour.toFixed(2) }g C02.eq per hour</td></tr>`+
					`<tr><td class="left">cpuLoad</td><td class="left">${ (cpuCharge * 100).toFixed(1) }%</td></tr>`+
					`<tr><td class="left"><span class="label">cpu</span> emission per hour</td><td class="left">${ averageCpuEmissionsPerHour.toFixed(2) }g C02.eq per hour</td></tr>`+
					`<tr><td class="left"><span class="label">memory</span> emission per hour</td><td class="left">${ memoryEmissionGramPerHour.toFixed(2) }g C02.eq per hour</td></tr>`+
					`<tr><td class="left"><span class="label">storage</span> emission per hour</td><td class="left">${ storageEmissionGramPerHour.toFixed(2) }g C02.eq per hour</td></tr>`+
					`<tr><td class="left"><span class="label">embodied</span> emission per hour</td><td class="left">${ greyEmissionInGramPerHour.toFixed(2) }g C02.eq per hour</td></tr>`+
					`<tr><td class="left"><span class="label">cpu</span> + <span class="label">memory</span> + <span class="label">storage</span> + <span class="label">embodied</span></td><td class="left">${ averageEmissionsPerHour.toFixed(2) }g C02.eq per hour</td></tr>`+
					`<tr><td class="left"><span class="label">cpu</span> + <span class="label">memory</span> + <span class="label">storage</span> + <span class="label">embodied</span> x ${periodFactor} hours (1 ${period})</td><td class="left">${ totalEmission.toFixed(2) }g C02.eq per ${period}</td></tr>`+
					'</table><br></br>';

				vmInfo[vmNumber]['debug']['explanations'] = [
					`${parametersTableHtml}`,
				];

				// Computes the savings for each vm
				for (let server of onPremiseCarbon) {
					for (let onPrVm of server.VMS) {
						if (onPrVm.ID === vm.uuid)
							vmInfo[vmNumber]['savings'] = (Number(onPrVm.CO2) + Number(onPrVm.CO2G)) - vmInfo[vmNumber]['totalEmissions'];
					}
				}
			}
			vmNumber++;
		}


		return {
			'computeEmissions': computeEmissions,
			'greyEmissions': greyEmissions,
			'totalEmissions': totalEmissions,
			'listedVMs': vmInfo,
			'faultyVMs': []
		};
	}

	// Fetch providers data from the API.
	fetchProviderDetails(providerName) {
		let cloudCarbonDetailsUrl = `${environment.apiUrl}/v1/cloudcarbon/provider/${providerName}/details`;
		return this.http.get(cloudCarbonDetailsUrl, {});
	}
}

<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon [attr.shape]="currentUser.currency == '8364' ? 'euro' : 'dollar'"></clr-icon>
			&nbsp;Cost 
		</h5>
	</div>
	<div class="card-block" id="dashboard-t6-core">
		<div class="card-text" *ngIf="isReady" style="width: 100%; height: 250px; display: block;">
			<div class="clr-row" >
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<div class="card">
						<div class="card-block">
							<div class="progress top">
								<progress value="100" max="100"></progress>
                        	</div>
							<div class="card-text addLink" (click)="loadDetail('allocated')">
								<span class="keyData" style="float:right">
									{{cost.allocated}}
								</span>
								Allocated cost
                        	</div>
                        </div>
                    </div>
                </div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<div class="card">
						<div class="card-block">
							<div class="progress top success">
								<progress value="{{cost.consumed_per}}" max="100"></progress>
                        	</div>
							<div class="card-text addLink" (click)="loadDetail('consumed')">
								<span class="keyData"style="float:right">
									{{cost.consumed}}
								</span>
								Consumed cost
                        	</div>
                        </div>
                    </div>
                </div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<div class="card">
						<div class="card-block">
							<div class="progress top danger">
								<progress value="{{cost.wasted_per}}" max="100"></progress>
                        	</div>
							<div class="card-text addLink" (click)="loadDetail('wasted')">
								<span class="keyData" style="float:right">
									{{cost.wasted}}
								</span>
								Wasted cost
                        	</div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
		<div *ngIf="!isReady">
			<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
		</div>
    </div>
</div>
<clr-modal [(clrModalOpen)]="isModalCost" [clrModalSize]="'xl'">
	<div class="modal-title">
		<h3>
			Cost detail for {{message.t6Detail}} resources
		</h3>
	</div>
	<div class="modal-body">
		<ng-template load-t6-detail></ng-template>
	</div>
    <div class="modal-footer">
	    <button type="button" class="btn btn-primary" (click)="isModalCost = false">OK</button>
    </div>
</clr-modal>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SelectionviewComponent } from './selectionview/selectionview.component';
import { PeriodviewComponent } from './periodview/periodview.component';
import { FiltermgtComponent } from './filtermgt/filtermgt.component';
import { ClustersynthesisComponent } from './clustersynthesis/clustersynthesis.component';
import { DcviewComponent } from './dcview/dcview.component';
import { DcviewFlowchartComponent } from './dcview/dcview-flowchart/dcview-flowchart.component';
import { HostsynthesisComponent } from './hostsynthesis/hostsynthesis.component';
import { HostinfoComponent } from './hostinfo/hostinfo.component';
import { HostconsoverComponent } from './hostconsover/hostconsover.component';
import { VmsynthesisComponent } from './vmsynthesis/vmsynthesis.component';
import { VminfoComponent } from './vminfo/vminfo.component';
import { VmconsoverComponent } from './vmconsover/vmconsover.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from '@app/_helpers';
import { GreenitComponent } from './greenit/greenit.component';
import { PowerComponent } from './greenit/power/power.component';
import { CapaplanComponent } from './capaplan/capaplan.component';
import { ReportComponent } from './report/report.component';
import { AlertingComponent } from './alerting/alerting.component';
import { CloudComponent } from './cloud/cloud.component';
import { CloudimpactComponent } from './cloudimpact/cloudimpact.component';
import { MycloudpricingComponent } from "@app/mycloudpricing/mycloudpricing.component";
import { GraphOnDemandComponent } from "@app/graph-on-demand/graph-on-demand.component";
import { LicenseComponent } from './license/license.component';
import { TroubleshootingComponent } from './troubleshooting/troubleshooting.component';
import { StorageOverviewComponent } from "@app/storage-overview/storage-overview.component";
import { StorageOverviewVmComponent } from "@app/storage-overview/storage-overview-vm/storage-overview-vm.component";
import { RecommendationComponent } from './recommendation/recommendation.component';
import { KpiComponent } from './kpi/kpi.component';
import { UsermgtComponent } from './usermgt/usermgt.component';
import { CostmgtComponent } from './costmgt/costmgt.component';
import { RpinfoComponent } from './rpinfo/rpinfo.component';
import { RpsynthesisComponent } from './rpsynthesis/rpsynthesis.component';
import { ClusterinfoComponent } from './clusterinfo/clusterinfo.component';
import { VcloudComponent } from './vcloud/vcloud.component';
import { VirtIndexComponent } from './virtIndex/virtIndex.component';
import { ProviderComponent } from './provider/provider.component';
import { AwsComponent } from './provider/aws/aws.component';
import { AzureComponent } from './provider/azure/azure.component';
import { VmsnapshotComponent } from './vmsnapshot/vmsnapshot.component';
import { AnalysisComponent } from './greenit/analysis/analysis.component';
import { NetscopeDashboardComponent } from './netscope/netscope-dashboard/netscope-dashboard.component';
import { NetscopeTopologyComponent } from './netscope/netscope-topology/netscope-topology.component';
import { NetscopeDependenciesViewerComponent } from './netscope/netscope-dependencies-viewer/netscope-dependencies-viewer.component';
import { NetscopeProtocolAnalysisComponent } from './netscope/netscope-protocol-analysis/netscope-protocol-analysis.component';
import { NetscopeClustersViewerComponent } from './netscope/netscope-clusters-viewer/netscope-clusters-viewer.component';
import { NetscopeHeatmapViewerComponent } from './netscope/netscope-heatmap-viewer/netscope-heatmap-viewer.component';
import {
	NetscopeCustomProtocolsComponent
} from "@app/netscope/netscope-custom-protocols/netscope-custom-protocols.component";


const routes: Routes = [
	{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
	{ path: 'selectionview', component: SelectionviewComponent, canActivate: [AuthGuard] },
	{ path: 'periodview', component: PeriodviewComponent, canActivate: [AuthGuard] },
	{ path: 'filter', component: FiltermgtComponent, canActivate: [AuthGuard] },
	{ path: 'clusterviews/synthesis', component: ClustersynthesisComponent, canActivate: [AuthGuard] },
	{ path: 'dcviews/treemap', component: DcviewComponent, canActivate: [AuthGuard] },
	{ path: 'dcviews/flowdiag', component: DcviewFlowchartComponent, canActivate: [AuthGuard] },
	{ path: 'hostviews/synthesis', component: HostsynthesisComponent, canActivate: [AuthGuard] },
	{ path: 'hostviews/information', component: HostinfoComponent, canActivate: [AuthGuard] },
	{ path: 'hostviews/consumption', component: HostconsoverComponent, canActivate: [AuthGuard] },
	{ path: 'vmviews/synthesis', component: VmsynthesisComponent, canActivate: [AuthGuard] },
	{ path: 'vmviews/information', component: VminfoComponent, canActivate: [AuthGuard] },
	{ path: 'vmviews/consumption', component: VmconsoverComponent, canActivate: [AuthGuard] },
	{ path: 'login', component: LoginComponent },
	{ path: 'greenit/score', component: GreenitComponent, canActivate: [AuthGuard] },
	{ path: 'greenit/power', component: PowerComponent, canActivate: [AuthGuard] },
	{ path: 'greenit/analysis', component: AnalysisComponent, canActivate: [AuthGuard] },
	{ path: 'capacity', component: CapaplanComponent, canActivate: [AuthGuard] },
	{ path: 'reporting', component: ReportComponent, canActivate: [AuthGuard] },
	{ path: 'cloudimpact', component: CloudimpactComponent, canActivate: [AuthGuard] },
	{ path: 'cloud/:provider/:region', component: CloudComponent, canActivate: [AuthGuard] },
	{ path: 'my_cloud_pricing/edit', component: MycloudpricingComponent, canActivate: [AuthGuard] },

	{ path: 'god', component: GraphOnDemandComponent, canActivate: [AuthGuard] },
	{ path: 'god/resource/:resource_uuid', component: GraphOnDemandComponent, canActivate: [AuthGuard] },
	{ path: 'alerting', component: AlertingComponent, canActivate: [AuthGuard] },
	{ path: 'license', component: LicenseComponent },
	{ path: 'troubleshooting', component: TroubleshootingComponent, canActivate: [AuthGuard] },
	{ path: 'recommendation', component: RecommendationComponent, canActivate: [AuthGuard] },
	{ path: 'kpi', component: KpiComponent, canActivate: [AuthGuard] },
	{ path: 'cost', component: CostmgtComponent, canActivate: [AuthGuard] },
	{ path: 'user', component: UsermgtComponent, canActivate: [AuthGuard] },
	{ path: 'alerting', component: AlertingComponent, canActivate: [AuthGuard] },
	{ path: 'storage-overview', component: StorageOverviewComponent, canActivate: [AuthGuard] },
	{ path: 'storage-overview-vm', component: StorageOverviewVmComponent, canActivate: [AuthGuard] },
	{ path: 'rpviews/information', component: RpinfoComponent, canActivate: [AuthGuard] },
	{ path: 'rpviews/synthesis', component: RpsynthesisComponent, canActivate: [AuthGuard] },
	{ path: 'clusterviews/information', component: ClusterinfoComponent, canActivate: [AuthGuard] },
	{ path: 'vcloud', component: VcloudComponent, canActivate: [AuthGuard] },
	{ path: 'greenindex', component: VirtIndexComponent, canActivate: [AuthGuard] },
	{ path: 'provider', component: ProviderComponent, canActivate: [AuthGuard] },
	{ path: 'provider/aws', component: AwsComponent, canActivate: [AuthGuard] },
	{ path: 'provider/azure', component: AzureComponent, canActivate: [AuthGuard] },
	{ path: 'storage-overview-snapshot', component: VmsnapshotComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/dashboard', component: NetscopeDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/topology', component: NetscopeTopologyComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/dependencies-viewer', component: NetscopeDependenciesViewerComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/dependencies-viewer/focuson/:resource_uuids', component: NetscopeDependenciesViewerComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/protocol-analysis', component: NetscopeProtocolAnalysisComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/clusters-viewer', component: NetscopeClustersViewerComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/clusters-viewer/focuson/:resource_uuids', component: NetscopeClustersViewerComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/heatmap-viewer', component: NetscopeHeatmapViewerComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/heatmap-viewer/:resource_uuid_1/:resource_uuid_2', component: NetscopeHeatmapViewerComponent, canActivate: [AuthGuard] },
	{ path: 'netscope/custom-protocols', component: NetscopeCustomProtocolsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, Input, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';

//For jest ...
import ChartModuleMore from 'highcharts/highcharts-more.js';
ChartModuleMore(Highcharts);


@Component({
  selector: 'app-gaugechart',
  templateUrl: './gaugechart.component.html',
  styleUrls: ['./gaugechart.component.css']
})
export class GaugechartComponent implements OnInit {

  @Input() data: any;

  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;
    this.update();
  };

  options: Highcharts.Options = {
    credits: { enabled: false },
    chart: {
      height: '220px',
      backgroundColor: 'rgba(255, 255, 255, 0.0)',
      marginTop: 120,
      spacing:[0, 0, 0, 0],  
    },
    exporting: {
      enabled: false
    },
    time: {
      useUTC: false
    },
    title: {
      text: ''
    },
    xAxis: {
      minPadding: 0,
      maxPadding: 0
    },
    yAxis: {
      min: 0,
      max: 100,
      labels: {
        enabled: false
      },
      lineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      tickWidth: 0
    },
    tooltip: { 
      enabled: false
    },
    pane: {
      size: '120%', // size of dial
      startAngle: -90,
      endAngle: 90,
      background: [{
        borderWidth: 0,
        backgroundColor: 'none',
        //innerRadius: '60%',
        //outerRadius: '100%',
        //shape: 'arc'
      }]
    },
    plotOptions: {
      pie: {
        size: '350%',
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          enabled: true,
          distance: -30,
          style: {
            fontWeight: 'bold',
            color: 'white',
            textShadow: '0px 1px 2px black'
          }
        },
        enableMouseTracking: false,
      },
      gauge: {
        dataLabels: {
          enabled: false
        },
        dial: {
          //radius: '100%'
        },
        enableMouseTracking: false,
      }
    },
    series: [{
      type: 'pie',
      innerSize: '50%',
      data: [
        { name: 'A',
          y: 14.29,
          color: '#006600'
        },
        { name: 'B',
          y: 14.29,
          color: '#339933'
        },
        { name: 'C',
          y: 14.29,
          color: '#00b300'
        },
        { name: 'D',
          y: 14.29,
          color: '#ffe600'
        },
        { name: 'E',
          y: 14.29,
          color: '#ff9900'
        },
        { name: 'F',
          y: 14.29,
          color: '#e63900'
        },
        { name: 'G',
          y: 14.29,
          color: '#cc3300'
        }
      ]
    },{
      type: 'gauge',
      data: [0],
      dial: {
        rearLength: '0'
      }
    }],
  };

  /**
   * 
   */
  constructor() { }

  /**
   * 
   */
  ngOnInit(): void { }

  /**
   *
   */
  private update(): void {
    if(this.chart.series[1] != undefined)
      this.chart.series[1].remove();

     if(this.data) {
      
      // Push to chart
      this.chart.addSeries({
        type: 'gauge',
        data: [this.data.top],
        dial: {
          rearLength: '0'
        }
      });

       // Update chart title
      this.addTitle();
    }
  }

  /**
   * 
   */
  private addTitle(): void  {
    // Add title
    this.chart.renderer.text(this.data.name, 20, 20)
      .attr({
        zIndex: 5
      })
      .css({
        color: 'black',
        fontSize: '12px',
        fontWeight: 'bold'
      })
    .add();
  }

}

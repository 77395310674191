<div class="main-container">

	<clr-alert *ngIf="message.isNotLoginPage" [clrAlertType]="message.genericInfoType" [clrAlertAppLevel]=true [(clrAlertClosed)]="message.isNoInfo">
		<clr-alert-item>
			<span class="alert-text">
				{{message.genericInfo}}
			</span>
		</clr-alert-item>
	</clr-alert>
    <clr-alerts *ngIf="message.isNotLoginPage">
        <!-- License-->
        <clr-alert *ngIf="license_info.alert >= 1" [clrAlertAppLevel]=true [clrAlertType]="license_info.alert == 2 ? 'danger' : 'warning'">
            <clr-alert-item>
                <div class="alert-text">
                    <b *ngIf="license_info.alertcode == 12 || license_info.alertcode == 15">No license key found&nbsp;!&nbsp;&nbsp;&nbsp;Please renew your license.</b>
                    <b *ngIf="license_info.alertcode == 13">Your license is expiring&nbsp;!&nbsp;[Expiration date]&nbsp;&nbsp;&nbsp;Please renew your license.</b>
                    <b *ngIf="license_info.alertcode == 14">Your license is expiring&nbsp;!&nbsp;[VM number]&nbsp;&nbsp;&nbsp;Please renew your license.</b>
                </div>
                <div class="alert-actions">
                    <button class="btn alert-action" (click)="callLicense()">Update license</button>
                </div>
            </clr-alert-item>
        </clr-alert>
        <!-- VMware heartbeat-->
		<clr-alert *ngFor="let data of instance_details" [clrAlertAppLevel]=true [clrAlertType]="instance_global_alert == 2 ? 'danger' : 'warning'">
			<clr-alert-item>
				<div class="alert-text">
					{{data.type}} connection problem since <b>{{formatDate(data.date, true)}}</b> for <b>{{data.name}}</b> ({{data.msg}})
				</div>
			</clr-alert-item>
		</clr-alert>
        <!-- Database status-->
		<clr-alert *ngFor="let data of db_details" [clrAlertAppLevel]=true [clrAlertType]="db_global_alert == 2 ? 'danger' : 'warning'">
			<clr-alert-item>
				<div class="alert-text">
					No data recorded into database since <b>{{formatDate(data.date, true)}}</b> for <b>{{data.name}}</b>
				</div>
			</clr-alert-item>
		</clr-alert>
    </clr-alerts>

    <ng-template load-alerting-watcher></ng-template>

    <header class="header-1" *ngIf="message.isNotLoginPage" style="background-color: rgb(4, 40, 60);">
        <div class="branding">
            <a clrVerticalNavLink routerLink="./dashboard" routerLinkActive="active">
                <img src="assets/img/logo_co2scope.png">
            </a>
        </div>
        <div style="padding-left:2rem;padding-top:1rem;">
            <clr-icon shape="home"></clr-icon>
            &nbsp;
            <span class="label white-breadcrump">
				{{message.breadcrumpRoute}}&nbsp;
    			</span>
            &nbsp;&nbsp;
            <clr-icon shape="filter"></clr-icon>
            &nbsp;
            <span class="label white-breadcrump">
				{{message.currentFilter}}
			</span>
            &nbsp;&nbsp;
            <clr-icon shape="target"></clr-icon>
            &nbsp;
            <span class="label white-breadcrump">
				{{message.elementView}}
			</span>
            &nbsp;&nbsp;
            <clr-icon shape="calendar"></clr-icon>
            &nbsp;
            <span class="label white-breadcrump">
				{{message.periodView}}
			</span>
        </div>
        <div style="padding-left:2rem;padding-top:1rem;">
            {{filter_dsp}}
            <div class="clr-select-wrapper">
                <select id="myfilter" class="clr-select" style="color:white;" [(ngModel)]="message.currentFilter" (ngModelChange)="switchFilter($event)">
                    <option *ngFor="let filter of message.filterList">{{filter.name}}</option>
                </select>
            </div>
        </div>
        <div class="header-actions">
            <clr-dropdown>
                <button class="nav-icon" clrDropdownTrigger aria-label="toggle filter menu">
                    <clr-icon shape="filter"></clr-icon>
                    <clr-icon shape="caret down"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <a clrDropdownItem routerLink="./periodview" routerLinkActive="active">
                        <clr-icon shape="calendar"></clr-icon>
                        Time view
                    </a>
                    <a clrDropdownItem routerLink="./selectionview" routerLinkActive="active">
                        <clr-icon shape="target"></clr-icon>
                        Selection view
                    </a>
                    <a clrDropdownItem routerLink="./filter" routerLinkActive="active">
                        <clr-icon shape="filter"></clr-icon>
                        Filter management
                    </a>
                </clr-dropdown-menu>
            </clr-dropdown>

            <!-- SETTINGS DROPDOWN -->
            <clr-dropdown>
                <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
                    <clr-icon shape="cog"></clr-icon>
                    <clr-icon shape="caret down"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <!-- SETTINGS -->
                    <a clrDropdownItem (click)="callSettings()">
                        <clr-icon shape="cog"></clr-icon>
                        &nbsp;Settings
                    </a>
                    <div class="dropdown-divider" role="separator"></div>
                    <!-- GREENIT SETTINGS -->
                    <a *ngIf="role.login == 'root'" clrDropdownItem (click)="callGreenitSettings()">
                        <fa-icon [icon]="['fas', 'leaf']"></fa-icon>
                        &nbsp;Green IT Settings
                    </a>
                    <div *ngIf="role.login == 'root'" class="dropdown-divider" role="separator"></div>
                    <!-- DOC -->
                    <!-- <clr-dropdown>
                        <button clrDropdownTrigger>
                            <clr-icon shape="list"></clr-icon>
                            &nbsp;Documentation
                        </button>
                        <clr-dropdown-menu>
                            <button clrDropdownItem (click)="callDoc('fr')"><img src="../assets/img/fr.svg" height="11">&nbsp;&nbsp;Français</button>
                            <button clrDropdownItem (click)="callDoc('en')"><img src="../assets/img/en.svg" height="11">&nbsp;&nbsp;English</button>
                            <button clrDropdownItem (click)="callDoc('es')"><img src="../assets/img/es.svg" height="11">&nbsp;&nbsp;Español</button>
                        </clr-dropdown-menu>
                    </clr-dropdown> -->
                    <a clrDropdownItem (click)="callDoc('fr')">
                        <clr-icon shape="list"></clr-icon>
                        &nbsp;Documentation
                    </a>
                    <!-- ABOUT -->
                    <div class="dropdown-divider" role="separator"></div>
                    <a clrDropdownItem (click)="callAbout()">
                        <clr-icon shape="help-info"></clr-icon>
                        &nbsp;About CO2Scope
                    </a>
                </clr-dropdown-menu>
            </clr-dropdown>

            <!-- FLAG DROPDOWN -->
            <clr-dropdown>
                <button class="nav-icon" clrDropdownTrigger aria-label="toggle filter menu">
                    <clr-icon [style.color]="updateFlagColor()" shape="flag"></clr-icon>
                    <clr-icon shape="caret down"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <!-- LICENSE STATUS-->
                    <clr-dropdown>
                        <button clrDropdownTrigger>
                            <clr-icon shape="key"></clr-icon>
                            &nbsp;License status :&nbsp;
                            <div *ngIf="license_info.alert == 2" style="background-image:radial-gradient(#FF0000,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
                            <div *ngIf="license_info.alert == 1" style="background-image:radial-gradient(#FFD700,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
                            <div *ngIf="license_info.alert == 0" style="background-image:radial-gradient(#00FF00,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
                        </button>
                        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
							<div clrDropdownItem>
								<b>VM number :</b> {{license_info.vmclient}} / {{license_info.vmlicense == 'noli' ? 'No limit number' : license_info.vmlicense}}
								<br>
								<i>excluded from the license :</i> {{license_info.vmclientexcluded}}
								<br><br>
								<b>Expiration date :</b> {{formatDate(license_info.endlicense, false)}}
							</div>
						</clr-dropdown-menu>
                    </clr-dropdown>
                    <!-- VMWARE STATUS-->
					<clr-dropdown>
						<div *ngIf="instance_global_alert == 0" clrDropdownItem>
							<fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
							&nbsp;Monitoring heartbeat :&nbsp;
							<div style="background-image:radial-gradient(#00FF00,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
						</div>
						<button *ngIf="instance_global_alert > 0" clrDropdownTrigger>
							<fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
							&nbsp;Monitoring heartbeat :&nbsp;
							<div *ngIf="instance_global_alert == 2" style="background-image:radial-gradient(#FF0000,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
							<div *ngIf="instance_global_alert == 1" style="background-image:radial-gradient(#FFD700,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
						</button>
						<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
							<div *ngFor="let data of instance_details" clrDropdownItem>
								<p style="margin:0px;"><b>Date :</b> {{formatDate(data.date, true)}}</p>
								<p style="margin:0px;"><b>Name :</b> {{data.name}}</p>
								<p style="margin:0px;word-wrap:break-word;white-space:normal;"><b>Reason :</b> {{data.msg}}</p>
							</div>
							<div class="dropdown-divider" role="separator"></div>
						</clr-dropdown-menu>
					</clr-dropdown>
					<!-- DB STATUS-->
					<clr-dropdown>
						<div *ngIf="db_global_alert == 0" clrDropdownItem>
							<clr-icon shape="storage"></clr-icon>
							&nbsp;Database status :&nbsp;
							<div style="background-image:radial-gradient(#00FF00,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
						</div>
						<button *ngIf="db_global_alert > 0" clrDropdownTrigger>
							<clr-icon shape="storage"></clr-icon>
							&nbsp;Database status :&nbsp;
							<div *ngIf="db_global_alert == 2" style="background-image:radial-gradient(#FF0000,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
							<div *ngIf="db_global_alert == 1" style="background-image:radial-gradient(#FFD700,#880000);border-radius:50%;width:10px;height:10px;display:inline-block"></div>
						</button>
						<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
							<div *ngFor="let data of db_details" clrDropdownItem>
								<p style="margin:0px;"><b>Date :</b> {{formatDate(data.date, true)}}</p>
								<p style="margin:0px;"><b>Name :</b> {{data.name}}</p>
								<p style="margin:0px;word-wrap:break-word;white-space:normal;"><b>Reason :</b> {{data.msg}}</p>
							</div>
							<div class="dropdown-divider" role="separator"></div>
						</clr-dropdown-menu>
					</clr-dropdown>
                    <!-- ARCHIVE-->
                    <div *ngIf="role.login == 'root'" class="dropdown-divider" role="separator"></div>
                    <button *ngIf="role.login == 'root'" clrDropdownItem (click)="callArchive()">
                        <clr-icon shape="download"></clr-icon>
                        &nbsp;Get CO2Scope status archive
                    </button>
                    <!-- UPLOAD MODAL-->
                    <div *ngIf="role.login == 'root'" class="dropdown-divider" role="separator"></div>
                    <button *ngIf="role.login == 'root'" clrDropdownItem (click)="callUpload()">
                        <clr-icon shape="upload"></clr-icon>
                        &nbsp;Upload CO2Scope update
                    </button>
                </clr-dropdown-menu>
            </clr-dropdown>

            <!-- USER DROPDOWN -->
            <clr-dropdown>
                <button class="nav-icon" clrDropdownTrigger aria-label="toggle user settings menu">
                    <clr-icon shape="user"></clr-icon>
                    <clr-icon shape="caret down"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
					<span style="font-size:12px;padding-top:0;padding-bottom:0;"clrDropdownItem>
						<clr-icon shape="user"></clr-icon>
						<b>&nbsp;Connected as  {{role.alias}}@{{role.login}}</b>
					</span>
                    <!-- COST -->
                    <div *ngIf="role.login == 'root'" class="dropdown-divider" role="separator"></div>
                    <a clrDropdownItem routerLink="./cost" routerLinkActive="active" *ngIf="role.login == 'root'">
                        <clr-icon shape="dollar"></clr-icon>
                        Cost management
                    </a>
                    <!-- USERS -->
                    <div *ngIf="role.login == 'root'" class="dropdown-divider" role="separator"></div>
                    <a clrDropdownItem routerLink="./user" routerLinkActive="active">
                        <clr-icon shape="users"></clr-icon>
                        User management
                    </a>
                    <!-- VCENTER / ESXI / VCLOUD / AWS / AZURE -->
                    <div *ngIf="role.login == 'root'" class="dropdown-divider" role="separator"></div>
                    <button *ngIf="role.login == 'root'" clrDropdownItem (click)="callMonitorList()">
                        <fa-icon [icon]="['fas', 'cogs']"></fa-icon>
                        &nbsp;Monitoring management
                    </button>
                    <!-- PLUGIN-->
                    <div *ngIf="role.login == 'root'" class="dropdown-divider" role="separator"></div>
                    <button *ngIf="role.login == 'root'" clrDropdownItem (click)="callPlugin()">
                        <clr-icon shape="view-cards"></clr-icon>
                        &nbsp;vSphere Client Plug-in
                    </button>
                    <!-- LICENSE-->
                    <div *ngIf="role.login == 'root'" class="dropdown-divider" role="separator"></div>
                    <button *ngIf="role.login == 'root'" clrDropdownItem (click)="callLicense()">
                        <clr-icon shape="key"></clr-icon>
                        &nbsp;License Key
                    </button>
                    <!-- LOGOUT -->
                    <div class="dropdown-divider" role="separator"></div>
                    <button clrDropdownItem  (click)="logout()">
                        <clr-icon shape="logout"></clr-icon>
                        &nbsp;Sign out
                    </button>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </header>
    <nav class="subnav" style="padding-left:12rem" *ngIf="message.isNotLoginPage">
        <ul class="nav">
            <li class="nav-item">
                <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <clr-icon shape="data-cluster"></clr-icon>
                        DC view
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header treemap" clrDropdownItem routerLink="./dcviews/treemap" routerLinkActive="active">
                            <clr-icon shape="grid-view"></clr-icon>
                            Tree Map
                        </div>
                        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                        <div aria-label="Dropdown header flow" clrDropdownItem routerLink="./dcviews/flowdiag" routerLinkActive="active">
                            <clr-icon shape="shuffle"></clr-icon>
                            Flow Diagram
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <li class="nav-item">
                <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <clr-icon shape="cluster"></clr-icon>
                        CLUSTER view
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header clrInformation" clrDropdownItem routerLink="./clusterviews/information" routerLinkActive="active">
                            <clr-icon shape="info-circle"></clr-icon>
                            Information
                        </div>
                        <div aria-label="Dropdown header clSynthesis" clrDropdownItem routerLink="./clusterviews/synthesis" routerLinkActive="active">
                            <clr-icon shape="details"></clr-icon>
                            Synthesis
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <li class="nav-item">
                <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <clr-icon shape="resource-pool"></clr-icon>
                        RES.POOL view
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header rpInformation" clrDropdownItem routerLink="./rpviews/information" routerLinkActive="active">
                            <clr-icon shape="info-circle"></clr-icon>
                            Information
                        </div>
                        <div aria-label="Dropdown header rpSynthesis" clrDropdownItem [disabled]="!rp_synth_show"
                             [routerLink]="rp_synth_show ? './rpviews/synthesis' : null" [routerLinkActive]="rp_synth_show ? 'active' : 'disable'">
                            <clr-icon shape="details"></clr-icon>
                            Synthesis
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <li class="nav-item">
                <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <clr-icon shape="host"></clr-icon>
                        SERVER view
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header srvInfomartion" clrDropdownItem routerLink="./hostviews/information" routerLinkActive="active">
                            <clr-icon shape="info-circle"></clr-icon>
                            Information
                        </div>
                        <div aria-label="Dropdown header srvConsumption" clrDropdownItem routerLink="./hostviews/consumption" routerLinkActive="active">
                            <clr-icon shape="table"></clr-icon>
                            Consumption
                        </div>
                        <div aria-label="Dropdown header srvSynthesis" clrDropdownItem routerLink="./hostviews/synthesis" routerLinkActive="active">
                            <clr-icon shape="details"></clr-icon>
                            Synthesis
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <li class="nav-item">
                <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <clr-icon shape="vm"></clr-icon>
                        VM view
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header vmInformation" clrDropdownItem routerLink="./vmviews/information" routerLinkActive="active">
                            <clr-icon shape="info-circle"></clr-icon>
                            Information
                        </div>
                        <div aria-label="Dropdown header vmConsumption" clrDropdownItem routerLink="./vmviews/consumption" routerLinkActive="active">
                            <clr-icon shape="table"></clr-icon>
                            Consumption
                        </div>
                        <div aria-label="Dropdown header vmSynthesis" clrDropdownItem routerLink="./vmviews/synthesis" routerLinkActive="active">
                            <clr-icon shape="details"></clr-icon>
                            Synthesis
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <li class="nav-item">
                <clr-dropdown>
                    <button class="btn btn-sm btn-link" clrDropdownTrigger>
                        <clr-icon shape="storage"></clr-icon>
                        STORAGE view
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <div aria-label="Dropdown header dsStorage" clrDropdownItem routerLink="./storage-overview" routerLinkActive="active">
                            <clr-icon shape="storage"></clr-icon>
                            Datastore
                        </div>
                        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                        <div aria-label="Dropdown header vmStorage" clrDropdownItem routerLink="./storage-overview-vm" routerLinkActive="active">
                            <clr-icon shape="vm"></clr-icon>
                            Virtual machine
                        </div>
                    </clr-dropdown-menu>
                </clr-dropdown>
            </li>
            <clr-icon shape="search" size="20" class="is-info"></clr-icon>
            <form clrForm [formGroup]="searchbar" class="search-bar">
                <clr-datalist-container id="trm" style="flex-direction:column;">
                    <input clrDatalistInput #searchbarInput (ngModelChange)="submit($event)" placeholder="Search a cluster, a server, a vm or a datastore ..." name="Options" formControlName="item" size="50">
                    <datalist>
                        <option *ngFor="let item of items" [ngValue]="item.uuid">{{item.name}} - [{{item.type}}]</option>
                    </datalist>
                </clr-datalist-container>
            </form>
        </ul>
    </nav>

    <div class="content-container">
        <clr-vertical-nav [clrVerticalNavCollapsible]="true" class="nav-trigger--bottom" *ngIf="message.isNotLoginPage">
            <a clrVerticalNavLink routerLink="./dashboard" routerLinkActive="active">
                Dashboard
            </a>
            <div class="nav-divider"></div>
            <clr-vertical-nav-group routerLinkActive="active">
                Measure
                <clr-vertical-nav-group-children *clrIfExpanded="true">
                    <a clrVerticalNavLink routerLink="./greenit/evolution" routerLinkActive="active">
                        <clr-icon shape="tree"></clr-icon>
                        CO2 Evolution
                    </a>
                    <a clrVerticalNavLink routerLink="./greenit/power" routerLinkActive="active">
                        <clr-icon shape="bolt"></clr-icon>
                        Power Graph
                    </a>
                    <a clrVerticalNavLink routerLink="./god" routerLinkActive="active" *ngIf="role.isMenuGod">
                        <clr-icon clrVerticalNavIcon shape="line-chart" style="margin-right:10px"></clr-icon>
                        Graph On Demand
                    </a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
            <div class="nav-divider"></div>
            <clr-vertical-nav-group routerLinkActive="active">
                    Analyse
                <clr-vertical-nav-group-children *clrIfExpanded="true">
                    <a clrVerticalNavLink routerLink="../dcviews/treemap" routerLinkActive="active">
                        <clr-icon clrVerticalNavIcon shape="grid-view" style="margin-right:10px"></clr-icon>
                        Power Distribution
                    </a>
                    <a clrVerticalNavLink routerLink="./elementsynthesis" routerLinkActive="active">
                        <clr-icon clrVerticalNavIcon shape="calendar" style="margin-right:10px"></clr-icon>
                        Power Usage
                    </a>
                    <a clrVerticalNavLink routerLink="./reporting" routerLinkActive="active" *ngIf="role.isMenuReport">
                        <clr-icon clrVerticalNavIcon shape="library" style="margin-right:10px"></clr-icon>
                        Reports
                    </a>
                    <a clrVerticalNavLink routerLink="./greenindex" routerLinkActive="active" *ngIf="role.isMenuVirtindex">
						<clr-icon clrVerticalNavIcon shape="bell-curve" style="margin-right:10px"></clr-icon>
						Green-Index&nbsp;<ng-template [ngTemplateOutlet]="greenindexstatus"></ng-template>
					</a>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
            <div class="nav-divider"></div>
            <clr-vertical-nav-group routerLinkActive="active">
                Reduce
                <clr-vertical-nav-group-children *clrIfExpanded="true">
                    <a clrVerticalNavLink routerLink="./opportunities" routerLinkActive="active" *ngIf="role.isMenuReco">
                        <clr-icon clrVerticalNavIcon shape="thumbs-up" style="margin-right:10px"></clr-icon>
                        Opportunities
                    </a>
                    <a clrVerticalNavLink routerLink="./greenit/analysis" routerLinkActive="active">
						<clr-icon clrVerticalNavIcon shape="table"></clr-icon>
						Green IT Analysis
					</a>
                    <a clrVerticalNavLink routerLink="./capacity" routerLinkActive="active" *ngIf="role.isMenuCapa">
                        <clr-icon clrVerticalNavIcon shape="slider" style="margin-right:10px"></clr-icon>
                        Capacity Planning
                    </a>
                    <a clrVerticalNavLink routerLink="./simulation" routerLinkActive="active">
                        <clr-icon clrVerticalNavIcon shape="deploy" style="margin-right:10px"></clr-icon>
                        Simulation
                    </a>
                    <clr-vertical-nav-group routerLinkActive="active" style="margin-left: 20px;">
                        Cloud Impact
                        <clr-vertical-nav-group-children *clrIfExpanded="true">
                            <a clrVerticalNavLink routerLink="./cloudimpact" routerLinkActive="active" *ngIf="role.isMenuReco">
                                <clr-icon clrVerticalNavIcon shape="network-globe" style="margin-right:10px"></clr-icon>
                                All regions
                            </a>
                            <a clrVerticalNavLink routerLink="./cloud/myprovider/myregion" routerLinkActive="active">
                                <fa-icon [icon]="myproviderIcon" ></fa-icon>
                                My Cloud Impact
                            </a>
                        </clr-vertical-nav-group-children>
                    </clr-vertical-nav-group>
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
        </clr-vertical-nav>
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<!-- MODAL ABOUT -->
<clr-modal [(clrModalOpen)]="isModalAbout" [clrModalSize]="'xs'">
    <h3 class="modal-title">About CO2Scope</h3>
    <div class="modal-body">
        <table style="margin-right:auto; margin-left:auto;">
            <tr><td><b>version:</b></td><td style="padding-left:50px">{{jsonLoader.version.co2scope.release}}</td></tr>
            <tr><td><b>build:</b></td><td style="padding-left:50px">{{jsonLoader.version.co2scope.build}}</td></tr>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="isModalAbout = false">OK</button>
    </div>
</clr-modal>

<!-- MODAL PROGRESS -->
<clr-modal [(clrModalOpen)]="message.waiting" [clrModalClosable]=false [clrModalSize]="'l'">
    <h3 class="modal-title">Please wait</h3>
    <div class="modal-body" style="min-height:100px;">
        <span class="spinner" style="position:absolute;top:50%;right:45%;"></span>
    </div>
    <div class="modal-footer">
    </div>
</clr-modal>

<!-- MODAL DATALOCK -->
<clr-modal [(clrModalOpen)]="isModalDatalock" [clrModalSize]="'xs'">
    <h3 class="modal-title">Welcome to CO2 Scope</h3>
    <div class="modal-body">
        CO2 Scope is a tool for analyzing and managing your infrastructure.<br><br>
        To do this, it must have a minimum of data history :
        <ol>
            <li>wait until the <b>next hour</b> for the 'database connection' status to be updated (must change from orange to green),</li>
            <li>wait until <b>2 days after the installation date</b> for all features (especially for capacity planning)</li>
        </ol>
        <br><br>
        <b>Note : </b>This message will disappear once 2 days of data collected.
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="isModalDatalock = false">OK</button>
    </div>
</clr-modal>

<!-- SETTINGS -->
<app-settings #settings (reload)="loadFilter($event)"></app-settings>

<!-- GREENIT SETTINGS -->
<app-greenitsettings #greenitsettings></app-greenitsettings>

<!-- LICENSE -->
<app-license #license [target]="modal"></app-license>

<!-- UPLOAD -->
<app-upload #upload></app-upload>

<!-- STATUS / MONITOR -->
<app-monitor #monitor (progress)="message.waiting=$event"></app-monitor>

<!-- PLUGIN -->
<app-plugin #plugin></app-plugin>

<!-- GREEN-INDEX-->
<ng-template #greenindexstatus >
	<clr-icon *ngIf="!greenindex_status" size="20" style="color:var(--clr-alert-warning-icon-color, #454545);" shape="exclamation-circle"></clr-icon>
</ng-template>

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-alerting-notification]'
})
export class AlertingnotificationDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

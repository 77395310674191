import { AfterViewInit, AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild, ComponentFactoryResolver, ComponentRef } from '@angular/core';

import { ConsoverComponent } from '@app/consover/consover.component';
import { HostconsoverDirective } from '@app/directives/hostconsover.directive';

import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { DatePipe } from '@angular/common';

import { AccountService, DataService, InfrastructureService, JsonloaderService, SettingsService, ShareService } from '@app/services';

import { ClusterSynthesis, Comment, HostBadge, Infrastructure, Json, JSONTarget, Message, HostSynthesis, User } from '@app/model';

import * as Highcharts from 'highcharts';

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import * as moment from 'moment';

import { EvolutionsynthesisComponent } from '@app/evolutionsynthesis/evolutionsynthesis.component';
import { EvolutionDirective } from '@app/directives/evolution.directive';

import { TroubleshootingcardComponent } from '@app/troubleshooting/troubleshootingcard/troubleshootingcard.component';
import { TroubleshootingDirective } from '@app/directives/troubleshooting.directive';

import { ConscalendarComponent } from '@app/conscalendar/conscalendar.component';
import { ConscalendarDirective } from '@app/directives/conscalendar.directive';

/*import { WeeklycalComponent } from '@app/weeklycal/weeklycal.component';
import { WeeklycalDirective } from '@app/directives/weeklycal.directive';

import { MonthlycalComponent } from '@app/monthlycal/monthlycal.component';
import { MonthlycalDirective } from '@app/directives/monthlycal.directive';

import { YearlycalComponent } from '@app/yearlycal/yearlycal.component';
import { YearlycalDirective } from '@app/directives/yearlycal.directive';*/

import { VmlistComponent } from '@app/vmlist/vmlist.component';
import { VmlistDirective } from '@app/directives/vmlist.directive';

import { commaBigNumber, getUserCurrency } from '../../assets/js/tools.js';

var componentRef2: ComponentRef<any>;


@Component({
  selector: 'app-hostsynthesis',
  templateUrl: './hostsynthesis.component.html',
  styleUrls: ['./hostsynthesis.component.css']
})
export class HostsynthesisComponent implements AfterViewInit, OnInit {

  isDcscope = true;
  isCo2scope = false;

  jsonLoader: Json;

  @ViewChild(EvolutionDirective, {static: false}) addEvolution: EvolutionDirective;

  @ViewChild(TroubleshootingDirective, {static: false}) addTroubleshooting: TroubleshootingDirective;

  @ViewChild(ConscalendarDirective, {static: false}) addConsCal: ConscalendarDirective;
  /*@ViewChild(WeeklycalDirective, {static: false}) addWeeklyCal: WeeklycalDirective;

  @ViewChild(MonthlycalDirective, {static: false}) addMonthlyCal: MonthlycalDirective;

  @ViewChild(YearlycalDirective, {static: false}) addYearlyCal: YearlycalDirective;*/

  @ViewChild(HostconsoverDirective, {static: false}) addHostConsover2: HostconsoverDirective;

  @ViewChild(VmlistDirective, {static: false}) addVmlist: VmlistDirective;

  math = Math;

  message: Message;

  data_hosts: any = [];

  data_vms: any = [];
  
  data_clusters: any = [];

  host_synth: HostSynthesis = {
    uuid: 'not registered',
    name: 'not registered',
    father: '',
    cpucap: 0,
    ramcap: 0,
    vcpus: 0,
    rams: 0,
    os: 'not registered',
    model: 'not registered',
    tsstate: 'not registered',
    cost: 0,
    cpu_oc: 0,
    ram_oc: 0,
    cpu_oc_tx: 0,
    ram_oc_tx: 0,
    vms: 0,
    vms_run: 0,
    vms_off: 0,
    vms_oth: 0,
    tools: 0,
    tools_ti: 0,
    tools_te: 0,
    tools_tu: 0,
    dis_vcpu_2: 0,
    dis_vcpu_4: 0,
    dis_vcpu_8: 0,
    dis_vcpu_16: 0,
    dis_vcpu_32: 0,
    dis_vcpu_64: 0,
    dis_vram_2: 0,
    dis_vram_4: 0,
    dis_vram_8: 0,
    dis_vram_16: 0,
    dis_vram_32: 0,
    dis_vram_64: 0,
    color_cpu: 'GREEN', 
    color_net: 'GREEN',
    color_ram: 'GREEN',
    arrow_o_c: 0.0,
    arrow_o_n: 0.0,
    arrow_o_r: 0.0,
    pts_cpu_ora: 0,
    pts_cpu_red: 0,
    pts_net_ora: 0,
    pts_net_red: 0,
    pts_ram_ora: 0,
    pts_ram_red: 0,
    arrow_ram_swp: 'FLAT',
    arrow_o_ram_swp: 0.0,
    color_ram_swp: 'GREEN',
    arrow_ram_bal: 'FLAT',
    arrow_o_ram_bal: 0,
    color_ram_bal: 'GREEN',
    avg_ram_swp: 0,
    avg_ram_bal: 0,
	co2: 0
  };

  comment : Comment = {
    ID: 0,
    USER: 'not registered',
    IDENTIFIER: 'not registered',
    COMMENT: 'not registered',
    OWNER: 'not registered',
    ENV: 'not registered',
    CREATE_DATE: 'not registered',
    END_DATE: 'not registered'
  };

  infra: Infrastructure = {
	cluster: '',
	datacenter: ''
  };

  host_badge: HostBadge = {
    cpu_commitment: 'badge-success',
    ram_commitment: 'badge-success',
    sto_commitment: 'badge-success',
    vm_off: 'badge-success addLink',
    vm_other: 'badge-success addLink',
    tools_te: 'badge-success addLink',
    tools_ti: 'badge-success addLink',
    tools_tu: 'badge-success addLink',
    nbvm_cap: false,
    nbvm_class: 'is-success',
    nbvm_capmax: false,
    nbvmmax_class: 'is-success',
    nbvm_cons: false,
    nbvmcons_class: 'is-success',
    cpu_sat: 'badge-success',
    ram_sat: 'badge-success'
  };

  newmoddate: string = '';
  
  newenddate: string = '';

  isNoCpuAlert: boolean = false;

  cpu_state: string = 'success';

  isNoRamAlert: boolean = false;

  ram_state: string = 'success';

  isNoTsAlert: boolean = false;

  trouble_state: string = 'success';
	
  tools_state: string = 'green';
	
  trouble_main_state: string = 'normal';

  isNoEvoAlert: boolean = false;

  evolution_state: string = 'success';

  isAvgMaxValue: boolean = true;

  isModalVmList: boolean = false;

  globalCurrency: string = '';

  zoom: boolean = false;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;

  isReady: boolean = false;

  Highcharts2: typeof Highcharts = Highcharts;

  chartOptions2: Highcharts.Options;

  isReady2: boolean = false;

  isReady3: boolean = false;

  currentUser: User;

  host_cons_avg_cpu: number = 0;

  host_cons_avg_ram: number = 0;

  datas_green: any = [];


  constructor(
	private componentFactoryResolver: ComponentFactoryResolver,
	private router: Router,
	private authentication_svc: AccountService,
	private settings_svc: SettingsService,
	private counter_svc: DataService,
	private infra_svc: InfrastructureService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
	this.data_hosts = this.jsonLoader.hostSynthesis;
	this.data_vms = this.jsonLoader.vmSynthesis;
	//this.data_cost = this.jsonLoader.costAllocated;

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.isVmConsover = false;
	this.message.powerUsageEnv = 'vmware';

	this.isCo2scope = this.message.isCO2Scope;
	this.isDcscope = this.message.isDCScope;

  }

  ngAfterViewInit(): void {

	this.buildCard();
	this.setAllocation();
	setTimeout(() => this.loadVmList(), 100);
  }

  ngAfterViewChecked(): void {

	if(this.message.isReloadConsover)
		this.reloadHostConsOver();

  	this.cd.detectChanges();
  }

  setCard():void {

	this.buildCard();
	this.setAllocation();
	setTimeout(() => $('#csstoadjust').parent().find('clr-date-container').removeClass('clr-form-control'), 100);
  }

  /*setOverallCons(): void {
	setTimeout(() => this.loadHostConsOver(), 100);
  }*/

  filterHost(hosts: any): void {

	let host: any = [];
	for (var i in hosts) {
		if(hosts[i][2] == this.message.currentUuid)
			host.push(hosts[i]);
	}

	return host;
  }

  setCalCons(): void {
	setTimeout(() => this.loaddConsCal(), 100);
  }

  /*setWeeklyCons(): void {
	setTimeout(() => this.loadWeeklyCal(), 100);
  }

  setMonthlyCons(): void {
	setTimeout(() => this.loadMonthlyCal(), 100);
  }

  setYearlyCons(): void {
	setTimeout(() => this.loadYearlyCal(), 100);
  }*/

  updateOwner(value): void {
	this.comment.OWNER = value;
	if(value == "")
		value = 'NULL';
	this.callUpdateComment('OWNER', value);
  }

  updateEnv(value): void {
	this.comment.ENV = value;
	if(value == "")
		value = 'NULL';
	this.callUpdateComment('ENV', value);
  }

  updateCreateDate(): void {
	if(this.newmoddate != '') {
		let formatedDate = new DatePipe('en-US').transform(this.newmoddate, 'fullDate');
		this.comment.CREATE_DATE = formatedDate;
		this.callUpdateComment('CREATE_DATE', formatedDate);
		this.newmoddate = '';
	}
  }

  updateEndDate(): void {
	if(this.newenddate != '') {
		let formatedDate = new DatePipe('en-US').transform(this.newenddate, 'fullDate');
		this.comment.END_DATE = formatedDate;
		this.callUpdateComment('END_DATE', formatedDate);
		this.newenddate = '';
	}
  }

  updateComment(value): void {
	this.comment.COMMENT = value;
	if(value == "")
		value = 'NULL';
	this.callUpdateComment('COMMENT', value);
  }

  setAllocation(): void {

	this.initGraph();

	let cat_cpu = ['threads capacity', 'vcpu allocated to VMs', 'max vcpu'];
	let data_cpu = [
		{
        		y: this.host_synth.cpucap,
        		color: '#CCC6AD'
    		}, {
        		y: this.host_synth.vcpus,
        		color: '#046380'
		}, {
        		y: this.host_synth.cpucap * this.host_synth.cpu_oc,
        		color: '#e74c3c'
    		}
	];
	this.callCpuBarGraph(data_cpu, cat_cpu, '', '');

	let cat_ram = ['ram capacity', 'vram allocated to VMs', 'max vram'];
	var data_ram = [
		{
        		y: Math.round(this.host_synth.ramcap / 1024),
        		color: '#CCC6AD'
    		}, {
        		y: Math.round(this.host_synth.rams / 1024),
        		color: '#046380'
    		}, {
        		y: Math.round(this.host_synth.ramcap / 1024 * this.host_synth.ram_oc / 100),
        		color: '#e74c3c'
    		}
	];
	var ram_unit: string = 'GB';
	if((this.host_synth.ramcap/1024) > 10000 && (this.host_synth.rams/1024) > 10000)
		ram_unit = 'TB';

	this.callRamBarGraph(data_ram, cat_ram, '', ram_unit);
  }

  setDistribution(): void {

	this.initGraph();

	let cat = [']0 - 2]', ']2 - 4]', ']4 - 8]', ']8 - 16]', ']16 - 32]', ']32 - ...]'];
	let data = [];
	let data_cpu = {
		type: 'column',
                name: 'vcpu',
                color: '#046380',
                data: [this.host_synth.dis_vcpu_2, this.host_synth.dis_vcpu_4, this.host_synth.dis_vcpu_8, this.host_synth.dis_vcpu_16, this.host_synth.dis_vcpu_32, this.host_synth.dis_vcpu_64]
	};
	let data_ram = {
		type: 'column',
                name: 'vram',
                color: '#333333',
                data: [this.host_synth.dis_vram_2, this.host_synth.dis_vram_4, this.host_synth.dis_vram_8, this.host_synth.dis_vram_16, this.host_synth.dis_vram_32, this.host_synth.dis_vram_64]
	};
	data.push(data_cpu);
	data.push(data_ram);
	this.callBarGraphDis(data, cat, 'The VM distribution according to the vcpu & vram allocation', '[vcpu (nb) - vram (GB)]', 'number of VM');
  }

  setConsumption(event): void {

	this.initGraph();

	if(this.isAvgMaxValue) {
		$('#server-consumption-dis-graph').html('');
		let cat_cpu = [this.message.currentName + ' (cpu usage %)', 'all servers (cpu usage %)'];
		let cat_ram = [this.message.currentName + ' (ram usage %)', 'all servers (ram usage %)'];
		this.counter_svc.getHostAvg(this.message.currentUuid, this.message.minTimeFilter/1000, this.message.maxTimeFilter/1000).pipe(first()).subscribe(
			data => {
				this.host_cons_avg_cpu = this.math.round(data.avg_cpu_one/10);
    				var data_cpu = [{
					y: this.math.round(data.avg_cpu_one/10),
        				color: '#7cb5ec'
    				}, {
					y: this.math.round(data.avg_cpu_all/10),
        				color: '#7cb5ec'
				}];
				if(event)
					this.callCpuBarGraph(data_cpu, cat_cpu, 'average cpu consumption', '');

				this.host_cons_avg_ram = this.math.round(data.avg_ram_one/10);
    				var data_ram = [{
					y: this.math.round(data.avg_ram_one/10),
        				color: '#333333'
    				}, {
					y: this.math.round(data.avg_ram_all/10),
        				color: '#333333'
    				}];
				if(event)
					this.callRamBarGraph(data_ram, cat_ram, 'average ram consumption', '');
                	},
			error=> {
				console.log('fail to get host average data');
                	}
		);
	} else {
		this.counter_svc.getHostMax(this.message.currentUuid, this.message.minTimeFilter/1000, this.message.maxTimeFilter/1000).pipe(first()).subscribe(
			data => {
				let days = 0;
				for (var i in data) {
					days += data[i];
				}
				days = days/2;
				var cat = [']0 - 10]', ']10 - 30]', ']30 - 50]', ']50 - 70]', ']70 - 90]', ']90 - 100]'];
    				var data_res = [{
        				name: 'cpu',
        				color: '#7cb5ec',
					data: [ data.area_cpu_10, data.area_cpu_10_30, data.area_cpu_30_50, data.area_cpu_50_70, data.area_cpu_70_90, data.area_cpu_90 ]
				}, {
        				name: 'ram',
        				color: '#333333',
					data: [ data.area_ram_10, data.area_ram_10_30, data.area_ram_30_50, data.area_ram_50_70, data.area_ram_70_90, data.area_ram_90 ]
				}];
				this.callBarGraphDis(data_res, cat, 'The daily distribution of maximums (for ' + days + ' days)', '% of consumption', 'days');
                	},
			error=> {
				console.log('fail to get host max data');
                	}
		);
	}
  }

  setEvolution(): void {
	setTimeout(() => this.loadEvolution(), 100);
  }

  setTroubleshooting(): void {
	setTimeout(() => this.loadTroubleshooting(), 100);
  }

  callVmList(): void {

	this.message.vmListFilter = 'all';
	this.message.colListFilter = 4; 
	this.isModalVmList = true;
  }

  filterVm(vm: any): any {

	let vms: any = [];
	for (var i in this.data_vms) {
		if(this.data_vms[i].name != "vm_average") {
			if(this.data_vms[i].father == this.message.currentUuid)
				vms.push(this.data_vms[i]);
		}
	}

	return vms;
  }

  callClusterRoute(name): void {

	this.data_clusters = this.jsonLoader.clusterSynthesis;
	var cluster: ClusterSynthesis = this.getClusterData(name);
	this.message.currentUuid = cluster.uuid;
	this.message.currentName = name;
	this.message.currentType = 'CLUSTER';
	this.router.navigate(['/clusterviews/synthesis']);
  }

  listVmRun(): void {

	this.message.vmListFilter = 'RUNNING';
	this.message.colListFilter = 4;
	this.isModalVmList = true;
  }

  listVmOff(): void {

	this.message.vmListFilter = 'OFF';
	this.message.colListFilter = 4;
	this.isModalVmList = true;
  }

  listVmOther(): void {

	this.message.vmListFilter = 'OTHER';
	this.message.colListFilter = 4;
	this.isModalVmList = true;
  }

  listToolsTe(): void {

	this.message.vmListFilter = 'not running';
	this.message.colListFilter = 5;
	this.isModalVmList = true;
  }

  listToolsTi(): void {

	this.message.vmListFilter = 'not installed';
	this.message.colListFilter = 5;
	this.isModalVmList = true;
  }

  listToolsTu(): void {

	this.message.vmListFilter = 'not up to date';
	this.message.colListFilter = 5;
	this.isModalVmList = true;
  }

  formatCost(cost: number): number {
	return commaBigNumber(cost);
  }

  private buildCard(): void {

	if(this.message != null) {
		if(this.message.currentUuid != '') {
			this.host_synth = this.getHostData(this.message.currentUuid);
			if(this.host_synth === undefined) {
				this.message.currentUuid = '';
				this.buildCard();
			}
		} else {
			this.host_synth = this.data_hosts[0];
			this.message.currentUuid = this.host_synth.uuid;
			this.message.currentName = this.host_synth.name;
			this.message.currentType = 'SERVER';
		}

		this.setBadges();

  		this.setConsumption(false);

		// SHARE THE HostSynthesis OBJECT
		this.message.hostSynth = this.host_synth;

		this.globalCurrency = getUserCurrency(this.currentUser.currency);
		if(!isNaN(this.host_synth.cost))
			this.host_synth.cost = commaBigNumber(this.math.round(this.host_synth.cost));

		// SET COMMENT
		this.settings_svc.getUserComment('root', this.message.currentUuid).pipe(first()).subscribe(
			data => {
				this.comment.ID = data.ID;
				this.comment.USER = data.USER;
				this.comment.COMMENT = data.COMMENT;
				this.comment.ENV = data.ENV;
				this.comment.OWNER = data.OWNER;
				this.comment.CREATE_DATE = data.CREATE_DATE;
				this.comment.END_DATE = data.END_DATE;
			}, error => {
				console.log('no comment data for ' + this.message.currentUuid);
    			this.comment.COMMENT = '';
				this.comment.OWNER = '';
				this.comment.ENV = '';
				this.comment.CREATE_DATE = 'not registered';
				this.comment.END_DATE = 'not registered';
				
				// No data, getMaxIdComment
				this.settings_svc.getMaxIdComment().pipe(first()).subscribe(
					data => {
						var y: number = Number(data.id);
						this.comment.ID = y;
					},
					error => {
						if(error != null)
							console.log(error)
					}
				);
			}
		);

		// SET LOCATION
		this.infra_svc.getElementInfra(this.message.currentUuid, 'SERVER').pipe(first()).subscribe(
			data => {
				this.infra.cluster = data.cluster;
				this.infra.datacenter = data.datacenter;
			}, error => {
				console.log('no location data ' + this.message.currentUuid);
			}
		);

		// SET CO2
		this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_POWER).subscribe(
			data => {
				this.datas_green = data;
				let co2_el: any = this.getPowerElement();
				if(this.message.currentUuid != "" && co2_el != undefined) {
					let direct: number = +co2_el.CO2;
					let grey: number = +co2_el.CO2G;
					this.host_synth.co2 = Math.round(direct+grey);
				}
			}
		);
	}
	setTimeout(() => this.applyCssSchema(), 100);
  }

  private applyCssSchema(): void {

	$('#csstoadjust').parent().find('clr-date-container').removeClass('clr-form-control');
	if(this.comment.CREATE_DATE != "not registered" && this.comment.CREATE_DATE !== null)
		$('#host-synth-input-create-date').addClass('applyBlack');
	if(this.comment.END_DATE != "not registered" && this.comment.END_DATE !== null)
		$('#host-synth-input-end-date').addClass('applyBlack');
  }

  private setBadges(): void {

	// ALERT & BADGE MANAGEMENT
	// SET TROUBLESHOOTING STATE
	if(this.host_synth.tsstate == "green") {
		this.trouble_state = 'success';
  		this.trouble_main_state = 'normal';
		setTimeout(() => $('#server-alert3-text').html('troubleshooting: normal'), 100);
	} else if(this.host_synth.tsstate == "orange") {
		this.isNoTsAlert = false;
		this.trouble_state = 'warning';
  		this.trouble_main_state = 'warning';
		setTimeout(() => $('#server-alert3-text').html('troubleshooting: warning'), 100);
	} else if(this.host_synth.tsstate == "red") {
		this.isNoTsAlert = false;
		this.trouble_state = 'danger';
  		this.trouble_main_state = 'critical';
		setTimeout(() => $('#server-alert3-text').html('troubleshooting: critical'), 100);
	}

	// CPU ALLOCATION
	if(this.math.round(this.host_synth.cpu_oc_tx/100) >= this.host_synth.cpu_oc) {
  		this.isNoCpuAlert = false;
		this.cpu_state = 'danger';
		setTimeout(() => $('#server-alert-text').html('cpu allocation: critical'), 100);
		this.host_badge.cpu_commitment = 'badge-danger';
	} else if((this.host_synth.cpu_oc_tx/this.host_synth.cpu_oc) >= 80) {
  		this.isNoCpuAlert = false;
		this.cpu_state = 'warning';
		setTimeout(() => $('#server-alert-text').html('cpu allocation: warning'), 100);
		this.host_badge.cpu_commitment = 'badge-warning';
	}
	// RAM ALLOCATION
	if(this.host_synth.ram_oc_tx >= this.host_synth.ram_oc) {
  		this.isNoRamAlert = false;
		this.ram_state = 'danger';
		setTimeout(() => $('#server-alert2-text').html('ram allocation: critical'), 100);
		this.host_badge.ram_commitment = 'badge-danger';
	} else if((this.host_synth.ram_oc_tx*100/this.host_synth.ram_oc) >= 80) {
  		this.isNoRamAlert = false;
		this.ram_state = 'warning';
		setTimeout(() => $('#server-alert2-text').html('ram allocation: warning'), 100);
		this.host_badge.ram_commitment = 'badge-warning';
	}

	// EVOLUTION
	var data_prediction = this.jsonLoader.hostsPrediction;
	var nbvm_cap: number = 0;
	var consram: number = 0;
	var conscpu: number = 0;
	for (var i in data_prediction) {
		if (data_prediction[i].UUID == this.message.currentUuid) {
			nbvm_cap = data_prediction[i].NBVM_CAP;
			conscpu = data_prediction[i].CONSCPU_DATE;
			consram = data_prediction[i].CONSRAM_DATE;
		}
	}
	if(nbvm_cap < (this.host_synth.vms*0.2)) {
		this.isNoEvoAlert = false;
		this.evolution_state = 'warning';
		setTimeout(() => $('#server-alert4-text').html('evolution: warning'), 100);
	}
	if(nbvm_cap < (this.host_synth.vms*0.1)) {
		this.isNoEvoAlert = false;
		this.evolution_state = 'danger';
		setTimeout(() => $('#server-alert4-text').html('evolution: critical'), 100);
	}
	if(this.evolution_state != "danger") {
		var cpusat_state = '';
		cpusat_state = this.analyseEvolution(conscpu);
		if(cpusat_state != "danger") {
			var ramsat_state = '';
			ramsat_state = this.analyseEvolution(consram);
			if(ramsat_state == "danger") {
				this.isNoEvoAlert = false;
				this.evolution_state = 'danger';
				setTimeout(() => $('#server-alert4-text').html('evolution: critical'), 100);
			} else if(ramsat_state == "warning" || cpusat_state == "warning") {
				this.isNoEvoAlert = false;
				this.evolution_state = 'warning';
				setTimeout(() => $('#server-alert4-text').html('evolution: warning'), 100);
			}
		} else {
			this.isNoEvoAlert = false;
			this.evolution_state = 'danger';
			setTimeout(() => $('#server-alert4-text').html('evolution: critical'), 100);
		}
	}

	// VM & TOOLS BADGES ALLOCATION
	if(this.host_synth.vms_off > 0) {	
		if(this.host_synth.vms_off > (this.host_synth.vms*0.1))
			this.host_badge.vm_off = 'badge-danger addLink';
		else
			this.host_badge.vm_off = 'badge-warning addLink';
	}
	if(this.host_synth.vms_oth > 0) {	
		if(this.host_synth.vms_oth > (this.host_synth.vms*0.1))
			this.host_badge.vm_other = 'badge-danger addLink';
		else
			this.host_badge.vm_other = 'badge-warning addLink';
	}

	let tools_w: boolean = false;
	let tools_c: boolean = false;
	if(this.host_synth.tools_te > 0) {
		if(this.host_synth.tools_te > (this.host_synth.vms*0.1)) {
			this.host_badge.tools_te = 'badge-danger addLink';
			tools_c = true;
		} else {
			this.host_badge.tools_te = 'badge-warning addLink';
			tools_w = true;
		}
	}
	if(this.host_synth.tools_ti > 0) {
		if(this.host_synth.tools_ti > (this.host_synth.vms*0.1)) {
			this.host_badge.tools_ti = 'badge-danger addLink';
			tools_c = true;
		} else {
			this.host_badge.tools_ti = 'badge-warning addLink';
			tools_w = true;
		}
	}
	if(this.host_synth.tools_tu > 0) {
		if(this.host_synth.tools_tu > (this.host_synth.vms*0.1)) {
			this.host_badge.tools_tu = 'badge-danger addLink';
			tools_c = true;
		} else {
			this.host_badge.tools_tu = 'badge-warning addLink';
			tools_w = true;
		}
	}
	if(tools_w)
		this.tools_state = 'orange';
	if(tools_c)
		this.tools_state = 'red';
  }

  private analyseEvolution(sat_date_unix: number): string {

	var alert: string = '';
	let now = moment();
	const sat_date = moment.unix(sat_date_unix/1000);
	if(sat_date.year() < now.year()) {
		alert = 'danger';
	} else if(sat_date.year() == now.year()) {
		if((sat_date.dayOfYear() - now.dayOfYear()) < 180) {
			if((sat_date.dayOfYear() - now.dayOfYear()) < 30)
				alert = 'danger';
			else
				alert = 'warning';
		}
	}

	return alert;
  }

  private getClusterData(name: string): ClusterSynthesis {
	return this.data_clusters.find(cluster => cluster.name === name);
  }

  private getHostData(uuid: string): HostSynthesis {
	return this.data_hosts.find(host => host.uuid === uuid);
  }

  private callUpdateComment(item: string, value: string): void {

	this.settings_svc.updateComment(this.comment.ID, this.message.currentUuid, item, value).subscribe(
		error => {
			if(error != null)
				console.log(error)
		}
	);
  }

  private loadEvolution(): void {

	if(this.addEvolution != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(EvolutionsynthesisComponent);
		const viewContainerRef = this.addEvolution.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadTroubleshooting(): void {

	if(this.addTroubleshooting != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TroubleshootingcardComponent);
		const viewContainerRef = this.addTroubleshooting.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadHostConsOver(): void {

	if(this.addHostConsover2 != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsoverComponent);
		const viewContainerRef = this.addHostConsover2.viewContainerRef;
	  	viewContainerRef.clear();
		componentRef2 = viewContainerRef.createComponent(componentFactory);
	}
  }

  private reloadHostConsOver(): void {

	this.message.isReloadConsover = false;
	$( "div.dataTables_filter" ).remove();
	if(componentRef2 != undefined)
		componentRef2.destroy();

	setTimeout(() => this.loadHostConsOver(), 100);
  }

  /*private loadWeeklyCal(): void {

	if(this.addWeeklyCal != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WeeklycalComponent);
		const viewContainerRef = this.addWeeklyCal.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadMonthlyCal(): void {

	if(this.addMonthlyCal != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MonthlycalComponent);
		const viewContainerRef = this.addMonthlyCal.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadYearlyCal(): void {

	if(this.addYearlyCal != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(YearlycalComponent);
		const viewContainerRef = this.addYearlyCal.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }*/

  private loaddConsCal(): void {

    if(this.addConsCal != undefined) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConscalendarComponent);
      const viewContainerRef = this.addConsCal.viewContainerRef;
      const componentRef = viewContainerRef.createComponent(componentFactory);
    }
  }

  private loadVmList(): void {

	if(this.addVmlist != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(VmlistComponent);
		const viewContainerRef = this.addVmlist.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private initGraph(): void {

	this.isReady = false;
	this.isReady2 = false;
	this.isReady3 = false;
  }

  private callCpuBarGraph(data: any, cat: any, text: string, unit: string): void {

	this.chartOptions = {
        	chart: {
			type: 'bar',
            		height: '50%',
           		backgroundColor: '#ffffff'
		},
    		credits: {
        		enabled: false
		},
        	exporting: {
            		enabled: false
		},
		legend: {
            		enabled: false
        	},
		title: {
			text: text,
            		style: {
                		font: 'normal 12px arial',
                		color: 'grey'
            		},
			align: 'center'
		},
		xAxis: {
            		categories: cat
        	},
        	yAxis: {
            		visible: false
        	},
		plotOptions: {
            		bar: {
                		dataLabels: {
                    			enabled: true,
                    			color: 'grey',
                    			format: '{point.y} {series.name}'
                		}
            		},
            		series: {
                		enableMouseTracking: false
            		}
		},
		series: [{
	    		type: 'bar',
            		name: unit,
            		data: data
        	}]
    	};
	this.isReady = true;
  }

  private callRamBarGraph(data: any, cat: any, text: string, unit: string): void {

	this.chartOptions2 = {
        	chart: {
			type: 'bar',
            		height: '50%',
           		backgroundColor: '#ffffff'
		},
    		credits: {
        		enabled: false
		},
        	exporting: {
            		enabled: false
		},
		legend: {
            		enabled: false
        	},
		title: {
			text: text,
            		style: {
                		font: 'normal 12px arial',
                		color: 'grey'
            		},
			align: 'center'
		},
		xAxis: {
            		categories: cat
        	},
        	yAxis: {
            		visible: false
        	},
		plotOptions: {
            		bar: {
                		dataLabels: {
                    			enabled: true,
                    			color: 'grey',
                    			format: '{point.y} {series.name}'
                		}
            		},
            		series: {
                		enableMouseTracking: false
            		}
		},
		series: [{
	    		type: 'bar',
            		name: unit,
            		data: data
        	}]
    	};
	this.isReady2 = true;
  }

  private callBarGraphDis(data: any, cat: any, text: string, xtext: string, ytext: string): void {

	this.chartOptions = {
        	chart: {
			type: 'column',
            		height: '50%',
           		backgroundColor: '#ffffff'
		},
    		credits: {
        		enabled: false
		},
        	exporting: {
            		enabled: false
		},
		title: {
			text: text,
            		style: {
                		font: 'normal 12px arial',
                		color: 'grey'
            		},
			align: 'center',
	    		margin: 30
		},
		xAxis: {
            		type: 'category',
            		categories: cat,
            		title: {
                		text: xtext,
                		align: 'high'
            		}
        	},
        	yAxis: {
            		min: 0,
            		title: {
                		text: ytext
            		}
        	},
        	plotOptions: {
            		column: {
                		dataLabels: {
                    			enabled: true,
                    			color: 'grey'
                		}
            		},
            		series: {
                		enableMouseTracking: false
            		}
        	},
        	legend: {
            		enabled: true,
            		align: 'right',
            		verticalAlign: 'top',
            		layout: 'vertical',
            		x: 10,
            		y: -10
        	},
        	series: data
    	};
	this.isReady3 = true;
  }

  private getPowerElement(): any {

	for(let i in this.datas_green) {
		if(this.datas_green[i].IDENTIFIER === this.message.currentUuid) {
			return this.datas_green[i];
		}
	}
  }
}

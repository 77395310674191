<div class="card" >
    <div class="card-header card-ajust">
        <h5><clr-icon shape="slider"></clr-icon>&nbsp;{{title}}</h5>
    </div>
    <div class="card-block" style="height:260px">
        <div style="float:left;width:100%">
            <div style="margin:5px;padding:10px;text-align:center;float:left;width:20%;">
                <br>
                USED<br>
                FREE<br>
                TOTAL
            </div>
            <div role="tooltip" class="tooltip tooltip-lg tooltip-top-right" style="margin:5px;padding:5px;border-style:outset;border-radius:10px;background-color:#e8e8e8;text-align:center;float:left;width:23%;">
                <b>{{ram_label}}</b><br>
                {{formatNumber(ram_used)}} {{ram_unit}}<br>
                {{formatNumber(ram_free)}} {{ram_unit}}<br>
                {{formatNumber(ram_total)}} {{ram_unit}}<br>
                <span class="tooltip-content info-tooltip">{{ram_legend}}</span>
            </div>
            <div role="tooltip" class="tooltip tooltip-lg tooltip-top-right" style="margin:5px;padding:5px;border-style:outset;border-radius:10px;background-color:#e8e8e8;text-align:center;float:left;width:23%;">
                <b>{{cpu_label}}</b><br>
                {{formatNumber(cpu_used)}} {{cpu_unit}}<br>
                {{formatNumber(cpu_free)}} {{cpu_unit}}<br>
                {{formatNumber(cpu_total)}} {{cpu_unit}}<br>
                <span class="tooltip-content info-tooltip">{{cpu_legend}}</span>
            </div>
            <div role="tooltip" class="tooltip tooltip-lg tooltip-top-right" style="margin:5px;padding:5px;border-style:outset;border-radius:10px;background-color:#e8e8e8;text-align:center;float:left;width:23%;">
                <b>{{disk_label}}</b><br>
                {{formatNumber(disk_used)}} {{disk_unit}}<br>
                {{formatNumber(disk_free)}} {{disk_unit}}<br>
                {{formatNumber(disk_total)}} {{disk_unit}}<br>
                <span class="tooltip-content info-tooltip">{{disk_legend}}</span>
            </div>
        </div>
        <div style="float:left;width:100%">
            <div style="margin:5px;padding:10px;text-align:center;float:left;width:20%;">
                SATURATION
            </div>
            <div [style.color]="ram_satdate_color" style="margin:5px;padding:5px;border-style:outset;border-radius:10px;background-color:#e8e8e8;text-align:center;float:left;width:23%;">   
                <b>{{ram_satdate_format}}</b><br>
            </div>
            <div [style.color]="cpu_satdate_color" style="margin:5px;padding:5px;border-style:outset;border-radius:10px;background-color:#e8e8e8;text-align:center;float:left;width:23%;">
                <b>{{cpu_satdate_format}}</b><br>
            </div>
            <div [style.color]="disk_satdate_color" style="margin:5px;padding:5px;border-style:outset;border-radius:10px;background-color:#e8e8e8;text-align:center;float:left;width:23%;">
                <b>{{disk_satdate_format}}</b><br>
            </div>
        </div>

        <div style="float:left;width:100%">
            <div style="margin:5px;text-align:center;float:left;width:20%;">
                <b><clr-icon shape="slider"></clr-icon>
                Simulation</b>
            </div>
            <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left" [style.color]="ram_simudate_color" style="margin:5px;padding:5px;border-style:outset;border-radius:10px;background-color:white;text-align:center;float:left;width:23%;">
                <b >{{ram_simudate_format}}</b><br>
                <!--<span *ngIf="ram_simudate_format == 'KO'" class="tooltip-content ko-tooltip">FREE : {{ram_simu_free}} {{ram_simu_unit}}</span>-->
            </div>
            <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left" [style.color]="cpu_simudate_color" [style.color]="cpu_simudate_color" style="margin:5px;padding:5px;border-style:outset;border-radius:10px;background-color:white;text-align:center;float:left;width:23%;">
                <b>{{cpu_simudate_format}}</b><br>
                <!--<span *ngIf="cpu_simudate_format == 'KO'" class="tooltip-content ko-tooltip">FREE : {{cpu_simu_free}} {{cpu_simu_unit}}</span>-->
            </div>
            <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left" [style.color]="disk_simudate_color" [style.color]="disk_simudate_color" style="margin:5px;padding:5px;border-style:outset;border-radius:10px;background-color:white;text-align:center;float:left;width:23%;">
                <b>{{disk_simudate_format}}</b><br>
                <!--<span *ngIf="disk_simudate_format == 'KO'" class="tooltip-content ko-tooltip">FREE : {{disk_simu_free}} {{disk_simu_unit}}</span>-->
            </div>
        </div>

    </div> 
</div>
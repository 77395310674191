import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NetscopeService} from '../../services/netscope.service';
import {Router} from '@angular/router';
import * as d3 from 'd3';

import {JsonloaderService, LicenseService} from "@app/services";
import {environment} from "@environments/environment";
import {ClrDatagridSortOrder, ClrDatagridStringFilterInterface} from "@clr/angular";

class SourceFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.source !== undefined && item.source.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return false;
  }
}

class DestinationFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.target !== undefined && item.target.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return false;
  }
}

@Component({
  selector: 'app-netscope-protocol-analysis-component',
  templateUrl: './netscope-protocol-analysis.component.html',
  styleUrls: ['./netscope-protocol-analysis.component.css']
})
export class NetscopeProtocolAnalysisComponent implements OnInit, AfterViewInit, OnDestroy {

  jsonData = undefined;
  subscriptions = [];
  isLoading = false;
  svg;
  height = 1024;
  width = 768;
  chartRef;
  chartCheckInterval;

  sourceIpFilter = new SourceFilter();
  destinationIpFilter = new DestinationFilter();
  descSort = ClrDatagridSortOrder.DESC;

  isNetscopeLicenceEnabled = true;

  visualisationSettings = {
    only_flows_with_src_and_dst_in_the_filter: false
  };

  lastSvgWidth = 0;
  lastSvgHeight = 0;

  root = [];
  nodesMapping = {};
  allMetrics = [];
  timestamps = [];
  replayData;
  graphData = {
    links: [],
    directed_links: [],
    protocols: [],
    vms: [],
    routers: [],
    clusters: []
  };
  currentTime = 0;
  timeResolution = "daily";
  minTime = 0;
  maxTime = 0;

  perProtocolFlow = [];

  @ViewChild('dataSettingsPanel')
  dataSettingsPanel;

  allVms = [];
  allProtocols = [];

  filters = {
    protocols: {
    },
    source: {
    },
    destination: {}
  };

  isPlaying = false;
  currentRangeValue = 0;
  minRangeValue = 0;
  maxRangeValue = 0;

  currentSelection;
  focusLevel = 1;
  focusNode;
  zoomFunction;
  labelsLevel = [
    'protocols',
    'protocol',
    'source',
    'destination'
  ];
  SelectionIsAPortOrAValidProtocol = false;

  resourceSelectionChanged = new EventEmitter();
  clickOnTimeSlotEmitter = new EventEmitter();

  constructor(private netscopeService: NetscopeService, public licenceService: LicenseService, private route:Router, private jsonLoaderService: JsonloaderService) {
  }

  ngOnInit(): void {
    let subscription1 = this.jsonLoaderService.currentJsonSimpleSubject.subscribe(json => {
      this.jsonData = json;
      this.reloadData();
    });
    let subscription2 = this.jsonLoaderService.eventJsonAsyncLoaded.subscribe(json => {
      this.jsonData = json;
      this.reloadData();
    });
    this.subscriptions = [subscription1, subscription2];

    window.onresize = () => {
      if (this.jsonData !== undefined) {
        this.reloadVisualization();
      }
    };

    this.route.events.subscribe((event) => {
      this.ngOnDestroy();
    });

    this.licenceService.licenseInfo.subscribe(licenceInfo => {
      if (environment.production) {
        // Check second bit to be different than 0
        this.isNetscopeLicenceEnabled = (licenceInfo.moduleslicense & (1 << 1)) !== 0;
      } else {
        this.isNetscopeLicenceEnabled = true;
      }
    });

    this.clickOnTimeSlotEmitter.subscribe((event) => {
      console.log(event);
      // @ts-ignore
      let timestamp = event[0];
      this.currentTime = timestamp;
      this.timeResolution = event[2];
      this.reloadData();
    })
  }

  ngOnDestroy = () => {
    if (this.chartCheckInterval) {
      clearInterval(this.chartCheckInterval);
    }
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // Following function is in a setTimeout in order to prevent the "Expression has changed" error when running
      // the unit tests
      this.dataSettingsPanel.togglePanel();
    });
  }

  callbackChartRef = (ref) => {
    this.chartRef = ref;
  }

  reloadData = () => {
    this.isLoading = true;
    const timestampsAndCountsObservable = this.netscopeService.getDailyTimestamps();
    timestampsAndCountsObservable.subscribe((timestampsAndCounts) => {
      this.timestamps = timestampsAndCounts
          .filter((t) => t.start_time !== 0)
          .map((t) => t.start_time);
      if (this.timestamps.length > 0) {
        if (this.currentTime === 0) {
          this.currentTime = this.timestamps[this.timestamps.length - 1];
        }
      }
      this.isLoading = false;
      this.reloadVisualization();
    });
  }

  reloadVisualization = () => {
    const startTimeInterval = this.currentTime;
    const lastTimeInterval: any = Math.max(...this.timestamps);
    let endTimeInterval;
    if (this.timeResolution === "daily") {
      if (lastTimeInterval <= startTimeInterval) {
        endTimeInterval = 'now()';
      } else {
        endTimeInterval = Math.min(...this.timestamps.filter((t) => t > startTimeInterval), lastTimeInterval);
      }
    } else {
      endTimeInterval = startTimeInterval + 3600 - 1;
    }

    const vmsUuids = this.jsonData.vmSynthesis.map((vm) => vm.uuid)
    const hostsUuids = this.jsonData.hostSynthesis.map((host) => host.uuid)

    this.isLoading = true;
    this.netscopeService.getClustersWithFilters(startTimeInterval, endTimeInterval, vmsUuids, hostsUuids, true, this.timeResolution).subscribe((clustersData) => {
      this.isLoading = false;

      // Initialize replayData
      this.replayData = clustersData;
      // Group ports with unknown protocols
      let unknownProtocols = this.replayData.protocols.children.filter((c) => c.object.is_unknown);
      let knownProtocols = this.replayData.protocols.children.filter((c) => !c.object.is_unknown);
      this.replayData.protocols.children = knownProtocols;
      this.replayData.protocols.children.push({
        object: {
          "type": "protocol",
          "name": "unknown",
          "uuid": "unknown",
          "is_unknown": true,
          "port": -1
        },
        children: unknownProtocols
      });
      // Reload UI
      this.reloadUi();
    });
  }

  reloadUi = () => {
    const sortedTimes = this.timestamps
        .sort((a, b) => a - b);

    if (this.minTime === 0 || this.maxTime === 0) {
      this.minTime = Math.min(...sortedTimes);
      this.maxTime = Math.max(...sortedTimes);
      console.log(`setting replay start time at ${this.currentTime}`);
    }
    if (this.currentTime === 0) {
      this.currentTime = Math.max(...sortedTimes);
      console.log(`setting replay start time at ${this.currentTime}`);
    }
    // Set the range value
    this.minRangeValue = 0;
    this.maxRangeValue = sortedTimes.length - 1;
    const indexOfCurrentTime = sortedTimes.indexOf(this.currentTime);
    this.currentRangeValue = indexOfCurrentTime;
    // Set the right links
    this.graphData.links = this.replayData.links;
    this.graphData.directed_links = this.replayData.directed_links;
    this.graphData.protocols = this.replayData.protocols;
    this.graphData.vms = this.replayData.vms;
    this.graphData.routers = this.replayData.routers;
    this.graphData.clusters = this.replayData.clusters;
    // Compute ids and labels on protocol data
    this.setParents(this.graphData.protocols);
    this.computeIdsAndLabels(this.graphData.protocols);
    // Refresh network view
    this.eraseNetworkView();
    this.createNetworkView(this.graphData);
  }

  onTimeRangeChange = () => {
    const sortedTimes = this.timestamps
        .sort((a, b) => a - b);
    const requestedTime = sortedTimes[this.currentRangeValue];
    this.currentTime = sortedTimes[this.currentRangeValue];

    // Prevent a continuous slide to trigger many calls to "reloadData()"
    setTimeout(() => {
      if (this.currentTime === requestedTime) {
        this.reloadData();
      }
    }, 200);
  }

  play = () => {
    this.isPlaying = true;
    this.next();
  }

  pause = () => {
    this.isPlaying = false;
  }

  next = () => {
    if (!this.isPlaying) {
      return;
    }
    const sortedTimestamps = this.timestamps.map((d) => d).sort();
    const firstTimestamp = Math.min(...sortedTimestamps);
    const lastTimestamp = Math.max(...sortedTimestamps);
    if (this.currentTime === 0 || this.currentTime === lastTimestamp) {
      this.currentTime = firstTimestamp;
    } else {
      const indexOfCurrentTimestamp = sortedTimestamps.indexOf(this.currentTime);
      this.currentTime = sortedTimestamps[indexOfCurrentTimestamp + 1];
    }

    this.reloadData();

    if (this.currentTime !== lastTimestamp) {
      setTimeout(() => {
        this.next();
      }, 1000);
    } else {
      this.isPlaying = false;
    }
  }

  _recursiveGenerateFlows(node, result=[]) {
    if (node.children !== undefined && node.children.length > 0) {
      for (let child of node.children) {
        this._recursiveGenerateFlows(child, result);
      }
    } else {
      if (node.data.role === "destination") {
        result.push({
          target: node.data.object,
          source: node.parent.data.object,
          port: node.parent.parent.data.object.port,
          protocol: node.parent.parent.data.object.is_unknown ? "unknown" : node.parent.parent.data.object.name,
          exchanged_bytes: node.value
        });
      }
    }
    return result;
  }


  updatePerProtocolFlows = () => {
    this.perProtocolFlow.splice(0, this.perProtocolFlow.length);
    this._recursiveGenerateFlows(this.currentSelection[this.currentSelection.length - 1], this.perProtocolFlow);
  }

  /**
   * This method export the data table into a CSV file
   */
  exportCSV() {
    let csvHeader = "Source, Destination, Port, Protocol, ExchangedBytes"
    let csvContent = csvHeader+"\n";
    for (let flow of this.perProtocolFlow) {
      let lineValue = `${flow.source.name}, ${flow.target.name}, ${flow.port}, ${flow.protocol}, ${flow.exchanged_bytes}\n`;
      csvContent += lineValue;
    }

    let exportedFilename = 'netscope-protocols.csv';
    let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  exportGraph = () => {
    let svgBody = this.svg.html();
    let svgCode = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${svgBody}</svg>`;
    let exportedFilename = 'netscope-protocols.svg';

    let blob = new Blob([svgCode], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  createNetworkView(graphData): void {
    d3.select('div#divSvg').select('svg').remove();
    this.svg = d3.select('div#divSvg')
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .append('g')
        .attr('id', 'graph_svg');
    this.refreshNetworkView(graphData);
  }

  eraseNetworkView(): void {
    d3.select('div#divSvg')
        .selectAll('*')
        .remove();
  }

  fixUuidToBeUsedInCirclesId = (uuid) => {
    return uuid
        .replace(":", "_")
        .replace(".", "_");
  }

  computeSvgId = (data) => {
    let prefix = "";
    if (data.object.parent !== null && data.object.parent !== undefined) {
      prefix = this.computeSvgId(data.object.parent);
    }
    let label = this.computeLabel(data);
    let fixedUuid = this.fixUuidToBeUsedInCirclesId(data.object.uuid);
    // @ts-ignore
    return `${prefix}_${label}_${fixedUuid}`;
  }

  computeLabel = (data) => {
    // Compute the left part of the text inside circles.
    // By default, it uses the labelsLevel, and if a description is provided from the API response,
    // then it uses the label returned from the API.
    let label = this.labelsLevel[data.depth];
    // @ts-ignore
    if (data.object.type !== undefined) {
      // @ts-ignore
      label = data.object.type;
    }
    // Also, VMs can be source or destination. This information is set in "d.data.role" property.
    // It will override the value computed above.
    // @ts-ignore
    if (data.role !== undefined) {
      // @ts-ignore
      label = data.role;
    }
    return label;
  }

  computeIdsAndLabels = (protocolData) => {
    protocolData.object.svg_id = this.computeSvgId(protocolData);
    protocolData.object.svg_label = this.computeLabel(protocolData);
    for (let child of protocolData.children) {
      this.computeIdsAndLabels(child);
    }
  }

  setParents = (protocolData) => {
    for (let child of protocolData.children) {
      child.object.parent = protocolData
      this.setParents(child);
    }
  }

  refreshNetworkView(graphData): void {
    const graphSvg = d3.select('g#graph_svg');
    graphSvg.selectAll('*').remove();

    let svgHeight = document.getElementById('divSvg').clientHeight;
    let svgWidth = document.getElementById('divSvg').clientWidth;

    if (svgHeight == 0) {
      svgHeight = this.lastSvgHeight;
    } else {
      this.lastSvgHeight = svgHeight;
    }

    if (svgWidth == 0) {
      svgWidth = this.lastSvgWidth;
    } else {
      this.lastSvgWidth = svgWidth;
    }

    if (svgHeight == 0) {
      svgHeight = this.lastSvgHeight;
    } else {
      this.lastSvgHeight = svgHeight;
    }

    if (svgWidth == 0) {
      svgWidth = this.lastSvgWidth;
    } else {
      this.lastSvgWidth = svgWidth;
    }

    const viewCenterX = svgWidth / 2;
    const viewCenterY = svgHeight / 2;

    const margin = 40;
    const diameter = Math.min(svgWidth, svgHeight);

    const color = d3.scaleLinear()
        .domain([0, 5])
        // @ts-ignore
        .range(['hsl(152,80%,80%)', 'hsl(228,30%,40%)'])
        // @ts-ignore
        .interpolate(d3.interpolateHcl);

    const pack = d3.pack()
        .size([svgWidth, svgHeight])
        .padding(2);

    const root = d3.hierarchy(this.replayData.protocols)
        .sum(function(d) { return d.size; })
        .sort(function(a, b) { return b.value - a.value; });

    let focus = root;
    const nodes = pack(root).descendants();
    let view;

    const g = graphSvg.append('g')
        .attr('transform', 'translate(' + diameter / 2 + ',' + diameter / 2 + ')');

    const visuNode = g
        .selectAll('g')
        .data(nodes)
        .enter()
        .append('g')
        .attr('class', 'circleGroup');

    const self = this;

    const circle = visuNode
        .append('circle')
        .attr('class', function(d) { return d.parent ? d.children ? 'node' : 'node node--leaf' : 'node node--root'; })
        // @ts-ignore
        .attr('id', (d) => d.data.object.svg_id)
        .style('fill', function(d) {
          return color(d.depth);
        })
        .style('cursor', 'pointer')
        // @ts-ignore
        .on('click', function(d, i) {
          if (focus !== i) {
            d.stopPropagation();
            zoom(i);
          }
        });

    const text = visuNode
        .append('text')
        .attr('id', (d) => {
          // @ts-ignore
          return `${d.data.object.svg_id}_text`;
        })
        .attr('class', 'textLabel')
        .style("text-anchor", "middle")
        .style('display', function(d) { return 'none'; })
        .text((d) => {
          // @ts-ignore
          let label = d.data.object.svg_label;
          // @ts-ignore
          return `${label}: ${d.data.object.name}`;
        })
        .each((d) => {
          // @ts-ignore
          d.circleId = d.data.object.svg_id;
          // @ts-ignore
          d.circle = $(`#${d.circleId}`)[0];
          return d;
        })
        .style('cursor', 'pointer')
        // @ts-ignore
        .on('click', function(d, i) {
          if (focus !== i) {
            d.stopPropagation();
            zoom(i);
          }
        });

    const node = g.selectAll('circle,text');

    graphSvg
        .style('background', color(-1));
    // // @ts-ignore
    // .on('click', function() { zoom(root); });

    let previousData;

    const zoom = (d) => {
      const focus0 = focus;
      focus = d;
      this.focusLevel = focus.depth + 1;
      this.focusNode = focus;

      this.currentSelection = [];
      let next = focus;
      while (next !== undefined && next !== null) {
        this.currentSelection.unshift(next);
        next = next.parent;
      }
      this.updatePerProtocolFlows();

      let isMatchingNode = (node) => {
        if (this.currentSelection.indexOf(node) !== -1) {
          return true;
        }
        if (this.focusNode.children !== undefined && this.focusNode.children.indexOf(node) !== -1) {
          return true;
        }
        return false;
      };

      let isParentNode = (node) => {
        return this.currentSelection.indexOf(node) !== -1 && node.children !== undefined && node.children.length > 0;
      }

      let matchingVisuNode = visuNode
          .filter((d2) => isMatchingNode(d2));

      let nonMatchingVisuNode;
      if (previousData !== undefined) {
        nonMatchingVisuNode = previousData.matchingVisuNode;
      }
      else {
        nonMatchingVisuNode= visuNode
            .filter((d2) => !isMatchingNode(d2));
      }

      let parentNodes = visuNode
          .filter((d2) => isParentNode(d2));

      setTimeout(() => {
        const transition = d3.transition()
            // @ts-ignore
            .duration(750)
            .tween('zoom', function (d2) {
              // @ts-ignore
              const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2 + margin]);
              return function (t) {
                zoomTo(i(t));
              };
            });
      }, 50);

      nonMatchingVisuNode
          .selectAll('.textLabel')
          .style('display', (d2) => 'none');

      // Hide other circles
      nonMatchingVisuNode
          .selectAll('circle')
          .style('display', (d2) => 'none');

      // Show circles that are in [0, this.focusLevel] and the circles just below
      matchingVisuNode
          .selectAll('circle')
          .style('display', (d2) => '');

      matchingVisuNode
          .selectAll('.textLabel')
          .style('display', (d2) => 'inline');

      parentNodes
          .selectAll('.textLabel')
          .style('display', (d2)  => 'none');

      function updateTextSize(minimumIteration, currentIterationCount=0, startTime=-1, minimumDuration=2.0) {
        if (startTime == -1) {
          startTime = Math.floor(new Date().getTime()/1000.0);
        }
        const text2 = matchingVisuNode
            .select('.textLabel')
            .filter((d2) => d2 !== focus.parent)
            .style("font-size", (d, i) => {
              // @ts-ignore
              let newTextWidth = d.circle.r.animVal.value / 5;

              // @ts-ignore
              let textId = `${d.circleId}_text`;
              if (!d.hasOwnProperty(textId)) {
                // @ts-ignore
                let textSvgElement = document.getElementById(textId);
                // @ts-ignore
                let currentTextWidth = textSvgElement.getComputedTextLength();
                let currentFontSize = parseFloat(textSvgElement.style['font-size'].replace('px', ''));

                if (currentTextWidth !== 0 && !isNaN(currentFontSize)) {
                  // @ts-ignore
                  d[textId] = currentFontSize / currentTextWidth;
                }
              }

              if (d.hasOwnProperty(textId)) {
                // @ts-ignore
                newTextWidth = (0.90 * 2 * newTextWidth * 5) * d[textId];
              }
              // @ts-ignore
              d.textWidth = newTextWidth;
              // @ts-ignore
              return `${d.textWidth}px`;
            });
        let now = Math.floor(new Date().getTime()/1000.0);
        if (currentIterationCount < minimumIteration || (now - startTime < minimumDuration)) {
          setTimeout(() => {
            updateTextSize(minimumIteration, currentIterationCount + 1, startTime, minimumDuration);
          }, 1);
        }
      }

      updateTextSize(10);
      setTimeout(() => {
        updateTextSize(10);
      }, 1000);

      previousData = {
        matchingVisuNode: matchingVisuNode,
        nonMatchingVisuNode: nonMatchingVisuNode,
        parentNodes: parentNodes,
      }
    };

    function zoomTo(v) {
      const k = diameter / v[2];
      view = v;
      // @ts-ignore
      node.attr('transform', function(d) { return 'translate(' + (d.x - v[0]) * k + ',' + (d.y - v[1]) * k + ')'; });
      circle.attr('r', function(d) { return d.r * k; });
    }

    // @ts-ignore
    zoomTo([root.x, root.y, root.r * 2 + margin]);
    zoom(root);
    this.currentSelection = [root];
    this.updatePerProtocolFlows();

    this.zoomFunction = zoom;
  }

  zoomOnNode = (d) => {
    this.zoomFunction(d);
  }

  showGraph = (selectionItem) => {
    // @ts-ignore
    let [rawObjectTypeName, objectUuid] = selectionItem.data.object.uuid.split(":");
    // @ts-ignore
    let objectTypeName = selectionItem.data.object.type == "host" ? "SERVER" : "VM";
    this.route.navigate(['god/resource/', objectUuid], { queryParams: { useResourceCountersFor: objectTypeName }} );
  }
}

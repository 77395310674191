<div class="clr-row" *ngIf="message.creditSelected == 'cost'">
        <div class="card">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
                                <clr-icon shape="dollar"></clr-icon>
                                COSTS
                        </span>
                        <span style="float: right;" ngClass="addLink" (click)="message.creditSelected = ''">
				<clr-icon shape="times" size="24"></clr-icon>
			</span>
                </div>
                <div class="card-block">
                        <div class="clr-row">
                                <div [ngClass]="graphClass">
                                        <clr-toggle-container clrInline>
                                                        <clr-toggle-wrapper>
                                                                <input id="spec-cloud-provider-aws-costgraph" type="checkbox" clrToggle name="iCostYear" value="isCostYear" [(ngModel)]="isCostYear" (ngModelChange)="reloadGraph()" />
                                                                <label>
                                                                        the last months
                                                                </label>
                                                        </clr-toggle-wrapper>
                                                        <clr-control-helper>display month by month</clr-control-helper>
                                        </clr-toggle-container>
                                        <highcharts-chart
						id="spec-cloud-provider-aws-costgraph2"
                                                [Highcharts] = "highcharts"
                                                [options] = "chartOptions"
                                                [constructorType]="'chart'"
                                                style = "width: 100%;display: block;" *ngIf="isReady">
                                        </highcharts-chart>
                                </div>
				<div class="clr-col-lg-8 clr-col-md-8 clr-col-12" *ngIf="!isCostYear">
                                        <div class="clr-row">
                                                <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                        <div class="clr-control-container">
                                                                <div class="clr-select-wrapper">
                                                                        <select class="clr-select"
                                                                                [(ngModel)]="model.selectedMonth"
                                                                                (ngModelChange)="loadMonth($event)">
                                                                                <option *ngFor="let month of months">{{month.name}}</option>
                                                                        </select>
                                                                </div>
                                                                <span class="clr-subtext">select month to display</span>
                                                        </div>
                                                </div>
                                                <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
                                                        <clr-checkbox-container>
                                                                <clr-checkbox-wrapper>
                                                                        <input id="overview-cost-detail-cb" type="checkbox" 
                                                                                clrCheckbox name="OverviewCostDetailCb" 
                                                                                [(ngModel)]="model.isModel"
                                                                                (change)="updateList()" />
                                                                        <label>current model</label>
                                                                </clr-checkbox-wrapper>
                                                        </clr-checkbox-container>
                                                </div>
                                                <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
                                                        <button class="btn btn-link" style="position:relative;float:right;" (click)="exportCosts()">
                                                                Export Costs
                                                        </button>
                                                </div>
                                        </div>
					<clr-datagrid [(clrDgSingleSelected)]="selected_instance" (clrDgSingleSelectedChange)="loadVmGraph($event)">
						<clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                                                <clr-dg-column [clrDgField]="'message.cloudAccount'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Account</ng-container></clr-dg-column>
                                                <clr-dg-column [clrDgField]="'state'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'compute'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute cost ($/month)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'storage'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Storage cost ($/month)</ng-container></clr-dg-column>
						<clr-dg-column [clrDgField]="'total'" [clrDgSortOrder]="dataOrder2"><ng-container *clrDgHideableColumn="{hidden: false}">Total cost ($/month)</ng-container></clr-dg-column>
						<clr-dg-row *clrDgItems="let costInstance of costInstances" [clrDgItem]="costInstance">
							<clr-dg-cell>{{costInstance.id}}</clr-dg-cell>
							<clr-dg-cell>{{costInstance.name}}</clr-dg-cell>
                                                        <clr-dg-cell>{{message.cloudAccount}}</clr-dg-cell>
                                                        <clr-dg-cell>{{costInstance.state}}</clr-dg-cell>
							<clr-dg-cell>{{costInstance.compute.toFixed(2)}}</clr-dg-cell>
                                                        <clr-dg-cell>{{costInstance.storage.toFixed(2)}}</clr-dg-cell>
                                                        <clr-dg-cell>{{costInstance.total.toFixed(2)}}</clr-dg-cell>
						</clr-dg-row>
						<clr-dg-footer>
							<clr-dg-pagination #pagination [clrDgPageSize]="10">
								<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
									entries per page
								</clr-dg-page-size>
								{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
							</clr-dg-pagination>
						</clr-dg-footer>
                                        </clr-datagrid>
                                </div>
                        </div>
                </div>
        </div>
</div>

<div class="card">
	<div class="card-header card-ajust">
		<div class="clr-row">
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
				<h5>
					<clr-icon shape="table"></clr-icon>
					&nbsp;Yearly consumption for {{message.currentName}} 
				</h5>
			</div>
			<div class="clr-col-lg-6 clr-col-md-6 clr-col-12" *ngIf="message.calcons_counter != 'power_float'">
				<clr-radio-container clrInline style="float: right;">
					<clr-radio-wrapper>
						<input id="yearly-radio-maxval" type="radio" clrRadio name="options" required (change)="updateYearlyOptions($event)" [(ngModel)]="isMinMaxValue" [value]=true />
						<label>[min - max] (%)</label>
  					</clr-radio-wrapper>
					<clr-radio-wrapper>
						<input id="yearly-radio-100" type="radio" clrRadio name="options" required (change)="updateYearlyOptions($event)" [(ngModel)]="isMinMaxValue" [value]=false />
						<label>all (%)</label>
					</clr-radio-wrapper>
					<clr-control-helper>Display according to the counter value</clr-control-helper>
					<clr-control-error>This field is required!</clr-control-error>
				</clr-radio-container>
			</div>
		</div>
	</div>
	<div class="card-block">
		<div class="clr-row">
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
				<button (click)="previous()" class="btn btn-link" [disabled]="isNoPrevData">
					<clr-icon shape="angle" style="transform:rotate(-90deg)"></clr-icon>
				</button>
				&nbsp;&nbsp;
				&nbsp;&nbsp;
				<button (click)="next()" class="btn btn-link" [disabled]="isNoNextData">
					<clr-icon shape="angle" style="transform:rotate(90deg)"></clr-icon>
				</button>
				&nbsp;&nbsp;
				<span>{{yearly_range_date}}</span>
			</div>
			<highcharts-chart
				[Highcharts]="Highcharts"
				[constructorType]="'chart'"
				[options]="chartOptions"
				style="width: 100%; height: 100%; display: block;" *ngIf="isReady">
			</highcharts-chart>
		</div>
	</div>
</div>
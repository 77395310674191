import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

import * as moment from 'moment';

import { JSONTarget, Message, User } from '@app/model';

import { KpitableComponent } from '@app/kpi/kpitable/kpitable.component';
import { KpitableDirective } from '@app/directives/kpitable.directive';


@Component({
  selector: 'app-kpiactivity',
  templateUrl: './kpiactivity.component.html',
  styleUrls: ['./kpiactivity.component.css']
})
export class KpiactivityComponent implements OnInit {

  @ViewChild(KpitableDirective) addTable: KpitableDirective;

  highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;

  isReady: boolean = false;

  message: Message;

  activity_data: any;

  col_class: string = 'clr-col-lg-12 clr-col-md-12 clr-col-12';

  table_title: string = '';

  isTable: boolean = false;

  private currentUser: User;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.message_svc.currentMessage.subscribe(message => this.message = message);

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.KPI_ACTIVITY).subscribe(
		data => {
			this.activity_data = data;
			this.buildCard();
		}
	);
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  hideTable(): void {

	this.isTable = false;
	this.isReady = false;
	this.col_class = 'clr-col-lg-12 clr-col-md-12 clr-col-12';
	setTimeout(() =>this.buildCard(), 100);
  }

  private buildTable(date: string, type: string): void {
	  
	this.message.kpiActivity = type;

	this.isTable = true;

	const firstDay: string = moment(date, 'YYYY-MM').startOf('month').format('x');
	const endDay: string = moment(date, 'YYYY-MM').endOf('month').format('x');
	this.message.kpiStart = +firstDay;
	this.message.kpiEnd = +endDay;

	const date_str: string = moment(date, 'YYYY-MM').startOf('month').format('MMMM YYYY');
	switch(type) {
		case "add":
			this.table_title = 'VM ADDED on ' + date_str;
			break;
		case "off":
			this.table_title = 'VM OFF on ' + date_str;
			break;
		case "del":
			this.table_title = 'VM DELETED on ' + date_str;
			break;
		case "suspend":
			this.table_title = 'VM SUSPENDED on ' + date_str;
			break;
		default:
			break;
	}
	if(this.col_class == "clr-col-lg-12 clr-col-md-12 clr-col-12") {
		this.col_class = 'clr-col-lg-6 clr-col-md-6 clr-col-12';
		this.isReady = false;
		setTimeout(() =>this.buildCard(), 100);
	}
	setTimeout(() =>this.loadTable(), 100);
  }

  private buildCard(): void {

	// Fix the the VMADD to 0 for the 1st month
	//this.activity_data['VMADD'][0] = 0;

	this.chartOptions = {
		credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                title: {
                    text: 'Monthly Average VM number'
                },
                xAxis: {
                    categories: this.activity_data['month'],
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'VM number'
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.0f}</b><br/>',
                    shared: true,
                    useHTML: true
		},
		plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [{
                    type: 'column',
                    name: 'VMRUN',
                    data: this.activity_data['vmrun']
                }, {
                    type: 'column',
                    name: 'VMOFF',
                    cursor: 'pointer',
		    point: {
                    	events: {
                        	click: function(e) {
		    			const p = e.point;
		    			this.buildTable(p.category, 'off');
                        	}.bind(this)
                    	}
		    },
                    data: this.activity_data['vmoff']
		}, {
		    type: 'column',
                    name: 'VMSUSPEND',
                    cursor: 'pointer',
		    point: {
                    	events: {
                        	click: function(e) {
		    			const p = e.point;
		    			this.buildTable(p.category, 'suspend');
                        	}.bind(this)
                    	}
		    },
                    data: this.activity_data['vmsus']
                }, {
                    type: 'spline',
                    name: 'VMALL TREND',
                    data: this.activity_data['alltrend'],
                    marker: {
                        lineWidth: 2,
                        lineColor: Highcharts.getOptions().colors[3],
                        fillColor: 'white'
                    }
		}, {
		type: 'column',
                    name: 'VMADD',
                    cursor: 'pointer',
		    point: {
                    	events: {
                        	click: function(e) {
		    			const p = e.point;
		    			this.buildTable(p.category, 'add');
                        	}.bind(this)
                    	}
		    },
                    data: this.activity_data['vmadd']
                }, {
                    type: 'column',
                    name: 'VMDEL',
                    cursor: 'pointer',
		    point: {
                    	events: {
                        	click: function(e) {
		    			const p = e.point;
		    			this.buildTable(p.category, 'del');
                        	}.bind(this)
                    	}
		    },
                    data: this.activity_data['vmdel']
		}]
	};

	this.isReady = true;
  }

  private loadTable(): void {

	if(this.addTable != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KpitableComponent);
		const viewContainerRef = this.addTable.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

}
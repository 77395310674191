import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-t9]'
})
export class T9Directive {

  constructor(public viewContainerRef9: ViewContainerRef) { }

}


<div class="clr-row">
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card" style="height:205px">
			<div class="card-header" style="color:grey;">
				<clr-icon shape="storage"></clr-icon>&nbsp;
				<span>Datastores</span>
			</div>
			<div class="card-block">
				<span>Current selection: {{model.current_sto_msg}}</span><span *ngIf="model.current_sto_msg == 'generic cost'">&nbsp;(<b>{{message.costStoGeneric}} {{globalCurrency}}</b>)</span><br><br>
				<span>Last Update {{moment(model.current_sto_date).format('MMMM Do YYYY')}}</span>
			</div>
			<div class="card-footer">
				<button class="btn btn-outline" style="float:right" (click)="isStoDetail = true;message.costType = model.current_sto;loadDetails()">Edit</button>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card" style="height:205px">
			<div class="card-header" style="color:grey;">
				<clr-icon shape="host"></clr-icon>&nbsp;
				<span>Servers</span>
			</div>
			<div class="card-block">
				<span>Current selection: {{model.current_server_msg}}</span><span *ngIf="model.current_server_msg == 'generic cost'">&nbsp;(<b>{{message.costSrvGeneric}} {{globalCurrency}}</b>)</span><br><br>
				<span>Last Update {{moment(model.current_server_date).format('MMMM Do YYYY')}}</span>
			</div>
			<div class="card-footer">
				<button class="btn btn-outline" style="float:right" (click)="isServerDetail = true;message.costType = model.current_server;loadDetails()">Edit</button>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
		<div class="card" style="height:205px">
			<div class="card-header" style="color:grey;">
				<clr-icon shape="vm"></clr-icon>&nbsp;
				<span>Virtual Machines</span>
			</div>
			<div class="card-block">
				<span>Current selection: {{model.current_vm_msg}}</span><span *ngIf="model.current_vm_msg == 'generic cost'">&nbsp;(<b>{{message.costVmGeneric}} {{globalCurrency}}</b>)</span><br><br>
				<span>Last Update {{moment(model.current_vm_date).format('MMMM Do YYYY')}}</span>
			</div>
			<div class="card-footer">
				<button class="btn btn-outline" style="float:right" (click)="isVmDetail = true;message.costType = model.current_vm;loadDetails()">Edit</button>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<br>
		<button type="button" class="btn btn-danger-outline" aria-label="settings" (click)="updateCost()" *ngIf="message.isCostRecord" style="float:left">
			Confirm update to processing
		</button>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isVmDetail">
		<clr-tabs>
			<clr-tab>
        			<button clrTabLink (click)="loadFixed()">FIXED cost</button>
				<clr-tab-content *clrIfActive="true">
					<div class="card">
						<div class="card-header card-ajust">
							<div class="clr-row">
								<div class="clr-col-2">
									<h5>
										<clr-icon shape="vm"></clr-icon>
										&nbsp;Virtual Machines
									</h5>
								</div>
								<div class="clr-col-2">
									<div class="clr-form-control">
										<div class="clr-control-container">
											<div class="clr-select-wrapper">
												<select id="select-vm-cost" class="clr-select" [(ngModel)]="message.costType" (ngModelChange)="loadDetails()">
													<option value="generic_vm">generic cost</option>
													<option value="detail_vm">individual cost</option>
												</select>
											</div>
											<span class="clr-subtext">Select the cost type</span>
										</div>
									</div>
								</div>
								<div class="clr-col-8">
									<br><span class="label label-info">{{model.current_vm_info}}</span>
								</div>
							</div>
							<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
							<span class="search" *ngIf="message.costType == 'detail_vm'"></span>
						</div>
						<div class="card-block">
							<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
							<ng-template load-costgeneric-vm></ng-template>
							<ng-template load-costdetail-vm></ng-template>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="closeHide()">
								HIDE DETAILS
							</button>
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="message.isCostTool = true" *ngIf="message.costType == 'detail_vm'">
								QUICK TOOL SELECTION
							</button>
						</div>
					</div>
				</clr-tab-content>
			</clr-tab>
			<clr-tab>
        			<button clrTabLink (click)="loadTag()">TAG cost</button>
				<clr-tab-content *clrIfActive="false">
					<div class="card">
						<div class="card-block">
							<ng-template load-costtag-vm></ng-template>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="closeHide()">
								HIDE DETAILS
							</button>
						</div>
					</div>
				</clr-tab-content>
			</clr-tab>
		</clr-tabs>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isServerDetail">
		<div class="card">
			<div class="card-header card-ajust">
				<div class="clr-row">
					<div class="clr-col-2">
						<h5>
							<clr-icon shape="host"></clr-icon>
							&nbsp;Servers
						</h5>
					</div>
					<div class="clr-col-2">
						<div class="clr-form-control">
							<div class="clr-control-container">
								<div class="clr-select-wrapper">
									<select id="select-vm-cost" class="clr-select" [(ngModel)]="message.costType" (ngModelChange)="loadDetails()">
										<option value="generic_server">generic cost</option>
										<option value="detail_server">individual cost</option>
									</select>
								</div>
								<span class="clr-subtext">Select the cost type</span>
							</div>
						</div>
					</div>
					<div class="clr-col-8">
						<br><span class="label label-info">{{model.current_server_info}}</span>
					</div>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search" *ngIf="message.costType == 'detail_server'"></span>
			</div>
			<div class="card-block">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<ng-template load-costgeneric-server></ng-template>
				<ng-template load-costdetail-server></ng-template>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="closeHide()">
					HIDE DETAILS
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="message.isCostTool = true" *ngIf="message.costType == 'detail_server'">
					QUICK TOOL SELECTION
				</button>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isStoDetail">
		<div class="card">
			<div class="card-header card-ajust">
				<div class="clr-row">
					<div class="clr-col-2">
						<h5>
							<clr-icon shape="storage"></clr-icon>
							&nbsp;Datastores
						</h5>
					</div>
					<div class="clr-col-2">
						<div class="clr-form-control">
							<div class="clr-control-container">
								<div class="clr-select-wrapper">
									<select id="select-vm-cost" class="clr-select" [(ngModel)]="message.costType" (ngModelChange)="loadDetails()">
										<option value="generic_sto">generic cost</option>
										<option value="detail_sto">individual cost</option>
									</select>
								</div>
								<span class="clr-subtext">Select the cost type</span>
							</div>
						</div>
					</div>
					<div class="clr-col-8">
						<br><span class="label label-info">{{model.current_sto_info}}</span>
					</div>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<span class="search" *ngIf="message.costType == 'detail_sto'"></span>
			</div>
			<div class="card-block">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
				<ng-template load-costgeneric-sto></ng-template>
				<ng-template load-costdetail-sto></ng-template>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="closeHide()">
					HIDE DETAILS
				</button>
				<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="message.isCostTool = true" *ngIf="message.costType == 'detail_sto'">
					QUICK TOOL SELECTION
				</button>
			</div>
		</div>
	</div>
</div>

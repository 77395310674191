import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {NetscopeService} from '../../services/netscope.service';
import {ActivatedRoute, Router} from '@angular/router';
import { of } from 'rxjs';
import {JsonloaderService, LicenseService} from "@app/services";
import {ClrDatagridSortOrder, ClrDatagridStringFilterInterface} from "@clr/angular";
import {environment} from "@environments/environment";
import {
  HeatmapComboboxComponent
} from "@app/netscope/netscope-heatmap-viewer/heatmap-combobox/heatmap-combobox.component";

class SourceFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.source !== undefined && item.source.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return false;
  }
}

class DestinationFilter implements ClrDatagridStringFilterInterface<any> {
  accepts(item: any, search: string): boolean {
    if (item.target !== undefined && item.target.name.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true;
    }
    return false;
  }
}

function formatVolumetry(value) {
  let displayedValue = value;
  let displayedUnit = "B"

  // @ts-ignore
  if (value > 0) {
    let units = [[Math.pow(1000, 4), "TB"], [Math.pow(1000, 3), "GB"], [Math.pow(1000, 2), "MB"], [Math.pow(1000, 1), "KB"]]
    // Find right units
    // @ts-ignore
    let selectedUnit = units.filter((u) => u[0] <= value).reduce((prev, curr) => prev[0] > curr[0] ? prev : curr);

    // @ts-ignore
    displayedValue = Math.round((1.0 * value) / selectedUnit[0]);
    // @ts-ignore
    displayedUnit = selectedUnit[1];
  }

  return `${displayedValue}${displayedUnit}`;
}


@Component({
  selector: 'app-netscope-heatmap-viewer-clusters',
  templateUrl: './netscope-heatmap-viewer.component.html',
  styleUrls: ['./netscope-heatmap-viewer.component.css']
})
export class NetscopeHeatmapViewerComponent implements OnInit {

  jsonData = undefined;
  subscriptions = [];
  isLoading = false;

  resourceUuidToObjectsIndex = {};
  heatmapFlowsCombinations = [];
  selectedResources1: any[] = [];
  selectedResources2: any[] = [];
  flowDetails;
  displayedTime = "";

  @ViewChild('vm2Combobox') vm2ComboboxRef: HeatmapComboboxComponent;

  reloadButtonColorClass = "btn-primary";

  sourceIpFilter = new SourceFilter();
  destinationIpFilter = new DestinationFilter();
  descSort = ClrDatagridSortOrder.DESC;

  isNetscopeLicenceEnabled = true;

  resourceSelectionChanged = new EventEmitter();
  clickOnTimeSlotEmitter = new EventEmitter();

  constructor(private netscopeService: NetscopeService, public licenceService: LicenseService, private activatedRouter: ActivatedRoute, private route:Router, private jsonLoaderService: JsonloaderService) {
  }

  ngOnInit(): void {

    let subscription1 = this.jsonLoaderService.currentJsonSimpleSubject.subscribe(json => {
      this.jsonData = json;
      this.buildResourceUuidToObjectIndex();
      // Hack: check url parameters here, and set the two given resources
      this.activatedRouter.params.subscribe((params) => {
        let resource_uuid_1 = params["resource_uuid_1"];
        let resource_uuid_2 = params["resource_uuid_2"];

        if (resource_uuid_1 !== undefined && resource_uuid_2 !== undefined) {
          this.selectedResources1 = [this.resourceUuidToObjectsIndex[resource_uuid_1]]
          this.selectedResources2 = [this.resourceUuidToObjectsIndex[resource_uuid_2]]
        }

        this.reloadData();
      })
    });
    let subscription2 = this.jsonLoaderService.eventJsonAsyncLoaded.subscribe(json => {
      this.jsonData = json;
      this.buildResourceUuidToObjectIndex();
      this.reloadData();
    });
    this.subscriptions = [subscription1, subscription2];

    window.onresize = () => {
      if (this.jsonData !== undefined) {
        this.reloadUi("*");
      }
    };

    this.licenceService.licenseInfo.subscribe(licenceInfo => {
      if (environment.production) {
        // Check second bit to be different than 0
        this.isNetscopeLicenceEnabled = (licenceInfo.moduleslicense & (1 << 1)) !== 0;
      } else {
        this.isNetscopeLicenceEnabled = true;
      }

      if (this.isNetscopeLicenceEnabled) {
        this.reloadData();
      }
    });

    this.route.events.subscribe((event) => {
      this.ngOnDestroy();
    });

    this.clickOnTimeSlotEmitter.subscribe((event) => {
      this.loadFlows(event[0]);
      this.displayedTime = event[1];
    })
  }

  vm1SelectionChanged = () => {
    this.selectedResources2 = [];
    setTimeout(() => {
      // @ts-ignore
      this.fetchVms2('', this.vm2ComboboxRef);
    }, 100);
    this.reloadUi('*');
  }

  vm2SelectionChanged = () => {
    // this.displayHeatmap();
    this.resourceSelectionChanged.emit({"update": true});
  }

  fetchVms1 = (filter = '', combobox: HeatmapComboboxComponent) => {
    combobox.loadingResources = true;

    let resources = Object.entries(this.heatmapFlowsCombinations)
        .map((e) => this.resourceUuidToObjectsIndex[e[0]])
        .filter((o) => o !== undefined)
        .filter((o) => o.uuid.indexOf(filter) !== 1 || o.name.indexOf(filter) !== -1);

    combobox.asyncResources$ = of(resources);
    combobox.loadingResources = false;
    this.flowDetails = undefined;
  }

  fetchVms2 = (filter = 'second_vm', combobox: HeatmapComboboxComponent) => {
    combobox.loadingResources = true;

    let allPossibleResources = [];
    for (let selectedResource of this.selectedResources1) {
      allPossibleResources.push(...this.heatmapFlowsCombinations[selectedResource.uuid]);
    }

    let resources = allPossibleResources
        .map((e) => this.resourceUuidToObjectsIndex[e])
        .filter((o) => o !== undefined)
        .filter((o) => o.uuid.indexOf(filter) !== 1 || o.name.indexOf(filter) !== -1);

    combobox.asyncResources$ = of(resources);
    combobox.loadingResources = false;
    this.flowDetails = undefined;
  }

  ngOnDestroy = () => {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  reloadData = () => {
    this.isLoading = true;
    const heatmapFlowsCombinationsObservable = this.netscopeService.getHeatmapFlowsCombinations();

    heatmapFlowsCombinationsObservable.subscribe((heatmapFlowsCombinations) => {
      this.heatmapFlowsCombinations = heatmapFlowsCombinations;
      this.isLoading = false;
    });

    if (this.selectedResources1.length > 0 && this.selectedResources2.length > 0) {
      this.resourceSelectionChanged.emit({"update": true});
    }
  }

  loadFlows = (timestamp) => {
    let srcUuid, dstUuid;
    for (let resource of this.selectedResources1) {
      srcUuid = resource.uuid;
    }
    for (let resource of this.selectedResources2) {
      dstUuid = resource.uuid;
    }

    if (srcUuid === undefined || dstUuid === undefined) {
      console.log(`could not fetch flows: one of src (${srcUuid}) or dst (${dstUuid}) is undefined`);
    }
    else {
      console.log(`loading flows for src (${srcUuid}) or dst (${dstUuid}) at timestamp ${timestamp}`);
      this.netscopeService.getHeatmapFlowsDetails(srcUuid, dstUuid, timestamp).subscribe((flowDetailsResponse) => {
        console.log(flowDetailsResponse);
        this.flowDetails = flowDetailsResponse;
        this.flowDetails.metrics = [];
        // Prepare a list of metrics in flowDetails
        for (let link of this.flowDetails.links) {
          for (let metric of link.metrics) {
            this.flowDetails.metrics.push(metric);
          }
        }
      });
    }
  }

  buildResourceUuidToObjectIndex = () => {
    // Create index of UUID => resource
    // this.resourceUuidToObjectsIndex = {};
    for (let tuple2 of [["vm", this.jsonData.vmSynthesis], ["host", this.jsonData.hostSynthesis]]) {
      let resourceType = tuple2[0];
      let resources = tuple2[1];
      let uuidPrefix = resourceType === "vm" ? "vim.VirtualMachine" : "vim.HostSystem";

      for (let resource of resources) {
        let shortUuid = resource.uuid;
        let longUuid = `${uuidPrefix}:${shortUuid}`;
        let resourceObject = {
          "shortUuid": resource.uuid,
          "uuid": longUuid,
          "name": resource.name,
          "resourceType": resourceType,
        };
        this.resourceUuidToObjectsIndex[longUuid] = resourceObject;
        this.resourceUuidToObjectsIndex[shortUuid] = resourceObject;
      }
    }
  }

  reloadUi = (category: string) => {
  }

  /**
   * This method export the data table into a CSV file
   */
  exportCSV() {
    let csvHeader = "Source, Destination, ExchangedBytes, TotalPackets"
    let csvContent = csvHeader+"\n";
    // for (let link of this.filteredLinks) {
    //   let lineValue = `${link.source.name}, ${link.target.name}, ${link.exchanged_bytes}, ${link.exchanged_packets}\n`;
    //   csvContent += lineValue;
    // }

    let exportedFilename = 'netscope-clusters.csv';
    let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}

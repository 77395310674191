import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-table-state]'
})
export class AlertingtablestateDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

<form clrForm [formGroup]="search" id="{{css_id}}">
	<clr-datalist-container>
		<input clrDatalistInput (ngModelChange)="submit($event)" (keyup.enter)="enter()" placeholder="search ..." name="Options" formControlName="item" required size="30">
		<datalist>
			<option *ngFor="let item of infra" [ngValue]="item.uuid">{{item.name}} - [{{item.type}}]</option>
        	</datalist>
    	</clr-datalist-container>
</form>
<clr-tree [clrLazy]="true">
	<clr-tree-node *clrRecursiveFor="let item of message.elementViewTree; getChildren: getItemChildren"
		        [clrExpandable]="item.isExpandable"
			[(clrExpanded)]="item.opened"
			[(clrSelected)]="item.selected"
			(clrSelectedChange)="elementTreeAnalysis(item, $event)">
		<clr-icon [attr.shape]="item.type == 'vm' ? 'vm' : 
					item.type == 'server' ? 'host' : 
					item.type == 'cluster' ? 'cluster' : 
					item.type == 'dc' ? 'data-cluster' : 
					item.type == 'folder' ? 'folder-open' : 
					item.type == 'tag' ? 'tag' : 
					item.type == 'category' ? 'tag' : 
					item.type == 'rp' ? 'resource-pool' : 
					item.type == 'datastore' ? 'storage' : 
					item.type == 'view' ? 'tree-view' : ''">
		</clr-icon> 
		{{item.name}}
    	</clr-tree-node>
</clr-tree>

import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { SelectiontreeComponent } from '@app/selectiontree/selectiontree.component';
import { SelectiontreeDirective } from '@app/directives/selectiontree.directive';
import { AccountService, ShareService } from '@app/services';
import { GreenitMinmax, GreenitServersSettings, Message, User } from '@app/model';

import { getUserCurrency } from '../../../assets/js/tools';
import { TreeTarget } from '../greenit.enums';

@Component({
  selector: 'app-greenittool',
  templateUrl: './greenittool.component.html',
  styleUrls: ['./greenittool.component.css']
})
export class GreenittoolComponent implements OnInit {

  @ViewChild(SelectiontreeDirective) addTree: SelectiontreeDirective;

  @Input() target: TreeTarget;

  @Input() value_purchase: number;
  @Input() value_pue: number;
  @Input() value_co2: number;
  @Input() value_cost: number;

  @Output('treedata') treedata = new EventEmitter();
  pushData() {
    this.treedata.emit(this.servers);
  }

  message: Message;

  currentUser: User;
  globalCurrency: string;

  indicatortree: TreeTarget;
  minmaxtree: TreeTarget;

  // Default settings
  value_amortization: number = 4;
  value_embodied: number = -1;

  value_min: number = 100;
  value_max: number = 200;

  // Checkboxes
  check_purchase: boolean = false;
  check_amortization: boolean = false;
  check_embodied: boolean = false;
  check_co2: boolean = false;
  check_cost: boolean = false;
  check_pue: boolean = false;

  check_min: boolean = false;
  check_max: boolean = false;

  apply_button: boolean;
  servers: any;

  /**
   * 
   */
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private message_svc: ShareService,
    private authenticationService: AccountService) {

    this.indicatortree = TreeTarget.INDICATOR;
    this.minmaxtree = TreeTarget.MINMAX;

    this.apply_button = false;
    this.servers = [];
  }

  /**
   * 
   */
  ngOnInit(): void {
    this.authenticationService.user.subscribe(user => this.currentUser = user);

    // BUILD TREE
    this.message_svc.currentMessage.subscribe(
      message => {
        this.message = message;

        setTimeout(() => {
          this.loadTree();
        }, 100);
    });

    this.globalCurrency = getUserCurrency(this.currentUser.currency);

  }

  /**
   * 
   */
  private loadTree(): void {
    this.message.elementViewType = 'server-scope';
    if(this.addTree != undefined) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectiontreeComponent);
      const viewContainerRef = this.addTree.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
    }
    this.message.isTreeAnalysis = false;
  }

  /**
   * 
   */
  saveTree(): void {
    this.servers = [];
    
    for(var i = 0; i < this.message.elementViewTree.length; ++i) {
      if(this.message.elementViewTree[i].type == "server" && this.message.elementViewTree[i].selected == 1) {
        this.initValues(this.message.elementViewTree[i]);
      } else {
			  this.checkTreeChild(this.message.elementViewTree[i]);
		  }
    }

    this.apply_button = false;
    this.pushData();

    this.resetTree(this.message.elementViewTree);

  }

  /**
   * 
   */
  private checkTreeChild(node: any): void {
    if(node != undefined) {
      for(var i=0; i < node.children.length; i++) {
        if(node.children[i].type == "server" && node.children[i].selected == 1) {
          this.initValues(node.children[i]);
          
        } else {
          this.checkTreeChild(node.children[i]);
        }
      }
    }
  }

  /**
   * 
  */
  private resetTree(node: any): void {
    node.forEach(
      element => { 
        element.selected = 0;
        this.resetTree(element.children);
     });
  }

  /**
   * 
   */
  private initValues(node : any): void {
    let name = node.name;
    let identifier = node.id;
    let year = node.year;
    let amort = node.amortization;
    let embodied = node.embodied;
    let co2 = node.co2;
    let cost = node.cost;
    let pue = node.pue;
    let min = node.min;
    let max = node.max;

    if(this.check_purchase)
      year = this.value_purchase;
    if(this.check_amortization)
      amort = this.value_amortization;
    if(this.check_embodied && this.value_embodied > -1)
      embodied = this.value_embodied;
    if(this.check_co2)
      co2 = this.value_co2;
    if(this.check_cost)
      cost = this.value_cost;
    if(this.check_pue)
      pue = this.value_pue;

    // No checkboxes for min/max, need to push the couple
    min = this.value_min;
    max = this.value_max;

    switch(this.target) {
      case TreeTarget.INDICATOR:
        let server: GreenitServersSettings = {
          name: name,
          identifier: identifier,
          year: year,
          amortization: amort,
          embodied: embodied,
          co2: co2,
          cost: cost,
          pue: pue,
          timeago: new Date().getTime()
        };
        this.servers.push(server);
      break;
      case TreeTarget.MINMAX:
        let serverB: GreenitMinmax = {
          name: node.name,
          identifier: node.id,
          min: min,
          max: max,
          model: '',
          cpumodel: '',
          ramcap: 0,
          sockets: 0,
          power: 0
        };
        this.servers.push(serverB);
      break;
      default:
      break;
    }

  }

  /**
   * 
   */
  checkSettings(): void {
    let overall_status = false;

    switch(this.target) {
      case TreeTarget.INDICATOR:
        if(this.check_purchase && this.value_purchase > 0)
          overall_status = true;
        if(this.check_amortization && this.value_amortization >= 0)
          overall_status = true;
        if(this.check_embodied && this.value_embodied > 0)
          overall_status = true;
        if(this.check_co2 && this.value_co2 >= 0)
          overall_status = true;
        if(this.check_cost && this.value_cost >= 0)
          overall_status = true;
        if(this.check_pue && this.value_pue > 0)
          overall_status = true;
      break;
      case TreeTarget.MINMAX:
        if(this.value_min >= 0 && this.value_max >= 0 && this.value_min <= this.value_max) {
          overall_status = true;
        }
      break;
    }

    // Update apply button status
    this.apply_button = overall_status;
  }

}

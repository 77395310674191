<div *ngIf="message.isNotLoginPage">
	<app-account-management [targetInfrastructure]="this.message.cloudProvider" (loadRegion)="loadRegion()" *ngIf="isAccountMgtReady"></app-account-management>
	<clr-tabs *ngIf="isCo2scope">
		<clr-tab>
        	<button clrTabLink (click)="setAllInclusive()">ALL IT</button>
			<clr-tab-content *clrIfActive="false">
				<app-allinclusive></app-allinclusive>
			</clr-tab-content>
		</clr-tab>
		<clr-tab>
        	<button clrTabLink (click)="setVmware()">VMWARE</button>
			<clr-tab-content *clrIfActive="true">
				<app-vmware></app-vmware>
			</clr-tab-content>
		</clr-tab>
		<clr-tab>
        	<button clrTabLink (click)="setAws()">AWS</button>
			<clr-tab-content *clrIfActive="false">
				<app-dashboard-cloud [isreload]="isDataReload" *ngIf="isReady == true"></app-dashboard-cloud>
				<div *ngIf="!this.message.isValidAws">
					<div class="card">
							<div class="card-block">
									<div class="card-text">
											<div>
													<strong class="center">No data available !</strong>
											</div>
									</div>
							</div>
					</div>
				</div>
			</clr-tab-content>
		</clr-tab>
		<clr-tab>
        	<button clrTabLink (click)="setAzure()">AZURE</button>
			<clr-tab-content *clrIfActive="false">
				<app-dashboard-cloud [isreload]="isDataReload" *ngIf="isReady == true"></app-dashboard-cloud>
				<div *ngIf="!this.message.isValidAzure">
					<div class="card">
							<div class="card-block">
									<div class="card-text">
											<div>
													<strong class="center">No data available !</strong>
											</div>
									</div>
							</div>
					</div>
				</div>
			</clr-tab-content>
		</clr-tab>
		<clr-tab>
			<button clrTabLink (click)="setStorage()">STORAGE</button>
			<clr-tab-content *clrIfActive="false">
				<app-storage></app-storage>
			</clr-tab-content>
		</clr-tab>
		<clr-tab>
			<button clrTabLink (click)="setNetwork()">NETWORK</button>
			<clr-tab-content *clrIfActive="false">
				<app-network></app-network>
			</clr-tab-content>
		</clr-tab>
	</clr-tabs>
	<app-vmware *ngIf="isDcscope"></app-vmware>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { HostAvg, HostMax, Measurement } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private body: any;

  private corsHeaders: HttpHeaders;

  data: Measurement = {
	time: 0,
    	value: 0
  };

  currentData: BehaviorSubject<Measurement> = new BehaviorSubject<Measurement>(this.data);
  public datas: Observable<Measurement> = this.currentData.asObservable();

  constructor(private http: HttpClient) {
	this.corsHeaders = new HttpHeaders({
	  'Content-Type': 'application/json',
	  'Access-Control-Allow-Credentials': 'true',
	'Access-Control-Allow-Headers': 'Content-Type',
	'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
	'Access-Control-Expose-Headers': 'Authorization',
	'Access-Control-Max-Age': '3600',
	'Connection': 'Keep-Alive'
	  //'Accept': 'application/json',
	  //	'Access-Control-Allow-Origin': 'https://localhost:4200',
	  //	'Access-Control-Allow-Origin': '*',
	  //		'Access-Control-Expose-Headers': 'Content-Length'
	});
  }

  getData(uuid: string, start: number, end: number, counter: string, rollup: string, type: string, wd: boolean) {
	  return this.http.get<Measurement>(`${environment.apiUrl}/` + 'measurement/counter/' + uuid + '&' + start + '&' + end + '&' + counter + '&' + rollup + '&' + type + '&' + wd).pipe(map(data => {
		localStorage.setItem('data', JSON.stringify(data));
		this.currentData.next(data);
		return data;
	}));
  }

  getHostAvg(uuid: string, start: number, end: number) {
	  return this.http.get<HostAvg>(`${environment.apiUrl}/` + 'measurement/hostavg/' + uuid + '&' + start + '&' + end).pipe(map(data => {
		return data;
	}));
  }

  getHostMax(uuid: string, start: number, end: number) {
	  return this.http.get<HostMax>(`${environment.apiUrl}/` + 'measurement/hostmax/' + uuid + '&' + start + '&' + end).pipe(map(data => {
		return data;
	}));
  }

  getPowerAllVms(pi: any) {
	return this.http.post<any>(`${environment.apiUrl}/measurement/` + 'powerallvm', pi).pipe(map(result => {
		return result;
	}));
  }
}

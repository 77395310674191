<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<label>
				<clr-icon shape="resource-pool"></clr-icon>
				{{rp_synth.name}}
				<span class="label clickable addLink" style="float:right;" (click)="callClusterRoute()">{{cluster_name}}
					<span class="badge">cluster</span>
				</span>
			</label>
		</h5>
	</div>
	<div class="card-block">
		<div class="clr-row">
			<div class="clr-col-lg-3 clr-col-md-3 clr-col-12" *ngIf="currentUser.isDashboardT6">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<b style="font-size: 20px;">{{globalCurrency}} {{math.round(rp_synth.cost)}}</b>
							<br>
							Cost
						</div>
					</div>
				</div>
			</div>
			<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<b style="font-size: 20px;">{{rp_synth.vms}}</b>
							<br>
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callVmList()">
								<clr-icon shape="vm"></clr-icon>
								VMs
							</button>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<b style="font-size: 20px;">2</b>
							<br>
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="callHostList()">
								<clr-icon shape="host"></clr-icon>
								Servers
							</button>
						</div>
					</div>
				</div>
			</div> -->
			<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<b style="font-size: 20px;">{{math.round((rp_synth.cpures / 1000) * 100) / 100}} GHz</b>
							<br>
							CPU reservation
						</div>
					</div>
				</div>
			</div>
			<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<b style="font-size: 20px;">{{math.round((rp_synth.ramres/1024) * 100) / 100}} GB</b>
							<br>
							Memory reservation
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
				<div class="card">
					<div class="card-block">
						<div class="card-text">
							<b style="font-size: 20px;">{{math.round((rp_synth.sto_cap/1024) * 100) / 100}} GB</b>
							<br>
							Storage capacity
						</div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</div>

<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="details"></clr-icon>
			&nbsp;Resource Pool parameters
		</h5>
	</div>
	<div class="card-block">
		<div class="clr-row">
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="margin-top:auto;margin-bottom:auto;padding-left:100px;">
				<label class="clr-control-label">CPU</label>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
				<label class="clr-control-label">Shares</label>
				<div class="clr-control-container">
					<div class="clr-input-wrapper">
						<span class="clr-input">{{rp_synth.cpusharesN}} ({{rp_synth.cpusharesV}})</span>
      				</div>
				</div>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
				<label class="clr-control-label">Reservation (MHz)</label>
				<div class="clr-control-container">
					<div class="clr-input-wrapper">
						<span class="clr-input">{{rp_synth.cpures}}</span>
      				</div>
				</div>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
				<label class="clr-control-label">Expandable</label>
				<div class="clr-control-container">
					<div class="clr-input-wrapper">
						<span class="clr-input">{{rp_synth.cpuexpandable}}</span>
      				</div>
				</div>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
				<label class="clr-control-label">Limit (MHz)</label>
				<div class="clr-control-container">
					<div class="clr-input-wrapper">
						<span class="clr-input">{{rp_synth.cpulimit}}</span>
      				</div>
				</div>
			</div>
		</div>

		<div class="clr-row" style="margin-top:20px">
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="margin-top:auto;margin-bottom:auto;padding-left:100px;">
				<label class="clr-control-label">Memory</label>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
				<label class="clr-control-label">Shares</label>
				<div class="clr-control-container">
					<div class="clr-input-wrapper">
						<span class="clr-input">{{rp_synth.ramsharesN}} ({{rp_synth.ramsharesV}})</span>
      				</div>
				</div>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
				<label class="clr-control-label">Reservation (MB)</label>
				<div class="clr-control-container">
					<div class="clr-input-wrapper">
						<span class="clr-input">{{rp_synth.ramres}}</span>
      				</div>
				</div>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
				<label class="clr-control-label">Expandable</label>
				<div class="clr-control-container">
					<div class="clr-input-wrapper">
						<span class="clr-input">{{rp_synth.ramexpandable}}</span>
      				</div>
				</div>
			</div>
			<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" style="padding-left:50px;">
				<label class="clr-control-label">Limit (MB)</label>
				<div class="clr-control-container">
					<div class="clr-input-wrapper">
						<span class="clr-input">{{rp_synth.ramlimit}}</span>
      				</div>
				</div>
			</div>
		</div>

	</div>
</div>

<div class="clr-row">
	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<clr-icon shape="line-chart"></clr-icon>
					&nbsp;Physical resources over the last 24 hours
				</h5>
			</div>
			<div class="card-block" style="height:425px;">
				<table style="width:100%">
					<tr>
						<td style="width:10%;font-size:.65rem;position:relative;vertical-align:top;"><b>CPU</b></td>
						<td>
							<app-rpviewbar [target]="cpu_res" [data]="rp_synth"></app-rpviewbar>
						</td>
					</tr>
					<tr><td style="padding-bottom:20px;"></td></tr>
					<tr>
						<td style="width:10%;font-size:.65rem;position:relative;vertical-align:top;"><b>MEMORY</b></td>
						<td>
							<app-rpviewbar [target]="ram_res" [data]="rp_synth"></app-rpviewbar>
						</td>
					</tr>
					<tr><td style="padding-bottom:20px;"></td></tr>
					<tr>
						<td style="width:10%;font-size:.65rem;position:relative;vertical-align:top;"><b>STORAGE</b></td>
						<td>
							<app-rpviewbar [target]="sto_res" [data]="rp_synth"></app-rpviewbar>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>

	<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
		<div class="card">
			<div class="card-header card-ajust">
				<h5>
					<clr-icon shape="line-chart"></clr-icon>
					&nbsp;Resources information
				</h5>
			</div>
			<div class="card-block" style="height:425px;">
				<app-rpevolution [data]="rp_synth"></app-rpevolution>
			</div>
		</div>
	</div>
</div>

<clr-modal [(clrModalOpen)]="isModalVmList" [clrModalSize]="'xl'">
	<h3 class="modal-title">The VM list for {{rp_synth.name}}</h3>
	<div class="modal-body">
		<ng-template load-vm-list></ng-template>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="isModalVmList=false">OK</button>
	</div>
</clr-modal>

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { GreenitTarget } from '@app/greenit/greenit.enums';
import { GreenitcfComponent } from '@app/greenit/greenitcf/greenitcf.component';
import { JSONTarget } from '@app/model';
import { JsonloaderService } from '@app/services';
import { T8Component } from '@app/t8/t8.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-allinclusive',
  templateUrl: './allinclusive.component.html',
  styleUrls: ['./allinclusive.component.css']
})
export class AllinclusiveComponent implements OnInit {

  @ViewChild('greenitcf') greenitcf: GreenitcfComponent;
  @ViewChild('t8') t8: T8Component;

  isReady: boolean = false;
  allowReload: boolean = false;

  t1_target: GreenitTarget = GreenitTarget.ALL_INCLUSIVE_CO2;
  t2_target: JSONTarget = JSONTarget.T8_ALL;

  json_subscription: Subscription;

  /**
   * 
   */
  constructor(private json_svc: JsonloaderService) { }

  /**
   * 
   */
  ngOnInit(): void {
    this.isReady = true;

    // Need subscription to JsonloaderService to detect filter reload
    this.json_subscription = this.json_svc.currentJson.subscribe(
			json => {
        if(this.greenitcf !== undefined && this.t8 !== undefined && this.allowReload) {
					this.greenitcf.load(true);
          this.t8.load(true);
        }
        this.allowReload = true;
			}
		)
  }

  /**
   * 
   */
  @HostListener('unloaded')
	ngOnDestroy(): void {
		// Remove subscription
		if (this.json_subscription != undefined) {
			this.json_subscription.unsubscribe();
		}
		//console.log("destroyed allinclusive");
	}

}

import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';

import { AccountService, JsonloaderService, SettingsService, ShareService } from '@app/services';

import { Json, Message, User } from '@app/model';

import { commaBigNumber, getUserCurrency } from '../../assets/js/tools.js';

import { T6detailComponent } from '@app/t6/t6detail/t6detail.component';
import { T6detailDirective } from '@app/directives/t6detail.directive';


@Component({
  selector: 'app-t6',
  templateUrl: './t6.component.html',
  styleUrls: ['./t6.component.css']
})
export class T6Component implements OnInit {

  @ViewChild(T6detailDirective) addT6Detail: T6detailDirective;

  jsonLoader: Json;

  data_t6: any;

  currentUser: User;

  private options: any;

  private chart: any;

  message: Message;

  cost: any = {
	allocated: 0,
	consumed: 0,
	wasted: 0,
	consumed_per: 0,
	wasted_per: 0
  };

  globalCurrency: string = '';

  isModalCost: boolean = false;

  isReady: boolean = false;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private json_svc: JsonloaderService,
	private authentication_svc: AccountService,
	private management_svc: SettingsService,
	private message_svc: ShareService) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.json_svc.currentJson.subscribe(
		json => {
			this.jsonLoader = json;
			if(this.jsonLoader.t6 != null) {
				this.globalCurrency = getUserCurrency(this.currentUser.currency);
				this.data_t6 = this.jsonLoader.t6;
				this.isReady = true;
				this.buildCard();
		    } else {
				this.data_t6 = [];
				this.cost = {
					allocated: 0,
					consumed: 0,
					wasted: 0,
					consumed_per: 0,
					wasted_per: 0
				};
				this.isReady = false;
			}
		}
	);

	this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  resizeCard(): void {
	$('#dashboard-t6-core').slideToggle();
  }

  closeCard(): void {

	this.management_svc.updateSettings(this.currentUser.login, 'isT6', false).subscribe(
		error => {
			if(error != null)
				console.log(error)
		}
	);
	$('#dashboard-t6').hide();
  }

  buildCard(): void {

	if(this.data_t6[0].allocated > 0) {
		this.cost.consumed_per = Math.round(this.data_t6[0].consumed*100/this.data_t6[0].allocated);
		this.cost.wasted_per = Math.round(this.data_t6[0].wasted*100/this.data_t6[0].allocated);
	}

	this.cost.allocated = this.globalCurrency + ' ' + commaBigNumber(this.data_t6[0].allocated);
	this.cost.consumed = this.globalCurrency + ' ' + commaBigNumber(this.data_t6[0].consumed);
	this.cost.wasted = this.globalCurrency + ' ' + commaBigNumber(this.data_t6[0].wasted);
  }

  loadDetail(val: string): void {

	this.isModalCost = true;
	this.message.t6Detail = val;
	setTimeout(() => this.loadTableCosts(), 100);
  }

  private loadTableCosts(): void {

	if(this.addT6Detail != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(T6detailComponent);
		const viewContainerRef = this.addT6Detail.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }
}
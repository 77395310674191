import { AfterViewChecked, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, OnChanges, SimpleChanges, ViewChild, Input } from '@angular/core';

import * as Highcharts from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);
import dumbbell from 'highcharts/modules/dumbbell';
dumbbell(Highcharts);

import { JSONTarget, Message } from '@app/model';

import { InfrastructureService, JsonloaderService, ShareService } from '@app/services';

import { CostsDetailComponent } from '../aws/costs-detail/costs-detail.component'; 
import { CloudcostsDirective } from '../../directives/cloudcosts.directive';

import { InstancesDetailComponent } from '../aws/instances-detail/instances-detail.component';
import { CloudinstancesDirective } from '../../directives/cloudinstances.directive';

import { InstancesCo2Component } from '../aws/instances-co2/instances-co2.component';
import { Cloudco2Directive } from '../../directives/cloudco2.directive';

import { KpiCreditsComponent } from '../aws/kpi-credits/kpi-credits.component';
import { CloudkpicreditsDirective } from '../../directives/cloudkpicredits.directive';

import { KpiSavingsplanComponent } from '../aws/kpi-savingsplan/kpi-savingsplan.component';
import { CloudkpisavingsplanDirective } from '../../directives/cloudkpisavingsplan.directive';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-cloud',
  templateUrl: './dashboard-cloud.component.html',
  styleUrls: ['./dashboard-cloud.component.css']
})
export class DashboardCloudComponent implements AfterViewChecked, OnInit, OnChanges {

  @Input() isreload: boolean = false;

  @ViewChild(CloudinstancesDirective) addCloudInstancesDetail: CloudinstancesDirective;
  @ViewChild(CloudcostsDirective) addCloudCostsDetail: CloudcostsDirective;
  @ViewChild(CloudkpicreditsDirective) addCloudKpiCredits: CloudkpicreditsDirective;
  @ViewChild(CloudkpisavingsplanDirective) addCloudKpiSavingsplan: CloudkpisavingsplanDirective;
  @ViewChild(Cloudco2Directive) addCloudCo2: Cloudco2Directive;

  isDcscope = true;
	isCo2scope = false;

  highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart | null;
  chartOptions: Highcharts.Options = {};
  chartOptions2: Highcharts.Options = {};
  chartOptions3: Highcharts.Options = {};
  chartOptions4: Highcharts.Options = {};

  metadata: any = [];

  message: Message;

  detail_str: string = "cost";

  isReady: boolean = false;
  isReady2: boolean = false;
  isReady3: boolean = false;
  isReady4: boolean = false;

  kpiSavingsplans: any = [];

  kpiEvolution: any = [];

  ageStructure: any  = [];

  model: any = {
        selectedId: 'savings plan 1',
        selectedSp: '',
        avgSp: 0,
        minSp: 0,
        maxSp: 0,
        lastSp: 0,
        avgSpColor: 'normal',
        minSpColor: 'normal',
        maxSpColor: 'normal',
        kpiAggUsagePercent: 0,
        more30Days: 0,
        lastDay: 0,
        insLastDay: 0
  };

  viewContainerRef: any;


  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    private infra_svc: InfrastructureService,
    private json_svc: JsonloaderService,
    private message_svc: ShareService,
    private library: FaIconLibrary,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.library.addIcons(faLeaf  );

    this.message_svc.currentMessage.subscribe(message => this.message = message);

    this.isCo2scope = this.message.isCO2Scope;
	  this.isDcscope = this.message.isDCScope;

    setTimeout(() => this.initCloudData(), 100);

    this.buildShape();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['isreload'].currentValue)
      this.initCloudData();

  }

  ngAfterViewChecked(): void {

		this.cd.detectChanges();
	}

  updateModel(): void {

    this.message.waiting = true;
    this.infra_svc.updateModelCloud('ODC').subscribe(
      complete => {
        this.message.waiting = false;
      },
      error => {
        if(error != null) {
          this.message.isNoInfo = true;
          this.message.waiting = false;
          console.log(error);
        }
      }
    );
  }

  loadSp(event: any): void {

    this.isReady2 = false;
    let id : number = event.split(" ")[2];
    if(id > 0) {
      let ksp : any = this.kpiSavingsplans.filter((ksp: { id: number; }) => ksp.id == id);
      this.model.selectedSp = ksp[0].uuid;
      this.loadSpGraph();
    }
  }

  loadCostsDetail(): void {

    this.message.creditSelected = 'cost';
    this.detail_str = "cost";
    setTimeout(() => this.loadCloudCostsDetail(), 100);
  }

  loadInstancesDetail(): void {

    this.message.creditSelected = 'instance';
    this.detail_str = "instance";
    setTimeout(() => this.loadCloudInstancesDetail(), 100);
  }

  loadCo2Detail(): void {

    this.message.creditSelected = 'co2';
    this.detail_str = "co2";
    setTimeout(() => this.loadCloudCo2Detail(), 100);
  }

  loadCreditsDetail() {

    this.message.creditSelected = 'credits';
    this.detail_str = "kpicredits";
    setTimeout(() => this.loadCloudKpiCredits(), 100);
  }

  loadSpDetail(): void {
    
    this.message.creditSelected = 'sp';
    this.detail_str = "kpisp";
    setTimeout(() => this.loadCloudKpiSp(), 100);
  }

  toFixedNumber(val: any, nb: number): number {

    let value: number = 0;
    if(val != null)
      value = val.toFixed(nb);

    return value;
  }

  private initCloudData(): void {

    setTimeout(() =>  this.loadRegionData(this.message.cloudAccount, this.message.cloudRegion), 500);
  }

  private loadRegionData(account: any, region: any): void {

    let account_json: string = account.replaceAll('-', '');
    let region_json: string = region.replaceAll('-', '');
    
    this.json_svc.getData('root','cloud_' + account_json + '_' + region_json, JSONTarget.CLOUD_METADATA).subscribe(
            data => {
              this.metadata = data;
              if(this.metadata.kpiCreditsAggMax > 0)
                this.model.kpiAggUsagePercent = (this.metadata.kpiCreditAggUsage*100/this.metadata.kpiCreditsAggMax).toFixed(0);

              this.loadCreditsGraph();

              this.json_svc.getData('root','cloud_' + account_json + '_' + region_json, JSONTarget.CLOUD_KPI_SAVINGSPLAN).subscribe(
                data2 => {
                  if(data2.length > 0) {
                    this.kpiSavingsplans = data2;
                    this.model.selectedSp = this.kpiSavingsplans[0].uuid;
                    this.model.selectedId = 'savings plan ' + this.kpiSavingsplans[0].id;
                    this.loadSpGraph();
                  }
                }
              );

              this.json_svc.getData('root','cloud_' + account_json + '_' + region_json, JSONTarget.CLOUD_KPI_EVOLUTION).subscribe(
                data => {
                  if(data.length > 0) {
                    this.kpiEvolution = data;
                    this.loadEvolutionGraph();
                  }
                }
              );

              this.json_svc.getData('root','cloud_' + account_json + '_' + region_json, JSONTarget.CLOUD_AGE_STRUCTURE).subscribe(
                data => {
                  if(data.length > 0) {
                    this.ageStructure = data;
                    this.loadAgeGraph();
                  }
                }
              );
            }
    );
  }

  private loadCloudCostsDetail(): void {

    if(this.addCloudCostsDetail != undefined) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CostsDetailComponent);
      const viewContainerRef = this.addCloudCostsDetail.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
    }
  }

  private loadCloudInstancesDetail(): void {

    if(this.addCloudInstancesDetail != undefined) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InstancesDetailComponent);
      const viewContainerRef = this.addCloudInstancesDetail.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
    }
  }

  private loadCloudCo2Detail(): void {

    if(this.addCloudCo2 != undefined) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InstancesCo2Component);
      const viewContainerRef = this.addCloudCo2.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
    }
  }

  private loadCloudKpiCredits(): void {

    if(this.addCloudKpiCredits != undefined) {
              const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KpiCreditsComponent);
              const viewContainerRef = this.addCloudKpiCredits.viewContainerRef;
              viewContainerRef.clear();
              const componentRef = viewContainerRef.createComponent(componentFactory);
      }
}

  private loadCloudKpiSp(): void {

    if(this.addCloudKpiSavingsplan != undefined) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KpiSavingsplanComponent);
      const viewContainerRef = this.addCloudKpiSavingsplan.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
    }
  }

  private loadCreditsGraph(): void {
  
    let title: string = '';
    let titleSize: string = '20px';
    let credits: string = 'normal';
    let surplus: string = '';

    this.chartOptions = {
      credits: {
        enabled: false
      },
      title: {
        text: title,
        style: {
          color: 'grey',
          fontSize: titleSize
        }
      },
      chart: {
        plotBorderWidth: null,
        plotShadow: false
      },
      tooltip: {
        shared: false,
        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false
          }
        }
      },
      lang: {
        contextButtonTitle: "Export graph"
      },
      exporting: {
        buttons: {
          contextButton: {
            className: "addLink",
            symbol: 'download',
            symbolStroke: "#0072A3"
          }
        }
      },
      series : [{
         type: 'pie',
         innerSize: '50%',
         name: 'instances',
         data: [{
               name: 'overused' + surplus,
               y: this.metadata.kpiCreditsOver,
               color: '#CD7F32'
         }, {
               name: 'underused',
               y: this.metadata.kpiCreditsUnder,
               color: '#C0C0C0'
         }, {
               name: 'normal',
               y: this.metadata.kpiCreditsNormal,
               color: '#FFCC00'
         }]
      }]
    };
    this.isReady = true;
  }

  private loadSpGraph(): void {
    
    let over: number = 0;
    let normal: number = 0;
    let under: number = 0;
    
    for(let i in this.kpiSavingsplans) {
      if(this.kpiSavingsplans[i].uuid == this.model.selectedSp) {
        over = this.kpiSavingsplans[i].stat.overzised;
        normal = this.kpiSavingsplans[i].stat.normal  ;
        under = this.kpiSavingsplans[i].stat.undersized;
        this.model.avgSp = this.kpiSavingsplans[i].info.avgUsageRate;
        this.model.minSp = this.kpiSavingsplans[i].info.minUsageRate;
        this.model.maxSp = this.kpiSavingsplans[i].info.maxUsageRate;
        let iter : number = 0;
        iter = this.kpiSavingsplans[i].counter.length;
        if(iter > 0)
          this.model.lastSp = this.kpiSavingsplans[i].counter[iter-1].usageRate;
      }
    } 
    if(this.model.avgSp < 90 || this.model.avgSp > 100)
      this.model.avgSpColor = 'over';
    else
      this.model.avgSpColor = 'normal';

    if(this.model.minSp < 90 || this.model.minSp > 100)
      this.model.minSpColor = 'over';
    else
      this.model.minSpColor = 'normal';

    if(this.model.maxSp < 90 || this.model.maxSp > 100)
      this.model.maxSpColor = 'over';
    else
      this.model.maxSpColor = 'normal';

    let title: string = '';
    let titleSize: string = '20px';
    let sp = 'normal';

    this.chartOptions2 = {
      credits: {
        enabled: false
      },
      title: {
        text: title,
        style: {
          color: 'grey',
          fontSize: titleSize
        }
      },
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
      },
      tooltip: {
        shared: false,
        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false
          }
        }
      },
      lang: {
        contextButtonTitle: "Export graph"
      },
      exporting: {
        buttons: {
          contextButton: {
            className: "addLink",
            symbol: 'download',
            symbolStroke: "#0072A3"
          }
        }
      },
      series : [{
         type: 'pie',
         innerSize: '50%',
         name: 'days',
         data: [{
               name: 'overused',
                y: over,
               color: '#CD7F32'
         }, {
               name: 'underused',
               y: under,
               color: '#C0C0C0'
         }, {
               name: 'normal',
               y: normal,
               color: '#FFCC00'
         }]
      }]
    };

    this.isReady2 = true;
  }

  private loadEvolutionGraph(): void {

    let title: string = '';
    let titleSize: string = '20px';
    let offset_y: number = 1.5;
    let offset_x: number = 1.5;

    let datas: any = [];
    let vm_datas: any = [];
    for(let i in this.kpiEvolution) {
      let x_all: number = this.kpiEvolution[i].all;
      let x_create: number = this.kpiEvolution[i].all+this.kpiEvolution[i].nbCreate;
      let x_del: number = x_all-this.kpiEvolution[i].ngDestroy;
      
      let j: number = +i;
      j = j + offset_y;

      let offset_2: number = 2*x_all/100;
      let offset_50: number = 50*x_all/100;
      if(x_all < 25) {
        offset_2 = offset_x;
        offset_50 = 50;
      }

      let del_color: string = "#5eb715";
      let del_offset: number = x_del;

      let add_color: string = "#0072a3";
      let add_offset: number = x_create+offset_2;

      if(x_create == x_all) {
        add_color = "#ffffff";
        add_offset = x_all+offset_50
      }  
      if(x_del == x_all) {
        del_offset = x_all-offset_50;
        del_color = "#ffffff";
      }

      let data_del : any = {
          x: del_offset,
          x2: x_all,
          y: j+1, // day
          color: del_color,
          text: ' ' + Highcharts.dateFormat('%Y/%m/%d', this.kpiEvolution[i].timeago)
          + '<br>' + Math.abs(this.kpiEvolution[i].ngDestroy) + ' deleted',
      };
      datas.push(data_del);

      let data_all : any = {
          x: x_all,
          x2: x_all+offset_2,
          y: j+1, // day
          color: '#c21d00',
          text: ' ' + Highcharts.dateFormat('%Y/%m/%d', this.kpiEvolution[i].timeago)
          + '<br>' + ' total: ' + this.kpiEvolution[i].all
      };
      datas.push(data_all);

      let data_add : any = {
          x: x_all+offset_2,
          x2: add_offset,
          y: j+1, // day
          color: add_color,
          text: ' ' + Highcharts.dateFormat('%Y/%m/%d', this.kpiEvolution[i].timeago)
          + '<br>' + Math.abs(this.kpiEvolution[i].nbCreate) + ' added',
        };
      datas.push(data_add); 
      vm_datas.push(this.kpiEvolution[i].all);
    }
    
    let serie0 : any = {
      type: 'xrange',
      name: 'evolution',
      pointWidth: 5,
      data: datas
    };
    let series: any = [];
    series.push(serie0);

    this.chartOptions3 = {
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: title,
        style: {
          color: 'grey',
          fontSize: titleSize
        }
      },
      chart: {
        inverted: false,
        plotBorderWidth: null,
        plotShadow: false,
      },
      xAxis: {
        visible: true,
        title: {
          text: "instances",
          align: 'high'
        },
        labels: {
            	enabled: false
        }
      },
      yAxis: {
        visible: true,
        title: {
          text: 'past days',
          align: 'high'
        },
        labels: {
            	enabled: false
        }
      },
      tooltip: {
        shared: false,
        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span><b>{point.text}</b><br/>'
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false
          }
        },
        series: {
            marker: {
                enabled: false
            }
        }
      },
      lang: {
        contextButtonTitle: "Export graph"
      },
      exporting: {
        buttons: {
          contextButton: {
            className: "addLink",
            symbol: 'download',
            symbolStroke: "#0072A3"
          }
        }
      },
      series: series
    };

    this.isReady3 = true;
  }

  private loadAgeGraph(): void {

    let title: string = '';
    let datas: any = [];
    let cat: any = [];

    this.model.lastDay = this.ageStructure[0].nbDay;
    this.model.insLastDay = this.ageStructure[0].nbVm;

    this.model.more30Days = 0;
    for(let i in this.ageStructure) {
      cat.push(this.ageStructure[i].nbDay);
      datas.push(this.ageStructure[i].nbVm);
      if(this.ageStructure[i].nbDay >= 30 )
        this.model.more30Days += this.ageStructure[i].nbVm;
    }

    let serie0 : any = {
      type: 'bar',
      name: 'number of instances',
      pointWidth: 5,
      data: datas
    };
    let series: any = [];
    series.push(serie0);

    this.chartOptions4 = {
      credits: {
          enabled: false
      },
      title: {
        text: title
      },
      xAxis: {
          categories: cat,
          title: {
              text: 'past days',
              align: 'high',
              rotation: -90
          }
      },
      yAxis: {
          min: 0,
          title: {
              text: 'instances',
              align: 'high'
          },
          labels: {
              overflow: 'justify'
          }
      },
      tooltip: {
          valueSuffix: ' '
      },
      plotOptions: {
          bar: {
              dataLabels: {
                  enabled: false
              }
          }
      },
      legend: {
          enabled: false
      },
      lang: {
        contextButtonTitle: "Export graph"
      },
      exporting: {
        buttons: {
          contextButton: {
            className: "addLink",
            symbol: 'download',
            symbolStroke: "#0072A3"
          }
        }
      },
      series: series
    };

    this.isReady4 = true;
  }

  private buildShape() {
    
    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
      var path = [
          // Arrow stem
          'M', x + w * 0.5, y,
          'L', x + w * 0.5, y + h * 0.7,
          // Arrow head
          'M', x + w * 0.3, y + h * 0.5,
          'L', x + w * 0.5, y + h * 0.7,
          'L', x + w * 0.7, y + h * 0.5,
          // Box
          'M', x, y + h * 0.9,
          'L', x, y + h,
          'L', x + w, y + h,
          'L', x + w, y + h * 0.9
      ];
      return path;
    };
  }
}

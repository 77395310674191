<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="slider"></clr-icon>
			&nbsp;Prediction about VCPU, VRAM & VDISK
		</h5>
	</div>
	<div class="card-block" id="dashboard-t2-core">
		<div class="card-text">
			<div *ngIf="isReady">
				<highcharts-chart id="t2-graph"
					[Highcharts]="highcharts"
					[options]="chartOptions"
					[constructorType]="'chart'"
					(chartInstance)="checkingPoint($event)"
					style="width: 100%; height: 250px; display: block;" *ngIf="isReady">
				</highcharts-chart>
				<div>
					<strong class="center">First saturation date:&nbsp;<span [ngStyle]="{color: satDate.value === 'KO' ? 'red' : satDate.value === '> 5 years' ? 'green' : 'orange' }">{{satDate.value}}</span></strong>
				</div>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
		</div>
	</div>
</div>
import {Component, OnInit, Output, Input, ViewChild, EventEmitter, TemplateRef, ElementRef} from '@angular/core';
import {Observable} from "rxjs";
import {ClrCombobox, ClrComboboxContainer} from "@clr/angular";

@Component({
  selector: 'app-graph-on-demand-combobox',
  templateUrl: './graph-on-demand-combobox.component.html',
  styleUrls: ['./graph-on-demand-combobox.component.css']
})
export class GraphOnDemandComboboxComponent implements OnInit {

  loadingResources = false;
  asyncResources$: Observable<any>;

  selectedItemsValue: String[] = [];
  comboboxOptionsAreVisible: Boolean = false;
  comboboxIsActive: Boolean = false;

  skipEventBeforeThisTimestamp;

  @Input()
  get selectedItems() {
    return this.selectedItemsValue;
  }

  @Output() selectedItemsChange = new EventEmitter();

  set selectedItems(val) {

    if (val == undefined) {
      val = [];
    }

    this.selectedItemsValue = val;
    this.selectedItemsChange.emit(this.selectedItemsValue);
    this.comboboxOptionsAreVisible = false;

    // Execute this block 100ms later to give the time of the event to propagate
    setTimeout(() => {
      if (this.resourceComboBox != undefined) {
        this.resourceComboBox.searchText = "";
        this.resourceComboBox.focused = false;
        this.resourceComboBox.textbox.nativeElement.blur();
      }
    }, 100);
  }

  @Output()
  onChange:EventEmitter<any> = new EventEmitter();

  @Input()
  itemsGenerator: (...args) => void;

  @Input()
  templateSelectedItems: TemplateRef<any>;

  @Input()
  templateDisplayItems: TemplateRef<any>;

  @Input()
  templateDisplayWhenEmpty: TemplateRef<any>;

  @Input()
  templateDisplayWhenErrors: TemplateRef<any>;

  @Input()
  itemsField: String;

  disabledCounters = {
    resource: false
  };

  @ViewChild('resourceComboBox') resourceComboBox: ClrCombobox<string>;

  /**
   *
   */
  constructor() {
  }

  /**
   *
   */
  ngOnInit(): void {
  }

  clickComboBox = (childElement, combo: any, event) => {
    if (event.target !== undefined && event.target.currentShapeAttrVal === "close") {
      return
    }
    this.sendArrowDown();
  }

  sendArrowDown = () => {
    console.log("sendArrowDown()");
    const event = new KeyboardEvent('keydown', {key: "ArrowDown"});
    this.comboboxOptionsAreVisible = true;
    this.resourceComboBox.textbox.nativeElement.dispatchEvent(event, "plop");
  }

  _focus = () => {
    console.log("_focus()");
    this.itemsGenerator();
  }

  _clrInputChange = (event: string) => {
    console.log(`_clrInputChange(${event})`);
    if (this.comboboxIsActive) {
      return this.itemsGenerator(event, this);
    } else {
      return null;
    }
  }

  _clrOpenChange = (event) => {
    console.log(`_clrOpenChange(${event})`);

    let now = new Date().getTime();

    if (event && this.skipEventBeforeThisTimestamp !== undefined && now < this.skipEventBeforeThisTimestamp) {
      console.log(`I ignore openChange events as ${this.skipEventBeforeThisTimestamp} > now (${now})`);
      this.comboboxOptionsAreVisible = false;
      return this._clrOpenChange(false);
    }
    this.comboboxIsActive = event;

    let result = null;
    if (this.comboboxIsActive) {
      this.clickComboBox(this.resourceComboBox, this, event);
      result = this.itemsGenerator('', this)
    } else {
      this.skipEventBeforeThisTimestamp = new Date().getTime() + 0.5 * 1000;
      console.log(`I set comboboxes to ignore openChange events before ${this.skipEventBeforeThisTimestamp}`);
    }
    return result;
  }

  _click = (resourceComboBox, event) => {
    console.log("_click()");
    this.clickComboBox(resourceComboBox, this, event);
  }

  _clrSelectionChange= (event) => {
    console.log(`_clrSelectionChange(${event})`);
    this.onChange.emit(event);
  }

}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-recommendation-operation]'
})
export class RecommendationoperationDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild } from '@angular/core';

import { ShareService } from '@app/services';

import { Message } from '@app/model';

import { KpihealthgraphComponent } from '@app/kpi/kpihealthgraph/kpihealthgraph.component';
import { KpihealthgraphDirective } from '@app/directives/kpihealthgraph.directive';

var componentRef: ComponentRef<any>;


@Component({
  selector: 'app-kpihealth',
  templateUrl: './kpihealth.component.html',
  styleUrls: ['./kpihealth.component.css']
})
export class KpihealthComponent implements AfterViewInit, OnInit {

  @ViewChild(KpihealthgraphDirective) addHealthGraph: KpihealthgraphDirective;

  message: Message;

  isAll: boolean = true;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.kpiHealth = 'all';
  }

  ngAfterViewInit(): void {
	setTimeout(() => this.loadHealthGraph(), 100);
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  callResource(val: string): void {

	this.message.kpiHealth = val;
	if(componentRef != undefined)
		componentRef.destroy();

	setTimeout(() => this.loadHealthGraph(), 100);
  }

  private loadHealthGraph(): void {

	if(this.addHealthGraph != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(KpihealthgraphComponent);
		const viewContainerRef = this.addHealthGraph.viewContainerRef;
	  	viewContainerRef.clear();
		componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }
}

import {EventEmitter, Injectable} from '@angular/core';
import {AsyncSubject, BehaviorSubject, merge, Observable, ReplaySubject, Subject, zip} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {concatAll, map, toArray} from 'rxjs/operators';

import {environment} from '@environments/environment';

import {Json, JSONTarget, Message} from '@app/model';
import {Router} from '@angular/router';
import {ShareService} from '@app/services/share.service';

declare var require: any;


@Injectable({
  providedIn: 'root'
})
export class JsonloaderService {

  // Empty json returns by the API if no json file found
  API_EMPTY_RESULT: string = "{}";


  message: Message;

  /**
   *
  */
  json: Json = {
	dcInfraMin: null,
    rpSynthesis: null,
	clusterSynthesis: null,
	clustersPrediction: null,
	hostInfo: null,
	hostConsover: null,
	hostSynthesis: null,
	hostsPrediction: null,
	vmInfo: null,
	vmConsover: null,
	vmSynthesis: null,
	vmComputeStorage: null,
	vmComputeStorageReco: null,
	costAllocated: null,
	t1: null,
	t2: null,
	opportunities: null,
	t3a: null,
	t3b: null,
	t4: null,
	t5: null,
	t6: null,
	priceExchangeRates: null,
	priceUpdateInfo: null,
	storageOverviewsDatastoreData: null,
	storageOverviewsVmData: null,
	storageOverviewsDatastoreVmData: null,
	version: require('../../assets/version.json'),
	kpiHealth: null,
	dcviewTreeData: null,
  	dcviewTreeStorageData: null,
	greenitPower: null
  };

  public currentJsonSubject: BehaviorSubject<Json> = new BehaviorSubject<Json>(this.json);
  public currentJson: Observable<Json> = this.currentJsonSubject.asObservable();
  public eventJsonAsyncLoaded = new EventEmitter<Json>()
  public currentJsonSimpleSubject = new AsyncSubject<Json>();

  /**
   *
   */
  constructor(private http: HttpClient, private router: Router,
	private message_svc: ShareService) {
		this.message_svc.currentMessage.subscribe(message => this.message = message);
	 }

  /**
   * 
   */
  changeJson(user: string, filter: string): void {

	this.message.waiting = true;

	// Get json files
	let dcinframin = this.getData(user, filter, JSONTarget.DC_INFRA_MIN);
    let rpSynthesis = this.getData(user, filter, JSONTarget.RP_SYNTHESIS);
	let clusterSynthesis = this.getData(user, filter, JSONTarget.CLUSTER_SYNTHESIS);
	let clustersPrediction = this.getData(user, filter, JSONTarget.CLUSTERS_PREDICTION);
	let hostInfo = this.getData(user, filter, JSONTarget.HOST_INFO);
	let hostConsover = this.getData(user, filter, JSONTarget.HOST_CONSOVER);
	let hostSynthesis = this.getData(user, filter, JSONTarget.HOST_SYNTHESIS);
	let hostsPrediction = this.getData(user, filter, JSONTarget.HOSTS_PREDICTION);
	let vmInfo = this.getData(user, filter, JSONTarget.VM_INFO);
	let vmConsover = this.getData(user, filter, JSONTarget.VM_CONSOVER);
	let vmSynthesis = this.getData(user, filter, JSONTarget.VM_SYNTHESIS);
	let costAllocated = this.getData(user, filter, JSONTarget.COST_ALLOCATED);
	let t1 = this.getData(user, filter, JSONTarget.T1);
	let t2 = this.getData(user, filter, JSONTarget.T2);
	let opportunities = this.getData(user, filter, JSONTarget.OPPORTUNITIES);
	let t3a = this.getData(user, filter, JSONTarget.T3A);
	let t3b = this.getData(user, filter, JSONTarget.T3B);
	let t4 = this.getData(user, filter, JSONTarget.T4);
	let t5 = this.getData(user, filter, JSONTarget.T5);
	let t6 = this.getData(user, filter, JSONTarget.T6);
	let aws = this.getCloudPricingData(JSONTarget.CLOUD_AWS);
	let az = this.getCloudPricingData(JSONTarget.CLOUD_AZURE);
	let gcp = this.getCloudPricingData(JSONTarget.CLOUD_GCP);
	let ovh = this.getCloudPricingData(JSONTarget.CLOUD_OVH);
	let rates = this.getCloudPricingData(JSONTarget.CLOUD_RATES);
	let update_infos = this.getCloudPricingData(JSONTarget.CLOUD_UPDATE_INFO);
	let storageOverviewsDatastoreData = this.getData(user, filter, JSONTarget.STORAGE_OVERVIEWS_DS);
	let storageOverviewsVmData = this.getData(user, filter, JSONTarget.STORAGE_OVERVIEWS_VM);
	let storageOverviewsDatastoreVmData = this.getData(user, filter, JSONTarget.STORAGE_OVERVIEWS_DS_VM);
	let kpiHealth = this.getData(user, filter, JSONTarget.KPI_HEALTH);

	let vmComputeStorage = this.getData(user, filter, JSONTarget.VM_COMPUTE_STORAGE);
	let vmComputeStorageReco = this.getData(user, filter, JSONTarget.VM_COMPUTE_STORAGE_RECO);
	let dcViewTreeData = this.getData(user, filter, JSONTarget.DCVIEW_TREE_DATA);
	let dcViewTreeStorageData = this.getData(user, filter, JSONTarget.DCVIEW_TREE_STORAGE_DATA);
    let greenitPower = this.getData(user, filter, JSONTarget.GREENIT_POWER);

	// wait until all observables have emitted a value
	zip(dcinframin, rpSynthesis, clusterSynthesis, clustersPrediction, hostInfo, hostConsover, hostSynthesis, hostsPrediction, 
		vmInfo,vmConsover, vmSynthesis, costAllocated, t1, t2, opportunities, t3a, t3b, t4, t5, t6,
		aws, az, gcp, ovh, rates, update_infos, storageOverviewsDatastoreData, storageOverviewsVmData, storageOverviewsDatastoreVmData,
		kpiHealth, vmComputeStorage, vmComputeStorageReco, dcViewTreeData, dcViewTreeStorageData, greenitPower).subscribe(

		all => {
			// Notify
			this.currentJsonSubject.next(this.json);
			this.eventJsonAsyncLoaded.emit(this.json);
			this.currentJsonSimpleSubject.next(this.json);
			this.currentJsonSimpleSubject.complete();

			let whiteList = ["/god", "/cloud/*" , "/cloudimpact", "/my_cloud_pricing/*", "/dcviews/*", "/storage-overview", "/storage-overview-vm", "/netscope/*"];

			if (whiteList.map((p) => this.router.url.match(p)).filter((v) => v !== null).length) {
				// Do nothing: these components don't need to be reloaded!
			} else {
				// Route to dashboard
				if (this.router.url != "/dashboard") {
					this.router.navigate(['/dashboard']);
				}
			}
			this.message.waiting = false;

		},
		error => {
			console.log(error);

 		 	// empty json
			this.resetJson();

			this.message.waiting = false;
		}
	);
  }

  /**
   * 
   */
  initJson() {
	  this.json = {
		dcInfraMin: null,
		rpSynthesis: null,
		clusterSynthesis: null,
		clustersPrediction: null,
		hostInfo: null,
		hostConsover: null,
		hostSynthesis: null,
		hostsPrediction: null,
		vmInfo: null,
		vmConsover: null,
		vmSynthesis: null,
		vmComputeStorage: null,
		vmComputeStorageReco: null,
		costAllocated: null,
		t1: null,
		t2: null,
		opportunities: null,
		t3a: null,
		t3b: null,
		t4: null,
		t5: null,
		t6: null,
		priceExchangeRates: null,
		priceUpdateInfo: null,
		storageOverviewsDatastoreData: null,
		storageOverviewsVmData: null,
		storageOverviewsDatastoreVmData: null,
		version: require('../../assets/version.json'),
		kpiHealth: null,
		dcviewTreeData: null,
		dcviewTreeStorageData: null,
		greenitPower: null
	  };
  }

  /**
   * 
   */
  initJsonVmCons(): void {
	this.json.vmConsover = null;
	// Notify
	this.currentJsonSubject.next(this.json);
  }

  /**
   * 
   */
  reloadJsonCloudPricing(): void {

	// Get cloud pricing jsons
	let aws = this.getCloudPricingData(JSONTarget.CLOUD_AWS);
	let az = this.getCloudPricingData(JSONTarget.CLOUD_AZURE);
	let gcp = this.getCloudPricingData(JSONTarget.CLOUD_GCP);
	let ovh = this.getCloudPricingData(JSONTarget.CLOUD_OVH);
	let rates = this.getCloudPricingData(JSONTarget.CLOUD_RATES);
	let infos = this.getCloudPricingData(JSONTarget.CLOUD_UPDATE_INFO);

	zip(aws, az, gcp, ovh, rates, infos).subscribe(
	  all => {
		  // Notify
		  this.currentJsonSubject.next(this.json);
	  }
	);
  }

  /**
   * 
   */
  getData(user: string, filter: string, target: JSONTarget) {
	return this.http.get<any>(`${environment.apiUrl}/` + 'json/data/' + user + '&' + filter + '&' + target).pipe(map(
		data => {
			//Treat empty data
			if (JSON.stringify(data) == this.API_EMPTY_RESULT) {
				data = null;
			}

			switch(target) {
				case JSONTarget.DC_INFRA_MIN:
					this.json.dcInfraMin = data;
				break;
				case JSONTarget.RP_SYNTHESIS:
					this.json.rpSynthesis = data;
				break;
				case JSONTarget.CLUSTER_SYNTHESIS:
					this.json.clusterSynthesis = data;
				break;
				case JSONTarget.CLUSTERS_PREDICTION:
					this.json.clustersPrediction = data;
				break;
				case JSONTarget.HOST_INFO:
					this.json.hostInfo = data;
				break;
				case JSONTarget.HOST_CONSOVER:
					this.json.hostConsover = data;
				break;
				case JSONTarget.HOST_SYNTHESIS:
					this.json.hostSynthesis = data;
				break;
				case JSONTarget.HOSTS_PREDICTION:
					this.json.hostsPrediction = data;
				break;
				case JSONTarget.VM_INFO:
					this.json.vmInfo = data;
				break;
				case JSONTarget.VM_CONSOVER:
					this.json.vmConsover = data;
				break;
				case JSONTarget.VM_SYNTHESIS:
					this.json.vmSynthesis = data;
				break;
				case JSONTarget.COST_ALLOCATED:
					this.json.costAllocated = data;
				break;
				case JSONTarget.T1:
					this.json.t1 = data;
				break;
				case JSONTarget.T2:
					this.json.t2 = data;
				break;
				case JSONTarget.OPPORTUNITIES:
					this.json.opportunities = data;
				break;
				case JSONTarget.T3A:
					this.json.t3a = data;
				break;
				case JSONTarget.T3B:
					this.json.t3b = data;
				break;
				case JSONTarget.T4:
					this.json.t4 = data;
				break;
				case JSONTarget.T5:
					this.json.t5 = data;
				break;
				case JSONTarget.T6:
					this.json.t6 = data;
				break;
				case JSONTarget.STORAGE_OVERVIEWS_DS:
					this.json.storageOverviewsDatastoreData = data;
				break;
				case JSONTarget.STORAGE_OVERVIEWS_VM:
					this.json.storageOverviewsVmData = data;
				break;
				case JSONTarget.STORAGE_OVERVIEWS_DS_VM:
				case JSONTarget.DCVIEW_TREE_STORAGE_DATA:
					this.json.storageOverviewsDatastoreVmData = data;
					this.json.dcviewTreeStorageData = data;
				break;
				case JSONTarget.DCVIEW_TREE_DATA:
					this.json.dcviewTreeData = data;
				break;
				case JSONTarget.KPI_HEALTH:
					this.json.kpiHealth = data;
				break;
				case JSONTarget.VM_COMPUTE_STORAGE:
					this.json.vmComputeStorage = data;
				break;
				case JSONTarget.VM_COMPUTE_STORAGE_RECO:
					this.json.vmComputeStorageReco = data;
				break;
				case JSONTarget.GREENIT_POWER:
					this.json.greenitPower = data;
				break;
			}

			return data;
		}
	));
  }

  /**
   * 
   */
  getCloudPricingData(target: JSONTarget) {
	return this.http.get<any>(`${environment.apiUrl}/` + 'json/cloudpricingdata/' + target).pipe(map(
		data => {
			//Treat empty data 
			if (JSON.stringify(data) == this.API_EMPTY_RESULT) {
				data = null;
			}
			switch(target) {
				case JSONTarget.CLOUD_RATES:
					this.json.priceExchangeRates = data;
				break;
				case JSONTarget.CLOUD_UPDATE_INFO:
					this.json.priceUpdateInfo = data;
				break;
			}
			return data;
		}
	));
  }

  // Check jsons
  checkJson(user: string, filter: string) {
      return this.http.post(`${environment.apiUrl}/` + 'json/check/', [user, filter]);
  }
	
  // Clean jsons
  cleanJson(user: string, filter: string) {
	return this.http.post(`${environment.apiUrl}/` + 'json/clean/', [user, filter]);
  }

  // Clean all jsons
  cleanAllJson() {
	return this.http.delete(`${environment.apiUrl}/` + 'json/cleanall/');
  }

  resetJson() {
	  this.initJson();
	  // Notify
	  this.currentJsonSubject.next(this.json);
	  this.eventJsonAsyncLoaded.emit(this.json);
	  this.currentJsonSimpleSubject.next(this.json);
	  this.currentJsonSimpleSubject.complete();
  }


}

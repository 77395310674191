import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Message } from '@app/model';
import { MonitorService } from './monitor.service';


@Injectable({
  providedIn: 'root'
})
export class ShareService {

  app: string = "dcscope";

  msg: Message = {
    isCO2Scope: false,
    isDCScope: false,
    currentUuid: '',
    currentName: '',
    currentType: '',
    isNotLoginPage: false,
    breadcrumpRoute: '',
    currentFilter: 'all',
    elementView: 'all',
    periodView: 'all',
    minTimeFilter: 0,
    maxTimeFilter: 0,
    vmSynth: null,
    clusterSynth: null,
    hostSynth: null,
    elementViewUser: 'root',
    elementViewSelected: '',
    elementViewTree: [],
    isElementWizard: false,
    isTreeAnalysis: false,
    elementViewType: 'server',
    elementPeriodSelected: '',
    elementPeriodStart: '',
    elementPeriodEnd: '',
    isPeriodWizard: false,
    filterList: [],
    isReloadConsover: false,
    resourceConsover: 'CPU',
    searchConsover: '',
    isVmConsover: false,
    alertTriggeredState: '',
    isAlertRt: true,
    alertHistoryTime: 0,
    alertWatcher: {
      rt_cri: 0,
      rt_warn: 0,
      rt_run: 0,
      dy_cri: 0,
      dy_warn: 0,
      dy_run: 0,
      all_cri: 0
    },
    troubleSelect: null,
    isTroubleGraph: false,
    troubleCounter: '',
    isTroubleMap: true,
    isRecoResize: false,
    isRecoConsolidation: false,
    kpiStart: 0,
    kpiEnd: 0,
    kpiActivity: '',
    kpiHealth: '',
    waiting: false,
    costType: 'VM',
    isCostTool: false,
    isCostRecord: false,
    t6Detail: 'allocated',
    vmListFilter: '',
    colListFilter: 0,
    recoResizeSearch: '',
    genericInfo: '',
    genericInfoType: 'info',
    isNoInfo: true,
    isRecoPowershell: false,
    isPowershellCpu: false,
    isPowershellRam: false,
    isModalPowershellCpu: false,
    isModalPowershellRam: false,
    recoServerStop: [],
    vmInfoOs: 0,
    cloudPricing: {
      currency: "EUR",
	    period: "year",
	    applyRecommendations: false,
	    billingType: "od",
    },
    currentBehTbl: true,
    costStoGeneric: 1,
    costVmGeneric: 200,
    costStoDetail: [],
    costVmDetail: [],
    costCpuGeneric: 89,
    costRamGeneric: 19,
    costSrvGeneric: 1000,
    costRatioGeneric: 40,
    costSrvDetail: [],
    creditSelected: '',
    isShared: false,
    cloudRegion: '',
    cloudProvider: '',
    cloudAccount: '',
    isValidAws: false,
    isValidAzure: false,
    isFromAlert: false,
    carbon_radar: [],
    efficiency_data: [],
    calcons_counter: 'cpu_usage',
    calcons_rollup: 'avg',
    powerUsageEnv: 'vmware'
  };

  private currentMessageSubject: BehaviorSubject<Message> = new BehaviorSubject<Message>(this.msg);
  public currentMessage: Observable<Message> = this.currentMessageSubject.asObservable();

  constructor(private monitor_svc: MonitorService) {
  }

  public changeMessage(message: Message): void {
    this.currentMessageSubject.next(message)
  }

  public initMessage(): void {
    this.msg.currentUuid = '';
    this.msg.currentName = '';
    this.msg.currentType = '';
    this.msg.isNotLoginPage = false;
    this.msg.currentFilter = 'all';
    this.msg.elementView = 'all';
    this.msg.periodView = 'all';
    this.msg.minTimeFilter = 0;
	  this.msg.maxTimeFilter = 0;
    this.msg.vmSynth = null;
    this.msg.clusterSynth = null;
    this.msg.hostSynth = null;
    this.msg.elementViewUser = 'root';
	  this.msg.elementViewSelected = '';
    this.msg.elementViewTree = [];
    this.msg.isElementWizard = false;
    this.msg.isTreeAnalysis = false;
    this.msg.elementViewType = 'server';
	  this.msg.elementPeriodSelected = '';
    this.msg.elementPeriodStart = '';
    this.msg.elementPeriodEnd = '';
    this.msg.isPeriodWizard = false;
    this.msg.filterList = [];
    this.msg.isReloadConsover = false;
    this.msg.resourceConsover = 'CPU';
    this.msg.searchConsover = '';
    this.msg.isVmConsover = false;
    this.msg.alertTriggeredState = '';
    this.msg.isAlertRt = true;
	  this.msg.alertHistoryTime = 0;
    this.msg.alertWatcher = {
      rt_cri: 0,
      rt_warn: 0,
      rt_run: 0,
      dy_cri: 0,
      dy_warn: 0,
      dy_run: 0,
      all_cri: 0
    };
    this.msg.troubleSelect = null;
    this.msg.isTroubleGraph = false;
    this.msg.troubleCounter = '';
    this.msg.isTroubleMap = true;
    this.msg.isRecoResize = false;
    this.msg.isRecoConsolidation = false;
    this.msg.kpiStart = 0;
    this.msg.kpiEnd = 0;
    this.msg.kpiActivity = '';
    this.msg.kpiHealth = '';
    this.msg.waiting = false;
    this.msg.costType = 'VM';
    this.msg.isCostTool = false;
    this.msg.isCostRecord = false;
    this.msg.t6Detail = 'allocated';
    this.msg.vmListFilter = '';
    this.msg.colListFilter = 0;
    this.msg.recoResizeSearch = '';
    this.msg.genericInfo = '';
    this.msg.genericInfoType = 'info';
    this.msg.isNoInfo = true;
    this.msg.isRecoPowershell = false;
    this.msg.isPowershellCpu = false;
    this.msg.isPowershellRam = false;
    this.msg.isModalPowershellCpu = false;
    this.msg.isModalPowershellRam = false;
    this.msg.recoServerStop = [];
    this.msg.vmInfoOs = 0;
    this.msg.currentBehTbl = true;
    this.msg.costStoGeneric = 1;
    this.msg.costStoDetail = [];
    this.msg.costVmGeneric = 200;
    this.msg.costVmDetail = [];
    this.msg.costCpuGeneric = 89;
    this.msg.costRamGeneric = 19;
    this.msg.costSrvGeneric = 10000;
    this.msg.costRatioGeneric = 40;
    this.msg.costSrvDetail = [];
    this.msg.creditSelected = '';
    this.msg.isShared = false;
    this.msg.cloudRegion = '';
    this.msg.cloudProvider = '';
    this.msg.cloudAccount = '';
    this.msg.isValidAws = false;
    this.msg.isValidAzure = false;
    this.msg.isFromAlert = false;
    this.msg.carbon_radar = [];
    this.msg.efficiency_data = [];
    this.msg.calcons_counter = 'cpu_usage';
    this.msg.calcons_rollup = 'avg';
    this.msg.powerUsageEnv = 'vmware';
  }

  resetMessage(): void {
    this.initMessage();
  }

  initApp() {

    let settings = this.monitor_svc.getSettings().subscribe(
      data => {
        let app: string = 'dcscope';

        data.forEach(element => {
          switch (element.option) {
            case "ovf.application.name":
              app = element.value;
              break;
          };
        });

        switch(app) {
          case 'co2scope':
            this.msg.isDCScope = false;
            this.msg.isCO2Scope = true;
            break;
          default:
            this.msg.isDCScope = true;
            this.msg.isCO2Scope = false;
          break;
        }

        settings.unsubscribe();
      },
      error => {
        this.msg.isDCScope= true;
        this.msg.isCO2Scope = false;

        settings.unsubscribe();
      }
    );    
  }
}

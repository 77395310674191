import { Component, OnInit } from '@angular/core';

import { ShareService } from '@app/services';

import { Message, TsSelected } from '@app/model';


@Component({
  selector: 'app-troubleshootingcardlist',
  templateUrl: './troubleshootingcardlist.component.html',
  styleUrls: ['./troubleshootingcardlist.component.css']
})
export class TroubleshootingcardlistComponent implements OnInit {

  message: Message;

  ts_selected: TsSelected = {
	name: '',
	uuid: '',
	father_id: '',
	type: '',
	cpu_nb: 0,
	pts_cpu_red: 0,
	pts_cpu_ora: 0,
	pts_crsr_red: 0,
	pts_crsr_ora: 0,
	pts_crdy_red: 0,
	pts_crdy_ora: 0,
	pts_cstp_red: 0,
	pts_cstp_ora: 0,
	pts_net_red: 0,
	pts_net_ora: 0,
	pts_ram_red: 0,
	pts_ram_ora: 0,
	pts_dlat_ora: 0,
	pts_dlat_red: 0,
	pts_dcmd_ora: 0,
	pts_dcmd_red: 0
  }


  constructor(
	private message_svc: ShareService) {
  }

  ngOnInit(): void {

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.ts_selected = this.message.troubleSelect;
  }

  callGraph(val: string): void {

	this.message.waiting = true;
	this.message.troubleCounter = val;
	this.message.isTroubleGraph = true;
  }
}

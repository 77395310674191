import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, Component, OnInit, ViewChild } from '@angular/core';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import { User, Message, RecoVm } from '@app/model';

import { RecommendationresizingdetailcpuComponent } from '@app/recommendation/recommendationresizingdetailcpu/recommendationresizingdetailcpu.component';
import { RecommendationresizingdetailcpuDirective } from '@app/directives/recommendationresizingdetailcpu.directive';

import { RecommendationresizingdetaildiskComponent } from '@app/recommendation/recommendationresizingdetaildisk/recommendationresizingdetaildisk.component';
import { RecommendationresizingdetaildiskDirective } from '@app/directives/recommendationresizingdetaildisk.directive';

import { RecommendationresizingdetailramComponent } from '@app/recommendation/recommendationresizingdetailram/recommendationresizingdetailram.component';
import { RecommendationresizingdetailramDirective } from '@app/directives/recommendationresizingdetailram.directive';


@Component({
  selector: 'app-recommendationresizingdetail',
  templateUrl: './recommendationresizingdetail.component.html',
  styleUrls: ['./recommendationresizingdetail.component.css']
})
export class RecommendationresizingdetailComponent implements AfterViewInit, OnInit {

  @ViewChild(RecommendationresizingdetaildiskDirective) addResizingDetailDisk: RecommendationresizingdetaildiskDirective;
  @ViewChild(RecommendationresizingdetailcpuDirective) addResizingDetailCpu: RecommendationresizingdetailcpuDirective;
  @ViewChild(RecommendationresizingdetailramDirective) addResizingDetailRam: RecommendationresizingdetailramDirective;

  message: Message;

  math = Math;

  data_model: RecoVm[] = [];

  data_elements: RecoVm[] = [];

  isCpu: boolean = true;
  isDisk: boolean = false;
  isRam: boolean = false;

  private currentUser: User;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  ngAfterViewInit(): void {
	setTimeout(() => this.loadResizeDetailCpu(), 500);
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  switchResource(resource: string): void {

	switch(resource) {
		case "cpu":
			this.isCpu = true;
			this.isDisk = false;
			this.isRam  = false;
			setTimeout(() => this.loadResizeDetailCpu(), 100);
	  		break;
	  	case "disk":
			this.isCpu = false;
			this.isDisk = true;
			this.isRam  = false;
			setTimeout(() => this.loadResizeDetailDisk(), 100);
	  		break;
	  	case "ram":
			this.isCpu = false;
			this.isDisk = false;
			this.isRam  = true;
			setTimeout(() => this.loadResizeDetailRam(), 100);
	  		break;
	  	default:
	  		break;
	}
  }

  private loadResizeDetailDisk(): void {

	if(this.addResizingDetailDisk != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RecommendationresizingdetaildiskComponent);
		const viewContainerRef = this.addResizingDetailDisk.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadResizeDetailCpu(): void {

	if(this.addResizingDetailCpu != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RecommendationresizingdetailcpuComponent);
		const viewContainerRef = this.addResizingDetailCpu.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadResizeDetailRam(): void {

	if(this.addResizingDetailRam != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RecommendationresizingdetailramComponent);
		const viewContainerRef = this.addResizingDetailRam.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }
}

<div class="clr-row" style="width: 100%">
    <div class="clr-col-11">
        <h3 style="margin: 5px;">
            Dashboard - {{ currentDayTimestamp * 1000 | date: 'dd/MM/yyyy'}} <button [disabled]="isFirstDay" class="btn btn-link" (click)="setPreviousTimestamp()">previous day</button> <button [disabled]="isLastDay" class="btn btn-link" (click)="setNextTimestamp()">next day</button>
            <span *ngIf="isLoading" style="margin: 0px;">
        <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
      </span>
        </h3>
    </div>
    <div class="clr-col-1">
    <span *ngIf="!isLoading" style="float: right; position: absolute;">
      <button (click)="reloadData()" class="btn btn-primary btn-sm" style="z-index: 1; position: absolute;">reload</button>
    </span>
    </div>
</div>

<clr-accordion style="margin-top: 15px;">
    <clr-accordion-panel>
        <clr-accordion-title>Visualisation settings</clr-accordion-title>
        <clr-accordion-content>
            <clr-checkbox-container clrInline>
                <label>Options</label>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox name="destination" [(ngModel)]="visualisationSettings.only_flows_with_src_and_dst_in_the_filter" />
                    <label>Analyse only flows with source and destination in the filter</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>

<div class="clr-row">
    <div class="clr-col-4">
        <div class="card">
            <div class="card-header">
                Inter vs intra datacenter traffic
            </div>
            <div class="card-block">
                <div class="card-title">
                </div>
                <div class="card-text">
                    <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="originPieChartOptions"
                            [(update)]="updateOriginPieChartFlag"
                            [callbackFunction]="callbackOriginPieChartRef"
                            style="width: 100%; height: 100%; max-height: 400px; display: block;">
                    </highcharts-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-4">
        <div class="card">
            <div class="card-header">
                Protocols
            </div>
            <div class="card-block">
                <div class="card-title">
                </div>
                <div class="card-text">
                    <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="protocolPieChartOptions"
                            [(update)]="updateProtocolPieChartFlag"
                            [callbackFunction]="callbackProtocolPieChartRef"
                            style="width: 100%; height: 100%; max-height: 400px; display: block;">
                    </highcharts-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-4">
        <div class="card">
            <div class="card-header">
                Top 20 Emitters
            </div>
            <div class="card-block">
                <div class="card-title">
                </div>
                <div class="card-text">
                    <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="emitterPieChartOptions"
                            [(update)]="updateEmitterPieChartFlag"
                            [callbackFunction]="callbackResourcePieChartRef"
                            style="width: 100%; height: 100%; max-height: 400px; display: block;">
                    </highcharts-chart>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-4">
        <div class="card">
            <div class="card-block">
                <h3 class="card-title">Data analysis</h3>
                <p class="card-text">
                    {{amountOfDataProcessed / (1024 * 1024)  | number: '1.0-0'}} MiB analysed
                </p>
            </div>
        </div>
    </div>
    <div class="clr-col-4">
        <div class="card">
            <div class="card-block">
                <h3 class="card-title">Packets analysis</h3>
                <p class="card-text">
                    {{amountOfPacketsProcessed  | number: '1.0-0'}} packets analysed
                </p>
            </div>
        </div>
    </div>
    <div class="clr-col-4">
        <div class="card">
            <div class="card-block">
                <h3 class="card-title">Flows analysis</h3>
                <p class="card-text">
                    {{amountOfFlowsProcessed  | number: '1.0-0'}} flows analysed
                </p>
            </div>
        </div>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-12">
        <div class="card">
            <div class="card-header">
                Details of traffic <button class="btn btn-primary btn-sm" style="float: right; margin-top: 0px; margin-bottom: 5px;" (click)="exportCSV()">Export CSV</button>
            </div>
            <div class="card-block" style="padding-top: 0px;">
                <div class="card-title">
                </div>
                <div class="card-text">

                    <clr-tabs>
                        <clr-tab>
                            <button clrTabLink>Matching flows</button>
                            <clr-tab-content *clrIfActive>
                                <clr-datagrid>
                                    <clr-dg-column [clrDgField]="'src_address'">
                                        Source
                                        <clr-dg-string-filter [clrDgStringFilter]="sourceIpFilter"></clr-dg-string-filter>
                                    </clr-dg-column>
                                    <clr-dg-column [clrDgField]="'dst_address'">
                                        Destination
                                        <clr-dg-string-filter [clrDgStringFilter]="destinationIpFilter"></clr-dg-string-filter>
                                    </clr-dg-column>
                                    <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort">Total bytes</clr-dg-column>
                                    <clr-dg-column [clrDgField]="'exchanged_packets'">Total (packets)</clr-dg-column>
                                    <clr-dg-column>Actions</clr-dg-column>

                                    <clr-dg-row *clrDgItems="let flowDetail of flowDetails" [clrDgItem]="flowDetail">
                                        <clr-dg-cell *ngIf="!(flowDetail.src_object !== undefined)">{{flowDetail.src_address}} <span *ngIf="flowDetail.src_ip_resolved !== undefined" class="label label-{{flowDetail.src_ip_resolved_class}}">{{flowDetail.src_ip_resolved}}</span><a style="cursor: pointer;"><clr-icon (click)="resolveDomain(flowDetail, 'src')" shape="network-settings"></clr-icon></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.src_object !== undefined && flowDetail.src_object.type === 'vm' && flowDetail.src_object.is_in_filter"><a routerLink="/god/resource/{{flowDetail.src_object.short_uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{flowDetail.src_object.name}}</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.src_object !== undefined && flowDetail.src_object.type === 'host' && flowDetail.src_object.is_in_filter"><a routerLink="/god/resource/{{flowDetail.src_object.short_uuid}}"  [queryParams]="{useResourceCountersFor: 'SERVER'}"  routerLinkActive="active" style="text-decoration: none;"><span class="badge">{{flowDetail.src_object.name}}</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.src_object !== undefined && flowDetail.src_object.type === 'vm' && !flowDetail.src_object.is_in_filter"><span class="label label-blue">{{flowDetail.src_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><clr-icon shape="info-circle" size="24"></clr-icon><span class="tooltip-content">Resource {{flowDetail.src_address}} is not available in this filter</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.src_object !== undefined && flowDetail.src_object.type === 'host' && !flowDetail.src_object.is_in_filter"><span class="label">{{flowDetail.src_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><clr-icon shape="info-circle" size="24"></clr-icon><span class="tooltip-content">Resource {{flowDetail.src_address}} is not available in this filter</span></a></clr-dg-cell>

                                        <clr-dg-cell *ngIf="!(flowDetail.dst_object !== undefined)">{{flowDetail.dst_address}} <span *ngIf="flowDetail.dst_ip_resolved !== undefined" class="label label-{{flowDetail.dst_ip_resolved_class}}">{{flowDetail.dst_ip_resolved}}</span><a style="cursor: pointer;"><clr-icon (click)="resolveDomain(flowDetail, 'dst')" shape="network-settings"></clr-icon></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.dst_object !== undefined && flowDetail.dst_object.type === 'vm' && flowDetail.dst_object.is_in_filter"><a routerLink="/god/resource/{{flowDetail.dst_object.short_uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{flowDetail.dst_object.name}}</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.dst_object !== undefined && flowDetail.dst_object.type === 'host' && flowDetail.dst_object.is_in_filter"><a routerLink="/god/resource/{{flowDetail.dst_object.short_uuid}}"  [queryParams]="{useResourceCountersFor: 'SERVER'}"  routerLinkActive="active" style="text-decoration: none;"><span class="badge">{{flowDetail.dst_object.name}}</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.dst_object !== undefined && flowDetail.dst_object.type === 'vm' && !flowDetail.dst_object.is_in_filter"><span class="label label-blue">{{flowDetail.dst_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><clr-icon shape="info-circle" size="24"></clr-icon><span class="tooltip-content">Resource {{flowDetail.dst_address}} is not available in this filter</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.dst_object !== undefined && flowDetail.dst_object.type === 'host' && !flowDetail.dst_object.is_in_filter"><span class="label">{{flowDetail.dst_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><clr-icon shape="info-circle" size="24"></clr-icon><span class="tooltip-content">Resource {{flowDetail.dst_address}} is not available in this filter</span></a></clr-dg-cell>

                                        <clr-dg-cell>{{flowDetail.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>
                                        <clr-dg-cell>{{flowDetail.exchanged_packets | number}}</clr-dg-cell>

                                        <ng-template #actions_if_true>
                                            <a *ngIf="flowDetail.src_object.type === 'vm' && flowDetail.dst_object.type === 'vm'" style="margin-right: 5px;" routerLink="/netscope/dependencies-viewer/focuson/{{flowDetail.src_object.uuid}},{{flowDetail.dst_object.uuid}}" routerLinkActive="active"><clr-icon shape="recycle"></clr-icon></a>
                                            <a *ngIf="flowDetail.src_object.type === 'vm' && flowDetail.dst_object.type === 'vm'" style="margin-right: 5px;" routerLink="/netscope/clusters-viewer/focuson/{{flowDetail.src_object.uuid}},{{flowDetail.dst_object.uuid}}" routerLinkActive="active"><clr-icon shape="organization"></clr-icon></a>
                                            <a routerLink="/netscope/heatmap-viewer/{{flowDetail.src_object.uuid}}/{{flowDetail.dst_object.uuid}}" routerLinkActive="active"><clr-icon shape="heat-map"></clr-icon></a>
                                        </ng-template>
                                        <ng-template #actions_if_not_true></ng-template>
                                        <clr-dg-cell>
                                            <div *ngIf="flowDetail.src_object !== undefined && flowDetail.src_object.is_in_filter && flowDetail.dst_object !== undefined && flowDetail.dst_object.is_in_filter; then actions_if_true else actions_if_not_true"></div>
                                        </clr-dg-cell>


                                        <app-expandable-rows *clrIfExpanded [flowDetail]="flowDetail" [day]="this.currentDayTimestamp" ngProjectAs="clr-dg-row-detail"></app-expandable-rows>
                                    </clr-dg-row>

                                    <clr-dg-footer>
                                        <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                                        </clr-dg-pagination>
                                    </clr-dg-footer>

                                </clr-datagrid>
                            </clr-tab-content>
                        </clr-tab>

                        <clr-tab>
                            <button clrTabLink>Unmatching flows</button>
                            <clr-tab-content *clrIfActive>
                                <clr-datagrid>
                                    <clr-dg-column [clrDgField]="'src_address'">
                                        Source
                                        <clr-dg-string-filter [clrDgStringFilter]="sourceIpFilter"></clr-dg-string-filter>
                                    </clr-dg-column>
                                    <clr-dg-column [clrDgField]="'dst_address'">
                                        Destination
                                        <clr-dg-string-filter [clrDgStringFilter]="destinationIpFilter"></clr-dg-string-filter>
                                    </clr-dg-column>
                                    <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort">Total bytes</clr-dg-column>
                                    <clr-dg-column [clrDgField]="'exchanged_packets'">Total (packets)</clr-dg-column>

                                    <clr-dg-row *clrDgItems="let flowDetail of outsideFlowDetails" [clrDgItem]="flowDetail">
                                        <clr-dg-cell *ngIf="!(flowDetail.src_object !== undefined)">{{flowDetail.src_address}} <span *ngIf="flowDetail.src_ip_resolved !== undefined" class="label label-{{flowDetail.src_ip_resolved_class}}">{{flowDetail.src_ip_resolved}}</span><a style="cursor: pointer;"><clr-icon (click)="resolveDomain(flowDetail, 'src')" shape="network-settings"></clr-icon></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.src_object !== undefined && flowDetail.src_object.type === 'vm' && flowDetail.src_object.is_in_filter"><a routerLink="/god/resource/{{flowDetail.src_object.short_uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{flowDetail.src_object.name}}</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.src_object !== undefined && flowDetail.src_object.type === 'host' && flowDetail.src_object.is_in_filter"><a routerLink="/god/resource/{{flowDetail.src_object.short_uuid}}"  [queryParams]="{useResourceCountersFor: 'SERVER'}"  routerLinkActive="active" style="text-decoration: none;"><span class="badge">{{flowDetail.src_object.name}}</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.src_object !== undefined && flowDetail.src_object.type === 'vm' && !flowDetail.src_object.is_in_filter"><span class="label label-blue">{{flowDetail.src_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><clr-icon shape="info-circle" size="24"></clr-icon><span class="tooltip-content">Resource {{flowDetail.src_address}} is not available in this filter</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.src_object !== undefined && flowDetail.src_object.type === 'host' && !flowDetail.src_object.is_in_filter"><span class="label">{{flowDetail.src_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><clr-icon shape="info-circle" size="24"></clr-icon><span class="tooltip-content">Resource {{flowDetail.src_address}} is not available in this filter</span></a></clr-dg-cell>

                                        <clr-dg-cell *ngIf="!(flowDetail.dst_object !== undefined)">{{flowDetail.dst_address}} <span *ngIf="flowDetail.dst_ip_resolved !== undefined" class="label label-{{flowDetail.dst_ip_resolved_class}}">{{flowDetail.dst_ip_resolved}}</span><a style="cursor: pointer;"><clr-icon (click)="resolveDomain(flowDetail, 'dst')" shape="network-settings"></clr-icon></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.dst_object !== undefined && flowDetail.dst_object.type === 'vm' && flowDetail.dst_object.is_in_filter"><a routerLink="/god/resource/{{flowDetail.dst_object.short_uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{flowDetail.dst_object.name}}</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.dst_object !== undefined && flowDetail.dst_object.type === 'host' && flowDetail.dst_object.is_in_filter"><a routerLink="/god/resource/{{flowDetail.dst_object.short_uuid}}"  [queryParams]="{useResourceCountersFor: 'SERVER'}"  routerLinkActive="active" style="text-decoration: none;"><span class="badge">{{flowDetail.dst_object.name}}</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.dst_object !== undefined && flowDetail.dst_object.type === 'vm' && !flowDetail.dst_object.is_in_filter"><span class="label label-blue">{{flowDetail.dst_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><clr-icon shape="info-circle" size="24"></clr-icon><span class="tooltip-content">Resource {{flowDetail.dst_address}} is not available in this filter</span></a></clr-dg-cell>
                                        <clr-dg-cell *ngIf="flowDetail.dst_object !== undefined && flowDetail.dst_object.type === 'host' && !flowDetail.dst_object.is_in_filter"><span class="label">{{flowDetail.dst_address}}</span> <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><clr-icon shape="info-circle" size="24"></clr-icon><span class="tooltip-content">Resource {{flowDetail.dst_address}} is not available in this filter</span></a></clr-dg-cell>

                                        <clr-dg-cell>{{flowDetail.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>
                                        <clr-dg-cell>{{flowDetail.exchanged_packets | number}}</clr-dg-cell>

                                        <app-expandable-rows *clrIfExpanded [flowDetail]="flowDetail" [day]="this.currentDayTimestamp" ngProjectAs="clr-dg-row-detail"></app-expandable-rows>
                                    </clr-dg-row>

                                    <clr-dg-footer>
                                        <clr-dg-pagination #pagination [clrDgPageSize]="10">
                                            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                                        </clr-dg-pagination>
                                    </clr-dg-footer>

                                </clr-datagrid>
                            </clr-tab-content>
                        </clr-tab>
                    </clr-tabs>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-12">
        <div class="card">
            <div class="card-header">
                Overall traffic
            </div>
            <div class="card-block">
                <div class="card-title">
                </div>
                <div class="card-text">
                    <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="overallTrafficChartOptions"
                            [(update)]="updateOverallTrafficChartFlag"
                            [oneToOne]="true"
                            [callbackFunction]="callbackOverallTrafficChartRef"
                            style="width: 100%; height: 100%; max-height: 400px; display: block;">
                    </highcharts-chart>
                </div>
            </div>
        </div>
    </div>
</div>

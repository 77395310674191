import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-vsan]'
})
export class VsanDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

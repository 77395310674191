import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-lcy]'
})
export class LcyDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

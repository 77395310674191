<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="employee-group"></clr-icon>
			&nbsp;Role management
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<button class="btn btn-primary" style="float:left;" (click)="wizardrole.open();initWizard()">
						<clr-icon shape="plus-circle" class="is-solid"></clr-icon>
						CREATE ROLE
					</button>
				</div>
				<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">select a role</h5>
							<div class="card-text">
								<div class="clr-control-container">
									<div class="clr-select-wrapper">
										<select id="role-list" class="clr-select" [(ngModel)]="selected_role" (ngModelChange)="switchRole($event)">
											<option *ngFor="let role of roles">{{role.role}}</option>
										</select>
									</div>
									<span class="clr-subtext">the list of roles</span>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="isDeletable()" *ngIf="selected_role.role != 'admin' && selected_role.role != ''">
								Delete
							</button>
            					</div>
					</div>
				</div>
				<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">Menu activation</h5>
							<div class="card-text">
								<div class="clr-form-control">
									<div class="clr-control-container">
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox1" name="checkbox-full" value="isMenuReport" class="clr-checkbox" [(ngModel)]="selected_role.isMenuReport" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox1" class="clr-control-label">display the reporting menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox2" name="checkbox-full" value="isMenuReco" class="clr-checkbox" [(ngModel)]="selected_role.isMenuReco" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox2" class="clr-control-label">display the recommendation menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox3" name="checkbox-full" value="isMenuGod" class="clr-checkbox" [(ngModel)]="selected_role.isMenuGod" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox3" class="clr-control-label">display the graph on demand menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox4" name="checkbox-full" value="isMenuCapa" class="clr-checkbox" [(ngModel)]="selected_role.isMenuCapa" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox4" class="clr-control-label">display the capacity planning menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox5" name="checkbox-full" value="isMenuTrouble" class="clr-checkbox" [(ngModel)]="selected_role.isMenuTrouble" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox5" class="clr-control-label">display the troubleshooting menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox6" name="checkbox-full" value="isMenuAlert" class="clr-checkbox" [(ngModel)]="selected_role.isMenuAlert" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox6" class="clr-control-label">display the alerting menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox7" name="checkbox-full" value="isMenuKpi" class="clr-checkbox" [(ngModel)]="selected_role.isMenuKpi" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox7" class="clr-control-label">display the kpi menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox9" name="checkbox-full" value="isMenuGreenit" class="clr-checkbox" [(ngModel)]="selected_role.isMenuGreenit" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox9" class="clr-control-label">display the GreenIt menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox17" name="checkbox-full" value="isMenuVcloud" class="clr-checkbox" [(ngModel)]="selected_role.isMenuVcloud" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox17" class="clr-control-label">display the vCloud Director menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox18" name="checkbox-full" value="isMenuVirtindex" class="clr-checkbox" [(ngModel)]="selected_role.isMenuVirtindex" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox18" class="clr-control-label">display the Green-Index menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox19" name="checkbox-full" value="isMenuExternalCloud" class="clr-checkbox" [(ngModel)]="selected_role.isMenuExternalCloud" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox19" class="clr-control-label">display the external cloud menu</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox8" name="checkbox-full" value="isMenuCloud" class="clr-checkbox" [(ngModel)]="selected_role.isMenuCloud" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox8" class="clr-control-label">display cloud pricing menu (licence)</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox10" name="checkbox-full" value="isMenuDCNetScope" class="clr-checkbox" [(ngModel)]="selected_role.isMenuDCNetScope" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox10" class="clr-control-label">display DCNetScope menu (licence)</label>
										</div>
    									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">Dashboard cards activation</h5>
							<div class="card-text">
								<div class="clr-form-control">
									<div class="clr-control-container">
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox15" name="checkbox-full" value="isDashboardT1" class="clr-checkbox" [(ngModel)]="selected_role.isDashboardT1" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox15" class="clr-control-label">display the current virtual machine behavior card</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox16" name="checkbox-full" value="isDashboardT2" class="clr-checkbox" [(ngModel)]="selected_role.isDashboardT2" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox16" class="clr-control-label">display the prediction about vcpu, vdisk and vram card</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox11" name="checkbox-full" value="isDashboardT3" class="clr-checkbox" [(ngModel)]="selected_role.isDashboardT3" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox11" class="clr-control-label">display the opportunities card</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox12" name="checkbox-full" value="isDashboardT4" class="clr-checkbox" [(ngModel)]="selected_role.isDashboardT4" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox12" class="clr-control-label">display the resources over the last 24 hours card</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox13" name="checkbox-full" value="isDashboardT5" class="clr-checkbox" [(ngModel)]="selected_role.isDashboardT5" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox13" class="clr-control-label">display the how many virtual machines can be created card</label>
										</div>
										<div class="clr-checkbox-wrapper">
											<input type="checkbox" id="vertical-checkbox14" name="checkbox-full" value="isDashboardT6" class="clr-checkbox" [(ngModel)]="selected_role.isDashboardT6" (ngModelChange)="isUpdate = true" />
											<label for="vertical-checkbox14" class="clr-control-label">display the cost card</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<button style="float: right;" type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateRole()" *ngIf="isUpdate">
						Update
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<clr-wizard #wizardRole
	[clrWizardSize]="'xl'"
	(clrWizardOnPrevious)="goBack()"
	(clrWizardOnCancel)=doReset() 
	(clrWizardOnFinish)="doFinish()">
    <clr-wizard-title>Create a role</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">Finish</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="model.name == '' || model.name == 'admin' || model.name == 'ADMIN' || filterList(model.name)">
        <ng-template clrPageTitle>The role name</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-form-control">
            				<label>Role name</label>
					<div class="clr-input-container" [ngClass]="{'clr-error' :  model.error}">
						<input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="model.name" name="name" (ngModelChange)="checkName($event)" required />
						<clr-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="model.error"></clr-icon>
						<clr-control-error *ngIf="model.error">This field cannot set {{model.errorName}}</clr-control-error>
					</div>
					<span class="clr-subtext">the name of a new role</span>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>
    <clr-wizard-page>
        <ng-template clrPageTitle>The menus activation</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-form-control">
					<div class="clr-control-container">
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox1" name="checkbox-full" value="isMenuReport" class="clr-checkbox" [(ngModel)]="model.isMenuReport" />
							<label for="wizard-checkbox1" class="clr-control-label">display the reporting menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox2" name="checkbox-full" value="isMenuReco" class="clr-checkbox" [(ngModel)]="model.isMenuReco" />
							<label for="wizard-checkbox2" class="clr-control-label">display the recommendation menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox3" name="checkbox-full" value="isMenuGod" class="clr-checkbox" [(ngModel)]="model.isMenuGod" />
							<label for="wizard-checkbox3" class="clr-control-label">display the graph on demand menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox4" name="checkbox-full" value="isMenuCapa" class="clr-checkbox" [(ngModel)]="model.isMenuCapa" />
							<label for="wizard-checkbox4" class="clr-control-label">display the capacity planning menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox5" name="checkbox-full" value="isMenuTrouble" class="clr-checkbox" [(ngModel)]="model.isMenuTrouble" />
							<label for="wizard-checkbox5" class="clr-control-label">display the troubleshooting menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox6" name="checkbox-full" value="isMenuAlert" class="clr-checkbox" [(ngModel)]="model.isMenuAlert" />
							<label for="wizard-checkbox6" class="clr-control-label">display the alerting menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox7" name="checkbox-full" value="isMenuKpi" class="clr-checkbox" [(ngModel)]="model.isMenuKpi" />
							<label for="wizard-checkbox7" class="clr-control-label">display the kpi menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox9" name="checkbox-full" value="isMenuGreenIt" class="clr-checkbox" [(ngModel)]="model.isMenuGreenit" />
							<label for="wizard-checkbox9" class="clr-control-label">display the greenIt menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="vertical-checkbox17" name="checkbox-full" value="isMenuVcloud" class="clr-checkbox" [(ngModel)]="model.isMenuVcloud" />
							<label for="vertical-checkbox17" class="clr-control-label">display the vCloud Director menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="vertical-checkbox18" name="checkbox-full" value="isMenuVirtindex" class="clr-checkbox" [(ngModel)]="model.isMenuVirtindex" />
							<label for="vertical-checkbox18" class="clr-control-label">display the Green-Index menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox19" name="checkbox-full" value="isMenuExternalCloud" class="clr-checkbox" [(ngModel)]="model.isMenuExternalCloud" />
							<label for="wizard-checkbox19" class="clr-control-label">display the external cloud menu</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox8" name="checkbox-full" value="isMenuCloud" class="clr-checkbox" [(ngModel)]="model.isMenuCloud" />
							<label for="wizard-checkbox8" class="clr-control-label">display cloud pricing menu (licence)</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox10" name="checkbox-full" value="isMenuDCNetScope" class="clr-checkbox" [(ngModel)]="model.isMenuDCNetScope" (ngModelChange)="isUpdate = true" />
							<label for="wizard-checkbox10" class="clr-control-label">display DCNetScope menu (licence)</label>
						</div>
    					</div>
    				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>
    <clr-wizard-page>
        <ng-template clrPageTitle>The dashboard cards activation</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-form-control">
					<div class="clr-control-container">
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox15" name="checkbox-full" value="isDashboardT1" class="clr-checkbox" [(ngModel)]="model.isDashboardT1" />
							<label for="wizard-checkbox15" class="clr-control-label">display the current virtual machine behavior card</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox16" name="checkbox-full" value="isDashboardT2" class="clr-checkbox" [(ngModel)]="model.isDashboardT2" />
							<label for="wizard-checkbox16" class="clr-control-label">display the prediction about vcpu, vdisk and vram card</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox11" name="checkbox-full" value="isDashboardT3" class="clr-checkbox" [(ngModel)]="model.isDashboardT3" />
							<label for="wizard-checkbox11" class="clr-control-label">display the opportunities card</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox12" name="checkbox-full" value="isDashboardT4" class="clr-checkbox" [(ngModel)]="model.isDashboardT4" />
							<label for="wizard-checkbox12" class="clr-control-label">display the resources over the last 24 hours card</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox13" name="checkbox-full" value="isDashboardT5" class="clr-checkbox" [(ngModel)]="model.isDashboardT5" />
							<label for="wizard-checkbox13" class="clr-control-label">display the how many virtual machines can be created card</label>
						</div>
						<div class="clr-checkbox-wrapper">
							<input type="checkbox" id="wizard-checkbox14" name="checkbox-full" value="isDashboardT6" class="clr-checkbox" [(ngModel)]="model.isDashboardT6" />
							<label for="wizard-checkbox14" class="clr-control-label">display the cost card</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>
</clr-wizard>
<clr-modal [(clrModalOpen)]="isDeleteRole" [clrModalClosable]=false>
    <h3 class="modal-title">Delete role</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>This role will be permanently deleted !<b></b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteRole = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="removeRole();isDeleteRole = false">Delete</button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="isDeleteError" [clrModalClosable]=false>
    <h3 class="modal-title">Cannot delete role</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
	    <p><b>the following users are attached to this role</b></p>
	    <table class="table table-compact">
	    	<thead>
			<tr>
				<th>name</th>
			</tr>
	    	</thead>
	    	<tbody>
    			<tr *ngFor="let user of usr_role">
				<td>{{user.login}}</td>
			</tr>
	    	</tbody>
	    </table>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteError = false">OK</button>
    </div>
</clr-modal>

import { Component, OnInit } from '@angular/core';
import { JSONTarget, Message, User, VDCResource, VDCSynthesis } from '@app/model';
import { AccountService, JsonloaderService, ShareService } from '@app/services';
import { ClrDatagridSortOrder } from '@clr/angular';

@Component({
  selector: 'app-vcloud',
  templateUrl: './vcloud.component.html',
  styleUrls: ['./vcloud.component.css']
})
export class VcloudComponent implements OnInit {

  message: Message;
  currentUser: User;

  cpu_res: VDCResource;
  ram_res: VDCResource;
  disk_res: VDCResource;

  vdc_synthesis: any;
  vdc_infos: any;
  vdc_prediction: any;

  vdc_synth: VDCSynthesis;

  selected_org: any;
  selected_vdcs: any;

  selected_vdc: any;

  global_orgs: number;
  global_vdcs: number;
  global_cpu: number;
  global_ram: number;
  global_disk: number;

  dataOrder = ClrDatagridSortOrder.ASC;


  /**
   * 
   */
  constructor(private authenticationService: AccountService, private message_svc: ShareService,
     private json_svc: JsonloaderService) {

    this.cpu_res = VDCResource.CPU;
	  this.ram_res = VDCResource.RAM;
	  this.disk_res = VDCResource.DISK;

    this.selected_vdcs = [];

    this.global_orgs = 0;
    this.global_vdcs = 0;
    this.global_cpu = 0;
    this.global_ram = 0;
    this.global_disk = 0;
  }

  /**
   * 
   */
  ngOnInit(): void {
    
    this.authenticationService.user.subscribe(user => this.currentUser = user);
    this.message_svc.currentMessage.subscribe(message => this.message = message);

    // GET INFOS
    this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.VDC_INFOS).subscribe(
      data => {
        if(data != undefined && data.length > 0) {
          this.vdc_infos = data;
          this.global_orgs = this.vdc_infos.length;
          this.global_vdcs = this.vdc_infos.reduce((sum: any, current: { vdcs: any; }) => sum + current.vdcs, 0);
          this.global_cpu = this.vdc_infos.reduce((sum: number, current: { avgVdcCpuUsed: any; }) => sum + Number(current.avgVdcCpuUsed), 0);
          this.global_ram = this.vdc_infos.reduce((sum: number, current: { avgVdcRamUsed: any; }) => sum + Number(current.avgVdcRamUsed), 0);
          this.global_disk = this.vdc_infos.reduce((sum: number, current: { avgVdcDiskUsed: any; }) => sum + Number(current.avgVdcDiskUsed), 0);

          this.global_cpu = this.formatNumber(String(this.global_cpu), 1000); // in GHz
          this.global_ram = this.formatNumber(String(this.global_ram), 1024); // in GB
          this.global_disk = this.formatNumber(String(this.global_disk), 1024); // in GB


        }
      }
    );

    // GET SYNTHESIS
    this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.VDC_SYNTHESIS).subscribe(
      data => {
        this.vdc_synthesis = data;
      }
    );

    // GET PREDICTIONS
    this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.VDC_PREDICTION).subscribe(
      data => {
        this.vdc_prediction = data;
      }
    );

  }

  /**
   * 
   */
  orgChanged(event: any) {
    if(event) {
      this.selected_vdcs = this.vdc_synthesis.filter(vdc => vdc.organization === event.orgname);
    }
  }

  /**
   * 
   */
  vdcChanged(event: any) {
    this.emptyVDCData();
    if(event) {
      this.vdc_synth = this.getVDCData(this.selected_vdc.uuid);
    }
  }

  /**
   * 
   */
  private emptyVDCData(): void {
    this.vdc_synth = {
      uuid: 'not registered',
      name: 'not registered',
      rpuuid: '',
      organization: '',
      vmquota: 0,
      allocationModel: '',
      avgVdcCpuAllocated: 0,
      avgVdcCpuLimit: 0,
      avgVdcCpuReserved: 0,
      avgVdcCpuResused: 0,
      avgVdcCpuUsed: 0,
      avgVdcRamAllocated: 0,
      avgVdcRamLimit: 0,
      avgVdcRamReserved: 0,
      avgVdcRamResused: 0,
      avgVdcRamUsed: 0,
      avgVdcDiskLimit: 0,
      avgVdcDiskUsed: 0,
    };
  }

  /**
   * 
   */
  private getVDCData(uuid: string): VDCSynthesis {
	  return this.vdc_synthesis.find(vdc => vdc.uuid === uuid);
  }

  /**
   * 
   */
  formatNumber(number: string, div: number): number {
    let value = Number(Number(number) / div).toFixed(2);
    return Number(value);
  }

  /**
   * 
   */
  exportOrgs() {
    let res = ["NAME", "VDCs", "CPU USED (MHz)", "RAM USED (MB)", "STORAGE USED (MB)"].join(',') + '\n';
		res += Object.values<any>(this.vdc_infos).map(org =>
			[org.orgname, org.vdcs, org.avgVdcCpuUsed, org.avgVdcRamUsed, org.avgVdcDiskUsed].join(",")
		).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'vcloud_orgs.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  /**
   * 
   */
  exportVDCs() {
    let res = ["NAME", "ORG NAME","RES.POOL IDENTIFIER", "ALLOCATION MODEL", "VM QUOTA", "CPU USED (MHz)", "CPU LIMIT (MHz)", "RAM USED (MB)", "RAM LIMIT (MB)", "STORAGE USED (MB)", "STORAGE LIMIT (MB)"].join(',') + '\n';
		res += Object.values<any>(this.selected_vdcs).map(vdc =>
			[vdc.name, vdc.organization, vdc.rpuuid, vdc.allocationModel, vdc.vmquota, vdc.avgVdcCpuUsed, vdc.avgVdcCpuLimit, vdc.avgVdcRamUsed, vdc.avgVdcRamLimit, vdc.avgVdcDiskUsed, vdc.avgVdcDiskLimit].join(",")
		).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'vcloud_vdcs.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

}

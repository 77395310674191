<div class="card">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="filter"></clr-icon>
			&nbsp;Filter management
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<button class="btn btn-primary" style="float:left;" (click)="wizardfv.open();initWizard()">
						<clr-icon shape="plus-circle" class="is-solid"></clr-icon>
						CREATE FILTER
					</button>
				</div>
				<div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">select your filter</h5>
							<div class="card-text">
								<div class="clr-control-container">
									<div class="clr-select-wrapper">
										<select id="filter-view" class="clr-select" [(ngModel)]="selected_filter" (ngModelChange)="switchFilter($event)">
											<option *ngFor="let filter of filters">{{filter.name}}</option>
										</select>
									</div>
									<span class="clr-subtext">the list of filters</span>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="editFilter()" *ngIf="selected_filter.name != 'all'
								&& selected_filter.name != 'all_last1D' && selected_filter.name != 'all_last30D' && selected_filter.name != 'all_last60D' 
								&& selected_filter.name != 'all_last180D' && selected_filter.name != 'all_last360D'">
								Edit
							</button>
							<button type="button" class="btn btn-link btn-sm" aria-label="settings" (click)="isDeleteFilter = true" *ngIf="selected_filter.name != 'all'
								&& selected_filter.name != 'all_last1D' && selected_filter.name != 'all_last30D' && selected_filter.name != 'all_last60D' 
                                                                && selected_filter.name != 'all_last180D' && selected_filter.name != 'all_last360D'">
								Delete
							</button>
            					</div>
					</div>
				</div>
				<div class="clr-col-lg-9 clr-col-md-9 clr-col-12">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">information on your filter</h5>
							<div class="card-text">
								<table class="table" *ngIf="isRdy">
  									<thead>
    										<tr>
      											<th>NAME</th>
      											<th>ELEMENT VIEW</th>
      											<th>TIME VIEW</th>
      											<th>ANALYSIS INTERVAL</th>
      											<th>SHARED</th>
    										</tr>
  									</thead>
  									<tbody>
										<td>{{selected_filter.name}}</td>
										<td>{{selected_filter.element_view}}</td>
										<td>{{selected_filter.time_view}}</td>
										<td>{{work_days_str}}</td>
										<td>{{edit_isshared}}</td>
  									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="isEdit">
					<div class="card">
						<div class="card-block">
							<h5 class="card-title">edit filter parameters for {{selected_filter.name}}</h5>
							<div class="card-text">
								<clr-timeline>
									<clr-timeline-step clrState="success">
										<clr-timeline-step-header>{{formatEditDate(this.selected_filter.time)}}</clr-timeline-step-header>
    										<clr-timeline-step-title>CREATE or UPDATE</clr-timeline-step-title>
										<clr-timeline-step-description>Last modification date for the filter {{selected_filter.name}}.</clr-timeline-step-description>
  									</clr-timeline-step>
									<clr-timeline-step clrState="current">
										<clr-timeline-step-header>STEP 1</clr-timeline-step-header>
										<clr-timeline-step-title>SELECTION VIEWS</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<div class="clr-select-wrapper">
													<select id="edit-element-view" class="clr-select" [(ngModel)]="edit_element_view" (ngModelChange)="enableUpdate($event)">
														<option *ngFor="let view of element_views">{{view.FILTER}}</option>
													</select>
												</div>
												<span class="clr-subtext">select the selection view</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>
									<clr-timeline-step clrState="current">
										<clr-timeline-step-header>STEP 2</clr-timeline-step-header>
										<clr-timeline-step-title>TIME VIEWS</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<div class="clr-select-wrapper">
													<select id="edit-time-view" class="clr-select" [(ngModel)]="edit_time_view" (ngModelChange)="enableUpdate($event)">
														<option *ngFor="let view of time_views">{{view.filter}}</option>
													</select>
												</div>
												<span class="clr-subtext">select the time view</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>
									<clr-timeline-step clrState="current">
										<clr-timeline-step-header>STEP 3</clr-timeline-step-header>
										<clr-timeline-step-title>WORK DAYS/HOURS ONLY</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<clr-checkbox-wrapper>
    													<input type="checkbox" clrCheckbox [(ngModel)]="edit_iswd" (ngModelChange)="enableUpdate($event)">
													<label>{{work_days_tpl}}</label>
												</clr-checkbox-wrapper>
												<span class="clr-subtext">select the analysis interval</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>
									<clr-timeline-step clrState="current" *ngIf="currentUser.login == 'root'">
										<clr-timeline-step-header>STEP 4</clr-timeline-step-header>
										<clr-timeline-step-title>Shared filter</clr-timeline-step-title>
										<clr-timeline-step-description>
											<div class="clr-control-container">
												<clr-checkbox-wrapper>
    													<input type="checkbox" clrCheckbox [(ngModel)]="edit_isshared" (ngModelChange)="enableUpdate($event)">
												</clr-checkbox-wrapper>
												<span class="clr-subtext">to share this filter with the other users</span>
											</div>
										</clr-timeline-step-description>
									</clr-timeline-step>
									<clr-timeline-step clrState="{{update_state}}">
										<clr-timeline-step-header>{{now}}</clr-timeline-step-header>
										<clr-timeline-step-title>UPDATE</clr-timeline-step-title>
										<clr-timeline-step-description>{{update_result}}</clr-timeline-step-description>
									</clr-timeline-step>
								</clr-timeline>
							</div>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="updateFilter()" *ngIf="isUpdate">
								Update
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<clr-wizard #wizardFilter
	[clrWizardSize]="'xl'"
	(clrWizardOnPrevious)="goBack()"
	(clrWizardOnCancel)=doReset() 
	(clrWizardOnFinish)="doFinish()">
    <clr-wizard-title>Create a filter</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">Finish</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="model.name == '' || model.name == 'all' || model.name == 'ALL' || filterList(model.name)">
        <ng-template clrPageTitle>The name of the filter</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-control-container" *ngIf="currentUser.login == 'root'">
					<label>shared filter</label>
					<clr-checkbox-wrapper>
    						<input type="checkbox" clrCheckbox [(ngModel)]="edit_isshared">
					</clr-checkbox-wrapper>
					<span class="clr-subtext">to share this filter with the other users</span>
				</div>
				<div class="clr-form-control">
					<div class="clr-input-container" [ngClass]="{'clr-error' :  model.error}">
						<input type="text" class="clr-input" placeholder="enter name ..." [(ngModel)]="model.name" name="name" (ngModelChange)="checkName($event)" required />
						<clr-icon shape="exclamation-circle" class="is-error" size="24" *ngIf="model.error"></clr-icon>
						<clr-control-error *ngIf="model.error">This field cannot set {{model.errorName}}</clr-control-error>
					</div>
					<span class="clr-subtext">the name of new filter</span>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>
    <clr-wizard-page (clrWizardPageOnLoad)="loadElementViews()">
        <ng-template clrPageTitle>Select an element view</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-control-container">
					<div class="clr-select-wrapper">
						<select class="clr-select" [(ngModel)]="edit_element_view">
							<option *ngFor="let view of element_views">{{view.FILTER}}</option>
						</select>
					</div>
					<span class="clr-subtext">element view</span>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>
    <clr-wizard-page (clrWizardPageOnLoad)="loadTimeViews()">
        <ng-template clrPageTitle>Select a time view</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="clr-control-container">
							<div class="clr-select-wrapper">
								<select class="clr-select" [(ngModel)]="edit_time_view">
									<option *ngFor="let view of time_views">{{view.filter}}</option>
								</select>
							</div>
							<span class="clr-subtext">time view</span>
						</div>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
						<div class="clr-control-container">
							<label>WORK DAYS/HOURS ONLY</label>
							<clr-checkbox-wrapper>
    								<input type="checkbox" clrCheckbox [(ngModel)]="edit_iswd">
								<label>{{work_days_tpl}}</label>
							</clr-checkbox-wrapper>
							<span class="clr-subtext">analysis interval</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>
    <!--<clr-wizard-page *ngIf="currentUser.login == 'root'">
        <ng-template clrPageTitle>Is this a shared filter?</ng-template>
	<div class="card">
		<div class="card-block">
			<div class="card-text">
				<div class="clr-control-container">
					<label>shared filter</label>
					<clr-checkbox-wrapper>
    						<input type="checkbox" clrCheckbox [(ngModel)]="edit_isshared">
					</clr-checkbox-wrapper>
					<span class="clr-subtext">to share this filter with the other users</span>
				</div>
			</div>
		</div>
	</div>
    </clr-wizard-page>-->
</clr-wizard>
<clr-modal [(clrModalOpen)]="isDeleteFilter" [clrModalClosable]=false>
    <h3 class="modal-title">Delete filter</h3>
    <div class="modal-body" style="overflow:auto">
        <div style="width:100%;">
            <p>This filter will be permanently deleted !<b></b></p>
            <p style="float:right;margin-right:50px"><b>Are you sure ?</b></p>
        </div>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isDeleteFilter = false">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="removeFilter();isDeleteFilter = false">Delete</button>
    </div>
</clr-modal>

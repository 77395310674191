import { Component, ChangeDetectorRef, OnInit } from '@angular/core';

import { AccountService, JsonloaderService, SettingsService, ShareService } from '@app/services';

import { Json } from '@app/model';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);


@Component({
  selector: 'app-t5',
  templateUrl: './t5.component.html',
  styleUrls: ['./t5.component.css']
})
export class T5Component implements OnInit {

  jsonLoader: Json;

  data_t5: any = [];

  highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;

  isReady: boolean = false;

  private currentUser;


  constructor(
	private json_svc: JsonloaderService,
	private authentication_svc: AccountService,
	private management_svc: SettingsService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.json_svc.currentJson.subscribe(
        json => {
            this.jsonLoader = json;
            if(this.jsonLoader.t5 != null)
                this.data_t5 = this.jsonLoader.t5;
            else {
                this.data_t5 = [];
                this.isReady = false;
            }
        }
    );
	
	this.authentication_svc.user.subscribe(user => this.currentUser = user);
  }

  ngAfterViewInit(): void {
    if(Object.keys(this.data_t5).length > 0)
	    this.buildCard();
  }

  ngAfterViewChecked(): void {
  	this.cd.detectChanges();
  }

  resizeCard(): void {
	$('#dashboard-t5-core').slideToggle();
  }

  closeCard(): void {

	this.management_svc.updateSettings(this.currentUser.login, 'isT5', false).subscribe(
		error => {
			if(error != null)
				console.log(error)
		}
	);
	$('#dashboard-t5').hide();
  }

  buildCard(): void {
	let data_0 = this.data_t5[0];
	let data_1 = this.data_t5[1];
	let data_2 = this.data_t5[2];
	let data_3 = this.data_t5[3];
	let data_4 = this.data_t5[4];
	let data_5 = this.data_t5[5];
	let data_6 = this.data_t5[6];
	let data_7 = this.data_t5[7];
	let data_17 = this.data_t5[17];
	let cat: string[] = ['blue', 'grey', 'green'];

	this.chartOptions = {
		chart: {
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
		},
        credits: {
        	enabled: false,
        },
        exporting: {
        	enabled: false
        },
        title: {
        	text: '',
		},
        legend: {
	    	title: {
                text: 'With different allocation ratio limitation : ',
                style: {
                    font: 'font-size:12px;font-weight:bold;',
                    //color: 'grey',
                },
            },
		},
        xAxis: {
		    visible: false
        },
        yAxis: {
            min: 0,
            title: {
                text: 'VM number'
            }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true
                }
            }
        },
		tooltip: {
            shared: false,
            useHTML: true,
            formatter: function() {
                var popupdata = 'Based on yours settings <b>' + this.y + ' VM</b> can be created<br>&nbsp;<br>';
                    if (this.series.name === "Maximum number based on your average consumption") {
                        popupdata += "Your average VM consumption :<br> <b>" + data_2 + "</b> MHz / <b>" + data_1 + "</b> MB RAM / <b>" + data_3 + "</b> MB DISK";
                    } else {
                        popupdata += "Your allocation template [<b>" + data_17 + "</b>] :<br>  <b>" + data_6 + "</b> VCPU / <b>" + data_5 + "</b> MB RAM / <b>" + data_7 + "</b> MB DISK";
                    }
                return popupdata;
            }
        },
        series: [{
            type: 'column',
            name: '4 VCPU per logical core - VRAM = 100% - VDISK = 100%',
            color: '#046380',
            data: [this.data_t5[4]]
        }, {
            type: 'column',
            name: 'Your ratio : VCPU = ' + [this.data_t5[9]] + ' - VRAM = ' + [this.data_t5[10]] + ' % - VDISK = ' + [this.data_t5[11]] + ' %',
            color: '#5C5C61',
            data: [this.data_t5[8]]
        }, {
            type: 'column',
            name: 'Maximum number based on your average consumption',
            color: '#5EA11A',
             data: [this.data_t5[0]]
        }]
	};

	this.isReady = true;
  }
}
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GraphOnDemandComponent} from "@app/graph-on-demand/graph-on-demand.component";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {ClarityModule} from "@clr/angular";
import {AppRoutingModule} from "@app/app-routing.module";
import {HighchartsChartModule} from "highcharts-angular";
import {DataTablesModule} from "angular-datatables";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {GraphOnDemandGraphComponent} from "@app/graph-on-demand/graph-on-demand-graph/graph-on-demand-graph.component";
import {
  GraphOnDemandComboboxComponent
} from "@app/graph-on-demand/graph-on-demand-combobox/graph-on-demand-combobox.component";


@NgModule({
	declarations: [
	  GraphOnDemandComponent,
      GraphOnDemandGraphComponent,
      GraphOnDemandComboboxComponent
	],
	imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      ClarityModule,
      AppRoutingModule,
      HighchartsChartModule,
      DataTablesModule,
      FontAwesomeModule,
      NgxSliderModule,
	]
})
export class GraphOnDemandModule {
}

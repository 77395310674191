import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[cloud-savingsplan-detail]'
})
export class CloudsavingsplanDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-cluster-distribution]'
})
export class ClusterdistributionDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import { Component, OnInit, Input } from '@angular/core';
import { LcyService } from '@app/services';
import { Observable, Subscription } from 'rxjs';

import { CapaplanComponent } from '../capaplan.component';
import { CapaResource } from '../capaplan.enums';

@Component({
  selector: 'app-capares',
  templateUrl: './capares.component.html',
  styleUrls: ['./capares.component.css']
})
export class CaparesComponent implements OnInit {

  @Input('target') target: CapaResource;

  @Input('infra') infra_data : Observable<void>;
  private infraSubscription: Subscription;

  @Input('simu') simu_data : Observable<void>;
  private simuSubscription: Subscription;

  capaplan_json: any;
  capaplan_simulation_json: any;

  title: string;
  cpu_label: string;
  ram_label: string;
  disk_label: string;
  cpu_legend: string;
  ram_legend: string;
  disk_legend: string;

  // FOR DATA
  private monthNames: string[];
  private currentDate: Date;
 
  ram_unit: string;
  cpu_unit: string;
  disk_unit: string;

  ram_used: number;
  ram_free: number;
  ram_total: number;
  private ram_satdate: Date;
  ram_satdate_color: string;
  ram_satdate_format: string;
  cpu_used: number;
  cpu_free: number;
  cpu_total: number;
  private cpu_satdate: Date;
  cpu_satdate_color: string;
  cpu_satdate_format: string;
  disk_used: number;
  disk_free: number;
  disk_total: number;
  private disk_satdate: Date;
  disk_satdate_color: string;
  disk_satdate_format: string;

  private ram_simudate: Date;
  ram_simudate_color: string;
  ram_simudate_format: string;
  private cpu_simudate: Date;
  cpu_simudate_color: string;
  cpu_simudate_format: string;
  private disk_simudate: Date;
  disk_simudate_color: string;
  disk_simudate_format: string;

  ram_simu_unit: string;
  cpu_simu_unit: string;
  disk_simu_unit: string;

  ram_simu_free: number;
  cpu_simu_free: number;
  disk_simu_free: number;

  /**
   * 
   */
  constructor(private capaplanComponent: CapaplanComponent) { 
    this.monthNames = capaplanComponent.monthNames;
    this.title = "";
    this.ram_label = "";
    this.cpu_label = "";
    this.disk_label = "";
    this.ram_legend = "";
    this.cpu_legend = "";
    this.disk_legend = "";

    this.currentDate = new Date();

    this.ram_used = 0;
    this.ram_free = 0;
    this.ram_total = 0;
    this.ram_satdate = new Date(-1);
    this.cpu_used = 0;
    this.cpu_free = 0;
    this.cpu_total = 0;
    this.cpu_satdate = new Date(-1);
    this.disk_used = 0;
    this.disk_free = 0;
    this.disk_total = 0;
    this.disk_satdate = new Date(-1);

    this.ram_unit = "MB";
    this.cpu_unit = "MHz";
    this.disk_unit = "MB";

    this.ram_simudate = new Date(-1);
    this.cpu_simudate = new Date(-1);
    this.disk_simudate = new Date(-1);

    this.ram_simu_free = 0;
    this.cpu_simu_free = 0;
    this.disk_simu_free = 0;

    this.ram_simu_unit = "MB";
    this.cpu_simu_unit = "MHz";
    this.disk_simu_unit = "MB";

  }

  /**
   * 
   */
  ngOnInit(): void {

    let ram_sattime: number;
    let cpu_sattime: number;
    let disk_sattime: number;
    let ram_simutime: number;
    let cpu_simutime: number;
    let disk_simutime: number;

    // INIT INFRA DATA
    this.infraSubscription = this.infra_data.subscribe(
      data => {

        this.capaplan_json = data;

        switch(this.target) {

          case CapaResource.CAP:
            this.title = "Resource Allocations";
            this.ram_label = "VRAM";
            this.cpu_label = "VCPU";
            this.disk_label = "VDISK";
            this.ram_legend = "Used = Sum of virtual machines memory allocation\nTotal = Sum of physical machines memory capacity * VRAM overcommitment ratio";
            this.cpu_legend = "Used = Sum of virtual machines vCPU allocation\nTotal = Sum of physical machines logical cores * VCPU overcommitment ratio";
            this.disk_legend = "Used = Sum of virtual machines storage allocation\nTotal = Sum of datastores storage capacity * VDISK overcommitment ratio";
            this.cpu_unit = "";
            this.cpu_simu_unit = "";
    
            if(this.capaplan_json) {
              this.ram_used = Number(this.capaplan_json.CAP.CAPLAST.RAMCAP.TOTAL_VM_ALLOCATED_RAM);
              this.ram_free = Number(this.capaplan_json.CAP.CAPLAST.RAMCAP.TOTAL_SERVER_FREE_RAM);
              this.ram_total = Number(this.capaplan_json.CAP.CAPLAST.RAMCAP.TOTAL_SERVER_RAM);
    
              if(this.ram_used > (1024*1024)) {
                this.ram_used = this.ram_used / (1024*1024);
                this.ram_free = this.ram_free / (1024*1024);
                this.ram_total = this.ram_total / (1024*1024);
                this.ram_unit = "TB";
              } else if(this.ram_used > 1024) {
                this.ram_used = this.ram_used / 1024;
                this.ram_free = this.ram_free / 1024;
                this.ram_total = this.ram_total / 1024;
                this.ram_unit = "GB";
              }
              this.ram_used = Math.round((this.ram_used + Number.EPSILON) * 100) / 100;
              this.ram_free = Math.round((this.ram_free + Number.EPSILON) * 100) / 100;
              this.ram_total = Math.round((this.ram_total + Number.EPSILON) * 100) / 100;
    
              ram_sattime = Number(this.capaplan_json.CAP.CAPLAST.RAMCAP.CAPRAM_DATE);
              this.ram_satdate = new Date(ram_sattime);
              if (ram_sattime > 2147483647000) {
                this.ram_satdate = new Date(2147483647000);
              }
    
              this.cpu_used = Number(this.capaplan_json.CAP.CAPLAST.CPUCAP.TOTAL_VM_ALLOCATED_VCPU);
              this.cpu_free = Number(this.capaplan_json.CAP.CAPLAST.CPUCAP.TOTAL_SERVER_FREE_THREAD);
              this.cpu_total = Number(this.capaplan_json.CAP.CAPLAST.CPUCAP.TOTAL_SERVER_THREAD);
              
              cpu_sattime = Number(this.capaplan_json.CAP.CAPLAST.CPUCAP.CAPCPU_DATE);
              this.cpu_satdate = new Date(cpu_sattime);
              if (cpu_sattime > 2147483647000) {
                this.cpu_satdate = new Date(2147483647000);
              }
    
              this.disk_used = Number(this.capaplan_json.CAP.CAPLAST.DISKCAP.TOTAL_VM_PROVISIONNED);
              this.disk_free = Number(this.capaplan_json.CAP.CAPLAST.DISKCAP.TOTAL_DATASTORE_FREE);
              this.disk_total = Number(this.capaplan_json.CAP.CAPLAST.DISKCAP.TOTAL_DATASTORE_CAPACITY);
    
              if(this.disk_used > (1024*1024)) {
                this.disk_used = this.disk_used / (1024*1024);
                this.disk_free = this.disk_free / (1024*1024);
                this.disk_total = this.disk_total / (1024*1024);
                this.disk_unit = "TB";
              } else if(this.disk_used > 1024) {
                this.disk_used = this.disk_used / 1024;
                this.disk_free = this.disk_free / 1024;
                this.disk_total = this.disk_total / 1024;
                this.disk_unit = "GB";
              }
              this.disk_used = Math.round((this.disk_used + Number.EPSILON) * 100) / 100;
              this.disk_free = Math.round((this.disk_free + Number.EPSILON) * 100) / 100;
              this.disk_total = Math.round((this.disk_total + Number.EPSILON) * 100) / 100;
    
              disk_sattime = Number(this.capaplan_json.CAP.CAPLAST.DISKCAP.CAPDISK_DATE);
              this.disk_satdate = new Date(disk_sattime);
              if (disk_sattime > 2147483647000) {
                this.disk_satdate = new Date(2147483647000);
              }
            }
        
          break;
    
          case CapaResource.CONS:
            this.title = "Resource Consumptions";
            this.ram_label = "RAM";
            this.cpu_label = "CPU";
            this.disk_label = "DISK";
            this.ram_legend = "Used = Sum of physical machines memory activity (Memory Consumed)\nTotal = Sum of physical machines memory capacity";
            this.cpu_legend = "Used = Sum of physical machines cpu activity (CPU MHz)\nTotal = Sum of physical machines CPU capacity in MHz (logical cores * freq)";
            this.disk_legend = "Free = Sum of datastores storage freespace\nTotal = Sum of datastores storage capacity";
    
            if(this.capaplan_json) {
              
              this.ram_used = Number(this.capaplan_json.CONS.CONSLAST.RAMCONS.TOTAL_SERVER_CONS_RAM);
              this.ram_free = Number(this.capaplan_json.CONS.CONSLAST.RAMCONS.TOTAL_SERVER_FREE_RAM);
              this.ram_total = Number(this.capaplan_json.CONS.CONSLAST.RAMCONS.TOTAL_SERVER_RAM);
    
              if(this.ram_used > (1024*1024)) {
                this.ram_used = this.ram_used / (1024*1024);
                this.ram_free = this.ram_free / (1024*1024);
                this.ram_total = this.ram_total / (1024*1024);
                this.ram_unit = "TB";
              } else if(this.ram_used > 1024) {
                this.ram_used = this.ram_used / 1024;
                this.ram_free = this.ram_free / 1024;
                this.ram_total = this.ram_total / 1024;
                this.ram_unit = "GB";
              }
              this.ram_used = Math.round((this.ram_used + Number.EPSILON) * 100) / 100;
              this.ram_free = Math.round((this.ram_free + Number.EPSILON) * 100) / 100;
              this.ram_total = Math.round((this.ram_total + Number.EPSILON) * 100) / 100;
    
              ram_sattime = Number(this.capaplan_json.CONS.CONSLAST.RAMCONS.CONSRAM_DATE);
              this.ram_satdate = new Date(ram_sattime);
              if (ram_sattime > 2147483647000) {
                this.ram_satdate = new Date(2147483647000);
              }
    
              this.cpu_used = Number(this.capaplan_json.CONS.CONSLAST.CPUCONSMHZ.TOTAL_SERVER_CONS_MHZ);
              this.cpu_free = Number(this.capaplan_json.CONS.CONSLAST.CPUCONSMHZ.TOTAL_SERVER_FREE_MHZ);
              this.cpu_total = Number(this.capaplan_json.CONS.CONSLAST.CPUCONSMHZ.TOTAL_SERVER_MHZ);
    
              if(this.cpu_used > (1000*1000)) {
                this.cpu_used = this.cpu_used / (1000*1000);
                this.cpu_free = this.cpu_free / (1000*1000);
                this.cpu_total = this.cpu_total / (1000*1000);
                this.cpu_unit = "THz";
              } else if(this.cpu_used > 1000) {
                this.cpu_used = this.cpu_used / 1000;
                this.cpu_free = this.cpu_free / 1000;
                this.cpu_total = this.cpu_total / 1000;
                this.cpu_unit = "GHz";
              }
              this.cpu_used = Math.round((this.cpu_used + Number.EPSILON) * 100) / 100;
              this.cpu_free = Math.round((this.cpu_free + Number.EPSILON) * 100) / 100;
              this.cpu_total = Math.round((this.cpu_total + Number.EPSILON) * 100) / 100;
    
              cpu_sattime = Number(this.capaplan_json.CONS.CONSLAST.CPUCONSMHZ.CONSCPU_DATE);
              this.cpu_satdate = new Date(cpu_sattime);
              if (cpu_sattime > 2147483647000) {
                this.cpu_satdate = new Date(2147483647000);
              }
    
              this.disk_used = Number(this.capaplan_json.CONS.CONSLAST.DISKCONS.TOTAL_DATASTORE_USED);
              this.disk_free = Number(this.capaplan_json.CONS.CONSLAST.DISKCONS.TOTAL_DATASTORE_FREE);
              this.disk_total = Number(this.capaplan_json.CONS.CONSLAST.DISKCONS.TOTAL_DATASTORE_CAPACITY);
              
              if(this.disk_used > (1024*1024)) {
                this.disk_used = this.disk_used / (1024*1024);
                this.disk_free = this.disk_free / (1024*1024);
                this.disk_total = this.disk_total / (1024*1024);
                this.disk_unit = "TB";
              } else if(this.disk_used > 1024) {
                this.disk_used = this.disk_used / 1024;
                this.disk_free = this.disk_free / 1024;
                this.disk_total = this.disk_total / 1024;
                this.disk_unit = "GB";
              }
              this.disk_used = Math.round((this.disk_used + Number.EPSILON) * 100) / 100;
              this.disk_free = Math.round((this.disk_free + Number.EPSILON) * 100) / 100;
              this.disk_total = Math.round((this.disk_total + Number.EPSILON) * 100) / 100;
    
              disk_sattime = Number(this.capaplan_json.CONS.CONSLAST.DISKCONS.CONSDISK_DATE);
              this.disk_satdate = new Date(disk_sattime);
              if (disk_sattime > 2147483647000) {
                this.disk_satdate = new Date(2147483647000);
              }
            }
        
          break;
    
          default:
            console.error("capares.target not set properly !");
          break;
        }
    
        let results =  this.formatDate(this.ram_satdate);
        this.ram_satdate_format = results[0];
        this.ram_satdate_color = results[1];
        results =  this.formatDate(this.cpu_satdate);
        this.cpu_satdate_format = results[0];
        this.cpu_satdate_color = results[1];
        results =  this.formatDate(this.disk_satdate);
        this.disk_satdate_format = results[0];
        this.disk_satdate_color = results[1];
    
      }

    );

    // INIT SIMU DATA
    this.simuSubscription = this.simu_data.subscribe(
      data => {

        this.capaplan_simulation_json = data;

        switch(this.target) {

          case CapaResource.CAP:
            this.title = "Resource Allocations";
            this.ram_label = "VRAM";
            this.cpu_label = "VCPU";
            this.disk_label = "VDISK";
            this.ram_legend = "Used = Sum of virtual machines memory allocation\nTotal = Sum of physical machines memory capacity * VRAM overcommitment ratio";
            this.cpu_legend = "Used = Sum of virtual machines vCPU allocation\nTotal = Sum of physical machines logical cores * VCPU overcommitment ratio";
            this.disk_legend = "Used = Sum of virtual machines storage allocation\nTotal = Sum of datastores storage capacity * VDISK overcommitment ratio";
            this.cpu_unit = "";
            this.cpu_simu_unit = "";
    
            if(this.capaplan_simulation_json) {
              ram_simutime = Number(this.capaplan_simulation_json.CAP.CAPLAST.RAMCAP.CAPRAM_DATE);
              this.ram_simudate = new Date(ram_simutime);
              if (ram_simutime > 2147483647000) {
                this.ram_simudate = new Date(2147483647000);
              }
    
              this.ram_simu_free = Number(this.capaplan_simulation_json.CAP.CAPLAST.RAMCAP.TOTAL_SERVER_FREE_RAM);
              if(this.ram_simu_free > (1024*1024) || this.ram_simu_free < (1024*1024)) {
                this.ram_simu_free = this.ram_simu_free / (1024*1024);
                this.ram_simu_unit = "TB";
              } else if(this.ram_simu_free > 1024 || this.ram_simu_free < 1024) {
                this.ram_simu_free = this.ram_simu_free / 1024;
                this.ram_simu_unit = "GB";
              }
              this.ram_simu_free = Math.round((this.ram_simu_free + Number.EPSILON) * 100) / 100;

    
              cpu_simutime = Number(this.capaplan_simulation_json.CAP.CAPLAST.CPUCAP.CAPCPU_DATE);
              this.cpu_simudate = new Date(cpu_simutime);
              if (cpu_simutime > 2147483647000) {
                this.cpu_simudate = new Date(2147483647000);
              }
    
              this.cpu_simu_free = Number(this.capaplan_simulation_json.CAP.CAPLAST.CPUCAP.TOTAL_SERVER_FREE_THREAD);
    
              disk_simutime = Number(this.capaplan_simulation_json.CAP.CAPLAST.DISKCAP.CAPDISK_DATE);
              this.disk_simudate = new Date(disk_simutime);
              if (disk_simutime > 2147483647000) {
                this.disk_simudate = new Date(2147483647000);
              }
    
              this.disk_simu_free = Number(this.capaplan_simulation_json.CAP.CAPLAST.DISKCAP.TOTAL_DATASTORE_FREE);
              if(this.disk_simu_free > (1024*1024) || this.disk_simu_free < (1024*1024)) {
                this.disk_simu_free = this.disk_simu_free / (1024*1024);
                this.disk_simu_unit = "TB";
              } else if(this.disk_simu_free > 1024 || this.disk_simu_free < 1024) {
                this.disk_simu_free = this.disk_simu_free / 1024;
                this.disk_simu_unit = "GB";
              }
              this.disk_simu_free = Math.round((this.disk_simu_free + Number.EPSILON) * 100) / 100;
    
            }
    
          break;
    
          case CapaResource.CONS:
            this.title = "Resource Consumptions";
            this.ram_label = "RAM";
            this.cpu_label = "CPU";
            this.disk_label = "DISK";
            this.ram_legend = "Used = Sum of physical machines memory activity (Memory Consumed)\nTotal = Sum of physical machines memory capacity";
            this.cpu_legend = "Used = Sum of physical machines cpu activity (CPU MHz)\nTotal = Sum of physical machines CPU capacity in MHz (logical cores * freq)";
            this.disk_legend = "Free = Sum of datastores storage freespace\nTotal = Sum of datastores storage capacity";
    
            if(this.capaplan_simulation_json) {
              ram_simutime = Number(this.capaplan_simulation_json.CONS.CONSLAST.RAMCONS.CONSRAM_DATE);
              this.ram_simudate = new Date(ram_simutime);
              if (ram_simutime > 2147483647000) {
                this.ram_simudate = new Date(2147483647000);
              }
    
              this.ram_simu_free = Number(this.capaplan_simulation_json.CONS.CONSLAST.RAMCONS.TOTAL_SERVER_FREE_RAM);
              if(this.ram_simu_free  > (1024*1024) || this.ram_simu_free  < (1024*1024)) {
                this.ram_simu_free = this.ram_simu_free / (1024*1024);
                this.ram_simu_unit = "TB";
              } else if(this.ram_simu_free  > 1024 || this.ram_simu_free  < 1024) {
                this.ram_simu_free = this.ram_simu_free / 1024;
                this.ram_simu_unit = "GB";
              }
              this.ram_simu_free = Math.round((this.ram_simu_free + Number.EPSILON) * 100) / 100;
    
              cpu_simutime = Number(this.capaplan_simulation_json.CONS.CONSLAST.CPUCONSMHZ.CONSCPU_DATE);
              this.cpu_simudate = new Date(cpu_simutime);
              if (cpu_simutime > 2147483647000) {
                this.cpu_simudate = new Date(2147483647000);
              }
    
              this.cpu_simu_free = Number(this.capaplan_simulation_json.CONS.CONSLAST.CPUCONSMHZ.TOTAL_SERVER_FREE_MHZ);
              if(this.cpu_simu_free > (1000*1000) || this.cpu_simu_free < (1000*1000)) {
                this.cpu_simu_free = this.cpu_simu_free / (1000*1000);
                this.cpu_simu_unit = "THz";
              } else if(this.cpu_simu_free > 1000 || this.cpu_simu_free < 1000) {
                this.cpu_simu_free = this.cpu_simu_free / 1000;
                this.cpu_simu_unit = "GHz";
              }
              this.cpu_simu_free = Math.round((this.cpu_simu_free + Number.EPSILON) * 100) / 100;
    
              disk_simutime = Number(this.capaplan_simulation_json.CONS.CONSLAST.DISKCONS.CONSDISK_DATE);
              this.disk_simudate = new Date(disk_simutime);
              if (disk_simutime > 2147483647000) {
                this.disk_simudate = new Date(2147483647000);
              }
    
              this.disk_simu_free = Number(this.capaplan_simulation_json.CONS.CONSLAST.DISKCONS.TOTAL_DATASTORE_FREE);
              if(this.disk_simu_free > (1024*1024) || this.disk_simu_free < (1024*1024)) {
                this.disk_simu_free = this.disk_simu_free / (1024*1024);
                this.disk_simu_unit = "TB";
              } else if(this.disk_simu_free > 1024 || this.disk_simu_free < 1024) {
                this.disk_simu_free = this.disk_simu_free / 1024;
                this.disk_simu_unit = "GB";
              }
              this.disk_simu_free = Math.round((this.disk_simu_free + Number.EPSILON) * 100) / 100;
    
            }
    
          break;
    
          default:
            console.error("capares.target not set properly !");
          break;
        }
       
        let results =  this.formatSimuResult(this.ram_simudate, this.ram_simu_free, this.ram_simu_unit);
        this.ram_simudate_format = results[0];
        this.ram_simudate_color = results[1];
        results =  this.formatSimuResult(this.cpu_simudate, this.cpu_simu_free, this.cpu_simu_unit);
        this.cpu_simudate_format = results[0];
        this.cpu_simudate_color = results[1];
        results =  this.formatSimuResult(this.disk_simudate, this.disk_simu_free, this.disk_simu_unit);
        this.disk_simudate_format = results[0];
        this.disk_simudate_color = results[1];

      }

    );

  }

  /**
   * 
   */
  private formatSimuResult(date: Date, free: Number, unit: string): string[] {
    let results = [];
    let year = date.getFullYear();
    if (date > this.currentDate) {
      if (year - this.currentDate.getFullYear() > 5) {
          results[0] =  "> 5 years";
          results[1] = "green";
      } else {
          results[0] =  this.monthNames[date.getMonth()] + ' ' + year;
          results[1] = "#F7750A";

      }
  } else {
      if (date.getMilliseconds() == new Date(-1).getMilliseconds()) {
          results[0] = "No Data";
      } else if (date.getMilliseconds() == new Date(-2).getMilliseconds()) {
        results[0] = free + " " + unit;
      } else {
        results[0] = free + " " + unit;
      }
      results[1] = "red";
  }
    return results;
  }

  /**
   * 
   */
  private formatDate(date: Date): string[] {
    let results = [];
    let year = date.getFullYear();
    if (date > this.currentDate) {
        if (year - this.currentDate.getFullYear() > 5) {
            results[0] =  "> 5 years";
            results[1] = "green";
        } else {
            results[0] =  this.monthNames[date.getMonth()] + ' ' + year;
            results[1] = "#F7750A";

        }
    } else {
        if (date.getMilliseconds() == new Date(-1).getMilliseconds()) {
            results[0] = "No Data";
        } else {
            results[0] = "KO";
        }
        results[1] = "red";
    }
    return results;
  }

  /**
   * 
   */
  formatNumber(value: number): string {
    return Intl.NumberFormat().format(value);
  }

}

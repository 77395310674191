<ng-template #templateSelectedResources let-item="item">
    <span [attr.class]="item?.resourceType == 'host' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ item?.resourceType }}</span>
    {{ item?.name }}
</ng-template>

<ng-template #templateDisplayResources let-item='item'>
    <span class="badge" [attr.class]="item?.resourceType == 'host' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ item?.resourceType }}</span>
    {{ item.name }}
</ng-template>

<ng-template #templateDisplayResourcesWhenEmpty>
    <clr-control-helper slot="displayWhenEmpty">Type a resource name</clr-control-helper>
</ng-template>

<ng-template #templateDisplayResourcesWhenErrors>
    <clr-control-error slot="displayWhenError">There was an error</clr-control-error>
</ng-template>

<ng-template #templateSelectedCounters let-item="item">
    <clr-icon shape="line-chart" role="img" aria-label="welcome home" size="12"></clr-icon>
    {{ item?.label }}
    <span class="badge" *ngFor="let ressourceType of item.description.appliesTo" [attr.class]="ressourceType == 'HOST' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ ressourceType | lowercase}}</span>
</ng-template>

<ng-template #templateDisplayCounters let-item='item'>
    {{ item.label }}
    <span class="badge" *ngFor="let ressourceType of item.description.appliesTo" [attr.class]="ressourceType == 'HOST' ? 'badge badge-blue' : 'badge badge-light-blue'">{{ ressourceType | lowercase}}</span>
</ng-template>

<ng-template #templateDisplayCountersWhenEmpty>
    <clr-control-helper slot="displayWhenEmpty">Type a counter name</clr-control-helper>
</ng-template>

<ng-template #templateDisplayCountersWhenErrors>
    <clr-control-error slot="displayWhenError">There was an error</clr-control-error>
</ng-template>

<div class="clr-row" style="width: 100%">
    <div class="clr-col-11">
        <h3 style="margin: 5px;">
            Heatmap viewer
            <span *ngIf="isLoading" style="margin: 0px;">
        <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
      </span>
        </h3>
    </div>
    <div class="clr-col-1">
    <span *ngIf="!isLoading" style="float: right; position: absolute;">
        <button (click)="reloadData()" class="btn {{reloadButtonColorClass}} btn-sm" style="z-index: 1; position: absolute;">reload</button>
    </span>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <div class="card">
            <div class="card-block">
                <div class="card-text">
                    <app-heatmap-combobox
                            [(selectedItems)]="selectedResources1"
                            [itemsGenerator]="fetchVms1"
                            (onChange)="vm1SelectionChanged()"
                            [templateSelectedItems]="templateSelectedResources"
                            [templateDisplayItems]="templateDisplayResources"
                            [templateDisplayWhenEmpty]="templateDisplayResourcesWhenEmpty"
                            [templateDisplayWhenErrors]="templateDisplayResourcesWhenErrors"
                            [itemsField]="'name'"
                            #vm1Combobox
                    >
                    </app-heatmap-combobox>
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
        <div class="card">
            <div class="card-block">
                <div class="card-text">
                    <app-heatmap-combobox
                            [(selectedItems)]="selectedResources2"
                            [itemsGenerator]="fetchVms2"
                            (onChange)="vm2SelectionChanged()"
                            [templateSelectedItems]="templateSelectedResources"
                            [templateDisplayItems]="templateDisplayResources"
                            [templateDisplayWhenEmpty]="templateDisplayResourcesWhenEmpty"
                            [templateDisplayWhenErrors]="templateDisplayResourcesWhenErrors"
                            [itemsField]="'name'"
                            #vm2Combobox
                    >
                    </app-heatmap-combobox>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="clr-row" *ngIf="selectedResources1?.length > 0 && selectedResources2?.length > 0">
    <div class="clr-col-12">
        <div class="card">
            <div class="card-header">
                Traffic between {{ selectedResources1[0].name }} and {{ selectedResources2[0].name }}
            </div>
            <div class="card-block">
                <div class="card-title">
                </div>
                <div class="card-text">
                    <app-heatmap-highcharts-heatmap
                        [onClick]=""
                        [updateEmitter]="resourceSelectionChanged"
                        [clickOnTimeSlotEmitter]="clickOnTimeSlotEmitter"
                        [selectedResources1]="selectedResources1"
                        [selectedResources2]="selectedResources2"
                    ></app-heatmap-highcharts-heatmap>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="clr-row" *ngIf="selectedResources1?.length > 0 && selectedResources2?.length > 0 && flowDetails !== undefined">

    <div class="clr-col-12">
        <div class="card">
            <div class="card-header">
                Details of the between {{ selectedResources1[0].name }} and {{ selectedResources2[0].name }} at {{ displayedTime }}
            </div>
            <div class="card-block">
                <div class="card-title">
                </div>
                <div class="card-text">


                <clr-datagrid>
                    <clr-dg-column [clrDgField]="'src_address'">
                        Source
                        <clr-dg-string-filter [clrDgStringFilter]="sourceIpFilter"></clr-dg-string-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'dst_address'">
                        Destination
                        <clr-dg-string-filter [clrDgStringFilter]="destinationIpFilter"></clr-dg-string-filter>
                    </clr-dg-column>
                    <clr-dg-column [clrDgField]="'port'" [clrDgSortOrder]="descSort">Port</clr-dg-column>
                    <clr-dg-column [clrDgField]="'application_protocol'">Protocol</clr-dg-column>
                    <clr-dg-column [clrDgField]="'exchanged_bytes'" [clrDgSortOrder]="descSort">Total bytes</clr-dg-column>

                    <clr-dg-row *clrDgItems="let flow of flowDetails.metrics" [clrDgItem]="flow">
                        <clr-dg-cell><a routerLink="/god/resource/{{flow.source_address.uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{flow.source_address.name}}</span></a></clr-dg-cell>

                        <clr-dg-cell><a routerLink="/god/resource/{{flow.destination_address.short_uuid}}" [queryParams]="{useResourceCountersFor: 'VM'}" routerLinkActive="active" style="text-decoration: none;"><span class="badge badge-blue">{{flow.destination_address.name}}</span></a></clr-dg-cell>
                        <clr-dg-cell>{{flow.port}}</clr-dg-cell>
                        <clr-dg-cell>{{flow.application_protocol}}</clr-dg-cell>
                        <clr-dg-cell>{{flow.exchanged_bytes / (1024 * 1024) | number: '1.0-0'}} MiB</clr-dg-cell>
                    </clr-dg-row>

                    <clr-dg-footer>
                        <clr-dg-pagination #pagination [clrDgPageSize]="10">
                            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Flows per page</clr-dg-page-size>
                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} flows
                        </clr-dg-pagination>
                    </clr-dg-footer>

                </clr-datagrid>


                </div>
            </div>
        </div>
    </div>

</div>

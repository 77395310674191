<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="thumbs-up"></clr-icon>
			&nbsp;Optimizations
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div *ngIf="!isNoData">
				<div class="card">
					<div class="card-block">
						<div class="clr-row">
							<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
								<clr-icon shape="tree" size="26" style="width:26px; height:26px; color: green;"></clr-icon>
								<span style="font-size: 20px;">
									&nbsp;{{co2ToAvoid.toFixed(0)}}
								</span>
								<div>
									<span class="clr-col-lg-12 clr-col-md-12 clr-col-12">KgCO2Eq to avoid</span>
								</div>
							</div>
							<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
								<span style="font-size: 20px;">{{globalCurrency}} {{cost_reclaim}}</span>
								<div>
									<span class="clr-col-lg-12 clr-col-md-12 clr-col-12">to avoid</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-block">
						<div class="clr-row">
							<div class="clr-col-lg-4 clr-col-md-4 clr-col-4">
								<clr-icon shape="vm" style="color: #FF3333;"></clr-icon>
								&nbsp;<span style="font-size: 20px;">VMs<br>{{reco_meta_data.idle}}</span>
								<div>
									<span class="clr-col-lg-12 clr-col-md-12 clr-col-12">idle</span>
								</div>
							</div>
							<div class="clr-col-lg-4 clr-col-md-4 clr-col-4">
								<clr-icon shape="vm" style="color: #F8E300;"></clr-icon>
								&nbsp;<span style="font-size: 20px;">VMs<br>{{reco_meta_data.oversized}}</span>
								<div>
									<span class="clr-col-lg-12 clr-col-md-12 clr-col-12">oversized</span>
								</div>
							</div>
							<div class="clr-col-lg-4 clr-col-md-4 clr-col-4">
								<clr-icon shape="vm"></clr-icon>
								&nbsp;<span style="font-size: 20px;">VMs<br>{{reco_meta_data.zombie}}</span>
								<div>
									<span class="clr-col-lg-12 clr-col-md-12 clr-col-12">zombies</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="isNoData">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
		</div>
	</div>
</div>
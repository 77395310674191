import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-costtag-vm]'
})
export class CostvmtagDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import { Component, ChangeDetectorRef, OnInit } from '@angular/core';

import { AccountService, JsonloaderService, SettingsService } from '@app/services';

import { Json } from '@app/model';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);


@Component({
  selector: 'app-t2',
  templateUrl: './t2.component.html',
  styleUrls: ['./t2.component.css']
})
export class T2Component implements OnInit {

  jsonLoader: Json;

  data_t2: any  = {};

  private currentUser;

  highcharts: typeof Highcharts = Highcharts;

  chartOptions: any;

  satRes: string[] = [];

  satDate: any  = {
	value: 'not available'
  };

  isReady: boolean = false;


  constructor(
	private json_svc: JsonloaderService,
	private authentication_svc: AccountService,
	private management_svc: SettingsService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.json_svc.currentJson.subscribe(
        json => {
            this.jsonLoader = json;
            if(this.jsonLoader.t2 != null) {
                this.data_t2 = this.jsonLoader.t2;
            } else {
                this.data_t2 = {};
                this.isReady = false;
                this.satDate = {
                    value: 'not available'
                };
            }
        }
    );

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
  }

  ngAfterViewInit(): void {
    if(Object.keys(this.data_t2).length > 0)
        this.buildCard();
  }

  ngAfterViewChecked(): void {
  	this.cd.detectChanges();
  }

  resizeCard(): void {
	$('#dashboard-t2-core').slideToggle();
  }

  closeCard(): void {
	this.management_svc.updateSettings(this.currentUser.login, 'isT2', false).subscribe(
		error => {
			if(error != null)
				console.log(error)
		}
	);
	$('#dashboard-t2').hide();
  }

  buildCard(): void {
    let val_tmp: string[] = [];
    let saturation: string = 'not available';

    let vcpu_data: number[] = [];
    let vram_data: number[] = [];
    let vdisk_data: number[] = [];

    $.each(this.data_t2, function(key, value) {
        if(key == 'VCPU') {
            $.each(value, function(k, v) {
                vcpu_data.push(v);
            });
        } else if(key == 'VRAM') {
            $.each(value, function(k, v) {
                vram_data.push(v);
            });
        } else if(key == 'VDISK') {
            $.each(value, function(k, v) {
                vdisk_data.push(v);
            });
        } else if(key == 'SATDATE') {
            saturation = value;
        } else if(key == 'SATRES') {
	        val_tmp = String(value).split(",");
        }
    });

    this.chartOptions = {
        chart: {
            renderTo: 't2-graph',
            backgroundColor:'rgba(255, 255, 255, 0.0)'
        },
        credits: {
            enabled: false
        },
        time: {
	    useUTC : false
        },
        exporting: {
            enabled: false
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: '%'
            }
	    },
	    series: [
        {
			name: 'VCPU',
			color: '#0B610B',
			type: 'line',
			data: vcpu_data
		},
        	{
			name: 'VRAM',
			color: '#08298A',
			type: 'line',
			data: vram_data
		},
        	{
			name: 'VDISK',
			color: '#000000',
			type: 'line',
			data: vdisk_data
		}
	    ]
    };

    this.isReady = true;
    this.satDate.value = saturation;
    for(var i=0; i < val_tmp.length; i++) {
	    this.satRes.push(val_tmp[i]);
    }
  }


  checkingPoint(chart: Highcharts.Chart) {
    // Checking point coloration
    for(var i = 0; i < this.chartOptions.series.length; i++) {
	    var nbpoints = this.chartOptions.series[i].data.length;
	    if(nbpoints == 3) {
            chart.series[i].data[2].update({
                marker: {
                    enabled: true,
                    fillColor: 'orange',
                    radius: 5,
                    states: {
                        hover: {
                            fillColor: 'orange'
                        }
                    }
                }
            });
	    } else if(nbpoints == 2) {
	        if(this.satRes.includes(this.chartOptions.series[i].name)) {
                chart.series[i].data[1].update({
                    marker: {
                        enabled: true,
                        fillColor: 'red',
                        radius: 5,
                        states: {
                            hover: {
                                fillColor: 'red'
                            }
                        }
                    }
                });
	        }
	    }
    }
  }

}
import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import * as Highcharts from 'highcharts';
import {NetscopeService} from "@app/services";


function formatVolumetry(value) {
  let displayedValue = value;
  let displayedUnit = "B"

  // @ts-ignore
  if (value > 0) {
    let units = [[Math.pow(1000, 4), "TB"], [Math.pow(1000, 3), "GB"], [Math.pow(1000, 2), "MB"], [Math.pow(1000, 1), "KB"], [Math.pow(1000, 0), "B"]]
    // Find right units
    // @ts-ignore
    let selectedUnit = units.filter((u) => u[0] <= value).reduce((prev, curr) => prev[0] > curr[0] ? prev : curr);

    // @ts-ignore
    displayedValue = Math.round((1.0 * value) / selectedUnit[0]);
    // @ts-ignore
    displayedUnit = selectedUnit[1];
  }

  return `${displayedValue}${displayedUnit}`;
}


@Component({
  selector: 'app-heatmap-highcharts-heatmap',
  templateUrl: './heatmap-highcharts-heatmap.component.html',
  styleUrls: ['./heatmap-highcharts-heatmap.component.css']
})
export class HeatmapHighchartsHeatmapComponent implements OnInit {


  @Input()
  selectedResources1;
  @Input()
  selectedResources2;
  @Input()
  onClick;

  @Input()
  updateEmitter: EventEmitter<any>;
  @Input()
  clickOnTimeSlotEmitter: EventEmitter<any>;

  @Input()
  maxHeight = 400;
  @Input()
  legendMaxHeight = 280;
  @Input()
  doubleClickSelectDay = false;
  @Input()
  selectLastDay = false;

  firstLoad = true;
  selectionPosition = {
    plotSelection: {
      type: "none", // can be "none", "hourly", "daily",
      x: -1,
      y: -1
    },
    rectanglePositions: {
      x: 0,
      y: 0,
      height: 0,
      width: 0
    }
  }

  Highcharts: typeof Highcharts = Highcharts;

  // Overall network traffic chart's data
  overallTrafficChartOptions: Highcharts.Options = {
    // @ts-ignore
    series: {
    },
    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: this.legendMaxHeight,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            halo: null
          }
        }
      }
    },
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginRight: 100,
      marginLeft: 50,
      marginBottom: 110,
      plotBorderWidth: 1
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      categories: [],
      title: null,
    },
    title: undefined,
    credits: {
      enabled: false
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, 'rgb(48, 0, 62)'],
        [0.5, 'rgb(30, 127, 122)'],
        [1, 'rgb(235, 213, 28)']
      ],
      reversed: false,
      labels: {
        formatter: function() {
          return formatVolumetry(this.value);
        }
      }
    }
  };
  updateHeatmapChartFlag = false;
  heatmapChartRef;
  chartCheckInterval;
  colorSelectedCellCheckInterval;

  ensureColorAxisIsInvertedInterval;

  isLoading = false;

  constructor(private netscopeService: NetscopeService) { }

  ngOnInit(): void {
    this.updateEmitter.subscribe((e) => {
      this.displayHeatmap();
    })
    this.displayHeatmap();

    this.chartCheckInterval = setInterval(() => {
      if (this.heatmapChartRef !== undefined && this.heatmapChartRef !== null) {
        try {
          this.heatmapChartRef.reflow();
          if (this.selectionPosition.plotSelection.type !== "none") {
            this.refreshRectanglePosition();
            this.drawRedRectangle();
          }
        } catch (e) {
          console.log(e);
          clearInterval(this.chartCheckInterval);
        }
      }
    }, 250);

    this.colorSelectedCellCheckInterval = setInterval(() => {
      // Select resource if needed
      if (this.selectLastDay && this.firstLoad) {
        if (this.heatmapChartRef.series.length > 0) {
          let maxX = Math.max(...this.heatmapChartRef.series[0].data.map((d) => d.x));
          this.markDayAsSelected(maxX);
          this.firstLoad = false;
        }
      }
    }, 100);
  }

  ngOnDestroy = () => {
    if (this.chartCheckInterval) {
      clearInterval(this.chartCheckInterval);
    }
  }

  callbackHeatmapChartRef = (ref) => {
    this.heatmapChartRef = ref;
  }

  markDayAsSelected = (x) => {
    this.selectionPosition.plotSelection = {
      type: "daily",
      x: x,
      y: -1
    }
    this.refreshRectanglePositionDaily();
    this.drawRedRectangle();
  }

  refreshRectanglePositionDaily = () => {
    let matchingElements = this.heatmapChartRef.series[0].data.filter((d) => d.x === this.selectionPosition.plotSelection.x);
    let xPosition = Math.min(...matchingElements
      .map((d) => d.shapeArgs.x)
    );
    let yPosition = Math.min(...matchingElements
      .map((d) => d.shapeArgs.y)
    );
    let yPositionBottom = Math.max(...matchingElements
      .map((d) => d.shapeArgs.y)
    );
    let width = Math.max(...matchingElements
      .map((d) => d.shapeArgs.width)
    );
    let translateX =  Math.min(...matchingElements
      .map((d) => d.graphic.parentGroup.translateX));
    let translateY =  Math.min(...matchingElements
      .map((d) => d.graphic.parentGroup.translateY));
    this.selectionPosition.plotSelection.type = "daily";
    this.selectionPosition.rectanglePositions.x = translateX + xPosition;
    this.selectionPosition.rectanglePositions.y = translateY;
    this.selectionPosition.rectanglePositions.height = this.heatmapChartRef.plotHeight;
    this.selectionPosition.rectanglePositions.width = width;
  }

  markHourAsSelected = (x, y) => {
    this.selectionPosition.plotSelection = {
      type: "hourly",
      x: x,
      y: y
    }
    this.refreshRectanglePositionHourly();
    this.drawRedRectangle();
  }

  refreshRectanglePositionHourly = () => {
    let matchingElements = this.heatmapChartRef.series[0].data
        .filter((d) => d.x === this.selectionPosition.plotSelection.x && d.y === this.selectionPosition.plotSelection.y);
    let xPosition = Math.min(...matchingElements
      .map((d) => d.shapeArgs.x)
    );
    let yPosition = Math.min(...matchingElements
      .map((d) => d.shapeArgs.y)
    );
    let yPositionBottom = Math.max(...matchingElements
      .map((d) => d.shapeArgs.y)
    );
    let width = Math.max(...matchingElements
      .map((d) => d.shapeArgs.width)
    );
    let height = Math.max(...matchingElements
      .map((d) => d.shapeArgs.height)
    );
    let translateX =  Math.min(...matchingElements
      .map((d) => d.graphic.parentGroup.translateX)
    );
    let translateY =  Math.min(...matchingElements
      .map((d) => d.graphic.parentGroup.translateY)
    );
    this.selectionPosition.plotSelection.type = "hourly";
    this.selectionPosition.rectanglePositions.x = translateX + xPosition;
    this.selectionPosition.rectanglePositions.y = translateY + yPosition;
    this.selectionPosition.rectanglePositions.height = height;
    this.selectionPosition.rectanglePositions.width = width;

    this.drawRedRectangle();
  }

  refreshRectanglePosition = () => {
    if (this.selectionPosition.plotSelection.type === "daily") {
      this.refreshRectanglePositionDaily();
    } else {
      this.refreshRectanglePositionHourly();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.selectionPosition.plotSelection.type !== "none") {
      this.refreshRectanglePosition();
      this.drawRedRectangle();
    }
  }

  drawRedRectangle = () => {
    if (this.selectionPosition.plotSelection.type == "hourly" || this.selectionPosition.plotSelection.type == "daily") {
      if (document.getElementById("highcharts-selection-marker") === null) {
        // @ts-ignore
        for(let legendElement of document.getElementsByClassName("highcharts-exporting-group")) {
          let rectangleGraphic = document.createElement("rect");
          rectangleGraphic.id = "highcharts-selection-marker";
          legendElement.after(rectangleGraphic)
        }
      }
      // @ts-ignore
      let rectangleGraphic: SVGRect = document.getElementById("highcharts-selection-marker");

      if (rectangleGraphic !== null) {
        // @ts-ignore
        rectangleGraphic.outerHTML = `
          <rect
            id="highcharts-selection-marker"
            x="${this.selectionPosition.rectanglePositions.x}"
            y="${this.selectionPosition.rectanglePositions.y}"
            height="${this.selectionPosition.rectanglePositions.height}"
            width="${this.selectionPosition.rectanglePositions.width}"
            stroke="red"
            stroke-width="2"
            fill="transparent"
            style="pointer-events: none;"
          >
          </rect>`;
      }
    }
  }

  displayHeatmap = () => {
    this.isLoading = true;

    let srcUuid = undefined;
    let dstUuid = undefined;

    for (let resource of this.selectedResources1) {
      srcUuid = resource.uuid;
    }

    for (let resource of this.selectedResources2) {
      dstUuid = resource.uuid;
    }

    const heatmapFlowsDataObservable = this.netscopeService.getHeatmapFlowsData(srcUuid, dstUuid);

    heatmapFlowsDataObservable.subscribe((heatmapFlowsData) => {
      // @ts-ignore
      let data = heatmapFlowsData.alternate_data;
      // @ts-ignore
      let startDate = heatmapFlowsData.start_at;

      // @ts-ignore
      this.overallTrafficChartOptions.series = [];

      let that = this;
      // @ts-ignore
      this.overallTrafficChartOptions.series.push({
        name: 'Traffic',
        turboThreshold: 0, // To prevent to be black when the number of cells is large
        data: data,
        cursor: 'pointer',
        className: 'heatmapTooltip',
        useHTML: true,
        events: {
          mouseOver: function (event) {},
          mouseOut: function (event) {},
          click: function (event) {
            that.firstLoad = false;

            let isAlreadySelected =
                that.selectionPosition.plotSelection.type === "hourly"
                && that.selectionPosition.plotSelection.x === event.point.x
                && that.selectionPosition.plotSelection.y === event.point.y

            // @ts-ignore
            let currentDate = new Date(startDate * 1000); // Create a new datetime corresponding of the start date of the heatmap
            currentDate.setDate(currentDate.getDate() + event.point.x); // Add the days (x) so that the datetime is on the correct column
            currentDate.setHours(event.point.y); // Add the hours (y) so that the datetime is on the correct row
            let epoch = currentDate.getTime() / 1000.0; // Transform the datetime to epoch
            console.log('(2) epoch: '+epoch);

            if (isAlreadySelected && that.doubleClickSelectDay) {
              that.markDayAsSelected(event.point.x);
              let displayed_label = `${xCategories[event.point.x]}0`;
              let dayMidnightAsDatetime = new Date();
              dayMidnightAsDatetime.setTime(currentDate.getTime()); // Create a new date corresponding to the datetime that have been clicked (with the hour)
              dayMidnightAsDatetime.setHours(0); // Set date to midnight (remove hours)
              dayMidnightAsDatetime.setMinutes(0); // Set date to midnight (remove minutes)
              dayMidnightAsDatetime.setSeconds(0); // Set date to midnight (remove seconds)
              dayMidnightAsDatetime.setMilliseconds(0); // Set date to midnight (remove milliseconds)
              // @ts-ignore
              let dayEpoch = dayMidnightAsDatetime.getTime() / 1000.0;
              that.clickOnTimeSlotEmitter.emit([dayEpoch, displayed_label, "daily"]);
            } else {
              that.markHourAsSelected(event.point.x, event.point.y);
              let displayed_label = `${xCategories[event.point.x]} at ${event.point.y}:00`;
              that.clickOnTimeSlotEmitter.emit([epoch, displayed_label, "hourly"]);
            }
          }
        }
      });

      // @ts-ignore
      let xCategories = heatmapFlowsData.x
          .map((x) => new Date(x * 1000))
          .map((d) => d.toLocaleDateString());

      // @ts-ignore
      this.overallTrafficChartOptions.tooltip = {
        formatter: function (tooltip) {
          let defaultFormatResult = tooltip.defaultFormatter.call(this, tooltip);
          // @ts-ignore
          let formatedValue = formatVolumetry(this.point.value);

          // Override second line:
          defaultFormatResult[1] = `${xCategories[this.point.x]} at ${this.point.y}:00 : ${formatedValue}`;

          return defaultFormatResult
        }
      };

      // @ts-ignore
      this.overallTrafficChartOptions.xAxis = {
        type: 'datetime',
        categories: xCategories,
        showLastLabel: true,
        title: {
          text: "Date"
        },
        labels: {
          useHTML: true,
          formatter: (e) => {
            let dayDateLabel = e.value;
            // @ts-ignore
            let dayEpoch = heatmapFlowsData.x[e.pos];
            return `<span>${e.value}</span>`;
          }
        }
      };

      this.overallTrafficChartOptions.yAxis = {
        title: {
          text: null
        },
        labels: {
          format: '{value}:00'
        },
        minPadding: 0,
        maxPadding: 0,
        startOnTick: true,
        endOnTick: true,
        tickPositions: [0, 6, 12, 18, 23],
        tickWidth: 1,
        reversed: true,
        min: 0,
        max: 23,
      };

      // @ts-ignore
      this.overallTrafficChartOptions.colorAxis.reversed = false;
      this.updateHeatmapChartFlag = true;

      if (this.ensureColorAxisIsInvertedInterval !== undefined) {
        console.log("Disabling the previous colorAxis check");
        clearInterval(this.ensureColorAxisIsInvertedInterval);
      }

      // @ts-ignore
      this.overallTrafficChartOptions.colorAxis.reversed = false;

      this.ensureColorAxisIsInvertedInterval = setInterval(() => {
        try {
          this.heatmapChartRef.axes.filter((a) => a.coll === "colorAxis").map((colorAxis) => {
            if (colorAxis.reversed) {
              // @ts-ignore
              this.overallTrafficChartOptions.colorAxis.reversed = false;
              this.updateHeatmapChartFlag = true;
            }
          })
        } catch (e) {
          console.log("checking of colorAxis triggered an exception: I am disabling the check");
          clearInterval(this.ensureColorAxisIsInvertedInterval);
        }

      }, 200);

      this.isLoading = false;
    });
  }
}

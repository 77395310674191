import { Component, ChangeDetectorRef, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { ClrWizard } from "@clr/angular";

import * as moment from 'moment';

import { AccountService, AuditService, JsonloaderService, ShareService } from '@app/services';

import { Alert, AlertCatalog, AlertToRecord, convertedAlert, Json, Message, UpdateAlert, User } from '@app/model';

import { alertConvApiToIhm, alertConvIhmToApi } from '../../../assets/js/tools.js';


@Component({
  selector: 'app-alertingalert',
  templateUrl: './alertingalert.component.html',
  styleUrls: ['./alertingalert.component.css']
})
export class AlertingalertComponent implements OnInit {

  jsonLoader: Json;

  message: Message;

  @ViewChild("wizardAlert") wizardav: ClrWizard;

  alerts: Alert[] = [];

  selected_alert: Alert;

  selected_alert_type: string = 'real time';

  converted_alert: convertedAlert;

  alert_state: string = 'ENABLED';

  enable_alert: boolean = true;

  isRdy: boolean = false;

  wizardAlertTypeRt: boolean = true;

  counter_type: string = 'real time meter';

  selected_counter: string;

  alerts_catalog: AlertCatalog[] = [];

  counters: string[] = [];

  frequencies: string[] = [];

  data_items: any;

  items: string[] = [];

  wizard_catalog: AlertCatalog;

  wizard_model: any;

  wizard_th_format: string = 'Beyond';

  wizard_freq_auth: boolean = false;

  isEdit: boolean = false;

  edit_model: any;

  now: any;

  isUpdate: boolean = false;

  update_states: any = {
        th1: false,
        th2: false,
        freq: false,
        item: false
  };

  update_state: string = 'error';

  update_result: string = 'changes have not been applied.';

  currentUser: User;
  
  
  constructor(
	private json_svc: JsonloaderService,
	private authentication_svc: AccountService,
        private message_svc: ShareService,
        private audit_svc: AuditService,
        private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.now = moment().format('MMMM Do YYYY');
	
	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
        this.data_items = this.jsonLoader.dcInfraMin;

        this.message_svc.currentMessage.subscribe(message => this.message = message);

        this.wizard_model = {
                error: false,
                errorName: '',
                name: 'cpu_usage',
                counter: 'CPU Usage ( %)',
                th1: 70,
                th2: 90,
                freq: 'since 1 minute',
                item: '---ALL VM---'
        };

        this.wizard_catalog = {
                id: 1,
                name: 'CPU Usage ( %)',
                counter: 'cpuusage',
                granularity: 'min',
                threshold_1: 70,
                threshold_2: 90,
                unit: '%',
                frequency: 5,
                definition: '% means the amount of CPU actively being used.',
                type: 'rt',
                base: 'consumption'
        };
  }

  ngAfterViewInit(): void {
        this.getAlerts();
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  switchAlert(val: string): void {

        this.isEdit = false;
        this.releaseUpdate();
        if(this.alerts.length > 0 && val != "")
                this.loadAlert(this.getAlert(val));
  }

  // WIZARD PART
  initWizard(): void {

        this.wizardav.reset();
        this.isEdit = false;
        this.releaseUpdate();
        this.wizard_model.error = false;
        this.wizard_model.errorName = '',
        this.wizard_model.name = '';
        this.wizardAlertTypeRt = true;
        this.initWizardModel(true);
        this.audit_svc.getAlertCatalog().pipe(first()).subscribe(
                data => {
                        this.alerts_catalog = data;
                },
                error => {
                        if(error != null)
                                console.log(error)
                }
        );
  }

  initWizardModel(isRt: boolean): void {

        if(isRt) {
                this.wizard_catalog.unit = '%';
                this.wizard_catalog.threshold_1 = 70;
                this.wizard_catalog.threshold_2 = 90;

                this.wizard_model.counter = 'CPU Usage ( %)';
                this.wizard_model.th1 = 70;
                this.wizard_model.th2 = 90;
                this.wizard_model.freq = 'since 1 minute';
                this.wizard_model.item = '---ALL VM---';
        } else {
                this.wizard_catalog.unit = 'remaining vCPU';
                this.wizard_catalog.threshold_1 = 50;
                this.wizard_catalog.threshold_2 = 10;
                this.wizard_catalog.definition = 'Remaining vCPU means the number of vCPU that can still be used.';

                this.wizard_model.counter = 'Free vCPU resource';
                this.wizard_model.th1 = 50;
                this.wizard_model.th2 = 10;
                this.wizard_model.freq = 'since 1 day';
                this.wizard_model.item = 'all';
        }
  }

  checkName(val: string): void {

        if(this.filterList(val)) {
                this.wizard_model.error = true;
                this.wizard_model.errorName = 'to ' + val + ' (alert already exists)';
        } else if(val.length == 0) {
                this.wizard_model.error = true;
                this.wizard_model.errorName = 'empty';
        } else if(val.length > 0) {
                this.wizard_model.error = false;
        }
  }

  filterList(name: string): boolean {

        let isInFilter: boolean = false;
        for(var i in this.alerts) {
                if(this.alerts[i].notified_name == name) {
                        isInFilter = true;
                        break;
                }
        }

        return isInFilter;
  }

  loadCounters(): void {

        this.counters = [];
        if(this.wizardAlertTypeRt) {
                this.counter_type = 'real time meter';
                this.wizard_th_format = 'Beyond';
                for(let i = 0; i < this.alerts_catalog.length; i++) {
                        if(this.alerts_catalog[i].type == "rt")
                                this.counters.push(this.alerts_catalog[i].name);

                }
        } else {
                this.counter_type = 'filter';
                this.wizard_th_format = 'Below';
                for(let i = 0; i < this.alerts_catalog.length; i++) {
                        if(this.alerts_catalog[i].type == "ft" && this.alerts_catalog[i].base == "allocation")
                                this.counters.push(this.alerts_catalog[i].name);

                }
        }
  }

  loadFrequencies(): void {

        this.frequencies = [];

        if(this.wizard_model.counter != "Saturation date in vCPU" &&
            this.wizard_model.counter != "Saturation date in vRAM" &&
            this.wizard_model.counter != "Saturation date in vDISK" &&
            this.wizard_model.counter != "VM Provisioning" &&
            this.wizard_model.counter != "Saturation date in CPU" &&
            this.wizard_model.counter != "Saturation date in DISK" &&
            this.wizard_model.counter != "Saturation date in RAM" &&
            this.wizard_model.counter != "VM remaining")
                this.wizard_freq_auth = true;
        else
                this.wizard_freq_auth = false;

        if(this.wizardAlertTypeRt) {
                this.frequencies.push('since 1 minute');
                for(var i = 2; i < 60; i++) {
                        this.frequencies.push('since ' + i + ' minutes');
                }
        } else {
                this.frequencies.push('since 1 day');
                if(this.wizard_freq_auth) {
                        for(var i = 2; i < 31; i++) {
                                this.frequencies.push('since ' + i + ' days');
                        }
                }
        }
  }

  loadItems(): void {

        this.items = [];
        if(this.wizardAlertTypeRt) {
                this.items.push('---ALL VM---');
                this.items.push('---ALL ESX---');
                for(var i=0; i < this.data_items.length; i++) {
                        if(this.data_items[i].type == "SERVER" || this.data_items[i].type == "VM")
                                this.items.push(this.data_items[i].name);
                }
        } else {
                for(var i=0; i < this.message.filterList.length; i++) {
                        this.items.push(this.message.filterList[i].name);
                }
        }
  }

  loadFilterCounters(event: any): void {

        this.counters = [];
        switch(event.target.value) {
                case "allocation":
                        this.wizard_catalog.unit = 'remaining vCPU';
                        this.wizard_catalog.threshold_1 = 50;
                        this.wizard_catalog.threshold_2 = 10;
                        this.wizard_catalog.definition = 'Remaining vCPU means the number of vCPU that can still be used.';
                        this.wizard_th_format = 'Below';
                        this.wizard_model.counter = 'Free vCPU resource';
                        this.wizard_model.th1 = 50;
                        this.wizard_model.th2 = 10;
                        this.wizard_model.freq = 'since 1 day';
                        this.wizard_model.item = 'all';
                        for(let i = 0; i < this.alerts_catalog.length; i++) {
                                if(this.alerts_catalog[i].type == "ft" && this.alerts_catalog[i].base == "allocation")
                                        this.counters.push(this.alerts_catalog[i].name);
                        }
			break;
		case "behavior":
                        this.wizard_catalog.unit = 'BUSY VM';
                        this.wizard_catalog.threshold_1 = 1;
                        this.wizard_catalog.threshold_2 = 5;
                        this.wizard_catalog.definition = 'BUSY VM means the number of VM with BUSY behavior.';
                        this.wizard_th_format = 'Beyond';
                        this.wizard_model.counter = 'BUSY Behavior';
                        this.wizard_model.th1 = 1;
                        this.wizard_model.th2 = 5;
                        this.wizard_model.freq = 'since 1 day';
                        this.wizard_model.item = 'all';
                        for(let i = 0; i < this.alerts_catalog.length; i++) {
                                if(this.alerts_catalog[i].type == "ft" && this.alerts_catalog[i].base == "behavior")
                                        this.counters.push(this.alerts_catalog[i].name);
                        }
			break;
		case "consumption":
                        this.wizard_catalog.unit = 'GB of disk remaining';
                        this.wizard_catalog.threshold_1 = 50;
                        this.wizard_catalog.threshold_2 = 10;
                        this.wizard_catalog.definition = 'Remaining GB means the amount of DISK that can still be used.';
                        this.wizard_th_format = 'Below';
                        this.wizard_model.counter = 'DISK remaining in GB';
                        this.wizard_model.th1 = 50;
                        this.wizard_model.th2 = 10;
                        this.wizard_model.freq = 'since 1 day';
                        this.wizard_model.item = 'all';
                        for(let i = 0; i < this.alerts_catalog.length; i++) {
                                if(this.alerts_catalog[i].type == "ft" && this.alerts_catalog[i].base == "consumption")
                                        this.counters.push(this.alerts_catalog[i].name);
                        }
                        break;
                default:
                        break;
        }
  }

  updateWizardThreshold(event: string): void {

        this.wizard_catalog = this.getAlertFromCatalog(event);
        this.wizard_model.th1 = this.wizard_catalog.threshold_1;
        this.wizard_model.th2 = this.wizard_catalog.threshold_2;
        if(this.wizard_catalog.threshold_1 >= this.wizard_catalog.threshold_2)
                this.wizard_th_format = 'Below';
        else
                this.wizard_th_format = 'Beyond';
  }

  updateWizardComment(): void {

        this.wizard_catalog.threshold_1 = this.wizard_model.th1;
        this.wizard_catalog.threshold_2 = this.wizard_model.th2;
  }

  doFinish(isTest: boolean, model_test: any): Alert {

        if(isTest)
                this.wizard_model = model_test;

        let user = this.currentUser.login;
        let name = this.wizard_model.counter;
        let notified_name = this.wizard_model.name;

        // FROM ALERT_FORMAT FOR IDENTIFIER, THRESHOLDS, FREQUENCY, DEFINITION
        let data_to_convert = {
                item: this.wizard_model.item,
                counter: this.wizard_model.counter,
                th1: this.wizard_model.th1,
                th2: this.wizard_model.th2,
                freq: this.wizard_model.freq
        };
	let alert_format: AlertToRecord = alertConvIhmToApi(data_to_convert);

	// FROM CATALOG FOR COUNTER, GRANULARITY
        let counter: string = '';
	let granularity: string = '';
	try {
		const alert_cat: AlertCatalog = this.getAlertFromCatalog(this.wizard_model.counter);
		counter = alert_cat.counter;
		granularity = alert_cat.granularity;
	} catch(e) {
		console.log('Fail to load alert fom catalog ' + e);
	} 

	// FROM DATA_ITEMS FOR TYPE
        let type: string = 'filter';
        let identifier: string = alert_format.identifier;
        if(this.wizardAlertTypeRt) {
                if(this.wizard_model.item == "---ALL VM---") {
                        type = 'vm';
                } else if(this.wizard_model.item == "---ALL ESX---") {
                        type = 'server';
		} else {
			try {
				const vm: any = this.getVmFromName(this.wizard_model.item);
				type = vm.type.toString().toLowerCase();
				identifier = vm.uuid;
			} catch(e) {
				console.log('Fail to load VM ' + e);
			}
                }
        } else {
                type = 'filter';
        }

	// RECORD
        let alert_rec: Alert = {
                id: 0, // MANAGE BY API
                timeago: moment().unix()*1000,
                user: user,
                uuid: '', // MANAGE BY API
                name: name,
                notified_name: notified_name,
                type: type,
                counter: counter,
                granularity: granularity,
                state: 'enable',
                identifier: identifier,
                threshold_1: alert_format.threshold_1,
                threshold_2: alert_format.threshold_2,
                frequency: alert_format.frequency,
                definition: alert_format.definition,
                base: alert_format.base
	};
	if(!isTest)
                this.recordAlert(alert_rec);

        return alert_rec;
  }

  recordAlert(alert: Alert): void {

        this.audit_svc.addAlert(alert).pipe(first()).subscribe(
                success => {
                        this.getAlerts();
			this.audit_svc.createTrigger().subscribe(
                		error => {
                        		console.log(error);
				}
			);
			this.audit_svc.discoverSnmp(alert.user).subscribe(
                		error => {
                        		console.log(error);
				}
			);
                },
                error => {
                        if(error != null)
                                console.log(error)
                }
        );
  }

  doReset(): void {

        this.initWizard();
        this.wizardav.reset();
  }

  goBack(): void {
  }

  // REMOVE PART
  removeAlert(): void {

        this.audit_svc.delAlert(this.selected_alert.uuid).pipe(first()).subscribe(
                success => {
                        this.getAlerts();
                },
                error => {
                        if(error != null)
                                console.log(error)
                }
        );
  }

  // ENABLE/DISABLE PART
  updateState(val: boolean): void {

        let state: string = '';
        if(val) {
                this.alert_state = 'ENABLED';
                state = 'enable';
        } else {
                this.alert_state = 'DISABLED';
                state = 'disable';
        }

        this.audit_svc.updateAlertState(this.selected_alert.uuid, state).pipe(first()).subscribe(
                success => {
                        let current_alert: Alert = this.getAlertFromUuid(this.selected_alert.uuid);
                        current_alert.state = state;
			this.audit_svc.discoverSnmp(this.selected_alert.user).subscribe(
                		error => {
                        		console.log(error);
				}
			);
                },
                error => {
                        if(error != null)
                                console.log(error)
                }
        );
  }

  // EDIT PART
  editAlert(): void {
        this.isEdit = true;
  }

  enableUpdateTh1(val: number): void {

        if(this.converted_alert.th1 != val) {
                this.isUpdate = true;
                this.update_states.th1 = true;
        } else {
                this.update_states.th1 = false;
                if(this.update_states.th2 == false && this.update_states.freq == false && this.update_states.item == false)
                        this.isUpdate = false;
        }
  }

  enableUpdateTh2(val: number): void {

        if(this.converted_alert.th2 != val) {
                this.isUpdate = true;
                this.update_states.th2 = true;
        } else {
                this.update_states.th2 = false;
                if(this.update_states.th1 == false && this.update_states.freq == false && this.update_states.item == false)
                        this.isUpdate = false;
        }
  }

  enableUpdateFreq(val: string): void {

        if(this.converted_alert.freq != val) {
                this.isUpdate = true;
                this.update_states.freq = true;
        } else {
                this.update_states.freq = false;
                if(this.update_states.th1 == false && this.update_states.th2 == false && this.update_states.item == false)
                        this.isUpdate = false;
        }
  }

  enableUpdateItem(val: string): void {

        if(this.converted_alert.itemname != val) {
                this.isUpdate = true;
                this.update_states.item = true;
        } else {
                this.update_states.item = false;
                if(this.update_states.th1 == false && this.update_states.th2 == false && this.update_states.freq == false)
                        this.isUpdate = false;
        }
  }

  formatEditDate(date: any): any {
        return moment(date).format('MMMM Do YYYY');
  }

  updateAlert(): void {

        let data_to_convert = {
                item: this.edit_model.item,
                counter: this.selected_alert.name,
                th1: this.edit_model.th1,
                th2: this.edit_model.th2,
                freq: this.edit_model.freq
        };
	let alert_format: AlertToRecord = alertConvIhmToApi(data_to_convert);
        let type: string = alert_format.type;
        let identifier: string = this.edit_model.item;
        if(this.edit_model.item == "---ALL VM---") {
		type = 'vm';
		identifier = 'all_vm'
        } else if(this.edit_model.item == "---ALL ESX---") {
                type = 'server';
		identifier = 'all_esx'
        } else {
		try {
			const element: any = this.getVmFromName(this.edit_model.item);
			type = element.type.toString().toLowerCase();
			identifier = element.uuid;
		} catch(e) {
			console.log('Fail to load element ' + e);
		}
        }

	let alert_upd: Alert = {
                id: this.selected_alert.id,
                timeago: moment().unix()*1000,
                user: this.selected_alert.user,
                name: this.selected_alert.name,
                notified_name: this.selected_alert.notified_name,
                counter: this.selected_alert.counter,
                granularity: this.selected_alert.granularity,
                uuid: this.selected_alert.uuid,
                state: this.selected_alert.state,
                threshold_1: alert_format.threshold_1,
                threshold_2: alert_format.threshold_2,
                frequency: alert_format.frequency,
                identifier: identifier,
                definition: alert_format.definition,
                type: type,
                base: alert_format.base
        };

	this.audit_svc.updateAlert(alert_upd).pipe(first()).subscribe(
                success => {
                        this.update_state = 'success';
                        this.update_result = 'changes have been applied.';

                        let current_alert: Alert = this.getAlertFromUuid(this.selected_alert.uuid);
                        current_alert.threshold_1 = alert_upd.threshold_1;
                        current_alert.threshold_2 = alert_upd.threshold_2;
                        current_alert.frequency = alert_upd.frequency;
                        current_alert.identifier = alert_upd.identifier;
                        current_alert.definition = alert_upd.definition;
                        current_alert.type = alert_upd.type;
			this.converted_alert = alertConvApiToIhm(current_alert);
			if(this.converted_alert.type == "filter") {
				this.converted_alert.itemname = this.converted_alert.item;
			} else {
				if(current_alert.identifier != "all_vm" && current_alert.identifier != "all_esx") {
					try {
						const element_name: string = this.getVmFromUuid(current_alert.identifier).name;
						this.converted_alert.itemname = element_name;
					} catch(e) {
						console.log('fail to load VM ' + e);
					}
				}
			}
			this.audit_svc.createTrigger().subscribe(
                		error => {
                        		console.log(error);
				}
			);
			this.audit_svc.discoverSnmp(alert_upd.user).subscribe(
                		error => {
                        		console.log(error);
				}
			);
                },
                error => {
                        if(error != null)
                                console.log(error)
                }
        );
        this.releaseUpdate();
  }

  private getAlerts(): void {

        this.audit_svc.getAlertList(this.currentUser.login).pipe(first()).subscribe(
                data => {
                        this.alerts = data;
                        if(this.alerts.length > 0)
                                this.loadAlert(this.alerts[0]);
                },
                error => {
                        if(error != null)
                                console.log(error)
                }
        );
  }

  private loadAlert(alert: Alert): void {

        this.frequencies = [];
        this.items = [];
        this.selected_alert = alert;
        if(alert.type == "filter") {
                this.selected_alert_type = 'filter';
                this.frequencies.push('since 1 day');
                for(var i = 1; i < 30; i++) {
                        this.frequencies.push('since ' + i + ' days');
                }
                for(var i=0; i < this.message.filterList.length; i++) {
                        this.items.push(this.message.filterList[i].name);
                }
        } else {
                this.selected_alert_type = 'real time';
                this.frequencies.push('since 1 minute');
                for(var i = 1; i < 60; i++) {
                        this.frequencies.push('since ' + i + ' minutes');
                }
                this.items.push('---ALL VM---');
                this.items.push('---ALL ESX---');
                for(var i=0; i < this.data_items.length; i++) {
                        if(this.data_items[i].type == "SERVER" || this.data_items[i].type == "VM")
                                this.items.push(this.data_items[i].name);
                }
        }

	this.converted_alert = alertConvApiToIhm(alert);
	const element_uuid: string = this.converted_alert.item;
	let element_name: string = element_uuid;
	if(this.converted_alert.type == "filter") {
		this.converted_alert.itemname = element_name;
	} else {
		if(element_uuid != "all_vm" && element_uuid != "all_esx") {
			try {
				element_name = this.getVmFromUuid(element_uuid).name;
				this.converted_alert.itemname = element_name;
			} catch(e) {
				console.log('fail to load VM ' + e);
			}
		}
	}

        this.edit_model = {
                th1: this.converted_alert.th1,
                th2: this.converted_alert.th2,
                freq: this.converted_alert.freq,
                item: this.converted_alert.itemname
	};

        if(this.selected_alert.state == "enable") {
                this.enable_alert = true;
                this.alert_state = 'ENABLED';
        } else {
                this.enable_alert = false;
                this.alert_state = 'DISABLED';
	}

	this.isRdy = true;
        setTimeout(() => $('#alert-view').val(alert.notified_name), 100);
  }

  private getAlert(name: string): Alert {
        return this.alerts.find(alert => alert.notified_name === name);
  }

  private getAlertFromUuid(uuid: string): Alert {
        return this.alerts.find(alert => alert.uuid === uuid);
  }

  private getAlertFromCatalog(name: string): AlertCatalog {
        return this.alerts_catalog.find(alert => alert.name === name);
  }

  private getVmFromUuid(uuid: string): any {
        return this.data_items.find(item => item.uuid === uuid);
  }

  private getVmFromName(name: string): any {
        return this.data_items.find(item => item.name === name);
  }

  private releaseUpdate(): void {

        this.isUpdate = false;
        this.update_states.th1 = false;
        this.update_states.th2 = false;
        this.update_states.freq = false;
        this.update_states.item = false;
        this.update_state = 'error';
        this.update_result = 'changes have not been applied.';
  }
}

<clr-tabs>
	<clr-tab>
        <button clrTabLink (click)="setVmware()">VMWARE</button>
		<clr-tab-content *clrIfActive="true">
			<div class="clr-row">
				<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
					<ng-template load-t9></ng-template>
				</div>
				<div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
					<ng-template load-t7></ng-template>
				</div>
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
					<ng-template load-t12></ng-template>
				</div>
				<div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
					<ng-template load-t13></ng-template>
				</div>
			</div>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
        <button clrTabLink (click)="setAws()">AWS</button>
		<clr-tab-content>
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<app-account-management [targetInfrastructure]="this.message.cloudProvider" (loadRegion)="loadRegion()" *ngIf="isAccountMgtReady"></app-account-management>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<div class="card addScroll" *ngIf="!isAwsReady">
						<div class="card-header card-ajust">
							<h5>
								<clr-icon shape="tree"></clr-icon>
								&nbsp;CO2 Emissions
							</h5>
						</div>
						<div class="card-block">
							<div class="card-text">
								<div>
									<strong class="center">No data available !</strong>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="isAwsReady">
						<app-instances-co2></app-instances-co2>
					</div>
				</div>
			</div>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
        <button clrTabLink (click)="setAzure()">AZURE</button>
		<clr-tab-content>
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<app-account-management [targetInfrastructure]="this.message.cloudProvider" (loadRegion)="loadRegion()" *ngIf="isAccountMgtReady"></app-account-management>
				</div>
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<div class="card addScroll" *ngIf="!isAzureReady">
						<div class="card-header card-ajust">
							<h5>
								<clr-icon shape="tree"></clr-icon>
								&nbsp;CO2 Emissions
							</h5>
						</div>
						<div class="card-block">
							<div class="card-text">
								<div>
									<strong class="center">No data available !</strong>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="isAzureReady">
						<app-instances-co2></app-instances-co2>
					</div>
				</div>
			</div>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
        <button clrTabLink>STORAGE</button>
		<clr-tab-content>
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<div class="card addScroll">
						<div class="card-header card-ajust">
							<h5>
								<clr-icon shape="tree"></clr-icon>
								&nbsp;CO2 Emissions
							</h5>
						</div>
						<div class="card-block">
							<div class="card-text">
								<div>
									<strong class="center">No data available !</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</clr-tab-content>
	</clr-tab>
	<clr-tab>
        <button clrTabLink>NETWORK</button>
		<clr-tab-content>
			<div class="clr-row">
				<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
					<div class="card addScroll">
						<div class="card-header card-ajust">
							<h5>
								<clr-icon shape="tree"></clr-icon>
								&nbsp;CO2 Emissions
							</h5>
						</div>
						<div class="card-block">
							<div class="card-text">
								<div>
									<strong class="center">No data available !</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</clr-tab-content>
	</clr-tab>
	<!--<clr-tab>
        <button clrTabLink>KUBERNETES</button>
		<clr-tab-content>
		</clr-tab-content>
	</clr-tab>-->
</clr-tabs>
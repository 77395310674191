import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import { AccountService, JsonloaderService, ManagementService, ShareService } from '@app/services';

import { Message, SelectionFilter, VmCost, VM_COST, User } from '@app/model';

import { SelectiontreeComponent } from '@app/selectiontree/selectiontree.component';
import { SelectiontreeDirective } from '@app/directives/selectiontree.directive';

import { getUserCurrency } from '../../../assets/js/tools.js';


@Component({
  selector: 'app-costvmdetail',
  templateUrl: './costvmdetail.component.html',
  styleUrls: ['./costvmdetail.component.css']
})
export class CostvmdetailComponent implements AfterViewInit, OnInit {

  @ViewChild(SelectiontreeDirective) addTree: SelectiontreeDirective;

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  model: any;

  data_cost: VmCost[] = [];

  message: Message;

  globalCurrency: string = '';

  private currentUser: User;

  private current_tool: boolean = false;


  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private management_svc: ManagementService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	$( "div.dataTables_filter" ).remove();

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.globalCurrency = getUserCurrency(this.currentUser.currency);

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.elementViewType = 'server';

	this.initDtOptions();

	const infra: any[] = this.json_svc.json.dcInfraMin;
	for(var i = 0; i < infra.length; i++) {
		if(infra[i].type == "VM") {
	  		let vm_cost: VmCost = {
				uuid: infra[i].uuid,
	  			name: infra[i].name,
	  			cost: 200
	  		};
			this.data_cost.push(vm_cost);
	  	}
	}
	this.model = {
		cost: 200
	};
  }

  ngAfterViewInit(): void {

	this.dtTrigger.next();
	this.initVmCost();
  }

  ngAfterViewChecked(): void {

	if(this.current_tool != this.message.isCostTool) {
	  	this.current_tool = this.message.isCostTool;
	  	if(this.current_tool) {
			$( "div.dataTables_filter" ).remove();
	  		this.loadTree();
			$('#cost-vm-list').slideToggle();
		} else {
			$('#cost-vm-list').slideToggle();
			this.reloadTable();
	  	}
	}
        this.cd.detectChanges();
  }

  private applyChild(node: any): void {

	if(node != undefined) {
		for(var i=0; i < node.children.length; i++) {
			if(node.children[i].type == "vm" && node.children[i].selected == 1) {
	  			try {
					let vm_cost: VmCost = this.findVmCost(node.children[i].id);
	  				vm_cost.cost = this.model.cost;
	  			} catch(e) {
	  				console.log('fail to load VmCost');
	  			}
	  		} else {
				this.applyChild(node.children[i]);
	  		}
	  	}
	}
  }

  applyElement(): void {

	this.message.isCostRecord = true;

	for(var i = 0; i < this.message.elementViewTree.length; ++i) {
		if(this.message.elementViewTree[i].type == "vm" && this.message.elementViewTree[i].selected == 1) {
	  		try {
				let vm_cost: VmCost = this.findVmCost(this.message.elementViewTree[i].id);
	  			vm_cost.cost = this.model.cost;
	  		} catch(e) {
	  			console.log('fail to load VmCost');
	  		}
	  	} else {
			this.applyChild(this.message.elementViewTree[i]);
		}
	}

	this.message.isTreeAnalysis = false;
	this.message.elementViewType = 'server';
	this.message.isCostTool = false;
	this.message.costVmDetail = this.data_cost;
  }

  enableVmUpdate(): void {

	this.message.isCostRecord = true;
	this.message.costVmDetail = this.data_cost;
  }

  closeCard(): void {
	this.message.isCostTool = false;
  }

  private initVmCost(): void {

	this.management_svc.getVmCost('root').pipe(first()).subscribe(
	  	data => {
			if(data.length > 0) {
	  			for(var i = 0; i < data.length; i++) {
	  				try {
	  					let vmCost: VmCost = this.findVmCost(data[i].IDENTIFIER);
						vmCost.cost = data[i].COST;
					} catch(e) {
						console.log('fail to find VmCost');
	  				}
	  			}
				this.reloadTable();
	  		}
		},
		error => {
			if(error != null)
				console.log(error)
	  	}
	);
  }

  private findVmCost(uuid: string): VmCost {
	return this.data_cost.find(vc => vc.uuid === uuid);
  }

  private loadTree(): void {

	if(this.addTree != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SelectiontreeComponent);
	  	const viewContainerRef = this.addTree.viewContainerRef;
	  	viewContainerRef.clear();
	  	const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private reloadTable(): void {

	  if(this.dtElement.dtInstance != undefined) {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			$( "div.dataTables_filter" ).remove();
                	dtInstance.destroy();
                	this.dtTrigger.next();
          	});
	  }
  }

  private initDtOptions(): void {

	this.dtOptions = {
		pagingType: 'full_numbers',
	  	pageLength: 10,
		lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
		processing: true,
	  	destroy: true,
		initComplete: function() {
	  		$('div.dataTables_filter').appendTo('span.search');
	  		$("[id*='DataTables_Table_']").css({'padding-top':'5px'});
	  		$("[name*='DataTables_Table_']").css({'padding':'0px'});
	  	},
	  	language: {
			loadingRecords: "loading .. ",
			zeroRecords: "loading ...",
	  		search: '',
	  		searchPlaceholder: " search..."
	  	},
		dom: '<"top">rt<"bottom"lifp>'
	};
  }
}

export enum ReportCard {
    MANAGE, OPS, RECO, CUSTOM, EDIT
  }
  
  export enum Months {
    January = 1,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
  }
  
  export enum ReportState {
    INITIALIZED = "initialized",
    READY = "ready",
    DELETED = "deleted"
  }

  export enum ReportFormat {
    PDF = "pdf",
    XLS = "xls"
  }


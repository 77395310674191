import { Component, OnInit } from '@angular/core';

import { ClrDatagridSortOrder } from '@clr/angular';

import { JSONTarget, Message } from '@app/model';

import { JsonloaderService, ShareService } from '@app/services';


@Component({
  selector: 'app-behavior-detail',
  templateUrl: './behavior-detail.component.html',
  styleUrls: ['./behavior-detail.component.css']
})
export class BehaviorDetailComponent implements OnInit {

  message: Message;

  behaviors: any = [];

  dataOrder = ClrDatagridSortOrder.ASC;

  selected_behavior: any;

  selected_beh: any;

  isSelected: boolean = false;


  constructor(
    private json_svc: JsonloaderService,
    private message_svc: ShareService
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);
    let account = this.message.cloudAccount;
    let region_str: string = '';
    region_str = this.message.cloudRegion.replace(/-/g,"");

    this.json_svc.getData('root','cloud_' + account + '_' + region_str, JSONTarget.CLOUD_BEHAVIOR).subscribe(
      data => {
        this.behaviors = data;
      }
    );
  }

  behChanged(event: any): void {

    if(event != null) {
      this.selected_behavior = this.selected_beh;
      this.isSelected = true;
    }
  }

  exportBehavior() {

    let res = ["ID", "Name", "Behavior", "vCPU Reference (%)", "Compute time in month (hour)", "Compute cost($)", "Action"].join(',') + '\n';
                res += Object.values<any>(this.behaviors).map(behavior =>
                        [behavior.uuid, behavior.name, behavior.idleThreshold, behavior.cpuRef, behavior.runH, behavior.computeCost, behavior.action].join(",")
                ).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'opportunities_behvior.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }
}

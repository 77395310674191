<div class="card">
	<div class="card-header card-adjust">
		<h5>
			<clr-icon shape="recycle"></clr-icon>
			&nbsp;Life cycle for {{message.currentName}} 
		</h5>
		<span class="export"></span>
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<span class="search"></span>
	</div>
	<div class="card-block">
		<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
		<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  			<thead>
    				<tr>
      					<th>DATE</th>
						<th>SERVER</th>
      					<th>VCPU</th>
      					<th>VRAM (MB)</th>
      					<th>STATE</th>
      					<th>EVENT</th>
      					<th>PERFORMER</th>
				</tr>
  			</thead>
			<tbody>
    				<tr *ngFor="let event of data_events">
					<td [attr.data-sort]="event.DATE">{{moment(event.DATE).format('MMMM Do YYYY h:mm a')}}</td>
					<td>{{event.SERVER}}</td>
					<td>{{event.VCPU}}</td>
					<td>{{event.VRAM}}</td>
					<td>{{event.STATE}}</td>
					<td>{{event.EVENT}}</td>
					<td>{{event.PERFORMER}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

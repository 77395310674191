import { Component, OnInit } from '@angular/core';

import { JSONTarget, Message } from '@app/model';

import { JsonloaderService, ShareService } from '@app/services';

import * as Highcharts from "highcharts/highstock";
import { Options } from "highcharts/highstock";

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);


@Component({
  selector: 'app-kpi-evolution',
  templateUrl: './kpi-evolution.component.html',
  styleUrls: ['./kpi-evolution.component.css']
})
export class KpiEvolutionComponent implements OnInit {

  highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart | null;
  chartOptions: Highcharts.Options = {};
  
  message: Message;

  metadata: any = [];

  kpiEvolution: any = [];

  selected_sp: any;

  isReady: boolean = false;


  constructor(
    private json_svc: JsonloaderService,
    private message_svc: ShareService
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);
    let account = this.message.cloudAccount;
    let region_str: string = '';
    region_str = this.message.cloudRegion.replace(/-/g,"");

    this.json_svc.getData('root','cloud_' + account + '_' + region_str, JSONTarget.CLOUD_KPI_EVOLUTION).subscribe(
      data => {
        this.kpiEvolution = data;
        this.json_svc.getData('root','cloud_' + account + '_' + region_str, JSONTarget.CLOUD_METADATA).subscribe(
          data => {
            this.metadata = data;
            this.loadGraph();
          }
        );
      }
    );
  }

  private loadGraph(): void {
  
    let title: string = 'instances created over the current month';
    let titleSize: string = '20px';

    let avg_create: number = this.metadata.kpiEvolutionAvg;
    let avg_min_ok : number = 0;
    let avg_max_ok : number = 0;
    avg_min_ok = avg_create - (10*avg_create/100);
    avg_max_ok = avg_create + (10*avg_create/100);

    let cat: any = [];
    let normal: any = [];
    let over: any = [];
    let under: any = [];
    for(let i in this.kpiEvolution) {
      cat.push(this.kpiEvolution[i].timeago);
      if(this.kpiEvolution[i].nbCreate >= avg_min_ok && this.kpiEvolution[i].nbCreate <= avg_max_ok) {
        normal.push(this.kpiEvolution[i].nbCreate)
        over.push(null);
        under.push(null);
      } else if(this.kpiEvolution[i].nbCreate < avg_min_ok) {
        normal.push(null)
        over.push(null);
        under.push(this.kpiEvolution[i].nbCreate);
      } else if(this.kpiEvolution[i].nbCreate > avg_max_ok) {
        normal.push(null)
        over.push(this.kpiEvolution[i].nbCreate);
        under.push(null);
      }
    }

    let series: any = [];
    let serie0: any = {
      type: 'column',
      name: 'instance created',
      data: normal,
      color: '#FFCC00'
    };
    series.push(serie0);
    let serie1: any = {
      type: 'column',
      name: 'instance created',
      data: over,
      color: '#CD7F32'
    };
    series.push(serie1);
    let serie2: any = {
      type: 'column',
      name: 'instance created',
      data: under,
      color: '#C0C0C0'
    };
    series.push(serie2);

    this.chartOptions = {
      credits: {
        enabled: false
      },
      title: {
        text: title,
        style: {
          color: 'grey',
          fontSize: titleSize
        }
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories:cat,
        title: {
          text: 'day of month'
        },
        labels:{
          formatter:function(){
              return Highcharts.dateFormat('%d',this.value);
          }
        }
      },
      yAxis: [{
        title: {
          text: 'instances',
        }
      }],
      chart: {
        plotBorderWidth: null,
        plotShadow: false
      },
      tooltip: {
        shared: false,
        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false
          }
        }
      },
      lang: {
        contextButtonTitle: "Export graph"
      },
      exporting: {
        buttons: {
          contextButton: {
            className: "addLink",
            symbol: 'download',
            text: "download",
            symbolStroke: "#0072A3",
            x: -10
          }
        }
      },
      series: series
    };
    this.isReady = true;
  }
}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-recommendation-resizing-detail-ram]'
})
export class RecommendationresizingdetailramDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

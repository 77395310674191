import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AccountService, ShareService, LicenseService, MonitorService } from '@app/services';
import { AliasUser, ConnectType, Message, User } from '@app/model';

import { environment } from "@environments/environment";
import { MonitorComponent } from '@app/monitor/monitor.component';
import { ClrDatagridSortOrder } from '@clr/angular';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild("monitor", { static: true }) monitorChild: MonitorComponent;

  dataOrder = ClrDatagridSortOrder.ASC;

  isDcscope = true;
	isCo2scope = false;

  message: Message;
  currentUser: User;

  waiting: boolean = false;

  error: boolean = false;
  error_message: string;

  form = {
    type: ConnectType.LOCAL,
    username: '',
    password: '',
    rememberMe: true
  };

  local = ConnectType.LOCAL;
  ldap = ConnectType.LDAP;

  alias_modal: boolean = false;
  aliases: Array<AliasUser> = [];
  alias_selected: AliasUser;


  /**
   * 
   */
  constructor(private router: Router, private authenticationService: AccountService,
    private data: ShareService, private license_svc: LicenseService,
    private monitor_svc: MonitorService, private cd: ChangeDetectorRef) {

  }

  /**
   * 
   */
  ngOnInit(): void {
    // Disable the monitor & licence check when DCSCOPE is running in development or staging infrastructure
    if (environment.production) {

      this.monitor_svc.checkInstall().subscribe(
        data => {
          this.license_svc.checkLicense().subscribe(
            data => {
              // data : license OK
              // Nothing
            },
            error => {
              // error : license KO
              this.license_svc.removeInfo();
              this.router.navigate(['/license'], { queryParams: { code: error.error.code } });
            }
          );
        },
        error => {
          // error : Install KO
                    
          let exit_code = error.error.code;
          
          //XXX if exit_code undefined, we consider that the api is not reachable
          // so we don't display the wizard ...
          if(exit_code !== undefined) {
          	// Migration in progress ?
            let goto_migration: boolean = exit_code == 1 ? true : false;

            //Open welcome modal
            this.monitorChild.initWizard(true, goto_migration);
          }
        }
      );

    }

    this.authenticationService.user.subscribe(user => this.currentUser = user);
    this.data.currentMessage.subscribe(message => this.message = message);

		this.isCo2scope = this.message.isCO2Scope;
		this.isDcscope = this.message.isDCScope;

  }

  /**
   * 
   */
  ngAfterViewChecked(): void {
		this.cd.detectChanges();
	}

  /**
   * 
   */
  onSubmit(): void {
    this.waiting = true;
    this.error = false;

    let hash = this.authenticationService.crypt(this.form.password);

    this.authenticationService.login(this.form.username, hash, this.form.type).pipe(first()).subscribe(
      data => {
        this.login();
      },
      error => {
        this.waiting = false;
        this.error = true;
       
        let error_code;
        let error_aliases: Array<AliasUser>;

        switch(this.form.type){
          case ConnectType.LDAP:
            this.error_message = error.error.message;
            this.currentUser.token = error.error.token;
            error_code = error.error.code;
            error_aliases = error.error.aliases;
          break;
          default:
            this.error_message = error.statusText;
            error_code = error.status;
          break;
        }

        // Bind OK but need to choose alias
        if(error_code == 409) {
            // show modal
            this.alias_modal = true;

            // Check duplicates
            error_aliases.forEach(attr => {
              if(!this.aliases.find(u => u.alias_login == attr.alias_login))
                  this.aliases.push(attr);
            });
        }
      }
    );
  }

  /**
   * 
   */
  confirmAlias(): void {
    this.alias_modal = false;

    this.waiting = true;
    this.error = false;

    this.authenticationService.confirmAlias(this.alias_selected.alias_login,this.alias_selected.user_login).pipe(first()).subscribe(
      data => {
        this.login();
      },
      error => {
        this.waiting = false;

        this.error = true;
        this.error_message = error.error.message;
      }
    );
  }

  /**
   * 
   */
  private login(): void {
    this.waiting = false;

    this.router.navigate(['/dashboard']);
    this.message.isNotLoginPage = true;

    // Send client infos
    // XXX need to waiting for data ...
    setTimeout(() => {
      this.license_svc.sendInfos();
    }, 2000);
  }

}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[cloud-graph-region]'
})
export class CloudgraphregionDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import {Component, Input, OnInit} from '@angular/core';
import {NetscopeService} from "@app/services";

@Component({
  selector: 'app-expandable-rows-links',
  templateUrl: './expandable-rows-links.component.html',
  styleUrls: ['./expandable-rows-links.component.css']
})
export class ExpandableRowsLinksComponent implements OnInit {

  @Input() flowDetail;
  @Input() timeResolution = "daily";
  @Input() epochDate;
  loading: boolean;

  ports = []
  protocols = []

  constructor(private netscopeService: NetscopeService) { }

  ngOnInit(): void {
    this.loading = true;
    let startTimeInterval = 0;
    let endTimeInterval = "now()";

    if (this.flowDetail !== undefined) {
      let srcIp = this.flowDetail.source.ipaddress;
      let dstIp = this.flowDetail.target.ipaddress;
      this.netscopeService.getFlowDetails(startTimeInterval, endTimeInterval, srcIp, dstIp, this.epochDate, this.timeResolution, false).subscribe((flowDetails) => {

        // @ts-ignore
        this.ports = flowDetails.ports;
        // @ts-ignore
        this.protocols = flowDetails.protocols;

        setTimeout(() => {
          this.loading = false;
        }, 300);
      })
    }
  }

}

<div class="clr-row">
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
		<div id="cost-server-list" class="card" style="margin-top:unset;">
			<div class="card-block">
				<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-compact">
  					<thead>
    						<tr>
      							<th>Nane</th>
      							<th>Thread capacity</th>
      							<th>Ram capacity (GB)</th>
      							<th>Server cost ({{globalCurrency}})</th>
      							<th>Ratio</th>
      							<th>vCPU cost ({{globalCurrency}})</th>
      							<th>vRAM cost ({{globalCurrency}}/GB)</th>
						</tr>
  					</thead>
					<tbody>
    						<tr *ngFor="let server of data_cost">
							<td>{{server.name}}</td>
							<td>{{server.thread}}</td>
							<td>{{server.ram}}</td>
							<td style="width:15%;vertical-align:top">
								<div class="clr-form-control" style="flex-direction:column;margin-top:unset;">
                                        				<div>
                                            					<div class="clr-input-wrapper">
											<b><input class="clr-input" type="number" [(ngModel)]="server.cost" (ngModelChange)="updateCostServerFromId(server.uuid)" style="width:6em;text-align:left"></b>
                                                					<clr-icon class="clr-validate-icon" *ngIf="server.cost == null" shape="exclamation-circle"></clr-icon>
                                            					</div>
									</div>
                                    				</div>
							</td>
							<td>
								<clr-range-container [clrRangeHasProgress]="true" style="margin-top:0px;align-items:center;text-align:left">
									<input id="detail" type="range" clrRange [(ngModel)]="server.ratio" name="three" (ngModelChange)="ratioChangeFromid(server.uuid)"/>
									<clr-control-helper>{{server.ratio}}% assigned to vCPU</clr-control-helper>
								</clr-range-container>
							</td>
							<td style="width:15%;vertical-align:top">
								<div class="clr-form-control" style="flex-direction:column;margin-top:unset;">
                                        				<div>
                                            					<div class="clr-input-wrapper">
											<b><input class="clr-input" type="number" [(ngModel)]="server.vcpu_cost" (ngModelChange)="updateCostVcpuFromId(server.uuid)" style="width:6em;text-align:left"></b>
                                                					<clr-icon class="clr-validate-icon" *ngIf="server.vcpu_cost == null" shape="exclamation-circle"></clr-icon>
                                            					</div>
                                        				</div>
                                    				</div>
							</td>
							<td style="width:15%;vertical-align:top">
								<div class="clr-form-control" style="flex-direction:column;margin-top:unset;">
                                        				<div>
                                            					<div class="clr-input-wrapper">
											<b><input class="clr-input" type="number" [(ngModel)]="server.vram_cost" (ngModelChange)="updateCostVramFromId(server.uuid)" style="width:6em;text-align:left"></b>
                                                					<clr-icon class="clr-validate-icon" *ngIf="server.vram_cost == null" shape="exclamation-circle"></clr-icon>
                                            					</div>
                                        				</div>
                                    				</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="clr-col-lg-12 clr-col-md-12 clr-col-12" *ngIf="message.isCostTool">
		<div class="card" style="margin-top:unset;">
			<div class="card-header card-ajust">
				<button type="button" class="close" aria-label="Close" (click)="closeCard()">
					<clr-icon aria-hidden="true" shape="close"></clr-icon>
				</button>
				<h6>
					<clr-icon shape="tools"></clr-icon>
					&nbsp;selection tool
				</h6>
			</div>
			<div class="card-block">
				<div class="clr-row">
					<div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
						<clr-control-helper>1- select servers where the new cost must be apply</clr-control-helper>
						<ng-template load-selection-tree></ng-template>
					</div>
					<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
						<div class="clr-input-wrapper">
							<input class="clr-input" type="number" [(ngModel)]="model.cost" (ngModelChange)="updateCostServer()" style="width:6em;text-align:left">
							<clr-control-helper>2- enter the new server cost in {{globalCurrency}}</clr-control-helper>
							<clr-icon class="clr-validate-icon" *ngIf="model.cost == null" shape="exclamation-circle"></clr-icon>
						</div>
					</div>
					<div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
						<clr-range-container [clrRangeHasProgress]="true" style="margin-top:0px;align-items:center;text-align:left">
							<input id="generictool" type="range" clrRange [(ngModel)]="model.ratio" name="three" (ngModelChange)="ratioChange()"/>
							<clr-control-helper>{{model.ratio}}% assigned to vCPU</clr-control-helper>
						</clr-range-container>
					</div>
					<div class="clr-col-lg-1 clr-col-md-1 clr-col-12">
						<div class="clr-input-wrapper">
							<input class="clr-input" type="number" [(ngModel)]="model.vcpu_cost" (ngModelChange)="updateCostVcpu()" style="width:6em;text-align:left">
							<clr-control-helper>vCPU cost in {{globalCurrency}}</clr-control-helper>
							<clr-icon class="clr-validate-icon" *ngIf="model.vcpu_cost == null" shape="exclamation-circle"></clr-icon>
						</div>
					</div>
					<div class="clr-col-lg-1 clr-col-md-1 clr-col-12">
						<div class="clr-input-wrapper">
							<input class="clr-input" type="number" [(ngModel)]="model.vram_cost" (ngModelChange)="updateCostVram()" style="width:6em;text-align:left">
							<clr-control-helper>vRAM cost in {{globalCurrency}}</clr-control-helper>
							<clr-icon class="clr-validate-icon" *ngIf="model.vram_cost == null" shape="exclamation-circle"></clr-icon>
						</div>
					</div>
					<div class="clr-col-lg-2 clr-col-md-2 clr-col-12" *ngIf="message.isTreeAnalysis">
						<button type="button" class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="applyElement()">
							APPLY
						</button>
						<clr-control-helper>3- apply cost to selected servers</clr-control-helper>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

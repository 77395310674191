<h2>Storage overview</h2>

<div class="clr-row">
    <div class="clr-col-10">
        <span>
            <clr-icon shape="vm"></clr-icon>
            <h4 style="display: inline; margin-left: 15px;">VMs storage information</h4>
            <span *ngIf="!selectedVm" style="margin-left: 10px;" class="label label-info">(Pick a vm to view its datastores)</span>
        </span>
    </div>
    <div class="clr-col-2">
        <clr-dropdown>
            <button class="btn btn-outline-primary" clrDropdownTrigger>
                Export
                <clr-icon shape="caret down"></clr-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen [clrPosition]="'bottom-right'">
                <label class="dropdown-header" aria-hidden="true">CSV file</label>
                <div clrDropdownItem><button class="btn" (click)="exportVmsCSV()">All VMs</button></div>
            </clr-dropdown-menu>
        </clr-dropdown>
    </div>
</div>

<div class="datagrid-limit-height">
    <clr-datagrid [clrDgLoading]="datastoresAreLoading" class="vmDataGrid" [(clrDgSingleSelected)]="selectedVm"
                  (clrDgSingleSelectedChange)="selectionChanged($event)">
        <clr-dg-column [clrDgField]="'name'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Vm Name
            </ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'stocom'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Usage
            </ng-container>
            <clr-dg-filter [clrDgFilter]="stocomFilter">
                <column-range-filter [fieldName]="'stocom'" [items]="listedVms" #stocomFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'stoprov'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Provisioned
            </ng-container>
            <clr-dg-filter [clrDgFilter]="stoprovFilter">
                <column-range-filter [fieldName]="'stoprov'" [items]="listedVms" #stoprovFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'avgiops'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                IOPS (avg)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="avgiopsFilter">
                <column-range-filter [fieldName]="'avgiops'" [items]="listedVms" #avgiopsFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'maxiops'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                IOPS (max)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="maxiopsFilter">
                <column-range-filter [fieldName]="'maxiops'" [items]="listedVms" #maxiopsFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'avglat'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Latency (avg)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="avglatFilter">
                <column-range-filter [fieldName]="'avglat'" [items]="listedVms" #avglatFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'maxlat'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Latency (max)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="maxlatFilter">
                <column-range-filter [fieldName]="'maxlat'" [items]="listedVms" #maxlatFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let vm of listedVms" [clrDgItem]="vm">
            <clr-dg-cell>{{ vm.name }}</clr-dg-cell>
            <clr-dg-cell>{{ vm.stocom }} Go</clr-dg-cell>
            <clr-dg-cell>{{ vm.stoprov }} Go</clr-dg-cell>
            <clr-dg-cell>{{ vm.avgiops }}</clr-dg-cell>
            <clr-dg-cell>{{ vm.maxiops }}</clr-dg-cell>
            <clr-dg-cell>{{ vm.avglat }} ms</clr-dg-cell>
            <clr-dg-cell>{{ vm.maxlat }} ms</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">VMs per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} VMs
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>

<div style="margin-top: 30px;" class="clr-row" *ngIf="listedDatastores.length > 0 && selectedVm != null">
    <div class="clr-col-10">
        <span>
            <h5 style="display: inline; margin-right: 10px;">Datastores associated with selected VM</h5>
            <span class="label label-blue">{{ selectedVm.name }} <span class="badge"><clr-icon shape="vm"></clr-icon></span></span>
            <span *ngIf="selectedDatastores.length == 0" style="margin-left: 10px;" class="label label-info">(Pick at least one datastore to enable visualization)</span>
        </span>
    </div>
    <div class="clr-col-2">
        <clr-dropdown>
            <button class="btn btn-outline-primary" clrDropdownTrigger>
                Export
                <clr-icon shape="caret down"></clr-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen [clrPosition]="'bottom-right'">
                <label class="dropdown-header" aria-hidden="true">CSV file</label>
                <div clrDropdownItem><button class="btn" (click)="exportDatastoreCSV()">All datastores</button></div>
            </clr-dropdown-menu>
        </clr-dropdown>
    </div>
</div>

<!--<br>-->
<div class="datagrid-limit-height" *ngIf="listedDatastores.length > 0 && selectedVm != null">
    <clr-datagrid [clrDgLoading]="graphsAreLoading" class="datastoreDataGrid"
                  [(clrDgSelected)]="selectedDatastores"
                  (clrDgSelectedChange)="updateChartData($event)">
        <clr-dg-column [clrDgField]="'name'">Datastore Name</clr-dg-column>

        <clr-dg-column [clrDgField]="'url'">Url</clr-dg-column>

        <clr-dg-column [clrDgField]="'capacity'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Datastore capacity
            </ng-container>
            <clr-dg-filter [clrDgFilter]="capacityFilter">
                <column-range-filter [fieldName]="'capacity'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #capacityFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'usageP'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Datastore usage
            </ng-container>
            <clr-dg-filter [clrDgFilter]="usagePFilter">
                <column-range-filter [fieldName]="'usageP'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #usagePFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'consummedStorage'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                VM capacity
            </ng-container>
            <clr-dg-filter [clrDgFilter]="consummedStorageFilter">
                <column-range-filter [fieldName]="'consummedStorage'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #consummedStorageFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'consummedStorageUsage'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                VM usage
            </ng-container>
            <clr-dg-filter [clrDgFilter]="consummedStorageUsageFilter">
                <column-range-filter [fieldName]="'consummedStorageUsage'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #consummedStorageUsageFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'free'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Free
            </ng-container>
            <clr-dg-filter [clrDgFilter]="freeFilter">
                <column-range-filter [fieldName]="'free'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #freeFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'provisioned'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Provisioned
            </ng-container>
            <clr-dg-filter [clrDgFilter]="provisionedFilter">
                <column-range-filter [fieldName]="'provisioned'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #provisionedFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'avgiops'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                IOPS (avg)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="avgiopsFilter">
                <column-range-filter [fieldName]="'avgiops'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #avgiopsFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'maxiops'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                IOPS (max)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="maxiopsFilter">
                <column-range-filter [fieldName]="'maxiops'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #maxiopsFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'avgiopsread'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                IOPS Read (avg)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="avgiopsreadFilter">
                <column-range-filter [fieldName]="'avgiopsread'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #avgiopsreadFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'maxiopsread'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                IOPS Read (max)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="maxiopsreadFilter">
                <column-range-filter [fieldName]="'maxiopsread'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #maxiopsreadFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'avgiopswrite'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                IOPS Write (avg)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="avgiopswriteFilter">
                <column-range-filter [fieldName]="'avgiopswrite'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #avgiopswriteFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'maxiopswrite'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                IOPS Write (max)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="maxiopswriteFilter">
                <column-range-filter [fieldName]="'maxiopswrite'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #maxiopswriteFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'avglat'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Latency (avg)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="avglatFilter">
                <column-range-filter [fieldName]="'avglat'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #avglatFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'maxlat'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Latency (max)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="maxlatFilter">
                <column-range-filter [fieldName]="'maxlat'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #maxlatFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'avglatread'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Latency Read (avg)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="avglatreadFilter">
                <column-range-filter [fieldName]="'avglatread'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #avglatreadFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'maxlatread'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Latency Read (max)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="maxlatreadFilter">
                <column-range-filter [fieldName]="'maxlatread'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #maxlatreadFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'avglatwrite'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Latency Write (avg)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="avglatwriteFilter">
                <column-range-filter [fieldName]="'avglatwrite'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #avglatwriteFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'maxlatwrite'">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Latency Write (max)
            </ng-container>
            <clr-dg-filter [clrDgFilter]="maxlatwriteFilter">
                <column-range-filter [fieldName]="'maxlatwrite'" [items]="listedDatastores" [tableUpdate]="secondTableShouldChange"
                                     #maxlatwriteFilter></column-range-filter>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let datastore of listedDatastores" [clrDgItem]="datastore">
            <clr-dg-cell>{{ datastore.name }}</clr-dg-cell>
            <clr-dg-cell>{{ datastore.url }}</clr-dg-cell>
            <clr-dg-cell>{{ datastore.capacity }} Go</clr-dg-cell>
            <clr-dg-cell>{{ datastore.usageP }} %</clr-dg-cell>
            <clr-dg-cell>{{ datastore.consummedStorage | number }} Go</clr-dg-cell>
            <clr-dg-cell>{{ datastore.consummedStorageUsage | number }} %</clr-dg-cell>
            <clr-dg-cell>{{ datastore.free }} Go</clr-dg-cell>
            <clr-dg-cell>{{ datastore.provisioned }} Go</clr-dg-cell>
            <clr-dg-cell>{{ datastore.avgiops }}</clr-dg-cell>
            <clr-dg-cell>{{ datastore.maxiops }}</clr-dg-cell>
            <clr-dg-cell>{{ datastore.avgiopsread }}</clr-dg-cell>
            <clr-dg-cell>{{ datastore.maxiopsread }}</clr-dg-cell>
            <clr-dg-cell>{{ datastore.avgiopswrite }}</clr-dg-cell>
            <clr-dg-cell>{{ datastore.maxiopswrite }}</clr-dg-cell>
            <clr-dg-cell>{{ datastore.avglat }} ms</clr-dg-cell>
            <clr-dg-cell>{{ datastore.maxlat }} ms</clr-dg-cell>
            <clr-dg-cell>{{ datastore.avglatread }} ms</clr-dg-cell>
            <clr-dg-cell>{{ datastore.maxlatread }} ms</clr-dg-cell>
            <clr-dg-cell>{{ datastore.avglatwrite }} ms</clr-dg-cell>
            <clr-dg-cell>{{ datastore.maxlatwrite }} ms</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Datastores per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Datastores
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>


<div class="card" *ngIf="selectedDatastores.length > 0 && selectedVm != null">
    <div class="card-header">
        <div class="clr-row">

            <div class="clr-col-11">
                <span style="margin-right: 10px;">{{ getSelectedMetric().label }} of</span>
                <span *ngFor="let selectedDatastore of selectedDatastores" class="label red">{{ selectedDatastore.name }} <span class="badge"><clr-icon shape="data-cluster"></clr-icon></span></span>
                associated with
                <span class="label label-blue">{{ selectedVm.name }} <span class="badge"><clr-icon shape="vm"></clr-icon></span></span>
            </div>

            <div class="clr-col-1">
                <clr-signpost style="float: right;">
                    <button class="btn btn-link" clrSignpostTrigger>
                        <clr-icon shape="cog"></clr-icon>
                    </button>
                    <clr-signpost-content [clrPosition]="'left-middle'" *clrIfOpen>

                        <h3>Settings</h3>

                        <div class="clr-row">
                            <div class="clr-col-4">
                                <clr-radio-container clrInline>
                                    <label>IOPS</label>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="iops_avg" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>avg</label>
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="iops_max" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>max</label>
                                    </clr-radio-wrapper>
                                </clr-radio-container>
                            </div>

                            <div class="clr-col-4">
                                <clr-radio-container clrInline>
                                    <label>IOPS (read)</label>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="iops_read_avg" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>avg</label>
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="iops_read_max" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>max</label>
                                    </clr-radio-wrapper>
                                </clr-radio-container>
                            </div>

                            <div class="clr-col-4">
                                <clr-radio-container clrInline>
                                    <label>IOPS (write)</label>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="iops_write_avg" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>avg</label>
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="iops_write_max" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>max</label>
                                    </clr-radio-wrapper>
                                </clr-radio-container>
                            </div>
                        </div>

                        <div class="clr-row">
                            <div class="clr-col-4">
                                <clr-radio-container clrInline>
                                    <label>Latency</label>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="lat_avg" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>avg</label>
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="lat_max" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>max</label>
                                    </clr-radio-wrapper>
                                </clr-radio-container>
                            </div>

                            <div class="clr-col-4">
                                <clr-radio-container clrInline>
                                    <label>Latency (read)</label>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="lat_read_avg" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>avg</label>
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="lat_read_max" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>max</label>
                                    </clr-radio-wrapper>
                                </clr-radio-container>
                            </div>

                            <div class="clr-col-4">
                                <clr-radio-container clrInline>
                                    <label>Latency (write)</label>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="lat_write_avg" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>avg</label>
                                    </clr-radio-wrapper>
                                    <clr-radio-wrapper>
                                        <input type="radio" clrRadio name="selectedMetricName" required value="lat_write_max" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                        <label>max</label>
                                    </clr-radio-wrapper>
                                </clr-radio-container>
                            </div>
                        </div>

                        <clr-radio-container clrInline>
                            <label>Usage counters</label>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="selectedMetricName" required value="commited" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                <label>Commited</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="selectedMetricName" required value="uncommited" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                <label>Uncommited</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="selectedMetricName" required value="provisioned" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                <label>Provisioned</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="selectedMetricName" required value="unshared" (change)="updateChartData($event)" [(ngModel)]="selectedMetricName" />
                                <label>Unshared</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>

                    </clr-signpost-content>
                </clr-signpost>
            </div>

        </div>
    </div>

    <div class="card-block">
        <highcharts-chart
                [Highcharts]="Highcharts"
                [constructorType]="'stockChart'"
                [callbackFunction]="chartCallbackStorageChart"
                [options]="storageChartOptions"
                [(update)]="updateStorageChartFlag"
                [oneToOne]="true"
                style="width: 100%; height: 400px; display: block;"
        ></highcharts-chart>
    </div>
</div>

import { Component, OnInit } from '@angular/core';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import { JSONTarget, Message, User, Vm } from '@app/model';

import { ClrDatagridSortOrder, ClrDatagridComparatorInterface } from '@clr/angular';

import { commaBigNumber } from '../../../assets/js/tools';

class Co2Comparator implements ClrDatagridComparatorInterface<Vm> {
  compare(a: Vm, b: Vm) {
      return a.CO2 - b.CO2;
  }
}

class Co2gComparator implements ClrDatagridComparatorInterface<Vm> {
  compare(a: Vm, b: Vm) {
      return a.CO2G - b.CO2G;
  }
}


@Component({
  selector: 'app-co2project',
  templateUrl: './co2project.component.html',
  styleUrls: ['./co2project.component.css']
})
export class Co2projectComponent implements OnInit {

  co2Comparator = new Co2Comparator();

  co2gComparator = new Co2gComparator();

  currentUser: User;

  message: Message;

  dataOrder = ClrDatagridSortOrder.ASC;

  datas_green: any = [];

  model: any = {
    totalCo2: 0,
    directCo2: 0,
    greyCo2: 0,
    vms: [],
    templates: [],
    simulateCo2: 0,
    simulateClass: 'label saveCo2'
  };


  constructor(
    private authentication_svc: AccountService,
    private json_svc: JsonloaderService,
    private message_svc: ShareService) { }

  ngOnInit(): void {

    this.authentication_svc.user.subscribe(user => this.currentUser = user);

    this.message_svc.currentMessage.subscribe(message => this.message = message);
    this.message.powerUsageEnv = 'vmware';

    this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.GREENIT_POWER).subscribe(
			data => {
				this.datas_green = data;
				this.buildCurrentCo2();
			}
		);
  }

  onAdd(vm: Vm): void {

    if(this.filterTemplate(vm.NAME).length == 0) {
      let tmpl: any = {
        name: vm.NAME,
        quantity: 1
      }
      this.model.templates.push(tmpl);
      this.buildSimulateCo2();
    }
  }

  onDelete(vm: Vm): void {

    if(this.filterTemplate(vm.NAME).length == 0) {
      let tmpl: any = {
        name: vm.NAME,
        quantity: 0
      }
      this.model.templates.push(tmpl);
      this.buildSimulateCo2();
    }
  }

  removeElement(template: any) {

    this.model.templates = this.model.templates.filter((tmpl: { name: any; }) => tmpl.name != template.name);
    this.buildSimulateCo2();
  }

  buildSimulateCo2(): void {

    let simu_co2_tmp: number = 0;

    for(let i in this.model.templates) {
      if(this.model.templates[i].quantity > 0) {
        let vm: any = this.filterVm(this.model.templates[i].name);
        if(vm != undefined) {
          let direct: number = +vm[0].CO2;
          let grey: number = +vm[0].CO2G;
          simu_co2_tmp += (direct*this.model.templates[i].quantity)+(grey*this.model.templates[i].quantity);
        }
      } else {
        let vm: any = this.filterVm(this.model.templates[i].name);
        if(vm != undefined) {
          let direct: number = +vm[0].CO2;
          let grey: number = +vm[0].CO2G;
          simu_co2_tmp -= direct+grey;
        }
      }
    }

    this.model.simulateCo2 = this.model.totalCo2 + simu_co2_tmp;

    if(this.model.simulateCo2 <= this.model.totalCo2)
      this.model.simulateClass = 'label saveCo2';
    else
      this.model.simulateClass = 'label exceedCo2';
  }

  formatBigNumber(val: number): any {

    if(val > 1000 || val < -1000)
      return commaBigNumber(val);
    else return val
  }

  convertToNumber(val: any): number {

    let value: number = +val;
    return value;
  }

  private buildCurrentCo2(): void {

    for(let i in this.datas_green) {
      for(let j in this.datas_green[i].VMS) {
        let direct: number = +this.datas_green[i].VMS[j].CO2;
				let grey: number = +this.datas_green[i].VMS[j].CO2G;
				this.model.totalCo2 += direct+grey;
        this.model.directCo2 += direct;
        this.model.greyCo2 += grey;
        this.model.vms.push(this.datas_green[i].VMS[j])
      }
    }
  }

  private filterTemplate(name: string): any {
    return this.model.templates.filter((tmpl: { name: string; }) => tmpl.name === name);
  }

  private filterVm(name: string): any {
    return this.model.vms.filter((vm: { NAME: string; }) => vm.NAME === name);
  }
}

<div *ngIf="greenit_alert" class="alert alert-info" role="alert">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
            </div>
            <span class="alert-text">If you have just created your filter, data will be available tomorrow</span>
        </div>
    </div>
</div>

<div *ngIf="!greenit_nodata">
    <div class="clr-row">
        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
            <highcharts-chart 
                [Highcharts]="Highcharts"
                [options]="options"
                [callbackFunction]="chartCallback"
                style="width:100%;display:block;" >
            </highcharts-chart>
        </div>
        <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
            <div class="card" *ngIf="isPieReady">
                <div class="card-footer">
                    <highcharts-chart
                        [Highcharts] = "Highcharts"
                        [options] = "chartOptions"
                        [constructorType]="'chart'"
                        style = "width: 100%;display: block;" *ngIf="isPieReady">
                    </highcharts-chart>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="greenit_nodata">
    <strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
</div>


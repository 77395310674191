import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild, ComponentFactoryResolver, ComponentRef } from '@angular/core';

import { ConsoverComponent } from '@app/consover/consover.component';
import { HostconsoverDirective } from '@app/directives/hostconsover.directive';

import { ShareService } from '@app/services';

import { Message } from '@app/model';

var componentRef: ComponentRef<any>;


@Component({
  selector: 'app-hostconsover',
  templateUrl: './hostconsover.component.html',
  styleUrls: ['./hostconsover.component.css']
})
export class HostconsoverComponent implements OnInit {

  @ViewChild(HostconsoverDirective) addHostConsover: HostconsoverDirective;

  message: Message;


  constructor(
	private componentFactoryResolver: ComponentFactoryResolver,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {}

  ngOnInit(): void {

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.message.isVmConsover = false;
  }

  ngAfterViewInit(): void{
	  this.loadHostConsOver();
  }

  ngAfterViewChecked(): void {

	if(this.message.isReloadConsover) {
		this.message.isReloadConsover = false;
		this.reloadHostConsOver();
	}
  	this.cd.detectChanges();
  }

  private loadHostConsOver(): void {

	if(this.addHostConsover != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ConsoverComponent);
		const viewContainerRef = this.addHostConsover.viewContainerRef;
	  	viewContainerRef.clear();
		componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private reloadHostConsOver(): void {

	$( "div.dataTables_filter" ).remove();
	if(componentRef != undefined)
		componentRef.destroy();

	setTimeout(() => this.loadHostConsOver(), 100);
  }
}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-ts]'
})
export class TroubleshootingDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

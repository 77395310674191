import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Evolution, Infrastructure, KpiActivity } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class InfrastructureService {

  infra: Infrastructure = {
	cluster: '',
	datacenter: ''
  };

  currentInfrastructure: BehaviorSubject<Infrastructure> = new BehaviorSubject<Infrastructure>(this.infra);
  public infrastructure: Observable<Infrastructure> = this.currentInfrastructure.asObservable();

  constructor(private http: HttpClient) { }

  public get currentInfra(): Infrastructure {
	return this.currentInfrastructure.value;
  }

  getFather(uuid: string) {
	  return this.http.get<any>(`${environment.apiUrl}/` + 'infrastructure/fatherhierarchy/' + uuid).pipe(map(father => {
		  return father;
	}));
  }

  getElementInfra(uuid: string, type: string) {
	  return this.http.get<Infrastructure>(`${environment.apiUrl}/` + 'infrastructure/hierarchy/' + uuid + '&' + type).pipe(map(infra => {
		localStorage.setItem('infra', JSON.stringify(infra));
		this.currentInfrastructure.next(infra);
		return infra;
	}));
  }

  getHostEvolution(uuid: string, type: string) {
	return this.http.get<Evolution>(`${environment.apiUrl}/` + 'infrastructure/evolution/' + uuid + '&' + type).pipe(map(evol => {
		return evol;
	}));
  }

  // KPI PART
  getKpiOff(user: string, start: number, end: number, fv: string) {
	return this.http.get<KpiActivity[]>(`${environment.apiUrl}/` + 'infrastructure/kpioff/' + user + '&' + start + '&' + end + '&' + fv).pipe(map(kpioff_lst => {
		return kpioff_lst;
	}));
  }

  getKpiSuspend(user: string, start: number, end: number, fv: string) {
	return this.http.get<KpiActivity[]>(`${environment.apiUrl}/` + 'infrastructure/kpisuspend/' + user + '&' + start + '&' + end + '&' + fv).pipe(map(kpisuspend_lst => {
		return kpisuspend_lst;
	}));
  }

  getKpiAction(user: string, start: number, filter: string, action: string) {
    return this.http.get<KpiActivity[]>(`${environment.apiUrl}/` + 'infrastructure/kpiaction/' + user + '&' + start + '&' + filter + '&' + action).pipe(map(kpiadd_lst => {
      return kpiadd_lst;
    }));
  }

  updateModel(consolidation: string) {
      return this.http.post(`${environment.apiUrl}/` + 'infrastructure/updatemodel/', [consolidation]);
  }

  updateModelCloud(consolidation: string) {
      return this.http.post(`${environment.apiUrl}/` + 'infrastructure/updatemodelcloud/', [consolidation]);
  }

  getSnapshots() {
	  return this.http.get<any>(`${environment.apiUrl}/` + 'infrastructure/getsnapshot').pipe(map(snapshots => {
		  return snapshots;
	  }));
  }

  getIdisk(uuid: string) {
	  return this.http.get<any>(`${environment.apiUrl}/` + 'infrastructure/getidisk/' + uuid).pipe(map(idisk => {
		  return idisk;
    }));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Comment, DashboardSettings, Filter, IhmSettings, IhmSettingsTarget } from '@app/model';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings: DashboardSettings = {
    user: '',
    isT1: false,
    isT1Disabled: false,
    isT2: false,
    isT2Disabled: false,
    isT3: false,
    isT3Disabled: false,
    isT4: false,
    isT4Disabled: false,
    isT5: false,
    isT5Disabled: false,
    isT6: false,
    isT6Disabled: false,
    isT7: false,
    isT7Disabled: false,
    isT8: false,
    isT8Disabled: false,
    isT9: false,
    isT9Disabled: false
  };

  comment: Comment = {
    ID: 0,
    USER: 'not registered',
    IDENTIFIER: 'not registered',
    COMMENT: 'not registered',
    OWNER: 'not registered',
    ENV: 'not registered',
    CREATE_DATE: 'not registered',
    END_DATE: 'not registered'
  };

  filter: Filter = {
    begin: 0,
    end: 0
  };

  // Default values
  capa_settings: IhmSettings = {
    user: '',
    filter: '',
    target: 'capacity',
    p1: '100',
    p2: '4',
    p3: '100',
    p4: '5',
    p5: '0',
    p6: '0',
    p7: 'template1',
    p8: '4',
    p9: '4096',
    p10: '102400',
    p11: 'template2',
    p12: '4',
    p13: '4096',
    p14: '102400',
    p15: 'template3',
    p16: '4',
    p17: '4096',
    p18: '102400',
    p19: '0',
    p20: '0',
  };

  reco_settings: IhmSettings = {
    user: '',
    filter: '',
    target: 'recommendation',
    p1: '2',
    p2: '10',
    p3: '99',
    p4: undefined,
    p5: undefined,
    p6: undefined,
    p7: undefined,
    p8: undefined,
    p9: undefined,
    p10: undefined,
    p11: undefined,
    p12: undefined,
    p13: undefined,
    p14: undefined,
    p15: undefined,
    p16: undefined,
    p17: undefined,
    p18: undefined,
    p19: undefined,
    p20: undefined,
  };

  god_settings: IhmSettings = {
    user: '',
    filter: '',
    target: 'god',
    p1: 'AVG_RAM_USAGE_INTRA',
    p2: 'AVG_CPU_USAGE_INTRA',
    p3: 'AVG_DISK_IO',
    p4: 'AVG_NET_IO',
    p5: undefined,
    p6: undefined,
    p7: undefined,
    p8: undefined,
    p9: undefined,
    p10: undefined,
    p11: undefined,
    p12: undefined,
    p13: undefined,
    p14: undefined,
    p15: undefined,
    p16: undefined,
    p17: undefined,
    p18: undefined,
    p19: undefined,
    p20: undefined,
  };

  currentCapa: BehaviorSubject<IhmSettings> = new BehaviorSubject<IhmSettings>(this.capa_settings);
  public capa: Observable<IhmSettings> = this.currentCapa.asObservable();

  currentReco: BehaviorSubject<IhmSettings> = new BehaviorSubject<IhmSettings>(this.reco_settings);
  public reco: Observable<IhmSettings> = this.currentReco.asObservable();

  currentGraphOnDemandSettings: BehaviorSubject<IhmSettings> = new BehaviorSubject<IhmSettings>(this.god_settings);
  public god: Observable<IhmSettings> = this.currentGraphOnDemandSettings.asObservable();

  currentDS: BehaviorSubject<DashboardSettings> = new BehaviorSubject<DashboardSettings>(this.settings);
  public ds: Observable<DashboardSettings> = this.currentDS.asObservable();

  currentComment: BehaviorSubject<Comment> = new BehaviorSubject<Comment>(this.comment);
  public cmt: Observable<Comment> = this.currentComment.asObservable();

  currentFilter: BehaviorSubject<Filter> = new BehaviorSubject<Filter>(this.filter);
  public ft: Observable<Filter> = this.currentFilter.asObservable();


  /**
   * 
   */
  constructor(private http: HttpClient) {
  }


  /**
  * DASHBOARD
  */

  public get currentSettings(): DashboardSettings {
	  return this.currentDS.value;
  }

  getUserSettings(login: string) {
    return this.http.get<DashboardSettings>(`${environment.apiUrl}/` + 'management/ds/' + login).pipe(map(ds => {
      localStorage.setItem('ds', JSON.stringify(ds));
      this.currentDS.next(ds);
      return ds;
    }));
  }

  updateSettings(login: string, counter: string, val: boolean) {
    switch(counter) {
      case 'isT1':
        this.currentDS.value.isT1 = val;
        break;
      case 'isT2':
        this.currentDS.value.isT2 = val;
        break;
      case 'isT3':
        this.currentDS.value.isT3 = val;
        break;
      case 'isT4':
        this.currentDS.value.isT4 = val;
        break;
      case 'isT5':
        this.currentDS.value.isT5 = val;
        break;
      case 'isT6':
        this.currentDS.value.isT6 = val;
        break;
      default:
        break;
    }

    return this.http.put<DashboardSettings>(`${environment.apiUrl}/` + 'management/ds/' + login + '&' + counter + '&' + val, [login, counter, val]);
  }

  /**
  * COMMENT
  */

  getUserComment(user: string, uuid: string) {
    return this.http.get<Comment>(`${environment.apiUrl}/` + 'management/comment/' + uuid).pipe(map(comment => {
      localStorage.setItem('comment', JSON.stringify(comment));
      this.currentComment.next(comment);
      return comment;
    }));
  }

  getMaxIdComment() {
    return this.http.get<any>(`${environment.apiUrl}/` + 'management/commentmaxid').pipe(map(id => {
      return id;
    }));
  }

  updateComment(id: number, uuid: string, item: string, val: string) {
	  return this.http.put<Comment>(`${environment.apiUrl}/` + 'management/comment/' + id + '&' + uuid + '&' + item + '&' + val, [id, uuid, item, val]);
  }

  
  /**
  * FILTER
  */

  getUserFilter(user: string) {
    return this.http.get<Filter>(`${environment.apiUrl}/` + 'management/filter/' + user).pipe(map(filter => {
      localStorage.setItem('filter', JSON.stringify(filter));
      this.currentFilter.next(filter);
      return filter;
    }));
  }


  /**
  * IHM_SETTINGS
  */

  /**
   * Reload settings from localStorage or from database
   * NOTE : do not store default values into localStorage, only user settings from database !
   */
  reload(user: string, filter:string,  target:IhmSettingsTarget, forceDB: boolean): void {
    if(localStorage.getItem(target) == undefined || forceDB) {
      this.getIhmSettings(user, filter, target).subscribe();
    } else {
      switch(target) {
        case IhmSettingsTarget.CAPACITY:
          this.currentCapa.next(JSON.parse(localStorage.getItem(target)));
          break;
        case IhmSettingsTarget.RECOMMENDATION:
          this.currentReco.next(JSON.parse(localStorage.getItem(target)));
          break;
        case IhmSettingsTarget.GOD:
          this.currentGraphOnDemandSettings.next(JSON.parse(localStorage.getItem(target)));
          break;
        default:
          break;
      }
      
    }
  }

  /**
   * Get settings from database and store them into localStorage
   */
  private getIhmSettings(user: string, filter:string, target:IhmSettingsTarget) {
    return this.http.get<IhmSettings>(`${environment.apiUrl}/` + 'management/getihmsettings/' + user + '&' + filter + '&' + target).pipe(map(settings => {
      localStorage.setItem(target, JSON.stringify(settings));
      switch(target) {
        case IhmSettingsTarget.CAPACITY:
          this.currentCapa.next(settings);
          break;
        case IhmSettingsTarget.RECOMMENDATION:
          this.currentReco.next(settings);
          break;
        case IhmSettingsTarget.GOD:
          this.currentGraphOnDemandSettings.next(settings);
          break;
        default:
          break;
      }
    }));
  }

  /**
   * Save settings to database
   */
  saveIhmSettings(settings: IhmSettings): void {
    const replacer = (key, value) => (value == null) ? undefined : value;

    this.http.post<IhmSettings>(`${environment.apiUrl}/` + 'management/updateihmsettings/', JSON.parse(JSON.stringify(settings,replacer))).subscribe(
      success => {
        // Force get settings from database & notify settings changes
        let keys = Object.keys(IhmSettingsTarget).filter(x => IhmSettingsTarget[x] == settings.target);
        if(keys.length > 0)
          this.getIhmSettings(settings.user, settings.filter, IhmSettingsTarget[keys[0]]).subscribe();
      },
      error => {
        if(error != null)
          console.log(error);
      }
    );  
  }

  /**
   * Get time zone
   */
  getTimeZone() {
	return this.http.get<any>(`${environment.apiUrl}/` + 'management/timezone').pipe(map(tz => {
                return tz;
        }));
  }

  /**
   * Remove all local storage
   */
  removeInfo(): void {
    localStorage.removeItem('comment');
    localStorage.removeItem('filter');
    localStorage.removeItem('ds');
    localStorage.removeItem(IhmSettingsTarget.CAPACITY);
    localStorage.removeItem(IhmSettingsTarget.RECOMMENDATION);
    localStorage.removeItem(IhmSettingsTarget.GOD);
  }

  updateRetention(user: string, val: number) {
	  return this.http.put<any>(`${environment.apiUrl}/` + 'management/retention/' + user + '&' + val, [user, val]);
  }
  
}

import {Injectable} from '@angular/core';
import {CloudService} from './cloud.service';

@Injectable({
	providedIn: 'root'
})
export class CloudimpactService {

	constructor(private cloudService: CloudService) {
	}

	computeDashboard(vmsRaw, vmConsover, cloudCarbonData, cloudPricingData, region, period, billingType, mapOS, exchangeRates, currencyOption) {
		// Retrieves the average cpu charge from the vmConsover data and merges it with the VMs data.
		for (let vm of vmConsover) {
			let uuid = vm['uuid'];
			let associatedVm = vmsRaw[uuid];
			associatedVm.cpuCharge = vm.avgcpu;
		}

		// Formats the VMs data and select only the emissions for the selected region to save compute time.
		let localVms = Object.entries(vmsRaw).map(([k, v]) => Object.assign(v, {'uuid': k}));

		// Output initialization.
		let costs = 0;
		let emissions = 0;

		// Cloud pricing initialization.
		let computeTypes = cloudPricingData.computeTypes;
		let computePrices = cloudPricingData.computePrices[region];
		let storageTypes = cloudPricingData.storageTypes;
		let storagePrices = cloudPricingData.storagePrices[region];
		let billingTypes = cloudPricingData.billingTypes;
		let defaultOs = cloudPricingData.defaultOs;

		let exchangeRate = exchangeRates.currency[currencyOption].rate;
		let pircingPeriodFactor = period == "month" ? 1.0 / 12 : 1;

		let sortedInstanceTypeCache = {};

		// Cloud carbon initialization.
		let regionalEmissions = 0;
		let carbonPeriodFactor = 0;

		if (cloudCarbonData !== null) {
			regionalEmissions = cloudCarbonData[region]['instance_types'];
			carbonPeriodFactor = period == 'month' ? 730 : 8760;

			// Filtering cloud pricing data to remove instances types not included in cloud carbon data.
			let avaliableInstancesTypes = Object.keys(cloudCarbonData[Object.keys(cloudCarbonData)[0]]['instance_types']);
			cloudPricingData.computeTypes = Object.fromEntries(Object.entries(cloudPricingData.computeTypes).filter(([instanceType, data]) => avaliableInstancesTypes.indexOf(instanceType) !== -1));
		}

		let sortedInstanceTypesCache = {};

		// Computes both the costs and the emissions.
		for (let vm of localVms) {
			// Cloud pricing
			let computeMatch = this.cloudService.matchComputeOffering(vm, computePrices, mapOS, sortedInstanceTypeCache, billingType, computeTypes, defaultOs, billingTypes);
			let storageMatch = this.cloudService.matchStorageOffering(vm, storageTypes, storagePrices, region);

			if (computeMatch["price"])
				costs += computeMatch["price"] / billingTypes[billingType].converterFactor * exchangeRate * pircingPeriodFactor;

			if (storageMatch["price"])
				costs += storageMatch["price"] / billingTypes[billingType].converterFactor * exchangeRate * pircingPeriodFactor;

			// Cloud carbon
			if (cloudCarbonData !== null) {
				let instanceMatch = this.cloudService.instanceMatch(vm, region, cloudPricingData, sortedInstanceTypesCache);
				let instance = instanceMatch['instanceTypeKey'];

				if (instance !== undefined) {
					let instanceEmissions = regionalEmissions[instance]['cpu_architectures'][regionalEmissions[instance]['lowest_cpu_architecture']];
					let cpuEmissionMin = instanceEmissions['cpu_co2_min'];
					let cpuEmissionMax = instanceEmissions['cpu_co2_max'];
					let greyEmission = instanceEmissions['grey_emission'];
					let memoryEmission = instanceEmissions['memory_co2'];
					let storageEmission = instanceEmissions['storage_co2'];

					let cpuCharge = vm['cpuCharge'] === undefined ? 0 : vm['cpuCharge'] / 100;

					let cpuEmission = cpuEmissionMin + (cpuEmissionMax - cpuEmissionMin) * cpuCharge;
					emissions += (cpuEmission + memoryEmission + storageEmission + greyEmission) * carbonPeriodFactor;
				}
			}
		}

		return {
			"costs": costs,
			"emissions": emissions,
		};
	}
}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import * as moment from 'moment';

import {trigger, state, style, animate, transition} from '@angular/animations';

import { AccountService, JsonloaderService, MeasurementService, ShareService } from '@app/services';

import { JSONTarget, Message, ThresholdStats, Trouble, User } from '@app/model';


@Component({
  selector: 'app-troubleshootingcard',
  templateUrl: './troubleshootingcard.component.html',
  styleUrls: ['./troubleshootingcard.component.css'],
  animations: [
          trigger('rotatedState', [
            state('default', style({ transform: 'rotate( 0 )' })),
	    state('rotated', style({ transform: '{{ts_overall_arrow_o}}' }), {params: {ts_overall_arrow_o: 'rotate( 0deg )'}}),
	    state('rotatedCpu', style({ transform: '{{ts_cpu_arrow_o}}' }), {params: {ts_cpu_arrow_o: 'rotate( 0deg )'}}),
	    state('rotatedDisk', style({ transform: '{{ts_disk_arrow_o}}' }), {params: {ts_disk_arrow_o: 'rotate( 0deg )'}}),
	    state('rotatedNet', style({ transform: '{{ts_net_arrow_o}}' }), {params: {ts_net_arrow_o: 'rotate( 0deg )'}}),
	    state('rotatedRam', style({ transform: '{{ts_ram_arrow_o}}' }), {params: {ts_ram_arrow_o: 'rotate( 0deg )'}}),
	    state('rotatedRamswp', style({ transform: '{{ts_ramswp_arrow_o}}' }), {params: {ts_ramswp_arrow_o: 'rotate( 0deg )'}}),
	    state('rotatedRambal', style({ transform: '{{ts_rambal_arrow_o}}' }), {params: {ts_rambal_arrow_o: 'rotate( 0deg )'}}),
	])
  ]
})
export class TroubleshootingcardComponent implements OnInit {

  message: Message;

  math = Math;

  data_ts: any = [];

  ts_selected: Trouble;

  ts_overall_url: string = 'assets/img/arrow_F_G.png';

  ts_overall_arrow_trigger: string = 'default';

  ts_overall_arrow_o: string = 'rotate( -0deg )';

  isTsDetail: boolean = false;

  isTsCpuDetail: boolean = false;

  isTsDiskDetail: boolean = false;

  isTsNetDetail: boolean = false;

  isTsRamDetail: boolean = false;

  ts_cpu_url: string = 'assets/img/arrow_F_G.png';

  ts_cpu_arrow_trigger: string = 'default';

  ts_cpu_arrow_o: string = 'rotate( -0deg )';

  ts_disk_url: string = 'assets/img/arrow_F_G.png';

  ts_disk_arrow_trigger: string = 'default';

  ts_disk_arrow_o: string = 'rotate( -0deg )';

  ts_net_url: string = 'assets/img/arrow_F_G.png';

  ts_net_arrow_trigger: string = 'default';

  ts_net_arrow_o: string = 'rotate( -0deg )';

  ts_ram_url: string = 'assets/img/arrow_F_G.png';

  ts_ram_arrow_trigger: string = 'default';

  ts_ram_arrow_o: string = 'rotate( -0deg )';

  ts_ramswp_url: string = 'assets/img/arrow_F_G.png';

  ts_ramswp_arrow_trigger: string = 'default';

  ts_ramswp_arrow_o: string = 'rotate( -0deg )';

  ts_rambal_url: string = 'assets/img/arrow_F_G.png';

  ts_rambal_arrow_trigger: string = 'default';

  ts_rambal_arrow_o: string = 'rotate( -0deg )';

  threshold_pts: ThresholdStats = {
    uuid: '',
    pts_cpu_total_orange: 0,
    pts_cpu_total_red: 0,
    pts_cpu_crsr_orange: 0,
    pts_cpu_crsr_red: 0,
    pts_cpu_rdy_orange: 0,
    pts_cpu_rdy_red: 0,
    pts_cpu_cstp_orange: 0,
    pts_cpu_cstp_red: 0,
    pts_ram_orange: 0,
    pts_ram_red: 0,
    pts_disk_total_orange: 0,
    pts_disk_total_red: 0,
    pts_disk_lat_orange: 0,
    pts_disk_lat_red: 0,
    pts_disk_cmd_orange: 0,
    pts_disk_cmd_red: 0,
    pts_net_orange: 0,
    pts_net_red: 0
  };

  private start: number = 0;

  currentUser: User;


  constructor(
	private router: Router,
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private measurement_svc: MeasurementService,
	private message_svc: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.message_svc.currentMessage.subscribe(message => this.message = message);
	if(this.message.currentType == "VM") {
		this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.TROUBLE_MAP).subscribe(
			data => {
				this.data_ts = data;
				this.buildTroubleshooting();
      			}
		);
	} else {
		this.data_ts = this.json_svc.json.hostSynthesis;
		this.buildTroubleshooting();
	}

	const start_str: string = moment.unix(this.message.maxTimeFilter/1000).subtract(30, 'days').startOf('day').format("x");
	this.start = +start_str;
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  setTroubleshootingDetails(): void {

	this.isTsDetail = true;
	//CPU RESOURCE
	if(this.ts_selected.color_cpu == "ORANGE")
		this.ts_cpu_url = 'assets/img/arrow_F_O.png';
	else if(this.ts_selected.color_cpu == "RED")
		this.ts_cpu_url = 'assets/img/arrow_F_R.png';

	this.ts_cpu_arrow_o = 'rotate( ' + this.ts_selected.arrow_o_c + 'deg ) scale(0.8)';
	this.ts_cpu_arrow_trigger = 'rotatedCpu';

	//DISK RESOURCE
	if(this.ts_selected.color_disk == "ORANGE")
		this.ts_disk_url = 'assets/img/arrow_F_O.png';
	else if(this.ts_selected.color_disk == "RED")
		this.ts_disk_url = 'assets/img/arrow_F_R.png';

	this.ts_disk_arrow_o = 'rotate( ' + this.ts_selected.arrow_o_d + 'deg ) scale(0.8)';
	this.ts_disk_arrow_trigger = 'rotatedDisk';

	//NET RESOURCE
	if(this.ts_selected.color_net == "ORANGE")
		this.ts_net_url = 'assets/img/arrow_F_O.png';
	else if(this.ts_selected.color_net == "RED")
		this.ts_net_url = 'assets/img/arrow_F_R.png';

	this.ts_net_arrow_o = 'rotate( ' + this.ts_selected.arrow_o_n + 'deg ) scale(0.8)';
	this.ts_net_arrow_trigger = 'rotatedNet';

	//RAM RESOURCE
	if(this.ts_selected.color_ram == "ORANGE")
		this.ts_ram_url = 'assets/img/arrow_F_O.png';
	else if(this.ts_selected.color_ram == "RED")
		this.ts_ram_url = 'assets/img/arrow_F_R.png';

	this.ts_ram_arrow_o = 'rotate( ' + this.ts_selected.arrow_o_r + 'deg ) scale(0.8)';
	this.ts_ram_arrow_trigger = 'rotatedRam';
  }

  setTroubleshootingCpuDetails(): void {

	this.isTsNetDetail = false;
	this.isTsDiskDetail = false;
	this.isTsRamDetail = false;
	this.isTsCpuDetail = true;

	this.measurement_svc.getThreshold(this.message.currentUuid, this.start/1000, this.message.maxTimeFilter/1000, 'cpu', this.message.currentType).pipe(first()).subscribe(
		data => {
			this.threshold_pts.pts_cpu_crsr_orange = data.pts_orange;
			this.threshold_pts.pts_cpu_crsr_red = data.pts_red;
                },
		error=> {
			console.log('fail to get threshold');
                }
	);
	this.measurement_svc.getThreshold(this.message.currentUuid, this.start/1000, this.message.maxTimeFilter/1000, 'cpurdy', this.message.currentType).pipe(first()).subscribe(
		data => {
			this.threshold_pts.pts_cpu_rdy_orange = data.pts_orange;
			this.threshold_pts.pts_cpu_rdy_red = data.pts_red;
                },
		error=> {
			console.log('fail to get threshold');
                }
	);
	this.measurement_svc.getThreshold(this.message.currentUuid, this.start/1000, this.message.maxTimeFilter/1000, 'cpucstp', this.message.currentType).pipe(first()).subscribe(
		data => {
			this.threshold_pts.pts_cpu_cstp_orange = data.pts_orange;
			this.threshold_pts.pts_cpu_cstp_red = data.pts_red;
                },
		error=> {
			console.log('fail to get threshold');
                }
	);
  }

  setTroubleshootingDiskDetails(): void {

	this.isTsCpuDetail = false;
	this.isTsRamDetail = false;
	this.isTsNetDetail = false;
	this.isTsDiskDetail = true;
	this.measurement_svc.getThreshold(this.message.currentUuid, this.start/1000, this.message.maxTimeFilter/1000, 'diskcmd', this.message.currentType).pipe(first()).subscribe(
		data => {
			this.threshold_pts.pts_disk_cmd_orange = data.pts_orange;
			this.threshold_pts.pts_disk_cmd_red = data.pts_red;
                },
		error=> {
			console.log('fail to get threshold');
                }
	);
	this.measurement_svc.getThreshold(this.message.currentUuid, this.start/1000, this.message.maxTimeFilter/1000, 'disklat', this.message.currentType).pipe(first()).subscribe(
		data => {
			this.threshold_pts.pts_disk_lat_orange = data.pts_orange;
			this.threshold_pts.pts_disk_lat_red = data.pts_red;
                },
		error=> {
			console.log('fail to get threshold');
                }
	);
  }

  setTroubleshootingNetDetails(): void {

	this.isTsCpuDetail = false;
	this.isTsDiskDetail = false;
	this.isTsRamDetail = false;
	this.isTsNetDetail = true;
	this.measurement_svc.getThreshold(this.message.currentUuid, this.start/1000, this.message.maxTimeFilter/1000, 'net', this.message.currentType).pipe(first()).subscribe(
		data => {
			this.threshold_pts.pts_net_orange = data.pts_orange;
			this.threshold_pts.pts_net_red = data.pts_red;
                },
		error=> {
			console.log('fail to get threshold');
                }
	);
  }

  setTroubleshootingRamDetails(): void {

	this.isTsCpuDetail = false;
	this.isTsDiskDetail = false;
	this.isTsNetDetail = false;
	this.isTsRamDetail = true;
	this.measurement_svc.getThreshold(this.message.currentUuid, this.start/1000, this.message.maxTimeFilter/1000, 'ram', this.message.currentType).pipe(first()).subscribe(
		data => {
			this.threshold_pts.pts_ram_orange = data.pts_orange;
			this.threshold_pts.pts_ram_red = data.pts_red;
                },
		error=> {
			console.log('fail to get threshold');
                }
	);

	//RAM SWAPPED RESOURCE
	if(this.ts_selected.color_ram_swp == "ORANGE")
		this.ts_ramswp_url = 'assets/img/arrow_F_O.png';
	else if(this.ts_selected.color_ram_swp == "RED")
		this.ts_ramswp_url = 'assets/img/arrow_F_R.png';

	this.ts_ramswp_arrow_o = 'rotate( ' + this.ts_selected.arrow_o_ram_swp + 'deg ) scale(0.4)';
	this.ts_ramswp_arrow_trigger = 'rotatedRamswp';

	//RAM BALLOON RESOURCE
	if(this.ts_selected.color_ram_bal == "ORANGE")
		this.ts_rambal_url = 'assets/img/arrow_F_O.png';
	else if(this.ts_selected.color_ram_bal == "RED")
		this.ts_rambal_url = 'assets/img/arrow_F_R.png';

	this.ts_rambal_arrow_o = 'rotate( ' + this.ts_selected.arrow_o_ram_bal + 'deg ) scale(0.4)';
	this.ts_rambal_arrow_trigger = 'rotatedRambal';
  }

  callGraph(): void {

	if(this.message.currentUuid != '' && this.currentUser.isMenuGod)
		this.router.navigate([`/god/resource/${this.message.currentUuid}`]);

  }

  private buildTroubleshooting(): void {

	this.isTsDetail = false;
	if(this.message != null) {
		if(this.message.currentUuid != '') {
			this.ts_selected = this.getElementTrouble(this.message.currentUuid);
			if(this.ts_selected != undefined)
				this.setTroubleshootingDetails();
		}
	}
  }

  private getElementTrouble(uuid: string): Trouble {
	if(this.message.currentType == "VM")
		return this.data_ts.find(ts => ts.id === uuid);
	else
		return this.data_ts.find(ts => ts.uuid === uuid);
  }
}

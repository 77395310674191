<div class="clr-row" *ngIf="message.creditSelected == 'credits'">
	<div class="card">
		<div class="card-header">
			<span style="color:grey;font-size:26px;">
				<clr-icon shape="cpu"></clr-icon>
				CPU CREDITS
			</span>
			<span style="float: right;" ngClass="addLink" (click)="message.creditSelected = ''">
				<clr-icon shape="times" size="24"></clr-icon>
			</span>
			<span style="margin-left:100px;" class="label label-info">
				Click on a state to list the instances
			</span>
                </div>
		<div class="card-block">
			<highcharts-chart
				[Highcharts] = "highcharts"
				[options] = "chartOptions"
				[constructorType]="'chart'"
				style = "width: 100%;display: block;" *ngIf="isReady">
			</highcharts-chart>
		</div>
	</div>
	<div class="card" *ngIf="creditSelected != ''">
                <div class="card-header">
                        <span style="color:grey;font-size:26px;">
				<clr-icon shape="cpu"></clr-icon>
				{{creditSelected}} credits usage
			</span>
                </div>
                <div class="card-block">
			<clr-datagrid *ngIf="creditSelected == 'overused'">
				<clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'surplus'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Surplus credits charged</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'surplusCostDay'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Cost surplus credits charged</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'state'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'runTime'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Running time (in the month)</ng-container></clr-dg-column>
				<clr-dg-row *clrDgItems="let instance of instances" >
					<clr-dg-cell>{{instance.id}}</clr-dg-cell>
					<clr-dg-cell>{{instance.name}}</clr-dg-cell>
					<clr-dg-cell>{{instance.surplus.toFixed(2)}}</clr-dg-cell>
					<clr-dg-cell>{{instance.surplusCostDay.toFixed(4)}}</clr-dg-cell>
					<clr-dg-cell>{{instance.state}}</clr-dg-cell>
					<clr-dg-cell>{{instance.runTime}} ({{instance.unitTime}})</clr-dg-cell>
				</clr-dg-row>
				<clr-dg-footer>
					<clr-dg-pagination #pagination [clrDgPageSize]="10">
						<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
							entries per page
						</clr-dg-page-size>
						{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
					</clr-dg-pagination>
				</clr-dg-footer>
                        </clr-datagrid>
			<clr-datagrid *ngIf="creditSelected == 'normal'">
				<clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'usageRate'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Usage rate (%)</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'state'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'runTime'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Running time (in the month)</ng-container></clr-dg-column>
				<clr-dg-row *clrDgItems="let instance of instances" >
					<clr-dg-cell>{{instance.id}}</clr-dg-cell>
					<clr-dg-cell>{{instance.name}}</clr-dg-cell>
					<clr-dg-cell>{{instance.usageRate}}</clr-dg-cell>
					<clr-dg-cell>{{instance.state}}</clr-dg-cell>
					<clr-dg-cell>{{instance.runTime}} ({{instance.unitTime}})</clr-dg-cell>
				</clr-dg-row>
				<clr-dg-footer>
					<clr-dg-pagination #pagination [clrDgPageSize]="10">
						<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
							entries per page
						</clr-dg-page-size>
						{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
					</clr-dg-pagination>
				</clr-dg-footer>
                        </clr-datagrid>
			<clr-datagrid *ngIf="creditSelected == 'underused'">
				<clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'usageRate'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Usage rate (%)</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'state'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'runTime'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Running time (in the month)</ng-container></clr-dg-column>
				<clr-dg-row *clrDgItems="let instance of instances" >
					<clr-dg-cell>{{instance.id}}</clr-dg-cell>
					<clr-dg-cell>{{instance.name}}</clr-dg-cell>
					<clr-dg-cell>{{instance.usageRate}}</clr-dg-cell>
					<clr-dg-cell>{{instance.state}}</clr-dg-cell>
					<clr-dg-cell>{{instance.runTime}} ({{instance.unitTime}})</clr-dg-cell>
				</clr-dg-row>
				<clr-dg-footer>
					<clr-dg-pagination #pagination [clrDgPageSize]="10">
						<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
							entries per page
						</clr-dg-page-size>
						{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
					</clr-dg-pagination>
				</clr-dg-footer>
                        </clr-datagrid>
		</div>
	</div>
</div>

import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';

import { AccountService, ShareService } from '@app/services';

import { Message } from '@app/model';

import { UsermgtrootComponent } from '@app/usermgt/usermgtroot/usermgtroot.component';
import { UsermgtrootDirective } from '@app/directives/usermgtroot.directive';

import { RolemgtComponent } from '@app/usermgt/rolemgt/rolemgt.component';
import { RolemgtDirective } from '@app/directives/rolemgt.directive';

import { UsermgtnorootComponent } from '@app/usermgt/usermgtnoroot/usermgtnoroot.component';
import { UsermgtnorootDirective } from '@app/directives/usermgtnoroot.directive';


@Component({
  selector: 'app-usermgt',
  templateUrl: './usermgt.component.html',
  styleUrls: ['./usermgt.component.css']
})
export class UsermgtComponent implements AfterViewInit, OnInit {

  isDcscope = true;
  isCo2scope = false;

  @ViewChild(UsermgtrootDirective) addUsermgtroot: UsermgtrootDirective;
  @ViewChild(RolemgtDirective) addRolemgt: RolemgtDirective;
  @ViewChild(UsermgtnorootDirective) addUsermgtnoroot: UsermgtnorootDirective;

  message: Message;

  isRoot: boolean = true;

  private currentUser;

  constructor(
	public componentFactoryResolver: ComponentFactoryResolver,
	private authentication_svc: AccountService,
	private data: ShareService,
	private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);

	this.data.currentMessage.subscribe(message => this.message = message);

	this.isCo2scope = this.message.isCO2Scope;
	this.isDcscope = this.message.isDCScope;

  }

  ngAfterViewInit(): void {

	if(this.currentUser.login == "root") {
		this.isRoot = true;
		setTimeout(() => this.loadRoot(), 100);
	} else {
		this.isRoot = false;
		setTimeout(() => this.loadNoRoot(), 100);
	}
  }

  ngAfterViewChecked(): void {
        this.cd.detectChanges();
  }

  callUserMgt(): void {
	setTimeout(() => this.loadRoot(), 100);
  }

  callRoleMgt(): void {
	setTimeout(() => this.loadRole(), 100);
  }

   callAliasMgt(): void {
	setTimeout(() => this.loadAlias(), 100);
  }

  private loadRoot(): void {

	if(this.addUsermgtroot != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UsermgtrootComponent);
		const viewContainerRef = this.addUsermgtroot.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadNoRoot(): void {

	if(this.addUsermgtnoroot != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UsermgtnorootComponent);
		const viewContainerRef = this.addUsermgtnoroot.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadRole(): void {

	if(this.addRolemgt != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RolemgtComponent);
		const viewContainerRef = this.addRolemgt.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

  private loadAlias(): void {

	if(this.addUsermgtroot != undefined) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UsermgtrootComponent);
		const viewContainerRef = this.addUsermgtroot.viewContainerRef;
	  	viewContainerRef.clear();
		const componentRef = viewContainerRef.createComponent(componentFactory);
	}
  }

}

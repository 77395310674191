
<div class="widget-content" id="div-settings-wip">
    <div class="clr-row">
        <div class="clr-col-lg-5 clr-col-md-5 clr-col-12">
            <clr-control-helper>1- select servers where new settings must be apply</clr-control-helper>
            <ng-template load-selection-tree></ng-template>
        </div>
    

        <div class="clr-col-lg-4 clr-col-md-4 clr-col-12">
            <clr-control-helper>2- update settings</clr-control-helper>
            <div>
                <p><b></b></p>
                <table cellspacing="0px" width="100%">
                    <tr *ngIf="target == indicatortree">
                        <td style="vertical-align:bottom"><input type="checkbox" clrCheckbox [(ngModel)]="check_purchase" (ngModelChange)="checkSettings();"/></td>
                        <td style="vertical-align:bottom">
                            <label for="settings.host.age" class="clr-control-label">Date of purchase&nbsp;&nbsp;</label>
                        </td>
                        <td>
                            <div class="clr-form-control">
                                <div [ngClass]="(check_purchase && value_purchase <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                    <div class="clr-input-wrapper">
                                        <input class="clr-input" id="settings.host.year" type="number" [(ngModel)]="value_purchase" style="width:4em;text-align:right" (ngModelChange)="checkSettings();">
                                        <clr-icon class="clr-validate-icon" *ngIf="(check_purchase && value_purchase <= 0)" shape="exclamation-circle"></clr-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="target == indicatortree">
                        <td style="vertical-align:bottom"><input type="checkbox" clrCheckbox [(ngModel)]="check_amortization" (ngModelChange)="checkSettings();"/></td>
                        <td style="vertical-align:bottom">
                            <label for="settings.host.amortization" class="clr-control-label">Amortization (year)&nbsp;&nbsp;</label>
                        </td>
                        <td>
                            <div class="clr-form-control">
                                <div [ngClass]="(check_amortization && value_amortization < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                    <div class="clr-input-wrapper">
                                        <input class="clr-input" id="settings.host.amortization" type="number" [(ngModel)]="value_amortization" style="width:4em;text-align:right" (ngModelChange)="checkSettings();">
                                        <clr-icon class="clr-validate-icon" *ngIf="(check_amortization && value_amortization < 0)" shape="exclamation-circle"></clr-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="target == indicatortree">
                        <td style="vertical-align:bottom"><input type="checkbox" clrCheckbox [(ngModel)]="check_embodied" (ngModelChange)="checkSettings();"/></td>
                        <td style="vertical-align:bottom">
                            <label for="settings.host.embodied" class="clr-control-label">Embodied carbon (kg CO2eq)&nbsp;&nbsp;</label>
                        </td>
                        <td>
                            <div class="clr-form-control">
                                <div [ngClass]="(check_embodied && value_embodied <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                    <div class="clr-input-wrapper">
                                        <input class="clr-input" id="settings.host.embodied" type="number" [(ngModel)]="value_embodied" style="width:4em;text-align:right" (ngModelChange)="checkSettings();">
                                        <clr-icon class="clr-validate-icon" *ngIf="(check_embodied && value_embodied <= 0)" shape="exclamation-circle"></clr-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="target == indicatortree">
                        <td style="vertical-align:bottom"><input type="checkbox" clrCheckbox [(ngModel)]="check_co2" (ngModelChange)="checkSettings();"/></td>
                        <td style="vertical-align:bottom">
                            <label for="settings.host.co2" class="clr-control-label">Emission factor (kg CO2eq/kWh)&nbsp;&nbsp;</label>
                        </td>
                        <td>
                            <div class="clr-form-control">
                                <div [ngClass]="(check_co2 && value_co2 < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                    <div class="clr-input-wrapper">
                                        <input class="clr-input" id="settings.host.co2" type="number" [(ngModel)]="value_co2" style="width:4em;text-align:right" (ngModelChange)="checkSettings();">
                                        <clr-icon class="clr-validate-icon" *ngIf="(check_co2 && value_co2 < 0)" shape="exclamation-circle"></clr-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="target == indicatortree">
                        <td style="vertical-align:bottom"><input type="checkbox" clrCheckbox [(ngModel)]="check_cost" (ngModelChange)="checkSettings();"/></td>
                        <td style="vertical-align:bottom">
                            <label for="settings.host.cost" class="clr-control-label">Energy cost ({{globalCurrency}} / kWh)&nbsp;&nbsp;</label>
                        </td>
                        <td>
                            <div class="clr-form-control">
                                <div [ngClass]="(check_cost && value_cost < 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                    <div class="clr-input-wrapper">
                                        <input class="clr-input" id="settings.host.cost" type="number" [(ngModel)]="value_cost" style="width:4em;text-align:right" (ngModelChange)="checkSettings();">
                                        <clr-icon class="clr-validate-icon" *ngIf="(check_cost && value_cost < 0)" shape="exclamation-circle"></clr-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="target == indicatortree">
                        <td style="vertical-align:bottom"><input type="checkbox" clrCheckbox [(ngModel)]="check_pue" (ngModelChange)="checkSettings();"/></td>
                        <td style="vertical-align:bottom">
                            <label for="settings.host.pue" class="clr-control-label">PUE&nbsp;&nbsp;</label>
                        </td>
                        <td>
                            <div class="clr-form-control">
                                <div [ngClass]="(check_pue && value_pue <= 0) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                    <div class="clr-input-wrapper">
                                        <input class="clr-input" id="settings.host.pue" type="number" [(ngModel)]="value_pue" style="width:4em;text-align:right" (ngModelChange)="checkSettings();">
                                        <clr-icon class="clr-validate-icon" *ngIf="(check_pue && value_pue <= 0)" shape="exclamation-circle"></clr-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="target == minmaxtree">
                        <td style="vertical-align:bottom"></td>
                        <td style="vertical-align:bottom">
                            <label for="settings.host.min" class="clr-control-label">Power min (W)&nbsp;&nbsp;</label>
                        </td>
                        <td>
                            <div class="clr-form-control">
                                <div [ngClass]="(value_min < 0 || value_max < value_min) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                    <div class="clr-input-wrapper">
                                        <input class="clr-input" id="settings.host.min" type="number" [(ngModel)]="value_min" style="width:4em;text-align:right" (ngModelChange)="checkSettings();">
                                        <clr-icon class="clr-validate-icon" *ngIf="(value_min < 0 || value_max < value_min)" shape="exclamation-circle"></clr-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="target == minmaxtree">
                        <td style="vertical-align:bottom"></td>
                        <td style="vertical-align:bottom">
                            <label for="settings.host.max" class="clr-control-label">Power max (W)&nbsp;&nbsp;</label>
                        </td>
                        <td>
                            <div class="clr-form-control">
                                <div [ngClass]="(value_max < 0 || value_max < value_min) ? 'clr-control-container clr-error' : 'clr-control-container'">
                                    <div class="clr-input-wrapper">
                                        <input class="clr-input" id="settings.host.max" type="number" [(ngModel)]="value_max" style="width:4em;text-align:right" (ngModelChange)="checkSettings();">
                                        <clr-icon class="clr-validate-icon" *ngIf="(value_max < 0 || value_max < value_min)" shape="exclamation-circle"></clr-icon>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="clr-col-lg-3 clr-col-md-3 clr-col-12">
            <clr-control-helper>3- apply settings to selected servers</clr-control-helper>
            <button type="button" style="margin-top:20%;margin-left:25%" [hidden]=!apply_button class="btn btn-danger-outline btn-sm" aria-label="settings" (click)="saveTree()">
                APPLY
            </button>
        </div>


    </div>
</div>


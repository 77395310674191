import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-t6-detail]'
})
export class T6detailDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

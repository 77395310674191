<div class="clr-row" style="width: 100%">
    <div class="clr-col-11">
        <h3 style="margin: 5px;">
            Topology
            <span *ngIf="isLoading" style="margin: 0px;">
        <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
      </span>
        </h3>
    </div>
    <div class="clr-col-1">
    <span *ngIf="!isLoading" style="float: right; position: absolute;">
      <button (click)="reloadData()" class="btn {{reloadButtonColorClass}} btn-sm" style="z-index: 1; position: absolute;">reload</button>
    </span>
    </div>
</div>

<clr-accordion style="margin-top: 15px;">

    <clr-accordion-panel #dataSettingsPanel>
        <clr-accordion-title>Map data settings <span class="badge badge-danger" style="min-width: 0.25rem; height: 0.25rem; padding: 0.1rem; border-radius: .25rem;" *ngIf="this.selectedResourcesWithSearchCombobox !== null && this.selectedResourcesWithSearchCombobox.length > 0"></span></clr-accordion-title>
        <clr-accordion-content>
            <clr-tabs>
                <clr-tab>
                    <button clrTabLink>Distributed vSwitches</button>
                    <clr-tab-content *clrIfActive>
                        <clr-checkbox-container clrInline>
                            <label>Select the distributed vSwitches that should be displayed <a (click)="checkFiltersCheckbox('switches')" class="linkLabel"><span class="badge badge-info">check all</span></a> <a (click)="uncheckFiltersCheckbox('switches')" class="linkLabel"><span class="badge badge-info">uncheck all</span></a></label>
                            <clr-checkbox-wrapper *ngFor="let distribuedVSwitch of allDistribuedVSwitches">
                                <input type="checkbox" clrCheckbox name="distribuedVSwitch" value="{{distribuedVSwitch.uuid}}" [(ngModel)]="mapDataFilters.switches[distribuedVSwitch.uuid]" (change)="clickSwitchCheckbox(distribuedVSwitch.uuid)" [indeterminate]="mapDataFilters.switches[distribuedVSwitch.uuid] == 'indeterminate'"/>
                                <label>{{distribuedVSwitch.name}}</label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                    </clr-tab-content>
                </clr-tab>

                <clr-tab>
                    <button clrTabLink>Port groups</button>
                    <clr-tab-content *clrIfActive>
                        <clr-checkbox-container clrInline>
                            <label>Select the port groups that should be displayed <a (click)="checkFiltersCheckbox('portgroups')" class="linkLabel"><span class="badge badge-info">check all</span></a> <a (click)="uncheckFiltersCheckbox('portgroups')" class="linkLabel"><span class="badge badge-info">uncheck all</span></a></label>
                            <clr-checkbox-wrapper *ngFor="let portgroup of allPortgroups">
                                <input type="checkbox" clrCheckbox name="portgroup" value="{{portgroup.uuid}}" [(ngModel)]="mapDataFilters.portgroups[portgroup.uuid]" (change)="clickPortgroupCheckbox(portgroup.uuid)"/>
                                <label>{{portgroup.name}}</label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                    </clr-tab-content>
                </clr-tab>

                <clr-tab>
                    <button clrTabLink>Networks</button>
                    <clr-tab-content *clrIfActive>
                        <clr-checkbox-container clrInline>
                            <label>Select the networks that should be displayed <a (click)="checkFiltersCheckbox('networks')" class="linkLabel"><span class="badge badge-info">check all</span></a> <a (click)="uncheckFiltersCheckbox('networks')" class="linkLabel"><span class="badge badge-info">uncheck all</span></a></label>
                            <clr-checkbox-wrapper *ngFor="let network of allNetworks">
                                <input type="checkbox" clrCheckbox name="network" value="{{network.uuid}}" [(ngModel)]="mapDataFilters.networks[network.uuid]" (change)="setButtonPrimaryRed()"/>
                                <label>{{network.name}}</label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                    </clr-tab-content>
                </clr-tab>

                <clr-tab>
                    <button clrTabLink>Hosts</button>
                    <clr-tab-content *clrIfActive>

                        <clr-checkbox-container clrInline>
                            <label>Select the hosts that should be displayed <a (click)="checkFiltersCheckbox('hosts')" class="linkLabel"><span class="badge badge-info">check all</span></a> <a (click)="uncheckFiltersCheckbox('hosts')" class="linkLabel"><span class="badge badge-info">uncheck all</span></a></label>
                            <clr-checkbox-wrapper *ngFor="let host of allHosts">
                                <input type="checkbox" clrCheckbox name="host" value="{{host.uuid}}" [(ngModel)]="mapDataFilters.hosts[host.uuid]" (change)="clickHostCheckbox(host.uuid)" [indeterminate]="mapDataFilters.hosts[host.uuid] == 'indeterminate'"/>
                                <label>{{host.name}}</label>
                            </clr-checkbox-wrapper>

                        </clr-checkbox-container>
                    </clr-tab-content>
                </clr-tab>

                <clr-tab>
                    <button clrTabLink>Virtual Machines</button>
                    <clr-tab-content *clrIfActive>

                        <clr-checkbox-container clrInline>
                            <label>Select the virtual machines that should be displayed <a (click)="checkFiltersCheckbox('virtualMachines')" class="linkLabel"><span class="badge badge-info">check all</span></a> <a (click)="uncheckFiltersCheckbox('virtualMachines')" class="linkLabel"><span class="badge badge-info">uncheck all</span></a></label>
                            <clr-checkbox-wrapper *ngFor="let vm of allVms">
                                <input type="checkbox" clrCheckbox name="virtualMachine" value="{{vm.uuid}}" [(ngModel)]="mapDataFilters.virtualMachines[vm.uuid]" (change)="clickVmCheckbox(vm.uuid)" [indeterminate]="mapDataFilters.hosts[vm.uuid] == 'indeterminate'"/>
                                <label>{{vm.name}}</label>
                            </clr-checkbox-wrapper>

                        </clr-checkbox-container>
                    </clr-tab-content>
                </clr-tab>

                <clr-tab>
                    <button clrTabLink>Search</button>
                    <clr-tab-content *clrIfActive>

                        <form class="clr-form">
                            <input autocomplete="off" style="width: 90%; margin-left: 10px; margin-right: 10px; margin-top: 10px;" name="targetedResourcePattern" (keyup)="updateListTargetedResources()" [(ngModel)]="targetedResourcePattern" type="text" id="basic" placeholder="Enter pattern here" class="clr-input" />
                        </form>

                        <div class="clr-row" *ngIf="targetedResources.switches.length > 0">
                            <div class="clr-col-12">
                                <div class="card">
                                    <div class="card-header">
                                        Distributed switches
                                    </div>
                                    <div class="card-block">
                                        <span class="label label-info" *ngFor="let targetedResource of targetedResources.switches">
                                            <input (change)="clickSwitchCheckbox(targetedResource.uuid)" type="checkbox" clrCheckbox [(ngModel)]="mapDataFilters.switches[targetedResource.uuid]" [indeterminate]="mapDataFilters.switches[targetedResource.uuid] == 'indeterminate'"/>
                                            {{targetedResource.name}}
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('switches', 'check')">check all</button>
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('switches', 'uncheck')">uncheck all</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="clr-row" *ngIf="targetedResources.portgroups.length > 0">
                            <div class="clr-col-12">
                                <div class="card">
                                    <div class="card-header">
                                        Port groups
                                    </div>
                                    <div class="card-block">
                                        <span class="label label-info" *ngFor="let targetedResource of targetedResources.portgroups">
                                            <input (change)="clickPortgroupCheckbox(targetedResource.uuid)" type="checkbox" clrCheckbox [(ngModel)]="mapDataFilters.portgroups[targetedResource.uuid]"/>
                                            {{targetedResource.name}}
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('portgroups', 'check')">check all</button>
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('portgroups', 'uncheck')">uncheck all</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="clr-row" *ngIf="targetedResources.networks.length > 0">
                            <div class="clr-col-12">
                                <div class="card">
                                    <div class="card-header">
                                        Networks
                                    </div>
                                    <div class="card-block">
                                        <span class="label label-info" *ngFor="let targetedResource of targetedResources.networks">
                                            <input (change)="setButtonPrimaryRed()" type="checkbox" clrCheckbox [(ngModel)]="mapDataFilters.networks[targetedResource.uuid]"/>
                                            {{targetedResource.name}}
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('networks', 'check')">check all</button>
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('networks', 'uncheck')">uncheck all</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="clr-row" *ngIf="targetedResources.hosts.length > 0">
                            <div class="clr-col-12">
                                <div class="card">
                                    <div class="card-header">
                                       Hosts
                                    </div>
                                    <div class="card-block">
                                        <span class="label label-info" *ngFor="let targetedResource of targetedResources.hosts">
                                            <input (change)="clickHostCheckbox(targetedResource.uuid)" type="checkbox" clrCheckbox [(ngModel)]="mapDataFilters.hosts[targetedResource.uuid]" [indeterminate]="mapDataFilters.hosts[targetedResource.uuid] == 'indeterminate'"/>
                                            {{targetedResource.name}}
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('hosts', 'check')">check all</button>
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('hosts', 'uncheck')">uncheck all</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="clr-row" *ngIf="targetedResources.virtualMachines.length > 0">
                            <div class="clr-col-12">
                                <div class="card">
                                    <div class="card-header">
                                        Virtual machines
                                    </div>
                                    <div class="card-block">
                                        <span class="label label-info" *ngFor="let targetedResource of targetedResources.virtualMachines">
                                            <input (change)="clickVmCheckbox(targetedResource.uuid)" type="checkbox" clrCheckbox [(ngModel)]="mapDataFilters.virtualMachines[targetedResource.uuid]" [indeterminate]="mapDataFilters.hosts[targetedResource.uuid] == 'indeterminate'"/>
                                            {{targetedResource.name}}
                                        </span>
                                    </div>
                                    <div class="card-footer">
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('virtualMachines', 'check')">check all</button>
                                        <button class="btn btn-sm btn-link" (click)="applyActionOnTargetResources('virtualMachines', 'uncheck')">uncheck all</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </clr-tab-content>
                </clr-tab>
            </clr-tabs>

        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion>


<clr-tabs>
    <clr-tab>
        <button clrTabLink>Map</button>
        <clr-tab-content>
            <div class="clr-row" style="width: 100%">
                <div class="clr-col-2">
                    <clr-radio-container clrInline>
                        <label>Click behaviour on hosts</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="resourceMouseMode" required value="graph" [(ngModel)]="resourceMouseMode" />
                            <label>View graphs</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="resourceMouseMode" required value="showHide" [(ngModel)]="resourceMouseMode" />
                            <label>Show/Hide VMs</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>
                </div>
                <div class="clr-col-2">
                    <clr-radio-container clrInline>
                        <label>Snap circles to the border</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="snapCircles" required value="SnapCircles" [(ngModel)]="snapCirclesToTheBorder" (click)="reloadData()" />
                            <label>Activated</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="snapCircles" required value="DontSnapCircles" [(ngModel)]="snapCirclesToTheBorder" (click)="reloadData()" />
                            <label>Deactivated</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>
                </div>
                <div class="clr-col-2">
                    <clr-radio-container clrInline>
                        <label>Color blind colors</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="useColorBlind" required value="useColorBlindColors" [(ngModel)]="useColorBlindColors" (click)="reloadData()" />
                            <label>Activated</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="useColorBlind" required value="DontUseColorBlindColors" [(ngModel)]="useColorBlindColors" (click)="reloadData()" />
                            <label>Deactivated</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>
                </div>
            </div>
            <span style="position: relative; z-index: 999; top:40px; left: 10px;">
                <clr-signpost>
                    <clr-icon shape="cog"
                              class="is-solid"
                              clrSignpostTrigger>
                    </clr-icon>
                    <clr-signpost-content [clrPosition]="'bottom-right'" *clrIfOpen>
                        <label class="clr-control-label">Export:</label>
                        <div class="btn-group btn-primary btn-icon">
                            <button class="btn btn-primary btn-sm" (click)="exportGraph()">Graph</button>
                        </div>
                    </clr-signpost-content>
                </clr-signpost>
            </span>
            <span *ngIf="isLoading" style="margin: 0px; position: relative; z-index: 999; top:45px; right: 15px; float: right;">
                <clr-spinner [clrSmall]="true">Loading ...</clr-spinner>
            </span>
            <div id="divSvg" class="card" style="margin-top: 10px; width: 100%; height: 80%; min-height: 350px;"></div>
        </clr-tab-content>
    </clr-tab>
    <clr-tab *ngFor="let datacenter of this.root">
        <button clrTabLink>{{ datacenter.name }}</button>
        <clr-tab-content *clrIfActive>

            <div class="clr-row">
                <div class="card" *ngFor="let switch of datacenter.switches">
                    <div class="card-header">
                        <b>{{ switch.name }}</b> <span class="label label-info" style="float: right;">switch</span>
                    </div>
                    <div class="card-block" *ngFor="let portgroup of switch.portGroups">
                        <div class="card-title">
                            <span style="font-size: 14px;">{{ portgroup.name }}</span> <span class="label label-info" style="float: right;">portgroup</span>
                        </div>
                        <div class="card-text">
                            <clr-datagrid *ngIf="portgroup.hasNoConnectees === false">
                                <clr-dg-column [clrDgField]="'key'" [clrDgSortBy]="portKeyComparator">Port key</clr-dg-column>
                                <clr-dg-column>Resource</clr-dg-column>
                                <clr-dg-column>Interface</clr-dg-column>
                                <clr-dg-column>VLANs</clr-dg-column>

                                <clr-dg-row *clrDgItems="let item of portgroup.connecteesAsArray">
                                    <clr-dg-cell>{{item.key}}</clr-dg-cell>
                                    <clr-dg-cell>
                                        <span class="badge badge-blue" *ngIf="item.value.resourceObj !== undefined && item.value.resourceObj.type === 'vm'">{{item.value.resourceObj.name}}</span>
                                        <span class="badge" *ngIf="item.value.resourceObj !== undefined && item.value.resourceObj.type === 'host'">{{item.value.resourceObj.name}}</span>
                                        <span class="label" *ngIf="item.value.resourceObj === undefined">not available <a href="#" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm"><clr-icon shape="info-circle" size="24"></clr-icon><span class="tooltip-content">Resource {{item.value.resourceObj.id}} is not available in this filter</span></a></span>
                                    </clr-dg-cell>
                                    <clr-dg-cell>{{item.value.interface}}</clr-dg-cell>
                                    <clr-dg-cell>
                                        <span *ngFor="let vlan of item.value.vlans">
                                            <span class="label label-info" *ngIf="vlan.start !== vlan.end">{{vlan.start}} - {{vlan.end}}</span>
                                            <span class="label label-info" *ngIf="vlan.start === vlan.end && vlan.start !== 0">{{vlan.start}}</span>
                                        </span>
                                    </clr-dg-cell>
                                </clr-dg-row>
                            </clr-datagrid>
                            <div class="alert alert-warning" role="alert"  *ngIf="portgroup.hasNoConnectees === true">
                                <div class="alert-items">
                                    <div class="alert-item static">
                                        <div class="alert-icon-wrapper">
                                            <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                                        </div>
                                        <span class="alert-text">Nothing is connected to this portgroup.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button class="btn btn-sm btn-link">Footer Action 1</button>
                        <button class="btn btn-sm btn-link">Footer Action 2</button>
                    </div>
                </div>
            </div>

        </clr-tab-content>
    </clr-tab>
</clr-tabs>

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-evolution]'
})
export class EvolutionDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

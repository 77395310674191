// MODULES
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClarityModule } from "@clr/angular";
import { DataTablesModule } from 'angular-datatables';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTh, faChartPie, faDatabase, faLeaf, faHeartbeat, faCogs, faTrain, faFaucet, faSeedling, faPowerOff, faArrowUp, faBolt, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { faLinux, faWindows } from '@fortawesome/free-brands-svg-icons';
import { NgxSliderModule } from "@angular-slider/ngx-slider";

// highcharts-angular
import { HighchartsChartModule } from 'highcharts-angular';

import { NetscopeModule } from "@app/netscope/netscope.module";

// COMPONENTS
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { LoginComponent } from '@app/login/login.component';
import { DashboardComponent } from '@app/dashboard/dashboard.component';
import { DcviewComponent } from '@app/dcview/dcview.component';
import { DcviewFlowchartComponent } from '@app/dcview/dcview-flowchart/dcview-flowchart.component';
import { VmsynthesisComponent } from '@app/vmsynthesis/vmsynthesis.component';
import { T1Component } from '@app/t1/t1.component';
import { T1Directive } from '@app/directives/t1.directive';
import { T2Component } from '@app/t2/t2.component';
import { T2Directive } from '@app/directives/t2.directive';
import { T3Component } from '@app/t3/t3.component';
import { T3Directive } from '@app/directives/t3.directive';
import { T4Component } from '@app/t4/t4.component';
import { T4Directive } from '@app/directives/t4.directive';
import { T5Component } from '@app/t5/t5.component';
import { T5Directive } from '@app/directives/t5.directive';
import { T6Component } from '@app/t6/t6.component';
import { T6Directive } from '@app/directives/t6.directive';
import { MonthlycalComponent } from '@app/monthlycal/monthlycal.component';
import { MonthlycalDirective } from '@app/directives/monthlycal.directive';
import { YearlycalComponent } from '@app/yearlycal/yearlycal.component';
import { YearlycalDirective } from '@app/directives/yearlycal.directive';
import { WeeklycalDirective } from '@app/directives/weeklycal.directive';
import { WeeklycalComponent } from '@app/weeklycal/weeklycal.component';
import { BehaviorcalComponent } from '@app/behaviorcal/behaviorcal.component';
import { BehaviorcalDirective } from '@app/directives/behaviorcal.directive';
import { HostsynthesisComponent } from '@app/hostsynthesis/hostsynthesis.component';
import { TroubleshootingComponent } from '@app/troubleshooting/troubleshooting.component';
import { TroubleshootingDirective } from '@app/directives/troubleshooting.directive';
import { ClustersynthesisComponent } from '@app/clustersynthesis/clustersynthesis.component';
import { ClusterdistributionComponent } from '@app/clusterdistribution/clusterdistribution.component';
import { ClusterdistributionDirective } from '@app/directives/clusterdistribution.directive';
import { Clusterdistribution2Directive } from '@app/directives/clusterdistribution2.directive';
import { Clusterdistribution3Directive } from '@app/directives/clusterdistribution3.directive';
import { Clusterdistribution4Directive } from '@app/directives/clusterdistribution4.directive';
import { Clusterdistribution5Directive } from '@app/directives/clusterdistribution5.directive';
import { Clusterdistribution6Directive } from '@app/directives/clusterdistribution6.directive';
import { Clusterdistribution7Directive } from '@app/directives/clusterdistribution7.directive';
import { Clusterdistribution8Directive } from '@app/directives/clusterdistribution8.directive';
import { Clusterdistribution9Directive } from '@app/directives/clusterdistribution9.directive';
import { Clusterdistribution10Directive } from '@app/directives/clusterdistribution10.directive';
import { Clusterdistribution11Directive } from '@app/directives/clusterdistribution11.directive';
import { Clusterdistribution12Directive } from '@app/directives/clusterdistribution12.directive';
import { EvolutionsynthesisComponent } from '@app/evolutionsynthesis/evolutionsynthesis.component';
import { EvolutionDirective } from '@app/directives/evolution.directive';
import { SelectionviewComponent } from '@app/selectionview/selectionview.component';
import { PeriodviewComponent } from '@app/periodview/periodview.component';
import { FiltermgtComponent } from '@app/filtermgt/filtermgt.component';
import { SelectiontreeComponent } from '@app/selectiontree/selectiontree.component';
import { SelectiontreeDirective } from '@app/directives/selectiontree.directive';
import { WizardselectiontreeDirective } from '@app/directives/wizardselectiontree.directive';
import { PeriodcalendarComponent } from '@app/periodcalendar/periodcalendar.component';
import { PeriodcalendarDirective } from '@app/directives/periodcalendar.directive';
import { WizardperiodcalendarDirective } from '@app/directives/wizardperiodcalendar.directive';
import { HostinfoComponent } from '@app/hostinfo/hostinfo.component';
import { VminfoComponent } from '@app/vminfo/vminfo.component';
import { HostconsoverComponent } from '@app/hostconsover/hostconsover.component';
import { VmconsoverComponent } from '@app/vmconsover/vmconsover.component';
import { GreenitComponent } from '@app/greenit/greenit.component';
import { GreenitcfComponent } from '@app/greenit/greenitcf/greenitcf.component';
import { GreenitvmComponent } from '@app/greenit/greenitvm/greenitvm.component';
import { PowerComponent } from '@app/greenit/power/power.component';
import { PowerComboboxComponent } from '@app/greenit/power/power-combobox/power-combobox.component';
import { PowerGraphComponent } from '@app/greenit/power/power-graph/power-graph.component';
import { CapaplanComponent } from '@app/capaplan/capaplan.component';
import { CaparesComponent } from '@app/capaplan/capares/capares.component';
import { CapaentityComponent } from '@app/capaplan/capaentity/capaentity.component';
import { CapasettingsComponent } from '@app/capaplan/capasettings/capasettings.component';
import { ReportComponent } from '@app/report/report.component';
import { ReportcardComponent } from '@app/report/reportcard/reportcard.component';
import { ReportcronComponent } from '@app/report/reportcron/reportcron.component';
import { ConsoverComponent } from '@app/consover/consover.component';
import { VmconsoverDirective } from '@app/directives/vmconsover.directive';
import { HostconsoverDirective } from '@app/directives/hostconsover.directive';
import { AlertingComponent } from '@app/alerting/alerting.component';
import { AlertingtableComponent } from '@app/alerting/alertingtable/alertingtable.component';
import { AlertingtablesDirective } from '@app/directives/alertingtables.directive';
import { AlertingtablestateComponent } from '@app/alerting/alertingtablestate/alertingtablestate.component';
import { AlertingtablerunComponent } from '@app/alerting/alertingtablerun/alertingtablerun.component';
import { AlertingtablestateDirective } from '@app/directives/alertingtablestate.directive';
import { AlertingtablerunDirective } from '@app/directives/alertingtablerun.directive';
import { AlertingnotificationComponent } from '@app/alerting/alertingnotification/alertingnotification.component';
import { AlertingnotificationDirective } from '@app/directives/alertingnotification.directive';
import { AlertingwatcherComponent } from '@app/alerting/alertingwatcher/alertingwatcher.component';
import { AlertingwatcherDirective } from '@app/directives/alertingwatcher.directive';
import { AlertingalertComponent } from '@app/alerting/alertingalert/alertingalert.component';
import { AlertingalertDirective } from '@app/directives/alertingalert.directive';
import { CloudComponent } from './cloud/cloud.component';
import { CloudimpactComponent } from './cloudimpact/cloudimpact.component';
import { GraphOnDemandComponent } from '@app/graph-on-demand/graph-on-demand.component';
import { GraphOnDemandGraphComponent } from '@app/graph-on-demand/graph-on-demand-graph/graph-on-demand-graph.component';
import { GraphOnDemandComboboxComponent } from '@app/graph-on-demand/graph-on-demand-combobox/graph-on-demand-combobox.component';
import { LicenseComponent } from '@app/license/license.component';
import { UploadComponent } from '@app/upload/upload.component';
import { TroubleshootingcardlistComponent } from '@app/troubleshooting/troubleshootingcardlist/troubleshootingcardlist.component';
import { TroubleshootingcardComponent } from '@app/troubleshooting/troubleshootingcard/troubleshootingcard.component';
import { TroubleshootinggraphComponent } from '@app/troubleshooting/troubleshootinggraph/troubleshootinggraph.component';
import { TroubleshootingcardlistDirective } from '@app/directives/troubleshootingcardlist.directive';
import { TroubleshootinggraphDirective } from '@app/directives/troubleshootinggraph.directive';
import { TroubleshootinglistDirective } from '@app/directives/troubleshootinglist.directive';
import { TroubleshootinglistComponent } from '@app/troubleshooting/troubleshootinglist/troubleshootinglist.component';
import { TroubleshootingmapComponent } from '@app/troubleshooting/troubleshootingmap/troubleshootingmap.component';
import { TroubleshootingmapDirective } from '@app/directives/troubleshootingmap.directive';
import { MonitorComponent } from '@app/monitor/monitor.component';
import { StorageOverviewComponent } from '@app/storage-overview/storage-overview.component';
import { StorageOverviewVmComponent } from '@app/storage-overview/storage-overview-vm/storage-overview-vm.component';
import { SettingsComponent } from '@app/settings/settings.component';
import { RecommendationComponent } from '@app/recommendation/recommendation.component';
import { RecommendationconsolidationComponent } from '@app/recommendation/recommendationconsolidation/recommendationconsolidation.component';
import { RecommendationoperationDirective } from '@app/directives/recommendationoperation.directive';
import { RecommendationoperationComponent } from '@app/recommendation/recommendationoperation/recommendationoperation.component';
import { RecommendationresizingComponent } from '@app/recommendation/recommendationresizing/recommendationresizing.component';
import { RecommendationresizingDirective } from '@app/directives/recommendationresizing.directive';
import { RecommendationresizingdetailDirective } from '@app/directives/recommendationresizingdetail.directive';
import { RecommendationresizingdetailComponent } from '@app/recommendation/recommendationresizingdetail/recommendationresizingdetail.component';
import { RecommendationconsolidationDirective } from '@app/directives/recommendationconsolidation.directive';
import { RecommendationconsolidationdetailDirective } from '@app/directives/recommendationconsolidationdetail.directive';
import { RecommendationconsolidationdetailComponent } from '@app/recommendation/recommendationconsolidationdetail/recommendationconsolidationdetail.component';
import { KpiComponent } from '@app/kpi/kpi.component';
import { KpiactivityComponent } from '@app/kpi/kpiactivity/kpiactivity.component';
import { KpicapacityComponent } from '@app/kpi/kpicapacity/kpicapacity.component';
import { KpicostComponent } from '@app/kpi/kpicost/kpicost.component';
import { KpihealthComponent } from '@app/kpi/kpihealth/kpihealth.component';
import { KpiactivityDirective } from '@app/directives/kpiactivity.directive';
import { KpicapacityDirective } from '@app/directives/kpicapacity.directive';
import { KpicostDirective } from '@app/directives/kpicost.directive';
import { KpihealthDirective } from '@app/directives/kpihealth.directive';
import { KpitableComponent } from '@app/kpi/kpitable/kpitable.component';
import { KpitableDirective } from '@app/directives/kpitable.directive';
import { KpihealthgraphDirective } from '@app/directives/kpihealthgraph.directive';
import { KpihealthgraphComponent } from '@app/kpi/kpihealthgraph/kpihealthgraph.component';
import { UsermgtComponent } from '@app/usermgt/usermgt.component';
import { UsermgtrootComponent } from '@app/usermgt/usermgtroot/usermgtroot.component';
import { UsermgtnorootComponent } from '@app/usermgt/usermgtnoroot/usermgtnoroot.component';
import { UsermgtrootDirective } from '@app/directives/usermgtroot.directive';
import { UsermgtnorootDirective } from '@app/directives/usermgtnoroot.directive';
import { RolemgtDirective } from '@app/directives/rolemgt.directive';
import { RolemgtComponent } from '@app/usermgt/rolemgt/rolemgt.component';
import { CostmgtComponent } from '@app/costmgt/costmgt.component';
import { CostvmdetailComponent } from '@app/costmgt/costvmdetail/costvmdetail.component';
import { CostvmdetailDirective } from '@app/directives/costvmdetail.directive';
import { CostserverdetailDirective } from '@app/directives/costserverdetail.directive';
import { CostserverdetailComponent } from '@app/costmgt/costserverdetail/costserverdetail.component';
import { CoststodetailComponent } from '@app/costmgt/coststodetail/coststodetail.component';
import { CoststodetailDirective } from '@app/directives/coststodetail.directive';
import { T6detailComponent } from '@app/t6/t6detail/t6detail.component';
import { T6detailDirective } from '@app/directives/t6detail.directive';
import { PluginComponent } from '@app/plugin/plugin.component';
import { VsanDirective } from '@app/directives/vsan.directive';
import { VsanComponent } from '@app/vsan/vsan.component';
import { RangeFilterComponent } from '@app/storage-overview/range-filter/range-filter.component';
import { VmlistComponent } from '@app/vmlist/vmlist.component';
import { HostlistComponent } from '@app/hostlist/hostlist.component';
import { VmlistDirective } from '@app/directives/vmlist.directive';
import { HostlistDirective } from '@app/directives/hostlist.directive';
import { LcyDirective } from '@app/directives/lcy.directive';
import { LcyComponent } from '@app/lcy/lcy.component';
import { RecommendationresizingdetaildiskComponent } from '@app/recommendation/recommendationresizingdetaildisk/recommendationresizingdetaildisk.component';
import { RecommendationresizingdetaildiskDirective } from '@app/directives/recommendationresizingdetaildisk.directive';
import { RecommendationresizingdetailramDirective } from '@app/directives/recommendationresizingdetailram.directive';
import { RecommendationresizingdetailramComponent } from '@app/recommendation/recommendationresizingdetailram/recommendationresizingdetailram.component';
import { RecommendationresizingdetailcpuComponent } from '@app/recommendation/recommendationresizingdetailcpu/recommendationresizingdetailcpu.component';
import { RecommendationresizingdetailcpuDirective } from '@app/directives/recommendationresizingdetailcpu.directive';
import { RecommendationresizingcpuComponent } from '@app/recommendation/recommendationresizingcpu/recommendationresizingcpu.component';
import { RecommendationresizingdiskComponent } from '@app/recommendation/recommendationresizingdisk/recommendationresizingdisk.component';
import { RecommendationresizingramComponent } from '@app/recommendation/recommendationresizingram/recommendationresizingram.component';
import { RecommendationresizingcpuDirective } from '@app/directives/recommendationresizingcpu.directive';
import { RecommendationresizingdiskDirective } from '@app/directives/recommendationresizingdisk.directive';
import { RecommendationresizingramDirective } from '@app/directives/recommendationresizingram.directive';
import { ConsovercpuComponent } from '@app/consover/consovercpu/consovercpu.component';
import { ConsoverdiskComponent } from '@app/consover/consoverdisk/consoverdisk.component';
import { ConsovernetComponent } from '@app/consover/consovernet/consovernet.component';
import { ConsoverramComponent } from '@app/consover/consoverram/consoverram.component';
import { ConsovercpuDirective } from '@app/directives/consovercpu.directive';
import { ConsoverdiskDirective } from '@app/directives/consoverdisk.directive';
import { ConsovernetDirective } from '@app/directives/consovernet.directive';
import { ConsoverramDirective } from '@app/directives/consoverram.directive';
import { RecommendationconsolidationserverlistComponent } from '@app/recommendation/recommendationconsolidationserverlist/recommendationconsolidationserverlist.component';
import { RecommendationconsolidationserverlistDirective } from '@app/directives/recommendationconsolidationserverlist.directive';
import { VminfodetailComponent } from '@app/vminfo/vminfodetail/vminfodetail.component';
import { VminfodetailDirective } from '@app/directives/vminfodetail.directive';
import { CostvmgenericComponent } from '@app/costmgt/costvmgeneric/costvmgeneric.component';
import { CostvmgenericDirective } from '@app/directives/costvmgeneric.directive';
import { CostvmtagDirective } from '@app/directives/costvmtag.directive';
import { CostvmtagComponent } from '@app/costmgt/costvmtag/costvmtag.component';
import { CostservergenericComponent } from '@app/costmgt/costservergeneric/costservergeneric.component';
import { CoststogenericComponent } from '@app/costmgt/coststogeneric/coststogeneric.component';
import { CostservergenericDirective } from '@app/directives/costservergeneric.directive';
import { CoststogenericDirective } from '@app/directives/coststogeneric.directive';
import { RpsynthesisComponent } from '@app/rpsynthesis/rpsynthesis.component';
import { RpevolutionComponent } from '@app/rpsynthesis/rpevolution/rpevolution.component';
import { RpviewbarComponent } from '@app/rpsynthesis/rpviewbar/rpviewbar.component';
import { RpinfoComponent } from '@app/rpinfo/rpinfo.component';
import { ClusterinfoComponent } from '@app/clusterinfo/clusterinfo.component';
import { GreenittoolComponent } from '@app/greenit/greenittool/greenittool.component';
import { VcloudComponent } from '@app/vcloud/vcloud.component';
import { VDCevolutionComponent } from '@app/vcloud/vdcevolution/vdcevolution.component';
import { VDCviewbarComponent } from '@app/vcloud/vdcviewbar/vdcviewbar.component';
import { VirtIndexComponent } from '@app/virtIndex/virtIndex.component';
import { BellcurveComponent } from '@app/virtIndex/bellcurve/bellcurve.component';
import { BoxplotComponent } from '@app/virtIndex/boxplotchart/boxplot.component';
import { BulletComponent } from '@app/virtIndex/bulletchart/bullet.component';
import { GaugechartComponent } from '@app/virtIndex/gaugechart/gaugechart.component';
import { PolarchartComponent } from '@app/virtIndex/polarchart/polarchart.component';
import { ProviderComponent } from '@app/provider/provider.component';
import { AwsComponent } from '@app/provider/aws/aws.component';
import { AzureComponent } from '@app/provider/azure/azure.component';
import { CloudbehaviorDirective } from '@app/directives/cloudbehavior.directive';
import { CloudcostsDirective } from '@app/directives/cloudcosts.directive';
import { CloudinstancesDirective } from '@app/directives/cloudinstances.directive';
import { CloudresizingDirective } from '@app/directives/cloudresizing.directive';
import { CloudtroubleDirective } from '@app/directives/cloudtrouble.directive';
import { CostsDetailComponent } from '@app/provider/aws/costs-detail/costs-detail.component';
import { BehaviorDetailComponent } from '@app/provider/aws/behavior-detail/behavior-detail.component';
import { InstancesDetailComponent } from '@app/provider/aws/instances-detail/instances-detail.component';
import { ResizingDetailComponent } from '@app/provider/aws/resizing-detail/resizing-detail.component';
import { TroubleDetailComponent } from '@app/provider/aws/trouble-detail/trouble-detail.component';
import { SavingsplanDetailComponent } from '@app/provider/aws/savingsplan-detail/savingsplan-detail.component';
import { CloudsavingsplanDirective } from '@app/directives/cloudsavingsplan.directive';
import { CloudkpicreditsDirective } from '@app/directives/cloudkpicredits.directive';
import { KpiCreditsComponent } from '@app/provider/aws/kpi-credits/kpi-credits.component';
import { KpiSavingsplanComponent } from '@app/provider/aws/kpi-savingsplan/kpi-savingsplan.component';
import { CloudkpisavingsplanDirective } from '@app/directives/cloudkpisavingsplan.directive';
import { CloudkpievolutionDirective } from '@app/directives/cloudkpievolution.directive';
import { KpiEvolutionComponent } from '@app/provider/aws/kpi-evolution/kpi-evolution.component';
import { GraphCounterComponent } from '@app/provider/aws/graph-counter/graph-counter.component';
import { GraphRegionComponent } from '@app/provider/aws/graph-region/graph-region.component';
import { GraphcounterDirective } from '@app/directives/graphcounter.directive';
import { GraphregionDirective } from '@app/directives/graphregion.directive';
import { CloudgraphregionDirective } from '@app/directives/cloudgraphregion.directive';
import { CloudgraphcounterDirective } from '@app/directives/cloudgraphcounter.directive';
import { ConsumptionProfileComponent } from '@app/provider/aws/consumption-profile/consumption-profile.component';
import { CloudconsumptionprofileDirective } from '@app/directives/cloudconsumptionprofile.directive';
import { CloudoppspotDirective } from '@app/directives/cloudoppspot.directive';
import { OpportunitiesSpotComponent } from '@app/provider/aws/opportunities-spot/opportunities-spot.component';
import { InstancesCo2Component } from '@app/provider/aws/instances-co2/instances-co2.component';
import { Cloudco2Directive } from '@app/directives/cloudco2.directive';
import { GraphCounterStackComponent } from '@app/provider/aws/graph-counter-stack/graph-counter-stack.component';
import { CloudgraphcounterstatckDirective } from '@app/directives/cloudgraphcounterstatck.directive';
import { VmsnapshotComponent } from '@app/vmsnapshot/vmsnapshot.component';
import { AnalysisComponent } from '@app/greenit/analysis/analysis.component';
import { GreenitsettingsComponent } from '@app/greenit/greenitsettings/greenitsettings.component';

import { DcscopeDashboardComponent } from '@app/applications/dcscope-dashboard/dcscope-dashboard.component';
import { Co2scopeDashboardComponent } from '@app/applications/co2scope-dashboard/co2scope-dashboard.component';
import { T7Component } from './t7/t7.component';
import { T8Component } from './t8/t8.component';
import { T9Component } from './t9/t9.component';
import { T10Component } from './t10/t10.component';
import { T11Component } from './t11/t11.component';
import { T7Directive } from './directives/t7.directive';
import { T8Directive } from './directives/t8.directive';
import { T9Directive } from './directives/t9.directive';
import { T10Directive } from './directives/t10.directive';
import { T11Directive } from './directives/t11.directive';
import { EvolutionComponent } from './greenit/evolution/evolution.component';
import { T12Component } from './t12/t12.component';
import { T13Component } from './t13/t13.component';
import { T14Component } from './t14/t14.component';
import { T12Directive } from './directives/t12.directive';
import { T13Directive } from './directives/t13.directive';
import { T14Directive } from './directives/t14.directive';
import { ElementsynthesisComponent } from './elementsynthesis/elementsynthesis.component';
import { ConscalendarComponent } from './conscalendar/conscalendar.component';
import { ConscalendarDirective } from './directives/conscalendar.directive';
import { PowerVmStackedComponent } from './greenit/power/power-vm-stacked/power-vm-stacked.component';
import { OpportunitiesComponent } from './opportunities/opportunities.component';
import { VmwareComponent } from './dashboard/vmware/vmware.component';
import { AllinclusiveComponent } from './dashboard/allinclusive/allinclusive.component';
import { PowerVmwareComponent } from './greenit/power/power-vmware/power-vmware.component';
import { PowerAwsComponent } from './greenit/power/power-aws/power-aws.component';
import { PowerAzureComponent } from './greenit/power/power-azure/power-azure.component';
import { AccountManagementComponent } from './provider/aws/account-management/account-management.component';
import { SimulationComponent } from './simulation/simulation.component';
import { StorageComponent } from './dashboard/storage/storage.component';
import { NetworkComponent } from './dashboard/network/network.component';
import { Co2projectComponent } from './simulation/co2project/co2project.component';
import { DashboardCloudComponent } from './provider/dashboard-cloud/dashboard-cloud.component';
import { OpportunitiesCloudComponent } from './provider/opportunities-cloud/opportunities-cloud.component';

// SERVICES
import { JwtInterceptor, ErrorInterceptor } from '@app/_helpers';
import { InterceptorService } from '@app/services/interceptor.service';
import { MycloudpricingComponent } from './mycloudpricing/mycloudpricing.component';
import { TurnedOffNoMatchFilterComponent } from './cloud/turnedoff-nomatch-filter/turned-off-no-match-filter.component';
import { GraphOnDemandModule } from "@app/graph-on-demand/graph-on-demand.module";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    DcviewComponent,
    VmsynthesisComponent,
    T1Component,
    T1Directive,
    T2Component,
    T2Directive,
    T3Component,
    T3Directive,
    T4Component,
    T4Directive,
    T5Component,
    T5Directive,
    T6Component,
    T6Directive,
    MonthlycalComponent,
    MonthlycalDirective,
    YearlycalComponent,
    YearlycalDirective,
    WeeklycalDirective,
    WeeklycalComponent,
    BehaviorcalComponent,
    BehaviorcalDirective,
    HostsynthesisComponent,
    TroubleshootingComponent,
    TroubleshootingDirective,
    ClustersynthesisComponent,
    ClusterdistributionComponent,
    ClusterdistributionDirective,
    Clusterdistribution2Directive,
    Clusterdistribution3Directive,
    Clusterdistribution4Directive,
    Clusterdistribution5Directive,
    Clusterdistribution6Directive,
    Clusterdistribution7Directive,
    Clusterdistribution8Directive,
    Clusterdistribution9Directive,
    Clusterdistribution10Directive,
    Clusterdistribution11Directive,
    Clusterdistribution12Directive,
    EvolutionsynthesisComponent,
    EvolutionDirective,
    SelectionviewComponent,
    PeriodviewComponent,
    FiltermgtComponent,
    SelectiontreeComponent,
    SelectiontreeDirective,
    WizardselectiontreeDirective,
    PeriodcalendarComponent,
    PeriodcalendarDirective,
    WizardperiodcalendarDirective,
    HostinfoComponent,
    VminfoComponent,
    HostconsoverComponent,
    VmconsoverComponent,
    ConsoverComponent,
    VmconsoverDirective,
    HostconsoverDirective,
    AlertingComponent,
    AlertingtableComponent,
    AlertingtablesDirective,
    AlertingtablestateComponent,
    AlertingtablerunComponent,
    AlertingtablestateDirective,
    AlertingtablerunDirective,
    AlertingnotificationComponent,
    AlertingnotificationDirective,
    AlertingwatcherComponent,
    AlertingwatcherDirective,
    AlertingalertComponent,
    AlertingalertDirective,
    GreenitComponent,
    GreenitcfComponent,
    GreenitvmComponent,
    PowerComponent,
    PowerComboboxComponent,
    PowerGraphComponent,
    CapaplanComponent,
    CaparesComponent,
    CapaentityComponent,
    CapasettingsComponent,
    ReportComponent,
    ReportcardComponent,
    ReportcronComponent,
    CloudComponent,
    CloudimpactComponent,
    // GraphOnDemandComponent,
    // GraphOnDemandGraphComponent,
    // GraphOnDemandComboboxComponent,
    LicenseComponent,
    UploadComponent,
    TroubleshootingcardlistComponent,
    TroubleshootingcardComponent,
    TroubleshootinggraphComponent,
    TroubleshootingcardlistDirective,
    TroubleshootinggraphDirective,
    TroubleshootinglistDirective,
    TroubleshootinglistComponent,
    TroubleshootingmapComponent,
    TroubleshootingmapDirective,
    MonitorComponent,
    StorageOverviewComponent,
    RangeFilterComponent,
    StorageOverviewVmComponent,
    SettingsComponent,
    RecommendationComponent,
    RecommendationconsolidationComponent,
    RecommendationoperationDirective,
    RecommendationoperationComponent,
    RecommendationresizingComponent,
    RecommendationresizingDirective,
    RecommendationresizingdetailDirective,
    RecommendationresizingdetailComponent,
    RecommendationconsolidationDirective,
    RecommendationconsolidationdetailDirective,
    RecommendationconsolidationdetailComponent,
    KpiComponent,
    KpiactivityComponent,
    KpicapacityComponent,
    KpicostComponent,
    KpihealthComponent,
    KpiactivityDirective,
    KpicapacityDirective,
    KpicostDirective,
    KpihealthDirective,
    KpitableComponent,
    KpitableDirective,
    KpihealthgraphDirective,
    KpihealthgraphComponent,
    DcviewComponent,
    DcviewFlowchartComponent,
    KpihealthgraphComponent,
    UsermgtComponent,
    UsermgtrootComponent,
    UsermgtnorootComponent,
    UsermgtrootDirective,
    UsermgtnorootDirective,
    RolemgtDirective,
    RolemgtComponent,
    CostmgtComponent,
    CostvmdetailComponent,
    CostvmdetailDirective,
    CostserverdetailDirective,
    CostserverdetailComponent,
    CoststodetailComponent,
    CoststodetailDirective,
    T6detailComponent,
    T6detailDirective,
    PluginComponent,
    VsanDirective,
    VsanComponent,
    VmlistComponent,
    HostlistComponent,
    VmlistDirective,
    HostlistDirective,
    LcyDirective,
    LcyComponent,
    MycloudpricingComponent,
    RecommendationresizingdetaildiskComponent,
    RecommendationresizingdetaildiskDirective,
    RecommendationresizingdetailramDirective,
    RecommendationresizingdetailramComponent,
    RecommendationresizingdetailcpuComponent,
    RecommendationresizingdetailcpuDirective,
    RecommendationresizingcpuComponent,
    RecommendationresizingdiskComponent,
    RecommendationresizingramComponent,
    RecommendationresizingcpuDirective,
    RecommendationresizingdiskDirective,
    RecommendationresizingramDirective,
    ConsovercpuComponent,
    ConsoverdiskComponent,
    ConsovernetComponent,
    ConsoverramComponent,
    ConsovercpuDirective,
    ConsoverdiskDirective,
    ConsovernetDirective,
    ConsoverramDirective,
    RecommendationconsolidationserverlistComponent,
    RecommendationconsolidationserverlistDirective,
    TurnedOffNoMatchFilterComponent,
    VminfodetailComponent,
    VminfodetailDirective,
    CostvmgenericComponent,
    CostvmgenericDirective,
    CostvmtagDirective,
    CostvmtagComponent,
    CostservergenericComponent,
    CoststogenericComponent,
    CostservergenericDirective,
    CoststogenericDirective,
    RpsynthesisComponent,
    RpevolutionComponent,
    RpviewbarComponent,
    RpinfoComponent,
    ClusterinfoComponent,
    GreenittoolComponent,
    VcloudComponent,
    VDCevolutionComponent,
    VDCviewbarComponent,
    VirtIndexComponent,
    BellcurveComponent,
    BoxplotComponent,
    BulletComponent,
    GaugechartComponent,
    PolarchartComponent,
    ProviderComponent,
    AwsComponent,
    AzureComponent,
    CloudbehaviorDirective,
    CloudcostsDirective,
    CloudinstancesDirective,
    CloudresizingDirective,
    CloudtroubleDirective,
    CostsDetailComponent,
    BehaviorDetailComponent,
    InstancesDetailComponent,
    ResizingDetailComponent,
    TroubleDetailComponent,
    SavingsplanDetailComponent,
    CloudsavingsplanDirective,
    CloudkpicreditsDirective,
    KpiCreditsComponent,
    KpiSavingsplanComponent,
    CloudkpisavingsplanDirective,
    CloudkpievolutionDirective,
    KpiEvolutionComponent,
    GraphCounterComponent,
    GraphRegionComponent,
    GraphcounterDirective,
    GraphregionDirective,
    CloudgraphregionDirective,
    CloudgraphcounterDirective,
    ConsumptionProfileComponent,
    CloudconsumptionprofileDirective,
    CloudoppspotDirective,
    OpportunitiesSpotComponent,
    InstancesCo2Component,
    Cloudco2Directive,
    GraphCounterStackComponent,
    CloudgraphcounterstatckDirective,
    VmsnapshotComponent,
    AnalysisComponent,
    GreenitsettingsComponent,
    DcscopeDashboardComponent,
    Co2scopeDashboardComponent,
    T7Component,
    T8Component,
    T9Component,
    T10Component,
    T11Component,
    T7Directive,
    T8Directive,
    T9Directive,
    T10Directive,
    T11Directive,
    EvolutionComponent,
    T12Component,
    T13Component,
    T14Component,
    T12Directive,
    T13Directive,
    T14Component,
    T14Directive,
    ElementsynthesisComponent,
    ConscalendarComponent,
    ConscalendarDirective,
    PowerVmStackedComponent,
    OpportunitiesComponent,
    VmwareComponent,
    AllinclusiveComponent,
    PowerVmwareComponent,
    PowerAwsComponent,
    PowerAzureComponent,
    AccountManagementComponent,
    SimulationComponent,
    StorageComponent,
    NetworkComponent,
    Co2projectComponent,
    DashboardCloudComponent,
    OpportunitiesCloudComponent,
    // NetscopeDashboardComponent,
    // NetscopeTopologyComponent,
    // NetscopeDependenciesViewerComponent,
    // NetscopeProtocolAnalysisComponent,
    // NetscopeClustersViewerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ClarityModule,
    AppRoutingModule,
    HighchartsChartModule,
    DataTablesModule,
    FontAwesomeModule,
    NgxSliderModule,
    NetscopeModule,
    GraphOnDemandModule
  ],
  providers: [
    DashboardComponent,
    VmwareComponent,
    //{ provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting, highmaps, xrange ] },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    //{ provide: LOCALE_ID, useValue: 'fr' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(faTh, faLinux, faDatabase, faWindows, faChartPie, faLeaf, faHeartbeat, faCogs, faTrain, faFaucet, faSeedling, faPowerOff, faArrowUp, faBolt, faProjectDiagram);
	}
}

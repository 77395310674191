<clr-tabs *ngIf="isCo2scope">
	<clr-tab>
        <button clrTabLink (click)="setVmware()">VMWARE</button>
		<clr-tab-content *clrIfActive="true">
            <app-power-vmware *ngIf="isVmwareReady"></app-power-vmware>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="setAws()">AWS</button>
		<clr-tab-content>
            <app-power-aws *ngIf="isAwsReady"></app-power-aws>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink (click)="setAzure()">AZURE</button>
		<clr-tab-content>
            <app-power-azure *ngIf="isAzureReady"></app-power-azure>
        </clr-tab-content>
    </clr-tab>
</clr-tabs>

<app-power-vmware *ngIf="isDcscope && isVmwareReady"></app-power-vmware>
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-t7]'
})
export class T7Directive {

  constructor(public viewContainerRef7: ViewContainerRef) { }

}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[cloud-cons-profile]'
})
export class CloudconsumptionprofileDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

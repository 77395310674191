import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { DriverInfo, MonitorSettings, Status, StatusTarget, InstanceType } from '@app/model';

import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  instance_global_alert: number = 2;
  instance_details: Array<Status> = [];

  vcloud_check: boolean = false;
  aws_check: boolean = false;
  azure_check: boolean = false;

  db_global_alert: number = 2;
  db_details: Array<Status> = [];

  private instanceGlobalSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.instance_global_alert);
  instanceGlobal: Observable<number> = this.instanceGlobalSubject.asObservable();

  private instanceDetailsSubject: BehaviorSubject<Array<Status>> = new BehaviorSubject<Array<Status>>(this.instance_details);
  instanceDetails: Observable<Array<Status>> = this.instanceDetailsSubject.asObservable();

  private vcloudCheckSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.vcloud_check);
  vcloudCheck: Observable<boolean> = this.vcloudCheckSubject.asObservable();

  private awsCheckSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.aws_check);
  awsCheck: Observable<boolean> = this.awsCheckSubject.asObservable();

  private azureCheckSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.azure_check);
  azureCheck: Observable<boolean> = this.azureCheckSubject.asObservable();

  private dbGlobalSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.db_global_alert);
  dbGlobal: Observable<number> = this.dbGlobalSubject.asObservable();

  private dbDetailsSubject: BehaviorSubject<Array<Status>> = new BehaviorSubject<Array<Status>>(this.db_details);
  dbDetails: Observable<Array<Status>> = this.dbDetailsSubject.asObservable();


  /**
   *
   */
  constructor(private http: HttpClient) { }


  /**
   * 
   */
  getStatus(): void {
    this.getInstanceStatus();
    this.getDBStatus();
  }

  /**
   * Monitoring instances status
   */
  private getInstanceStatus(): void {
    this.http.get<any>(`${environment.apiUrl}/` + 'trackdc/getinstancesstatus/').pipe(map(infos => { return infos })).subscribe(
      data => {
        this.initStatus(data, null, StatusTarget.INSTANCE);
      },
      error => {
        this.initStatus(null, error, StatusTarget.INSTANCE);
      }
    );
  }

  /**
   * Database status
   */
  private getDBStatus(): void {
    this.http.get<any>(`${environment.apiUrl}/` + 'trackdc/getdbstatus/').pipe(map(infos => { return infos })).subscribe(
      data => {
        this.initStatus(data, null, StatusTarget.DB);
      },
      error => {
        this.initStatus(null, error, StatusTarget.DB);
      }
    );
  }

  /**
   * 
   */
  private initStatus(data: any, error: any, target: StatusTarget): void {

    let tmp_global_alert: number = 2;
    let tmp_details: Array<Status> = [];
    let tmp_vcloud_check = false;
    let tmp_aws_check = false;
    let tmp_azure_check = false;

    if (data) {

      let total_alert: number = 0;
      let nb_alert = 0;

      // Build alert, details & check if vcloud/aws/azure
      for (let i in data) {
        let tmp: Status = data[i];
        
        // Check if vcloud/aws/azure type is declared
        switch(tmp.type) {
          case InstanceType.VCLOUD:
            tmp_vcloud_check = true;
            break;
          case InstanceType.AWS:
            tmp_aws_check = true;
            break;
          case InstanceType.AZURE:
            tmp_azure_check = true;
            break;
        } 

        // Sum total alert & build details
        total_alert += tmp.status;
        if (tmp.status > 0)
          tmp_details.push(tmp);
        nb_alert++;
      }

      // Compute global alert
      tmp_global_alert = total_alert / nb_alert;
      if (tmp_global_alert == 0)
        tmp_global_alert = 0;
      //else if(tmp_global_alert > 0 && tmp_global_alert < 1)
      //  tmp_global_alert = 1;
      else
        tmp_global_alert = 2;

    } else if (error) {

      // error : no status
      let default_status: Status = {
        status: 2,
        name: "No element found",
        date: new Date().getTime(),
        msg: "No status found",
        type: undefined
      };
      tmp_details.push(default_status);

    }

    // Update status
    switch (target) {
      case StatusTarget.INSTANCE:
        this.instanceGlobalSubject.next(tmp_global_alert);
        this.instanceDetailsSubject.next(tmp_details);
        // Force show vCloud Director menu if dev mode
        this.vcloudCheckSubject.next(tmp_vcloud_check || !environment.production);
        // Force show AWS menu if dev mode
        this.awsCheckSubject.next(tmp_aws_check || !environment.production);
        // Force show Azure menu if dev mode
        this.azureCheckSubject.next(tmp_azure_check || !environment.production);
        break;
      case StatusTarget.DB:
        this.dbGlobalSubject.next(tmp_global_alert);
        this.dbDetailsSubject.next(tmp_details);
        break;
      default:
        break;
    }

  }

  /**
   * 
   */
  getArchive(user: string): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}/` + 'trackdc/getarchive/' + user, { responseType: 'blob' });
  }

  /**
   *
   */
  checkInstall() {
    return this.http.get(`${environment.apiUrl}/` + 'trackdc/checkInstall/').pipe(map(list => {
      return list;
    }));
  }

  /**
   * 
   */
  getMonitorList() {
    return this.http.get(`${environment.apiUrl}/` + 'trackdc/getinstanceslist/').pipe(map(list => {
      return list;
    }));
  }

  /**
   * 
   */
  getInstance(instance: string) {
    return this.http.get<DriverInfo>(`${environment.apiUrl}/` + 'trackdc/getinstance/' + instance).pipe(map(list => {
      return list;
    }));
  }

  /**
   * 
   */
  unregisterInstance(instance: string) {
    return this.http.post(`${environment.apiUrl}/` + 'trackdc/unregisterinstance/', [instance]);
  }

  /**
   * 
   */
  registerInstance(data: JSON) {
    return this.http.post(`${environment.apiUrl}/` + 'trackdc/registerinstance/', data);
  }

  /**
  * 
  */
  migrateTest(ip: string, password: string) {
    return this.http.post(`${environment.apiUrl}/` + 'trackdc/migratetest/', [ip, password]);
  }

  /**
  * 
  */
  migrate(ip: string, password: string, retention: number) {
    return this.http.post(`${environment.apiUrl}/` + 'trackdc/migrate/', [ip, password, retention]);
  }

  /**
  * 
  */
  getMigrateDetails() {
    return this.http.get(`${environment.apiUrl}/` + 'trackdc/migratedetails/').pipe(map(infos => {
      return infos;
    }, error => { }));
  }

  /**
  * 
  */
  dropMigrate() {
    this.http.post(`${environment.apiUrl}/` + 'trackdc/dropmigrate/', ["btrpersist_migration"]).subscribe();
  }

  /**
   * 
   */
  getSettings() {
    return this.http.get<MonitorSettings[]>(`${environment.apiUrl}/` + 'trackdc/getsettings/').pipe(map(infos => {
      return infos;
    }, error => { }));
  }

  /**
   * NOTE : keep compatibility with trackdc
   */
  crypt(text: string): string {
    let secretKey = "6B3E6A5A8B07224D2B7DA4AD4112A0CA";
    var key = CryptoJS.enc.Hex.parse(secretKey);

    let crypted = CryptoJS.AES.encrypt(text, key, {
      mode: CryptoJS.mode.ECB,
    }).ciphertext.toString();

    return crypted;
  }

  /**
   * 
   */
  cryptBase64(text: string): string {
    let pwd = btoa(text);
    // Remove all = characters at the end of a string
    pwd = pwd.replace(/=*$/, '');
    return pwd;
  }

  /**
   * 
   */
  checkDatalock() {
    return this.http.get<any>(`${environment.apiUrl}/` + 'trackdc/checkdatalock/').pipe(map(data => {
      return data;
    }));
  }

  /**
   * 
   */
  getPluginXML() {
    return this.http.get(`${environment.apiUrl}/` + 'plugin/getxml/', { responseType: 'blob' });
  }

  /**
   * 
   */
  updatePlugin(hostname: string) {
    return this.http.post(`${environment.apiUrl}/` + 'plugin/update/', [hostname]);
  }

}


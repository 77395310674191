import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CapaplanService {

  constructor(private http: HttpClient) { }


   /**
   * CAPAPLAN
   */
  saveCapaSimulationFile(user: string, filter: string, content: JSON) {
    return this.http.post<String>(`${environment.apiUrl}/` + 'capaplan/savesimulation/', [user, filter, content]);
  }
  
  runSimulation(user: string, filter: string) {
    return this.http.post(`${environment.apiUrl}/` + 'capaplan/runsimulation/', [user, filter]);
  }

  /**
   * 
   */
  generateReport(user: string, filter: string): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}/` + 'capaplan/generatereport/' + user + '&' + filter, { responseType: 'blob'});
  }

  /**
   * VDC / RP synthesis
   */
  getEvolution(uuid: string) {
	  return this.http.get(`${environment.apiUrl}/` + 'capaplan/getevolution/' + uuid);
  }


}

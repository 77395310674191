import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CloudPricingService} from "@app/services/cloud-pricing.service";
import { ClrLoadingState } from '@clr/angular';

@Component({
	selector: 'app-mycloudpricing',
	templateUrl: './mycloudpricing.component.html',
	styleUrls: ['./mycloudpricing.component.css']
})
export class MycloudpricingComponent implements OnInit {

	showVmTypeDetailsModal = false;
	showVmTypeEditModal = false;
	showStorageTypeDetailsModal = false;
	showStorageTypeEditModal = false;
	providerSettingsDetailsModal = false;
	providerSettingsEditModal = false;

	vmTypeName = "";
	vmTypeCores = "";
	vmTypeRam = "";
	vmTypeStorage = "";
	// emissionFactor = 0.0;

	selectedVmType;
	selectedStorageType;
	providerSettings = {
		emissionFactor: 0.0,
		minimumPowerDrawServer: 0.0,
		maximumPowerDrawServer: 0.0,
		cpuCoresPerServer: 0.0,
	};
	tempProviderSettings = {
		emissionFactor: 0.0,
		minimumPowerDrawServer: 0.0,
		maximumPowerDrawServer: 0.0,
		cpuCoresPerServer: 0.0
	}

	committingState = ClrLoadingState.DEFAULT;

	availableVmTypes = [];
	availableStorageTypes = [];

	constructor(public cloudPricingService: CloudPricingService) {
	}

	ngOnInit(): void {
		this.reloadData();
	}

	reloadData = () => {
		this.cloudPricingService.fetchProviderDetails("myprovider").subscribe((details) => {
			// Compute Types
			this.availableVmTypes.splice(0, this.availableVmTypes.length);
			for (let vmTypeName in details["computeTypes"]) {
				let vmTypeObject = details["computeTypes"][vmTypeName];
				let newVmTypeObject = {
					"name": vmTypeName,
					"attributes": {
						"cores": vmTypeObject.vcpu,
						"ram": vmTypeObject.memory,
					},
					"prices": {
						"od": {
							"linux": details["computePrices"]["myregion"][vmTypeName]["linux"]["od"],
							"rhel": details["computePrices"]["myregion"][vmTypeName]["rhel"]["od"],
							"win": details["computePrices"]["myregion"][vmTypeName]["win"]["od"]
						},
						"1y": {
							"linux": details["computePrices"]["myregion"][vmTypeName]["linux"]["1y"],
							"rhel": details["computePrices"]["myregion"][vmTypeName]["rhel"]["1y"],
							"win": details["computePrices"]["myregion"][vmTypeName]["win"]["1y"]
						},
						"3y": {
							"linux": details["computePrices"]["myregion"][vmTypeName]["linux"]["3y"],
							"rhel": details["computePrices"]["myregion"][vmTypeName]["rhel"]["3y"],
							"win": details["computePrices"]["myregion"][vmTypeName]["win"]["3y"]
						},
					},
					"consumption": {
						0: 0,
						100: 0
					}
				};
				this.availableVmTypes.push(newVmTypeObject);
			}
			// Storage types
			this.availableStorageTypes.splice(0, this.availableStorageTypes.length);
			for (let storageTypeName in details["storageTypes"]) {
				let storageTypeObject = details["storageTypes"][storageTypeName];
				let newStorageTypeObject = {
					"name": storageTypeName,
					"attributes": {
						"min_size": storageTypeObject.volumeSizeMin,
						"max_size": storageTypeObject.volumeSizeMax,
						"max_iops": storageTypeObject.maxIopsPerVolume,
						"max_throughput": storageTypeObject.maxThroughputPerVolume,
					},
					"prices": {
						"od": {
							"iops": details["storagePrices"]["myregion"][storageTypeName]["iops"],
							"volume": details["storagePrices"]["myregion"][storageTypeName]["volume"],
						}
					},
					"consumption": 0
				};
				this.availableStorageTypes.push(newStorageTypeObject);
			}

			this.cloudPricingService.fetchProviderCarbonDetails("myprovider").subscribe((carbonDetails) => {
				// @ts-ignore
				this.providerSettings.emissionFactor = carbonDetails.myregion.emissionFactor;
				// @ts-ignore
				this.providerSettings.minimumPowerDrawServer = carbonDetails.myregion.minimumPowerDrawServer;
				// @ts-ignore
				this.providerSettings.maximumPowerDrawServer = carbonDetails.myregion.maximumPowerDrawServer;
				// @ts-ignore
				this.providerSettings.cpuCoresPerServer = carbonDetails.myregion.cpuCoresPerServer;
				console.log(carbonDetails);

				// Set consumption of vm types
				for (let vmType of this.availableVmTypes) {
					// @ts-ignore
					let correspondingInstanceTypeWithCarbonData = carbonDetails.myregion.instance_types[vmType["name"]];
					if (correspondingInstanceTypeWithCarbonData !== undefined) {
						let cpuArchitectureDefault = correspondingInstanceTypeWithCarbonData.lowest_cpu_architecture;
						let cpuArchitectureDetails = correspondingInstanceTypeWithCarbonData.cpu_architectures[cpuArchitectureDefault];
						vmType["consumption"]["grey_emission"] = cpuArchitectureDetails["grey_emission"];
						vmType["consumption"]["memory"] = cpuArchitectureDetails["memory_consumption"];
						vmType["consumption"]["storage"] = cpuArchitectureDetails["storage_consumption"];
						vmType["consumption"][0] = cpuArchitectureDetails["cpu_consumption_min"];
						vmType["consumption"][100] = cpuArchitectureDetails["cpu_consumption_max"];

					}
				}

				// Set consumption of storage types
				for (let storageType of this.availableStorageTypes) {
					storageType["consumption"] = 7.5;
				}
			})

			console.log(details);
		});
	}

	public selectVmType(vmType, action) {
		this.selectedVmType = JSON.parse(JSON.stringify(vmType));
		if (action !== "new") {
			this.selectedVmType.originalObject = vmType;
		}
		if (action == "edit" || action == "new") {
			this.showVmTypeEditModal = true;
			this.showVmTypeDetailsModal = false;
		} else {
			this.showVmTypeDetailsModal = true;
			this.showVmTypeEditModal = false;
		}
	}

	public newVmType() {
		let newVmTypeObject = {
			"name": "",
			"attributes": {
				"cores": 2,
				"ram": 4096
			},
			"prices": {
				"od": {
					"linux": 300,
					"rhel": 400,
					"win": 500
				},
				"1y": {
					"linux": 250,
					"rhel": 350,
					"win": 450
				},
				"3y": {
					"linux": 200,
					"rhel": 300,
					"win": 400
				},
			},
			"consumption": {
				0: 0,
				100: 0,
				memory: 0,
				storage: 0
			}
		}
		return this.selectVmType(newVmTypeObject, "new");
	}

	viewProviderSettings = () => {
		this.providerSettingsDetailsModal = true;
		this.providerSettingsEditModal = false;
	}

	editProviderSettings = () => {
		this.tempProviderSettings.emissionFactor = this.providerSettings.emissionFactor;
		this.tempProviderSettings.minimumPowerDrawServer = this.providerSettings.minimumPowerDrawServer;
		this.tempProviderSettings.maximumPowerDrawServer = this.providerSettings.maximumPowerDrawServer;
		this.tempProviderSettings.cpuCoresPerServer = this.providerSettings.cpuCoresPerServer;

		this.providerSettingsDetailsModal = false;
		this.providerSettingsEditModal = true;
	}

	commitProviderSettings = () => {
		this.providerSettings.emissionFactor = this.tempProviderSettings.emissionFactor;
		this.providerSettings.minimumPowerDrawServer = this.tempProviderSettings.minimumPowerDrawServer;
		this.providerSettings.maximumPowerDrawServer = this.tempProviderSettings.maximumPowerDrawServer;
		this.providerSettings.cpuCoresPerServer = this.tempProviderSettings.cpuCoresPerServer;

		this.viewProviderSettings();
	}

	hideProviderSettings = () => {
		this.providerSettingsDetailsModal = false;
		this.providerSettingsEditModal = false;
	}

	hideVmTypeModal = () => {
		this.showVmTypeEditModal = false;
		this.showVmTypeDetailsModal = false;
	}

	public selectStorageType(storageType, action) {
		this.selectedStorageType = JSON.parse(JSON.stringify(storageType));
		if (action !== "new") {
			this.selectedStorageType.originalObject = storageType;
		}
		if (action == "edit" || action == "new") {
			this.showStorageTypeEditModal = true;
			this.showStorageTypeDetailsModal = false;
		} else {
			this.showStorageTypeDetailsModal = true;
			this.showStorageTypeEditModal = false;
		}
	}

	public newStorageType() {
		let newStorageTypeObject = {
			"name": "",
			"attributes": {
				"min_size": 10,
				"max_size": 1000,
				"max_iops": 200,
				"max_throughput": 200,
			},
			"prices": {
				"od": {
					"volume": 0.01,
					"iops": 0.01,
				}
			},
			"consumption": 0
		}
		return this.selectStorageType(newStorageTypeObject, "new");
	}

	public hideStorageTypeModal() {
		this.showStorageTypeEditModal = false;
		this.showStorageTypeDetailsModal = false;
	}

	public commitSelectedVmType() {
		let isNewObject = this.selectedVmType.originalObject === undefined;
		if (isNewObject) {
			this.availableVmTypes.push(this.selectedVmType);
		} else {
			this.selectedVmType.originalObject.name = this.selectedVmType.name;
			this.selectedVmType.originalObject.attributes.cores = this.selectedVmType.attributes.cores;
			this.selectedVmType.originalObject.attributes.ram = this.selectedVmType.attributes.ram;

			for (let billingPeriod of ["od", "1y", "3y"]) {
				for (let os of ["linux", "rhel", "win"]) {
					this.selectedVmType.originalObject.prices[billingPeriod][os] = parseFloat(this.selectedVmType.prices[billingPeriod][os]);
				}
			}

			this.selectedVmType.originalObject.consumption[0] = parseFloat(this.selectedVmType.consumption[0]);
			this.selectedVmType.originalObject.consumption[100] = parseFloat(this.selectedVmType.consumption[100]);
		}
		this.hideVmTypeModal();
	}

	public commitSelectedStorageType() {
		let isNewObject = this.selectedStorageType.originalObject === undefined;
		if (isNewObject) {
			this.availableStorageTypes.push(this.selectedStorageType);
		} else {
			this.selectedStorageType.originalObject.name = this.selectedStorageType.name;
			this.selectedStorageType.originalObject.attributes.min_size = this.selectedStorageType.attributes.min_size;
			this.selectedStorageType.originalObject.attributes.max_size = this.selectedStorageType.attributes.max_size;
			this.selectedStorageType.originalObject.attributes.max_iops = this.selectedStorageType.attributes.max_iops;
			this.selectedStorageType.originalObject.attributes.max_throughput = this.selectedStorageType.attributes.max_throughput;

			for (let billingPeriod of ["od"]) {
				for (let os of ["volume", "iops"]) {
					this.selectedStorageType.originalObject.prices[billingPeriod][os] = parseFloat(this.selectedStorageType.prices[billingPeriod][os]);
				}
			}

			this.selectedStorageType.originalObject.consumption = parseFloat(this.selectedStorageType.consumption);
		}
		this.hideStorageTypeModal();
	}

	public deleteVmType(vmType) {
		let index = this.availableVmTypes.indexOf(vmType);
		this.availableVmTypes.splice(index, 1);
	}

	public deleteStorageType(storageType) {
		let index = this.availableStorageTypes.indexOf(storageType);
		this.availableStorageTypes.splice(index, 1);
	}

	public commitData() {
		this.committingState = ClrLoadingState.LOADING;
		let providerData = {
			"providerSettings": this.providerSettings,
			"vmTypes": this.availableVmTypes,
			"storageTypes": this.availableStorageTypes
		}

		this.cloudPricingService.updateMyprovideDetails(providerData).subscribe((result) => {
			this.cloudPricingService.updateMyprovideCarbonDetails(providerData).subscribe((result) => {
				// Ensure that the animation has enough time to be played to provide: it provides
				// a positive feedback to the user!
				setTimeout(() => {
					this.reloadData();
					this.committingState = ClrLoadingState.DEFAULT;
				}, 500);
			});
		});
	}
}

<div class="clr-row">
        <div class="card">
                <div class="card-header">
                        <div class="clr-row">
                                <div class="clr-col-lg-2 clr-col-md-2 clr-col-12">
                                        <span style="color:grey;font-size:26px;">
                                                <clr-icon shape="cloud"></clr-icon>
                                                {{region}}
                                        </span>
                                </div>
                                <div class="clr-col-lg-10 clr-col-md-10 clr-col-12">
                                        <div class="clr-control-container" *ngIf="!isRegionMap && !isRegionList">
                                                <div class="clr-select-wrapper">
                                                        <select class="clr-select"
                                                                [(ngModel)]="resource"
                                                                (ngModelChange)="reloadGraph()">
                                                                        <option>vram</option>
                                                                        <option>vcpu</option>
                                                                        <option>cpucons</option>
                                                                        <option>diskread</option>
                                                                        <option>diskwrite</option>
                                                                        <option>netin</option>
                                                                        <option>netout</option>
                                                                        <option>creditusage</option>
                                                                        <option>creditbalance</option>
                                                                        <option *ngIf="message.cloudProvider == 'aws'">surpluscreditscharged</option>
                                                                        <option>runhour</option>
                                                                        <option>computecost</option>
                                                                        <option>storagecost</option>
                                                        </select>
                                                </div>
                                                <span class="clr-subtext">select resource to display</span>
                                        </div>
                                        <clr-toggle-container clrInline style="float: right;margin-top: 0em;">
                                                <clr-toggle-wrapper>
                                                        <input type="checkbox" clrToggle name="isRegionMap" value="isRegionMap" [(ngModel)]="isRegionMap" (ngModelChange)="reloadGraph()" />
                                                        <label>
                                                                map view
                                                        </label>
                                                </clr-toggle-wrapper>
                                        </clr-toggle-container>
                                        <clr-toggle-container clrInline style="float: right;margin-top: 0em;" *ngIf="!isRegionMap">
                                                <clr-toggle-wrapper>
                                                        <input id="spec-cloud-provider-aws-regionlist" type="checkbox" clrToggle name="isRegionList" value="isRegionList" [(ngModel)]="isRegionList" (ngModelChange)="loadList()" />
                                                        <label>
                                                                list view
                                                        </label>
                                                </clr-toggle-wrapper>
                                        </clr-toggle-container>
                                        <button class="btn btn-link" clrInline style="float: right;margin-top: 0em;" (click)="export()" *ngIf="isRegionList">
				                Export
			                </button>
                                </div>
                        </div>
                </div>
                <div class="card-block">
                        <highcharts-chart
                                [Highcharts] = "highcharts"
                                [options] = "chartOptions2"
                                [constructorType]="chartConstructor" 
                                style = "width: 100%;display: block;" *ngIf="isReady2">
                        </highcharts-chart>
                        <highcharts-chart
                                [Highcharts] = "highcharts"
                                [options] = "chartOptions"
                                [callbackFunction]="chartCallback"
                                [constructorType]="chartConstructor" 
                                [(update)]="updateFlag"
                                [oneToOne]="oneToOneFlag"
                                style = "width: 100%;display: block;" *ngIf="isReady">
                        </highcharts-chart>
                        <clr-datagrid *ngIf="isRegionList">
				<clr-dg-column [clrDgField]="'id'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">ID</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'create'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Create date</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'it'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Type</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'vcpu'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">vCPU</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'vram'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">vRAM (MB)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'os'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">OS</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'status'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">State</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'monitoring'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Monitoring</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'billing'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Billing</ng-container></clr-dg-column>
				<clr-dg-column [clrDgField]="'runh'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Running hour in month</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'computeCost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Compute cost ($)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'storageCost'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Storage cost ($)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'direct_power'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Direct power (Wh)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'direct_co2'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Direct Co2 (kgCo2eq)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'grey_co2'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Grey emission (kgCo2eq)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'cpucons'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Cpu cons (%)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'diskread'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Disk read (bytes)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'diskwrite'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Disk write (bytes)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'netin'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Network in (bytes)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'netout'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Network out (bytes)</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'creditusage'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Creditusage</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'creditbalance'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Credit balance</ng-container></clr-dg-column>
                                <clr-dg-column [clrDgField]="'surpluscreditscharged'" [clrDgSortOrder]="dataOrder" *ngIf="message.cloudProvider == 'aws'"><ng-container *clrDgHideableColumn="{hidden: false}">Surplus credits charged</ng-container></clr-dg-column>
				<clr-dg-row *clrDgItems="let instance of instances" [clrDgItem]="instance">
					<clr-dg-cell>{{instance.id}}</clr-dg-cell>
					<clr-dg-cell>{{instance.name}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.create}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.it}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.vcpu}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.vram}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.os}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.status}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.monitoring}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.billing}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.runh}}</clr-dg-cell>
                                        <clr-dg-cell>{{convertToNumber(instance.computeCost)}}</clr-dg-cell>
                                        <clr-dg-cell>{{convertToNumber(instance.storageCost)}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.direct_power.toFixed(2)}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.direct_co2.toFixed(2)}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.grey_co2.toFixed(2)}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.cpucons.toFixed(2)}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.diskread}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.diskwrite}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.netin}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.netout}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.creditusage.toFixed(2)}}</clr-dg-cell>
                                        <clr-dg-cell>{{instance.creditbalance}}</clr-dg-cell>
                                        <clr-dg-cell *ngIf="message.cloudProvider == 'aws'">{{instance.surpluscreditscharged.toFixed(2)}}</clr-dg-cell>
				</clr-dg-row>
				<clr-dg-footer>
					<clr-dg-pagination #pagination [clrDgPageSize]="10">
						<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">
							entries per page
						</clr-dg-page-size>
						{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
					</clr-dg-pagination>
				</clr-dg-footer>
                        </clr-datagrid>
                </div>
        </div>
</div>
<clr-modal [(clrModalOpen)]="isInstanceInfo" [clrModalClosable]=false>
    <h3 class="modal-title">
                Instance:  
                <b>{{instance_selected.name}}</b>
    </h3>
    <div class="modal-body" style="overflow:auto">
	    <table class="table table-compact">
	    	<tbody>
                        <tr>
				<th>id</th>
                                <td>{{instance_selected.id}}</td>
			</tr>
                        <tr>
				<th>creation date</th>
                                <td>{{instance_selected.create}}</td>
			</tr>
                        <tr>
				<th>type</th>
                                <td>{{instance_selected.it}}</td>
			</tr>
                        <tr>
				<th>vRAM</th>
                                <td>{{instance_selected.vram}} MB</td>
			</tr>
                        <tr>
				<th>vCPU</th>
                                <td>{{instance_selected.vcpu}}</td>
			</tr>
                        <tr>
				<th>state</th>
                                <td>{{instance_selected.status}}</td>
			</tr>
                        <tr>
				<th>OS</th>
                                <td>{{instance_selected.os}}</td>
			</tr>
                        <tr>
				<th>monitoring</th>
                                <td>{{instance_selected.monitoring}}</td>
			</tr>
                        <tr>
				<th>billing</th>
                                <td>{{instance_selected.billing}}</td>
			</tr>
                        <tr>
				<th>running hours</th>
                                <td>{{instance_selected.runh}}</td>
			</tr>
                        <tr>
				<th>compute cost</th>
                                <td>{{instance_selected.computeCost}} $</td>
			</tr>
                        <tr>
				<th>storage cost</th>
                                <td>{{instance_selected.storageCost}} $</td>
			</tr>
                        <tr>
				<th>direct power</th>
                                <td>{{toFixedNumber(instance_selected.direct_power, 0)}} Wh</td>
			</tr>
                        <tr>
				<th>direct co2</th>
                                <td>{{toFixedNumber(instance_selected.direct_co2, 4)}} kgCo2eq</td>
			</tr>
                        <tr>
				<th>grey emission</th>
                                <td>{{toFixedNumber(instance_selected.grey_co2, 4)}} kgCo2eq</td>
			</tr>
                        <tr>
				<th>avg cpu cons</th>
                                <td>{{toFixedNumber(instance_selected.cpucons, 2)}} %</td>
			</tr>
                        <tr>
				<th>avg disk read</th>
                                <td>{{instance_selected.diskread}} bytes</td>
			</tr>
                        <tr>
				<th>avg disk write</th>
                                <td>{{instance_selected.diskwrite}} bytes</td>
			</tr>
                        <tr>
				<th>avg network in</th>
                                <td>{{instance_selected.netin}} bytes</td>
			</tr>
                        <tr>
				<th>avg network out</th>
                                <td>{{instance_selected.netout}} bytes</td>
			</tr>
                        <tr>
				<th>cpu credits usage</th>
                                <td>{{toFixedNumber(instance_selected.creditusage, 2)}} </td>
			</tr>
                        <tr>
				<th>cpu credits balance</th>
                                <td>{{instance_selected.creditbalance}} </td>
			</tr>
                        <tr *ngIf="message.cloudProvider == 'aws'">
				<th>surplus credits charged</th>
                                <td>{{toFixedNumber(instance_selected.surpluscreditscharged, 2)}} </td>
			</tr>
	    	</tbody>
	    </table>
    </div>
    <div class="modal-footer" style="margin-top:20px">
        <button type="button" class="btn btn-link" (click)="isInstanceInfo = false">OK</button>
    </div>
</clr-modal>

import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';

import { Message } from '@app/model';
import { ManagementService, ShareService } from '@app/services';

import { AllinclusiveComponent } from './allinclusive/allinclusive.component';
import { VmwareComponent } from './vmware/vmware.component';
import { DashboardCloudComponent } from '@app/provider/dashboard-cloud/dashboard-cloud.component';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements AfterViewChecked, OnInit {

	message: Message;

	isDcscope = true;
	isCo2scope = false;

	@ViewChild('alldashboard') alldashboard: AllinclusiveComponent;
	@ViewChild('vmwaredashboard') vmwaredashboard: VmwareComponent;
	@ViewChild('dashboardCloud') awsdashboard: DashboardCloudComponent;

	isAccountMgtReady: boolean = false;
	isReady: boolean = false;

	isDataReload: boolean = false;


	constructor(
		private cd: ChangeDetectorRef,
		private mgt_svc: ManagementService,
		private message_svc: ShareService) {
	 }

	ngOnInit(): void {
		this.message_svc.currentMessage.subscribe(message => this.message = message);

		this.isCo2scope = this.message.isCO2Scope;
		this.isDcscope = this.message.isDCScope;		
	}

	ngAfterViewInit(): void {
		
	}

	ngAfterViewChecked(): void {

		this.cd.detectChanges();
	}

	setVmware(): void {
		
		this.isAccountMgtReady = false;
	}

	setAllInclusive(): void {

		this.isAccountMgtReady = false;
	}

	setAws() : void {

		this.isAccountMgtReady = false;
		this.isReady = false;
		this.message.cloudProvider = 'aws';
		this.mgt_svc.getRegions('aws').pipe(first()).subscribe(
			data => {
				if(data.length > 0) {
					this.message.isValidAws = true;
					setTimeout(() =>  this.initCloud(), 100);
				}
			}
    	);
	}

	setAzure() : void {

		this.isAccountMgtReady = false;
		this.isReady = false;
		this.message.cloudProvider = 'azure';
		this.mgt_svc.getRegions('azure').pipe(first()).subscribe(
			data => {
				if(data.length > 0) {
					this.message.isValidAzure = true;
					setTimeout(() =>  this.initCloud(), 100);
				}
        	}
    	);
	}

	setStorage(): void {
		
		this.isAccountMgtReady = false;
	}

	setNetwork(): void {
		
		this.isAccountMgtReady = false;
	}

	loadRegion(): void {
	
		this.isDataReload = true;
		setTimeout(() => this.isDataReload = false, 100);
	}

	private initCloud(): void {

		this.isAccountMgtReady = true;
		setTimeout(() =>  this.isReady = true, 100);
	}
}

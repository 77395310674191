import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, range} from 'rxjs';
import * as Highcharts from 'highcharts';
import HC_stock from 'highcharts/modules/stock';
import {HighchartsChartComponent} from "highcharts-angular";
import {Chart} from "highcharts";
import {JsonloaderService, ShareService} from "@app/services";

HC_stock(Highcharts);

interface ExtremesChangeEvent {
  graphId: string,
  minRange: number,
  maxRange: number,
}

@Component({
  selector: 'app-graph-on-demand-graph',
  templateUrl: './graph-on-demand-graph.component.html',
  styleUrls: ['./graph-on-demand-graph.component.css']
})
export class GraphOnDemandGraphComponent implements OnInit {

  @Input()
  fetchNavigatorData: (min: number, max: number) => Observable<Object>;

  @Input()
  thisGraphCategory: string;

  @Input()
  fetchGraphData: (min: number, max: number) => Observable<Object>;

  @Input()
  callBackWhenCreated: () => void;

  @Input()
  selectedResources: string[];

  @Input()
  selectedCounters;

  @Input()
  onSetExtremesChange:EventEmitter<any>;

  @ViewChild('highchartsChartComponent')
  chartComponent: HighchartsChartComponent;

  getSelectedCounters() {
    return this.selectedCounters != null ? this.selectedCounters : [];
  }

  getSelectedResources() {
    return this.selectedResources != null ? this.selectedResources : [];
  }

  Highcharts: typeof Highcharts = Highcharts;
  onLoadingChange:EventEmitter<string> = new EventEmitter();

  startTime: number = 0;
  endTime: number = 2553270400000;
  minRange: number = this.startTime;
  maxRange: number = this.endTime;
  minRangeLastLoad: number = undefined;
  maxRangeLastLoad: number = undefined;
  lastSearchHash: string = "";
  dynamic_period_views = ['all', 'last_1D', 'last_30D', 'last_60D', 'last_180D', 'last_360D'];

  chartOptions: Highcharts.Options = {
    chart: {
      type: 'stockChart',
      zoomType: 'x',
      events: {}
    },
    scrollbar: {
      liveRedraw: false
    },
    time: {
      useUTC: false
    },
    series: [],
    navigator: {
      series: [],
      adaptToUpdatedData: false
    },
    yAxis: {},
    legend: {
      align: 'left',
      verticalAlign: 'top',
      borderWidth: 0
    },
    xAxis: {
      events: {
        afterSetExtremes: (event) => {
          // Quick way to ensure that we don't do the same queries in parallel. This can be caused by the fact that
          // the Highcharts navigator "is moving" thus firing several instances of the "setExtreme" event.
          let searchHash = `${this.getSelectedCounters()}_${this.getSelectedResources()}_${event.min}_${event.max}`;
          if (searchHash == this.lastSearchHash) {
            return;
          }
          this.lastSearchHash = searchHash;

          this.minRange = event.min != undefined ? event.min : this.minRange;
          this.maxRange = event.max != undefined ? event.max : this.maxRange;

          this.reloadDataChart(this.thisGraphCategory);
          // Notify parent that there was a change
          this.onSetExtremesChange.emit({
            graphId: this.thisGraphCategory,
            minRange: this.minRange,
            maxRange: this.maxRange
          });
        }
      },
      type: 'datetime',
      minRange: 3600 * 1000 // one hour
    },
    credits: {
      enabled: false
    }
  };
  updateFlag = false;

  displayGraph = true
  noDataMessage = ""

  constructor(public jsonLoaderService: JsonloaderService, public shareService: ShareService) {
  }

  ngOnInit(): void {
    this.jsonLoaderService.currentJson.subscribe((json) => {
      this.shareService.currentMessage.subscribe((msg) => {
        // Change default time interval if specified in filter
        if (msg.minTimeFilter != 0) {
          this.startTime = msg.minTimeFilter != undefined ? msg.minTimeFilter : this.startTime;
          this.minRange = msg.minTimeFilter != undefined ? msg.minTimeFilter : this.minRange;
        }
        if (msg.maxTimeFilter != 0) {
          let timeMargin = 0;
          if(this.dynamic_period_views.includes(msg.periodView)) {
            timeMargin = (24 * 3600 - 1) * 1000;
          }
		  this.endTime = msg.maxTimeFilter != undefined ? msg.maxTimeFilter + timeMargin : this.endTime;
		  this.maxRange = msg.maxTimeFilter != undefined ? msg.maxTimeFilter + timeMargin : this.maxRange;
        }
      });
    });
  }

  ngAfterContentInit() {
  }

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    console.log(`chart.chartCallback(${this.thisGraphCategory});`);

    let lastRangeFromEvent = {
      min: undefined,
      max: undefined
    }

    this.onLoadingChange.subscribe((loadingValue: string) => {
      if (loadingValue == "loading") {
        chart.showLoading();
      } else {
        chart.hideLoading();
      }
    });
    this.onSetExtremesChange.subscribe((c:ExtremesChangeEvent) => {
      if (this.thisGraphCategory == c.graphId) {
        return;
      }

      if (lastRangeFromEvent.min == c.minRange && lastRangeFromEvent.max == c.maxRange) {
        return;
      }

      lastRangeFromEvent.min = c.minRange;
      lastRangeFromEvent.max = c.maxRange;

      console.log(`${this.thisGraphCategory} => onSetExtremesChange.subscribe(origin: ${c.graphId}, min: ${c.minRange}, max: ${c.maxRange});`);

      // The following conditions is necessary as graphs can be displayed and then hidden (cf GraphOnDemandComponent.graphsPresentationMode).
      // When they are hidden, their xAxis property becomes undefined.
      if (chart.xAxis != undefined) {
        chart.xAxis[0].setExtremes(c.minRange, c.maxRange, undefined, false);
      }
    });
    let intervalTimerId = setInterval(() => {
      // The following if is required to run some tests launched with jest
      if (this != undefined && chart != undefined) {
        try {
          chart.reflow();
        } catch (e) {
          console.error(e);
          console.log("I am clearing the interval in charge of reflowing the chart");
          clearInterval(intervalTimerId);
        }
      }
    }, 1000);
  };

  reloadUi(category: string) {
    return setTimeout(() => {
      this.reloadDataChart(category);
    });
  }

  reloadDataChart(category: string, newMinRange=undefined, newMaxRange=undefined) {

    if (newMinRange != undefined) {
      this.minRange = newMinRange;
    }

    if (newMaxRange != undefined) {
      this.maxRange = newMaxRange;
    }

    let flushChart = false;

    let selectedResourcesTypes = this.getSelectedResources()
        .map(r => r["resourceType"]);
    let selectedCountersResourcesTypes = this.getSelectedCounters()
        .map(c => c.description
            .appliesTo
            .map(r => r.toLowerCase()))
        .flat()
    let commonResourceTypes = selectedResourcesTypes
        .filter((rt) => selectedCountersResourcesTypes.indexOf(rt) != -1)

    let flushMessages = "";

    if (commonResourceTypes.length == 0) {
      flushMessages = "No counter matches the selected resources";
      flushChart = true;
    }

    if (this.getSelectedCounters().length == 0) {
      flushMessages = "No counter is selected";
      flushChart = true;
    }

    if (this.getSelectedResources().length == 0) {
      flushMessages = "No resource is selected";
      flushChart = true;
    }

    if (flushChart) {
      this.displayGraph = false;
      this.noDataMessage = flushMessages;
      console.log(`Flushed chart associated to category ${category}!`);
      return;
    } else {
      this.displayGraph = true;
      this.noDataMessage = "";
    }

    // Show loading modal
    this.onLoadingChange.emit("loading");

    // Get the values
    this.fetchNavigatorData(this.startTime, this.endTime).subscribe((navigatorData: Array<[[]]>) => {
      this.fetchGraphData(this.minRange, this.maxRange).subscribe((dataSeries: Array<[[]]>) => {
        // Compute Y-axes
        let allUnits = this
          .getSelectedCounters()
          .map(c => c.description.unit)
          .filter((v, i, a) => a.indexOf(v) === i); // Ensure that there is no duplicates

        let yAxes = [];
        for (let unit of allUnits) {
          let yAxis = {
            id: unit,
            title: {
              text: unit
            },
          };
          yAxes.push(yAxis);
        }

        // Compute series
        const chartData = [];
        const navigatorChartData = [];

        for (let s of this.getSelectedResources()) {
          for (let c of this.getSelectedCounters()) {
            // Check if ressource is relevant to the type
            // @ts-ignore
            let resourceTypeUppercase = s.resourceType.toUpperCase();
            if (c.description.appliesTo.indexOf(resourceTypeUppercase) === -1) {
              continue;
            }

            // Serie
            let conversionFactor = c.description.conversionFactor;
            let resourceData = dataSeries
              .flat()
              .filter((e) => e["uuid"] == s["uuid"]);
            let values = resourceData
              .map((e) => e["dataPoints"]
                .filter((p) => p.metricName == c.metricName || p.metricName == c.metricNameMinutely)
                .map((x) => x.value * conversionFactor))
              .flat();
            let times = resourceData
              .map((e) => e["time"]);
            let dataPoints = values.map((e, i) => [times[i], e]);

            // Navigator serie
            let navigatorResourceData = navigatorData
                .flat()
                .filter((e) => e["uuid"] == s["uuid"]);
            let navigatorValues = navigatorResourceData
                .map((e) => e["dataPoints"]
                    .filter((p) => p.metricName == c.metricName || p.metricName == c.metricNameMinutely)
                    .map((x) => x.value))
                .flat();
            let navigatorTimes = navigatorResourceData
                .map((e) => e["time"]);
            let navigatorDataPoints = navigatorValues.map((e, i) => [navigatorTimes[i], e]);

            // Create the series objects
            let newSerie: Object = {
              type: "line",
              name: `${s['name']} - ${c['label']}`,
              pointInterval: 24 * 3600 * 1000,
              data: dataPoints,
              yAxis: c.description.unit,
              showInNavigator: false,
              tooltip: {
                valueSuffix: c.description.unit,
                valueDecimals: 2
              }
            };
            let newNavigatorSerie: Object = {
              type: "line",
              name: `${s['name']} - ${c['label']}_navigator`,
              pointInterval: 24 * 3600 * 1000,
              data: navigatorDataPoints
            };

            chartData.push(newSerie);
            navigatorChartData.push(newNavigatorSerie);
          }
        }

        this.onLoadingChange.emit("loaded");

        // Update the chart
        this.chartOptions.yAxis = yAxes;
        this.chartOptions.series = chartData;
        this.chartOptions.navigator.series = navigatorChartData;

        this.updateFlag = true;
      });
    });
  }
}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-cluster-distribution4]'
})
export class Clusterdistribution4Directive {

  constructor(public viewContainerRef4: ViewContainerRef) { }

}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-cluster-distribution2]'
})
export class Clusterdistribution2Directive {

  constructor(public viewContainerRef2: ViewContainerRef) { }

}

import { Component, ChangeDetectorRef, OnInit } from '@angular/core';

import { AccountService, GreenitService, ShareService } from '@app/services';

import { Json, Message, User } from '@app/model';

import { GreenitTarget } from '@app/greenit/greenit.enums';

import * as Highcharts from 'highcharts';

import xrange from "highcharts/modules/xrange";
xrange(Highcharts);


@Component({
  selector: 'app-t14',
  templateUrl: './t14.component.html',
  styleUrls: ['./t14.component.css']
})
export class T14Component implements OnInit {

  message: Message;

  private currentUser: User;

  private shared_user: string;

  jsonLoader: Json;

  data_t14: any  = {};

  isReady: boolean = false;


  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;
    this.chart.showLoading();

    this.setData();

    this.chart.hideLoading();      
  };

  options: Highcharts.Options = {
    chart: {
      height: '250px',
      backgroundColor:'rgba(255, 255, 255, 0.0)',
      zoomType: 'xy',
      resetZoomButton: {
        position: {
          align: 'left',
          verticalAlign: 'top', 
          x: 20,
          y: 10
        }
      }
    },
    credits: {
      enabled: false
    },
    time: {
	    useUTC : false
    },
    exporting: {
      enabled: false
    },
    title: {
       text: ''
    },
    xAxis: {
      type: 'datetime',
      ordinal: false
    },
    yAxis: [
      { id: 'yaxis-1', title: { text: 'kg CO2eq'}},
    ],
    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      }
    }
  }


  constructor(private authentication_svc: AccountService, private message_svc: ShareService,
    private greenit_svc: GreenitService, private cd: ChangeDetectorRef) {
  }

  /**
   * 
   */
  ngOnInit(): void {
    this.authentication_svc.user.subscribe(user => this.currentUser = user);
	  this.message_svc.currentMessage.subscribe(message => this.message = message);

    //XXX Check shared filter
    this.shared_user = this.currentUser.login;
    if(this.message.isShared) {
      this.shared_user = "root";
    }

    this.greenit_svc.getGreenitData(this.shared_user, this.message.currentFilter, GreenitTarget.DASHBOARD_DATA).subscribe(
      data2 => {
        this.data_t14 = data2;

        if(this.data_t14 != undefined)
          this.isReady = true;
      }
    );
  }

  /**
   * 
   */
  private setData(): void {

    //XXX
    let granularity = "MONTHLY";

    let datas: Array<Array<Number>>= [];
    let datas_20: Array<Array<Number>>= [];
    let datas_0: Array<Array<Number>>= [];

    let nb_entries: number = this.data_t14.length * 0.1;

    let j = 0;
    let indice = 0;

    let last_value = 0;
    let cumul_avg = 0;

    let first_value = 0;

    for (let i in this.data_t14) {

      let lastTime = this.data_t14[i].time;
      let sum = this.data_t14[i].sum;

      // FIX for month
      if(granularity == "MONTHLY") {
        if(Number(i) == 0 || Number(i) == (this.data_t14.length - 1)) {
          if(Number(i) == 0)
            first_value = sum;
          continue;
        }
      }

      // Save points
      let time = parseInt(lastTime);
      let value = Number((parseFloat(sum)).toFixed(2));

      datas[indice] = [time,value];

      if(j < nb_entries) {
        j++;
        last_value = value;

        // FIX for month
        if(granularity == "MONTHLY") {
          cumul_avg = (last_value - first_value) / j;
        } else {
          cumul_avg = last_value / j;
        }

        cumul_avg = Number(cumul_avg.toFixed(2));

        datas_20[indice] = [time,Number(value.toFixed(2))];
        datas_0[indice] = [time,Number(value.toFixed(2))];

      } else {
        last_value += cumul_avg;

        datas_20[indice] = [time,Number((last_value*0.8).toFixed(2))];
        datas_0[indice] = [time,Number(last_value.toFixed(2))];
      }

      indice++;
    }

    // Push to chart
    this.chart.addSeries({
        name: "Current cumulative CO2 emissions (kg CO2eq)",
        color: '#0B610B',
        type: 'line',
        data: datas,
    },false);

    this.chart.addSeries({
        name: "Linear CO2 emissions -20% (kg CO2eq)",
        //color: '#0B610B',
        type: 'line',
        dashStyle: 'ShortDashDot',
        data: datas_20,
    },false);

    this.chart.addSeries({
        name: "Linear CO2 emissions (kg CO2eq)",
        //color: '#0B610B',
        type: 'line',
        dashStyle: 'LongDashDotDot',
        data: datas_0,
    });
  }

}
import { Component, OnInit } from '@angular/core';

import { ClrDatagridSortOrder } from '@clr/angular';

import { JSONTarget, Message } from '@app/model';

import { JsonloaderService, ShareService } from '@app/services';


@Component({
  selector: 'app-savingsplan-detail',
  templateUrl: './savingsplan-detail.component.html',
  styleUrls: ['./savingsplan-detail.component.css']
})
export class SavingsplanDetailComponent implements OnInit {

  message: Message;

  dataOrder = ClrDatagridSortOrder.ASC;

  savingplans: any = [];

  resizes: any = [];

  selected_sp: any;


  constructor(
    private json_svc: JsonloaderService,
    private message_svc: ShareService
  ) { }

  ngOnInit(): void {

    this.message_svc.currentMessage.subscribe(message => this.message = message);
    let account = this.message.cloudAccount;
    let region_str: string = '';
    region_str = this.message.cloudRegion.replace(/-/g,"");

    this.json_svc.getData('root','cloud_' + account + '_' + region_str, JSONTarget.CLOUD_SAVINGSPLAN_SOLUTION).subscribe(
      data => {
        this.savingplans = data;
      }
    );

    this.json_svc.getData('root','cloud_' + account + '_' + region_str, JSONTarget.CLOUD_RESIZING).subscribe(
      data => {
        this.filterResa(data);
      }
    );
  }

  exportSavingsPlan() {

    let res = ["Family", "Compute cost On Demand ($/month)", "Compute cost 1 Year ($/month)", "Estimated commitment 1 Year ($/hour)", "Compute cost 3 Years ($/month)", "Estimated commitment 3 Years ($/hour)"].join(',') + '\n';
                res += Object.values<any>(this.savingplans).map(sp =>
                        [sp.family, sp.computeCost.toFixed(), sp.computeCost1Y.toFixed(), sp.commitment1Y.toFixed(4), sp.computeCost3Y.toFixed(), sp.commitment3Y.toFixed(4)].join(",")
                ).join('\n');

    let file_type = "text/csv;charset=utf-8;";

    // Create temp link
    let blob: Blob = new Blob([res], { type: file_type });
    let fileName = 'opportunities_savingsplan.csv';
    let objectUrl: string = URL.createObjectURL(blob);

    let a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  private filterResa(data: any): void {

    for(let obj of data) {
      if(obj.type == "resa")
        this.resizes.push(obj);
    }
  }
}

<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="tree"></clr-icon>
			&nbsp;Servers efficiency
		</h5>
	</div>
	<div class="card-block" id="dashboard-t9-core" style="height:255px">
		<div class="card-text">
			<div *ngIf="isReady">
				<div class="clr-row">
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
						<clr-icon class="is-solid" shape="server"></clr-icon>
						&nbsp;<span class="label" style="background:#476888;color:white;border-color:#476888;">Less efficient server</span>
						<br>
						<label class="clr-control-label" style="padding-top:10px;margin-left:25px;word-wrap:anywhere;">{{worst_cee.name}}&nbsp;({{worst_cee.cee}})</label>
					</div>
					<div class="clr-col-lg-6 clr-col-md-6 clr-col-6">
						<clr-icon class="is-solid" shape="server"></clr-icon>
						&nbsp;<span class="label" style="background:white;color:black;">More efficient server</span>
						<br>
						<label class="clr-control-label" style="padding-top:10px;margin-left:25px;word-wrap:anywhere;">{{best_cee.name}}&nbsp;({{best_cee.cee}})</label>
					</div>
				</div>
				<highcharts-chart [Highcharts]="Highcharts" [options]="options" [callbackFunction]="chartCallback" style="margin-top:1.1rem;width:100%;display:block;" ></highcharts-chart>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
		</div>
	</div>
	<div class="card-footer" *ngIf="isReady">
		<button class="btn btn-link" (click)="displayModalEfficiency()">Servers efficiency list</button>
        <button class="btn btn-link" (click)="displayModalOptimization()" style="float:right;">Servers optimization</button>
	</div>
</div>

<!-- GREENIT EE -->
<clr-modal [(clrModalOpen)]="efficiency_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Servers efficiency list</h3>
    <div class="modal-body">
        <clr-datagrid *ngIf="efficiency_modal">
            <clr-dg-column [clrDgField]="'name'"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'cee'" [clrDgSortOrder]="dataOrder">
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left">
                        CEE
                        <span class="tooltip-content dt-tooltip">Current Energy Efficiency = Power / Number of virtual machines</span>
                    </div>
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'tee'">
                <ng-container *clrDgHideableColumn="{hidden: false}">
                    <div role="tooltip" class="tooltip tooltip-sm tooltip-bottom-left">
                        TEE
                        <span class="tooltip-content dt-tooltip">Theorical Energy Efficiency = Power / Theoretical max number of virtual machines"</span>
                    </div>
                </ng-container>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'wh'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual kWh</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'co2'"><ng-container *clrDgHideableColumn="{hidden: false}">Annual CO2 (kg CO2eq)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'power'"><ng-container *clrDgHideableColumn="{hidden: false}">Power (W)</ng-container></clr-dg-column>

            <clr-dg-row *clrDgItems="let host of efficiency_data" [clrDgItem]="host">
                <clr-dg-cell>{{host.name}}</clr-dg-cell>
                <clr-dg-cell><font [attr.color]="(host.vmnb == 0) ? 'red' : ''">{{host.cee}}</font></clr-dg-cell>
                <clr-dg-cell>{{number(host.tee).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.wh/1000).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.co2).toFixed(2)}}</clr-dg-cell>
                <clr-dg-cell>{{number(host.power).toFixed(2)}}</clr-dg-cell>
            </clr-dg-row>              
            <clr-dg-footer>
                <clr-dg-pagination #cee_page [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{cee_page.firstItem + 1}} - {{cee_page.lastItem + 1}} of {{cee_page.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>

<!-- GREENIT MODAL OPTIMIZATION -->
<clr-modal [(clrModalOpen)]="optimization_modal" [clrModalClosable]=true [clrModalSize]="'xl'">
    <h3 class="modal-title">Energy Optimization</h3>
    <div class="modal-body">
        <div class="clr-row" style="width:100%;">
            <div style="float:left;text-align:center;width:50%;">
                <p style="font-size: 18px;margin:0"><b>Current annual kWh</b></p>
                <p style="font-size: 18px;padding-bottom:10px" id="greenit-prediction-current"></p>
                <div>Now you have : {{optimization_current_servers}} server(s)</div>
            </div>
            <div style="float:right;text-align:center;width:50%;">
                <p style="font-size: 18px;margin:0"><b>New annual kWh</b></p>
                <p style="font-size: 18px;padding-bottom:10px" id="greenit-prediction-new"></p>
                <div>After optimization you will have : {{optimization_new_servers}} server(s)</div>
            </div>
        </div>
        <hr>

            <clr-datagrid *ngIf="optimization_modal">
                <clr-dg-action-bar style="margin-top:0px">
                    <div class="btn-group" style="float:right">
                        <button type="button" class="btn btn-sm btn-link" (click)="exportOptimization()">
                            Export to CSV
                        </button>
                    </div>
                </clr-dg-action-bar>
                <clr-dg-column [clrDgField]="'NEWFLAG'" [clrDgSortOrder]="dataOrderDesc">&nbsp;</clr-dg-column>
                <clr-dg-column [clrDgField]="'NAME'"><ng-container *clrDgHideableColumn="{hidden: false}">New server or server to keep</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'OLDKWH'"><ng-container *clrDgHideableColumn="{hidden: false}">Current annual kWh</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'KWH'"><ng-container *clrDgHideableColumn="{hidden: false}">New annual kWh (estimation)</ng-container></clr-dg-column>
                <clr-dg-column [clrDgField]="'OLDSERVERS'"><ng-container *clrDgHideableColumn="{hidden: false}">Servers that will be replaced</ng-container></clr-dg-column>
            
                <clr-dg-row *clrDgItems="let entry of optimization_json.SERVERS" [clrDgItem]="entry">
                    <clr-dg-cell><span *ngIf="entry.NEWFLAG" style="width:36px" class="badge badge-danger">NEW</span></clr-dg-cell>
                    <clr-dg-cell>{{entry.NAME}}</clr-dg-cell>
                    <clr-dg-cell>{{number(entry.OLDKWH).toFixed(0)}}</clr-dg-cell>
                    <clr-dg-cell>{{number(entry.KWH).toFixed(0)}}</clr-dg-cell>
                    <clr-dg-cell>{{entry.OLDSERVERS.split(',').join(', ')}}</clr-dg-cell>
                </clr-dg-row>              
                <clr-dg-footer>
                    <clr-dg-pagination #opti_page [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                        {{opti_page.firstItem + 1}} - {{opti_page.lastItem + 1}} of {{opti_page.totalItems}} entries
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
       

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>
<table class="table">
	<thead>
		<tr>
			<th class="left">Scope</th>
			<th class="left">Type</th>
			<th class="left">Recurrence</th>
			<th>Average thread capacity</th>
			<th>Average ram capacity (GB)</th>
			<th>Server cost ({{globalCurrency}})</th>
			<th>Ratio</th>
			<th>vCPU cost ({{globalCurrency}})</th>
			<th>vRAM cost ({{globalCurrency}}/GB)</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td class="left">all Servers</td>
			<td class="left">generic cost</td>
			<td class="left">annual</td>
			<td>{{model.serverThread}}</td>
			<td>{{model.serverRam}}</td>
			<td style="width:15%;vertical-align:top">
				<div class="clr-form-control" style="flex-direction:column;margin-top:unset;align-items:center;">
                                	<div class="clr-input-wrapper">
						<b><input class="clr-input" type="number" [(ngModel)]="model.srvCostGeneric" (ngModelChange)="updateCostServer()" style="width:6em;text-align:left"></b>
                                                <clr-icon class="clr-validate-icon" *ngIf="model.srvCostGeneric == null" shape="exclamation-circle"></clr-icon>
                                	</div>
                        	</div>
			</td>
			<td>
				<clr-range-container [clrRangeHasProgress]="true" style="margin-top:0px;align-items:center;text-align:left">
					<input id="generic" type="range" clrRange [(ngModel)]="model.serverRatio" name="three" (ngModelChange)="updateRatio()"/>
					<clr-control-helper>{{model.serverRatio}}% assigned to vCPU</clr-control-helper>
				</clr-range-container>
			</td>
			<td style="width:15%;vertical-align:top">
				<div class="clr-form-control" style="flex-direction:column;margin-top:unset;align-items:center;">
                                	<div class="clr-input-wrapper">
						<b><input class="clr-input" id="cost.sto.gen" type="number" [(ngModel)]="model.cpuCostGeneric" (ngModelChange)="updateCostVcpu()" style="width:6em;text-align:left"></b>
                                                <clr-icon class="clr-validate-icon" *ngIf="model.cpuCostGeneric == null" shape="exclamation-circle"></clr-icon>
                                	</div>
                       		</div>
			</td>
			<td style="width:15%;vertical-align:top">
				<div class="clr-form-control" style="flex-direction:column;margin-top:unset;align-items:center;">
                                	<div class="clr-input-wrapper">
						<b><input class="clr-input" id="cost.sto.gen" type="number" [(ngModel)]="model.ramCostGeneric" (ngModelChange)="updateCostVram()" style="width:6em;text-align:left"></b>
                                                <clr-icon class="clr-validate-icon" *ngIf="model.vramCost == null" shape="exclamation-circle"></clr-icon>
                                	</div>
                        	</div>
			</td>
	</tbody>
</table>

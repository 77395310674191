import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-yearly-cal]'
})
export class YearlycalDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { DiskGroup, VsanStorage } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class VsanService {


  constructor(private http: HttpClient) { }


  getVsanData(uuid: string, start: number, end: number) {
    return this.http.get<VsanStorage>(`${environment.apiUrl}/` + 'vsan/getdata/' + uuid + '&' + start + '&' + end).pipe(map(data => {
      return data;
    }));
  }

  getVsanDiskGroup(uuid: string, start: number, end: number) {
    return this.http.get<DiskGroup>(`${environment.apiUrl}/` + 'vsan/getdiskgroup/' + uuid + '&' + start + '&' + end).pipe(map(data => {
      return data;
    }));
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { UploadType } from '../upload/upload.enums';


@Injectable({
  providedIn: 'root'
})
export class UploadService {
  /**
   *
   */
  constructor(private http: HttpClient) { }


  /**
   * Logo
   */
  downloadLogo() {
    return this.http.get(`${environment.apiUrl}/` + 'upload/getlogo/', { responseType: 'blob'});
  }

  checkLogo() {
    return this.http.get(`${environment.apiUrl}/` + 'upload/checklogo/', { responseType: 'blob'});
  }

  deleteLogo() {
    return this.http.put<Object>(`${environment.apiUrl}/` + 'upload/deletelogo/', { responseType: 'blob'});
  }

  getLogo() {
    return this.http.get(`${environment.apiUrl}/` + 'upload/getlogo', { responseType: 'blob'});
  }

  /**
   * Upload for Logo & Updates
   */
  upload(file: File, target: UploadType): Observable<any> {
    var formData: any = new FormData();
    formData.append("file", file);

    switch(target) {
      case UploadType.UPDATES:
        return this.http.post(`${environment.apiUrl}/` + 'upload/updates', formData, {
          reportProgress: true,
          observe: 'events'
        }).pipe( );
      break;
      case UploadType.REPORT:
        return this.http.post(`${environment.apiUrl}/` + 'upload/logo', formData, {
          reportProgress: true,
          observe: 'events'
        }).pipe( );
      break;
      default:
      break;
    }
  }

}

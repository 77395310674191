<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="resource-pool"></clr-icon>
			&nbsp;Physical resources over the last 24 hours
		</h5>
	</div>
	<div class="card-block" id="dashboard-t4-core">
		<div class="card-text">
			<div *ngIf="isReady">
			<label style="padding-left:47px;"><b>{{cpu_used}}</b> GHz Used ({{cpu_cons}}%)</label>
			<label style="float:right;"><b>{{cpu_free}}</b> GHz Free</label>
			<div class="progress-block" style="margin-bottom:0;">
				<label>CPU</label>
				<div [ngClass]="cpu_class">
					<progress value="{{cpu_cons}}" max="100"></progress>
				</div>
			</div>
			<span class="badge" [ngClass]="cpu_prov_class" style="margin-left:47px;font-size:10px;">{{cpu_commitper}}% commited to VMs</span>
			<label style="float:right;"><b>{{cpu_total}}</b> GHz Total</label>
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
			<label style="padding-left:47px;"><b>{{ram_used}}</b> {{ram_unit}} Used ({{ram_cons}}%)</label>
			<label style="float:right;"><b>{{ram_free}}</b> {{ram_unit}} Free</label>
			<div class="progress-block" style="margin-bottom:0;">
				<label>RAM</label>
				<div [ngClass]="ram_class">
					<progress value="{{ram_cons}}" max="100"></progress>
				</div>
			</div>
			<span class="badge" [ngClass]="ram_prov_class" style="margin-left:47px;font-size:10px;">{{ram_commitper}}% commited to VMs</span>
			<label style="float:right;"><b>{{ram_total}}</b> {{ram_unit}} Total</label>
			<div class="clr-col-lg-12 clr-col-md-12 clr-col-12">&nbsp;</div>
			<label style="padding-left:47px;"><b>{{sto_used}}</b> {{sto_unit}} Used ({{sto_cons}}%)</label>
			<label style="float:right;"><b>{{sto_free}}</b> {{sto_unit}} Free</label>
			<div class="progress-block" style="margin-bottom:0;">
				<label>DISK</label>
				<div [ngClass]="sto_class">
					<progress value="{{sto_cons}}" max="100"></progress>
				</div>
			</div>
			<span class="badge" [ngClass]="sto_prov_class" style="margin-left:47px;font-size:10px;">{{sto_commitper}}% commited to VMs</span>
			<label style="float:right;"><b>{{sto_total}}</b> {{sto_unit}} Total</label>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
        	</div>
	</div>
</div>
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { AccountService, ShareService, JsonloaderService } from '@app/services';
import { User, Message, JSONTarget } from '@app/model';
import { CapaplanComponent } from '../capaplan.component';
import { CapaAction, CapaEntity } from '../capaplan.enums';
import { Observable, Subscription, zip } from 'rxjs';

@Component({
  selector: 'app-capaentity',
  templateUrl: './capaentity.component.html',
  styleUrls: ['./capaentity.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CapaentityComponent implements OnInit {

  @ViewChild('dtable') dtable: ElementRef;
  @ViewChild('addtable') add_table: ElementRef;
  @ViewChild('edittable') edit_data_table: ElementRef;
  @ViewChild('legendtable') edit_legend_table: ElementRef;

  @Input('target') target: CapaEntity;

  @Input('config') config_data : Observable<void>;
  private configSubscription: Subscription;

  
  message: Message;
  currentUser: User;

  title: string;
  icon: string;

  // FOR LIST MODAL
  element_modal : boolean;
  modal_data : any;
  dtOptions: any;

  // FOR SIMULATION
  simulation_id_suffix: string;
  simulation_quantity_suffix: string;
  simulation_input_suffix: string;
  simulation_value_separator: string;

  simulation_config_json: any;

  // FOR EDIT
  edit_modal: boolean;
  edit_row : HTMLTableRowElement;

  /**
   * 
   */
  constructor(private capaplanComponent: CapaplanComponent,private authenticationService: AccountService,
     private message_svc: ShareService, private renderer: Renderer2,
     private json_svc: JsonloaderService) {
    
    this.title = "";
    this.icon = "";
    this.element_modal = false;
    this.edit_modal = false;
    this.simulation_id_suffix = this.capaplanComponent.simulation_id_suffix;
    this.simulation_quantity_suffix = this.capaplanComponent.simulation_quantity_suffix;
    this.simulation_input_suffix = this.capaplanComponent.simulation_input_suffix;
    this.simulation_value_separator = this.capaplanComponent.simulation_value_separator;
   }

  /**
   * 
   */
  ngOnInit(): void {
    this.authenticationService.user.subscribe(user => this.currentUser = user);
    this.message_svc.currentMessage.subscribe(message => this.message = message);

    // WARN Need to init here ...
    this.dtOptions = {
      dom: 'Bfrtlip',
      pagingType: 'full_numbers',
	  	pageLength: 10,
		  lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
		  processing: true,
	  	destroy: true,
      order: [
          [1, 'asc']
      ],
      language: {
          //"emptyTable": "Processing, please wait ...",
          search: '',
	  		  searchPlaceholder: " search ..."
      },
      columnDefs: [
          { className: 'dt-body-left', targets: [1] },
          { className: 'dt-body-center', targets: "_all" },
      ],
      buttons: [
	  		{ extend: 'csv', className: 'btn btn-link btn-sm', title: 'capa_' + this.target },
	  		{ extend: 'copy', className: 'btn btn-link btn-sm', title: 'Capacity Planning ' + this.target },
	  		{ extend: 'print', className: 'btn btn-link btn-sm', title: 'Capacity Planning ' + this.target }
	  	]
    };

    let elements_data: Observable<any>;

    // Init modal data
    switch(this.target) {
      case CapaEntity.HOST:
        this.title = "Physical Machines";
        this.icon = "host";
        // GET SIMU DATA
        elements_data = this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.CAPA_SRV);
      break;
      case CapaEntity.VM:
        this.title = "Virtual Machines";
        this.icon = "vm";
        elements_data =  this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.CAPA_VM);
      break;
      case CapaEntity.DS:
        this.title = "Datastores";
        this.icon = "storage";
        elements_data =  this.json_svc.getData(this.currentUser.login,this.message.currentFilter, JSONTarget.CAPA_DS);
      break;
      default:
        console.error("capaentity.target not set properly !");
      break;
    }

    zip(elements_data, this.config_data).subscribe(
      all => {
          this.modal_data = all[0];
          this.simulation_config_json = all[1];
          
          // Load simulation
          if(this.simulation_config_json != null)
            setTimeout(() => {
              this.loadSimulation();
            });
      }
    );

  }

  /**
   * 
   */
  private loadSimulation(): void {
    let data: any;

    if(this.simulation_config_json !== undefined) {
      switch(this.target) {
        case CapaEntity.VM:
          data = this.simulation_config_json.vm;
        break;
        case CapaEntity.HOST:
          data = this.simulation_config_json.server;
          break;
        case CapaEntity.DS:
          data = this.simulation_config_json.datastore;
          break;
      }

      if (data !== undefined) {
        for (var i = 0; i < data.length; i++) {
          let query = '[data-id~="' + data[i].ID + this.simulation_input_suffix + '"]';
          let list: NodeList = this.dtable.nativeElement.querySelectorAll(query);
            
          if (list.length > 0) {
              let select = (list[0] as HTMLSelectElement);
              select.value = data[i].ACTION;

              let query2 = 'option[value=' + data[i].ACTION + ']'
              let option = select.querySelector(query2) as HTMLOptionElement;
              this.addRemoveElement(option, data[i]);
            }
        }
      }

    }
  }

  /**
   * 
   */
  addRemoveElement(option: any, element: any): void {

    let select: HTMLSelectElement = option.parentElement as HTMLSelectElement;
    select.style.borderBottomColor = "#b3b3b3";
    select.style.borderWidth = "1px";

    // Action only if event comes from an HTMLOptionElement
    if(option instanceof HTMLOptionElement) {

      let rowElt: HTMLElement;
      let objTable: HTMLTableElement = this.add_table.nativeElement;

      // Looking for ROW & delete it if exists
      let query = '[data-id~="' + element.ID + this.simulation_id_suffix + '"]';
      let list: NodeList = objTable.querySelectorAll(query);
      if (list.length > 0) {
        rowElt = list[0] as HTMLTableRowElement;
        rowElt.remove();
      }

      // Add new table entry
      if (option.value != "") {
    
        let row: HTMLTableRowElement = this.renderer.createElement('tr');
        row.dataset.id = element.ID + this.simulation_id_suffix;
        row.dataset.action = option.value;
        row.dataset.value = this.buildElementData(element);

        let cell0 = row.insertCell(0); 
        cell0.style.paddingTop = "0.5em";
        cell0.style.paddingBottom = "0.2em";

        let cell1 = row.insertCell(1); 
        cell1.style.paddingTop = "0.5em";
        cell1.style.paddingBottom = "0.2em";
        cell1.style.paddingRight = "0.5em";
        cell1.style.wordWrap = "break-word";
        cell1.style.fontSize = "15px";

        let cell2 = row.insertCell(2);
        cell2.style.paddingTop = "0.5em";
        cell2.style.paddingBottom = "0.2em";
        cell2.style.paddingRight = "0.5em";

        let cell3 = row.insertCell(3);
        cell3.style.paddingTop = "0.5em";
        cell3.style.paddingBottom = "0.2em";
        cell3.style.paddingRight = "0.5em";

        let cell4 = row.insertCell(4);
        cell4.style.paddingTop = "0.5em";
        cell4.style.paddingBottom = "0.2em";
        cell4.style.paddingRight = "0.5em";

        switch(option.value) {
          case CapaAction.ADD:
            select.style.borderBottomColor = "green";
            select.style.borderWidth = "1px";
            select.style.color = "green";

            cell0.innerHTML = "<b><clr-icon shape='plus' style='width:50px'></b>";
            cell1.innerHTML = "<label class='clr-control-label' style='color:green;font-size:12px'>" + element.NAME + "</label>";
            //cell2.innerHTML = "<input data-id='" + element.ID + this.simulation_quantity_suffix + "' type='number' min='1' value='" + (element.QUANTITY == null ? 1 : element.QUANTITY) + "' style='width:3em;text-align:right'>";
            
            let nbInput: HTMLInputElement = this.renderer.createElement('input');
            nbInput.dataset.id = element.ID + this.simulation_quantity_suffix;
            nbInput.type = "number";
            nbInput.min = "1";
            nbInput.value = (element.QUANTITY == null ? 1 : element.QUANTITY);
            nbInput.style.width = "3em";
            nbInput.style.textAlign = "right";
            nbInput.addEventListener("keyup", () => this.checkInputNumber(nbInput));
            cell2.appendChild(nbInput);

            cell3.innerHTML = "<div onmouseover=\"this.style.cursor='pointer'\"><clr-icon shape='pencil' style='width:15px'></clr-icon></div>";
            cell4.innerHTML = "<div onmouseover=\"this.style.cursor='pointer'\"><clr-icon shape='trash' style='width:15px'></clr-icon></div>";
          break;
          case CapaAction.DEL:
            select.style.borderBottomColor = "red";
            select.style.borderWidth = "1px";
            select.style.color = "red";

            cell0.innerHTML = "<clr-icon shape='minus' style='width:50px'>";
            cell1.innerHTML = "<label class='clr-control-label' style='color:red;font-size:12px'>" + element.NAME + "</label>";
            cell2.innerHTML = "<input data-id='" + element.ID + this.simulation_quantity_suffix + "' type='hidden' value='1' style='width:50px'>-";
            cell3.innerHTML = "<div>-</div>";
            cell4.innerHTML = "<div onmouseover=\"this.style.cursor='pointer'\"><clr-icon shape='trash' style='width:15px'></clr-icon></div>";
          break;
        }

        this.renderer.appendChild(objTable, row);

        if(option.value == CapaAction.ADD)
          this.renderer.listen(cell3, "click", () => this.showEditModal(row));
        this.renderer.listen(cell4, "click", () => this.deleteElement(objTable,row,select));

      }
    }
    
  }

 /**
   * 
   */
  private deleteElement(objTable: HTMLTableElement, row: HTMLTableRowElement, select: HTMLSelectElement) {
    this.renderer.removeChild(objTable, row);
    select.style.borderBottomColor = "#b3b3b3";
    select.style.borderWidth = "1px";
    select.value = "";
  }

   /**
   * 
   */
  private buildElementData(element: any) : string {
    let value: string;
    switch(this.target){
      case CapaEntity.VM:
        value = element.ID + this.simulation_value_separator + element.NAME +this.simulation_value_separator + element.RAMCAP + this.simulation_value_separator + element.CPUTHREADNB + this.simulation_value_separator + element.DISKCAP + this.simulation_value_separator + element.RAMCONS + this.simulation_value_separator + element.CPUCONSMHZ;
      break;
      case CapaEntity.HOST:
        value = element.ID + this.simulation_value_separator + element.NAME + this.simulation_value_separator + element.RAMCAP + this.simulation_value_separator + element.CPUTHREADNB + this.simulation_value_separator + element.RAMCONS + this.simulation_value_separator + element.CPUCONSMHZ;
      break;
      case CapaEntity.DS:
        value = element.ID + this.simulation_value_separator + element.NAME + this.simulation_value_separator + element.DISKCAP;
      break;
    }
    return value;
  }

  /**
   * 
   */
  showEditModal(row: HTMLTableRowElement): void {
    this.edit_modal = true;
    this.edit_row = row;
    this.addEditData(row);
    this.addEditLegend();
  }

  /**
   * 
   */
  private addEditLegend(): void {

    let objTable = this.edit_legend_table.nativeElement;
    while (objTable.hasChildNodes()) {
      objTable.removeChild(objTable.lastChild);
    }

    let rowA: HTMLTableRowElement;
    let rowB: HTMLTableRowElement;
    let cell0: HTMLTableDataCellElement;
    let cell1: HTMLTableDataCellElement;

    switch(this.target) {
      case CapaEntity.VM:
        rowA = this.renderer.createElement('tr');
        cell0 = rowA.insertCell(0);
        cell0.style.padding = "1em";
        cell0.innerHTML= "<label class='clr-control-label'>VRAM</label>";
        cell1 = rowA.insertCell(1);
        cell1.style.padding = "1em";
        cell1.innerHTML= "<label>The quantity of RAM allocated (in MB)</label>";

        rowB = this.renderer.createElement('tr');
        cell0 = rowB.insertCell(0);
        cell0.style.padding = "1em";
        cell0.innerHTML= "<label class='clr-control-label'>VCPU</label>";
        cell1 = rowB.insertCell(1);
        cell1.style.padding = "1em";
        cell1.innerHTML= "<label>The number of VCPU allocated</label>";

        let rowC: HTMLTableRowElement = this.renderer.createElement('tr');
        cell0 = rowC.insertCell(0);
        cell0.style.padding = "1em";
        cell0.innerHTML= "<label class='clr-control-label'>VDISK</label>";
        cell1 = rowC.insertCell(1);
        cell1.style.padding = "1em";
        cell1.innerHTML= "<label>The space disk provisionned (in MB)</label>";

        let rowD: HTMLTableRowElement = this.renderer.createElement('tr');
        cell0 = rowD.insertCell(0);
        cell0.style.padding = "1em";
        cell0.innerHTML= "<label class='clr-control-label'>RAM</label>";
        cell1 = rowD.insertCell(1);
        cell1.style.padding = "1em";
        cell1.innerHTML= "<label>The average RAM consumption (in MB)</label>";

        let rowE: HTMLTableRowElement = this.renderer.createElement('tr');
        cell0 = rowE.insertCell(0);
        cell0.style.padding = "1em";
        cell0.innerHTML= "<label class='clr-control-label'>CPU</label>";
        cell1 = rowE.insertCell(1);
        cell1.style.padding = "1em";
        cell1.innerHTML= "<label>The average CPU consumption (in MHz)</label>";

        this.renderer.appendChild(objTable, rowA);
        this.renderer.appendChild(objTable, rowB);
        this.renderer.appendChild(objTable, rowC);
        this.renderer.appendChild(objTable, rowD);
        this.renderer.appendChild(objTable, rowE);

      break;
      case CapaEntity.HOST:
        rowA = this.renderer.createElement('tr');
        cell0 = rowA.insertCell(0);
        cell0.style.padding = "1em";
        cell0.innerHTML= "<label class='clr-control-label'>RAM Capacity</label>";
        cell1 = rowA.insertCell(1);
        cell1.style.padding = "1em";
        cell1.innerHTML= "<label>The capacity of RAM (in MB)</label>";

        rowB = this.renderer.createElement('tr');
        cell0 = rowB.insertCell(0);
        cell0.style.padding = "1em";
        cell0.innerHTML= "<label class='clr-control-label'>Logical Cores</label>";
        cell1 = rowB.insertCell(1);
        cell1.style.padding = "1em";
        cell1.innerHTML= "<label>The total number of logical cores (or threads)/label>";

        this.renderer.appendChild(objTable, rowA);
        this.renderer.appendChild(objTable, rowB);

      break;
      case CapaEntity.DS:
        rowA = this.renderer.createElement('tr');
        cell0 = rowA.insertCell(0);
        cell0.style.padding = "1em";
        cell0.innerHTML= "<label class='clr-control-label'>Datastore Capacity</label>";
        cell1 = rowA.insertCell(1);
        cell1.style.padding = "1em";
        cell1.innerHTML= "<label>The space disk capacity (in MB)</label>";

        this.renderer.appendChild(objTable, rowA);
      break;
    }

  }

  /**
   * 
   */
  private addEditData(row: HTMLTableRowElement): void {
    
    let objTable = this.edit_data_table.nativeElement;
    while (objTable.hasChildNodes()) {
      objTable.removeChild(objTable.lastChild);
    }

    let values = row.dataset.value.split(this.simulation_value_separator);

    // ROW HEADER
    let rowA: HTMLTableRowElement = this.renderer.createElement('tr');
    
    let cellA0 = rowA.insertCell(0);
    cellA0.style.paddingBottom = "1em";
    //cellA0.innerHTML= "<label class='clr-control-label'>Template</label>";

    let cellA1 = rowA.insertCell(1);
    cellA1.style.paddingBottom = "1em";

    let cellA2: HTMLTableDataCellElement;

    // ROW DATA
    let rowB: HTMLTableRowElement = this.renderer.createElement('tr');
    rowB.style.fontSize = '15px';

    let cellB0 = rowB.insertCell(0);
    cellB0.style.paddingBottom = "1em";
    cellB0.style.paddingRight = "0.5em";
    cellB0.innerHTML = "<label class='clr-control-label'>" + values[1] + "</label>";

    let cellB1 = rowB.insertCell(1);
    cellB1.style.paddingBottom = "1em";

    let cellB2: HTMLTableDataCellElement;

    let b1Input: HTMLInputElement = this.renderer.createElement('input');
    let b2Input: HTMLInputElement = this.renderer.createElement('input');
        
    switch(this.target) {
      case CapaEntity.VM:
        // HEADER
        cellA1.innerHTML = "<label class='clr-control-label'>VRAM (MB)</label>";
        cellA2 = rowA.insertCell(2);
        cellA2.style.paddingBottom = "1em";
        cellA2.innerHTML = "<label class='clr-control-label'>VCPU</label>";
        let cellA3 = rowA.insertCell(3);
        cellA3.style.paddingBottom = "1em";
        cellA3.innerHTML = "<label class='clr-control-label'>VDISK (MB)</label>";
        let cellA4 = rowA.insertCell(4);
        cellA4.style.paddingBottom = "1em";
        cellA4.innerHTML = "<label class='clr-control-label'>RAM (MB)</label>";
        let cellA5 = rowA.insertCell(5);
        cellA5.style.paddingBottom = "1em";
        cellA5.innerHTML = "<label class='clr-control-label'>CPU (MHz)</label>";
        
        // DATA
        //cellB1.innerHTML = '<input id="capa-ramcap-submit" type="number" min="1" value="' + values[2] + '" style="width:4em;text-align:right" />';
        b1Input.id = "capa-ramcap-submit";
        b1Input.type = "number";
        b1Input.min = "1";
        b1Input.value = values[2];
        b1Input.style.width = "4em";
        b1Input.style.textAlign = "right";
        b1Input.addEventListener("keyup", () => this.checkInputNumber(b1Input));
        cellB1.appendChild(b1Input);

        cellB2 = rowB.insertCell(2);
        cellB2.style.paddingBottom = "1em";
        //cellB2.innerHTML = '<input id="capa-cputhreadnb-submit" type="number" min="1" value="' + values[3] + '" style="width:3em;text-align:right" />';
        b2Input.id = "capa-cputhreadnb-submit";
        b2Input.type = "number";
        b2Input.min = "1";
        b2Input.value = values[3];
        b2Input.style.width = "3em";
        b2Input.style.textAlign = "right";
        b2Input.addEventListener("keyup", () => this.checkInputNumber(b2Input));
        cellB2.appendChild(b2Input);

        let cellB3 = rowB.insertCell(3);
        cellB3.style.paddingBottom = "1em";
        //cellB3.innerHTML = '<input id="capa-disk-submit" type="number" min="1" value="' + values[4] + '" style="width:5em;text-align:right" />';
        let b3Input: HTMLInputElement = this.renderer.createElement('input');
        b3Input.id = "capa-disk-submit";
        b3Input.type = "number";
        b3Input.min = "1";
        b3Input.value = values[4];
        b3Input.style.width = "5em";
        b3Input.style.textAlign = "right";
        b3Input.addEventListener("keyup", () => this.checkInputNumber(b3Input));
        cellB3.appendChild(b3Input);

        let cellB4 = rowB.insertCell(4);
        cellB4.style.paddingBottom = "1em";
        //cellB4.innerHTML = '<input id="capa-ramcons-submit" type="number" min="0" value="' + values[5] + '" style="width:4em;text-align:right" />';
        let b4Input: HTMLInputElement = this.renderer.createElement('input');
        b4Input.id = "capa-ramcons-submit";
        b4Input.type = "number";
        b4Input.min = "1";
        b4Input.value = values[5];
        b4Input.style.width = "4em";
        b4Input.style.textAlign = "right";
        b4Input.addEventListener("keyup", () => this.checkInputNumber(b4Input));
        cellB4.appendChild(b4Input);

        let cellB5 = rowB.insertCell(5);
        cellB5.style.paddingBottom = "1em";
        //cellB5.innerHTML = '<input id="capa-cpucons-submit" type="number" min="0" value="' + values[6] + '" style="width:4em;text-align:right" />';
        let b5Input: HTMLInputElement = this.renderer.createElement('input');
        b5Input.id = "capa-cpucons-submit";
        b5Input.type = "number";
        b5Input.min = "1";
        b5Input.value = values[6];
        b5Input.style.width = "4em";
        b5Input.style.textAlign = "right";
        b5Input.addEventListener("keyup", () => this.checkInputNumber(b5Input));
        cellB5.appendChild(b5Input);

      break;
      case CapaEntity.HOST:
        // HEADER
        cellA1.innerHTML= "<label class='clr-control-label'>RAM Capacity (MB)</label>";
        cellA2 = rowA.insertCell(2);
        cellA2.style.paddingBottom = "1em";
        cellA2.innerHTML= "<label class='clr-control-label'>Logical Cores</label>";
        // DATA
        //cellB1.innerHTML = '<input id="capa-ramcap-submit" type="number" min="1" value="' + values[2] + '" style="width:5em;text-align:right" />';
        b1Input.id = "capa-ramcap-submit";
        b1Input.type = "number";
        b1Input.min = "1";
        b1Input.value = values[2];
        b1Input.style.width = "5em";
        b1Input.style.textAlign = "right";
        b1Input.addEventListener("keyup", () => this.checkInputNumber(b1Input));
        cellB1.appendChild(b1Input);

        cellB2 = rowB.insertCell(2);
        cellB2.style.paddingBottom = "1em";
        //cellB2.innerHTML = '<input id="capa-cputhreadnb-submit" type="number" min="1" value="' + values[3] + '" style="width:3em;text-align:right" />';
        b2Input.id = "capa-cputhreadnb-submit";
        b2Input.type = "number";
        b2Input.min = "1";
        b2Input.value = values[3];
        b2Input.style.width = "3em";
        b2Input.style.textAlign = "right";
        b2Input.addEventListener("keyup", () => this.checkInputNumber(b2Input));
        cellB2.appendChild(b2Input);
      break;
      case CapaEntity.DS:
        // HEADER
        cellA1.innerHTML= "<label class='clr-control-label'>Datastore Capacity (MB)</label>";
        // DATA
        //cellB1.innerHTML = '<input id="capa-disk-submit" type="number" min="0" value="' + values[2] + '" style="width:6em;text-align:right" />';
        b1Input.id = "capa-disk-submit";
        b1Input.type = "number";
        b1Input.min = "1";
        b1Input.value = values[2];
        b1Input.style.width = "6em";
        b1Input.style.textAlign = "right";
        b1Input.addEventListener("keyup", () => this.checkInputNumber(b1Input));
        cellB1.appendChild(b1Input);
      break;
    }

    this.renderer.appendChild(objTable, rowA);
    this.renderer.appendChild(objTable, rowB);
  }

  /**
   * 
   */
  private saveEditData(): void {
    let values = this.edit_row.dataset.value.split(this.simulation_value_separator);
    let rowid = values[0];
    let rowname = values[1];

    let newvalue: string;

    let input1: HTMLInputElement;
    let input2: HTMLInputElement;
    let value1: number = 0;
    let value2: number = 0;
    let value3: number = 0;
    let value4: number = 0;
    let value5: number = 0;
  

    switch(this.target) {
      case CapaEntity.VM:
        input1 = document.getElementById('capa-ramcap-submit') as HTMLInputElement;
        value1 = Number(input1.value);
        input2 = document.getElementById('capa-cputhreadnb-submit') as HTMLInputElement;
        value2 = Number(input2.value);
        let input3: HTMLInputElement;
        input3 = document.getElementById('capa-disk-submit') as HTMLInputElement;
        value3 = Number(input3.value);
        let input4: HTMLInputElement;
        input4 = document.getElementById('capa-ramcons-submit') as HTMLInputElement;
        value4 = Number(input4.value);
        let input5: HTMLInputElement;
        input5 = document.getElementById('capa-cpucons-submit') as HTMLInputElement;
        value5 = Number(input5.value);
      break;
      case CapaEntity.HOST:
        input1 = document.getElementById('capa-ramcap-submit') as HTMLInputElement;
        value1 = Number(input1.value);
        input2 = document.getElementById('capa-cputhreadnb-submit') as HTMLInputElement;
        value2 = Number(input2.value);
      break;
      case CapaEntity.DS:
        input1 = document.getElementById('capa-disk-submit') as HTMLInputElement;
        value1 = Number(input1.value);
      break;
    }
  
    newvalue = rowid + this.simulation_value_separator + rowname + this.simulation_value_separator + value1 + this.simulation_value_separator + value2
              + this.simulation_value_separator + value3 + this.simulation_value_separator + value4 + this.simulation_value_separator + value5;
    this.edit_row.dataset.value = newvalue;
    
  }

  /**
   * 
   */
  showElementModal(){
    this.element_modal = true;
    // Update datatable positions
    setTimeout(() => {
      let filter: HTMLCollectionOf<Element> = document.getElementsByClassName('dataTables_filter');
      (filter[0] as HTMLElement).style.float = "left";
      let buttons: HTMLCollectionOf<Element> = document.getElementsByClassName('dt-buttons');
      if(buttons.length > 0)
        (buttons[0] as HTMLElement).style.float = "right";
    });
  }

  /**
   * 
   */
  closeEditModal() {
    this.edit_modal = false;
    this.saveEditData();
  }

  /**
   * 
   */
  closeModal() {
    this.element_modal = false;
  }

  /**
  * 
  */
  checkInputNumber(element: HTMLInputElement): void {
    element.value = element.value.replace('.', '').replace(',', ''); // FIX replace decimal symbol
    if (element.value.trim() == "" || Number(element.value) <= 0) {
      element.value = "1";
    }
  }

}

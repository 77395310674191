<!-- GLOBAL INFOS -->
<div class="clr-row" style="box-shadow: 0 -1px 0 inset #dedede;padding-bottom:20px;">
    <div style="display:flex;flex-grow:1;flex-shrink:1;flex-basis:auto;flex-direction:column;">
        <div style="text-align:left;display:block;padding-bottom:12px;">Environment</div>
        <div style="display:flex;flex-wrap:nowrap;">
            <div style="margin-left:30px;text-align:left;display:block;flex-grow:1;flex-shrink:1;flex-basis:auto;">
                <clr-icon shape="organization" size="16"></clr-icon>
                <span>&nbsp;Organizations&nbsp;:&nbsp;</span>
                <span>{{this.global_orgs}}</span>
            </div>
            <div style="text-align:left;display:block;flex-grow:1;flex-shrink:1;flex-basis:auto;">
                <clr-icon shape="cloud" size="16"></clr-icon>
                <span>&nbsp;VDCs&nbsp;:&nbsp;</span>
                <span>{{this.global_vdcs}}</span>
            </div>
        </div>
    </div>
    <div style="display:flex;flex-grow:1;flex-shrink:1;flex-basis:auto;flex-direction:column;padding-left:72px;border-left:1px solid;border-left-color:#dedede">
        <div style="text-align:left;display:block;padding-bottom:12px;">Resources used</div>
        <div style="display:flex;flex-wrap:nowrap;">
            <div style="margin-left:30px;text-align:left;display:block;flex-grow:1;flex-shrink:1;flex-basis:auto;">
                <clr-icon shape="cpu" size="16"></clr-icon>
                <span>&nbsp;CPU&nbsp;:&nbsp;</span>
                <span>{{this.global_cpu}}</span>
                <span>&nbsp;GHz</span>
            </div>
            <div style="text-align:left;display:block;flex-grow:1;flex-shrink:1;flex-basis:auto;">
                <clr-icon shape="memory" size="16"></clr-icon>
                <span>&nbsp;RAM&nbsp;:&nbsp;</span>
                <span>{{this.global_ram}}</span>
                <span>&nbsp;GB</span>
            </div>
            <div style="text-align:left;display:block;flex-grow:1;flex-shrink:1;flex-basis:auto;">
                <clr-icon shape="storage" size="16"></clr-icon>
                <span>&nbsp;Storage&nbsp;:&nbsp;</span>
                <span>{{this.global_disk}}</span>
                <span>&nbsp;GB</span>
            </div>
        </div>
    </div>
</div>

<!-- ORGS-->
<div>
    <div class="clr-row" style="margin-top:60px;">
        <div class="clr-col-10">
            <span>
                <clr-icon shape="organization" size="20"></clr-icon>
                <h5 style="display:inline;">&nbsp;Organizations</h5>
                <span *ngIf="!selected_org" style="margin-left:10px;" class="label label-info">(Pick an organization to view its VDC)</span>
            </span>
        </div>
        <div class="clr-col-2">
            <button class="btn btn-link" style="position:relative;float:right;" (click)="exportOrgs()">
                Export Organizations
            </button>
        </div>
    </div>

    <div class="datagrid-limit-height" >
        <clr-datagrid [(clrDgSingleSelected)]="selected_org" (clrDgSingleSelectedChange)="orgChanged($event)">
            <clr-dg-column [clrDgField]="'orgname'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'vdcs'"><ng-container *clrDgHideableColumn="{hidden: false}">VDCs</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'avgVdcCpuUsed'"><ng-container *clrDgHideableColumn="{hidden: false}">CPU used (GHz)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'avgVdcRamUsed'"><ng-container *clrDgHideableColumn="{hidden: false}">Memory used (GB)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'avgVdcDiskUsed'"><ng-container *clrDgHideableColumn="{hidden: false}">Storage used (GB)</ng-container></clr-dg-column>
                    
            <clr-dg-row *clrDgItems="let org of vdc_infos" [clrDgItem]="org">
                <clr-dg-cell>{{org.orgname}}</clr-dg-cell>
                <clr-dg-cell>{{org.vdcs}}</clr-dg-cell>
                <clr-dg-cell>{{formatNumber(org.avgVdcCpuUsed, 1000)}}</clr-dg-cell>
                <clr-dg-cell>{{formatNumber(org.avgVdcRamUsed, 1024)}}</clr-dg-cell>
                <clr-dg-cell>{{formatNumber(org.avgVdcDiskUsed, 1024)}}</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>

        </clr-datagrid>
    </div>
</div>

<!-- VDCS -->
<div  style="margin-top:60px;" *ngIf="selected_vdcs.length > 0 && selected_org != null">
    <div class="clr-row" >
        <div class="clr-col-10">
            <span>
                <clr-icon shape="cloud" size="20"></clr-icon>
                <h5 style="display:inline;">&nbsp;VDCs list for&nbsp;&nbsp;</h5>
                <span class="label label-blue">{{ selected_org.orgname }}<span></span></span>
                <span *ngIf="!selected_vdc" style="margin-left: 10px;" class="label label-info">(Pick a VDC to enable visualization)</span>
            </span>
        </div>
        <div class="clr-col-2">
            <button class="btn btn-link" style="position:relative;float:right;" (click)="exportVDCs()">
                Export VDCs
            </button>
        </div>
    </div>

    <div class="datagrid-limit-height">
        <clr-datagrid [(clrDgSingleSelected)]="selected_vdc" (clrDgSingleSelectedChange)="vdcChanged($event)">
            <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="dataOrder"><ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'rpuuid'"><ng-container *clrDgHideableColumn="{hidden: false}">Res.Pool identifier</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'allocationModel'"><ng-container *clrDgHideableColumn="{hidden: false}">Allocation model</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'vmquota'"><ng-container *clrDgHideableColumn="{hidden: false}">VM quota</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'avgVdcCpuUsed'"><ng-container *clrDgHideableColumn="{hidden: false}">CPU used (GHz)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'avgVdcCpuLimit'"><ng-container *clrDgHideableColumn="{hidden: false}">CPU limit (GHz)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'avgVdcRamUsed'"><ng-container *clrDgHideableColumn="{hidden: false}">Memory used (GB)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'avgVdcRamLimit'"><ng-container *clrDgHideableColumn="{hidden: false}">Memory limit (GB)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'avgVdcDiskUsed'"><ng-container *clrDgHideableColumn="{hidden: false}">Storage used (GB)</ng-container></clr-dg-column>
            <clr-dg-column [clrDgField]="'avgVdcDiskLimit'"><ng-container *clrDgHideableColumn="{hidden: false}">Storage limit (GB)</ng-container></clr-dg-column>
                   
            <clr-dg-row *clrDgItems="let vdc of selected_vdcs" [clrDgItem]="vdc">
                <clr-dg-cell>{{vdc.name}}</clr-dg-cell>
                <clr-dg-cell>{{vdc.rpuuid}}</clr-dg-cell>
                <clr-dg-cell>{{vdc.allocationModel}}</clr-dg-cell>
                <clr-dg-cell>{{vdc.vmquota}}</clr-dg-cell>
                <clr-dg-cell>{{formatNumber(vdc.avgVdcCpuUsed, 1000)}}</clr-dg-cell>
                <clr-dg-cell>{{formatNumber(vdc.avgVdcCpuLimit, 1000)}}</clr-dg-cell>
                <clr-dg-cell>{{formatNumber(vdc.avgVdcRamUsed, 1024)}}</clr-dg-cell>
                <clr-dg-cell>{{formatNumber(vdc.avgVdcRamLimit, 1024)}}</clr-dg-cell>
                <clr-dg-cell>{{formatNumber(vdc.avgVdcDiskUsed, 1024)}}</clr-dg-cell>
                <clr-dg-cell>{{formatNumber(vdc.avgVdcDiskLimit, 1024)}}</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="10">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">entries per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} entries
                </clr-dg-pagination>
            </clr-dg-footer>

        </clr-datagrid>
    </div>
</div>

<!-- VDC INFOS-->
<div  style="margin-top:60px;" *ngIf="selected_vdc">
    <div class="clr-row">
        <div class="clr-col-10">
            <span>
                <clr-icon shape="line-chart" size="20"></clr-icon>
                <h5 style="display:inline;">&nbsp;Resources information for&nbsp;&nbsp;</h5>
                <span class="label label-blue">{{ selected_vdc.name }}<span></span></span>
            </span>
        </div>

        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
            <div class="card">
                <div class="card-header card-ajust">
                    <h5>
                        <clr-icon shape="line-chart"></clr-icon>
                        &nbsp;Physical resources over the last 24 hours
                    </h5>
                </div>
                <div class="card-block">
                    <table style="width:100%">
                        <tr>
                            <td style="width:10%;font-size:.65rem;position:relative;"><b>CPU</b></td>
                            <td>
                                <app-vdcviewbar [target]="cpu_res" [data]="vdc_synth"></app-vdcviewbar>
                            </td>
                        </tr>
                        <tr><td style="padding-bottom:20px;"></td></tr>
                        <tr>
                            <td style="width:10%;font-size:.65rem;position:relative;"><b>MEMORY</b></td>
                            <td>
                                <app-vdcviewbar [target]="ram_res" [data]="vdc_synth"></app-vdcviewbar>
                            </td>
                        </tr>
                        <tr><td style="padding-bottom:20px;"></td></tr>
                        <tr>
                            <td style="width:10%;font-size:.65rem;position:relative;"><b>STORAGE</b></td>
                            <td>
                                <app-vdcviewbar [target]="disk_res" [data]="vdc_synth"></app-vdcviewbar>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
            <div class="card">
                <div class="card-header card-ajust">
                    <h5>
                        <clr-icon shape="line-chart"></clr-icon>
                        &nbsp;Evolution information
                    </h5>
                </div>
                <div class="card-block" style="height:425px;">
                    <app-vdcevolution [selected_vdc]="selected_vdc" [data_prediction]="vdc_prediction"></app-vdcevolution>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService, ShareService, SettingsService, MeasurementService, JsonloaderService, GraphOnDemandService, LicenseService, NetscopeService } from '@app/services';

import { DashboardSettings, FilterMgt, Message, User, Settings, IhmSettingsTarget, IhmSettings, Options } from '@app/model';

import { VmwareComponent } from '@app/dashboard/vmware/vmware.component';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  @Output('reload') reloadChange = new EventEmitter<boolean>();
  pushReload(reload: any) {
    this.reloadChange.emit(reload);
  }
  
  isDcscope = true;
  isCo2scope = false;

  message: Message;

  currentUser: User;

  valid_filter: boolean = true;

  modal_settings: boolean = false;

  reload_filter: boolean = false;
  
  userSettings: DashboardSettings;
  reco_settings: IhmSettings;
  god_settings: IhmSettings;

  modal_confirm_periods: boolean = false;
  settings_period_start: number;
  settings_period_end: number;
  settings_period_lastupdate: string;
  period_start: any;
  period_end: any;
  period_days: any;
  period_days_names = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  settings_period_update: boolean = false;
  period_changed: boolean = false;
  timezone: string = '---';
  settings_retention_all: string = '1 year';
  settings_retention_update: boolean = false;

  settings_threshold_level: number;
  settings_threshold_noise: number;
  settings_threshold_update: boolean = false;

  graphOnDemandSettings = {
    ihmSetting: undefined,
    counters: {
      "cpu": [],
      "ram": [],
      "disk": [],
      "network": []
    },
    defaultCounter: {
      "cpu": "",
      "ram": "",
      "disk": "",
      "network": ""
    },
    changed: false
  }

  netscopeSettings = {
    agentUrl :  "",
    tokenApi :  "",
    netscopeModuleIsInLicence: false,
    isUploading: false,
    showSettings: false
  }

  /**
   * 
   */
  constructor(private authentication_svc: AccountService, private message_svc: ShareService,
    private settings_svc: SettingsService, private measurement_svc: MeasurementService,
    private dashboardComponent: VmwareComponent, private router: Router,
    private json_svc: JsonloaderService, private graphOnDemandService: GraphOnDemandService,
    private licenseService: LicenseService, private netscopeService: NetscopeService) {
    }


  /**
   * 
   */
  ngOnInit(): void {
    this.authentication_svc.user.subscribe(user => this.currentUser = user);
    this.message_svc.currentMessage.subscribe(message => this.message = message);

    this.isCo2scope = this.message.isCO2Scope;
	  this.isDcscope = this.message.isDCScope;

    // Dashboard 
    this.settings_svc.currentDS.subscribe(
      userSettings => { 
        this.userSettings = userSettings;
        this.setSettingsDashboard();
    });

    //Thresholds
    this.settings_svc.currentReco.subscribe(
      recoSettings => {
        this.reco_settings = recoSettings;
    });

    // GraphOnDemand
    this.settings_svc.currentGraphOnDemandSettings.subscribe((ihmSettingsGraphOndemand) => {
      this.god_settings = ihmSettingsGraphOndemand;

      this.graphOnDemandSettings.ihmSetting = ihmSettingsGraphOndemand;

      this.graphOnDemandSettings.defaultCounter["cpu"] = ihmSettingsGraphOndemand.p2;
      this.graphOnDemandSettings.defaultCounter["ram"] = ihmSettingsGraphOndemand.p1;
      this.graphOnDemandSettings.defaultCounter["disk"] = ihmSettingsGraphOndemand.p3;
      this.graphOnDemandSettings.defaultCounter["network"] = ihmSettingsGraphOndemand.p4;

      for (let category of ["cpu", "ram", "disk", "network"]) {
        this.graphOnDemandService.getCounters(category, "").subscribe(countersResult => {
          let countersNames = countersResult.map((c) => c.metricName);
          let categoryDefaultCounter = this.graphOnDemandSettings.defaultCounter[category];
          if (countersNames.indexOf(categoryDefaultCounter) == -1) {
            this.graphOnDemandSettings.defaultCounter[category] = `AVG_${categoryDefaultCounter}`;
          }
          this.graphOnDemandSettings.counters[category] = countersResult;
        })
      }
    });
  }

  callNetscopeSettings(): void {
    this.netscopeSettings.showSettings = this.currentUser.login === "root";
    this.licenseService.licenseInfo.subscribe((licenceInfo) => {
      this.netscopeSettings.netscopeModuleIsInLicence = ((licenceInfo.moduleslicense >> 1) & 1) === 1;
      this.netscopeService.getNetscopeSettings().subscribe((settings) => {
        if (this.netscopeSettings.netscopeModuleIsInLicence) {
          // Load the agent URL
          settings.filter((optionValue) => optionValue.option === "agent_url").map((agentUrlOptionValue) => {
            this.netscopeSettings.agentUrl = agentUrlOptionValue.value;
          })
          settings.filter((optionValue) => optionValue.option === "token_api").map((tokenApiOptionValue) => {
            this.netscopeSettings.tokenApi = tokenApiOptionValue.value;
          })
        }
      });
    });
  }

  /**
   * 
   */
  callSettings(): void {

    this.modal_settings = true;
    this.reload_filter = false;

    if(this.currentUser.login != "root") {
      const filter: FilterMgt = this.getFilter(this.message.currentFilter);
	    
      if(filter != undefined) {
        if(filter.shared)
          this.valid_filter = false;
        else
          this.valid_filter = true;
	    }
    } else {
	    this.valid_filter = true;
    }

    // Netscope
    this.callNetscopeSettings();
  }

  /**
   * 
   */
  setSettingsDashboard(): void {
    if(!this.currentUser.isDashboardT1)
      this.userSettings.isT1Disabled = true;
    if(!this.currentUser.isDashboardT2)
      this.userSettings.isT2Disabled = true;
    if(!this.currentUser.isDashboardT3)
      this.userSettings.isT3Disabled = true;
    if(!this.currentUser.isDashboardT4)
      this.userSettings.isT4Disabled = true;
    if(!this.currentUser.isDashboardT5)
      this.userSettings.isT5Disabled = true;
    if(!this.currentUser.isDashboardT6)
      this.userSettings.isT6Disabled = true;
  }

  /**
   * 
   */
  updateDashboardSettings(event): void {

    var counter = 'isT1';
    switch(event.target.id) {
      case 'set-dashboard-t2':
        counter = 'isT2';
        break;
      case 'set-dashboard-t3':
        counter = 'isT3';
        break;
      case 'set-dashboard-t4':
        counter = 'isT4';
        break;
      case 'set-dashboard-t5':
        counter = 'isT5';
        break;
      case 'set-dashboard-t6':
        counter = 'isT6';
        break;
      case 'set-dashboard-t7':
        counter = 'isT7';
        break;
      case 'set-dashboard-t8':
        counter = 'isT8';
        break;
      case 'set-dashboard-t9':
        counter = 'isT9';
        break;
      default:
        break;
    }
  
    if (event.target.checked) {
      this.settings_svc.updateSettings(this.currentUser.login, counter, true).subscribe(
        error => {
          if(error != null)
            console.log(error);
        }
      );
      if(this.router.url == "/dashboard") {
        switch(event.target.id) {
          case 'set-dashboard-t1':
            this.dashboardComponent.reloadCard('t1');
            break;
          case 'set-dashboard-t2':
            this.dashboardComponent.reloadCard('t2');
            break;
          case 'set-dashboard-t3':
            this.dashboardComponent.reloadCard('t3');
            break;
          case 'set-dashboard-t4':
            this.dashboardComponent.reloadCard('t4');
            break;
          case 'set-dashboard-t5':
            this.dashboardComponent.reloadCard('t5');
            break;
          case 'set-dashboard-t6':
            this.dashboardComponent.reloadCard('t6');
            break;
          default:
            break;
        }
      }
    } else {
      this.settings_svc.updateSettings(this.currentUser.login, counter, false).subscribe(
        error => {
          if(error != null)
            console.log(error);
        }
      );
      switch(event.target.id) {
        case 'set-dashboard-t1':
          this.dashboardComponent.unLoadCard('t1');
          break;
        case 'set-dashboard-t2':
          this.dashboardComponent.unLoadCard('t2');
          break;
        case 'set-dashboard-t3':
          this.dashboardComponent.unLoadCard('t3');
          break;
        case 'set-dashboard-t4':
          this.dashboardComponent.unLoadCard('t4');
          break;
        case 'set-dashboard-t5':
          this.dashboardComponent.unLoadCard('t5');
          break;
        case 'set-dashboard-t6':
          this.dashboardComponent.unLoadCard('t6');
          break;
        default:
          break;
      }
    }
  }

  /**
   * 
   */
  setSettingsPeriod():void {

    // Default values
    this.settings_period_start = 10;
    this.settings_period_end = 22;
    let dow = "3,4,5,6,7";

    this.period_start = [];
    this.period_end = [];
    this.period_days = [];

    // Init select & days buttons
    for (let i = 0; i < 24; i++) {
      this.period_start.push({
        value: i,
        text: i.toString().padStart(2, '0') + ":00"
      });

      this.period_end.push({
        value: i + 1,
        text: i.toString().padStart(2, '0') + ":59"
      });
    }

    for (let i = 0; i < 7; i++) {
      this.period_days.push({
        caption: this.period_days_names[i],
        state: false
      });
    }

    // Get measurement settings
    this.measurement_svc.getSettings().subscribe(
      data => {
        for(let option of data) {
          switch(option.name) {
            case "DAYS_OF_WEEK" :
              dow = option.value;
              this.initPeriodsDays(dow);
            break;
            case "TIME_RANGE_START":
              this.settings_period_start = parseInt(option.value.split(":")[0]);
            break;
            case "TIME_RANGE_END":
              this.settings_period_end = parseInt(option.value.split(":")[0]);
            break;
            case "LAST_UPDATED":
              this.settings_period_lastupdate = option.value;
            break;
          }
        }
      },
      error => {
        this.initPeriodsDays(dow);
      }
    );

    this.settings_period_update = false;

    //Timezone
    this.settings_svc.getTimeZone().subscribe(
	    data => {
        if(data.tz)
	    	  this.timezone = data.tz;
	    },
	    error => {
		    console.log(error);
	    }
    );
  }

  /**
   * 
   */
  private initPeriodsDays(dow: string) {
    //XXX need to convert dow -> array index 
    // In JS : Sunday = dow 1 but 0 in the array
    // AND need to add +1 for the analysis
    // Ex: Sunday = Monday 00:00 -->  2 (dow) becomes 0 (array), etc ...
    // ... but warning for the last dow
    let days = dow.split(",").map(e => parseInt(e));
    days.forEach(i => {
      
      if(Number(i)) {
        let idx = (i < 2) ? (7 - i) : (i - 2);
        this.period_days[idx].state = true;
      }
    });
  }

  /**
   * 
   */
  updatePeriodsSettings(): void {
    this.modal_confirm_periods = false;

    this.settings_period_update = false;

    let measurementSettings: Settings[];
    measurementSettings = new Array();

    let range_start: Settings =  {
      name :  "TIME_RANGE_START",
      value : this.settings_period_start.toString().padStart(2, '0') + ":00"

    };
    measurementSettings.push(range_start);
    
    let range_end: Settings =  {
      name :  "TIME_RANGE_END",
      value : this.settings_period_end.toString().padStart(2, '0') + ":00"

    };
    measurementSettings.push(range_end);

    //XXX need to convert array index -> dow
    // See explications in initPeriodsDays
    let dow = []
    this.period_days.forEach((el, i) => {
      if (el.state) {
        let idx = (i < 6) ? (i + 2) : 1;
        dow.push(idx)
      }
    });

    let days_of_week: Settings =  {
      name :  "DAYS_OF_WEEK",
      value : dow.join(',')

    };
    measurementSettings.push(days_of_week);

    // Delete WD jsons & save settings
    this.measurement_svc.saveSettings(measurementSettings);

    // Get settings from database (in order to verify settings)
    setTimeout(() => {
      this.setSettingsPeriod();
    }, 1000);

    // Period changed
    this.period_changed = true;

    // Reload filter
    this.reload_filter = true;
  }

  /**
   * 
   */
  setSettingsThreshold():void {
    this.settings_svc.reload(this.currentUser.login, this.message.currentFilter, IhmSettingsTarget.RECOMMENDATION, false);
    this.settings_threshold_update = false;
  }

  /**
   * 
   */
  updateSettingsThreshold(): void {
    this.settings_threshold_update = false;

    if(this.reco_settings.user == '')
        this.reco_settings.user = this.currentUser.login;
    if(this.reco_settings.filter == '')
      this.reco_settings.filter = this.message.currentFilter;

    // Save settings
    this.settings_svc.saveIhmSettings(this.reco_settings);

    // Reload filter
    this.reload_filter = true;

  }

  setSettingsGod(): void {
    this.settings_svc.reload(this.currentUser.login, this.message.currentFilter, IhmSettingsTarget.GOD, false);
  }

  /**
   * 
   */
  updateSettingsGod(): void {
    this.god_settings.p1 = this.graphOnDemandSettings.defaultCounter.ram;
    this.god_settings.p2 = this.graphOnDemandSettings.defaultCounter.cpu;
    this.god_settings.p3 = this.graphOnDemandSettings.defaultCounter.disk;
    this.god_settings.p4 = this.graphOnDemandSettings.defaultCounter.network;

    // Save settings
    this.settings_svc.saveIhmSettings(this.god_settings);
  }

  /**
   *
   */
  updateNetscopeSettings(): void {
    let params = {
      "agent_url": this.netscopeSettings.agentUrl,
      "token_api": this.netscopeSettings.tokenApi,
    };
    let jsonParams = JSON.parse(JSON.stringify(params));
    this.netscopeSettings.isUploading = true;
    this.netscopeService.updateNetscopeSettings(jsonParams).subscribe((result) => {
      setTimeout(() => {
        this.netscopeSettings.isUploading = false;
      }, 300);
    })
  }

  /**
   * 
   */
  reloadFilter() {
    this.modal_settings = false;

    // Delete jsons
    this.json_svc.cleanJson(this.currentUser.login, this.message.currentFilter).subscribe();

    // Notify the parent whether or not to reload the filter
    let options: Options = {
      reload: this.reload_filter,
      wd: this.period_changed,
    }
    this.pushReload(options);
  }

  /**
   * 
   */
  private getFilter(name: string): FilterMgt {
	  return this.message.filterList.find(filter => filter.name === name);
  }

  /**
   * 
   */
  updateRetention() {
    this.settings_retention_update = false;
    let month: number = 0;

    switch(this.settings_retention_all) {
      case "1 year":
        month = 12;
        break;
      case "2 years":
        month = 24;
        break;
      case "3 years":
        month = 36;
        break;
      default:
        break;
    }

    this.settings_svc.updateRetention(this.currentUser.login, month).subscribe(
      error => {
        if(error != null)
          console.log(error)
      }
    );
  }

  /**
	 * 
	 */
	formatDate(time: string): string {
    if(time == undefined)
      return "---";
    else
	    return new Date(time).toLocaleDateString('en-EN');
	}
}

<div class="card addScroll">
	<div class="card-header card-ajust">
		<h5>
			<clr-icon shape="vm"></clr-icon>
			&nbsp;Informations about VM
		</h5>
	</div>
	<div class="card-block">
		<div class="card-text">
			<div *ngIf="isReady">
                <app-greenitvm></app-greenitvm>
			</div>
			<div *ngIf="!isReady">
				<strong class="center">No data available ! <br> <br> Please try to refresh the page or rebuild the filter.</strong>
			</div>
		</div>
	</div>
</div>
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-cluster-distribution11]'
})
export class Clusterdistribution11Directive {

  constructor(public viewContainerRef11: ViewContainerRef) { }

}

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import { AccountService, JsonloaderService, ShareService } from '@app/services';

import { Json, JSONTarget, Message, RecoVm, User } from '@app/model';


@Component({
  selector: 'app-recommendationresizingdisk',
  templateUrl: './recommendationresizingdisk.component.html',
  styleUrls: ['./recommendationresizingdisk.component.css']
})
export class RecommendationresizingdiskComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions3: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  message: Message;

  math = Math;

  reco_vms_data: RecoVm[] = [];

  disk_model: RecoVm[] = [];

  private disk_table: any;

  private currentUser: User;

  private search: string = '';


  constructor(
	private authentication_svc: AccountService,
	private json_svc: JsonloaderService,
	private message_svc: ShareService,
        private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {

	this.authentication_svc.user.subscribe(user => this.currentUser = user);
	this.message_svc.currentMessage.subscribe(message => this.message = message);

	this.initDtOptions();

	this.json_svc.getData(this.currentUser.login, this.message.currentFilter, JSONTarget.RECO_VM).subscribe(
		data => {
	  		this.reco_vms_data = data;
	  		if(this.reco_vms_data.length > 0)
	  			this.buildData();
      		}
      	);
  }

  ngAfterViewInit(): void {
	this.dtTrigger.next();
  }

  ngOnDestroy(): void {
	this.dtTrigger.unsubscribe();
  }

  ngAfterViewChecked(): void {

	if(this.search != this.message.recoResizeSearch) {
		this.search = this.message.recoResizeSearch;
		if(this.disk_table == undefined) {
			this.disk_table = $('#reco-resizing-disk-list').dataTable();
			if(this.disk_table == undefined)
				this.disk_table.api().search(this.search).draw();
		} else {
			this.disk_table.api().search(this.search).draw();
		}
	}

        this.cd.detectChanges();
  }

  private buildData(): void {

	for(var i = 0; i < this.reco_vms_data.length; i++) {
		if(this.reco_vms_data[i].disk_saved > 0)
			this.disk_model.push(this.reco_vms_data[i]);
	}
	this.disk_model.sort((a, b) => (a.disk_saved > b.disk_saved) ? 1 : -1);
	this.reloadTable();
  }

  private reloadTable(): void {

	  if(this.dtElement.dtInstance != undefined) {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                	dtInstance.destroy();
                	this.dtTrigger.next();
          	});
	  }
  }

  private initDtOptions(): void {

	this.dtOptions3 = {
		pagingType: 'full_numbers',
		pageLength: 10,
	  	processing: true,
		destroy: true,
	  	language: {
			loadingRecords: "loading .. ",
			zeroRecords: "no data"
	  	},
	  	dom: ''
  	}
  }
}

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[load-vm-list]'
})
export class VmlistDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

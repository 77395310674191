import { Component, OnInit } from '@angular/core';

import { ShareService } from '@app/services';

import { BadgeDistri, ClusterSynthesis, Message } from '@app/model';

import * as Highcharts from 'highcharts';

import xrange from 'highcharts/modules/xrange';
xrange(Highcharts);

import { isPair } from '../../assets/js/tools.js';


@Component({
  selector: 'app-clusterdistribution',
  templateUrl: './clusterdistribution.component.html',
  styleUrls: ['./clusterdistribution.component.css']
})
export class ClusterdistributionComponent implements OnInit {

  message: Message;

  title: string = '';
  
  titlezoom: string = '';

  cmpt_ref: string = '';

  isModalDistribution: boolean = false;

  badge_dist: BadgeDistri = {
	face: 'success-standard',
	faceClass: 'is-success'
  }

  dis_id: string = '';

  private cluster_synth: ClusterSynthesis;

  private counter: string = '';

  private max: number = 100;
  
  private max_conv: number = 100;

  private avg: number = 0;

  private min: number = 0;

  private main_color: string = '#CCC6AD';

  private max_color: string = '#FFFFFF'; // white

  private avg_color: string = '#77BA33'; // green

  private min_color: string = '#7CB5EC'; // blue

  private hostMax: string = '';

  private hostMin: string = '';

  private unit: string = '';

  private max_dsp: number = 0;

  private avg_dsp: number = 0;

  private min_dsp: number = 0;

  private math = Math;

  private isDataLabel: boolean = false;

  private options: any;

  private chart: any;


  constructor(
	private message_svc: ShareService) {
  }

  ngOnInit(): void {
	this.message_svc.currentMessage.subscribe(message => this.message = message);
	this.buildCard();
  }

  zoom(ref): void {
	this.isModalDistribution = true;
	this.isDataLabel = true;
	switch (ref) {
		case "dis1":
			this.hostMax = ' on ' + this.cluster_synth.srv_vms_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_vms_min;
			this.titlezoom = 'The VM distribution per server for the ';
			break;
		case "dis2":
			this.hostMax = ' on ' + this.cluster_synth.srv_vcpu_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_vcpu_min;
			this.titlezoom = 'The VCPU distribution per server for the ';
			break;
		case "dis3":
			this.hostMax = ' on ' + this.cluster_synth.srv_vram_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_vram_min;
			this.titlezoom = 'The VRAM distribution per server for the ';
			break;
		case "dis4":
			this.hostMax = ' on ' + this.cluster_synth.srv_cpucons_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_cpucons_min;
			this.titlezoom = 'The AVERAGE CPUCONS distribution per server for the ';
			break;
		case "dis5":
			this.hostMax = ' on ' + this.cluster_synth.srv_cpuconsmax_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_cpuconsmax_min;
			this.titlezoom = 'The MAXIMUM CPUCONS distribution per server for the ';
			break;
		case "dis6":
			this.hostMax = ' on ' + this.cluster_synth.srv_cpuready_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_cpuready_min;
			this.titlezoom = 'The AVERAGE CPU READY distribution per server for the ';
			break;
		case "dis7":
			this.hostMax = ' on ' + this.cluster_synth.srv_ramcons_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_ramcons_min;
			this.titlezoom = 'The AVERAGE RAMCONS distribution per server for the ';
			break;
		case "dis8":
			this.hostMax = ' on ' + this.cluster_synth.srv_ramconsmax_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_ramconsmax_min;
			this.titlezoom = 'The MAXIMUM RAMCONS distribution per server for the ';
			break;
		case "dis9":
			this.hostMax = ' on ' + this.cluster_synth.srv_ramswp_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_ramswp_min;
			this.titlezoom = 'The AVERAGE RAMSWAP distribution per server for the ';
			break;
		case "dis10":
			this.hostMax = ' on ' + this.cluster_synth.srv_iodisk_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_iodisk_min;
			this.titlezoom = 'The AVERAGE I/O DISK distribution per server for the ';
			break;
		case "dis11":
			this.hostMax = ' on ' + this.cluster_synth.srv_latdisk_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_latdisk_min;
			this.titlezoom = 'The AVERAGE LATENCY DISK distribution per server for the ';
			break;
		case "dis12":
			this.hostMax = ' on ' + this.cluster_synth.srv_ionet_max;
                	this.hostMin = ' on ' + this.cluster_synth.srv_ionet_min;
			this.titlezoom = 'The AVERAGE I/O NET distribution per server for the ';
			break;
		default:
			break;
	}

	this.callGraph('cluster-distribution-graph-zoom', 70);
  }

  private buildCard(): void {

	if(this.message.clusterSynth) {
	this.cluster_synth = this.message.clusterSynth;
	
	this.cmpt_ref = this.cluster_synth.current_dis;
	switch (this.cluster_synth.current_dis) {
		case "dis1":
			this.dis_id = 'cluster-distribution-1';
			this.title = 'VM per server';
			this.setGraphVal(this.cluster_synth.vms_max, this.cluster_synth.vms_avg, this.cluster_synth.vms_min);
        		this.unit = 'VM';
			this.counter = 'VM';
			this.max_dsp = this.cluster_synth.vms_max;
			this.avg_dsp = this.cluster_synth.vms_avg;
			this.min_dsp = this.cluster_synth.vms_min;
			break;
		case "dis2":
			this.dis_id = 'cluster-distribution-2';
			this.title = 'VCPU per server';
			this.setGraphVal(this.cluster_synth.vcpu_max, this.cluster_synth.vcpu_avg, this.cluster_synth.vcpu_min);
        		this.unit = 'vcpu';
			this.counter = 'vcpu';
			this.max_dsp = this.cluster_synth.vcpu_max;
			this.avg_dsp = this.cluster_synth.vcpu_avg;
			this.min_dsp = this.cluster_synth.vcpu_min;
			break;
		case "dis3":
			this.dis_id = 'cluster-distribution-3';
			this.title = 'VRAM per server';
			this.setGraphVal(this.cluster_synth.vram_max, this.cluster_synth.vram_avg, this.cluster_synth.vram_min);
        		this.unit = 'Go';
			this.counter = 'Go';
			this.max_dsp = this.math.round(this.cluster_synth.vram_max/1024);
			this.avg_dsp = this.math.round(this.cluster_synth.vram_avg/1024);
			this.min_dsp = this.math.round(this.cluster_synth.vram_min/1024);
			break;
		case "dis4":
			this.dis_id = 'cluster-distribution-4';
			this.title = 'AVG CPUCONS';
			this.setGraphVal(this.cluster_synth.cpucons_max, this.cluster_synth.cpucons_avg, this.cluster_synth.cpucons_min);
        		this.unit = '%';
			this.counter = 'cpu consumption';
			this.max_dsp = this.cluster_synth.cpucons_max;
			this.avg_dsp = this.cluster_synth.cpucons_avg;
			this.min_dsp = this.cluster_synth.cpucons_min;
			break;
		case "dis5":
			this.dis_id = 'cluster-distribution-5';
			this.title = 'MAX CPUCONS';
			this.setGraphVal(this.cluster_synth.cpuconsmax_max, this.cluster_synth.cpuconsmax_avg, this.cluster_synth.cpuconsmax_min);
        		this.unit = '%';
			this.counter = 'cpu consumption';
			this.max_dsp = this.cluster_synth.cpuconsmax_max;
			this.avg_dsp = this.cluster_synth.cpuconsmax_avg;
			this.min_dsp = this.cluster_synth.cpuconsmax_min;
			break;
		case "dis6":
			this.dis_id = 'cluster-distribution-6';
			this.title = 'AVG CPURDY';
			this.setGraphVal(this.cluster_synth.cpuready_max, this.cluster_synth.cpuready_avg, this.cluster_synth.cpuready_min);
        		this.unit = '%';
			this.counter = 'cpu ready';
			this.max_dsp = this.cluster_synth.cpuready_max;
			this.avg_dsp = this.cluster_synth.cpuready_avg;
			this.min_dsp = this.cluster_synth.cpuready_min;
			break;
		case "dis7":
			this.dis_id = 'cluster-distribution-7';
			this.title = 'AVG RAMCONS';
			this.setGraphVal(this.cluster_synth.ramcons_max, this.cluster_synth.ramcons_avg, this.cluster_synth.ramcons_min);
        		this.unit = '%';
			this.counter = 'ram consumption';
			this.max_dsp = this.cluster_synth.ramcons_max;
			this.avg_dsp = this.cluster_synth.ramcons_avg;
			this.min_dsp = this.cluster_synth.ramcons_min;
			break;
		case "dis8":
			this.dis_id = 'cluster-distribution-8';
			this.title = 'MAX RAMCONS';
			this.setGraphVal(this.cluster_synth.ramconsmax_max, this.cluster_synth.ramconsmax_avg, this.cluster_synth.ramconsmax_min);
        		this.unit = '%';
			this.counter = 'ram consumption';
			this.max_dsp = this.cluster_synth.ramconsmax_max;
			this.avg_dsp = this.cluster_synth.ramconsmax_avg;
			this.min_dsp = this.cluster_synth.ramconsmax_min;
			break;
		case "dis9":
			this.dis_id = 'cluster-distribution-9';
			this.title = 'AVG RAMSWP';
			this.setGraphVal(this.cluster_synth.ramswp_max, this.cluster_synth.ramswp_avg, this.cluster_synth.ramswp_min);
        		this.unit = '%';
			this.counter = 'ram swapped';
			this.max_dsp = this.cluster_synth.ramswp_max;
			this.avg_dsp = this.cluster_synth.ramswp_avg;
			this.min_dsp = this.cluster_synth.ramswp_min;
			break;
		case "dis10":
			this.dis_id = 'cluster-distribution-10';
			this.title = 'AVG I/O DISK';
			this.setGraphVal(this.cluster_synth.iodisk_max, this.cluster_synth.iodisk_avg, this.cluster_synth.iodisk_min);
			this.unit = 'ko/s';
			this.counter = 'i/o disk';
			this.max_dsp = this.cluster_synth.iodisk_max;
			this.avg_dsp = this.cluster_synth.iodisk_avg;
			this.min_dsp = this.cluster_synth.iodisk_min;
			break;
		case "dis11":
			this.dis_id = 'cluster-distribution-11';
			this.title = 'AVG LAT DISK';
			this.setGraphVal(this.cluster_synth.latdisk_max, this.cluster_synth.latdisk_avg, this.cluster_synth.latdisk_min);
			this.unit = 'ms';
			this.counter = 'latency disk';
			this.max_dsp = this.cluster_synth.latdisk_max;
			this.avg_dsp = this.cluster_synth.latdisk_avg;
			this.min_dsp = this.cluster_synth.latdisk_min;
			break;
		case "dis12":
			this.dis_id = 'cluster-distribution-12';
			this.title = 'AVG I/O NET';
			this.setGraphVal(this.cluster_synth.ionet_max, this.cluster_synth.ionet_avg, this.cluster_synth.ionet_min);
			this.unit = 'ko/s';
			this.counter = 'i/o net';
			this.max_dsp = this.cluster_synth.ionet_max;
			this.avg_dsp = this.cluster_synth.ionet_avg;
			this.min_dsp = this.cluster_synth.ionet_min;
			break;
		default:
			break;
	}

	// BADGE MANAGEMENT
	if(this.main_color == "#FF8830") {
		this.badge_dist.face = 'warning-standard';
		this.badge_dist.faceClass = 'is-warning';
	} else if(this.main_color == "#E74C3C") {
		this.badge_dist.face = 'error-standard';
		this.badge_dist.faceClass = 'is-error';
	}

	this.callGraph(this.dis_id, 150);
	}
  }

  private setGraphVal(max: number, avg: number, min: number): void {

    	var coef: number = 1;
	var coef_min: number = 1;
	coef = isPair(max/avg) / 2;
	if(min > 0)
               	coef_min = isPair(avg/min) / 2;
	else
		coef_min = 1;

	this.main_color = this.getColor(coef, coef_min);
	this.max_conv = (max/avg) * (50/coef);
	this.avg = (50/coef);
	this.min = (min/avg) * (50/coef);
  }

  private getColor(coef: number, coef_min: number): string {

	var color = '#CCC6AD';
    	if(coef > 1 && coef <= 2) {
        	if(coef_min <= 2)
            		color = '#FF8830'; // orange
        	else
            		color = '#E74C3C'; // red
	} else if(coef > 2) {
		color = '#E74C3C'; // red
	} else {
        	if(coef_min > 1 && coef_min <= 2)
			color = '#FF8830'; // orange
		else if(coef_min > 2)
			color = '#E74C3C'; // red
        }

	return color;
  }

  private callGraph(id: string, spec_height: number): void {

    var hostMax = this.hostMax;
    var hostMin = this.hostMin;
    var unit = this.unit;
    var max = this.max_dsp;
    var avg = this.avg_dsp;
    var min = this.min_dsp;

    this.options = {
        chart: {
		renderTo: id,
		type: 'column',
            	backgroundColor: '#FFFFFF',
            	height: spec_height + '%'
	},
	title: {
        	text: null
    	},
    	legend: {
        	enabled: false
    	},
    	credits: {
        	enabled: false
	},
        exporting: {
            enabled: false
	},
	xAxis: {
	    labels: {
                enabled: true,
                useHTML: true,
                style: {
                    color: 'grey',
                    fontSize: '10px',
                    fontWeight: 'normal'
                },
                x: 0,
                y: 20
	    },
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            minorTickLength: 0,
            tickLength: 0,
            gridLineWidth: 0,
	    categories: [ 'min: ' + min + '<br>average:' + avg + '<br>max:' + max ]
	},
	yAxis: {
            labels: {
                enabled: false
            },
            gridLineWidth: 0,
            title: {
                text: null
            }
	},
	series: [{
            name: 'highest',
            color: this.main_color,
            pointWidth: 10,
            borderRadius: 4,
            enableMouseTracking: false,
            dataLabels: {
                enabled: false,
            },
            data: [
                this.max,
            ]
	}, {
            type: 'scatter',
            marker: {
                symbol: 'circle'
            },
            name: 'highest',
            color: this.max_color,
            enableMouseTracking: this.isDataLabel,
            tooltip: {
		//pointFormat: '<b>{point.y} ' + unit + '</b>',
                pointFormat: '<b>' + this.max_dsp + ' ' + unit + '</b>',
                valueDecimals: 0
            },
            dataLabels: {
                formatter: function() {
                    return '<span id="test-label">highest: ' + max + ' ' + unit + '<br>' + hostMax + '</span>';
                },
                useHTML: true,
                enabled: this.isDataLabel,
                align: 'left',
                x: 50,
                color: 'grey'
            },
            data: [
                this.max_conv,
	    ]
	}, {
            type: 'scatter',
            marker: {
                symbol: 'diamond'
            },
            name: 'average',
            color: this.avg_color,
            enableMouseTracking: this.isDataLabel,
            tooltip: {
                pointFormat: '<b>' + this.avg_dsp + ' ' + unit + '</b> per server',
                valueDecimals: 0,
            },
            data: [
                this.avg,
            ]
	}, {
	    type: 'scatter',
            marker: {
                symbol: 'circle'
            },
            name: 'lowest',
            color: this.min_color,
            enableMouseTracking: this.isDataLabel,
            tooltip: {
		pointFormat: '<b>' + this.min_dsp + ' ' + unit + '</b>',
                valueDecimals: 0
            },
            dataLabels: {
                formatter: function() {
                    return '<span id="test-label">lowest: ' + min + ' ' + unit + '<br>' + hostMin + '</span>';
                },
                useHTML: true,
                enabled: this.isDataLabel,
                align: 'left',
                x: 50,
                color: this.min_color
            },
            data: [
                this.min,
            ]
        }]
    };

    setTimeout(() => this.chart = new Highcharts.Chart(this.options), 100);
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { JsonloaderService, LicenseService, MonitorService, ShareService } from '@app/services';

import { LicenseType } from './license.enums';
import { LicenseInfo, ProductKey } from '../model/license';
import { Message, MonitorSettings } from '@app/model';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {

  @Input() target: LicenseType;

  subscriptions: Subscription[] = []

  isDcscope = true;
	isCo2scope = false;

  message: Message;

  page: LicenseType;
  modal: LicenseType;

  submit: boolean;
  send: boolean;

  license_info: LicenseInfo;
  product_key: ProductKey;
  license_key: string;

  license_modal: boolean;
  license_message: string;
  license_message_color: string;

  monitor_settings: MonitorSettings[];

  private request_url_base: string = "https://www.easyvirt.com/demande-de-licence/?";
  license_request_url: string = this.request_url_base;
  private request_url_args;

  /**
   * 
   */
  constructor(private license_svc: LicenseService, private data: ShareService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private monitor_svc: MonitorService, private json_svc: JsonloaderService) {

    this.license_modal = false;

    this.license_message = "Your license key is not valid !";
    this.license_message_color = "black";

    this.page = LicenseType.PAGE;
    this.modal = LicenseType.MODAL;

    this.submit = false;
    this.send = false;

    this.request_url_args = new Set<String>();

  }

  /**
   * 
   */
  ngOnInit(): void {

    let code: number = 12;

    // Get license error code
    this.activatedRoute.queryParams.subscribe(
      params => {
        code = Number(params.code);
      }
    );

    this.data.currentMessage.subscribe(
      message => {
        this.message = message;
        if (this.target != LicenseType.MODAL)
          this.message.isNotLoginPage = false;
        this.isCo2scope = this.message.isCO2Scope;
		    this.isDcscope = this.message.isDCScope;
      }
    );

    this.license_svc.productKey.subscribe(
      key => {
        this.product_key = key;

        if (this.product_key.key == "")
          this.license_svc.getProductKey();

        this.buildRequestURLLicense();

      });

    this.license_svc.licenseInfo.subscribe(
      infos => {
        this.license_info = infos;
        if (!this.submit)
          if (this.license_info.valid == true) {
            this.license_message = "Your license key is valid !";
          } else {
            switch (code) {
              case 13:
                this.license_message = "Your license key is not valid ! [Expiration date]";
                break;
              case 14:
                this.license_message = "Your license key is not valid ! [VM number]";
                break;
              default:
                this.license_message = "Your license key is not valid !";
                break;
            }
          }

        if (this.send) {
          this.license_svc.sendInfos();
          this.send = false;
        }

        this.buildRequestURLLicense();
      }
    );

    this.monitor_svc.getSettings().subscribe(
      data => {
        this.monitor_settings = data;
        this.buildRequestURLLicense();
      }
    );

    // Add version
    this.request_url_args.add("sourceLicense=" + this.json_svc.json.version.release);

  }

  /**
   * 
   */
  /*@HostListener('unloaded')
  ngOnDestroy() {
    console.log('LicenseComponent destroyed !');
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }*/

  /**
   * 
   */
  onSubmit(): void {
    // Send new key for validation
    if (this.license_key != undefined && this.license_key.trim() != "") {
      this.submit = true;

      this.license_svc.sendLicense(this.license_key).subscribe(
        data => {
          // data : License OK
          this.license_message = "Your new license key is registred !";
          this.license_message_color = "#306b00";
          if (this.target == LicenseType.MODAL) {
            this.license_svc.getLicenseInfo();
            // Update client infos
            this.send = true;
          } else {
            this.router.navigate(['/login']);
          }
        },
        error => {
          // error : License KO
          this.license_key = "";
          this.license_message = "Your new license key is not valid !";
          this.license_message_color = "#c21d00";
        }
      );

    }
  }

  /**
   * 
   */
  displayModalLicense(): void {
    this.license_modal = true;
  }

  /**
   * 
   */
  buildRequestURLLicense(): void {
    // Check settings
    if (this.monitor_settings != undefined) {
      this.monitor_settings.forEach(element => {
        switch (element.option) {
          case "ovf.customer.company":
            this.request_url_args.add("your-company=" + element.value);
            break;
          case "ovf.customer.email":
            this.request_url_args.add("your-email=" + element.value);
            break;
        };
      });
    }

    // Check PKey
    if (this.product_key.key)
      this.request_url_args.add("product-key=" + this.product_key.key);

    // Check VM client 
    if (this.license_info && this.license_info.vmclient > 0)
      this.request_url_args.add("VMs=" + this.license_info.vmclient);

    // Build full URL
    if (this.request_url_args)
      //this.license_request_url = this.request_url_base + '&' + Array.from(this.request_url_args).join("&");
      this.license_request_url = this.request_url_base + Array.from(this.request_url_args).join("&");

  }

}


import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import { JsonloaderService, ShareService } from '@app/services';

import { Json, Message } from '@app/model';


@Component({
  selector: 'app-clusterinfo',
  templateUrl: './clusterinfo.component.html',
  styleUrls: ['./clusterinfo.component.css']
})
export class ClusterinfoComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  dtOptions: any = {};

  dtTrigger: Subject<any> = new Subject();

  jsonLoader: Json;

  message: Message;

  data_clusters: any;

  math = Math;


  constructor(
	private router: Router,
	private json_svc: JsonloaderService,
	private message_svc: ShareService
  ) {
  }

  ngAfterViewInit(): void {
	this.dtTrigger.next();
  }

  ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {

	this.initDtOptions();
	this.json_svc.currentJson.subscribe(json => this.jsonLoader = json);
	this.data_clusters = this.jsonLoader.clusterSynthesis;
	this.message_svc.currentMessage.subscribe(message => this.message = message);
  }

  callroute(cluster): void {

        this.message.currentUuid = cluster.uuid;
        this.message.currentName = cluster.name;
        this.message.currentType = 'CLUSTER';
        this.router.navigate(['/clusterviews/synthesis']);
  }

  private initDtOptions(): void {

        this.dtOptions = {
                pagingType: 'full_numbers',
                pageLength: 10,
                lengthMenu: [[10, 15, 20, 25, 50, -1], [10, 15, 20, 25, 50, "All"]],
                processing: true,
                destroy: true,
                initComplete: function() {
                        $('div.dataTables_filter').appendTo('span.search');
                        $('div.dt-buttons').appendTo('span.export');
                        $("[id*='DataTables_Table_']").css({'padding-top':'5px'});
                        $("[name*='DataTables_Table_']").css({'padding':'0px'});
                },
                language: {
                        search: '',
                        searchPlaceholder: " search..."
                },
                dom: '<"top"B><"clear">frtlip',
                buttons: [
                        { extend: 'csv', className: 'btn btn-link btn-sm' },
                        { extend: 'copy', className: 'btn btn-link btn-sm' },
                        { extend: 'print', className: 'btn btn-link btn-sm' }
                ]
        };
  }
}
